import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import {QuestionCss} from "components/Chat/components/PollsContainer/components/JSXElements";
import {UserSelectNoneCss} from "styles/common";

const Wrap = styled.div`

`;

const QuestionStyled = styled.div`
  ${QuestionCss};
  ${UserSelectNoneCss};
  overflow-wrap: anywhere;
`;

function Question({
                    className,
                    value = '',
                  }) {


  return (
    <Wrap
      className={className}
    >
      <QuestionStyled dir={'auto'}>
        {value}
      </QuestionStyled>
    </Wrap>
  );
}

Question.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default (React.memo(Question));