import {hasPermissions} from '../../index';

export default function canCreateRecordDuration(eventId, userId, accessControls, recordDuration) {
  const permissionFunction = 'eventCreateRecordDuration';
  const params = {recordDuration};
  const object = {
    type: 'event',
    id: eventId,
    key: 'toEventId',
    parent: {
      type: 'user',
      id: userId,
      key: 'toUserId'
    }
  };

  return hasPermissions(object, accessControls, permissionFunction, params);
}