import {createSelector} from 'reselect';
import {generateFullName} from 'utilities/general';

export const getUserProfile = state => state.userProfile;
export const getUserFirstName = createSelector(getUserProfile, userProfile => userProfile.firstName);
export const getUserLastName = createSelector(getUserProfile, userProfile => userProfile.lastName);
export const getUserFullName = createSelector(getUserProfile, userProfile => generateFullName(userProfile));
export const getUserMobilePhoneNumber = createSelector(getUserProfile, userProfile =>userProfile.mobilePhoneNumber );
export const getUserDateOfBirth = createSelector(getUserProfile, userProfile => userProfile.dateOfBirth);
export const getUserAvatar = createSelector(getUserProfile, userProfile => userProfile.avatar);
export const getUserAvatarThumbnail = createSelector(getUserProfile, userProfile => userProfile.avatarThumbnail);
export const getUserRating = createSelector(getUserProfile, userProfile => userProfile.rating);
export const getUserRatingCount = createSelector(getUserProfile, userProfile => userProfile.ratingCount);
export const getUserFollowersCount = createSelector(getUserProfile, userProfile => userProfile.followersCount);
