import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { WEBSITE_NAME, FB_APP_ID, HOME_PAGE_CONTENT, TWITTER_ID } from 'config/constants';

function MetaDecorator({
  url = window.location.href,
  title = WEBSITE_NAME,
  titleSuffix = '',
  description = HOME_PAGE_CONTENT,
  image,
  type = 'website',
  children,
}) {
  return (
    <Helmet defer={false}>
      <title>{title + titleSuffix}</title>
      <link rel="canonical" href={url} />

      {/*<meta property="og:locale" content={''} />*/}
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={'event, workshop, live, VOD, video on demand, free, paid, sharing'}
      />
      {/*<meta name="theme-color" content="#E6E6FA"/>*/}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      {image && [
        <meta key={'og:image'} property="og:image" content={image} />,
        <meta key={'og:image:width'} property="og:image:width" content="600" />,
        <meta key={'og:image:height'} property="og:image:height" content="480" />,
      ]}

      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Livly" />
      <meta property="og:description" content={description} />
      <meta property="fb:app_id" content={FB_APP_ID} />
      <meta property="twitter:card" content={'summary_large_image'} />
      <meta property="twitter:site" content={'@' + TWITTER_ID} />
      {children}
    </Helmet>
  );
}

MetaDecorator.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default MetaDecorator;
