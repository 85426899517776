import {hasPermissions} from '../../index';

export default function canCreateLiveParticipantsLimit(eventId, userId, accessControls, participantsLimit) {
  const params = {participantsLimit};
  const permissionFunction = 'eventCreateLiveParticipantsLimit';
  const object = {
    type: 'event',
    id: eventId,
    key: 'toEventId',
    parent: {
      type: 'user',
      id: userId,
      key: 'toUserId'
    }
  };

  return hasPermissions(object, accessControls, permissionFunction, params);
}