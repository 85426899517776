import {useEffect} from "react";
import {cloneDeep} from "lodash";

let originalEvent = {};
let originalOccurrences = {};
let originalSettings = {};

export function resetUseOriginalEvent() {
  originalEvent = {};
  originalOccurrences = {};
  originalSettings = {};
}

export function useOriginalEvent(event, occurrences, eventSettings) {

  useEffect(() => {
    if (originalEvent.id !== event.id) {
      originalEvent = cloneDeep(event);
      originalOccurrences = cloneDeep(occurrences);
      originalSettings = cloneDeep(eventSettings);
    }

  }, [event]);// eslint-disable-line

  return [originalEvent, originalOccurrences, originalSettings];
}