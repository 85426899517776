import {appendData, getData} from "utilities/localStorage";
import {VOD_END_TIME} from "config/constants";

const VOD_LOCAL_STORAGE = 'VOD_LOCAL_STORAGE'


export function getVod(id) {
  const vodDB = getData(VOD_LOCAL_STORAGE);
  if (!vodDB) {
    return null;
  }

  return vodDB[id];
}

export function setVod(id, data = {}) {
  if (!id) {
    return;
  }

  let vodDB = getData(VOD_LOCAL_STORAGE) || {};

  vodDB[id] = {...vodDB[id], ...data};

  appendData(VOD_LOCAL_STORAGE, vodDB);
}

export function setPlayTime(id, time) {
  setVod(id, {
    playTime: time
  });
}

export function getPlayTime(id) {
  const vod = getVod(id) || {};
  return vod.playTime || 0;
}

//if the remaining of the time is less the end time seek to that time
export function isVodEnded(playTime, duration) {
  return !((duration - playTime) > VOD_END_TIME)
}