import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const EventsCountWrap = styled.div`
  display: flex;
`;

const CountLabel = styled.div`
  padding-right: 0.3em;
`;

function EventsCount({count, ...props}) {
  return (
    <EventsCountWrap  {...props} >
      <CountLabel>
        {count}
      </CountLabel>
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.89744 9.22333C1.04766 9.18546 1.01936 9.34042 1.02952 8.40084C1.03678 7.75295 1.03097 7.10436 1.03097 6.47189C0.980175 6.44595 0.960581 6.42702 0.940262 6.42702C0.174662 6.4137 0.159422 6.49573 0.162325 5.64731C0.169582 3.92661 0.163776 2.20661 0.165228 0.486619C0.165228 0.0420708 0.211672 0.00070118 0.695705 0C4.67755 0 8.65867 0 12.6398 0.00070118C13.1129 0.00070118 13.1608 0.0462779 13.1616 0.496436C13.1623 2.27463 13.1623 4.05282 13.1616 5.83102C13.1616 6.40598 13.155 6.41159 12.5549 6.41931C12.4838 6.42001 12.4127 6.42492 12.2951 6.42982C12.2951 7.0714 12.2951 7.70667 12.2951 8.34124C12.2951 8.45764 12.2951 8.57403 12.2951 8.68973C12.2936 9.17144 12.2588 9.2051 11.7624 9.2093C11.6674 9.21 11.5723 9.2093 11.4286 9.2093C11.4286 9.88384 11.4286 10.5422 11.4286 11.2C11.4286 12 11.4286 12 10.6086 12C7.89017 12 5.17175 12 2.45332 12C1.93155 12 1.89817 11.9677 1.89744 11.4685C1.89744 10.7274 1.89744 9.98621 1.89744 9.22333ZM12.568 0.582681C8.61296 0.582681 4.68699 0.582681 0.771177 0.582681C0.771177 2.35456 0.771177 4.1019 0.771177 5.84223C4.71747 5.84223 8.63473 5.84223 12.568 5.84223C12.568 4.08508 12.568 2.34615 12.568 0.582681ZM1.63475 6.43403C1.63475 7.1864 1.63475 7.91072 1.63475 8.62452C5.00847 8.62452 8.35679 8.62452 11.6979 8.62452C11.6979 7.88057 11.6979 7.16466 11.6979 6.43403C8.33937 6.43403 4.99976 6.43403 1.63475 6.43403ZM2.50339 9.22262C2.50339 9.9799 2.50339 10.7042 2.50339 11.425C5.29294 11.425 8.05418 11.425 10.8299 11.425C10.8299 10.6839 10.8299 9.95957 10.8299 9.22262C8.04692 9.22262 5.28495 9.22262 2.50339 9.22262Z"
          fill="white"/>
      </svg>

    </EventsCountWrap>
  );
}

EventsCount.propTypes = {
  count: PropTypes.number,
};


export default (EventsCount);