export const SET_POLLS = 'SET_POLLS';
export const ADD_POLL = 'ADD_POLL';
export const ADD_POLLS = 'ADD_POLLS';
export const DELETE_POLL = 'DELETE_POLL';
export const UPDATE_POLL = 'UPDATE_POLL';
export const UPSERT_POLL = 'UPSERT_POLL';
export const CLEAR_POLLS = 'CLEAR_POLLS';

export const UPDATE_POLL_OPTION = 'UPDATE_POLL_OPTION';

export const UPSERT_POLL_OPTION_VOTE = 'UPSERT_POLL_OPTION_VOTE';
