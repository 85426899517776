import styled, {css} from 'styled-components';
import {msr} from './helpers';
import theme from './theme';
import {Link} from 'react-router-dom';
import AddToCalendarSelector from "components/AddToCalendarSelector";
import React from "react";

export const TILE_SEPARATOR_HEIGHT = '60px';
export const BORDER_RADIUS = '20px';
export const COVER_RATIO = '80%'; //=> 4/5
export const COVER_BORDER_RADIUS = '0.5rem';
export const COVER_MAX_WIDTH = '270px';
export const COVER_ASPECT_RATIO_INT = 0.8; //=> 4/5  height / width

export const TILE_COVER_WIDTH = 300;
export const TILE_COVER_HEIGHT = TILE_COVER_WIDTH * COVER_ASPECT_RATIO_INT;

export const UserSelectNoneCss = css`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const CoverHeightScaleCss = css`
  ${scale([
    ['default', [['min-height', `calc(230px * ${COVER_ASPECT_RATIO_INT})`]]],
    ['560px', [['min-height', `calc(300px * ${COVER_ASPECT_RATIO_INT})`]]],
    ['768px', [['min-height', `calc(350px * ${COVER_ASPECT_RATIO_INT})`]]],
    ['992px', [['min-height', `calc(350px * ${COVER_ASPECT_RATIO_INT})`]]],
    ['1200px', [['min-height', `calc(350px * ${COVER_ASPECT_RATIO_INT})`]]],
    ['1400px', [['min-height', `calc(400px * ${COVER_ASPECT_RATIO_INT})`]]],
  ])};

`;

export function scale(scales) {
  return css`
    ${scales.map(scale => {
      if (scale[0] === 'default') {
        return (scale[1] || []).map(style => `${style[0]}: ${style[1]};`).join('\n');
      }
      return `
          @media screen and (min-width: ${scale[0]}) {
            ${(scale[1] || []).map(style => `${style[0]}: ${style[1]};`).join('\n')}
          }
        `;
    })}
  `;
}

export const ModalSmallFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body]]],
    ['560px', [['font-size', theme.typo.sizes.small]]],
    ['768px', [['font-size', theme.typo.sizes.medium]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]],
  ])}
`;

export const ContactSupport = styled(Link)`
  display: flex;
  cursor: pointer;
  color: ${({theme}) => theme.colors.grayLighten};
  font-weight: bold;
  margin-bottom: 0.2em;
  ${ModalSmallFontSizeCss};

  &:hover {
    color: ${({theme}) => theme.colors.grayNew};
  }

  &:active {
    color: ${({theme}) => theme.colors.grayLighten};
  }
`;

export const HoverStyle = css`
  background-color: ${({theme: {colors}}) => colors.paleGrey};
  box-shadow: 0 0 20px -3px rgba(0, 0, 0, 0.20);
`;

export const HoverEffect = css`
  border-bottom: 1px solid #fff;

  &:hover {
    ${HoverStyle};
    transition: all 0.4s;
  }
`;

export const scaleFont = css`
  ${scale([
    ['default', [['font-size', msr(1)]]],
    ['560px', [['font-size', msr(1.7)]]],
    ['768px', [['font-size', msr(1.8)]]],
    ['992px', [['font-size', msr(2.4)]]],
    ['1200px', [['font-size', msr(2.8)]]],
    ['1400px', [['font-size', msr(3.2)]]],
    ['1600px', [['font-size', msr(3.6)]]],
  ])}
`;


export const LabelFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.medium]]],
    ['1200px', [['font-size', theme.typo.sizes.medium]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]],
    ['1600px', [['font-size', theme.typo.sizes.small]]],
  ])}
`;

export const StyledTextInputCss = css`
  display: flex !important; //todo: zoom restyle remove important
  align-items: center;
  border: 1px solid ${({theme: {colors}}) => colors.lightGrey};
  border-radius: 4px;
  padding: 5px !important;
  background-color: ${({theme: {colors}}) => colors.paleGrey};


  //zoom restyle
  outline: none !important;
  transition: none !important;
  height: calc(1.5em + .75rem + 2px) !important;
  line-height: 1.5 !important;
  ${LabelFontSizeCss};
`;

export const MenuItemWrapCss = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: 0;
  ${HoverEffect};


`;

export const DropDownItemCss = css`
  padding: 3px ${({theme: {padding}}) => padding.pageM}px;

  ${scale([
    ['560px', [['padding', `5px ${theme.padding.pageM}px`]]],
    ['768px', [['padding', `7px ${theme.padding.pageM}px`]]]
  ])}
`;


export const ScaleFontMenuCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]],
    ['1600px', [['font-size', theme.typo.sizes.base]]],
  ])}
`;


export const GeneralDropDownItemCss = css`
  ${MenuItemWrapCss};
  color: ${({theme: {colors}}) => colors.grayNew};
  font-weight: normal;
  ${ScaleFontMenuCss};
  ${UserSelectNoneCss};
  ${DropDownItemCss};

  &:hover {
    color: ${({theme: {colors}}) => colors.darkGrey};
  }

  ${({disabled}) => disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;

      // &:hover {
        //   background-color: ${({theme: {colors}}) => colors.purpleLighten};
      // }
      //
      // &:active {
        //   background-color: ${({theme: {colors}}) => colors.purple};
      // }
    `
  };
`;


export const MenuItemIconCss = css`
  margin-right: ${({theme: {padding}}) => padding.pageM}px;
  ${scale([['768px', [['margin-right', `${theme.padding.pageD}px`]]]])}
`;

export const MenuItemLabelCss = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CustomScrollBars = css`
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-track-piece {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: none;
  }

`;

export const LightCustomToolbar = css`
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    -webkit-border-radius: 9px;
    background-color: ${({theme}) => theme.colors.lightGrey};

    &:hover {
      background-color: ${({theme}) => theme.colors.grayLightenX3};
    }

    &:active {
      background-color: ${({theme}) => theme.colors.grayLightenX2};
    }
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

`;

export const GeneralTileWrapCss = css`
  display: flex;
  flex-direction: column;
  height: ${TILE_COVER_HEIGHT + 105}px;
    // width: ${TILE_COVER_WIDTH}px;
  width: calc(260px + 2vw);

  flex-shrink: 0;
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 7px 7px;
  -webkit-box-shadow: 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%), 0 2px 3px -1px rgb(0 0 0 / 20%);
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%), 0 2px 3px -1px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%), 0 2px 3px -1px rgb(0 0 0 / 20%);
  margin: 10px ${({theme: {typo}}) => typo.sizes.body} 10px 5px;

`;


export const HideScrollBarCss = css`
  scrollbar-width: none;

  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

export const DatePickerFontCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]],
  ])}
`;

export const NoContentCss = css`
  font-size: 4rem;
`;

export const SpinnerCss = css`
  width: 5rem;
  height: 5rem;
`;

export const PriceLiveLabelDimCss = css`
  width: 34px;
  min-width: 34px;
  max-width: 34px;

  height: 46px;
  min-height: 46px;
  max-height: 46px;
`;

export const PriceLiveLabelFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.medium]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]],
  ])}
`;

export const PriceLiveLabelReduceFontSizeCss = css`
  ${scale([
    ['default', [['font-size', '1.05rem']]],
    ['560px', [['font-size', '0.9rem']]],
    ['768px', [['font-size', '0.80rem']]],
    ['992px', [['font-size', '0.65rem']]],
    ['1200px', [['font-size', '0.65rem']]],
    ['1400px', [['font-size', '0.55rem']]],
  ])}
`;

export const ErrorFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]],
    ['1600px', [['font-size', theme.typo.sizes.base]]],
  ])}
`;

export const InfoIconFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]],
    ['1600px', [['font-size', theme.typo.sizes.body]]],
  ])}
`;

export const TinyFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.medium]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]],
  ])}
`;


export const BodyFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h4]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}
`

export const Body2FontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h3]]],
    ['560px', [['font-size', theme.typo.sizes.h3]]],
    ['768px', [['font-size', theme.typo.sizes.h4]]],
    ['992px', [['font-size', theme.typo.sizes.h5]]],
    ['1200px', [['font-size', theme.typo.sizes.h5]]],
    ['1400px', [['font-size', theme.typo.sizes.body2]]]
  ])}
`;

export const BaseFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]]
  ])}
`;

export const MediumFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.medium]]],
    ['1200px', [['font-size', theme.typo.sizes.medium]]],
    ['1400px', [['font-size', theme.typo.sizes.medium]]]
  ])}
`;

export const SmallFontSizeCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}
`;

export const TextShadowCss = css`
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
`;

export const addToCalendarModal = (setDialog) => {
  return [
    <div key={1} style={{paddingTop: 5}}>
      <AddToCalendarSelector
        setDialog={setDialog}
      />
      <div style={{fontSize: 17}}>
        note: you can set calendar preference in
        <Link
          target='_blank'
          to={'/user-settings/preferences'}
        >
          {' user settings'}
        </Link>
      </div>
    </div>
  ];

}


export const HomeChannelAvatarCss = css`
  --dim: 200px;
  width: var(--dim);
  height: var(--dim);
  min-width: var(--dim);
  max-width: var(--dim);
  min-height: var(--dim);
  max-height: var(--dim);

  ${scale([
    ['default', [['--dim', '100px;']]],
    ['560px', [['--dim', '110px;']]],
    ['768px', [['--dim', '120px;']]],
    ['992px', [['--dim', '130px;']]],
    ['1200px', [['--dim', '140px;']]],
    ['1400px', [['--dim', '140px;']]],
  ])};
  margin-bottom: 0.3em;
`;

