import AuthService from 'services/auth';
import * as auth from 'utilities/auth';

import {removeFeedback} from '../feedbacks/actions';
import {updateUser} from '../user/actions';
import {FEEDBACK_NAMES, USER_STATUS} from 'config/constants';
import {errorHandler} from 'utilities/services';

const authService = new AuthService();

export function register({email, password, firstName, lastName, timezone}) {
  return dispatch => authService
    .register({email, password, firstName, lastName, timezone})
    .then((response) => {
      const {data: {accessToken}} = response;
      auth.login(accessToken);
      return {accessToken};
    })
}

export function login({email, password}) {
  return dispatch => authService
    .login({email, password})
    .then((response) => {
      const {data: {accessToken}} = response;
      auth.login(accessToken);
      return {accessToken};
    })
}

export function logout() {
  return dispatch => authService
    .logout()
    .catch(err=>{errorHandler(err)})
    .finally(() => {
      auth.logout();
    });
}

export function forgotPassword({email}) {
  return dispatch => authService
    .forgotPassword({email});
}

export function resetPassword({accessToken, password}) {
  return dispatch => authService
    .resetPassword(accessToken, {password})
    .then((response) => {
      const {data: {accessToken}} = response;
      auth.login(accessToken);
      return {accessToken};
    })
    .catch(err=>{errorHandler(err)});
}

export function verifyEmail(accessToken) {
  return dispatch => authService
    .verifyEmail(accessToken)
    .then((response) => {
      dispatch(removeFeedback(FEEDBACK_NAMES.EMAIL_VERIFICATION.key));
      dispatch(updateUser({status: USER_STATUS.VERIFIED.key}));
      const {data: {accessToken}} = response;
      auth.login(accessToken);
      return {accessToken};
    })
    .catch(err=>{errorHandler(err)});
}