import EventService from 'services/event';

import {
  setHomeUpcomingEvents,
  addHomeUpcomingEvents
} from './actions';
import {errorHandler} from 'utilities/services';

const eventService = new EventService();

export function fetchHomeUpcomingEvents(query, addNextPage) {
  return dispatch => eventService
    .getAllUpcoming(query)
    .then((response) => {
      const {data: {events}} = response;
      events.reqParams = query.reqParams;
      if (addNextPage) {
        dispatch(addHomeUpcomingEvents(events));
      } else {
        dispatch(setHomeUpcomingEvents(events));
      }
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}