import React from 'react';
import PropTypes from 'prop-types';
//  import styled from 'styled-components';
import classnames from 'classnames';


function SelectInput({
                       setRef = () => {
                       }, className, name, value, options = [], onChange, ...props
                     }) {
  return (
    <select
      ref={setRef}
      className={classnames('form-control', className)}
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    >
      {options.map(({text, ...option}) => {
        return (
          <option
            key={option.value}
            {...option}
          >
            {text}
          </option>
        );
      })}
    </select>
  );
}

SelectInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  PollOptions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired
};

export default SelectInput;
