import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { take } from 'lodash';
import { Drag } from 'utilities/utils';
import { bindActionCreators } from 'redux';
import { logout } from '../../../../reduxUtils/auth/thunks';
import { updateSearch } from '../../../../reduxUtils/search/actions';
import { connect } from 'react-redux';
import { CATEGORIES } from 'config/categories';
import { HideScrollBarCss, UserSelectNoneCss } from 'styles/common';

const TagsWrap = styled.div`
  overflow: auto;
  white-space: nowrap;
  display: flex;
  justify-content: start;
  text-transform: capitalize;
  width: 100%;
  height: auto;
  align-items: center;
  margin-block-start: auto;

  ${UserSelectNoneCss};

  ${HideScrollBarCss};
`;

const Tag = styled.span`
  margin-right: 1.5em;

  &:last-child {
    margin-right: 0;
  }

  width: auto;

  ${({ asText }) =>
    asText
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;

          &:hover,
          &:focus {
            color: ${({ theme: { colors } }) => colors.grayNew};
            text-decoration: none;
          }
        `};
`;

function Tags({
  categories = [],
  maxCategories = 4,
  updateSearch,
  scrolled = true,
  asText = false,
  ...props
}) {
  const sectionRef = useRef();
  const drag = useRef();

  useEffect(() => {
    if (scrolled) {
      drag.current = new Drag(true, false, 1.3);
      drag.current.register(sectionRef);
      return () => {
        drag.current.deRegister();
      };
    }
  }, [scrolled]);

  function findLabelCategory(value) {
    for (let i = 0; i < CATEGORIES.length; ++i) {
      if (CATEGORIES[i].value === value) {
        return CATEGORIES[i].label;
      }
      if (CATEGORIES[i].sub) {
        for (let m = 0; m < CATEGORIES[i].sub.length; ++m) {
          if (CATEGORIES[i].sub[m].value === value) {
            return CATEGORIES[i].sub[m].label;
          }
        }
      }
    }
  }

  return (
    <TagsWrap ref={sectionRef} {...props}>
      {take(categories, maxCategories).map((item) => {
        const label = findLabelCategory(item);
        return (
          <Tag
            key={item}
            asText={asText}
            onClick={() =>
              asText
                ? {}
                : ((scrolled && !drag.current.isDrag) || !scrolled) && // Todo: dudu - this need to be checked
                  updateSearch({ category: { value: item, label: label } })
            }
          >
            {label}
          </Tag>
        );
      })}
    </TagsWrap>
  );
}

Tags.propTypes = {
  tags: PropTypes.array,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
      updateSearch,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(React.memo(Tags));
