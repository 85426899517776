import styled, {css} from 'styled-components';
import React from "react";
import Icon from "components/Icon";
import {UserSelectNoneCss} from "styles/common";

export const PollWrapCSS = css`
  display: flex;
  flex-direction: column;
  margin: 10px 0 15px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0 3px 6px, rgb(0 0 0 / 10%) 0 3px 6px;
`;

export const QuestionCss = css`
  color: ${({theme}) => theme.colors.darkGrey};
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 3px;

`;

export const OptionBoxCss = css`
  border-radius: 15px;
  background-color: ${({theme: {colors}}) => colors.whiteFill};
  border-width: 1px 1px 3px;
  border-color: ${({theme: {colors}}) => colors.lightGrayV2};
  border-style: solid;
  font-size: 14px;
  color: ${({theme}) => theme.colors.darkGrey};
  font-weight: 600;
`;

export const OptionBoxCssInner = css`
  padding: 7px 10px;
  min-height: 46px;
`;

export const SettingFontSize = css`
  font-size: 13px;
  //font-weight: bold;
`;


const DeletePollButtonOuterWrap = styled.div`
  display: flex;
  width: 100%;
`;

const DeletePollButtonWrap = styled.div`
  cursor: pointer;
  background-color: #e5e8ea69;
  border-radius: 50%;
  margin-left: auto;

  &:hover {
    background-color: ${({theme: {colors}}) => colors.grayLightenX4};
  }

  &:active {
    background-color: #d1d5d8;
  }

`;

const DeletePoll = styled(Icon)`
  color: ${({theme: {colors}}) => colors.grayNew};
  cursor: pointer;
`;

export function DeletePollComponent({onDelete}) {
  return <DeletePollButtonOuterWrap>
    <DeletePollButtonWrap onClick={onDelete}>
      <DeletePoll iconName='fas fa-times' />
    </DeletePollButtonWrap>
  </DeletePollButtonOuterWrap>
}


export const OptionWrapCss = css`
  color: ${({theme: {colors}}) => colors.darkGrey};
`;

export const OptionImageCss = css`
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-top: 2px;
  border-radius: 50%;
`;

export const PollActionButton = styled.button`
  padding: 6px 7px;
  background-color: #fefefe59;
  color: ${({theme: {colors}}) => colors.grayNew};
  width: max-content;
  margin-left: auto;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  border: 1px solid ${({theme: {colors}}) => colors.lightGrayV2};
  border-radius: 10px;
  font-weight: 500;
  transition: 0.1s linear;
  ${({disabled}) =>
    disabled
      ? css`
        opacity: 50%;
      `
      : css`
        &:hover {
          transform: scale(1.03);
          background-color: ${({theme: {colors}}) => colors.whiteFill}
        }

        &:active {
          transform: scale(1.00);
        }
      `
  };

  ${UserSelectNoneCss};
`;