import {hasPermissions, hasNoPermission, getReqPermissions} from '../../index';
import canViewWorkshopContent from '../workshops/can-view-workshop-content';

export default function canView(eventId, workshopId, userId, accessControls) {
  const permissionFunction = 'eventCanView';
  const object = {
    type: 'event',
    id: eventId,
    key: 'toEventId'
  };
  const requiredPermissions = getReqPermissions(permissionFunction);
  const eventDisallowed = hasNoPermission(object, requiredPermissions[0], accessControls);
  const eventAllowed = hasPermissions(object, accessControls, permissionFunction);

  return eventAllowed || (!eventDisallowed && canViewWorkshopContent(workshopId, accessControls));
}

