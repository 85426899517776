import {createAction} from 'redux-actions';
import {
  SET_POLLS,
  ADD_POLL,
  ADD_POLLS,
  DELETE_POLL,
  UPDATE_POLL,
  UPSERT_POLL,
  CLEAR_POLLS,
  UPDATE_POLL_OPTION,
  UPSERT_POLL_OPTION_VOTE
} from './types';


export const setPollsAction = createAction(SET_POLLS);
export const addPollAction = createAction(ADD_POLL);
export const addPollsAction = createAction(ADD_POLLS);
export const deletePollAction = createAction(DELETE_POLL);
export const updatePollAction = createAction(UPDATE_POLL);
export const upsertPollAction = createAction(UPSERT_POLL);
export const clearPollsAction = createAction(CLEAR_POLLS);

export const updatePollOptionAction = createAction(UPDATE_POLL_OPTION);
export const upsertPollOptionVoteAction = createAction(UPSERT_POLL_OPTION_VOTE);

