import moment from 'moment';
import { dateToUserTimeZone, formatTime, getUserTimeZone } from 'utilities/date';
import {
  addGoogleCalendarBatchEventsGapi,
  addGoogleCalendarEvent,
  deleteBatchCalendarEvents,
  getGoogleSignedStatus,
  GOOGLE_API,
  grandScopes,
  hasAccessToGoogleScope,
} from 'utilities/gapi';
import { errorHandler } from 'utilities/services';
import { getData, setData } from 'utilities/localStorage';
import { ADD_TO_CALENDAR_POPUP_NOTICE_FLAG } from 'config/constants';
import { getEventUrl, getFutureEvents } from 'utilities/event';
import { addToCalendarModal } from 'styles/common';
import { getStoreState } from 'utilities/store';
import { getAddToCalendarPopUp } from '../reduxUtils/userSettings/selectors';
import { appName } from 'config/config';

export const EMAIL_OPTIONS = {
  NONE: 'none',
  GOOGLE_AUTO: 'googleAuto',
  GOOGLE_POPUP: 'googlePopup',
  YAHOO_POPUP: 'yahooPopup',
};

export const emailOptions = [
  { text: 'None', value: EMAIL_OPTIONS.NONE },
  { text: 'Google | Auto', value: EMAIL_OPTIONS.GOOGLE_AUTO },
  { text: 'Google | Popup', value: EMAIL_OPTIONS.GOOGLE_POPUP },
  { text: 'Yahoo | Popup', value: EMAIL_OPTIONS.YAHOO_POPUP },
  // {text: 'Apple', value: 'apple'},
  // {text: 'Outlook', value: 'outlook'},
];

const GOOGLE_CALENDAR_URL = 'https://www.google.com/calendar/event';
const YAHOO_CALENDAR_URL = 'https://calendar.yahoo.com/?v=60&view=d&type=20';
const OUTLOOK_CALENDAR_URL = 'https://outlook.live.com/owa/?rru=addeven';

export function googleCalendarUrl(startTime, endTime, location, title, summary) {
  let calendarUrl = GOOGLE_CALENDAR_URL;
  calendarUrl += '?action=TEMPLATE';
  calendarUrl += '&dates=' + startTime;
  calendarUrl += '/' + endTime;
  calendarUrl += '&location=' + location;
  calendarUrl += '&text=' + title;
  calendarUrl += '&details=' + summary;

  return calendarUrl;
}

export function yahooCalendarUrl(startTime, duration, location, title, summary) {
  const durationFormat = moment.utc(moment.duration(duration, 's').asMilliseconds()).format('HHmm');
  let calendarUrl = YAHOO_CALENDAR_URL;
  calendarUrl += '&title=' + title;
  calendarUrl += '&st=' + startTime;
  calendarUrl += '&dur=' + durationFormat;
  calendarUrl += '&desc=' + summary;
  calendarUrl += '&in_loc=' + location;

  return calendarUrl;
}

export function outlookCalendarUrl(startTime, endTime, location, title, summary, uid) {
  let calendarUrl = OUTLOOK_CALENDAR_URL;
  calendarUrl += '&startdt=' + startTime;
  calendarUrl += '&enddt=' + endTime;
  calendarUrl += '&subject=' + title;
  calendarUrl += '&location=' + location;
  calendarUrl += '&body=' + summary;
  calendarUrl += '&allday=false';
  calendarUrl += '&uid=' + uid;
  calendarUrl += '&path=/calendar/view/Month';
  return calendarUrl;
}

export function icsCalendarUrl(startTime, endTime, location, title, summary) {
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    'URL:' + document.URL,
    'DTSTART:' + startTime,
    'DTEND:' + endTime,
    'SUMMARY:' + title,
    'DESCRIPTION:' + summary,
    'LOCATION:' + location,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');
}

export function getCalendarEventId({ eventId, occurrenceId, workshopId }) {
  return workshopId
    ? `${'workshopId' + workshopId}`
    : `${eventId ? 'event' + eventId : ''}${occurrenceId ? `occurrence${occurrenceId}` : ''}`;
}

export function setAddToCalendarSingleEventPopUp(
  calendarEventId,
  setGoogleDialog,
  startTime,
  duration,
  title,
  summary,
  location = appName
) {
  let userStartDateTime = dateToUserTimeZone(startTime);
  let endTime = moment(userStartDateTime).add(duration, 's');
  let url;
  const state = getStoreState();
  const calendar = getAddToCalendarPopUp(state);

  switch (calendar) {
    case EMAIL_OPTIONS.NONE:
      return;
    case EMAIL_OPTIONS.GOOGLE_AUTO:
      const calendarStartTime = moment(startTime);
      const calendarEndTime = moment(startTime).add(duration, 's');

      const addToGoogleCalendarFunc = () =>
        addGoogleCalendarEvent(
          calendarEventId,
          calendarStartTime,
          calendarEndTime,
          location,
          title,
          summary
        );
      if (!getGoogleSignedStatus()) {
        setGoogleDialog((currentVal) => {
          return {
            ...currentVal,
            show: true,
            title: `Sign in with google`,
            scopes: GOOGLE_API.calendar.scopes,
            discoveryDocs: GOOGLE_API.calendar.discoveryDocs,
            onSuccess: () => {
              currentVal.onHide();
              addToGoogleCalendarFunc();
            },
            onFail: errorHandler,
          };
        });
      } else {
        if (!hasAccessToGoogleScope(GOOGLE_API.calendar.scopes)) {
          grandScopes(GOOGLE_API.calendar.scopes).then(() => {
            addToGoogleCalendarFunc();
          });
        } else {
          addToGoogleCalendarFunc();
        }
      }
      return;
    case EMAIL_OPTIONS.GOOGLE_POPUP:
      url = googleCalendarUrl(
        formatTime(userStartDateTime),
        formatTime(endTime),
        encodeURIComponent(location),
        encodeURIComponent(title),
        encodeURIComponent(summary)
      );
      break;
    case EMAIL_OPTIONS.YAHOO_POPUP:
      url = yahooCalendarUrl(
        formatTime(userStartDateTime),
        duration,
        encodeURIComponent(location),
        encodeURIComponent(title),
        encodeURIComponent(summary)
      );
      break;
    // case 'outlook':
    //   url = outlookCalendarUrl(formatTime(userStartDateTime), formatTime(endTime), encodeURIComponent(location), encodeURIComponent(title), encodeURIComponent(summary));
    //   break;
    // case 'apple':
    //   url = icsCalendarUrl(formatTime(userStartDateTime), formatTime(endTime), location, title, summary);
    //   break;
    default:
      return;
  }

  if (url) {
    const win = window.open(url, '_blank');
    win.focus();
  }
}

export function setAddToCalendarWorkshop(setGoogleDialog, workshopEventsRef, adminOrOwner) {
  const state = getStoreState();
  const calendar = getAddToCalendarPopUp(state);
  const workshopEvents = workshopEventsRef.current;
  switch (calendar) {
    case EMAIL_OPTIONS.NONE:
      return;
    case EMAIL_OPTIONS.GOOGLE_AUTO:
      const addToGoogleCalendarFunc = () =>
        addGoogleCalendarBatchWorkshopEvents(workshopEvents, adminOrOwner);
      if (!getGoogleSignedStatus()) {
        setGoogleDialog((currentVal) => {
          return {
            ...currentVal,
            show: true,
            title: `Sign in with google`,
            scopes: GOOGLE_API.calendar.scopes,
            discoveryDocs: GOOGLE_API.calendar.discoveryDocs,
            onSuccess: () => {
              currentVal.onHide();
              addToGoogleCalendarFunc();
            },
            onFail: errorHandler,
          };
        });
      } else {
        if (!hasAccessToGoogleScope(GOOGLE_API.calendar.scopes)) {
          grandScopes(GOOGLE_API.calendar.scopes).then(() => {
            addToGoogleCalendarFunc();
          });
        } else {
          addToGoogleCalendarFunc();
        }
      }
      return;

    default:
      return;
  }
}

export function setAddToCalendarBatch({
  setGoogleDialog,
  adminOrOwner,
  eventId,
  occurrences,
  duration,
  title,
  summary,
}) {
  const state = getStoreState();
  const calendar = getAddToCalendarPopUp(state);
  switch (calendar) {
    case EMAIL_OPTIONS.NONE:
      return;
    case EMAIL_OPTIONS.GOOGLE_AUTO:
      const addToGoogleCalendarFunc = () =>
        addGoogleCalendarBatchEvents({
          adminOrOwner,
          eventId,
          occurrences,
          duration,
          title,
          summary,
        });
      if (!getGoogleSignedStatus()) {
        setGoogleDialog((currentVal) => {
          return {
            ...currentVal,
            show: true,
            title: `Sign in with google`,
            scopes: GOOGLE_API.calendar.scopes,
            discoveryDocs: GOOGLE_API.calendar.discoveryDocs,
            onSuccess: () => {
              currentVal.onHide();
              addToGoogleCalendarFunc();
            },
            onFail: errorHandler,
          };
        });
      } else {
        if (!hasAccessToGoogleScope(GOOGLE_API.calendar.scopes)) {
          grandScopes(GOOGLE_API.calendar.scopes).then(() => {
            addToGoogleCalendarFunc();
          });
        } else {
          addToGoogleCalendarFunc();
        }
      }
      return;

    default:
      return;
  }
}

function setDialogCalendar(setDialog, onConfirm) {
  const noticeFlag = getData(ADD_TO_CALENDAR_POPUP_NOTICE_FLAG);

  if (noticeFlag) {
    // if notice was shown dont show selection choice
    onConfirm();
    return;
  }

  setData(ADD_TO_CALENDAR_POPUP_NOTICE_FLAG, true);
  setDialog((current) => {
    return {
      show: true,
      title: `Add to Calendar Popup | One-Time Notice`,
      children: addToCalendarModal(setDialog),
      confirmationText: `Do you wish to add to calendar?`,
      action: 'ADD',
      cancelButtonText: 'NO',
      onCancel: current.onCancel,
      onConfirm: () => {
        current.onCancel();
        onConfirm();
      },
    };
  });
}

export function setEventCalendarConfirmation({
  adminOrOwner,
  eventId,
  occurrence,
  duration,
  title,
  summary,
  setGoogleDialog,
  setDialog,
}) {
  const calendarEventId = getCalendarEventId({ eventId: eventId, occurrenceId: occurrence.id });
  const onConfirm = () =>
    setAddToCalendarSingleEventPopUp(
      calendarEventId,
      setGoogleDialog,
      occurrence.date,
      duration,
      title,
      summary,
      getEventUrl(eventId, occurrence.id, adminOrOwner, true)
    );
  setDialogCalendar(setDialog, onConfirm);
}

export function deleteEventFromGoogleCalendar(calendarEventId) {
  const state = getStoreState();
  const calendar = getAddToCalendarPopUp(state);
  if (calendar === EMAIL_OPTIONS.GOOGLE_AUTO && getGoogleSignedStatus()) {
    let request = window.gapi.client.calendar.events.delete({
      calendarId: 'primary',
      eventId: calendarEventId,
    });

    request.execute(function (event) {
      console.log(`calendar event delete: `, event);
    });
  }
}

export function deleteBatchEventFromGoogleCalendar({ eventId, occurrences }) {
  const state = getStoreState();
  const calendar = getAddToCalendarPopUp(state);
  if (calendar !== EMAIL_OPTIONS.GOOGLE_AUTO || !getGoogleSignedStatus()) {
    return;
  }
  const liveEvents = getFutureEvents({
    events: occurrences,
    filterVodEvents: true,
    isLiveEvent: true,
  });

  const calendarEvents = liveEvents.map(({ id }) => {
    return {
      id: getCalendarEventId({ eventId: eventId, occurrenceId: id }),
    };
  });

  deleteBatchCalendarEvents(calendarEvents);
}

export function deleteBatchWorkshopEventFromGoogleCalendar(workshopEvents) {
  const state = getStoreState();
  const calendar = getAddToCalendarPopUp(state);

  if (calendar !== EMAIL_OPTIONS.GOOGLE_AUTO || !getGoogleSignedStatus()) {
    return;
  }
  const liveEvents = getFutureEvents({
    events: workshopEvents,
    filterVodEvents: true,
  });

  const calendarEvents = liveEvents.map(({ id, occurrenceId, occurrences }) => {
    return {
      id: getCalendarEventId({
        eventId: id,
        occurrenceId: occurrenceId || (occurrences && occurrences[0] && occurrences[0][0].id),
      }),
    };
  });

  deleteBatchCalendarEvents(calendarEvents);
}

export function setWorkshopCalendarConfirmation({
  adminOrOwner,
  workshopEventsRef,
  setGoogleDialog,
  setDialog,
}) {
  const onConfirm = () =>
    setAddToCalendarWorkshop(setGoogleDialog, workshopEventsRef, adminOrOwner);
  setDialogCalendar(setDialog, onConfirm);
}

export function setBatchEventCalendarConfirmation({
  adminOrOwner,
  eventId,
  occurrences,
  duration,
  title,
  summary,
  setGoogleDialog,
  setDialog,
}) {
  const onConfirm = () =>
    setAddToCalendarBatch({
      setGoogleDialog,
      adminOrOwner,
      eventId,
      occurrences,
      duration,
      title,
      summary,
    });
  setDialogCalendar(setDialog, onConfirm);
}

function getCalendarObject({
  adminOrOwner,
  eventId,
  occurrenceId,
  date,
  duration,
  title,
  summary,
  userTimeZone,
}) {
  return {
    id: getCalendarEventId({ eventId: eventId, occurrenceId: occurrenceId }),
    summary: title,
    location: getEventUrl(eventId, occurrenceId, adminOrOwner, true),
    description: summary,
    start: {
      dateTime: moment(date).toISOString(),
      timeZone: userTimeZone,
    },
    end: {
      dateTime: moment(date).add(duration, 's').toISOString(),
      timeZone: userTimeZone,
    },
    recurrence: [
      // 'RRULE:FREQ=DAILY;COUNT=2'
    ],
    attendees: [],
    reminders: {
      useDefault: false,
      overrides: [
        { method: 'email', minutes: 15 },
        { method: 'popup', minutes: adminOrOwner ? 15 : 10 },
      ],
    },
  };
}

export function addGoogleCalendarBatchEvents({
  adminOrOwner,
  eventId,
  occurrences,
  duration,
  title,
  summary,
}) {
  if (!occurrences || !occurrences.length) {
    return;
  }

  const userTimeZone = getUserTimeZone();
  const liveEvents = getFutureEvents({
    events: occurrences,
    filterVodEvents: true,
    isLiveEvent: true,
  });
  const calendarEvents = liveEvents.map(({ date, id }) => {
    return getCalendarObject({
      adminOrOwner,
      eventId: eventId,
      occurrenceId: id,
      title: title,
      date: date,
      duration: duration,
      summary: summary,
      userTimeZone: userTimeZone,
    });
  });

  addGoogleCalendarBatchEventsGapi(calendarEvents);
}

export function addGoogleCalendarBatchWorkshopEvents(workshopEvents, adminOrOwner) {
  if (!workshopEvents || !workshopEvents.length) {
    return;
  }

  const userTimeZone = getUserTimeZone();
  const liveEvents = getFutureEvents({
    events: workshopEvents,
    filterVodEvents: true,
  });

  const calendarEvents = liveEvents.map(
    ({ date, duration, id, occurrenceId, occurrences, title, summary }) => {
      return getCalendarObject({
        adminOrOwner,
        eventId: id,
        occurrenceId: occurrenceId || (occurrences && occurrences[0] && occurrences[0][0].id),
        title: title,
        date: date || (occurrences && occurrences[0] && occurrences[0][0].date),
        duration: duration,
        summary: summary,
        userTimeZone: userTimeZone,
      });
    }
  );

  addGoogleCalendarBatchEventsGapi(calendarEvents);
}
