// import { createCachedSelector } from 're-reselect';

// export const getUserEventRegistrations = state => state.userEventRegistrations.rows;
export const getUserEventRegistrationsReqParams = (state) => state.userEventRegistrations.reqParams;

export const getUserEventRegistrations = (state) => {
  // console.log('getUserEventRegistrations', state.userEventRegistrations.rows);
  return state.userEventRegistrations.rows;
};

// export const getEventRegistration = createCachedSelector(
//   getUserEventRegistrations,
//   (state, eventId) => {
//     console.log('eventId', eventId);
//     return eventId;
//   },
//   (eventRegistrations, eventId) => {
//     console.log('eventRegistrations, eventId', eventRegistrations, eventId);
//     return (eventRegistrations || []).filter((item) => +item.EventId === +eventId);
//   }
// )((state, eventId) => eventId);

function getEventRegistrationFunc(eventRegistrations, eventId) {
  return (eventRegistrations || []).filter((item) => +item.EventId === +eventId);
}

function getEventRegistration(state, eventId) {
  const eventRegistrations = getUserEventRegistrations(state);
  return (eventRegistrations || []).filter((item) => +item.EventId === +eventId);
}

// const getEventRegistration = createCachedSelector(
//   // inputSelectors
//   getUserEventRegistrations,
//   (state, eventId) => {
//     console.log('eventId', eventId);
//     return 2;
//   },
//   // resultFunc
//   (eventRegistrations, eventId) => getEventRegistrationFunc(eventRegistrations, eventId)
// )(
//   // re-reselect keySelector (receives selectors' arguments)
//   // Use "libraryName" as cacheKey
//   (_state_, eventId) => eventId
// );

export { getEventRegistration };
