import React from 'react';
import PropTypes from 'prop-types';
//  import styled from 'styled-components';
import {connect} from 'react-redux';

import {getAuthIsLoggedIn} from '../../reduxUtils/auth/selectors';
import {getUserId} from '../../reduxUtils/user/selectors';
import {getUserFullName, getUserAvatarThumbnail} from '../../reduxUtils/userProfile/selectors';
import {getBrowserIsMobile, getBrowserIsLeftBarOpened} from '../../reduxUtils/browserSettings/selectors';

import WithSideBar from 'layouts/WithSideBar';


function FriendsActivity({location, isMobile, isLoggedIn, isLeftBarOpened, userId, fullName, avatar}) {
  return (
    <WithSideBar
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
    >
      This is page content
    </WithSideBar>
  );
}

FriendsActivity.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state)
  };
}

//  function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, null)(FriendsActivity);
