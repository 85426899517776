import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';

import { getEventBaseUrl } from 'utilities/event';
import { getDecodedRedirectPath } from 'utilities/general';

import { getAuthIsLoggedIn } from '../../../../reduxUtils/auth/selectors';
import { getUserId } from '../../../../reduxUtils/user/selectors';
import { getUserAccessControl } from '../../../../reduxUtils/accessControl/selectors';
import { getBrowserIsMobile } from '../../../../reduxUtils/browserSettings/selectors';
import {
  getUserFullName,
  getUserAvatarThumbnail,
} from '../../../../reduxUtils/userProfile/selectors';
import { getEvent } from '../../../../reduxUtils/event/selectors';
import { getFileUploader } from '../../../../reduxUtils/fileUploader/selectors';
import { getEventOccurrences } from '../../../../reduxUtils/eventOccurrences/selectors';
import { getEventEager, startEvent } from '../../../../reduxUtils/event/thunks';

import WithSideBar from 'layouts/WithSideBar';

import MetaDecorator from 'components/MetaDecorator';
import Fallback from 'components/Fallback';
import { getIsChatDetach, getIsChatMinimized } from '../../../../reduxUtils/chat/selectors';
import { getUserSettings } from '../../../../reduxUtils/userSettings/selectors';

const Edit = lazy(() => import('./Edit'));

function EventEdit({
  match,
  location,
  isMobile,
  isLoggedIn,
  userId,
  fullName,
  avatar,
  event,
  accessControl,
  userSettings,
  fileUploader,
  occurrences,
  getEventEager,
  isChatMinimized,
  isChatDetach,
}) {
  const navigate = useNavigate();

  let { workshopId, eventId } = useParams();

  const firstRedirectTo = getDecodedRedirectPath();
  const [redirectTo, setRedirectTo] = useState('');
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isLeftBarOpened, setIsLeftBarOpened] = useState(true);

  const [menuOptions, setMenuOptions] = useState([
    {
      label: 'Home',
      iconName: 'fas fa-home',
      linkTo: '/',
    },
    {
      label: 'Details',
      iconName: 'fas fa-info-circle',
      linkTo: `event-details`,
    },
    {
      label: 'Schedule',
      iconName: 'far fa-clock',
      linkTo: `event-schedule`,
    },
    {
      label: 'Settings',
      iconName: 'fas fa-cog',
      linkTo: `event-settings`,
    },
  ]);

  useEffect(() => {
    if (!eventId) {
      setRedirectToHome(true);
    }
  }, [eventId]);

  useEffect(() => {
    if (firstRedirectTo) {
      setRedirectTo(firstRedirectTo);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    changeMenuSchedule(event.isLive);
  }, [event.isLive]);

  function onIsLeftBarChange() {
    setIsLeftBarOpened(!isLeftBarOpened);
  }

  function changeMenuSchedule(add) {
    setMenuOptions((current) => {
      const newMenu = [...current];
      newMenu[2].ignore = !add;
      // newMenu[3].ignore = !add;
      return newMenu;
    });
  }

  function onMenuChange(id, updatedMenu) {
    setMenuOptions((current) => {
      const newMenu = [...current];
      if (newMenu[id] && !newMenu[id].ignore) {
        newMenu[id] = { ...newMenu[id], ...updatedMenu };
      }
      return newMenu;
    });
  }

  if (redirectToHome) {
    return <Navigate to="/" />;
  }

  const isWorkshopEvent = !!workshopId;

  return (
    <>
      <MetaDecorator
        url={getEventBaseUrl(eventId, true)}
        title={`${event.title} | ${isWorkshopEvent ? 'workshop event' : 'event'} (edit)`}
        description={`Create new ${
          isWorkshopEvent
            ? 'live or vod workshop event'
            : "live or vod event, paid or free it's up to you"
        }`}
      ></MetaDecorator>
      <WithSideBar
        location={location}
        options={[...menuOptions].filter(({ ignore }) => !ignore)}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        setLocalIsLeftBarOpened={onIsLeftBarChange}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <Suspense fallback={<Fallback />}>
          <Edit
            isMobile={isMobile}
            navigate={navigate}
            match={match}
            userId={userId}
            event={event}
            accessControl={accessControl}
            userSettings={userSettings}
            fileUploader={fileUploader}
            occurrences={occurrences}
            getEventEager={getEventEager}
            onMenuChange={onMenuChange}
            redirectTo={redirectTo}
          />
        </Suspense>
      </WithSideBar>
    </>
  );
}

EventEdit.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  isLeftBarOpened: PropTypes.bool,
  event: PropTypes.object,
  accessControl: PropTypes.array,
  fileUploader: PropTypes.object,
  occurrences: PropTypes.array,
  getEventEager: PropTypes.func,
  startEvent: PropTypes.func,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  const userId = getUserId(state);
  let accessControl = [];
  if (userId) {
    accessControl = getUserAccessControl(state, userId);
  }
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    userId,
    event: getEvent(state),
    accessControl,
    userSettings: getUserSettings(state),
    fileUploader: getFileUploader(state),
    occurrences: getEventOccurrences(state),
    isChatMinimized: getIsChatMinimized(state),
    isChatDetach: getIsChatDetach(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEventEager,
      startEvent,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);
