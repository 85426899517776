import React from 'react';
import styled from 'styled-components';
import EmptyModal from '../EmptyModal';
import PropTypes from 'prop-types';
import Button from 'components/Button';

import theme from 'styles/theme';
import {scale} from 'styles/common';

const ConfirmationText = styled.span`
  white-space: pre-line;
  color: ${({theme}) => theme.colors.darkGrey};
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]]
  ])}

`;

const Children = styled.span`
  color: ${({theme}) => theme.colors.darkGrey};

`;

const ContentTitleText = styled.span`
  overflow: hidden;
  font-weight: 500;
  color: ${({theme}) => theme.colors.darkGrey};
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]]
  ])}

`;

const ConfirmationActions = styled.div`
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
`;

const ConfirmationActionsButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }

  padding-top: 5px;
  padding-bottom: 5px;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}
`;

function ConfirmationModal(
  {
    onHide = null,
    show,
    title,
    contentTitle = '',
    confirmationText,
    isLoading,
    cancelButtonText = 'CANCEL',
    cancelButtonIcon = '',
    onCancel,
    confirmButtonText = 'CONFIRM',
    confirmButtonIcon = '',
    onConfirm,
    children,
    ...props
  }
) {

  return (
    <EmptyModal
      onHide={onHide}
      dialogClassName='custom-width-dialog'
      centered
      show={show}
      title={title}
      displayCloseButton
      // animation={false}
      {...props}
    >
      {
        confirmationText &&
        (
          <ConfirmationText dir={'auto'}>{confirmationText}</ConfirmationText>
        )
      }
      {
        contentTitle &&
        (
          <ContentTitleText dir={'auto'}>{contentTitle}</ContentTitleText>
        )
      }

      {
        children &&
        <Children>{children}</Children>
      }
      <ConfirmationActions>
        <ConfirmationActionsButton
          backIconName={cancelButtonIcon}
          label={cancelButtonText}
          isLoading={isLoading}
          buttonStyle='buttonLabel'
          onClick={onCancel}
        />
        <ConfirmationActionsButton
          backIconName={confirmButtonIcon}
          label={confirmButtonText}
          isLoading={isLoading}
          buttonStyle='generalPurple'
          onClick={onConfirm}
        />
      </ConfirmationActions>
    </EmptyModal>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  confirmationText: PropTypes.string,
  isLoading: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonIcon: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonIcon: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onHide: PropTypes.func
};

export default ConfirmationModal;