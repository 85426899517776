import {createAction} from 'redux-actions';
import {
  SET_HOME_WORKSHOPS_SUGGESTION1,
  ADD_HOME_WORKSHOP_SUGGESTION1,
  ADD_HOME_WORKSHOPS_SUGGESTION1,
  UPDATE_HOME_WORKSHOPS_SUGGESTION1,
} from './types';

export const setHomeWorkshopsSuggestion1 = createAction(SET_HOME_WORKSHOPS_SUGGESTION1);
export const addHomeWorkshopSuggestion1 = createAction(ADD_HOME_WORKSHOP_SUGGESTION1);
export const addHomeWorkshopsSuggestion1 = createAction(ADD_HOME_WORKSHOPS_SUGGESTION1);
export const updateHomeWorkshopsSuggestion1 = createAction(UPDATE_HOME_WORKSHOPS_SUGGESTION1);
