import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

const IconElement = styled.i``;

function Icon({className, iconName, fontAwesomeIcon, ...props}) {
  return (
    <IconWrap className={className} {...props} >
      {
        iconName &&
        <IconElement className={iconName} />
      }
      {
        fontAwesomeIcon &&
        <FontAwesomeIcon icon={fontAwesomeIcon} />
      }
    </IconWrap>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  fontAwesomeIcon: PropTypes.object
};
export default Icon;