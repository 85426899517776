import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import ResizeableTextBox from "components/ResizeableTextBox";
import Icon from "components/Icon";
import {
  OptionBoxCss,
  OptionBoxCssInner,
  OptionImageCss
} from "components/Chat/components/PollsContainer/components/JSXElements";
import {ACCEPTED_IMAGES} from "config/constants";
import {debounceFunction} from "utilities/general";
import Compressor from "compressorjs";
import {errorHandler} from "utilities/services";
import UploadImage from "components/UploadImage";
import {POLL_OPTION_MAX_INPUT} from "utilities/polls.const";

const Wrap = styled.div`
  padding-bottom: 5px;

`;

const StyleResizeableTextBox = styled(ResizeableTextBox)`
  ${OptionBoxCss};
  ${OptionBoxCssInner};

  .TextArea {
    color: inherit;
    font-weight: inherit;
    background-color: inherit;
  }

`;

const Delete = styled(Icon)`
  color: ${({theme: {colors}}) => colors.grayNew};
  cursor: pointer;
  font-size: 12px;
  margin-top: 3px;

  &:hover {
    color: ${({theme: {colors}}) => colors.darkGrey};
  }

  &:active {
    color: ${({theme: {colors}}) => colors.dark};
  }
`;


const ImageIcon = styled(Icon)`

`;

const UploadImageContainer = styled(UploadImage)`
  font-size: 15px;
  ${OptionImageCss};
  margin-right: 5px;
  margin-left: 5px;
  background-color: #ece9e9;

  &:hover {
    background-color: #dadada;
  }
`;

function ImagePlacement() {
  return <ImageIcon
    iconName={'fas fa-image'}
  />

}


function OptionInput({
                       className,
                       value = '',
                       image = '',
                       onDelete,
                       onUpdate,
                       isLast,
                       addNewOption,
                     }) {
  const clearImageRef = useRef(null);

  function updateImage(file) {

    new Compressor(file, {
      maxWidth: 75,
      maxHeight: 75,
      quality: 0.92,
      checkOrientation: false,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        onUpdate({imageData: result});
      },
      error(err) {
        errorHandler(err.message)
        console.log(err.message);
      },
    });

  }

  const [input, setInput] = useState(value);

  function onInput(inputText) {
    setInput(inputText);
    debounceFunction(() => {
      onUpdate({text: inputText});
    }, 500)
    if (isLast) {
      addNewOption();
    }
  }

  return (
    <Wrap className={className}>
      <StyleResizeableTextBox
        onInput={onInput}
        placeholder={'Add option'}
        maxLength={POLL_OPTION_MAX_INPUT}
        value={input}
        onBlur={() => onUpdate({text: input})}
        // onEnterClick={() => {
        //   onSendMessageClick(currentMessage, setCurrentMessage);
        //   inputRef.current.focus();
        // }}
      >
        <UploadImageContainer
          ImagePlacement={ImagePlacement}
          acceptImages={ACCEPTED_IMAGES}
          initialImg={image}
          clearImageRef={clearImageRef}
          update={updateImage}
        />
        <Delete onClick={onDelete} iconName='fas fa-times' />

      </StyleResizeableTextBox>
    </Wrap>
  );
}

OptionInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  isLast: PropTypes.bool,
  addNewOption: PropTypes.func,
};

export default (React.memo(OptionInput));