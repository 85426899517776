import CurrenciesService from 'services/currencies';

import {
  setIsFxReady
} from './actions';

import {setRates} from 'utilities/fx';

const currenciesService = new CurrenciesService();
let lastFetch = null;

export function getRates() {
  if (lastFetch) {
    const timeDiff = (Date.now() - lastFetch) / (60 * 1000); //in minutes
    if (timeDiff < 60) {
      return dispatch => Promise.resolve();
    }
  }
  return dispatch => currenciesService
    .getRates()
    .then((response) => {
      const {data: {rates}} = response;
      if (rates) {
        lastFetch = Date.now();
        setRates(rates);
        dispatch(setIsFxReady(true));
      }
      return response;
    })
    .catch(err => {
      console.log(`get rates error`, err)
    });
}