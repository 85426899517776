import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChannelService from 'services/channel';
import { errorHandler } from 'utilities/services';

import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import { getUser, getUserId } from '../../reduxUtils/user/selectors';
import { getUserProfile } from '../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
  getBrowserDimensions,
} from '../../reduxUtils/browserSettings/selectors';
import { getRates } from '../../reduxUtils/browserSettings/thunks';

import WithSideBar from 'layouts/WithSideBar';
import MetaDecorator from 'components/MetaDecorator';
import { generateFullName, getChannelBaseUrl } from 'utilities/general';
import { setAllWorkshopRegistrations } from '../../reduxUtils/userWorkshopRegistrations/thunks';
import { getUserWorkshopRegistrationsReqParams } from '../../reduxUtils/userWorkshopRegistrations/selectors';
import { getAccessControl } from '../../reduxUtils/accessControl/selectors';

import Fallback from 'components/Fallback';
import { setAllEventRegistrations } from '../../reduxUtils/userEventRegistrations/thunks';
import { getUserEventRegistrationsReqParams } from '../../reduxUtils/userEventRegistrations/selectors';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';
// import Channel from './Channel';
// const Channel = './Channel.js';
const Channel = lazy(() => import('./Channel'));
const channelService = new ChannelService();

function ChannelWrap({
  navigate,
  match,
  location,
  isMobile,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  userProfile,
  userSettings,
  dimensions,
  accessControls,
  getRates,
  setAllWorkshopRegistrations,
  setAllEventRegistrations,
  userEventRegistrationsReqParams,
  userWorkshopRegistrationsReqParams,
  isChatMinimized,
  isChatDetach,
}) {
  let { channelId } = useParams();

  const [pristine, setPristine] = useState(true);
  const [channel, setChannel] = useState({
    avatarThumbnail: '',
    fullName: '',
    rating: 0,
    ratingCount: 0,
    followersCount: 0,
  });

  const { avatarThumbnail } = userProfile;
  const fullName = generateFullName(userProfile);
  useEffect(() => {
    if (!!+channelId) {
      if (+channelId === +userId) {
        setChannel({ ...userProfile, id: channelId, fullName: fullName });
        setPristine(false);
      } else {
        channelService
          .getChannel(channelId)
          .then((response) => {
            const {
              data: { channel },
            } = response;
            setChannel(channel);
            return response;
          })
          .catch((err) => {
            errorHandler(err);
          })
          .finally((err) => {
            setPristine(false);
          });
      }
    } else {
      setPristine(false);
    }
  }, [channelId]); // eslint-disable-line

  const userAvatar = channel.avatar;
  const channelFullName = channel.fullName;

  const channelUrl = getChannelBaseUrl(channelId, false, true);

  return (
    <>
      <MetaDecorator
        url={channelUrl}
        image={userAvatar}
        title={channelFullName}
        titleSuffix={' - Livly.tv'}
        description={`${channelFullName} channel - check out events and workshop, live or vod`}
      ></MetaDecorator>
      <WithSideBar
        location={location}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatarThumbnail}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <Suspense fallback={<Fallback />}>
          <Channel
            navigate={navigate}
            match={match}
            channelId={channelId}
            pristine={pristine}
            channel={channel}
            isLoggedIn={isLoggedIn}
            isMobile={isMobile}
            userId={userId}
            userSettings={userSettings}
            dimensions={dimensions}
            accessControls={accessControls}
            getRates={getRates}
            setAllWorkshopRegistrations={setAllWorkshopRegistrations}
            setAllEventRegistrations={setAllEventRegistrations}
            userEventRegistrationsReqParams={userEventRegistrationsReqParams}
            userWorkshopRegistrationsReqParams={userWorkshopRegistrationsReqParams}
            isChatMinimized={isChatMinimized}
          />
        </Suspense>
      </WithSideBar>
    </>
  );
}

ChannelWrap.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  userSettings: PropTypes.object,
  userProfile: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  dimensions: PropTypes.object,
  accessControls: PropTypes.array,
  getRates: PropTypes.func,
  setAllWorkshopRegistrations: PropTypes.func,
  setAllEventRegistrations: PropTypes.func,
  userEventRegistrationsReqParams: PropTypes.object,
  userWorkshopRegistrationsReqParams: PropTypes.object,
  isChatDetach: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    user: getUser(state),
    userSettings: getUserSettings(state),
    userProfile: getUserProfile(state),
    dimensions: getBrowserDimensions(state),
    accessControls: getAccessControl(state),
    userEventRegistrationsReqParams: getUserEventRegistrationsReqParams(state),
    userWorkshopRegistrationsReqParams: getUserWorkshopRegistrationsReqParams(state),
    isChatMinimized: getIsChatMinimized(state),
    isChatDetach: getIsChatDetach(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRates,
      setAllWorkshopRegistrations,
      setAllEventRegistrations,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelWrap);
