import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {InfoIconFontSizeCss} from 'styles/common';

const InfoButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const InfoButtonIcon = styled.i`
  color: ${({theme: {colors}}) => colors.grayNew};
  ${InfoIconFontSizeCss};
`;

const SettingsItemToolTip = styled(Tooltip)`
`;

function InfoButton({className, infoText}) {
  const renderTooltip = (props) => (
    <SettingsItemToolTip id="styled-text" {...props}>
      {infoText}
    </SettingsItemToolTip>
  );

  return (
    <InfoButtonWrap className={className}>
      <OverlayTrigger
        placement="auto"
        overlay={renderTooltip}
      >
        <InfoButtonIcon className="far fa-question-circle"/>
      </OverlayTrigger>
    </InfoButtonWrap>
  );
}

InfoButton.propTypes = {
  infoText: PropTypes.string
};

export default InfoButton;