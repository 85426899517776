import {handleActions} from 'redux-actions';
import {unionWith} from 'lodash';
import {
  SET_TRANSACTIONS,
  ADD_TRANSACTION,
  ADD_TRANSACTIONS,
  DELETE_TRANSACTION,
  UPDATE_TRANSACTION, UPSERT_TRANSACTION
} from './types';
import {LOGOUT} from "../auth/types";

const comparator = (itemA, itemB) => {
  return itemA.id === itemB.id;
};

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_TRANSACTIONS]: (state, {payload}) => {

    if (!payload) {
      return state;
    }

    const unionArr = unionWith(state.rows, payload.rows, comparator);

    if (unionArr.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      reqParams: {setAll: true},
      rows: unionArr
    };
  },
  [ADD_TRANSACTIONS]: (state, {payload}) => {
    if (!payload) {
      return state
    }

    const unionArr = unionWith(state.rows, payload, comparator);

    if (unionArr.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      ...payload,
      rows: unionArr
    };
  },
  [UPSERT_TRANSACTION]: (state, {payload}) => {
    if (!payload) {
      return state
    }
    const foundIndex = state.rows.findIndex((transaction) => transaction.id === payload.id);
    if (foundIndex === -1) {
      const transactions = [...state.rows];
      transactions.push({...payload});

      return {
        ...state,
        rows: transactions
      };
    } else {
      const transactions = [...state.rows];
      transactions[foundIndex] = {...transactions[foundIndex], ...payload};

      return {
        ...state,
        rows: transactions
      };
    }
  },
  [ADD_TRANSACTION]: (state, {payload}) => {
    if (!payload) {
      return state
    }
    const foundIndex = state.rows.findIndex((transaction) => transaction.id === payload.id);
    if (foundIndex === -1) {
      const transactions = [...state.rows];
      transactions.push({...payload});

      return {
        ...state,
        rows: transactions
      };
    }

    return state;
  },
  [DELETE_TRANSACTION]: (state, {payload}) => {
    const foundIndex = state.rows.findIndex((transaction) => {
      return transaction.id === payload
    });

    if (foundIndex > -1) {
      const transactions = [...state.rows];
      transactions.splice(foundIndex, 1);
      return {
        ...state,
        rows: transactions
      };
    }

    return state;
  },

  [UPDATE_TRANSACTION]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    const foundIndex = state.rows.findIndex((transaction) => {
      if (payload.id && transaction.id && transaction.id === payload.id) {
        return true;
      }

      const {eventId, occurrenceId, workshopId} = payload;
      if (eventId) {
        if ((transaction.eventId) && transaction.eventId === eventId) {
          if (occurrenceId) {
            return transaction.occurrenceId === occurrenceId
          }
          return true
        }
      }
      return !!((workshopId && transaction.workshopId) && transaction.workshopId === workshopId);

    });

    if (foundIndex > -1) {
      const transactions = [...state.rows];
      transactions[foundIndex] = {...transactions[foundIndex], ...payload}
      return {
        ...state,
        rows: transactions
      };
    }

    return state;
  },

  [LOGOUT]: () => {
    return initialState;
  }
}, initialState);