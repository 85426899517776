import React from 'react';
//  import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledAvatar from 'components/StyledAvatar';
import PropTypes from 'prop-types';

const UserMenuButtonWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

function UserMenuButton({userFullName = '', avatar = '', onClick = null}) {
  return (
    <UserMenuButtonWrap onClick={onClick}>
      <StyledAvatar name={userFullName} url={avatar}/>
    </UserMenuButtonWrap>
  );
}

UserMenuButton.propTypes = {
  userFullName: PropTypes.string,
  avatar: PropTypes.string,
  onClick: PropTypes.func,
};

export default UserMenuButton;
