import React, {useEffect, useRef} from 'react';
import {
  CardElement,
} from '@stripe/react-stripe-js';

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js'

import styled, {css} from 'styled-components';

import PropTypes from 'prop-types';

import StyledTextInput from 'components/Inputs/StyledTextInput';
import StyledSwitchInput from 'components/Inputs/StyledSwitchInput';
import {ErrorFontSizeCss} from "styles/common";
import {validator} from "utilities/general";
import PhoneNumberInput from "components/PhoneNumberInput";
import {getCountryOfClient} from "utilities/location";

const Section = styled.div`
  margin-bottom: 3px;
`;

const StyledTextInputWrap = styled(StyledTextInput)`
  font-size: 16px;
  height: auto;
`;

const StyleInputCss = css`
  font-size: 16px !important;
`;

const InputFieldCss = css`
  height: auto !important;
`;

const PhoneNumberWrap = styled.div`
  font-size: 16px;
  margin-bottom: 0.5em;
`;

const Feedback = styled.div`
  color: ${({theme: {colors}}) => colors.error};
  font-weight: 400;
  ${ErrorFontSizeCss};
`;

// const appearance = {
//   theme: 'stripe'
// };

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      iconColor: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

function AddPayment(
  {
    errors,
    setErrors,
    billingDetails,
    setBillingDetails,
    savePaymentMethod,
    setSavePaymentMethod,
    setDisabledAddPayment,
    setCardErrorResponse,
  }
) {
  const cardComplete = useRef(false);

  const updateError = (name, value) => {
    setErrors((currentValue) => {
      return {
        ...currentValue,
        [name]: value
      }
    });
  };

  const handlePersonalChange = ({target: {name, value}}) => {
    setBillingDetails((currentValue) => {
      return {
        ...currentValue,
        [name]: value
      }
    });
  };

  // const handleAddressChange = ({target: {name, value}}) => {
  //   setBillingDetails((currentValue) => {
  //     return {
  //       ...currentValue,
  //       address: {
  //         ...currentValue.address,
  //         [name]: value
  //       }
  //     }
  //   });
  // };

  const handleCardChange = async (event) => {
    setCardErrorResponse(null);
    cardComplete.current = event.complete;
    if (event.complete) {
      checkForErrors();
    }

    setDisabledAddPayment(!event.complete);
    updateError('card', event.error ? event.error.message : null);
  };

  function isNameValid(e) {
    const name = e ? e.target && e.target.value : billingDetails.name;

    const isValid = !!name;
    if (!isValid) {
      updateError('name', 'Full name is required');
    } else {
      updateError('name', null);
    }

    return isValid;
  }

  function isEmailValid(e) {
    const email = e ? e.target && e.target.value : billingDetails.email;
    const isValid = (email && validator.isEmail(email));
    if (!isValid) {
      updateError('email', 'Invalid email');
    } else {
      updateError('email', null);
    }

    return isValid;
  }

  function isPhoneValid(input = '') {
    let phone = input;

    const isValid = !phone || (phone && isPossiblePhoneNumber(phone) && isValidPhoneNumber(phone));
    if (!isValid) {
      updateError('phone', 'Invalid Phone number');
    } else {
      updateError('phone', '');
    }

    return isValid;
  }

  function checkForErrors() {
    isPhoneValid(billingDetails.phone);
    isEmailValid();
    isNameValid();
  }

  useEffect(() => {
    setDisabledAddPayment(true);
  }, [setDisabledAddPayment]);

  // const {address} = billingDetails;
  // const stripe = useStripe();

  // const paymentElementOptions = {
  //   layout: "tabs"
  // }

  return (
    <Section>
      <StyledTextInputWrap
        placeholder='Email'
        name='email'
        value={billingDetails.email}
        onChange={(e) => {
          isEmailValid(e);
          handlePersonalChange(e)
        }}
        error={errors.email}
        onBlur={isEmailValid}
        inputStyleCss={StyleInputCss}
        inputFieldCss={InputFieldCss}
      />

      <StyledTextInputWrap
        placeholder='Cardholder name'
        name='name'
        value={billingDetails.name}
        onChange={(e) => {
          isNameValid(e);
          handlePersonalChange(e)
        }}
        error={errors.name}
        onBlur={isNameValid}
        inputStyleCss={StyleInputCss}
        inputFieldCss={InputFieldCss}
      />

      <PhoneNumberWrap>
        <PhoneNumberInput
          error={!!errors.phone}
          enableSearch
          country={getCountryOfClient()}
          placeholder='Mobile phone number'
          value={billingDetails.phone}
          onChange={(value) => {
            if (value?.length > 0 && value[0] !== '+') {
              value = '+' + value;
            }
            const e = {target: {name: 'phone', value: value ? value : ''}};
            isPhoneValid(value);
            handlePersonalChange(e);
          }}

        />
        {errors.phone && <Feedback>{errors.phone}</Feedback>}
      </PhoneNumberWrap>

      {/*<StyledTextInput*/}
      {/*  placeholder="Street address or P.O. Box"*/}
      {/*  name="line1"*/}
      {/*  value={address.line1}*/}
      {/*  onChange={handleAddressChange}*/}
      {/*/>*/}
      {/*<StyledTextInput*/}
      {/*  placeholder="Apartment, suite, unit, building, floor, etc."*/}
      {/*  name="line2"*/}
      {/*  value={address.line2}*/}
      {/*  onChange={handleAddressChange}*/}
      {/*/>*/}
      {/*<StyledTextInput*/}
      {/*  placeholder="City"*/}
      {/*  name="city"*/}
      {/*  value={address.city}*/}
      {/*  onChange={handleAddressChange}*/}
      {/*/>*/}
      {/*<StyledTextInput*/}
      {/*  placeholder="State"*/}
      {/*  name="state"*/}
      {/*  value={address.state}*/}
      {/*  onChange={handleAddressChange}*/}
      {/*/>*/}
      <CardElement
        id='card-element'
        options={cardStyle}
        onChange={handleCardChange}
      />
      {/*if we want to change to stripe PaymentElement <PaymentElement id='payment-element' options={paymentElementOptions} />*/}
      <StyledSwitchInput
        label='Save Payment Method'
        onChange={setSavePaymentMethod}
        value={savePaymentMethod}
      />
    </Section>
  );
}


AddPayment.propTypes = {
  UserId: PropTypes.number,
  email: PropTypes.string,
  fullName: PropTypes.string,
  mobilePhoneNumber: PropTypes.string,
  eventId: PropTypes.number,
  workshopId: PropTypes.number,
  occurrenceId: PropTypes.number,
  isProcessing: PropTypes.bool,
};

export default AddPayment;
