import {
  addHomeFollowings, deleteHomeFollowingsAction
} from './actions';

import FollowingService from 'services/following';
import {errorHandler} from 'utilities/services';

const followingService = new FollowingService();

export function fetchHomeFollowings(query) {
  const {queryReq, properties} = query;

  return dispatch => followingService
    .getFollowingsTile(queryReq)
    .then((response) => {
      const {data: {followingsTile}} = response;
      properties.reqParams = query.reqParams;
      dispatch(addHomeFollowings({followingsTile, properties}));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });

}

export function deleteHomeFollowings(payload) {
  return dispatch => dispatch(deleteHomeFollowingsAction(payload));
}