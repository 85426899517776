import mixpanel from 'mixpanel-browser';

import {
  dlOnStreamExit,
  dlOnStreamPlay,
  dlOnStreamProgress,
  pushChannelEventToDataLayer,
  pushEventToDataLayer, pushPageViewDataLayer, pushShareToDataLayer,
  pushToDataLayer, pushWorkshopToDataLayer
} from 'utilities/dataLayer';

import {convertCurrency} from 'utilities/fx';
import {getLCP, getFID, getCLS, getTTFB, getFCP} from 'web-vitals/base';
import {MIX_PANEL_API_KEY} from 'config/config';

mixpanel.init(MIX_PANEL_API_KEY, {debug: false, ignore_dnt: true});

export function getDeviceInfo() {
  //const nav = /** @type {null | (Navigator & { deviceMemory: number, connection: NetworkInformation })} */
  const nav = typeof navigator === 'undefined'
    ? null
    : navigator
  const conn = nav && nav.connection ? nav.connection : null
  return {
    url: window.location ? window.location.href : null,
    referrer: document ? document.referrer : null,
    userAgent: nav ? nav.userAgent : null,
    memory: nav ? nav.deviceMemory : undefined,
    cpus: nav ? nav.hardwareConcurrency : undefined,
    connection: conn ? {effectiveType: conn.effectiveType, rtt: conn.rtt, downlink: conn.downlink} : undefined,
  }
}

function parseMetric(metric) {
  switch (metric.name) {
    // capture LCP element and its size
    case 'LCP': {
      const entry = metric.entries[metric.entries.length - 1] // use the last
      return {
        largestContentfulPaint: Math.round(metric.value),
        largestContentfulPaintName: entry.name,
        // largestContentfulElement: entry.element,
        largestContentfulElementSize: entry.size,
        LCPScore: metric.value < 2500 ? 'good' : metric.value < 4500 ? 'needs improvement' : 'poor'
      }
    }

    // capture cumulative/largest/total layout shift
    case 'CLS': {
      return {
        cumulativeLayoutShift: metric.value.toFixed(2),
        largestLayoutShift: Math.max(...metric.entries.map((e) => e.value)).toFixed(2),
        totalLayoutShifts: metric.entries.length,
        CLSScore: metric.value < 0.1 ? 'good' : metric.value < 0.25 ? 'needs improvement' : 'poor'
      }
    }

    // report more information about first input
    case 'FID': {
      const entry = metric.entries[0]
      return {
        firstInputDelay: Math.round(metric.value),
        firstInputName: entry.name,
        firstInputStartTime: Math.round(entry.startTime),
        FIDScore: metric.value < 100 ? 'good' : metric.value < 300 ? 'needs improvement' : 'poor'
      }
    }

    // report more information about first input
    case 'FCP': {
      const entry = metric.entries[0]
      return {
        firstContentfulPaint: Math.round(metric.value),
        firstContentfulPaintName: entry.name,
        firstContentfulPaintStartTime: Math.round(entry.startTime),
        FCPScore: metric.value < 2000 ? 'good' : metric.value < 4000 ? 'needs improvement' : 'poor'
      }
    }

    case 'TTFB': {
      const entry = metric.entries[0]
      return {
        timeToFirstByte: Math.round(metric.value),
        timeToFirstByteName: entry.name,
        timeToFirstByteStartTime: entry.startTime,
        // Calculate the request time by subtracting from TTFB
        // everything that happened prior to the request starting.
        timeToFirstByteRequestTime: (metric.value - metric.entries[0].requestStart).toFixed(2),
        TTFBScore: metric.value < 100 ? 'good' : metric.value < 500 ? 'needs improvement' : 'poor'
      }
    }
    // default name –> value mapping
    default:
      return {[metric.name]: metric.value}
  }
}

export function getWebVitals(webVitals) {
  const pushWebVitalsCb = (metric) => {
    webVitals.webVitals = {...webVitals.webVitals, ...parseMetric(metric)};
  };
  if (window.webVitals) {
    getCLS(pushWebVitalsCb);
    getTTFB(pushWebVitalsCb);
    getFID(pushWebVitalsCb);
    getLCP(pushWebVitalsCb);
    getFCP(pushWebVitalsCb);
  }
}

export function getEventItemId(eventId = '', occurrenceId) {
  return `${eventId ? 'e-' + eventId : ''}${occurrenceId ? `_o-${occurrenceId}` : ''}`;
}

export function getWorkshopItemId(workshopId = '') {
  return `${workshopId ? 'w-' + workshopId : ''}`;
}

function appendContentTypeToElement(alEvent, isWorkshop) {
  return `${alEvent}_${isWorkshop ? 'workshop' : 'event'}`;
}

export const AL_EVENT = {
  EVENT: {
    createEvent: 'create',
    registerEvent: 'register',
    unregisterEvent: 'unregister',
  },
  WORKSHOP: {
    createWorkshop: 'create',
    registerWorkshop: 'register',
    unregisterWorkshop: 'unregister',
  },
  CHANNEL: {
    follow: 'follow',
    unfollow: 'unfollow',
  },
  GENERAL: {
    login: 'login',
    signup: 'sign_up',
    refund: 'refund',
    purchase: 'purchase',
    addToCart: 'add_to_cart',
    report: 'report',
    share: 'share',
    pageView: 'page_view_web_vital',
  },
  STREAM: {
    liveStreamCreator: 'live_stream_creator',
    liveStreamUser: 'live_stream_user',
    vodStream: 'vod_stream',
  }
};

export const AL_EVENT_STATUS = {
  success: 'success',
  failed: 'failed',
  complete: 'complete',
};

export const AL_EVENT_ACTION = {
  click: 'click',
  start: 'start',
  finish: 'finish',
  submit: 'submit',
  exit: 'exit',
  publish: 'publish',
  unpublish: 'unpublish',
  progress: 'progress',
};

const WATCH_CONVERSION_TIME = 30; //the minimum seconds user watch for streaming to consider conversion

export function mixpanelIdentify(userId) {
  if (userId) {
    mixpanel.identify(userId);
  } else {
    mixpanel.identify();
  }
}

export function mixpanelRegister({...props}) {
  if (!mixpanel) {
    return;
  }
  mixpanel.register(props);
}

export function alWebVitals(webVitals) {
  mixpanel.track(AL_EVENT.GENERAL.pageView, webVitals);

  pushPageViewDataLayer({
    alEvent: AL_EVENT.GENERAL.pageView,
    webVitals
  })
}

export function alOnStreamProgress({alEvent, streamStatsRef, progressStatusRef, event, embeddedType, userId}) {
  streamStatsRef.current.watchDuration += streamStatsRef.current.playbackRate;
  let playedPercentage = Math.round((streamStatsRef.current.watchDuration / event.duration) * 100);
  if (playedPercentage > 90) {
    playedPercentage = 100;
  }

  const watchDuration = Math.round(streamStatsRef.current.watchDuration);
  if (watchDuration >= WATCH_CONVERSION_TIME && !streamStatsRef.current.watchConversion) {
    streamStatsRef.current.watchConversion = true;
    const trackEvent = `${alEvent}_${AL_EVENT_ACTION.progress}_conversion`
    mixpanel.people.increment(`Total ${trackEvent}`);

    mixpanel.track(trackEvent, {
      eventAction: AL_EVENT_ACTION.progress,
      eventStatus: playedPercentage,
      eventLabel: 'The user watched ' + playedPercentage,
      ...event,
      watchDuration,
      playedPercentage,
      embeddedType,
    });
    dlOnStreamProgress({
      alEvent: `${alEvent}_conversion`,
      eventAction: AL_EVENT_ACTION.progress,
      eventStatus: playedPercentage,
      eventLabel: 'The user watched ' + playedPercentage,
      progressStatusRef,
      event,
      playedPercentage,
      watchDuration,
      embeddedType,
      userId,
    });
  }
  if (progressStatusRef.current[playedPercentage] === false) {
    progressStatusRef.current[playedPercentage] = true;
    const trackEvent = `${alEvent}_${AL_EVENT_ACTION.progress}_${playedPercentage}`
    mixpanel.people.increment(`Total ${trackEvent}`);

    mixpanel.track(trackEvent, {
      eventAction: AL_EVENT_ACTION.progress,
      eventStatus: playedPercentage,
      eventLabel: 'progress',
      ...event,
      watchDuration,
      playedPercentage,
      embeddedType,
    });

    dlOnStreamProgress({
      alEvent,
      progressStatusRef,
      event,
      playedPercentage,
      watchDuration,
      embeddedType,
      userId,
    });
  }
}

export function alOnStreamPlay({alEvent, eventStatus, error, event, embeddedType, userId}) {
  const trackEvent = `${alEvent}_${AL_EVENT_ACTION.start}_${eventStatus}`

  mixpanel.track(trackEvent, {
    eventAction: AL_EVENT_ACTION.start,
    eventStatus,
    eventLabel: 'start stream',
    error,
    ...event,
    embeddedType,
  })

  if (!error) {
    mixpanel.people.increment(`Total ${alEvent}_start`);

    mixpanel.people.set_once([`First ${alEvent}`], new Date().toISOString());

    mixpanel.people.set({
      [`Last ${alEvent}`]: new Date().toISOString()
    });
  }

  dlOnStreamPlay(
    {
      alEvent,
      eventAction: AL_EVENT_ACTION.start,
      eventStatus,
      eventLabel: 'start stream',
      content: event,
      embeddedType,
      userId,
      error,
    })
}


export function alOnStreamExit({alEvent, streamStatsRef, event, embeddedType, userId}) {
  const trackEvent = `${alEvent}_${AL_EVENT_ACTION.exit}_${AL_EVENT_STATUS.success}`
  const watchDuration = Math.round(streamStatsRef.current.watchDuration);

  mixpanel.track(trackEvent, {
    eventAction: AL_EVENT_ACTION.exit,
    eventStatus: AL_EVENT_STATUS.success,
    watchDuration,
    eventLabel: 'start stream',
    ...event,
    embeddedType,
  })

  dlOnStreamExit(
    {
      alEvent,
      eventAction: AL_EVENT_ACTION.exit,
      eventStatus: AL_EVENT_STATUS.success,
      eventLabel: 'exit stream',
      content: event,
      watchDuration,
      embeddedType,
      userId,
    })
}


export function alFollowUnFollow(
  {
    alEvent,
    eventAction,
    eventStatus,
    eventLabel,
    error,
    userId,
    channelId
  }
) {

  if (!error) {
    mixpanel.people.increment(`Total ${alEvent}`);
  }
  const trackEvent = `${alEvent}_${eventAction}_${eventStatus}`
  mixpanel.track(trackEvent, {
    eventAction,
    eventStatus,
    eventLabel,
    error,
    channelId,
  })

  pushChannelEventToDataLayer(
    {
      alEvent,
      eventAction,
      eventStatus,
      eventLabel,
      userId,
      channelId,
      error
    });

}

export function alShare(
  {
    alEvent,
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    content, //the event object
    occurrenceId,
    userId,
    shareMedium,
    isWorkshop,
  }
) {

  const trackEvent = appendContentTypeToElement(alEvent, isWorkshop);

  mixpanel.track(trackEvent, {
    eventAction,
    eventStatus,
    eventLabel,
    ...content,
    occurrenceId: !isWorkshop ? occurrenceId : undefined,
    shareMedium,
  })

  pushShareToDataLayer({
    alEvent,
    eventAction,
    eventStatus,
    eventLabel,
    content,
    occurrenceId,
    userId,
    shareMedium,
    isWorkshop,
  })
}

export function alLogin(
  {
    eventAction,
    eventStatus,
    eventLabel,
    eventCategory,
    loginMethod = 'email',
    error,
    userId,
  }
) {
  const trackEvent = `${AL_EVENT.GENERAL.login}_${eventAction}_${eventStatus}`

  mixpanel.identify(userId);
  mixpanel.people.set(loginMethod);
  mixpanel.track(trackEvent, {
    eventAction: eventAction,
    eventStatus: eventStatus,
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    error: error,
  });

  pushToDataLayer({
    alEvent: AL_EVENT.GENERAL.login,
    eventAction,
    eventStatus,
    eventCategory,
    eventLabel,
    error,

  })
}

export function alSignup(
  {
    eventAction,
    eventStatus,
    eventLabel,
    eventCategory,
    loginMethod = 'email',
    error,
    email,
    phone,
    firstName,
    lastName,
  }
) {
  const fullName = `${firstName} ${lastName}`;
  mixpanel.people.set({
    $name: fullName,
    $first_name: firstName,
    $last_name: lastName,
    $email: email,
    $phone: phone,
    $created: new Date().toISOString(),
    loginMethod,
  });

  const trackEvent = `${AL_EVENT.GENERAL.signup}_${eventAction}_${eventStatus}`
  mixpanel.track(trackEvent, {
    eventAction,
    eventStatus,
    eventCategory,
    eventLabel,
    error,
  });

  pushToDataLayer({
    alEvent: AL_EVENT.GENERAL.signup,
    eventAction,
    eventStatus,
    eventCategory,
    eventLabel,
    error,
  })
}


export function alPurchaseRefund(
  {
    alEvent,
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    content, //the event object
    occurrenceId,
    promotion_id,
    promotion_name,
    creative_name,
    creative_slot,
    affiliation,
    coupon,
    discount = 0,
    payment_type,
    transaction_id,
    quantity = 1,
    tax = 0,
    userId,
    error,
    isWorkshop,
    isRefund
  }
) {
  const {
    price,
    currency,
  } = content;
  const value = ((quantity * price) + tax - discount);
  let valueInUSD = value;

  if (!error) {
    const alEventWithType = appendContentTypeToElement(alEvent, isWorkshop);
    mixpanel.people.increment(`Total Amount ${alEventWithType} ${currency}`, isRefund ? -value : value);
    if (currency !== 'USD') {
      valueInUSD = convertCurrency(value, currency, 'USD');
    }

    if (valueInUSD) {
      mixpanel.people.increment(`Total Amount ${alEventWithType} In USD`, isRefund ? -valueInUSD : valueInUSD);
      mixpanel.people.track_charge(isRefund ? -valueInUSD : valueInUSD, {
        'original_currency': currency,
        'original_amount': value,
        'price': price,
        'exchange_rate': 0
      });
    }
  }

  alPushContent({
    alEvent,
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    content, //the event object
    occurrenceId,
    promotion_id,
    promotion_name,
    creative_name,
    creative_slot,
    affiliation,
    coupon,
    discount,
    payment_type,
    transaction_id,
    value,
    valueInUSD,
    quantity,
    tax,
    userId,
    error,
    isWorkshop
  });
}

/*
 * Call this function when a user interact with event.
 * @param {Object} item An object that represents the ecommerce item.
https://developers.google.com/tag-platform/gtagjs/reference/events#select_item
 */
export function alPushContent(
  {
    alEvent = '',
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    content, //the event object
    homeCategory,  //the home category the event is shown
    occurrenceId,
    userId,
    error,
    isWorkshop,
    ...itemsProps
  }
) {
  const alEventWithType = appendContentTypeToElement(alEvent, isWorkshop);

  const trackEvent = `${alEventWithType}_${eventAction}_${eventStatus}`

  if (!error) {
    mixpanel.people.increment(`Total ${alEventWithType}`);
  }

  mixpanel.track(trackEvent, {
    eventAction,
    eventStatus,
    eventLabel,
    homeCategory,
    error,
    occurrenceId,
    ...content,
    ...itemsProps,
  });
  const pushDataLayer = isWorkshop ? pushWorkshopToDataLayer : pushEventToDataLayer;

  pushDataLayer({
    alEvent,
    eventAction,
    eventStatus,
    eventLabel,
    homeCategory,
    content,
    occurrenceId: !isWorkshop ? occurrenceId : undefined,
    error: error,
    ...itemsProps
  });

}

export function alContentCreator({stripeAccountStatus}) {
  mixpanel.people.set_once({
    [`Creator Status First Date`]: new Date().toISOString()
  });

  mixpanel.people.set({
    [`Creator Status`]: stripeAccountStatus,
    [`Creator Status Last Date`]: new Date().toISOString()
  });

  mixpanel.register({
    [`Creator Status`]: stripeAccountStatus
  });
}

export function alResetIdentify() {
  mixpanel.reset();
}