import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import TextInput from '../TextInput';
import InfoButton from 'components/InfoButton';

import {ErrorFontSizeCss, LabelFontSizeCss, StyledTextInputCss} from 'styles/common';


const PasswordInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
`;

const PasswordInputFieldWrap = styled.div`
  ${StyledTextInputCss}
  &.is-focused {
    border: 1px solid ${({theme: {colors}}) => colors.secondaryLight};
  }

  &.form-control.is-invalid {
    padding: 5px;
    background: none;
  }

`;

const TextInputWrap = styled(TextInput)`
  margin: 0 ${({theme: {typo}}) => typo.sizes.tiniest};
  ${LabelFontSizeCss};

`;

const PasswordInputLabel = styled.label`
`;

const PasswordInputIcon = styled.i`
  padding: 0.5rem;
`;

const PasswordInputViewIcon = styled.i`
  padding: 0.5rem;
  cursor: pointer;
`;

const Feedback = styled.div`
  margin-top: .25rem;
  font-size: ${({theme: {typo}}) => typo.sizes.base};
  font-weight: 400;
  ${ErrorFontSizeCss};
`;

function PasswordInput(
  {
    label = '',
    error,
    hideErrorText,
    name,
    style,
    className = null,
    infoText,
    icon,
    inputTip,
    onFocus,
    onBlur,
    ...props

  }) {
  const [isFocused, setIsFocused] = useState(false);
  const [isPassword, setIsPassword] = useState(true);

  function handleOnFocus(e) {
    setIsFocused(true);
    onFocus && onFocus(e);
  }

  function handleOnBlur(e) {
    setIsFocused(false);
    onBlur && onBlur(e);
  }

  const wrapperClass = classnames(
    'form-group',
    className,
    error && error.length > 0 ? 'has-error' : ''
  );

  const fieldClass = classnames(
    'form-control form-control-sm',
    error ? 'is-invalid' : '',
    !error && isFocused ? 'is-focused' : ''
  );

  const passwordViewClass = classnames(
    'fas',
    isPassword ? 'fas fa-eye' : 'fa-eye-slash'
  );

  return (
    <PasswordInputWrap
      style={style}
      className={wrapperClass}
    >
      {
        label && <PasswordInputLabel htmlFor={name}>{label}</PasswordInputLabel>
      }
      <PasswordInputFieldWrap className={fieldClass}>
        {
          icon && <PasswordInputIcon className={icon}/>
        }
        <TextInputWrap
          name={name}
          {...props}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          type={isPassword ? 'password' : 'text'}
        />
        {
          infoText && <InfoButton infoText={infoText}/>
        }
        <PasswordInputViewIcon
          onClick={() => setIsPassword(!isPassword)}
          className={passwordViewClass}
        />
      </PasswordInputFieldWrap>
      {
        error && !hideErrorText && <Feedback className="invalid-feedback">{error}</Feedback>
      }
      {
        inputTip && !error && <Feedback>{inputTip}</Feedback>
      }
    </PasswordInputWrap>
  );
}


PasswordInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  hideErrorText: PropTypes.bool,
  name: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  infoText: PropTypes.string,
  icon: PropTypes.string,
  inputTip: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

export default PasswordInput;
