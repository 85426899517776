import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {
  UPLOAD_STATUS
} from 'config/constants';

import Flow, {getFlow, addFlow} from 'utilities/flows';

import {theme} from 'styles/theme';

import Icon from 'components/Icon';
import {HeaderTitle, StyledProgressBar} from 'components/FileUploader/components/JSXElements';

const FileWrap = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 0 5px 5px;
  background-color: ${({theme: {colors}}) => colors.fill};

  &:last-child {
    margin-bottom: 0;
  }

  ${({status, theme: {colors}}) => {

    if (status === UPLOAD_STATUS.SUCCESS.key) {
      return css`border-color: ${colors.success}`;
    }

    if (status === UPLOAD_STATUS.ERROR.key) {
      return css`border-color: ${colors.error}`;
    }

    return css`border-color: ${colors.lightGrey}`;
  }};

`;

const FileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
//
// const FileHeaderTitle = styled.div`
//   width: 20em;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   ${scale([
//     ['default', [['font-size', theme.typo.sizes.h5]]],
//     ['560px', [['font-size', theme.typo.sizes.body2]]],
//     ['768px', [['font-size', theme.typo.sizes.body2]]],
//     ['992px', [['font-size', theme.typo.sizes.body]]],
//     ['1200px', [['font-size', theme.typo.sizes.body]]],
//     ['1400px', [['font-size', theme.typo.sizes.small]]]
//   ])}
//   ${({isCollapsed}) =>
//   isCollapsed && css`
//       width: 70px;
//     `
// }
//
//   ${({status, theme: {colors}}) =>
//   status === UPLOAD_STATUS.ERROR.key && css`color: ${colors.error}`
// }
// `;

const FileHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-left: 30px;
`;

const StyledIcon = styled(Icon)`
  width: auto;
  height: auto;
  margin-left: 10px;
  cursor: pointer;
`;

function File(
  {
    isCollapsed,
    flowId,
    flowOpts,
    uniqueIdentifier,
    size,
    name,
    uploadType,
    progress,
    status,
    cancelRequest,
    ...props
  }
) {
  const [flow, setFlow] = useState(false);
  const isCompleted = status === UPLOAD_STATUS.SUCCESS.key;
  let progressLabel = 'starting';
  const percentages = Math.round(progress * 100);
  if (isCompleted && progress === 1) {
    progressLabel = 'Completed';
  } else if (!isCompleted && progress === 1) {
    progressLabel = 'Processing...';
  } else if (progress > 0) {
    progressLabel = `${Math.round(progress * 100)}%`;
  }

  useEffect(() => {
    let flow = getFlow(flowId);
    if (!flow) {
      flow = new Flow(flowId, flowOpts);
      addFlow(flow);
    }
    setFlow(flow);
  }, [flowId, flowOpts]);
  return (
    <FileWrap status={status} {...props}>
      <FileHeader>
        <HeaderTitle
          status={status}
          isCollapsed={isCollapsed}
        >
          {
            status === UPLOAD_STATUS.ERROR.key
              ? `Error: ${name}`
              : name
          }
        </HeaderTitle>

        {
          !isCollapsed &&
          (
            <FileHeaderActions>
              {
                status === UPLOAD_STATUS.ERROR.key &&
                (
                  <StyledIcon
                    onClick={() => flow.retry({uniqueIdentifier, name})}
                    iconName="fas fa-undo"
                  />
                )
              }
              <StyledIcon
                onClick={() => flow.cancel({uniqueIdentifier, name, cancelRequest})}
                style={{color: theme.colors.error}}
                iconName="fas fa-ban"
              />
            </FileHeaderActions>
          )
        }
      </FileHeader>
      <StyledProgressBar
        animated
        striped
        variant={progress === 0 ? 'info' : 'success'}
        now={progress === 0 ? 100 : percentages}
        label={progressLabel}
      />
    </FileWrap>
  );
}

File.propTypes = {
  isCollapsed: PropTypes.bool,
  flowId: PropTypes.string,
  flowOpts: PropTypes.object,
  uniqueIdentifier: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string,
  uploadType: PropTypes.string,
  progress: PropTypes.number,
  status: PropTypes.string,
  cancelRequest: PropTypes.func
};

export default File;
