import {TIME_UNIT} from "config/constants";
import {difference} from "lodash";
import {getNowUTC} from "utilities/date";

export const POLL_QUESTION_MAX_INPUT = 150;
export const POLL_OPTION_MAX_INPUT = 100;

export const POLL_STATUS = {
  STARTED: {key: 'STARTED', value: 'Started'},
  COMPLETED: {key: 'COMPLETED', value: 'Completed'},
  PUBLISHED: {key: 'PUBLISHED', value: 'Published'},
  UNPUBLISHED: {key: 'UNPUBLISHED', value: 'Unpublished'},
  DELETED: {key: 'DELETED', value: 'Deleted'}
};

export const LOCAL_NEW_POLL_SETTINGS = {
  timer: {
    value: '',
    unitKey: TIME_UNIT.m.key
  },
}

export const POLL_SETTINGS = {
  timerInSec: 0,
  showOnChat: true,
  alwaysShow: true,

  /*
local new poll only
  */
  ...LOCAL_NEW_POLL_SETTINGS,

};

export const POLL_OPTION = {
  id: '',
  img: '',
  text: '',

  /*
  new poll only
   */
  imageData: null,

};

export const POLL = {
  id: null,
  text: '',
  UserId: '',
  EventId: '',
  OccurrenceId: '',
  WorkshopId: '',
  createdAt: '',
  publishedAt: '',
  type: '',
  status: POLL_STATUS.STARTED.key,
  PollOptions: [],
  ...POLL_SETTINGS,
};

// const allFieldsPoll = [
//   'text',
//   'timerInSec',
//   'type',
// ];


const createFieldsPoll = [
  'text',
  'PollOptions',
  'EventId',
  'OccurrenceId',
  'WorkshopId',
  'timerInSec',
  'showOnChat',
  'alwaysShow',
];

const updateFieldsPoll = [
  'text',
  'EventId',
  'OccurrenceId',
  'WorkshopId',
  'type',
  'PollOptions'
];

const createFieldsPollOption = [
  'text',
  'imageData',
];

const allowedFieldsFor = {
  getPoll: [
    ...difference(Object.keys(POLL), Object.keys(LOCAL_NEW_POLL_SETTINGS)),
  ],
  createPoll: [
    ...createFieldsPoll,
  ],
  updatePoll: updateFieldsPoll,
  getPollOption: [
    ...(Object.keys(POLL_OPTION)),
  ],
  createPollOption: [
    ...createFieldsPollOption,
  ],
  updatePollOption: updateFieldsPoll,

};

export function getAllowedFieldsByKey(key) {
  return allowedFieldsFor[key] || [];
}

export function getTotalVotes(poll) {
  return poll.PollOptions.reduce(
    (previousValue, el) => {
      return previousValue + (el.PollOptionVotes ? el.PollOptionVotes.length : 0)
    },
    0
  );
}

export function getUserVoteIndex(poll, userId) {
  let userPollOptionVotesIndex = -1;
  let userPollOptionIndex;
  for (userPollOptionIndex = 0; userPollOptionIndex < poll.PollOptions.length; userPollOptionIndex++) {
    userPollOptionVotesIndex = poll.PollOptions[userPollOptionIndex].PollOptionVotes?.findIndex((el) => {
      return el.UserId === userId
    });

    if (userPollOptionVotesIndex > -1) {
      return [userPollOptionIndex, userPollOptionVotesIndex];
    }
  }

  return [-1, userPollOptionVotesIndex];
}

export function getUserVote(poll, userId) {
  const [userPollOptionIndex, userPollOptionVotesIndex] = getUserVoteIndex(poll, userId);

  if (userPollOptionVotesIndex !== -1) {
    return poll.PollOptions[userPollOptionIndex]?.PollOptionVotes[userPollOptionVotesIndex];
  }

  return null;
}

export function getRemainingTimeToVote(publishedAt, timerInSec) {
  let remainingVoteTime = 0;
  let voteTimePassed = false;

  if (!timerInSec) {
    return {remainingVoteTime, voteTimePassed};
  }

  const now = getNowUTC();
  const diff = (now.diff(publishedAt) / 1000) - timerInSec;

  remainingVoteTime = (-diff);
  if (remainingVoteTime <= 0) {
    voteTimePassed = true;
    remainingVoteTime = 0;
  }
  return {remainingVoteTime, voteTimePassed};
}