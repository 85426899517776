import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import { getUserId, getUserEmail } from '../../reduxUtils/user/selectors';
import { getUserFullName, getUserAvatarThumbnail } from '../../reduxUtils/userProfile/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
} from '../../reduxUtils/browserSettings/selectors';

import WithSideBar from 'layouts/WithSideBar';

import Fallback from 'components/Fallback';
import { Navigate, Route, Routes } from 'react-router-dom';
import HowToCreateAnEvent from 'containers/Help/containers/HowToCreateAnEvent';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';

const Faqs = lazy(() => import('./containers/Faqs'));
function F() {
  return <div>sssssssss</div>;
}
function HelpFaqs({
  location,
  match,
  isMobile,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  userEmail,
  fullName,
  avatar,
  isChatDetach,
  isChatMinimized,
}) {
  return (
    <>
      <WithSideBar
        location={location}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <Suspense fallback={<Fallback />}>
          <Routes>
            <Route path={`faqs`} element={<Faqs userEmail={userEmail} fullName={fullName} />} />
            {/* <Route path={`faqs`} element={<Faqs userEmail={userEmail} fullName={fullName} />} /> */}
            <Route
              path={`how-to-create-an-event`}
              element={<HowToCreateAnEvent location={location} />}
            />
            <Route path="*" element={<Navigate to={`faqs`} replace={true} />} />
          </Routes>
        </Suspense>
      </WithSideBar>
    </>
  );
}

HelpFaqs.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  userEmail: PropTypes.string,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    userEmail: getUserEmail(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

export default connect(mapStateToProps, null)(HelpFaqs);
