export default function isOwner(eventUserId, workshopUserId, channelUserId, userId) {
  if (eventUserId && eventUserId === userId) {
    return true;
  }

  if (workshopUserId && workshopUserId === userId) {
    return true;
  }

  return !!channelUserId && channelUserId === userId;
}