import {toast} from 'react-toastify';

/**
 * Shows a toast when an error occurs.
 * @param {object} err Error rejected by the promise.
 */
let errorCalled = false;

export function errorHandler(err) {
  console.log(`err: `, err);

//   dont print error on token expired
  if (err?.data?.error === 'TokenExpiredError' || err?.message === 'TokenExpiredError') {
    return;
  }

  setTimeout(() => {
    errorCalled = false;
  }, 5000);

  if (!errorCalled) {
    toast.error(err.message || err);
  }
  errorCalled = true

  /*eslint no-console: 0*/
  if (process.env.NODE_ENV !== 'production') {
    console.error(err);
  }
}

/**
 * Shows a toast when an success occurs.
 */
export function successHandler(msg) {
  toast.success(msg);
}