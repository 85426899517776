const CHAT_ROOM_STATUSES = {
  messages: [],
  isConnected: false,
  isConnecting: false,
  reconnect: false,
}

const CHAT_ROOM_PROPS = {
  messages: [],
  ...CHAT_ROOM_STATUSES
}

export const USERS_ROOM = {
  key: '',
  room: '',
  usersInRoom: [],
  visible: false,
  disabled: false,
  isConnected: false,
  isConnecting: false
}
export const SEND_MESSAGE_COOLDOWN_INTERVAL = 400;

export const CHAT_ROOMS_LABELS = {
  LOBBY: {
    key: 'LOBBY',
    label: 'Lobby',
    value: 'lobby',
    room: '',
    visible: true,
    isPublished: true,
    disabled: true,
    isLoading: true,
    unreadMessages: 0,
    usersCount: 0,
  },
  EVENT: {
    key: 'EVENT',
    label: 'Event',
    value: 'event',
    room: '',
    isPublished: true,
    visible: true,
    disabled: true,
    isLoading: true,
    unreadMessages: 0,
    usersCount: 0,
  },
  POLLS: {
    key: 'POLLS',
    label: 'Polls',
    value: 'polls',
    room: '',
    isPublished: true,
    visible: true,
    disabled: false,
    isLoading: true,
    unreadMessages: 0,
    usersCount: 0,
  },
  WORKSHOP: {
    key: 'WORKSHOP',
    label: 'Workshop',
    value: 'workshop',
    room: '',
    isPublished: true,
    visible: false,
    disabled: false,
    isLoading: true,
    unreadMessages: 0,
    usersCount: 0,
  },
  CHANNEL: {
    key: 'CHANNEL',
    label: 'Channel',
    value: 'channel',
    room: '',
    isPublished: true,
    visible: true,
    disabled: true,
    isLoading: true,
    unreadMessages: 0,
    usersCount: 0,
  },
  PM: {
    key: 'PM',
    label: 'PM',
    value: 'pm',
    room: '',
    isPublished: true,
    visible: false,
    disabled: true,
    isLoading: true,
    unreadMessages: 0,
    usersCount: 0,
  },
}
export const CHAT_ROOMS = {
  LOBBY: {
    ...CHAT_ROOMS_LABELS.LOBBY,
    ...CHAT_ROOM_PROPS,
  },
  EVENT: {
    ...CHAT_ROOMS_LABELS.EVENT,
    ...CHAT_ROOM_PROPS,
  },
  POLLS: {
    ...CHAT_ROOMS_LABELS.POLLS,
    ...CHAT_ROOM_STATUSES,
  },
  WORKSHOP: {
    ...CHAT_ROOMS_LABELS.WORKSHOP,
    ...CHAT_ROOM_PROPS,
  },
  CHANNEL: {
    ...CHAT_ROOMS_LABELS.CHANNEL,
    ...CHAT_ROOM_PROPS,
  },
  PM: {
    ...CHAT_ROOMS_LABELS.PM,
    ...CHAT_ROOM_PROPS,
  },
}

export function getLobbyChatRoomId(channelId, eventId, workshopId) {
  const room = `channel_${channelId}${workshopId ? '_workshop_' + workshopId : ''}${eventId ? '_event_' + eventId : ''}_${CHAT_ROOMS_LABELS.LOBBY.value}`
  return {
    room,
  }
}

export function getEventChatRoomId(channelId, eventId, occurrenceId) {
  return `channel_${channelId}${eventId ? '_event_' + eventId : ''}${occurrenceId ? '_occurrence_' + occurrenceId : ''}_${CHAT_ROOMS_LABELS.EVENT.value}`
}

export function getPollChatRoomId({channelId, eventId, occurrenceId, workshopId}) {
  return `channel_${channelId}${workshopId ? '_workshop_' + workshopId : ''}${eventId ? '_event_' + eventId : ''}${occurrenceId ? '_occurrence_' + occurrenceId : ''}_${CHAT_ROOMS_LABELS.POLLS.value}`
}

export function getChannelChatRoomId(channelId) {
  return `channel_${channelId}_${CHAT_ROOMS_LABELS.CHANNEL.value}`;
}

function getEventRoom(
  {
    isPublished,
    isLoggedIn,
    isLive,
    isAllowedToEventRoom,
    channelId,
    eventId,
    occurrenceId
  }
) {
  if (!isLive || !eventId || !occurrenceId) {
    return {
      disabled: true,
      visible: false,
      isLoading: false,
      isPublished: false,
      room: '',
    }
  }
  const room = getEventChatRoomId(channelId, eventId, occurrenceId);

  return {
    disabled: (!isAllowedToEventRoom || !isLoggedIn || !isPublished || !channelId),
    isLoading: !channelId,
    isPublished: isPublished,
    visible: true,
    room: room,
  }
}

//
// const ALLOW_FIELDS_allowChat_ROOM_FILTER = ["LOBBY", "EVENT", "WORKSHOP", "CHANNEL", "PM"];
// const ALLOW_FIELDS_allowPolls_ROOM_FILTER = ["POLLS"];

/**
 * filter the object and leaving only the keys that are in the fields array
 * @param  {object} obj the object to filter the fields from
 * @param  {array} fields The fields that will not be removed
 * @return {object}      the filtered object
 */
// function allowFieldsFilterBy(obj, fields = []) {
//   function filterFunc(el, key) {
//     return fields.includes(key);
//   }
//
//   return pickBy(obj, filterFunc);
// }

//
export function parseChatRooms(
  {
    isLoggedIn,
    isPublished,
    isLive,
    isAllowedToEventRoom,
    channelId,
    eventId,
    occurrenceId,
    workshopId
  }
) {

  const allRooms = {
    LOBBY: {
      ...CHAT_ROOMS.LOBBY,
      ...getLobbyChatRoomId(channelId, eventId, workshopId),
      disabled: !isPublished || !channelId,
      isLoading: !channelId,
      visible: true,
      isPublished: isPublished,
    },
    EVENT: {
      ...CHAT_ROOMS.EVENT,
      ...getEventRoom(
        {
          isPublished,
          isLoggedIn,
          isLive,
          isAllowedToEventRoom,
          channelId,
          eventId,
          occurrenceId,
        })
    },
    POLLS: {
      ...CHAT_ROOMS.POLLS,
      room: getPollChatRoomId({
        channelId,
        eventId,
        occurrenceId,
        workshopId,
      }),
      disabled: !channelId,
      isLoading: !channelId,
      visible: !!occurrenceId && isAllowedToEventRoom,
    },
    WORKSHOP: {
      ...CHAT_ROOMS.WORKSHOP,
      room: '',
      disabled: !isLoggedIn || !channelId,
      visible: false,
      isPublished: isPublished,
    },
    CHANNEL: {
      ...CHAT_ROOMS.CHANNEL,
      room: getChannelChatRoomId(channelId),
      disabled: !channelId,
      isLoading: !channelId,
      visible: true,
    },
    PM: {
      ...CHAT_ROOMS.PM,
    },
  };

  return allRooms;
  // if (allowChat && allowPolls) {
  //   return allRooms;
  // }

  //   if (allowChat) {
  //   return allowFieldsFilterBy(allRooms, ALLOW_FIELDS_allowChat_ROOM_FILTER);
  // }

  //   if (allowPolls) {
  //   return allowFieldsFilterBy(allRooms, ALLOW_FIELDS_allowPolls_ROOM_FILTER);
  // }

}

export function getRoomStatus(rooms, chatRooms) {
  const roomsToJoin = {};
  const roomsToLeave = {}

  Object.values(rooms).forEach((value) => {
    if (value.key !== CHAT_ROOMS_LABELS.PM.key) {
      const thisChatRoom = chatRooms[value.key];
      if (value.visible && !value.disabled) {
        if (!thisChatRoom || !thisChatRoom.room) {
          roomsToJoin[value.key] = value;
        } else {
          //the room is visible but as changed id
          if (value.room !== thisChatRoom.room) {
            roomsToJoin[value.key] = value;
            roomsToLeave[value.key] = thisChatRoom;
          }
          //the room is the same one as the original
        }
      } else {
        //if the current room set changed from visible to none need to leave from the current room
        roomsToLeave[value.key] = thisChatRoom;
      }
    }
  })

  return {
    roomsToJoin,
    roomsToLeave
  }
}
