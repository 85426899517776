import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LightCustomToolbar, UserSelectNoneCss } from 'styles/common';
import PropTypes from 'prop-types';

const TextAreaWrap = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;

  .TextArea {
    overflow: auto;
    height: auto;
    width: 100%;
    border: none;
    resize: none;
    box-sizing: border-box;
    line-height: 24px;

    &::placeholder {
      ${UserSelectNoneCss};
    }

    &:focus,
    &:active {
      outline: none;
    }

    ${LightCustomToolbar};
  }
`;

const TextArea = styled.textarea``;

const textareaLineHeight = 24;

function ResizableTextarea({
  className,
  rowsInit = 1,
  minRows = 1,
  maxRows,
  disabled,
  onInput,
  onBlur,
  placeholder,
  inputRef,
  maxLength,
  value,
  onEnterClick,
  disableShiftEnter,
  children,
  ...props
}) {
  const [rows, setRow] = useState(rowsInit);

  function handleChange(event) {
    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (maxRows) {
      if (currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }
      setRow(currentRows < maxRows ? currentRows : maxRows);
    } else {
      setRow(currentRows);
    }

    onInput(event.target.value);
  }

  const useEffectTrigger = !value;
  useEffect(() => {
    if (useEffectTrigger) {
      setRow(rowsInit);
    }
  }, [useEffectTrigger, setRow, rowsInit]);

  return (
    <TextAreaWrap className={className} {...props}>
      <TextArea
        className="TextArea"
        disabled={disabled}
        dir={'auto'}
        placeholder={placeholder}
        ref={inputRef}
        maxLength={maxLength}
        rows={rows}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (!disableShiftEnter && event.key === 'Enter') {
            if (event.ctrlKey || event.altKey || event.shiftKey) {
              const [start, end] = [
                event.currentTarget.selectionStart,
                event.currentTarget.selectionEnd,
              ];
              event.currentTarget.setRangeText('\n', start, end, 'end');
              handleChange(event);
              event.preventDefault();
              event.currentTarget.setSelectionRange(end + 1, end + 1);
              event.currentTarget.blur();
              event.currentTarget.focus();
              return false;
            } else {
              if (onEnterClick) {
                event.preventDefault();
                onEnterClick(event);
                return false;
              }
              return true;
            }
          }
        }}
      ></TextArea>
      {children}
    </TextAreaWrap>
  );
}

ResizableTextarea.propTypes = {
  rowsInit: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  disabled: PropTypes.bool,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  inputRef: PropTypes.object,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  onEnterClick: PropTypes.func,
  className: PropTypes.string,
  disableShiftEnter: PropTypes.bool,
};

export default React.memo(ResizableTextarea);
