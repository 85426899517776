import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from 'styles/theme';
import {scale} from 'styles/common';

import EmptyCentered from '../EmptyCentered';


const CenteredWithBackgroundWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px ${({theme: {padding}}) => padding.pageM}px;
  ${scale([['768px', [['padding', `10px ${theme.padding.pageD}px`]]]])}

  border-radius: 4px;
  background-color: ${({theme: {colors}}) => colors.white};
  border: 1px solid ${({theme: {colors}}) => colors.lightGrey};
`;


function CenteredWithBackground({children, ...props}) {
  return (
    <EmptyCentered>
      <CenteredWithBackgroundWrap {...props}>
        {children}
      </CenteredWithBackgroundWrap>
    </EmptyCentered>
  );
}

CenteredWithBackground.propTypes = {
  children: PropTypes.any
};

export default CenteredWithBackground;