import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faChevronRight, faEllipsis, faUser } from '@fortawesome/free-solid-svg-icons';
import Icon from 'components/Icon';
import ChatRooms from 'components/Chat/components/ChatRooms';
import ChatInput from 'components/Chat/components/ChatInput';
import ChatMessageContainer from 'components/Chat/components/ChatMessageContainer';
import ContextMenu from 'components/ContextMenu/EmptyContextMenu';
import { UserSelectNoneCss } from 'styles/common';
import ContentMenuItem from 'components/MenuItems/ContentMenuItem';
import Action from 'components/ActionsBar/component/Action';
import UsersContainer from 'components/Chat/components/UsersContainer';
import classnames from 'classnames';
import { ConnectingCss } from 'components/Chat/components/JSXElements';
import PollsContainer from 'components/Chat/components/PollsContainer';
import { CHAT_ROOMS_LABELS, SEND_MESSAGE_COOLDOWN_INTERVAL } from 'utilities/chat.utils';
import moment from 'moment-timezone';

const ChatWindow = styled.div`
  width: 100%;
  height: 100%;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  color: ${({ theme: { colors } }) => colors.grayNew};
`;

const Header = styled.span`
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding: 10px;
  ${UserSelectNoneCss};
`;

const ChatSubHeader = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid #dadde1;
  ${UserSelectNoneCss};
`;

const UsersWrap = styled.button`
  display: flex;
  line-height: normal;
  align-items: center;
  //cursor: pointer;
  box-shadow: inset 0 0 5px #c9c9c9;
  border-radius: 5px;
  padding: 1px 4px;
  margin-left: auto;
  border: none;
  color: ${({ theme: { colors } }) => colors.grayNew};

  &:hover:not(.disabled) {
    background-color: #dcdcdc;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.isConnecting {
    ${ConnectingCss};
  }

  &.isSelected {
    color: ${({ theme: { colors } }) => colors.purple};
    box-shadow: inset 0 0 5px ${({ theme: { colors } }) => colors.purple};
  }
`;

const UsersIcon = styled(Icon)``;

const RightArrowIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: ${({ theme: { colors } }) => colors.grayNew};

  cursor: pointer;
  box-shadow: inset 0 0 5px #c9c9c9;
  border-radius: 5px;

  &:hover {
    background-color: #dcdcdc;
  }
`;

const UsersCount = styled.span`
  padding-left: 3px;
`;

export const HeaderContextMenu = styled(ContextMenu)`
  font-size: 16px;
  margin-left: 8px;
  rotate: 90deg;
`;

export const HeaderContextMenuItem = styled(ContentMenuItem)``;

export const ChatAction = styled(Action)`
  font-size: 13px;
`;

const ChatInputWrap = styled.div`
  position: relative;
`;

let chatLastMessageTimeStamp;

function ChatBody({
  isAdmin,
  chatBodyRef,
  messageList,
  userId,
  channelId,
  eventId,
  occurrenceId,
  workshopId,
  usersCount,
  sendMessage,
  userName,
  avatar,
  selectedRoom,
  onRoomClick,
  chatRoomsLabels,
  isLoggedIn,
  isSocketConnected,
  toggleChatMinimizeAction,
  onClickChatDetach,
  onClickUserCount,
  isChatDetach,
  hideChatDetachAction,
  usersRoom,
  isConnecting,
}) {
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const onSendMessageClick = (currentMessage, setCurrentMessage) => {
    if (!currentMessage) {
      return true;
    }

    if (
      chatLastMessageTimeStamp &&
      moment().diff(chatLastMessageTimeStamp, 'milliseconds') < SEND_MESSAGE_COOLDOWN_INTERVAL
    ) {
      setShowWarningMsg(true);
      setTimeout(() => {
        setShowWarningMsg(false);
      }, SEND_MESSAGE_COOLDOWN_INTERVAL);
      return;
    }

    setShowWarningMsg(false);

    const messageData = {
      room: selectedRoom.room,
      roomKey: selectedRoom.key,
      userName,
      userId,
      avatar: avatar,
      message: currentMessage,
      // EventId: eventId,
      // OccurrenceId: occurrenceId,
      id: `user_${userId}_${moment().unix()}`,
    };

    sendMessage(messageData);
    setCurrentMessage('');
    chatLastMessageTimeStamp = moment();
    return true;
  };

  function toggleShow() {
    toggleChatMinimizeAction();
  }

  let liClasses = classnames({
    isSelected: usersRoom.visible,
    disabled: !isSocketConnected,
    isConnecting: usersRoom.isConnecting,
  });

  return (
    <ChatWindow ref={chatBodyRef}>
      <Header>
        <RightArrowIcon onClick={toggleShow} fontAwesomeIcon={faChevronRight} />

        {selectedRoom.key !== CHAT_ROOMS_LABELS.POLLS.key && (
          <UsersWrap className={liClasses} onClick={onClickUserCount} disabled={!isSocketConnected}>
            <UsersCount>{usersCount}</UsersCount>
            <UsersIcon fontAwesomeIcon={faUser} />
          </UsersWrap>
        )}

        <HeaderContextMenu fontAwesomeIcon={faEllipsis}>
          <HeaderContextMenuItem>
            <ChatAction
              label={`${isChatDetach ? 'Restore Chat' : 'Popout Chat'}`}
              onClick={() => {
                isChatDetach ? hideChatDetachAction() : onClickChatDetach();
                return false;
              }}
            />
          </HeaderContextMenuItem>
        </HeaderContextMenu>
      </Header>
      <ChatSubHeader>
        <ChatRooms
          chatRoomsLabels={chatRoomsLabels}
          selectedRoom={selectedRoom}
          onRoomClick={onRoomClick}
          isSocketConnected={isSocketConnected}
          isUsersRoomVisible={usersRoom.visible}
        />
      </ChatSubHeader>
      {selectedRoom.key === CHAT_ROOMS_LABELS.POLLS.key ? (
        <PollsContainer
          isAdmin={isAdmin}
          userId={userId}
          channelId={channelId}
          eventId={eventId}
          occurrenceId={occurrenceId}
          workshopId={workshopId}
        />
      ) : usersRoom.visible ? (
        <UsersContainer usersInRoom={usersRoom.usersInRoom} />
      ) : (
        <ChatMessageContainer userId={userId} messageList={messageList} />
      )}

      {selectedRoom.key !== CHAT_ROOMS_LABELS.POLLS.key && (
        <ChatInputWrap>
          <ChatInput
            showWarningMsg={showWarningMsg}
            style={{ display: usersRoom.visible ? 'none' : 'flex' }}
            isLoggedIn={isLoggedIn}
            onSendMessageClick={onSendMessageClick}
            isChatDetach={isChatDetach}
            isSocketConnected={isSocketConnected}
            isPublished={chatRoomsLabels[selectedRoom.key]?.isPublished}
            isLoading={chatRoomsLabels[selectedRoom.key]?.isLoading}
            isDisabled={chatRoomsLabels[selectedRoom.key]?.disabled || selectedRoom.isConnecting}
            isConnecting={isConnecting}
            isConnected={!selectedRoom.isConnecting && selectedRoom.isConnected}
          />
        </ChatInputWrap>
      )}
    </ChatWindow>
  );
}

ChatBody.propTypes = {};

export default React.memo(ChatBody);
