import {createAction} from 'redux-actions';
import {
  SET_HOME_LIVE_EVENTS_SUGGESTION2,
  ADD_HOME_LIVE_EVENTS_SUGGESTION2,
  UPDATE_OCCURRENCE_LIVE_EVENT_SUGGESTION2,
} from './types';

export const setHomeLiveEventsSuggestion2 = createAction(SET_HOME_LIVE_EVENTS_SUGGESTION2);
export const addHomeLiveEventsSuggestion2 = createAction(ADD_HOME_LIVE_EVENTS_SUGGESTION2);
export const updateOccurrenceLiveEventsSuggestion2 = createAction(UPDATE_OCCURRENCE_LIVE_EVENT_SUGGESTION2);
