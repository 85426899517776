import moment from 'moment-timezone';
import {dateToUserTimeZone, dateFromUserTimeZone, isDateInFuture} from './date';
import {NO_TZ_TIMESTAMP_FORMAT} from 'config/constants';
import {getStore} from './store';
import {
  updateEventOccurrenceAction,
  deleteEventOccurrenceAction
} from '../reduxUtils/eventOccurrences/actions';
import {hasEmbeddedLinkError} from 'utilities/event-details';
import {isObject, isString} from 'utilities/general';

export function processOccurrence(occurrence) {
  return {
    ...occurrence,
    date: dateToUserTimeZone(occurrence.date).format(NO_TZ_TIMESTAMP_FORMAT)
  };
}

export function processOccurrences(occurrences = []) {
  return occurrences.map((occurrence) => {
    return processOccurrence(occurrence);
  });
}

export function getHasFutureDateError(date) {
  const now = moment.utc(dateToUserTimeZone().format(NO_TZ_TIMESTAMP_FORMAT));
  const after = moment.utc(moment(date).format(NO_TZ_TIMESTAMP_FORMAT));
  if (now.isAfter(after)) {
    return 'Date and time should be the future';
  }

  return null;
}

export function getHasDuplicateOccurrenceError(date, occurrences = [], errorsRef) {
  const duplicateOccurrences = occurrences.filter((occurrence) =>
    moment(occurrence.date).unix() === moment(date).unix()
  );

  if (duplicateOccurrences.length > 1) {
    if (errorsRef && errorsRef.occurrenceError) {
      duplicateOccurrences.forEach((occurrence) => {
        errorsRef.occurrenceError[occurrence.id] = true;
      });
    }

    return 'You cannot add the same date.';
  }

  return null;
}

export function getOccurrenceExistsError(date, occurrences = []) {
  const index = occurrences.findIndex((occurrence) =>
    moment(occurrence.date).unix() === moment(date).unix()
  );
  if (index > -1) {
    return 'This date already exists';
  }

  return null;
}

export function getOccurrenceErrors(date, occurrences = []) {
  const hasFutureDateError = getHasFutureDateError(date);
  if (hasFutureDateError) {
    return hasFutureDateError;
  }

  const hasDuplicateDateError = getOccurrenceExistsError(date, occurrences);
  if (hasDuplicateDateError) {
    return hasDuplicateDateError;
  }

  return null;
}

export function hasIsLiveError(isLive, occurrences) {
  if (isLive && !occurrences.length) {
    return 'Live events must have at least one date in the future.';
  }

  return false;
}

export function getEventScheduleErrors(occurrences, isLive, checkLive) {
  const errors = {
    embeddedLink: {},
    occurrenceError: {},
    hasErrors: false
  };

  if (isLive && !occurrences) {
    errors.hasErrors = true;
    return errors;
  }

  const occurrencesWithoutDeleted = occurrences.filter(({needDelete}) => !needDelete);
  const preparedOccurrences = occurrencesWithoutDeleted.filter(({needSave, isNew}) => needSave || isNew);
  preparedOccurrences.forEach((occurrence, index) => {
    const hasFutureDateError = getHasFutureDateError(occurrence.date);
    if (hasFutureDateError) {
      errors.hasFutureDateError = hasFutureDateError;
      errors.occurrenceError[occurrence.id] = true;
      errors.hasErrors = true;
    }

    errors.embeddedLink[occurrence.id] = hasEmbeddedLinkError(occurrence.embeddedLink);
    if (errors.embeddedLink[occurrence.id]) {
      errors.hasErrors = true;
    }

    const hasDuplicateOccurrenceError = getHasDuplicateOccurrenceError(occurrence.date, occurrencesWithoutDeleted, errors);
    if (hasDuplicateOccurrenceError) {
      errors.hasDuplicateOccurrenceError = hasDuplicateOccurrenceError;
      errors.hasErrors = true;
    }
  });

  if (checkLive) {
    const futureOccurrences = occurrencesWithoutDeleted.filter(({date}) => isDateInFuture(date));
    const isLiveError = hasIsLiveError(isLive, futureOccurrences);
    if (isLiveError) {
      futureOccurrences?.forEach(occurrence => {
        errors.embeddedLink[occurrence.id] = true;
      });

      errors.hasErrors = true;
      if (isLiveError) {
        errors.hasIsLiveError = isLiveError;
      }
    }
  }

  return errors;
}

export function hasOccurrencesErrors(object) {
  for (const property in object) {
    if (object[property]) {
      if (isObject(object[property])) {
        for (const subProperty in object[property]) {
          if (isString(object[property][subProperty]) && object[property][subProperty].length) {
            return true;
          }
        }
      }
    }
    if (isString(object[property]) && object[property].length) {
      return true;
    }
  }
  return false;
}

async function deleteOccurrences(eventId, occurrences = [], eventService) {
  const {dispatch} = getStore();

  let i = 0;
  const l = occurrences.length;
  for (i; i < l; i++) {
    const occurrenceId = occurrences[i].id;
    await eventService.deleteEventOccurrence(eventId, occurrenceId);
    dispatch(deleteEventOccurrenceAction(occurrenceId));
  }
}

async function updateOccurrences(eventId, occurrences = [], eventService) {
  const {dispatch} = getStore();

  let i = 0;
  const l = occurrences.length;
  for (i; i < l; i++) {
    const {needDelete, isNew, needSave, ...occurrence} = occurrences[i];
    const occurrenceId = occurrence.id;
    const updatedEvent = {
      ...occurrence,
      date: dateFromUserTimeZone(occurrence.date)
    };
    const response = await eventService.updateEventOccurrence(eventId, occurrenceId, updatedEvent);
    const updateOccurrence = response.data.occurrence;
    dispatch(
      updateEventOccurrenceAction({
        id: occurrenceId,
        occurrence: {
          ...processOccurrence(updateOccurrence),
          needSave: false
        }
      })
    )
  }
}

async function addOccurrences(eventId, occurrences = [], eventService) {
  const {dispatch} = getStore();

  let i = 0;
  const l = occurrences.length;
  for (i; i < l; i++) {
    const {needDelete, isNew, needSave, ...occurrence} = occurrences[i];
    const occurrenceId = occurrence.id;
    const updatedEvent = {
      ...occurrence,
      date: dateFromUserTimeZone(occurrence.date)
    };
    const response = await eventService.createEventOccurrence(eventId, updatedEvent);
    const addedOccurrence = response.data.occurrence;
    dispatch(
      updateEventOccurrenceAction({
        id: occurrenceId,
        occurrence: {
          ...processOccurrence(addedOccurrence),
          isNew: false,
          needSave: false
        }
      })
    );
  }
}

export async function saveOccurrences(eventId, occurrences, eventService) {
  const occurrencesNeedDelete = occurrences.filter(({needDelete}) => needDelete);
  await deleteOccurrences(eventId, occurrencesNeedDelete, eventService);
  const occurrenceNeedUpdate = occurrences.filter(({isNew, needSave, needDelete}) => !isNew && !needDelete && needSave);
  await updateOccurrences(eventId, occurrenceNeedUpdate, eventService);
  const OccurrenceNeedAdd = occurrences.filter(({isNew}) => isNew);
  await addOccurrences(eventId, OccurrenceNeedAdd, eventService);
}
