import moment from 'moment-timezone';
import {PERMISSIONS_ACTIONS} from 'config/constants';

import permissionFunctions from './permissionFunctions';

export function getReqPermissions(permissionFunction, params) {
  if (!permissionFunctions.hasOwnProperty(permissionFunction) || typeof permissionFunctions[permissionFunction] !== 'function') {
    throw new Error('Action does not exist');
  }

  return permissionFunctions[permissionFunction](params);
}

export function hasNoPermission({type, id, key, parent}, permission, accessControls = []) {
  const foundAccessControls = accessControls.filter((accessControl) => accessControl[key] === id);
  if ((!foundAccessControls || !foundAccessControls.length) && !parent) {
    return false;
  }

  let i = 0;
  let l = foundAccessControls.length;
  for (i; i < l; i++) {
    const accessControl = foundAccessControls[i];
    const now = moment.utc();
    const beginAt = accessControl.beginAt ? moment.utc(accessControl.beginAt) : null;
    const expireAt = accessControl.expireAt ? moment.utc(accessControl.expireAt) : null;
    const begun = beginAt ? now.isAfter(beginAt) : true;
    const expired = expireAt ? now.isBefore(expireAt) : false;
    if (
      accessControl &&
      accessControl.permission === permission &&
      accessControl.action === PERMISSIONS_ACTIONS.DISALLOW.key &&
      begun &&
      !expired
    ) {
      return true;
    }
  }

  if (parent) {
    return hasNoPermission(parent, permission, accessControls);
  }

  return false;
}

export function hasPermission({type, id, key, parent}, permission, accessControls = []) {
  const foundAccessControls = accessControls.filter((accessControl) => accessControl[key] === id);
  if ((!foundAccessControls || !foundAccessControls.length) && !parent) {
    return false;
  }
  let i = 0;
  let l = foundAccessControls.length;
  for (i; i < l; i++) {
    const accessControl = foundAccessControls[i];
    const now = moment.utc();
    const beginAt = accessControl.beginAt ? moment.utc(accessControl.beginAt) : null;
    const expireAt = accessControl.expireAt ? moment.utc(accessControl.expireAt) : null;
    const begun = beginAt ? now.isAfter(beginAt) : true;
    const expired = expireAt ? now.isBefore(expireAt) : false;
    if (
      accessControl &&
      accessControl.permission === permission &&
      accessControl.action === PERMISSIONS_ACTIONS.ALLOW.key &&
      begun &&
      !expired
    ) {
      return true;
    }

    if (
      accessControl &&
      accessControl.permission === permission &&
      accessControl.action === PERMISSIONS_ACTIONS.DISALLOW.key
    ) {
      return false;
    }
  }

  if (parent) {
    return hasPermission(parent, permission, accessControls);
  }

  return false;
}


export function hasPermissions(object, accessControls, permissionFunction, params) {
  const requiredPermissions = getReqPermissions(permissionFunction, params);
  let i = 0;
  let l = requiredPermissions.length;

  for (i; i < l; i++) {
    const permission = requiredPermissions[i];
    if (hasPermission(object, permission, accessControls)) {
      return true;
    }

    if (hasNoPermission(object, permission, accessControls)) {
      return false;
    }
  }
  return false;
}