import io from 'socket.io-client';
import EventEmitter from 'events';
import { WEBSOCKET_HOST } from 'config/config';
import { getStore } from 'utilities/store';
import { setSocketConnectionStatusAction } from '../reduxUtils/socket/actions';
import { getRandomInt } from 'utilities/general';
import { getChatInstance } from 'utilities/chat';
import jwtLogin from 'websockets/sendActions/authentication/auth.jwt-login.action';
import { errorHandler } from 'utilities/services';

let reconnectTries = 0;
let reconnectTimer = null;
let init = false;

export default class SocketRouter extends EventEmitter {
  constructor() {
    super();
    this.socket = io(WEBSOCKET_HOST, {
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: 20,
      auth: {
        authorization: '',
      },
    });

    this.socket.on('connect', () => {
      reconnectTries = 0;
      const { dispatch } = getStore();
      dispatch(setSocketConnectionStatusAction(true));

      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
        reconnectTimer = null;
      }

      if (init) {
        jwtLogin()
          .then((res) => {
            const chat = getChatInstance();
            if (chat.isInit()) {
              chat.reconnectToRooms();
            }
          })
          .catch((err) => {
            errorHandler(err);
          });
      }

      init = true;
      this.socket.on('route', (...params) => {
        const lastElement = params.pop();
        let cb = lastElement;
        if (typeof lastElement !== 'function') {
          params.push(lastElement);
          cb = function noop() {};
        }
        const [router, action, paramsArray] = params;
        this.route(router, action, paramsArray, cb);
      });
    });

    const tryReconnect = () => {
      // console.log(`try to reconnect`, reconnectTries)
      if (reconnectTries >= 20) {
        return;
      }

      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
        reconnectTimer = null;
      }

      reconnectTimer = setTimeout(() => {
        this.socket.io.open((err) => {
          if (err) {
            console.log(`socket.io.open err`, err);
            tryReconnect();
          } else {
            reconnectTries = 0;
            const { dispatch } = getStore();
            dispatch(setSocketConnectionStatusAction(true));
          }
        });
      }, 1000 + getRandomInt(++reconnectTries * 5) * 1000);
    };

    this.socket.on('disconnect', (reason) => {
      console.log(`websocket disconnect`, reason); // prints the message associated with the error
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        // this.socket.disconnect();
        tryReconnect();
      }

      if (reason === 'io client disconnect') {
        // the disconnection was initiated by the client, you need to reconnect manually
        tryReconnect();
      }

      //reconnect done automatically by manager
      if (reason === 'ping timeout') {
        // socketConnected = false;
        // pingTimeOut = true;
      }
      const { dispatch } = getStore();
      dispatch(setSocketConnectionStatusAction(false));
      // tryReconnect();
    });

    // called when the underlying connection is closed
    this.socket.io.on('close', (reason) => {
      console.log(`connection close`, reason);
      const { dispatch } = getStore();
      dispatch(setSocketConnectionStatusAction(false));
      // tryReconnect();
    });

    this.socket.on('connect_error', (err) => {
      const { dispatch } = getStore();
      dispatch(setSocketConnectionStatusAction(false));
      console.log(`connect_error`, err.message); // prints the message associated with the error
      tryReconnect();
    });

    this.socket.on('error', (e) => {
      console.log(`socket error`, e); // the Set contains at least the socket ID
    });

    // this.socket.on('disconnecting', () => {
    //   console.log(`disconnecting`,); // the Set contains at least the socket ID
    // });
  }

  route(router, method, paramsArray, cb) {
    const route = `${router}:${method}`;
    this.emit(route, this.socket, paramsArray, cb);
  }

  generateActionChain(actions, chain = () => {}) {
    const firstAction = actions.shift();
    const oldChain = chain;
    chain = async (socket, paramsArray, cb) => {
      await oldChain(socket, paramsArray, cb);
      await firstAction(socket, paramsArray, cb);
    };

    if (actions.length > 0) {
      return this.generateActionChain(actions, chain);
    }
    return chain;
  }

  use(route, ...actions) {
    this.on(route, this.generateActionChain(actions));
  }
}
