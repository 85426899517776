import React, { useState, Suspense, lazy, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAuthIsLoggedIn } from '../../../../reduxUtils/auth/selectors';
import { getUserEmail, getUserId } from '../../../../reduxUtils/user/selectors';
import { getUserAccessControl } from '../../../../reduxUtils/accessControl/selectors';
import { getBrowserIsMobile } from '../../../../reduxUtils/browserSettings/selectors';
import {
  getUserFullName,
  getUserAvatarThumbnail,
} from '../../../../reduxUtils/userProfile/selectors';
import { getEvent } from '../../../../reduxUtils/event/selectors';
import { getFileUploader } from '../../../../reduxUtils/fileUploader/selectors';
import { getEventOccurrences } from '../../../../reduxUtils/eventOccurrences/selectors';
import { getEventEager, startEvent, resetEventActions } from '../../../../reduxUtils/event/thunks';

import WithSideBar from 'layouts/WithSideBar';
import MetaDecorator from 'components/MetaDecorator';
import Fallback from 'components/Fallback';
import { updateMenusAndValidate } from 'utilities/event';
import { AL_EVENT, AL_EVENT_ACTION, AL_EVENT_STATUS, alPushContent } from 'utilities/analytics';
import { getIsChatDetach, getIsChatMinimized } from '../../../../reduxUtils/chat/selectors';
import { getUserSettings } from '../../../../reduxUtils/userSettings/selectors';

const New = lazy(() => import('./New'));

function EventNew({
  match,
  location,
  isMobile,
  isLoggedIn,
  userId,
  fullName,
  avatar,
  event,
  accessControl,
  userSettings,
  fileUploader: { files },
  occurrences,
  getEventEager,
  resetEventActions,
  startEvent,
  email,
  isChatDetach,
  isChatMinimized,
}) {
  let { channelId, workshopId } = useParams();

  const [isLeftBarOpened, setIsLeftBarOpened] = useState(true);
  const [menuOptions, setMenuOptions] = useState([
    {
      label: 'Home',
      iconName: 'fas fa-home',
      linkTo: '/',
    },
    {
      label: 'Details',
      iconName: 'fas fa-info-circle',
      linkTo: `event-details`,
    },
    {
      label: 'Schedule',
      iconName: 'far fa-clock',
      linkTo: `event-schedule`,
      isDisabled: true,
    },
    {
      label: 'Settings',
      iconName: 'fas fa-cog',
      linkTo: `event-settings`,
      isDisabled: true,
    },
  ]);

  useEffect(() => {
    changeMenuSchedule(event.isLive);
  }, [event.isLive]);

  useEffect(() => {
    alPushContent({
      alEvent: AL_EVENT.EVENT.createEvent,
      eventAction: AL_EVENT_ACTION.start,
      eventStatus: AL_EVENT_STATUS.success,
      eventLabel: 'creating new event',
      eventCategory: 'event',
      userId: userId,
    });
  }, []); // eslint-disable-line

  function changeMenuSchedule(add) {
    setMenuOptions((current) => {
      const newMenu = [...current];
      newMenu[2].ignore = !add;
      // newMenu[3].ignore = !add;
      return newMenu;
    });
  }

  function onMenuChange(id, updatedMenu) {
    setMenuOptions((current) => {
      const newMenu = [...current];
      if (newMenu[id] && !newMenu[id].ignore) {
        newMenu[id] = { ...newMenu[id], ...updatedMenu };
      }
      return newMenu;
    });
  }

  function updateMenusAndGetErrors({ occurrences: stateOccurrences, checkLive }) {
    const tempOccurrences = stateOccurrences || occurrences;
    return updateMenusAndValidate({
      event,
      userId,
      files,
      accessControl,
      userSettings,
      occurrences: tempOccurrences,
      onMenuChange,
      checkLive,
    });
  }

  function OnIsLeftBarChange() {
    setIsLeftBarOpened(!isLeftBarOpened);
  }

  const isWorkshopEvent = !!workshopId;

  return (
    <>
      <MetaDecorator
        title={`Create new ${isWorkshopEvent ? 'workshop event' : 'event'}`}
        description={`Create new ${
          isWorkshopEvent
            ? 'live or vod workshop event'
            : "live or vod event, paid or free it's up to you"
        }`}
      />
      <WithSideBar
        location={location}
        options={[...menuOptions].filter(({ ignore }) => !ignore)}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        setLocalIsLeftBarOpened={OnIsLeftBarChange}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <Suspense fallback={<Fallback />}>
          <New
            isMobile={isMobile}
            channelId={channelId}
            workshopId={workshopId}
            match={match}
            userId={userId}
            getEventEager={getEventEager}
            resetEventActions={resetEventActions}
            startEvent={startEvent}
            onMenuChange={onMenuChange}
            updateMenusAndGetErrors={updateMenusAndGetErrors}
          />
        </Suspense>
      </WithSideBar>
    </>
  );
}

EventNew.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  isLeftBarOpened: PropTypes.bool,
  event: PropTypes.object,
  accessControl: PropTypes.array,
  userSettings: PropTypes.object,
  fileUploader: PropTypes.object,
  occurrences: PropTypes.array,
  getEventEager: PropTypes.func,
  resetEventActions: PropTypes.func,
  startEvent: PropTypes.func,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  const userId = getUserId(state);
  let accessControl = [];
  if (userId) {
    accessControl = getUserAccessControl(state, userId);
  }

  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    userId,
    event: getEvent(state),
    accessControl,
    userSettings: getUserSettings(state),
    fileUploader: getFileUploader(state),
    occurrences: getEventOccurrences(state),
    email: getUserEmail(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEventEager,
      resetEventActions,
      startEvent,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventNew);
