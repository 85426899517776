import axios from 'axios';
import moment from "moment-timezone";

const defaultProps = {
  country: '',
  countryCode: ''
};

const apis = {
  eil: {
    url: 'https://extreme-ip-lookup.com/json/',
    map: ({country, countryCode}) => {
      return {
        ...defaultProps,
        country,
        countryCode
      };
    }
  },
  ipApi: {
    url: 'http://ip-api.com/json',
    map: ({country, countryCode}) => {
      return {
        ...defaultProps,
        country,
        countryCode
      };
    }
  },
  ipinfo: {
    url: 'https://ipinfo.io/json',
    map: ({country}) => {
      return {
        ...defaultProps,
        country,
        countryCode: country
      };
    }
  },
  geoipDb: {
    url: 'https://geoip-db.com/json/',
    map: ({country_name, country_code}) => {
      return {
        ...defaultProps,
        country: country_name,
        countryCode: country_code
      };
    }
  }
};

function mapResults(results, apiUsed) {
  return apiUsed.map(results);
}

export function getCountryInfo() {
  return axios.get(apis.eil.url)
    .then(results => mapResults(results.data, apis.eil))
    .catch(err => {
      return axios.get(apis.ipApi.url)
        .then(results => mapResults(results.data, apis.ipApi))
        .catch(err => {
          return axios.get(apis.ipinfo.url)
            .then(results => mapResults(results.data, apis.ipinfo))
            .catch(err => {
              return axios.get(apis.geoipDb.url)
                .then(results => mapResults(results.data, apis.geoipDb))
              // .catch(err => {
              //   console.log(`Something went wrong none of the api worked`);
              // });
            });
        });
    });
}

export function getCountryOfClient() {
  const countryNames = moment.tz.zone(moment.tz.guess(true)).countries(); // This will return an array from which we can pass the value to locale()
  const countryName = countryNames[0] || 'us';
  return countryName?.toLowerCase();
}