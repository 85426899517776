import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BarItem from './components/BarItem';
import {ScaleFontMenuCss, UserSelectNoneCss} from 'styles/common';

const BottomBarWrap = styled.div`
  display: flex;
  background-color: ${({theme: {colors}}) => colors.paleGrey};
  border-top: 1px solid ${({theme: {colors}}) => colors.lightGrey};
  ${ScaleFontMenuCss};
  font-weight: bold;
  color: ${({theme: {colors}}) => colors.grayNew};
  ${UserSelectNoneCss};
  overflow-x: auto;
  width: 100%;
  flex-shrink: 0;

`;

function BottomBar({options = []}) {
  return (
    <BottomBarWrap>
      {
        options.map(({label, iconName, linkTo, isDisabled}) => (
          <BarItem
            key={label}
            label={label}
            iconName={iconName}
            linkTo={linkTo}
            isDisabled={isDisabled}
          />
        ))
      }
    </BottomBarWrap>
  );
}

BottomBar.propTypes = {
  options: PropTypes.array
};

export default BottomBar;