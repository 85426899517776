import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { getEncodedRedirectPath } from 'utilities/general';
import LinkedMenuItem from 'components/MenuItems/LinkedMenuItem';
import { scale, UserSelectNoneCss } from 'styles/common';

const AddContentDropdownWrap = styled(Dropdown)`
  display: flex;
  align-items: center;
  ${UserSelectNoneCss};
  border-radius: 50%;

  &:hover,
  &:active,
  &:focus {
    background-color: #ebebeb;
  }
`;

const AddContentDropdownIconWrap = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 50%;

  ${scale([
    [
      'default',
      [
        ['width', '25px'],
        ['min-width', '25px'],
        ['height', '25px'],
        ['min-height', '25px'],
      ],
    ],
    [
      '768px',
      [
        ['width', '30px'],
        ['min-width', '30px'],
        ['height', '30px'],
        ['min-height', '30px'],
      ],
    ],
  ])}
  box-sizing: border-box;
  justify-content: center;
  -webkit-box-shadow: 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%),
    0 2px 3px -1px rgb(0 0 0 / 20%);
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%),
    0 2px 3px -1px rgb(0 0 0 / 20%);
  border: 1.5px solid #b4b4b4;
`;

const AddContentDropdownIcon = styled.svg`
  display: flex;
  font-size: ${({ theme: { typo } }) => typo.sizes.h5};
  color: ${({ isOpened, theme: { colors } }) => (isOpened ? colors.black : colors.darkGrey)};
  width: 20px;
  height: 20px;
  margin: auto;
`;

function AddContentDropdown({ userId }) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  const redirect = getEncodedRedirectPath();
  return (
    <AddContentDropdownWrap>
      <AddContentDropdownWrap.Toggle as={CustomToggle}>
        <AddContentDropdownIconWrap>
          <AddContentDropdownIcon
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 22"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 11h10m-5 5V6"
            />
          </AddContentDropdownIcon>
        </AddContentDropdownIconWrap>
      </AddContentDropdownWrap.Toggle>
      <AddContentDropdownWrap.Menu>
        <LinkedMenuItem
          to={`/channel/${userId}/event/new${redirect}`}
          iconName="fas fa-calendar-week"
          label="Add Event"
          disabled={!userId}
        />
        <LinkedMenuItem
          to={`/channel/${userId}/workshop/new${redirect}`}
          iconName="fas fa-project-diagram"
          label="Add Workshop"
          disabled={!userId}
        />
      </AddContentDropdownWrap.Menu>
    </AddContentDropdownWrap>
  );
}

AddContentDropdown.propTypes = {};

export default AddContentDropdown;
