import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  UPDATE_EVENT,
  DELETE_EVENT
} from './types';

import {
  PAYMENT_TYPES,
  EVENT_STATUS,
  EVENT_SIZE_LIMITATIONS,
  EVENT_RECORD_DURATIONS
} from 'config/constants';

import {
  LOGOUT
} from '../auth/types';

import {fixCategories} from 'utilities/general';

export const initialState = {
  id: null,
  upload: null,
  title: 'Event title',
  categories: [],
  description: '',
  summary: '',
  coverPhotoDetails: '',
  coverPhoto: '',
  coverPhotoHomePage: '',
  coverPhotoThumbnail: '',
  isLive: true,
  allowDropIn: true,
  recordEvent: false,
  recordDuration: EVENT_RECORD_DURATIONS.MEMBERSHIP_FREE.key,
  paymentType: PAYMENT_TYPES.FREE.key,
  price: 0,
  currency: 'USD',
  duration: '',
  streamDuration: '',
  videoDuration: 0,
  limitEventToDuration: false,
  participantsLimit: EVENT_SIZE_LIMITATIONS.MEMBERSHIP_FREE.participantsLimit.max,
  rating: 0,
  ratingCount: 0,
  user: '',
  isPrivate: false,
  WorkshopId: null,
  status: EVENT_STATUS.STARTED.key,
};

export default handleActions({
  [UPDATE_EVENT]: (state, {payload}) => {
    if (payload.categories) {
      payload.categories = fixCategories(payload.categories);
    }

    return {...state, ...pick(payload, Object.keys(initialState))};
  },
  [DELETE_EVENT]: () => {
    return initialState;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
