import React, { Suspense, lazy } from 'react';

const LoginSignupModal = lazy(() => import('./LoginSignupModal'));

export default function LoginSignupModalWrap({ show, setShow }) {
  return (
    <Suspense fallback={<></>}>
      <LoginSignupModal show={show} setShow={setShow} />
    </Suspense>
  );
}
