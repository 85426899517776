import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {GeneralDropDownItemCss, UserSelectNoneCss} from 'styles/common';

import Icon from 'components/Icon';
import {MenuItemIconCss, MenuItemLabelCss} from 'styles/common';
import Dropdown from 'react-bootstrap/Dropdown';

const DropdownItem = styled(Dropdown.Item)`

`;

const LinkedMenuItemWrap = styled.span`
  ${GeneralDropDownItemCss};
`;

const LinkedMenuItemIcon = styled(Icon)`
  color: inherit;

  &:hover {
    color: inherit;
  }

  ${MenuItemIconCss};
`;

const LinkedMenuItemLabel = styled.span`
  ${MenuItemLabelCss};
  ${UserSelectNoneCss};
`;


function LinkedMenuItem({
                          className,
                          to,
                          iconName,
                          label,
                          disabled,
                          onClick = () => {
                          }
                        }) {
  return (
    <DropdownItem
      disabled={disabled}
      className={className} as={disabled ? 'div' : Link} to={to}>
      <LinkedMenuItemWrap
        className={className}
        onClick={onClick}
        disabled={disabled}
      >
        {iconName && <LinkedMenuItemIcon iconName={disabled ? 'fas fa-circle-notch fa-spin' : iconName}/>}
        <LinkedMenuItemLabel>{label}</LinkedMenuItemLabel>
      </LinkedMenuItemWrap>
    </DropdownItem>

  );
}

LinkedMenuItem.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default LinkedMenuItem;