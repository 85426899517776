import {createAction} from 'redux-actions';
import {
  SET_BROWSER_DIMENSIONS,
  SET_BROWSER_IS_Left_BAR_OPENED,
  SET_IS_FX_READY,
  SET_LOGIN_MODAL_STATUS
} from './types';

export const setBrowserDimensions = createAction(SET_BROWSER_DIMENSIONS);
export const setBrowserIsLeftBarOpened = createAction(SET_BROWSER_IS_Left_BAR_OPENED);
export const setIsFxReady = createAction(SET_IS_FX_READY);
export const setLoginModalStatus = createAction(SET_LOGIN_MODAL_STATUS);