import React from 'react';
import styled from 'styled-components';

import theme from 'styles/theme';
import {scale} from 'styles/common';


const RoundCheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({isSelected, theme: {colors}}) => isSelected ? colors.secondary : colors.lightGrey};
  color: ${({isSelected, theme: {colors}}) => isSelected ? colors.secondary : colors.lightGrey};
  cursor: ${({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer'};
  padding: 3px 4px 3px 3px;
  border-radius: 50%;

  &:hover {
    border-color: ${({theme: {colors}}) => colors.primary};
    color: ${({theme: {colors}}) => colors.primary};
  }

  ${scale([
    ['default', [
      ['width', theme.typo.sizes.h3],
      ['height', theme.typo.sizes.h3],
      ['font-size', theme.typo.sizes.body]
    ]],
    ['560px', [
      ['width', theme.typo.sizes.h4],
      ['height', theme.typo.sizes.h4],
      ['font-size', theme.typo.sizes.small]
    ]],
    ['768px', [
      ['width', theme.typo.sizes.h5],
      ['height', theme.typo.sizes.h5],
      ['font-size', theme.typo.sizes.base]
    ]],
    ['992px', [
      ['width', theme.typo.sizes.body2],
      ['height', theme.typo.sizes.body2],
      ['font-size', theme.typo.sizes.base]
    ]],
    ['1400px', [
      ['width', theme.typo.sizes.body],
      ['height', theme.typo.sizes.body],
      ['font-size', theme.typo.sizes.tiny]
    ]]
  ])}
`;
const RoundCheckboxState = styled.i``;

function RoundCheckbox({disabled, name, value, onChange, ...props}) {
  return (
    <RoundCheckboxWrap
      isSelected={value}
      onClick={() => {
        if (!disabled) {
          onChange({target: {name, value: !value}});
        }
      }}
      {...props}
    >
      {
        value &&
        (
          <RoundCheckboxState
            disabled={disabled}
            className='fas fa-check'
          />
        )
      }

    </RoundCheckboxWrap>
  );
}

export default RoundCheckbox;