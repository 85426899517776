import {
  PAYMENT_TYPES,
  TEXT_LENGTH_LIMITATIONS,
  UNLIMITED_SIZE,
  USER_STRIPE_ACCOUNT_STATUS
} from "config/constants";
import {isPaid, parseInteger} from "utilities/general";
import {isFinite} from "lodash";
import {getStoreState} from "utilities/store";
import {getUserStripeAccountId, getUserStripeAccountStatus} from "../reduxUtils/user/selectors";
import currencies from 'config/currencies';

export function hasCreatorBillingError(value) {
  if (value === PAYMENT_TYPES.PAID.key) {
    const state = getStoreState();
    const stripeAccountId = getUserStripeAccountId(state);
    const stripeAccountStatus = getUserStripeAccountStatus(state);

    if (!stripeAccountId || !stripeAccountStatus) {
      return 'You are missing a ';
    }
    if (stripeAccountId && stripeAccountStatus !== USER_STRIPE_ACCOUNT_STATUS.VERIFIED.key)
      return 'You must complete creating a ';
  }

  return false;
}


export function hasTitleError(title) {
  if (!title) {
    return 'Title is required';
  }

  if (title && title.length > TEXT_LENGTH_LIMITATIONS.WORKSHOP_TITLE_MAX) {
    return `Title has to be shorter then ${TEXT_LENGTH_LIMITATIONS.WORKSHOP_TITLE_MAX} letters`;
  }

  return false;
}

export function hasSummaryError(summary) {
  if (!summary) {
    return 'Summary is required';
  }

  if (summary && summary.length > TEXT_LENGTH_LIMITATIONS.WORKSHOP_SUMMERY_MAX) {
    return `Summary has to be shorter then ${TEXT_LENGTH_LIMITATIONS.WORKSHOP_SUMMERY_MAX} letters`;
  }

  return false;
}

export function hasDescriptionError(description) {
  if (description && description.length > TEXT_LENGTH_LIMITATIONS.WORKSHOP_DESCRIPTION_MAX) {
    return `Description has to be shorter then ${TEXT_LENGTH_LIMITATIONS.WORKSHOP_DESCRIPTION_MAX} letters`;
  }
  return false;
}

export function hasCategoriesError(categories) {
  if (!categories.length) {
    return 'You must select at least 1 category';
  }

  return false;
}

export function hasPriceError(price, paymentType, userCurrency) {
  const numericPrice = +price;
  if (isPaid(paymentType)) {
    if ((!isFinite(numericPrice) || numericPrice <= 0)) {
      return 'You must specify a price for paid content';
    }
    const {minPurchase, maxPurchase, symbol} = currencies[userCurrency];
    if (numericPrice < minPurchase) {
      return `The minimum ticket price is ${minPurchase}${symbol}`;
    }

    if (numericPrice > maxPurchase) {
      return `The maximum ticket price is ${maxPurchase}${symbol}`;
    }
  }

  return false;
}

export function hasParticipantsLimitError(participantsLimit) {
  const numericParticipantsLimit = parseInteger(participantsLimit);
  if (numericParticipantsLimit > UNLIMITED_SIZE) {
    return `Max Participants limit is ${UNLIMITED_SIZE}`;
  } else if (numericParticipantsLimit <= 0) {
    return 'You must specify participants limit';
  }
  return false;
}


export function getContentErrors(content) {
  const errors = {};
  const {
    title,
    summary,
    coverPhoto,
    description,
    paymentType
  } = content;

  const titleError = hasTitleError(title);
  if (titleError) {
    errors.title = titleError;
  }

  if (!coverPhoto) {
    errors.coverPhoto = {show: true, error: 'Please upload a photo cover.'};
  }

  const summaryError = hasSummaryError(summary);
  if (summaryError) {
    errors.summary = summaryError;
  }

  const descriptionError = hasDescriptionError(description);
  if (descriptionError) {
    errors.description = descriptionError;
  }

  const creatorBilling = hasCreatorBillingError(paymentType);
  if (creatorBilling) {
    errors.creatorBilling = creatorBilling;
  }

  return errors;
}