import React from 'react';
import PropTypes from 'prop-types';

import {PageSpinnerWrap, PageStyledSpinner,} from 'components/JSXElements/EventsAndWorkshops';

function Fallback() {
  return (
    <PageSpinnerWrap>
      <PageStyledSpinner/>
    </PageSpinnerWrap>
  );
}

Fallback.propTypes = {
  className: PropTypes.string
};
export default Fallback;