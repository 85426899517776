import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody} from 'react-bootstrap';
import styled, {css} from 'styled-components';

import theme from 'styles/theme';
import {scale} from 'styles/common';

import Icon from 'components/Icon';

const StyledModal = styled(Modal)`
  .custom-width-dialog {
    margin: auto;
    max-width: 450px;

    ${({width}) => {
      return width &&
        css`
          width: ${width}px;
        `
    }}
    ${({max_width}) => {
      return max_width &&
        css`
          max-width: ${max_width}px;
        `
    }}
  }

  & .modal-content {
    background-color: ${({theme: {colors}}) => colors.fill};
    border-radius: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px ${({theme: {padding}}) => padding.pageM}px !important;
  border-bottom: 1px solid ${({theme: {colors}}) => colors.lightGrey};

  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.medium]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]]
  ])}
`;

const CloseButton = styled(Icon)`
  cursor: pointer;
  color: ${({theme}) => theme.colors.darkGrey};

  &:hover {
    color: ${({theme}) => theme.colors.secondary};
  }
`;

const Title = styled.span`
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
`;

const Root = styled(ModalBody)`
  flex-direction: column;
  display: flex;
  padding: 10px ${({theme: {padding}}) => padding.pageD}px 15px !important;
`;

function EmptyModal(
  {
    children,
    onHide = null,
    width,
    show,
    centered,
    title,
    titleIcon,
    displayCloseButton,
    ...props
  }
) {
  return (
    <StyledModal
      width={width}
      onHide={onHide}
      dialogClassName="custom-width-dialog"
      centered={centered}
      show={show}
      {...props}
      // todo:remove this when we fix zoom
      animation={false}
    >
      {
        <Header>
          {title && <Title>{title}</Title>}
          {displayCloseButton && <CloseButton onClick={onHide} className={`fa fa-times`}/>}
        </Header>
      }
      <Root id={"modal-body-inner"}>{children}</Root>
    </StyledModal>
  );
}

EmptyModal.propTypes = {
  width: PropTypes.string,
  show: PropTypes.bool,
  centered: PropTypes.bool,
  title: PropTypes.string,
  displayCloseButton: PropTypes.bool,
  onHide: PropTypes.func
};

export default EmptyModal;
