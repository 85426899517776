import {getRouter} from 'config/websockets';
import {getAccessToken} from 'utilities/auth';

function jwtLoginPromisified(socket) {
  const token = getAccessToken();
  return new Promise((resolve, reject) => {
    socket.emit('route', 'auth', 'jwtLogin', {token}, (res) => {
      if (res.status === 'ok') {
        resolve();
      } else {
        reject(res);
      }
    });
  });
}

export default async function jwtLogin() {
  let socketRouter = getRouter();
  return jwtLoginPromisified(socketRouter.socket);
};