import React, { Suspense, lazy } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAuthIsLoggedIn } from '../../../../reduxUtils/auth/selectors';
import { getUserId } from '../../../../reduxUtils/user/selectors';
import { getAccessControl } from '../../../../reduxUtils/accessControl/selectors';
import {
  getUserFullName,
  getUserAvatarThumbnail,
} from '../../../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
  getBrowserDimensions,
  getLoginModalStatus,
} from '../../../../reduxUtils/browserSettings/selectors';
import { getRates } from '../../../../reduxUtils/browserSettings/thunks';

import WithSideBar from 'layouts/WithSideBar';

import {
  createWorkshopRegistration,
  addWorkshopRegistration,
  deleteWorkshopRegistration,
  setAllWorkshopRegistrations,
  setWorkshopRegistrations,
} from '../../../../reduxUtils/userWorkshopRegistrations/thunks';
import {
  getUserWorkshopRegistrations,
  getUserWorkshopRegistrationsReqParams,
  getWorkshopRegistration,
} from '../../../../reduxUtils/userWorkshopRegistrations/selectors';

import {
  getUserEventRegistrations,
  getUserEventRegistrationsReqParams,
} from '../../../../reduxUtils/userEventRegistrations/selectors';

import Fallback from 'components/Fallback';
import { setAllEventRegistrations } from '../../../../reduxUtils/userEventRegistrations/thunks';
import { getIsChatDetach, getIsChatMinimized } from '../../../../reduxUtils/chat/selectors';
import { setLoginModalStatus } from '../../../../reduxUtils/browserSettings/actions';

const Inspect = lazy(() => import('./Inspect'));

function WorkshopInspect({
  navigate,
  match,
  location,
  isMobile,
  userSettings,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  accessControls,
  fullName,
  avatar,
  getRates,
  dimensions,
  registration,
  userWorkshopRegistrations,
  eventRegistrations,
  setWorkshopRegistrations,
  addWorkshopRegistration,
  createWorkshopRegistration,
  deleteWorkshopRegistration,
  userEventRegistrationsReqParams,
  setAllEventRegistrations,
  setAllWorkshopRegistrations,
  userWorkshopRegistrationsReqParams,
  isChatDetach,
  isChatMinimized,
  setLoginModalStatus,
  showLoginSignupModal,
}) {
  return (
    <WithSideBar
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      isChatMinimized={isChatMinimized && isChatDetach}
    >
      <Suspense fallback={<Fallback />}>
        <Inspect
          navigate={navigate}
          match={match}
          isMobile={isMobile}
          userSettings={userSettings}
          isLoggedIn={isLoggedIn}
          userId={userId}
          accessControls={accessControls}
          getRates={getRates}
          dimensions={dimensions}
          registration={registration}
          userWorkshopRegistrations={userWorkshopRegistrations}
          eventRegistrations={eventRegistrations}
          setWorkshopRegistrations={setWorkshopRegistrations}
          addWorkshopRegistration={addWorkshopRegistration}
          createWorkshopRegistration={createWorkshopRegistration}
          deleteWorkshopRegistration={deleteWorkshopRegistration}
          userEventRegistrationsReqParams={userEventRegistrationsReqParams}
          setAllEventRegistrations={setAllEventRegistrations}
          setAllWorkshopRegistrations={setAllWorkshopRegistrations}
          userWorkshopRegistrationsReqParams={userWorkshopRegistrationsReqParams}
          setLoginModalStatus={setLoginModalStatus}
          showLoginSignupModal={showLoginSignupModal}
        />
      </Suspense>
    </WithSideBar>
  );
}

WorkshopInspect.propTypes = {
  navigate: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  registration: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  accessControls: PropTypes.array,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  dimensions: PropTypes.object,
  setWorkshopRegistrations: PropTypes.func,
  addWorkshopRegistration: PropTypes.func,
  createWorkshopRegistration: PropTypes.func,
  deleteWorkshopRegistration: PropTypes.func,
  userWorkshopRegistrations: PropTypes.array,
  eventRegistrations: PropTypes.array,
  setAllWorkshopRegistrations: PropTypes.func,
  setAllEventRegistrations: PropTypes.func,
  userEventRegistrationsReqParams: PropTypes.object,
  userWorkshopRegistrationsReqParams: PropTypes.object,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state, { workshopId }) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    accessControls: getAccessControl(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    dimensions: getBrowserDimensions(state),
    registration: getWorkshopRegistration(state, workshopId),
    userWorkshopRegistrations: getUserWorkshopRegistrations(state),
    eventRegistrations: getUserEventRegistrations(state),
    userEventRegistrationsReqParams: getUserEventRegistrationsReqParams(state),
    userWorkshopRegistrationsReqParams: getUserWorkshopRegistrationsReqParams(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
    showLoginSignupModal: getLoginModalStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRates,
      setWorkshopRegistrations,
      addWorkshopRegistration,
      createWorkshopRegistration,
      deleteWorkshopRegistration,
      setAllEventRegistrations,
      setAllWorkshopRegistrations,
      setLoginModalStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopInspect);
