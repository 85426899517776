import UserService from 'services/user';
import {setAccessControl} from '../accessControl/actions';

const userService = new UserService();

export function fetchAccessControls({toUserId, toEventId, toOccurrenceId, toWorkshopId} = {}) {
  return dispatch => userService.getAccessControls({toUserId, toEventId, toWorkshopId, toOccurrenceId})
    .then((response) => {
      const {data: {accessControls}} = response;
      dispatch(setAccessControl(accessControls));
      return response;
    })
}