import {createAction} from 'redux-actions';
import {
  SET_TRANSACTIONS,
  ADD_TRANSACTION,
  ADD_TRANSACTIONS,
  UPSERT_TRANSACTION,
  DELETE_TRANSACTION,
  UPDATE_TRANSACTION,
} from './types';


export const setTransactionsAction = createAction(SET_TRANSACTIONS);
export const addTransactionAction = createAction(ADD_TRANSACTION);
export const upsertTransactionAction = createAction(UPSERT_TRANSACTION);
export const addTransactionsAction = createAction(ADD_TRANSACTIONS);
export const deleteTransactionAction = createAction(DELETE_TRANSACTION);
export const updateTransactionAction = createAction(UPDATE_TRANSACTION);
