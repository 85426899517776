import EventService from 'services/event';
import UploadService from 'services/upload';

import { updateEventAction, deleteEventAction } from './actions';
import { updateEventSettingsAction, deleteEventSettingsAction } from '../eventSettings/actions';
import { setEventOccurrencesAction } from '../eventOccurrences/actions';
import { errorHandler } from 'utilities/services';

const eventService = new EventService();
const uploadService = new UploadService();

export function startEvent({ channelId, workshopId }) {
  return (dispatch) =>
    eventService.startEvent({ channelId, workshopId }).then((response) => {
      const {
        data: { event },
      } = response;
      dispatch(updateEventAction(event));
      dispatch(updateEventSettingsAction(event.eventSetting || {}));
      dispatch(setEventOccurrencesAction(event.occurrences || []));
      return response;
    });
}

export function completeEvent(eventId) {
  return (dispatch) =>
    eventService.completeEvent(eventId).then((response) => {
      const {
        data: { event },
      } = response;
      dispatch(updateEventAction(event));
      return response;
    });
}

export function getEvent(eventId) {
  return (dispatch) =>
    eventService.getEvent(eventId).then((response) => {
      const {
        data: { event },
      } = response;
      dispatch(updateEventAction(event));
      return response;
    });
}

// todo: make sure that upload id is loaded with event

export function getEventEager(eventId) {
  return (dispatch) =>
    eventService.getEventEager(eventId).then((response) => {
      const {
        data: { event },
      } = response;
      dispatch(updateEventAction(event));
      dispatch(updateEventSettingsAction(event.eventSetting || {}));
      dispatch(setEventOccurrencesAction(event.occurrences || []));
      // dispatch(setEventOccurrencesAction(processOccurrences(event.occurrences) || []));
      return response;
    });
}

export function getEventUploadSignUrl(event, uploadId) {
  return (dispatch) =>
    uploadService
      .getUploadSignedUrl(uploadId)
      .then((response) => {
        const {
          data: { url },
        } = response;
        dispatch(
          updateEventAction({
            ...event,
            upload: {
              ...event.upload,
              url,
            },
          })
        );
        return response;
      })
      .catch((err) => {
        errorHandler(err);
      });
}

export function updateEvent(eventId, event) {
  return (dispatch) =>
    eventService.updateEvent(eventId, event).then((response) => {
      const {
        data: { event },
      } = response;
      dispatch(updateEventAction(event));
      return response;
    });
}

export function resetEventActions() {
  return (dispatch) => {
    dispatch(deleteEventAction());
    dispatch(deleteEventSettingsAction());
    dispatch(setEventOccurrencesAction([]));
  };
}

export function deleteEvent(eventId) {
  return (dispatch) =>
    eventService.deleteEvent(eventId).then((response) => {
      resetEventActions()(dispatch);
      return response;
    });
}

export function updateEventCoverPhoto(eventId, data, requestConfig) {
  let headers = { headers: { 'Content-Type': 'multipart/form-data' } };
  const _requestConfig = Object.assign(requestConfig, headers);
  return (dispatch) =>
    eventService.updateEventCoverPhoto(eventId, data, _requestConfig).then((response) => {
      const {
        data: { coverPhoto, coverPhotoThumbnail },
      } = response;
      dispatch(updateEventAction({ coverPhoto, coverPhotoThumbnail }));
      return response;
    });
}

/*
export function getEventIncomplete() {
  return dispatch => eventService
    .getEventIncomplete()
    .then((response) => {
      return handleEager(dispatch, response);
    });
}
*/
