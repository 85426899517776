import React from 'react';
import styled from 'styled-components';
import ContentMenuItem from 'components/MenuItems/ContentMenuItem';
import Icon from 'components/Icon';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

import { Dropdown } from 'react-bootstrap';
import { FB_APP_ID, TWITTER_ID } from 'config/constants';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UserSelectNoneCss } from 'styles/common';
import { AL_EVENT, AL_EVENT_ACTION, AL_EVENT_STATUS, alShare } from 'utilities/analytics';
import { successHandler } from 'utilities/services';
import Action from 'components/ActionsBar/component/Action';
import { appName } from 'config/config';

const ActionBarWrap = styled(Dropdown)`
  ${UserSelectNoneCss};
`;

const ActionBarToggle = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 5px;
  cursor: pointer;
`;

const ActionBarIcon = styled(Icon)`
  width: auto;
  height: auto;
`;

function Share({
  url = '',
  title = '',
  summary = '',
  hashtags = [],
  content,
  occurrenceId,
  isWorkshop,
  ...props
}) {
  const CustomToggle = React.forwardRef(({ children, onClick, classname }, ref) => (
    <ActionBarToggle
      classname={classname}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </ActionBarToggle>
  ));

  function onClickShare(shareMedium) {
    alShare({
      alEvent: AL_EVENT.GENERAL.share,
      eventAction: AL_EVENT_ACTION.click,
      eventStatus: AL_EVENT_STATUS.success,
      content,
      occurrenceId,
      shareMedium,
      isWorkshop,
    });
  }

  return (
    <ActionBarWrap drop={'left'} {...props}>
      <ActionBarWrap.Toggle as={CustomToggle}>
        <ActionBarIcon iconName="fas fa-share fa-sm" />
      </ActionBarWrap.Toggle>
      <ActionBarWrap.Menu style={{ minWidth: 'auto' }}>
        {
          <ContentMenuItem>
            <FacebookShareButton url={url} quote={title} hashtag={'#Livly.tv'}>
              <Action
                label="Facebook"
                iconName="fab fa-facebook-square"
                onClick={() => onClickShare('facebook')}
              />
            </FacebookShareButton>
          </ContentMenuItem>
        }
        {
          <ContentMenuItem>
            <FacebookMessengerShareButton appId={FB_APP_ID} url={url}>
              <Action
                style={{ width: '100%' }}
                label="Messenger"
                iconName="fab fa-facebook-messenger"
                onClick={() => onClickShare('facebook-messenger')}
              />
            </FacebookMessengerShareButton>
          </ContentMenuItem>
        }
        {
          <ContentMenuItem>
            <TwitterShareButton
              url={url}
              title={title}
              hashtags={['Livly.tv', ...hashtags]}
              via={TWITTER_ID}
              related={[TWITTER_ID]}
            >
              <Action
                label="Twitter"
                iconName="fab fa-twitter"
                onClick={() => onClickShare('twitter')}
              />
            </TwitterShareButton>
          </ContentMenuItem>
        }
        {
          <ContentMenuItem>
            <LinkedinShareButton url={url} title={title} source={url} summary={summary}>
              <Action
                label="Linkedin"
                iconName="fab fa-linkedin"
                onClick={() => onClickShare('linkedin')}
              />
            </LinkedinShareButton>
          </ContentMenuItem>
        }
        {
          <ContentMenuItem>
            <WhatsappShareButton url={url} title={title}>
              <Action
                label="Whatsapp"
                iconName="fab fa-whatsapp"
                onClick={() => onClickShare('whatsapp')}
              />
            </WhatsappShareButton>
          </ContentMenuItem>
        }
        {
          <ContentMenuItem>
            <CopyToClipboard text={url}>
              <Action
                label="Copy"
                iconName="fas fa-link"
                onClick={() => {
                  successHandler('Link Copied');
                  onClickShare('link copied');
                }}
              />
            </CopyToClipboard>
          </ContentMenuItem>
        }
      </ActionBarWrap.Menu>
    </ActionBarWrap>
  );
}

Share.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  hashtags: PropTypes.array,
};
export default React.memo(Share);
