import {createAction} from 'redux-actions';
import {
  SET_HOME_EVENTS,
  ADD_HOME_EVENT,
  ADD_HOME_EVENTS,
  UPDATE_HOME_EVENT,
  DELETE_HOME_EVENT
} from './types';

export const setHomeEvents = createAction(SET_HOME_EVENTS);
export const addHomeEvent = createAction(ADD_HOME_EVENT);
export const addHomeEvents = createAction(ADD_HOME_EVENTS);
export const updateHomeEvent = createAction(UPDATE_HOME_EVENT);
export const deleteHomeEvent = createAction(DELETE_HOME_EVENT);