import React, {useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from 'styled-components';

import {
  DeletePollComponent,
  PollActionButton,
  PollWrapCSS
} from "components/Chat/components/PollsContainer/components/JSXElements";
import Question from "../Question";
import Options from "../Options";
import PollTimer from "components/Chat/components/PollsContainer/components/DisplayPoll/components/PollTimer";
import {getRemainingTimeToVote, getTotalVotes, getUserVote, POLL_STATUS} from "utilities/polls.const";
import {updatePollVisibility} from "../../../../../../../../reduxUtils/polls/thunks";

const PollWrap = styled.div`
  ${PollWrapCSS};
  ${({published}) =>
    published
      ? css`
        background-color: ${({theme: {colors}}) => colors.purpleFeather};
      `
      : css`
        background-color: #F2F2F2;
      `
  };
`;


function PollElement({
                       isAdmin,
                       userId,
                       poll,
                       deletePoll,
                       setDialog,
                     }) {
  const {
    id,
    PollOptions = [],
    text = '',
    timerInSec = 0,
    // showOnChat = true,
    // alwaysShow = true,
    publishedAt,
    status,
  } = poll;

  let remainingVoteTime = 0;
  let voteTimePassed = false;
  if (timerInSec && status === POLL_STATUS.PUBLISHED.key) {
    const voteTimeObj = getRemainingTimeToVote(publishedAt, timerInSec);
    remainingVoteTime = voteTimeObj.remainingVoteTime;
    voteTimePassed = voteTimeObj.voteTimePassed;
  }

  const [disableVotes, setDisableVotes] = useState(voteTimePassed);

  function onUnPublishClick() {
    setDialog(current => {
      return {
        ...current,
        show: true,
        title: `Poll Unpublish`,
        confirmationText: `Are you sure you want to unpublish the poll`,
        action: 'Unpublish',
        onConfirm: () => {
          updatePollVisibility(id, POLL_STATUS.UNPUBLISHED.key);
          current.onCancel();
        }
      }
    });
  }

  function onPublishClick() {
    updatePollVisibility(id, POLL_STATUS.PUBLISHED.key);
  }

  function onDelete() {
    setDialog(current => {
      return {
        ...current,
        show: true,
        title: `Poll Delete`,
        confirmationText: `Are you sure you want to delete the poll`,
        action: 'Delete',
        onConfirm: () => {
          deletePoll(id)
          current.onCancel();
        }
      }
    });

  }

  let buttonText;
  let onClick;
  if (status === POLL_STATUS.PUBLISHED.key) {
    buttonText = 'unpublish';
    onClick = onUnPublishClick;
  } else {
    buttonText = 'publish';
    onClick = onPublishClick;
  }

  function onPollTimerDone() {
    setDisableVotes(true);
  }

  const totalVotes = getTotalVotes(poll);

  return (
    <PollWrap published={status === POLL_STATUS.PUBLISHED.key}>
      {
        isAdmin &&
        <DeletePollComponent onDelete={onDelete} />
      }
      <Question
        value={text}
      />
      <Options
        PollId={id}
        PollOptions={PollOptions}
        totalVotes={totalVotes}
        disabled={status !== POLL_STATUS.PUBLISHED.key || disableVotes}
        publishedAt={publishedAt}
        timerInSec={timerInSec}
        userSelected={getUserVote(poll, userId)?.PollOptionId}
      />

      {
        !!timerInSec &&
        <PollTimer timer={remainingVoteTime} max={timerInSec} onDone={onPollTimerDone} />
      }

      {
        isAdmin &&
        <PollActionButton
          onClick={onClick}
        >
          {buttonText}
        </PollActionButton>
      }
    </PollWrap>
  );
}

PollElement.propTypes = {
  isAdmin: PropTypes.bool,
  userId: PropTypes.number,
  poll: PropTypes.object,
  deletePoll: PropTypes.func,
  setDialog: PropTypes.func,
};

export default (React.memo(PollElement));
