import {getRouter} from 'config/websockets';

const emit = (socket, toRoom, data, func) => {
  socket.emit('route', 'chat', 'joinRoom', [toRoom, data], func);
}

function parseMessageData(data) {
  const {messages = []} = data;
  const parsedMessages = messages.map((el) => {
    return el.data;
  });
  return {
    ...data,
    messages: parsedMessages
  }
}

function joinRoomPromisified(socket, toRoom, data) {
  return new Promise((resolve, reject) => {
    emit(socket, toRoom, data, (res) => {
      if (res.status === 'ok') {
        resolve(parseMessageData(res.data));
      } else {
        reject(res.error);
      }
    });
  });
}

export default async function chatJoinRoom(toRoom, data, async) {
  let socketRouter = getRouter();
  if (async) {
    emit(socketRouter.socket, toRoom, data);
    return;
  }
  return joinRoomPromisified(socketRouter.socket, toRoom, data);
};
