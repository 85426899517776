import {createAction} from 'redux-actions';
import {
  SET_HOME_UPCOMING_WORKSHOPS,
  ADD_HOME_UPCOMING_WORKSHOP,
  ADD_HOME_UPCOMING_WORKSHOPS,
  UPDATE_HOME_UPCOMING_WORKSHOP,
  DELETE_HOME_UPCOMING_WORKSHOP
} from './types';

export const setHomeUpcomingWorkshops = createAction(SET_HOME_UPCOMING_WORKSHOPS);
export const addHomeUpcomingWorkshop = createAction(ADD_HOME_UPCOMING_WORKSHOP);
export const addHomeUpcomingWorkshops = createAction(ADD_HOME_UPCOMING_WORKSHOPS);
export const updateHomeUpcomingWorkshop = createAction(UPDATE_HOME_UPCOMING_WORKSHOP);
export const deleteHomeUpcomingWorkshop = createAction(DELETE_HOME_UPCOMING_WORKSHOP);