import {createAction} from 'redux-actions';
import {
  SET_WORKSHOP_OCCURRENCES,
  ADD_WORKSHOP_OCCURRENCE,
  UPDATE_WORKSHOP_OCCURRENCE,
  DELETE_WORKSHOP_OCCURRENCE
} from './types';

export const setWorkshopOccurrencesAction = createAction(SET_WORKSHOP_OCCURRENCES);
export const addWorkshopOccurrenceAction = createAction(ADD_WORKSHOP_OCCURRENCE);
export const updateWorkshopOccurrenceAction = createAction(UPDATE_WORKSHOP_OCCURRENCE);
export const deleteWorkshopOccurrenceAction = createAction(DELETE_WORKSHOP_OCCURRENCE);