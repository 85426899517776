import React, {Suspense, lazy} from 'react';

import CenteredWithBackground from 'layouts/CenteredWithBackground';
import Fallback from 'components/Fallback';

const ResetPassword = lazy(() => import('./ResetPassword'));

export default function ResetPasswordWrap() {
  return (
    <CenteredWithBackground>
      <Suspense fallback={<Fallback />}>
        <ResetPassword />
      </Suspense>
    </CenteredWithBackground>
  );
}
