// import {createCachedSelector} from 're-reselect';

export const getUserFollowings = (state) => state.userFollowings;
// export const getFollow = createCachedSelector(
//   getUserFollowings,
//   (state, channelId) => channelId,
//   (userFollowings, channelId) => {
//     return userFollowings.find(
//       (item) => {
//         return (+item.toUserId === +channelId);
//       });
//   }
// )(
//   (state, channelId) => channelId // Use dataType as cacheKey
// );

export const getFollow = (state, channelId) => {
  const userFollowings = getUserFollowings(state);
  return userFollowings.find((item) => {
    return +item.toUserId === +channelId;
  });
};
