import {createSelector} from 'reselect';

export const getFileUploader = state => state.fileUploader;
export const getFileUploaderFiles = createSelector(getFileUploader, getFileUploader => getFileUploader.files);

export const getFile = function (fileName) {
  return createSelector(getFileUploaderFiles, files => {
    const foundIndex = files.findIndex((file) => file.name === fileName);
    return foundIndex > -1
      ? files[foundIndex]
      : false;
  });
};