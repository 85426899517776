import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from 'styles/theme';
import { scale, UserSelectNoneCss } from 'styles/common';

import BarItem from './components/BarItem';

const LeftSideBarWrap = styled.div`
  position: relative;
  margin-top: -1px;
  ${UserSelectNoneCss};
`;

const LeftSideBarContainer = styled.div`
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors.fill};
  border-right: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  padding: 0 ${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['padding', `0px ${theme.padding.pageD}px`]]]])}
  font-weight: bold;
`;

function LeftSideBar({ closeToHide = false, isLeftBarOpened, options = [] }) {
  if (!isLeftBarOpened && closeToHide) {
    return null;
  }

  return (
    <LeftSideBarWrap>
      <LeftSideBarContainer>
        {options.map(({ label, iconName, linkTo, isDisabled }) => (
          <BarItem
            key={label}
            isOpened={isLeftBarOpened}
            label={label}
            iconName={iconName}
            linkTo={linkTo}
            isDisabled={isDisabled}
          />
        ))}
      </LeftSideBarContainer>
    </LeftSideBarWrap>
  );
}

LeftSideBar.propTypes = {
  isLeftBarOpened: PropTypes.bool,
  closeToHide: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  options: PropTypes.array,
};

export default LeftSideBar;
