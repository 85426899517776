import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from 'react-avatar';

import {COLOR_ARRAY} from 'config/constants';

const StyledAvatarWrap = styled(Avatar)`
  display: block;
  --dim: 32px;
  width: var(--dim);
  height: var(--dim);
  min-width: var(--dim);
  max-width: var(--dim);
  min-height: var(--dim);
  max-height: var(--dim);

  border-radius: 100%;
`;


function StyledAvatar({className, url = '', backupUrl = '', name = '', ...props}) {
  return (
    <StyledAvatarWrap
      {...props}
      className={className}
      unstyled={false}
      maxInitials={2}
      title={' '}
      src={url || backupUrl}
      round={true}
      color={!name ? 'gray' : Avatar.getRandomColor(name, COLOR_ARRAY)}
      name={!name ? '?' : name}
    />
  );
}

StyledAvatar.propTypes = {
  url: PropTypes.string,
  backupUrl: PropTypes.string
};

export default StyledAvatar;
