import React from 'react';
import styled from 'styled-components';


const TextInputWrap = styled.input`
  background: none;
  border: none;
  outline: none;
  border-radius: 0;
  margin: 0;
  padding: 5px 0;
  width: 100%;
  min-width: 0;

  &:focus {
    box-shadow: none;
    outline: none;
    background: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 100%;
    color: #999999;
  }

  :-ms-input-placeholder {
    font-size: 100%;
    color: #999999;
  }

  &.myTextInputHolder {
    outline: none !important;
  }
`;

function TextInput(
  {
    setRef = () => {},
    type = 'text',
    ...props
  }
) {
  return (
    <TextInputWrap className={'myTextInputHolder'} ref={setRef} type={type} {...props} />
  );
}

export default TextInput;
