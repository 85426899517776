import {handleActions} from 'redux-actions';
import {
  SET_SOCKET_CONNECTION_STATUS,
} from './types';

let initialState = {
  socketConnected: false,
};

export default handleActions({
  [SET_SOCKET_CONNECTION_STATUS]: (state, {payload}) => {
    return {
      ...state,
      socketConnected: payload
    };
  },
}, {...initialState});
