import {handleActions} from 'redux-actions';
import {
  SET_HOME_WORKSHOPS,
  ADD_HOME_WORKSHOP,
  ADD_HOME_WORKSHOPS,
  UPDATE_HOME_WORKSHOP
  //UPDATE_HOME_WORKSHOP,
  //DELETE_HOME_WORKSHOP
} from './types';

import {
  reduxAddWorkshopHelper,
  reduxAddWorkshopsHelper,
  reduxSetWorkshopHelper, reduxUpdateWorkshopsHelper
} from "../helpers/workshops";


/*


[
  {
    rate: 5,
    comment: 'Really awesoasda sda sda me content !! word asd th the time so asd ame SOmeh kahdasd asd  ad asdasd ad ',
    fromUserFullName: 'Jontra Vulta',
    fromUserId: 19
  },
  {
    rate: 4,
    fromUserFullName: 'Jourge maka',
    fromUserId: 12
  },
  {
    rate: 4.5,
    fromUserFullName: 'Sam Eli',
    fromUserId: 48
  },
  {
    rate: 2.5,
    comment: 'Dont waste your time on this content',
    fromUserFullName: 'Mac Cain',
    fromUserId: 33
  },
  {
    rate: 5,
    fromUserFullName: 'Mosha Dosha',
    fromUserId: 400
  },
  {
    rate: 3,
    fromUserFullName: 'Some Doat',
    fromUserId: 130
  },
]
*/
export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_HOME_WORKSHOPS]: (state, {payload}) => {
    return reduxSetWorkshopHelper(state, payload);
  },
  [ADD_HOME_WORKSHOP]: (state, {payload}) => {

    return reduxAddWorkshopHelper(state, payload);
  },
  [ADD_HOME_WORKSHOPS]: (state, {payload}) => {
    return reduxAddWorkshopsHelper(state, payload);
  },
  [UPDATE_HOME_WORKSHOP]: (state, {payload}) => {
    return reduxUpdateWorkshopsHelper(state, payload);
  },
  /*

  [HOME_DELETE_WORKSHOP]: (state, { payload }) => {
    const workshopId = payload;
    const workshops = [...state];

    const foundIndex = workshops.findIndex((occurrence) => occurrence.id === workshopId);
    if( foundIndex > -1 ) {
      workshops.splice(foundIndex, 1);
      return workshops;
    }

    return state;
  },
  */
}, {...initialState});