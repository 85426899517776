import qs from 'qs';

import {
  CLOUDINARY_HOST,
  EVENT_SIZE_LIMITATIONS,
  PAYMENT_TYPES,
  PERMISSIONS,
  PERMISSIONS_ACTIONS,
  SCREEN_SIZES,
  TIME_UNIT,
  TRANSACTION_STATUS,
} from 'config/constants';

import { CATEGORIES } from 'config/categories';
import { differenceWith, flattenDeep, forEach, isEmpty, isEqual, take } from 'lodash';
import { HOST } from 'config/config';
import { fetchAccessControls } from '../reduxUtils/accessControl/thunks';
import canView from 'permissions/actionFunctions/events/can-view';
import { getStore } from 'utilities/store';
import canViewOccurrence from 'permissions/actionFunctions/events/can-view-occurrence';
import { updateTransactionAction } from '../reduxUtils/transactions/actions';
import { getNowUnix } from 'utilities/date';
import currencies from 'config/currencies';
import { updateUserSettingsAction } from '../reduxUtils/userSettings/actions';
import { getCountryOfClient } from 'utilities/location';

export function getChannelBaseUrl(channelId, adminView = false, fullPath = false) {
  return `${fullPath ? window.location.origin : ''}/channel/${channelId}${
    adminView ? `?adminView=1` : ''
  }`;
}

export function getSocialMediaLink({ redirectTo, EventId, WorkshopId }) {
  const content = EventId ? `EventId=${EventId}` : `WorkshopId=${WorkshopId}`;
  return `${HOST}/social-media/quick-links?redirectTo=${redirectTo}&${content}`;
}

export function isProperId(id) {
  return id !== 'undefined' && id !== 'null' && id !== undefined && id !== null;
}

export function getQuery() {
  const { search } = document.location;
  if (search && search.charAt(0) === '?') {
    return qs.parse(search.substring(1));
  }
  return {};
}

export function truncate(length = 50, str = '') {
  return str.length > length ? `${str.substring(0, length - 3)}...` : str;
}

export function getEncodedRedirectPath() {
  const { pathname, search } = document.location;
  return `?redirect=${encodeURIComponent(`${pathname}${search}`)}`;
}

export function getDecodedRedirectPath() {
  const { redirect } = getQuery();
  if (redirect) {
    return decodeURIComponent(redirect) || '';
  }
  return '';
}

export function getChannelIdFromPath() {
  const { search } = document.location;
  if (search && search.charAt(0) === '?') {
    let { channelId } = qs.parse(search.substring(1));
    return channelId || '';
  }

  return '';
}

export function isActiveRoute({ pathname }, route, exect) {
  if (exect && pathname === route) {
    return true;
  } else if (!exect && pathname.indexOf(route) === 0) {
    return true;
  }
  return false;
}

export function isMobile(dimensions) {
  return dimensions.width < SCREEN_SIZES.MOBILE;
}

export function isTablet(dimensions) {
  return dimensions.width < SCREEN_SIZES.TABLET;
}

export function matchUrtToPathname(url, pathname) {
  const regex = new RegExp(url.replace(/\//g, '\\/') + '/.+');
  return regex.test(pathname);
}

export function getIdFromRoute(url, pathname) {
  const regex = new RegExp(url.replace(/\//g, '\\/') + '/([^\\/]+)');
  return (regex.exec(pathname) || [])[1] || false;
}

export function bindItemToActions(actions, item) {
  return (actions || []).map((action) => {
    action.onClick = action.onClick.bind(this, item);
    return action;
  });
}

export function getSettingsKeyName(componentName, tabName) {
  return `${componentName}_${tabName}`;
}

export function constToSelectOptions(constant, placeHolder, selectedKey) {
  let options = Object.keys(constant).map((key) => {
    const option = constant[key];
    let selected = {};
    if (selectedKey && selectedKey === key) {
      selected = { selected: 'selected' };
    }
    return {
      text: option.value,
      value: option.key,
      ...selected,
    };
  });

  if (placeHolder) {
    options = [
      {
        text: placeHolder,
        value: placeHolder,
        disabled: 'disabled',
      },
      ...options,
    ];
  }
  return options;
}

export function findEventStateFile(files, eventId) {
  return files.find(
    ({ flowOpts }) => flowOpts && flowOpts._query && flowOpts._query.EventId === eventId
  );
}

export function hasFile({ id, upload }, files) {
  return !!(findEventStateFile(files, id) || (upload && upload.id));
}

export function isFree(paymentType) {
  return paymentType === PAYMENT_TYPES.FREE.key;
}

// export function isCharity(paymentType) {
//   return paymentType === PAYMENT_TYPES.CHARITY.key;
// }

export function isPaid(paymentType) {
  return paymentType === PAYMENT_TYPES.PAID.key;
}

export function buildCategories() {
  const resultCategories = [];
  CATEGORIES.forEach((parentCat) => {
    const { value, label, sub } = parentCat;
    resultCategories.push({ value, label });
    if (sub && sub.length) {
      sub.forEach(({ value, label }) => {
        resultCategories.push({ value, label: `${parentCat.label} - ${label}` });
      });
    }
  });

  return resultCategories;
}

export function fixCategories(categoriesStr = []) {
  const fixedCategories = [...categoriesStr];
  CATEGORIES.forEach((parentCat) => {
    const { value, label, sub } = parentCat;
    const parentIndex = fixedCategories.indexOf(value);
    if (parentIndex > -1) {
      fixedCategories[parentIndex] = { value, label };
    }

    if (sub && sub.length) {
      sub.forEach(({ value, label }) => {
        const subIndex = fixedCategories.indexOf(value);
        if (subIndex > -1) {
          fixedCategories[subIndex] = { value, label: `${parentCat.label} - ${label}` };
        }
      });
    }
  });

  return fixedCategories;
}

export function findCategoryByValue(categoryValue) {
  const pL = CATEGORIES.length;
  for (let pI = 0; pI < pL; pI++) {
    const parentCat = CATEGORIES[pI];
    const { value, sub } = parentCat;
    if (categoryValue === value) {
      return parentCat;
    }

    if (sub && sub.length) {
      const sL = sub.length;
      let sI = 0;
      for (sI = 0; sI < sL; sI++) {
        const subCat = sub[sI];
        if (categoryValue === subCat.value) {
          return subCat;
        }
      }
    }
  }

  return false;
}

export function buildQuery({ page, pageSize, offset, limit, order, filters, isLive, isWorkshop }) {
  let query = {};

  if (page && page > 0) {
    query.page = page;
  }

  if (pageSize && pageSize > 0) {
    query.pageSize = pageSize;
  }

  if (offset >= 0) {
    query.offset = offset;
  }

  if (limit && limit > 0) {
    query.limit = limit;
  }

  if (order) {
    query.order = order;
  }

  if (isLive) {
    query.isLive = isLive;
  }

  if (isWorkshop) {
    query.isWorkshop = isWorkshop;
  }

  if (filters && Object.keys(filters).length) {
    query.filters = filters;
    if (filters.category && filters.category === 'all') {
      delete query.filters.category;
    }
  }

  return query;
}

export const destructuringKey = ({ favoriteId, followingId, id }) =>
  +favoriteId || followingId || id;
export const destructuringPurchasesContentKey = ({ id, occurrences }) => {
  if (!occurrences || !occurrences.length) {
    return id;
  }
  const flattenOccurrences = flattenDeep(occurrences);
  return flattenOccurrences[0].id;
};

export const comparator = (itemA, itemB) => {
  const a = destructuringKey(itemA);
  const b = destructuringKey(itemB);
  return a === b;
};

export function buildCustomQuery(
  deletion,
  dataSet,
  { page, totalPages, totalRows, pageSize, rows = [] }
) {
  if (!dataSet || !dataSet.length) return null;

  let pRows = rows;
  const deleteIds = differenceWith(pRows, dataSet, comparator);
  const properties = {
    page,
    pageSize,
    totalRows,
    totalPages,
  };

  if (deleteIds.length > 0) {
    deletion({
      deleteIds,
      properties,
    });
    pRows = differenceWith(rows, deleteIds, comparator);
  }

  const totalItem = (page + 1) * pageSize;
  const itemsToFetch = totalItem - pRows.length;
  if (itemsToFetch <= 0) return null;

  const diff = take(differenceWith(dataSet, pRows, comparator), itemsToFetch);
  const ids = diff.map(({ id }) => id);

  if (!ids.length) return null;

  return {
    queryReq: { ids: ids },
    properties: properties,
  };
}

export function getIsAdminView() {
  const { search } = document.location;
  if (search && search.charAt(0) === '?') {
    let { adminView } = qs.parse(search.substring(1));
    return adminView;
  }

  return '';
}

export function generateFullName({ firstName, lastName }) {
  if (!firstName || !lastName) {
    return '';
  }
  return `${firstName} ${lastName}`;
}

export function findHighestPermission(accessControl = []) {
  const permissionsOrder = [
    PERMISSIONS.subscription.MEMBERSHIP_ENTERPRISE,
    PERMISSIONS.subscription.MEMBERSHIP_PREMIUM,
    PERMISSIONS.subscription.MEMBERSHIP_GOLD,
    PERMISSIONS.subscription.MEMBERSHIP_FREE,
  ];

  const l = permissionsOrder.length;
  let i = 0;
  for (i; i < l; i++) {
    const permissionName = permissionsOrder[i];
    const foundPermission = accessControl.find(({ permission, action }) => {
      return permission === permissionName && action === PERMISSIONS_ACTIONS.ALLOW.key;
    });
    if (foundPermission) {
      return EVENT_SIZE_LIMITATIONS[permissionName];
    }
  }

  return PERMISSIONS.subscription.MEMBERSHIP_FREE;
}

export function cloudinaryUrlWithTrans(
  { cloud, resourceType, type, version, publicId, format },
  { height, width }
) {
  if (!cloud) {
    return null;
  }
  return `${CLOUDINARY_HOST}/${cloud}/${resourceType}/${type}/c_limit,h_${height},w_${width}/v${version}/${publicId}.${format}`;
}

let _isMobileDevice = null;

export function isMobileDevice() {
  if (_isMobileDevice !== null) {
    return _isMobileDevice;
  }

  _isMobileDevice =
    /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return _isMobileDevice;
}

function BeforeUnload(e) {
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = 'Data will be lost if you leave the page, are you sure?';
}

export function SetPreventRefreshWarning() {
  window.addEventListener('beforeunload', BeforeUnload, { capture: true });
}

export function RemovePreventRefreshWarning() {
  window.removeEventListener('beforeunload', BeforeUnload, { capture: true });
}

let debouncer = null;

export function debounceFunction(fn = () => {}, wait = 500, debounceRef) {
  if (debounceRef) {
    if (debounceRef.debouncer) {
      clearTimeout(debounceRef.debouncer);
      debounceRef.debouncer = setTimeout(fn, wait);
    }
  } else {
    if (debouncer) {
      clearTimeout(debouncer);
    }
    debouncer = setTimeout(fn, wait);
  }
}

export function parseInteger(input) {
  const numeric = +input;
  return isNaN(numeric) ? -1 : numeric;
}

export function subsStr(str, start, includeStart = true, end = '', includeEnd = true) {
  let startReg = start;
  let endReg = end;

  if (!includeStart) {
    startReg = `(?<=${startReg})`;
  }
  if (!includeEnd && end) {
    endReg = `(?=${endReg})`;
  }
  const regex = new RegExp(`(${startReg}(([^ \n])*)${endReg})`);
  return (regex.exec(str) || [])[1] || false;
}

export function extractUrl(str) {
  return subsStr(str, `https?:\\/\\/`);
}

export function isObject(value) {
  return typeof value == 'object' || value instanceof Object;
}

export function isString(value) {
  return typeof value == 'string' || value instanceof String;
}

export function isArray(value) {
  return Array.isArray(value);
}

export function hasErrors(object) {
  for (const property in object) {
    if (object[property]) {
      return true;
    }
  }
  return false;
}

export function categoriesParser(categories = [], separator = '|') {
  return categories.reduce((previousValue, category) => {
    return previousValue + (isObject(category) ? category.value : category) + separator;
  }, '');
}

export function checkClosestNumber(number, checkWithArr) {
  const reducer = (previousValue, currentValue) => {
    const diff = Math.abs(number - currentValue);
    if (!previousValue.diff) {
      previousValue.diff = diff;
      previousValue.val = currentValue;
    } else {
      if (diff < previousValue.diff) {
        previousValue.diff = diff;
        previousValue.val = currentValue;
      }
    }
    return previousValue;
  };

  const res = checkWithArr.reduce(reducer, { val: null, diff: null });
  return res.val;
}

const convertorCompact = new Intl.NumberFormat('en', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumSignificantDigits: 3,
});

export function compactNumber(number) {
  return convertorCompact.format(+number);
}

const convertor = new Intl.NumberFormat();

//this will convert 1000 -> 1,000
export function fixNumber(number) {
  return convertor.format(+number);
}

/* this is for horizontal scroll of content */
export function getMaxTilesHorizontal(pageSize, tileWidth) {
  const numberOfTiles = Math.round((window.innerWidth - 100) / tileWidth) + 1;
  return Math.max(pageSize, numberOfTiles, 2);
}

export function getHorizontalSkeleton(rows, pageSize, tileWidth) {
  const max = getMaxTilesHorizontal(pageSize, tileWidth);
  return rows.length ? (pageSize ? pageSize - 1 : max - 1) : max;
}

/**********************************************************/

/* when showing tiles in page and the scroll is vertical*/
export function getMaxTilesFitAllPage(isMobile) {
  const windowWidth = isMobile ? window.innerWidth : window.innerWidth - 340;
  const tileWidth = 330; //todo:in the future will more dynamic
  const numberOfTilesHorizontal = Math.round(windowWidth / tileWidth); //tile width is around 300px
  const tileHeight = 350; //todo:in the future will more dynamic
  const pageSizeStep = numberOfTilesHorizontal * 2;
  const totalTiles = window.innerHeight > tileHeight ? pageSizeStep * 3 : pageSizeStep * 2;
  return {
    maxTiles: Math.max(totalTiles, pageSizeStep),
    pageSizeStep,
    numberOfTilesHorizontal,
  };
}

export function getVerticalScrollSkeletons(rows, isMobile) {
  const max = getMaxTilesFitAllPage(isMobile);
  let skeletons = max.maxTiles;
  if (rows?.length) {
    const residue = rows?.length % max.numberOfTilesHorizontal;
    skeletons = max.pageSizeStep - max.numberOfTilesHorizontal + residue;
  }
  return skeletons;
}

export function getRandomInt(max = 1000) {
  return Math.floor(Math.random() * max);
}

export function sleep(delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

export async function fetchAccessControlsPolling({ transaction, userId }) {
  let tries = 10;
  let intervalTime = 2000;
  const { dispatch } = getStore();
  dispatch(updateTransactionAction({ ...transaction, isPolling: true }));

  for (let i = 0; i < tries; i++) {
    const { EventId, OccurrenceId, WorkshopId } = transaction;
    const params = { toEventId: EventId, toOccurrenceId: OccurrenceId, toWorkshopId: WorkshopId };
    const response = await dispatch(fetchAccessControls(params)).catch((err) => {
      const errorMsg = `error when trying to get the access control for eventId ${EventId}, occurrenceId ${OccurrenceId}, workshopId ${WorkshopId}`;
      console.log(errorMsg, err);
      return new Error(errorMsg);
    });
    if (response?.data?.accessControls) {
      const {
        data: { accessControls },
      } = response;
      const canViewContent = OccurrenceId
        ? canViewOccurrence(OccurrenceId, WorkshopId, userId, accessControls)
        : canView(EventId, WorkshopId, userId, accessControls);
      if (canViewContent) {
        dispatch(
          updateTransactionAction({
            ...transaction,
            status: TRANSACTION_STATUS.FULFILLED.value,
            isPolling: false,
          })
        );
        return Promise.resolve();
      }
    }

    await sleep(intervalTime);
  }

  dispatch(updateTransactionAction({ ...transaction, isPolling: false }));
  return Promise.reject();
}

export function generatePollId() {
  return `p_${getRandomInt()}_${getNowUnix()}`;
}

export function generatePollOptionId() {
  return `o_${getRandomInt()}_${getNowUnix()}`;
}

export function getTimeInSec(time, unitKey) {
  return TIME_UNIT[unitKey].unit * +time;
}

export function isSubsetObject(obj, subObj) {
  let matched = true;
  forEach(subObj, (value, key) => {
    if (!isEqual(value, obj[key])) {
      matched = false;
      return matched;
    }
  });
  return matched;
}

export const validator = {
  isEmail: (value) => {
    // let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    let regex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    ); // eslint-disable-line

    return regex.test(value);
  },
};

export function errorsObjectHoldErrors(object) {
  if (!object || isEmpty(object)) {
    return false;
  }

  for (const property in object) {
    if (object[property]) {
      if (isObject(object[property])) {
        const res = errorsObjectHoldErrors(object[property]);
        if (res) {
          return true;
        }
      }
      if (isArray(object[property])) {
        if (object[property].length > 0) {
          return true;
        }
      }
      if (!isObject(object[property]) && !isEmpty(object[property])) {
        return true;
      }
    }
  }

  return false;
}

export function setCurrencyForNoneLoggedUser() {
  import('country-to-currency/index').then((module) => {
    const countryToCurrency = module.default;
    const country = getCountryOfClient().toUpperCase();
    const currency = countryToCurrency[country];
    if (currency && currencies[currency]) {
      const { dispatch } = getStore();
      dispatch(updateUserSettingsAction({ currency }));
    }
  });
}

export function getPromise() {
  let res;
  let rej;
  const prom = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });

  return [prom, res, rej];
}
