import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_EVENT_OCCURRENCES,
  ADD_EVENT_OCCURRENCE,
  UPDATE_EVENT_OCCURRENCE,
  DELETE_EVENT_OCCURRENCE
} from './types';

import {
  LOGOUT
} from '../auth/types';

const defaultOccurrenceFields = [
  'EventId',
  'id',
  'date',
  'registrationsCount',
  'embeddedLink',
  'embeddedType',
  'needSave',
  'needDelete',
  'isNew',
  'createdAt'
];

export function setOccurrence(occurrences) {
  return occurrences.map((occurrence) => pick(occurrence, defaultOccurrenceFields));
}

export function addOccurrence(occurrences, newOccurrence) {
  newOccurrence = pick(newOccurrence, defaultOccurrenceFields);

  const foundIndex = occurrences.findIndex((occurrence) => occurrence.id === newOccurrence.id);
  if (foundIndex === -1) {
    const updatedOccurrences = [...occurrences];
    updatedOccurrences.push(newOccurrence);
    return updatedOccurrences;
  }
  return occurrences;
}

export function updateOccurrence(occurrences, {id, occurrence}) {
  const updatedOccurrence = pick(occurrence, defaultOccurrenceFields);

  const foundIndex = occurrences.findIndex((occurrence) => occurrence.id === id);
  if (foundIndex > -1) {
    const updatedOccurrences = [...occurrences];
    updatedOccurrences[foundIndex] = {...updatedOccurrences[foundIndex], ...updatedOccurrence};
    return updatedOccurrences;
  }

  return occurrences;
}

export function deleteOccurrence(occurrences, id) {

  const foundIndex = occurrences.findIndex((occurrence) => occurrence.id === id);
  if (foundIndex > -1) {
    const updatedOccurrences = [...occurrences];
    updatedOccurrences.splice(foundIndex, 1);
    return updatedOccurrences;
  }

  return occurrences;
}

export const initialState = [];

export default handleActions({
  [SET_EVENT_OCCURRENCES]: (state, {payload}) => {
    return setOccurrence(payload);
  },
  [ADD_EVENT_OCCURRENCE]: (state, {payload}) => {
    return addOccurrence(state, payload);
  },
  [UPDATE_EVENT_OCCURRENCE]: (state, {payload}) => {
    return updateOccurrence(state, payload);
  },
  [DELETE_EVENT_OCCURRENCE]: (state, {payload}) => {
    return deleteOccurrence(state, payload);
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, [...initialState]);