import React, { useCallback, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from 'styles/theme';
import { MediumFontSizeCss, scale } from 'styles/common';

import { getSearch } from '../../../reduxUtils/search/selectors';
import { updateSearch } from '../../../reduxUtils/search/actions';
import { logout } from '../../../reduxUtils/auth/thunks';

import LeftSideBarButton from './components/LeftSideBarButton';
import Logo from 'components/Logo';
import Categories from './components/Categories';
import SearchBar from './components/SearchBar';
import SearchButton from './components/SearchButton';
import AddContentMenu from './components/AddContentMenu';
import UserMenuDropdown from './components/UserMenuDropdown';
import UserMenuButton from './components/UserMenuButton';
import UserMenuLoginButton from './components/UserMenuLoginButton';
import UserMenu from './components/UserMenu';
import FullScreenModal from 'components/Modal/FullScreenModal';
import { alResetIdentify } from 'utilities/analytics';
import Button from 'components/Button';
import { toggleChatMinimizeAction } from '../../../reduxUtils/chat/actions';
import { setLoginModalStatus } from '../../../reduxUtils/browserSettings/actions';

const TopBarWrap = styled.div`
  display: flex;
  padding: 10px ${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['padding', `10px ${theme.padding.pageD}px`]]]])}
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.fill};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  font-family: ${({ theme: { typo } }) => typo.fontFamily.montserrat};
  -webkit-box-shadow: 0 1px 10px -6px rgb(0 0 0 / 42%), 0 1px 10px 0 rgb(0 0 0 / 12%),
    0 4px 5px -2px rgb(0 0 0 / 10%);
  box-shadow: 0 1px 10px -6px rgb(0 0 0 / 42%), 0 1px 10px rgb(0 0 0 / 12%),
    0 4px 5px -2px rgb(0 0 0 / 10%);
  // transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(-100%)')};

  // transition: ${({ visible }) => (visible ? '0.4s' : '0.6s')};
  //position: fixed;
  //position: ${({ visible }) => (visible ? '' : 'fixed')};
  // margin-top: ${({ visible }) => (visible ? '0' : '-100%')};
  z-index: 100;
  width: 100%;
`;

const TopBarSeparator = styled.div`
  display: block;
  height: 30px;
  border-right: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  margin: 0 ${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['margin', `0 ${theme.padding.pageD}px`]]]])}
`;

const TopBarRightSeparator = styled.div`
  display: block;
  margin-right: ${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['margin-right', `${theme.padding.pageD}px`]]]])}
`;

const TopBarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  ${scale([
    ['default', [['width', '70%']]],
    ['560px', [['width', '70%']]],
    ['768px', [['width', '20%']]],
    ['992px', [['width', '15%']]],
  ])}
`;

const TopBarMiddle = styled.div`
  display: flex;
  align-items: center;
  ${({ isloggedin }) =>
    isloggedin
      ? scale([
          ['768px', [['width', '50%']]],
          ['992px', [['width', '50%']]],
        ])
      : scale([
          ['768px', [['width', '50%']]],
          ['992px', [['width', '50%']]],
        ])}
`;

const TopBarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ isloggedin }) =>
    isloggedin
      ? scale([
          ['default', [['width', '30%']]],
          ['560px', [['width', '30%']]],
          ['768px', [['width', '30%']]],
          ['992px', [['width', '35%']]],
        ])
      : scale([
          ['default', [['width', '30%']]],
          ['560px', [['width', '30%']]],
          ['768px', [['width', '40%']]],
          ['992px', [['width', '35%']]],
        ])}
`;

const UserMenuWrap = styled.div`
  flex-grow: 1;
  font-size: ${({ theme: { typo } }) => typo.sizes.h5};
`;

const ShowChatButton = styled(Button)`
  margin-right: 10px;
  padding: 5px 10px;
  ${MediumFontSizeCss};
`;

// let prevScrollPos = 0;
// let prevDiff = null;
// let hasStateChange = false;
// let ignoreOnce = false;

function TopBar({
  isLoggedIn,
  isMobile,
  forAdmin,
  isLeftBarOpened,
  userId,
  fullName,
  avatar,
  logout,
  setLocalIsLeftBarOpened,
  search,
  updateSearch,
  // bodyRef,
  isChatMinimized,
  toggleChatMinimizeAction,
  setLoginModalStatus,
}) {
  const [isShown, setIsShown] = useState(false);

  // const [visible, setVisible] = useState(true);

  function handleOnClick() {
    setIsShown(!isShown);
  }

  const onSelect = useCallback(
    (selected) => {
      if (selected === 'logout') {
        alResetIdentify();
        logout();
      }
    },
    [logout]
  );

  function onUpdateSearch({ target: { name, value } }) {
    updateSearch({
      ...search,
      [name]: value,
    });
  }

  // // Hide or show the menu.
  // function handleScroll(e) {
  //   const currentScrollPos = e.target.scrollTop;
  //   const diff = Math.abs(prevScrollPos - currentScrollPos);
  //   const hide = prevScrollPos > currentScrollPos;
  //   prevScrollPos = currentScrollPos;
  //
  //   if (ignoreOnce) {
  //     ignoreOnce = false;
  //     return;
  //   }
  //   if (diff < 7 || (prevDiff === diff)) {
  //     return;
  //   }
  //
  //   hasStateChange = visible !== hide;
  //   if (hasStateChange) {
  //     ignoreOnce = true;
  //   }
  //   prevDiff = diff;
  //   setVisible(hide);
  // }
  //
  // const throttledEventHandler = useMemo(
  //   (e) => throttle((e) => handleScroll(e), 50)
  //   , []);// eslint-disable-line
  //
  // useLayoutEffect(() => {
  //   if (!visible && !isMobile) {
  //     setVisible(true);
  //   } else {
  //     if (bodyRef.current && isMobile) {
  //       bodyRef.current.addEventListener("scroll", throttledEventHandler, true);
  //       return () => {
  //         bodyRef.current.removeEventListener("scroll", throttledEventHandler, true);
  //       }
  //     }
  //   }
  // }, [bodyRef.current, isMobile]);// eslint-disable-line

  return (
    <TopBarWrap>
      <TopBarLeft>
        {!isMobile && isLoggedIn && (
          <>
            <LeftSideBarButton
              setLocalIsLeftBarOpened={setLocalIsLeftBarOpened}
              isLeftBarOpened={isLeftBarOpened}
            />
            <TopBarRightSeparator />
          </>
        )}
        <Logo />
        <TopBarSeparator />
        {isMobile && !forAdmin && (
          <Categories
            category={search.category}
            updateCategory={(value) => onUpdateSearch({ target: { name: 'category', value } })}
          />
        )}
      </TopBarLeft>
      {!isMobile && !forAdmin && (
        <TopBarMiddle isloggedin={+isLoggedIn}>
          <SearchBar onUpdateSearch={onUpdateSearch} />
        </TopBarMiddle>
      )}
      <TopBarRight isloggedin={+isLoggedIn}>
        {isMobile && !forAdmin && (
          <>
            <SearchButton />
            <TopBarSeparator />
          </>
        )}

        {isChatMinimized && !forAdmin && (
          <ShowChatButton
            // isLoading={ isLoading }
            isChatMinimized={isChatMinimized}
            onClick={toggleChatMinimizeAction}
            label="Show Chat"
            buttonStyle={'generalWhite'}
          />
        )}

        {isLoggedIn && !forAdmin && (
          <>
            <AddContentMenu userId={userId} />
            <TopBarSeparator />
          </>
        )}

        {!isLoggedIn && (
          <>
            <UserMenuLoginButton setLoginModalStatus={setLoginModalStatus} />
            <TopBarSeparator />
          </>
        )}

        {
          <>
            {/*<Notification/>*/}
            {/*<TopBarSeparator/>*/}
          </>
        }

        {isMobile ? (
          <UserMenuButton onClick={handleOnClick} avatar={avatar} userFullName={fullName} />
        ) : (
          <UserMenuDropdown
            forAdmin={forAdmin}
            userId={userId}
            fullName={fullName}
            avatar={avatar}
            onSelect={onSelect}
            isLoggedIn={isLoggedIn}
          />
        )}
      </TopBarRight>

      {isMobile && (
        <FullScreenModal
          max_width={'100%'}
          min_width={'100%'}
          show={isShown}
          headerContent="User Menu"
          footerContent=""
          onCloseClick={handleOnClick}
        >
          <UserMenuWrap>
            <UserMenu
              forAdmin={forAdmin}
              isLoggedIn={isLoggedIn}
              userId={userId}
              fullName={fullName}
              avatar={avatar}
              onSelect={(select) => {
                onSelect(select);
                handleOnClick();
              }}
            />
          </UserMenuWrap>
        </FullScreenModal>
      )}
    </TopBarWrap>
  );
}

TopBar.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  forAdmin: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  logout: PropTypes.func,
  setLocalIsLeftBarOpened: PropTypes.func,
  updateSearch: PropTypes.func,
  setLoginModalStatus: PropTypes.func,
  search: PropTypes.object,
  bodyRef: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    search: getSearch(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
      updateSearch,
      toggleChatMinimizeAction,
      setLoginModalStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
