import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { errorHandler } from 'utilities/services';
import { register } from '../../../reduxUtils/auth/thunks';
import StyledTextInput from 'components/Inputs/StyledTextInput';
import PasswordInput from 'components/Inputs/PasswordInput';
import Button from 'components/Button';
import { ContactSupport, TinyFontSizeCss } from 'styles/common';
import { AL_EVENT_ACTION, AL_EVENT_STATUS, alSignup } from 'utilities/analytics';
import { validator } from 'utilities/general';

const RegisterForm = styled.form`
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 1em;
`;

const SignupAgreement = styled.div`
  color: ${({ theme }) => theme.colors.grayLighten};
  ${TinyFontSizeCss};
  max-width: 20em;
`;

const ContentWrap = styled.div`
  > div {
    margin-bottom: 10px;
  }
`;

function Register({ setShow, register }) {
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfError, setPasswordConfError] = useState('');

  const passwordTip = 'Enter password';
  const passwordConfTip = 'Passwords do not match';

  function isFirstNameValid() {
    const isValid = !!firstName;
    if (!isValid) {
      setFirstNameError('Enter first name');
    } else {
      setFirstNameError('');
    }
    return isValid;
  }

  function isLastNameValid() {
    const isValid = !!lastName;
    if (!isValid) {
      setLastNameError('Enter last name');
    } else {
      setLastNameError('');
    }
    return isValid;
  }

  function isEmailValid() {
    const isValid = validator.isEmail(email);
    if (!isValid) {
      setEmailError('Email is invalid please try again');
    } else {
      setEmailError('');
    }
    return isValid;
  }

  function isPasswordValid() {
    let isValid = !!password;
    if (!isValid) {
      setPasswordError(passwordTip);
    } else {
      setPasswordError('');
    }
    return isValid;
  }

  function confirmPassValidation() {
    return !!password && !!passwordConf && password === passwordConf;
  }

  function isPasswordConfValid() {
    let isValid = confirmPassValidation();
    if (!isValid) {
      setPasswordConfError(passwordConfTip);
    } else {
      setPasswordConfError('');
    }
    return isValid;
  }

  function isFormValid() {
    const firstNameIsValid = isFirstNameValid();
    const lastNameIsValid = isLastNameValid();
    const emailIsValid = isEmailValid();
    const passwordIsValid = isPasswordValid();
    const passwordConfIsValid = isPasswordConfValid();
    return (
      firstNameIsValid && lastNameIsValid && emailIsValid && passwordIsValid && passwordConfIsValid
    );
  }

  function checkValidation() {
    return !!firstName && !!lastName && !!email && confirmPassValidation();
  }

  let disabledRegisterButton = !checkValidation();

  function onSubmit(e) {
    e.preventDefault();
    if (isFormValid()) {
      const formData = {
        email,
        password,
        firstName,
        lastName,
        timezone: moment.tz.guess(),
      };
      let hasError;
      setIsLoading(true);
      register(formData)
        .then(() => {
          setShow(false);
        })
        .catch((err) => {
          hasError = err.message;
          if (err.statusCode === 409) {
            err.message = 'User with this email exists';
          }
          errorHandler(err);
          setIsLoading(false);
        })
        .finally(() => {
          alSignup({
            eventAction: AL_EVENT_ACTION.submit,
            eventStatus: hasError ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
            eventLabel: 'signup',
            firstName: firstName,
            lastName: lastName,
            email,
            error: hasError,
          });
        });
    }
  }

  return (
    <RegisterForm onSubmit={onSubmit}>
      <ContentWrap>
        <StyledTextInput
          placeholder="First Name"
          value={firstName}
          name="firstName"
          onChange={({ target }) => setFirstName(target.value)}
          onBlur={() => firstName && isFirstNameValid()}
          error={firstNameError}
        />
        <StyledTextInput
          placeholder="Last Name"
          value={lastName}
          name="lastName"
          onChange={({ target }) => setLastName(target.value)}
          onBlur={() => lastName && isLastNameValid()}
          error={lastNameError}
        />
        <StyledTextInput
          placeholder="Email address"
          value={email}
          name="email"
          type="email"
          onChange={({ target }) => setEmail(target.value)}
          onBlur={() => email && isEmailValid()}
          error={emailError}
          inputTip="You'll need to confirm your mail later"
        />
        <PasswordInput
          placeholder="Password"
          value={password}
          name="password"
          onChange={({ target }) => setPassword(target.value)}
          onBlur={() => password && isPasswordValid()}
          error={passwordError}
        />
        <PasswordInput
          placeholder="Confirm password"
          value={passwordConf}
          onChange={({ target }) => setPasswordConf(target.value)}
          onBlur={() => passwordConf && isPasswordConfValid()}
          error={passwordConfError}
        />
      </ContentWrap>

      <ContactSupport target="_blank" rel="noopener noreferrer" to={'/help'}>
        Contact Support
      </ContactSupport>
      <SignupAgreement>
        By clicking Sign Up, you are indicating that you have read and acknowledge the{' '}
        <Link target={'_blank'} to="/legal/terms-of-service">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link target={'_blank'} to="/legal/privacy-notice">
          Privacy Notice.
        </Link>
      </SignupAgreement>
      <ButtonWrap>
        <Button
          isLoading={isLoading}
          backIconName="fas fa-sign-in-alt"
          label="Sign Up"
          buttonStyle={'generalPurple'}
          disabled={disabledRegisterButton}
        />
      </ButtonWrap>
    </RegisterForm>
  );
}

Register.propTypes = {
  setShow: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      register,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Register);
