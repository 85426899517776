import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';

import FullScreenModal from 'components/Modal/FullScreenModal';
import PaymentDetails from 'components/Payment/components/PaymentDetails';
import {
  getUserEmail,
  getUserId,
  getUserPaymentMethods,
  getUserStripeCustomerDPM,
  getUserStripeCustomerId,
} from '../../reduxUtils/user/selectors';
import { getUserFullName, getUserMobilePhoneNumber } from '../../reduxUtils/userProfile/selectors';

import {
  deletePaymentMethod,
  getPaymentListMethods,
  updateUserDpm,
} from '../../reduxUtils/user/thunks';
import StripeService from 'services/stripe';
import { errorHandler } from 'utilities/services';
import {
  AL_EVENT,
  AL_EVENT_ACTION,
  AL_EVENT_STATUS,
  alPurchaseRefund,
  alPushContent,
} from 'utilities/analytics';
import { TRANSACTION_STATUS } from 'config/constants';
import { getTransaction } from '../../reduxUtils/transactions/selectors';
import { fetchAccessControlsPolling } from 'utilities/general';
import { setAllTransactions } from '../../reduxUtils/transactions/thunks';
import { getAccessControl } from '../../reduxUtils/accessControl/selectors';
import { upsertTransactionAction } from '../../reduxUtils/transactions/actions';

const ContentWrap = styled.div`
  margin: 20px auto;
`;

const stripeService = new StripeService();
let isLoadingPaymentsRef = false;
let setAllTransactionsWasCalled = false;

function Payment({
  isMobile,
  header,
  subHeader,
  refundLabel,
  eventId,
  occurrenceId,
  workshopId,
  title,
  coverPhoto,
  price,
  currency,
  userCurrency,
  show,
  onHide,
  onComplete,
  email,
  fullName,
  content,
  userId,
  mobilePhoneNumber,
  stripeCustomerId,
  stripeCustomerDPM,
  updateUserDpm,
  paymentMethods,
  getPaymentListMethods,
  deletePaymentMethod,
  upsertTransactionAction,
  transaction,
  setAllTransactions,
  accessControls,
}) {
  const [isLoadingPayments, setIsLoadingPayments] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paySubmitError, setPaySubmitError] = useState(null);
  const [addPaymentMethod, setAddPaymentMethod] = useState();
  const [cardErrorResponse, setCardErrorResponse] = useState(null);
  const [disabledAddPayment, setDisabledAddPayment] = useState(false);

  const errorsDefaultState = {
    card: null,
    email: null,
    name: null,
    phone: null,
  };
  const [errors, setErrors] = useState(errorsDefaultState);

  const [billingDetails, setBillingDetails] = useState({
    // address: {
    //   city: '',
    //   country: 'US',
    //   line1: '',
    //   line2: '',
    //   state: ''
    // },
    email,
    name: fullName,
    phone: mobilePhoneNumber,
  });

  const [savePaymentMethod, setSavePaymentMethod] = useState(true);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (userId) {
      if (!setAllTransactionsWasCalled) {
        setAllTransactionsWasCalled = true;
        setAllTransactions({
          imBuyer: true,
          limit: 300,
        });
      }
    }
  }, [userId, setAllTransactions]);

  useEffect(() => {
    if (show) {
      if (transaction && transaction.status) {
        // if (!transaction.isPolling && transaction.status.toUpperCase() === TRANSACTION_STATUS.FULFILLED.key) {
        //   fetchAccessControlsPolling({transaction, userId})
        //     .then(() => {
        //       onComplete && onComplete();
        //       onCloseClick();
        //       // successHandler(`Your purchase of ${content.title} successfully completed`)
        //     }).catch(() => {
        //     // successHandler(`We are still processing your payment for \n"${content.title}"\nwe will send notification to your mail once we complete`)
        //   })
        // }

        if (
          (transaction.status.toUpperCase() === TRANSACTION_STATUS.STARTED.key ||
            transaction.status.toUpperCase() === TRANSACTION_STATUS.SUCCEEDED.key) &&
          !transaction.isPolling
        ) {
          fetchAccessControlsPolling({ transaction, userId })
            .then(() => {
              onComplete && onComplete();
              onCloseClick();
              // successHandler(`Your purchase of ${content.title} successfully completed`)
            })
            .catch(() => {
              // successHandler(`We are still processing your payment for \n"${content.title}"\nwe will send notification to your mail once we complete`)
            });
        }
      }
    }
  }, [show]); // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      if (!isLoadingPaymentsRef && isLoadingPayments) {
        setIsLoadingPayments(false);
      }
    }, 5000);
  }, []); // eslint-disable-line

  function fetchPaymentMethods(reGetPaymentList) {
    if (
      !isLoadingPaymentsRef &&
      (reGetPaymentList || (stripeCustomerId && !paymentMethods.length))
    ) {
      setIsLoadingPayments(true);
      isLoadingPaymentsRef = true;
      getPaymentListMethods()
        .then((results) => {})
        .catch((err) => {
          errorHandler(err);
        })
        .finally(() => {
          isLoadingPaymentsRef = false;
          setIsLoadingPayments(false);
        });
    }
    if (stripeCustomerId === null) {
      setIsLoadingPayments(false);
    }
  }

  useEffect(() => {
    fetchPaymentMethods();
  }, [stripeCustomerId, paymentMethods.length, getPaymentListMethods]); //eslint-disable-line

  useEffect(() => {
    if (stripeCustomerDPM) {
      setSelectedPaymentMethod(stripeCustomerDPM);
    }
  }, [stripeCustomerDPM]);

  useEffect(() => {
    setBillingDetails((currentValue) => {
      return {
        ...currentValue,
        name: fullName,
        email,
        // phone: mobilePhoneNumber
      };
    });
  }, [fullName, email, mobilePhoneNumber, setBillingDetails]);

  function onDeletePaymentMethod(paymentMethodId) {
    return deletePaymentMethod(paymentMethodId);
  }

  function alEcommerce({ alEvent, eventAction, eventStatus, error, transaction_id }) {
    const eventOrWorkshop = eventId ? 'event' : 'workshop';
    alPurchaseRefund({
      alEvent,
      eventAction,
      eventStatus,
      eventLabel: `purchase ${eventOrWorkshop}`,
      content,
      occurrenceId,
      userId,
      error,
      transaction_id,
      isWorkshop: !!workshopId,
    });
  }

  async function pollingAccess(transaction) {
    await fetchAccessControlsPolling({ transaction, userId })
      .then(() => {
        onCloseClick();
        onComplete && onComplete(transaction);
        // successHandler(`Your purchase of ${content.title} successfully completed`)
      })
      .catch(() => {
        // successHandler(`We are still processing your payment for \n"${content.title}"\nwe will send notification to your mail once we complete`)
      });
  }

  function handlePaySubmit(e) {
    e.preventDefault();
    setIsProcessing(true);
    setPaySubmitError(null);
    let error;
    let transactionId = null;

    const payload = stripeService
      .paymentNewCharge({
        // eslint-disable-line
        eventId,
        workshopId,
        occurrenceId,
        paymentMethodId: selectedPaymentMethod,
      })
      .then(async (res) => {
        const {
          data: { paymentIntent },
        } = res;

        transactionId = paymentIntent.id;
        // successHandler(PAYMENT_PROCESSING_MSG)

        const transaction = {
          ...paymentIntent,
          id: +paymentIntent.metadata.transactionId,
          status: paymentIntent.status.toUpperCase(),
          EventId: eventId,
          OccurrenceId: occurrenceId,
          WorkshopId: workshopId,
        };

        upsertTransactionAction(transaction);
        await pollingAccess(transaction);
        // onCloseClick();
      })
      .catch((err) => {
        error = `Payment failed ${err.message}`;
        errorHandler(error);
        setPaySubmitError(error);
      })
      .finally(() => {
        alEcommerce({
          alEvent: AL_EVENT.GENERAL.purchase,
          eventAction: AL_EVENT_ACTION.submit,
          eventStatus: error ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
          error,
          transaction_id: transactionId,
        });
        setIsProcessing(false);
      });
  }

  const handleSubmitNewPayment = async (ev) => {
    ev.preventDefault();
    setCardErrorResponse(null);
    setIsProcessing(true);
    let transactionId;
    try {
      const results = await stripeService
        .paymentCreateIntent({
          eventId,
          workshopId,
          occurrenceId,
          savePaymentMethod,
        })
        .catch((res) => res);
      if (results.status !== 'success') {
        const errorMessage = `Payment failed: ${results.message}`;
        setIsProcessing(false);
        throw new Error(errorMessage);
      }

      const {
        data: { clientSecret, transactionId: transactionIdRes },
      } = results;
      transactionId = transactionIdRes;
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: billingDetails,
        },
      });

      if (payload.error) {
        const error = `Payment failed ${payload.error.message}`;
        throw new Error(error);
      } else {
        //todo: fetch stripeCustomerId on new intent then call
        fetchPaymentMethods(true);

        const { paymentIntent } = payload;
        // successHandler(PAYMENT_PROCESSING_MSG)
        // transactionId = paymentIntent.id;

        const transaction = {
          ...paymentIntent,
          id: +transactionId,
          status: paymentIntent.status.toUpperCase(),
          EventId: eventId,
          OccurrenceId: occurrenceId,
          WorkshopId: workshopId,
        };

        upsertTransactionAction(transaction);
        await pollingAccess(transaction);
        setIsProcessing(false);

        setAddPaymentMethod(false);

        alEcommerce({
          alEvent: AL_EVENT.GENERAL.purchase,
          eventAction: AL_EVENT_ACTION.submit,
          eventStatus: AL_EVENT_STATUS.success,
          transaction_id: transactionId,
        });
      }
    } catch (error) {
      if (transactionId) {
        stripeService.transactionFailed(transactionId).catch((err) => {
          errorHandler('if this error persists please contact support');
        });
      }
      errorHandler(error);
      setCardErrorResponse(error.message);
      setIsProcessing(false);
      alEcommerce({
        alEvent: AL_EVENT.GENERAL.purchase,
        eventAction: AL_EVENT_ACTION.submit,
        eventStatus: AL_EVENT_STATUS.failed,
        error,
      });
    }
  };

  function onDefaultPaymentMethod(e, id) {
    e.stopPropagation();
    return updateUserDpm(id);
  }

  function onNewPaymentMethodClick(value = true) {
    setAddPaymentMethod(value);
    setCardErrorResponse(null);
  }

  function onSelectPaymentMethod(id) {
    setSelectedPaymentMethod(id);
    setPaySubmitError(null);
  }

  function onCloseClick() {
    // if (!isProcessing) {
    setPaySubmitError(null);
    setCardErrorResponse(null);
    setErrors(errorsDefaultState);
    onHide && onHide();
    // }
  }

  useEffect(() => {
    if (show) {
      alPushContent({
        alEvent: AL_EVENT.GENERAL.addToCart,
        eventAction: AL_EVENT_ACTION.click,
        eventStatus: AL_EVENT_STATUS.success,
        content: content,
        isWorkshop: !!workshopId,
      });
    }
  }, [show]); // eslint-disable-line

  return (
    <FullScreenModal
      show={show}
      width={isMobile ? '100%' : 'auto'}
      max_width={isMobile ? '100%' : 'max-content'}
      height={isMobile ? '100%' : 'max-content'}
      onHide={onCloseClick}
      headerContent="Payment Details"
      onCloseClick={onCloseClick}
      margin={'auto'}
      border_radius={isMobile ? 0 : 1}
      backdrop={!isMobile}
    >
      <ContentWrap>
        <PaymentDetails
          header={header}
          subHeader={subHeader}
          refundLabel={refundLabel}
          title={title}
          coverPhoto={coverPhoto}
          price={price}
          currency={currency}
          userCurrency={userCurrency}
          email={email}
          fullName={fullName}
          mobilePhoneNumber={mobilePhoneNumber}
          eventId={eventId}
          workshopId={workshopId}
          occurrenceId={occurrenceId}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          paymentMethods={paymentMethods}
          isLoadingPayments={isLoadingPayments}
          cardErrorResponse={cardErrorResponse}
          paySubmitError={paySubmitError}
          stripeCustomerDPM={stripeCustomerDPM}
          addPaymentMethod={addPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          onDeletePaymentMethod={onDeletePaymentMethod}
          onNewPaymentMethodClick={onNewPaymentMethodClick}
          onSelectPaymentMethod={onSelectPaymentMethod}
          onDefaultPaymentMethod={onDefaultPaymentMethod}
          handlePaySubmit={handlePaySubmit}
          handleSubmitNewPayment={handleSubmitNewPayment}
          errors={errors}
          setErrors={setErrors}
          billingDetails={billingDetails}
          setBillingDetails={setBillingDetails}
          savePaymentMethod={savePaymentMethod}
          setSavePaymentMethod={setSavePaymentMethod}
          disabledAddPayment={disabledAddPayment}
          setDisabledAddPayment={setDisabledAddPayment}
          setCardErrorResponse={setCardErrorResponse}
          transaction={transaction}
        />
      </ContentWrap>
    </FullScreenModal>
  );
}

Payment.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  stripeCustomerId: PropTypes.string,
  stripeCustomerDPM: PropTypes.string,
  userId: PropTypes.number,
  email: PropTypes.string,
  fullName: PropTypes.string,
  mobilePhoneNumber: PropTypes.string,
  eventId: PropTypes.number,
  workshopId: PropTypes.number,
  occurrenceId: PropTypes.number,
  updateUserDpm: PropTypes.func,
  deletePaymentMethod: PropTypes.func,
  content: PropTypes.object,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  refundLabel: PropTypes.string,
};

function mapStateToProps(state, { eventId, occurrenceId, workshopId }) {
  return {
    stripeCustomerId: getUserStripeCustomerId(state),
    stripeCustomerDPM: getUserStripeCustomerDPM(state),
    userId: getUserId(state),
    email: getUserEmail(state),
    fullName: getUserFullName(state),
    mobilePhoneNumber: getUserMobilePhoneNumber(state),
    paymentMethods: getUserPaymentMethods(state),
    transaction: getTransaction(state, eventId, occurrenceId, workshopId),
    accessControls: getAccessControl(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserDpm,
      deletePaymentMethod,
      getPaymentListMethods,
      upsertTransactionAction,
      setAllTransactions,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Payment));
