import {hasPermissions, hasNoPermission, getReqPermissions} from '../../index';
import canViewWorkshopContent from '../workshops/can-view-workshop-content';

export default function canViewOccurrence(occurrenceId, workshopId, userId, accessControls) {
  const permissionFunction = 'eventCanViewOccurrence';
  const object = {
    type: 'occurrence',
    id: occurrenceId,
    key: 'toOccurrenceId'
  };

  const requiredPermissions = getReqPermissions(permissionFunction);
  const occurrenceDisallowed = hasNoPermission(object, requiredPermissions[0], accessControls);
  const occurrenceAllowed = hasPermissions(object, accessControls, permissionFunction);

  return occurrenceAllowed || (!occurrenceDisallowed && canViewWorkshopContent(workshopId, accessControls));
}

