import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {Link} from 'react-router-dom';
import StyledAvatar from 'components/StyledAvatar';
import Follow from 'components/Follow';
import StarIcon from 'components/StarIcon';
import {UserSelectNoneCss} from 'styles/common';
import {compactNumber} from "utilities/general";

const PageChannelWrap = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${({channel_wrap_css}) =>
    channel_wrap_css
  };
  ${UserSelectNoneCss};
`;

const UserDetailsWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.06em;
`;

const FollowersRatingWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.15em;
  white-space: nowrap;
  ${({followers_rating_css}) =>
    followers_rating_css
  };

`;

const PageChannelUserAvatar = styled(StyledAvatar)`
  ${({channel_user_avatar_css}) =>
    channel_user_avatar_css
  };
`;

const PageChannelUserName = styled(Link)`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9em;
  text-decoration: none;
  text-transform: capitalize;
  color: ${({theme: {colors}}) => colors.grayNew};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.grayNew};
    text-decoration: none;
  }

  ${({user_name_css}) =>
    user_name_css
  }

  ${({disable_hover}) =>
    !disable_hover &&
    css`
      &:hover,
      &:focus {
        color: inherit;
      }
    `
  };
`;

const PageChannelFollowers = styled.div`
`;

const AvatarWrap = styled(Link)`
  margin-right: 0.3em;
`;

const DetailsWrap = styled.div`

`;

const Rating = styled.span`
`;

const RatingCount = styled.span`
  margin-right: 0.4em;
`;


function StyledChannel(
  {
    to,
    userFullName,
    userAvatarUrl,
    followers = 0,
    userId,
    channelId,
    rating = 0,
    ratingCount = 0,
    channelWrapCss = null,
    userNameCss = null,
    channelUserAvatarCss = null,
    followersRatingCss = null,
    showUnfollow = false,
    showFollow = true,
    setDialog,
    ...props
  }
) {
  //todo set followers and remove rating
  const [followersCount, setFollowersCount] = useState(0);

  const followersTotal = followersCount + followers;

  return (
    <PageChannelWrap channel_wrap_css={channelWrapCss} {...props}>
      <AvatarWrap
        as={!to && 'div'}
        to={to}
        disable_hover={to}
      >
        <PageChannelUserAvatar
          channel_user_avatar_css={channelUserAvatarCss}
          name={userFullName}
          url={userAvatarUrl}
        />
      </AvatarWrap>

      <DetailsWrap>
        <UserDetailsWrap>
          <PageChannelUserName
            as={!to && 'div'}
            to={to}
            user_name_css={userNameCss}
            disable_hover={to}
          >
            {userFullName || 'Mr.s X'}
          </PageChannelUserName>

          {
            (showFollow && (!userId || channelId && (+userId !== +channelId))) &&// eslint-disable-line
            <Follow
              setFollowersCount={setFollowersCount}
              userId={userId}
              channelId={channelId}
              userFullName={userFullName}
              showUnfollow={showUnfollow}
              setDialog={setDialog}
            />
          }
        </UserDetailsWrap>
        <FollowersRatingWrap followers_rating_css={followersRatingCss}>
          {
            !!rating && (
              <>
                <Rating>{rating}</Rating>
                <StarIcon/>
                <RatingCount>({compactNumber(+ratingCount)})</RatingCount>
              </>
            )
          }
          {
            !!followersTotal &&
            (
              <PageChannelFollowers>
                {compactNumber(+followersTotal)} {+followersTotal === 1 ? 'Follower' : 'Followers'}
              </PageChannelFollowers>
            )
          }
        </FollowersRatingWrap>
      </DetailsWrap>

    </PageChannelWrap>
  );
}

StyledChannel.propTypes = {
  to: PropTypes.string,
  userFullName: PropTypes.string,
  userAvatarUrl: PropTypes.string,
  followers: PropTypes.number,
  userId: PropTypes.number,
  // channelId: PropTypes.number,
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  channelWrapCss: PropTypes.array,
  userNameCss: PropTypes.array,
  channelUserAvatarCss: PropTypes.array,
  followersRatingCss: PropTypes.array,
  showUnfollow: PropTypes.bool,
};

export default React.memo(StyledChannel);
