import {getStore, getStoreState} from "utilities/store";
import {deletePollAction, upsertPollAction} from "../../../reduxUtils/polls/actions";
import {getUserId} from "../../../reduxUtils/user/selectors";
import {POLL_STATUS} from "utilities/polls.const";

export default async function pollsUpdatePollVisibilityMessage(socket, data = {}, cb) {
  const {dispatch} = getStore();
  const {status, UserId, id} = data
  if (status === POLL_STATUS.UNPUBLISHED.key) {
    const state = getStoreState();
    const userId = getUserId(state);
    //if the poll is unpublished and the user is not the creator delete poll
    if (userId !== UserId) {
      return dispatch(deletePollAction(id));
    }
  }

  dispatch(upsertPollAction(data));
}
