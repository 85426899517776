import {handleActions} from 'redux-actions';
import {
  SET_HOME_LIVE_EVENTS_SUGGESTION2,
  ADD_HOME_LIVE_EVENTS_SUGGESTION2,
  UPDATE_OCCURRENCE_LIVE_EVENT_SUGGESTION2,
} from './types';
import {
  reduxAddEventHelper,
  reduxEventHelper,
  reduxUpdateEventOccurrenceHelper
} from "../helpers/events";

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_HOME_LIVE_EVENTS_SUGGESTION2]: (state, {payload}) => {
    return reduxEventHelper(state, payload);
  },
  [ADD_HOME_LIVE_EVENTS_SUGGESTION2]: (state, {payload}) => {
    return reduxAddEventHelper(state, payload);
  },
  [UPDATE_OCCURRENCE_LIVE_EVENT_SUGGESTION2]: (state, {payload}) => {
    return reduxUpdateEventOccurrenceHelper(state, payload);
  },

}, {...initialState});