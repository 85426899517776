import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { errorHandler, successHandler } from 'utilities/services';
import CustomLabel from 'components/CustomLabel';
import { getWorkshopRegistrationLogic } from 'utilities/workshop';
import { AL_EVENT, AL_EVENT_ACTION, AL_EVENT_STATUS, alPushContent } from 'utilities/analytics';
import { setWorkshopCalendarConfirmation } from 'utilities/calendar';

const WorkshopRegButtonWrap = styled(CustomLabel)``;

function WorkshopRegButton({
  className,
  label,
  unRegisterLabel = label,
  userId,
  adminOrOwner,
  id,
  isFree,
  registration,
  addWorkshopRegistration,
  createWorkshopRegistration,
  updateParentComponentOnRegister = () => {},
  updateParentComponentOnRemoveRegister = () => {},
  deleteWorkshopRegistration,
  workshop,
  workshopType,
  workshopEventsRef,
  setDialog,
  onBuyClick,
  setGoogleDialog,
}) {
  const busyRef = useRef(false);

  //todo: if we allow unregister uncomment
  //
  function deregisterToWorkshop(id, registrationId) {
    const action = 'unregister';

    setDialog({
      show: true,
      title: `Workshop Registration`,
      confirmationText: `Are you sure you want to ${action}`,
      contentTitle: `${workshop.title ? `"${workshop.title}"` : ''}`,
      action: action,
      onCancel: () => {
        setDialog((currentDialog) => {
          return {
            ...currentDialog,
            show: false,
          };
        });
      },
      onConfirm: () => {
        setDialog((currentDialog) => {
          return {
            ...currentDialog,
            isLoading: true,
          };
        });

        if (busyRef.current) {
          return;
        }
        busyRef.current = true;

        deleteWorkshopRegistration(id, registrationId)
          .catch((err) => {
            errorHandler(err);
          })
          .finally(() => {
            updateParentComponentOnRemoveRegister();
            busyRef.current = false;
            setDialog((currentDialog) => {
              return {
                ...currentDialog,
                show: false,
              };
            });
          });
      },
    });
  }

  function onCompleteBuy(transaction) {
    successHandler(
      `Your purchase of ${workshop.title} successfully completed\nyou may enter the workshop`
    );
    addWorkshopRegistration(workshop.id, workshop);
    setWorkshopCalendarConfirmation({
      adminOrOwner,
      workshopEventsRef,
      setGoogleDialog,
      setDialog,
    });
  }

  function workshopRegistration(workshopId, workshop) {
    let error;
    if (busyRef.current) {
      return;
    }

    busyRef.current = true;
    createWorkshopRegistration(workshopId, workshop)
      .then(() => {
        updateParentComponentOnRegister();
        setWorkshopCalendarConfirmation({
          workshopEventsRef,
          setGoogleDialog,
          setDialog,
        });
      })
      .catch((err) => {
        error = err.message;
        errorHandler(err);
      })
      .finally(() => {
        busyRef.current = false;
        alPushContent({
          alEvent: AL_EVENT.WORKSHOP.registerWorkshop,
          eventAction: AL_EVENT_ACTION.submit,
          eventStatus: error ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
          eventLabel: 'register to workshop',
          content: workshop,
          workshopType,
          isWorkshop: true,
          error,
        });
      });
  }

  function onRegisterClick(id, registered) {
    if (registered) {
      if (isFree) {
        deregisterToWorkshop(id, registration.id);
      } else {
        errorHandler('Sorry its not possible to unregister from Workshop');
      }
    } else {
      if (isFree) {
        workshopRegistration(id, workshop);
      } else {
        onBuyClick(onCompleteBuy); // dont need to call registration has it is done on the server
      }
    }
  }

  let workshopRegLogic = getWorkshopRegistrationLogic(registration, id, isFree);
  return (
    <>
      <WorkshopRegButtonWrap
        className={className}
        adminOrOwner={adminOrOwner}
        invertColor={workshopRegLogic.registered}
        disable={workshopRegLogic.registered && !isFree}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onRegisterClick(id, workshopRegLogic.registered);
        }}
        label={workshopRegLogic.registered ? unRegisterLabel : label}
        workshopType={workshopType}
      />
    </>
  );
}

WorkshopRegButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  userId: PropTypes.number,
  adminOrOwner: PropTypes.bool,
  unRegisterLabel: PropTypes.string,
  id: PropTypes.number,
  isFree: PropTypes.bool,
  registration: PropTypes.object,
  addWorkshopRegistration: PropTypes.func,
  createWorkshopRegistration: PropTypes.func,
  updateParentComponentOnRegister: PropTypes.func,
  deleteWorkshopRegistration: PropTypes.func,
  navigate: PropTypes.object,
  workshop: PropTypes.object,
  workshopType: PropTypes.string,
  workshopEventsRef: PropTypes.object,
  onBuyClick: PropTypes.func,
  setDialog: PropTypes.func,
  setGoogleDialog: PropTypes.func,
};
export default React.memo(WorkshopRegButton);
