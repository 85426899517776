import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { SelectInputWrap } from 'containers/UserSettings/JSXElements';
import { emailOptions } from 'utilities/calendar';
import { getAddToCalendarPopUp } from '../../reduxUtils/userSettings/selectors';
import { connect } from 'react-redux';
import { updateUserSettings } from '../../reduxUtils/userSettings/thunks';

const AddToCalendarSelectorWrap = styled.div``;

function AddToCalendarSelector({ className, addToCalendarPopUp, updateUserSettings, setDialog }) {
  function onChange({ target: { name, value } }) {
    setDialog &&
      setDialog((current) => {
        return {
          ...current,
          isLoading: true,
        };
      });

    updateUserSettings({
      [name]: value,
    }).finally(() => {
      setDialog &&
        setDialog((current) => {
          return {
            ...current,
            isLoading: false,
          };
        });
    });
  }

  return (
    <AddToCalendarSelectorWrap className={className}>
      <SelectInputWrap
        id={'Add To Calendar'}
        onChange={onChange}
        value={addToCalendarPopUp}
        options={emailOptions}
        name="addToCalendarPopUp"
      />
    </AddToCalendarSelectorWrap>
  );
}

AddToCalendarSelector.propTypes = {
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    addToCalendarPopUp: getAddToCalendarPopUp(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserSettings: updateUserSettings,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCalendarSelector);
