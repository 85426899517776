import {handleActions} from 'redux-actions';
import {LOGOUT} from "../auth/types";
import {
  ADD_NEW_POLL,
  CLEAR_NEW_POLLS,
  DELETE_NEW_POLL,
  UPDATE_NEW_POLL,

  ADD_NEW_POLL_OPTION,
  UPDATE_NEW_POLL_OPTION,
  DELETE_NEW_POLL_OPTION,
} from "../newPolls/types";

import {isSubsetObject} from "utilities/general";

export const initialState = {
  rows: [],
};

export default handleActions({
  [ADD_NEW_POLL]: (state, {payload}) => {
    if (!payload) {
      return state
    }

    const foundIndex = state.rows.findIndex((el) => {
      return el.id === payload.id
    });

    if (foundIndex > -1) {
      return state;
    }

    const elements = [...state.rows, {...payload}];

    return {
      ...state,
      rows: elements
    };

  },
  [DELETE_NEW_POLL]: (state, {payload}) => {
    const foundIndex = state.rows.findIndex((el) => {
      return el.id === payload
    });

    if (foundIndex > -1) {
      const elements = [...state.rows];
      elements.splice(foundIndex, 1);
      return {
        ...state,
        rows: elements
      };
    }

    return state;
  },
  [UPDATE_NEW_POLL]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    const foundIndex = state.rows.findIndex((el) => {
      return !!(payload.id && el.id === payload.id);
    });

    if (foundIndex > -1) {
      if (isSubsetObject(state.rows[foundIndex], payload)) {
        return state;
      }

      const elements = [...state.rows];
      elements[foundIndex] = {...elements[foundIndex], ...payload}
      return {
        ...state,
        rows: elements
      };
    }

    return state;
  },
  [ADD_NEW_POLL_OPTION]: (state, {payload}) => {
    const foundPollIndex = state.rows.findIndex((el) => {
      if (payload.PollId && el.id && el.id === payload.PollId) {
        return true;
      }
      return false;
    });

    if (foundPollIndex === -1) {
      return state;
    }

    const foundIndex = state.rows[foundPollIndex].PollOptions?.findIndex((el) => {
      return el.id === payload.id
    });

    if (foundIndex === -1) {
      const elements = [...state.rows];
      elements[foundPollIndex] = {
        ...elements[foundPollIndex],
        PollOptions: [...elements[foundPollIndex].PollOptions, {...payload}]
      };

      return {
        ...state,
        rows: elements
      };
    }

    return state;
  },
  [UPDATE_NEW_POLL_OPTION]: (state, {payload}) => {
    const foundPollIndex = state.rows.findIndex((el) => {
      if (payload.PollId && el.id && el.id === payload.PollId) {
        return true;
      }
      return false;

    });

    if (foundPollIndex === -1) {
      return state;
    }

    const foundIndex = state.rows[foundPollIndex].PollOptions?.findIndex((el) => {
      return el.id === payload.id
    });

    if (foundIndex > -1) {
      if (isSubsetObject(state.rows[foundPollIndex].PollOptions[foundIndex], payload)) {
        return state;
      }

      const elements = [...state.rows];
      const newOptions = [...elements[foundPollIndex].PollOptions];
      newOptions[foundIndex] = {...newOptions[foundIndex], ...payload};
      elements[foundPollIndex] = {
        ...elements[foundPollIndex],
        PollOptions: newOptions
      };

      return {
        ...state,
        rows: elements
      };
    }

    return state;
  },
  [DELETE_NEW_POLL_OPTION]: (state, {payload}) => {
    const foundPollIndex = state.rows.findIndex((el) => {
      if (payload.PollId && el.id && el.id === payload.PollId) {
        return true;
      }
      return false;

    });

    if (foundPollIndex === -1) {
      return state;
    }

    const foundIndex = state.rows[foundPollIndex].PollOptions?.findIndex((el) => {
      return el.id === payload.id
    });

    if (foundIndex > -1) {
      const elements = [...state.rows];
      const newOptions = [...elements[foundPollIndex].PollOptions];
      newOptions.splice(foundIndex, 1);
      elements[foundPollIndex] = {
        ...elements[foundPollIndex],
        PollOptions: newOptions
      };

      return {
        ...state,
        rows: elements
      };
    }

    return state;
  },
  [CLEAR_NEW_POLLS]: () => {
    return initialState;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, initialState);