import {handleActions} from 'redux-actions';
import {
  SET_PURCHASES_EVENTS,
  ADD_PURCHASES_EVENTS
} from './types';

import {unionWith} from "lodash";
import {LOGOUT} from "../../auth/types";


const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};
const comparator = (itemA, itemB) => {
  return itemA.id === itemB.id;
};

export default handleActions({
  [SET_PURCHASES_EVENTS]: (state, {payload}) => {
    return {...state, ...payload};
  },

  [ADD_PURCHASES_EVENTS]: (state, {payload}) => {
    const unionArr = unionWith(state.rows, payload.rows, comparator);
    if (unionArr.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      ...payload,
      rows: unionArr
    };
  },
  [LOGOUT]: () => {
    return initialState;
  }

}, {...initialState});