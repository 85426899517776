import React, {useRef} from "react";
import PropTypes from 'prop-types';
import {
  Body,
  Container,
} from "components/Chat/components/JSXElements";
import NewPoll from "./components/NewPoll";
import DisplayPoll from "./components/DisplayPoll";

function PollsContainer({
                          isAdmin,
                          userId,
                          channelId,
                          eventId,
                          occurrenceId,
                          workshopId,
                        }) {

  const bodyRef = useRef(null)
  return (
    <Body ref={bodyRef}>
      <Container>
        {
          isAdmin &&
          <NewPoll
            isAdmin={isAdmin}
            userId={userId}
            eventId={eventId}
            occurrenceId={occurrenceId}
            workshopId={workshopId}
          />
        }
        <DisplayPoll
          isAdmin={isAdmin}
          userId={userId}
          channelId={channelId}
          eventId={eventId}
          occurrenceId={occurrenceId}
          workshopId={workshopId}
        />

      </Container>
    </Body>
  );
}


PollsContainer.propTypes = {
  isAdmin: PropTypes.bool,
  userId: PropTypes.number,
  eventId: PropTypes.string,
  occurrenceId: PropTypes.string,
  workshopId: PropTypes.string,
};
export default (React.memo(PollsContainer));
