import React from 'react';
import {
  TileDescriptionBody,
  TileDescriptionHeader,
  TileDescriptionTitle,
  TilePlaceHolders
} from 'components/Tiles/Placeholder/JSXElements';
import styled from 'styled-components';
import {ChannelTileWrap} from 'components/Tiles/components/JSXElements';
import {HomeChannelAvatarCss} from "styles/common";
import PlaceHolder from "components/PlaceHolder";

const TileDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 0.5em;
`;

const ChannelTileAvatarPlaceHolder = styled(PlaceHolder)`
  ${HomeChannelAvatarCss};
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;
`;

function PlaceholderChannelTile() {
  return (
    <ChannelTileWrap>
      <ChannelTileAvatarPlaceHolder
        // width={CHANNEL_AVATAR_DIM}
        // height={CHANNEL_AVATAR_DIM}
        marginTop={0}
        marginLeft={0}
        borderRadius={100}
      />
      <TileDescription>
        <TileDescriptionHeader>
          <TileDescriptionTitle>
            <TilePlaceHolders
              width={'70%'}
              height={'15px'}
              marginTop={0}
              marginLeft={0}
              marginAuto={'auto'}
            />

          </TileDescriptionTitle>
        </TileDescriptionHeader>
        <TileDescriptionBody>
          <TilePlaceHolders
            width={'85%'}
            height={'10px'}
            marginTop={'9px'}
            marginLeft={'8px'}
          />
        </TileDescriptionBody>
      </TileDescription>
    </ChannelTileWrap>
  );
}

export default PlaceholderChannelTile;