import React, {lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getAuthIsLoggedIn} from '../../reduxUtils/auth/selectors';
import {getUserId} from '../../reduxUtils/user/selectors';
import {getUserFullName, getUserAvatarThumbnail} from '../../reduxUtils/userProfile/selectors';
import {getUserSettings} from '../../reduxUtils/userSettings/selectors';
import {getBrowserIsMobile, getBrowserIsLeftBarOpened, getBrowserDimensions} from '../../reduxUtils/browserSettings/selectors';
import WithSideBar from 'layouts/WithSideBar';

import Fallback from 'components/Fallback';
import {getIsChatDetach, getIsChatMinimized} from "../../reduxUtils/chat/selectors";

const LegalLazy = lazy(() => import('./Legal'));

function Legal(
  {
    location,
    match,
    isLoggedIn,
    isMobile,
    userId,
    fullName,
    avatar,
    isChatDetach,
    isChatMinimized
  }) {
  const options = [
    {
      label: 'Terms Of Service',
      linkTo: '/legal/terms-of-service'
    },
    {
      label: 'Creator Terms',
      linkTo: '/legal/creator-terms'
    },
    {
      label: 'Community Guidelines',
      linkTo: `/legal/community-guidelines`
    },
    {
      label: 'Privacy Notice',
      linkTo: '/legal/privacy-notice'
    },
    {
      label: 'Privacy Choices',
      linkTo: '/legal/privacy-choices'
    },
    {
      label: 'Dmca Guidelines',
      linkTo: '/legal/dmca-guidelines'
    },
    {
      label: 'Security',
      linkTo: '/legal/security'
    },
    {
      label: 'California Privacy Disclosure',
      linkTo: '/legal/california-privacy-disclosure'
    },
    {
      label: 'Sexual Content',
      linkTo: '/legal/sexual-content'
    },
    {
      label: 'Cookie Notice',
      linkTo: '/legal/cookie-notice'
    }
  ];

  return (
    <WithSideBar
      options={options}
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={true}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      showLeftBar={true}
      isChatMinimized={isChatMinimized && isChatDetach}
    >

      <Suspense fallback={<Fallback/>}>
        <LegalLazy
          match={match}
        />
      </Suspense>

    </WithSideBar>
  );
}

Legal.propTypes = {
  location: PropTypes.object,
  options: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  setLocalIsLeftBarOpened: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    dimensions: getBrowserDimensions(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}


export default connect(mapStateToProps)(Legal);
