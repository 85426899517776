import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import theme from 'styles/theme';
import { scale, UserSelectNoneCss } from 'styles/common';

import Icon from '../Icon';
import { Link } from 'react-router-dom';
// import Spinner from '../Spinner';

const Label = styled.div``;

const buttonGeneralPurple = css`
  border-radius: 30px;
  color: ${({ theme: { colors } }) => colors.white};
  background-color: ${({ theme: { colors } }) => colors.purple};
  border: ${({ theme: { colors } }) => colors.purple};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${({ theme: { colors } }) => colors.purpleLighten};
        color: ${({ theme: { colors } }) => colors.white};
      }

      &:active {
        background-color: ${({ theme: { colors } }) => colors.purple};
        color: ${({ theme: { colors } }) => colors.white};
      }
    `};
`;

const buttonGeneralWhite = css`
  border-radius: 30px;
  color: ${({ theme: { colors } }) => colors.grayNew};
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.purple};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border: 1px solid ${({ theme: { colors } }) => colors.purpleLighten};
        background-color: ${({ theme: { colors } }) => colors.paleGrey};
        color: ${({ theme: { colors } }) => colors.darkGrey};
      }

      &:active {
        color: ${({ theme: { colors } }) => colors.grayNew};
        border: 1px solid ${({ theme: { colors } }) => colors.purple};
      }
    `};
`;

const label = css`
  border-radius: 30px;
  color: ${({ theme: { colors } }) => colors.grayNew};
  background-color: inherit;
  border: 1px solid ${({ theme: { colors } }) => colors.paleGrey};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${({ theme: { colors } }) => colors.paleGrey};
      }
    `};
`;

const buttonGeneral = css`
  border-radius: 30px;

  border: 1px solid ${({ theme: { colors } }) => colors.black};
  color: ${({ theme: { colors } }) => colors.black};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ theme: { colors } }) => colors.darkGrey};
        background-color: ${({ theme: { colors } }) => colors.paleGrey};
        border: 1px solid ${({ theme: { colors } }) => colors.darkGrey};
      }

      &:focus {
        color: ${({ theme: { colors } }) => colors.paleGrey};
        background-color: ${({ theme: { colors } }) => colors.darkGrey};
        border: 1px solid ${({ theme: { colors } }) => colors.paleGrey};
      }
    `};
`;

const buttonNotify = css`
  background-color: ${({ theme: { colors } }) => colors.notify};
  border: 1px solid ${({ theme: { colors } }) => lighten(0.1, colors.paleNotify)};
  color: ${({ theme: { colors } }) => colors.black};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ theme: { colors } }) => colors.darkGrey};
        background-color: ${({ theme: { colors } }) => colors.paleNotify};
        border: 1px solid ${({ theme: { colors } }) => colors.notify};
      }

      &:focus {
        color: ${({ theme: { colors } }) => colors.paleGrey};
        background-color: ${({ theme: { colors } }) => colors.tanNotify};
        border: 1px solid ${({ theme: { colors } }) => colors.paleNotify};
      }
    `};
`;

const buttonSuccess = css`
  background-color: ${({ theme: { colors } }) => colors.purple};
  color: ${({ theme: { colors } }) => colors.white};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ theme: { colors } }) => colors.white};
        background-color: ${({ theme: { colors } }) => colors.purpleLighten};
      }

      &:focus {
        color: ${({ theme: { colors } }) => colors.white};
        background-color: ${({ theme: { colors } }) => colors.purple};
      }
    `};
`;

const buttonError = css`
  background-color: ${({ theme: { colors } }) => colors.error};
  border: 1px solid ${({ theme: { colors } }) => lighten(0.1, colors.paleError)};
  color: ${({ theme: { colors } }) => colors.black};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ theme: { colors } }) => colors.darkGrey};
        background-color: ${({ theme: { colors } }) => colors.paleError};
        border: 1px solid ${({ theme: { colors } }) => colors.error};
      }

      &:focus {
        color: ${({ theme: { colors } }) => colors.paleGrey};
        background-color: ${({ theme: { colors } }) => colors.tanError};
        border: 1px solid ${({ theme: { colors } }) => colors.paleError};
      }
    `};
`;

const buttonWarning = css`
  background-color: ${({ theme: { colors } }) => colors.warning};
  border: 1px solid ${({ theme: { colors } }) => lighten(0.1, colors.paleWarning)};
  color: ${({ theme: { colors } }) => colors.black};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ theme: { colors } }) => colors.darkGrey};
        background-color: ${({ theme: { colors } }) => colors.paleWarning};
        border: 1px solid ${({ theme: { colors } }) => colors.warning};
      }

      &:focus {
        color: ${({ theme: { colors } }) => colors.paleGrey};
        background-color: ${({ theme: { colors } }) => colors.tanWarning};
        border: 1px solid ${({ theme: { colors } }) => colors.paleWarning};
      }
    `};
`;

const buttonStyles = {
  general: buttonGeneral,
  generalPurple: buttonGeneralPurple,
  generalWhite: buttonGeneralWhite,
  buttonLabel: label,
  notify: buttonNotify,
  success: buttonSuccess,
  error: buttonError,
  warning: buttonWarning,
};

const ButtonWrap = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  white-space: nowrap;
  //padding: 0.7em 1em;
  font-size: 1rem;
  ${scale([['768px', [['padding', `0.7em ${theme.padding.pageD}px`]]]])}
  padding: 0.7em ${({ theme: { padding } }) => padding.pageM}px;

  &:focus {
    outline: none;
  }

  ${({ buttonstyle }) => buttonStyles[buttonstyle]};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};

  ${UserSelectNoneCss};
`;

const iconGeneral = css`
  color: ${({ theme: { colors } }) => colors.black};

  ${ButtonWrap}:hover & {
    color: ${({ theme: { colors } }) => colors.darkGrey};
  }

  ${ButtonWrap}:focus & {
    color: ${({ theme: { colors } }) => colors.paleGrey};
  }
`;

const iconsStyles = {
  general: iconGeneral,
  success: iconGeneral,
  error: iconGeneral,
  warning: iconGeneral,
};

const buttonCss = css`
  height: auto;
  width: auto;
  margin-right: ${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['margin-right', `${theme.padding.pageD}px`]]]])}
  ${({ iconstyle }) => iconsStyles[iconstyle]};
`;

const StyledBackIcon = styled(Icon)`
  ${buttonCss}
`;

const StyledFrontIcon = styled(Icon)`
  ${buttonCss}
`;

// const StyledSpinner = styled(Spinner)`
//   font-size: ${({theme: {typo}}) => typo.sizes.body};
//   ${buttonCss}
// `;

function Button({
  label,
  frontIconName,
  backIconName,
  buttonStyle,
  isLoading,
  asLink = false,
  to = null,
  asAnchor = false,
  href = null,
  ...props
}) {
  const disabled = isLoading ? { disabled: true } : '';
  // const BackIcon = isLoading ? StyledSpinner : StyledBackIcon;
  // const FrontIcon = isLoading ? StyledSpinner : StyledFrontIcon;

  const BackIcon = StyledBackIcon;
  const FrontIcon = StyledFrontIcon;

  return (
    <ButtonWrap
      as={(asLink && Link) || (asAnchor && 'a')}
      to={to}
      href={href}
      buttonstyle={buttonStyle}
      {...props}
      {...disabled}
    >
      {backIconName && <BackIcon iconstyle={buttonStyle} iconName={backIconName} />}
      <Label>{label}</Label>
      {frontIconName && <FrontIcon iconstyle={buttonStyle} iconName={frontIconName} />}
    </ButtonWrap>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  buttonStyle: PropTypes.string,
  frontIconName: PropTypes.string,
  backIconName: PropTypes.string,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  type: 'submit',
  buttonStyle: 'general',
  isLoading: false,
};

export default Button;
