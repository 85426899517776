import React, {Suspense, lazy} from 'react';
import CenteredWithBackground from 'layouts/CenteredWithBackground';
import Fallback from 'components/Fallback';

const ForgotPassword = lazy(() => import('./ForgotPassword'));

export default function ForgotPasswordWrap() {
  return (
    <CenteredWithBackground>
      <Suspense fallback={<Fallback/>}>
        <ForgotPassword />
      </Suspense>
    </CenteredWithBackground>
  );
}
