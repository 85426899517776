import Flow from '@flowjs/flow.js';
import EventEmitter from 'events';
import axios from 'axios';

import UploadService from 'services/upload';

import {getFile} from '../reduxUtils/fileUploader/selectors';
import {addFile, updateFile, deleteFile} from '../reduxUtils/fileUploader/actions';
import {CHUNKED_VIDEO_UPLOAD_URL} from 'config/config';
import {
  UPLOAD_VIDEO_CHUNK_SIZE,
  UPLOAD_STATUS,
  UPLOAD_TYPE, AMAZON_MIN_S3_CHUNK_SIZE
} from 'config/constants';

import {CONTENT_HAS_TRANSACTIONS} from 'config/errors';

import {getAccessToken} from 'utilities/auth';
import {errorHandler, successHandler} from 'utilities/services';
import {getStore} from 'utilities/store';
import {prepareFile} from 'utilities/file';
import {RemovePreventRefreshWarning, SetPreventRefreshWarning} from 'utilities/general';

const uploadService = new UploadService();
const sameFileError = 'Please cancel previous file or select the same file.';

export default class FlowWrap extends EventEmitter {
  constructor(
    flowId = 'event',
    flowOpts = {}
    //  beforeAdd= () => {}
  ) {
    super();

    const defaultOptions = {
      singleFile: true,
      target: CHUNKED_VIDEO_UPLOAD_URL,
      chunkSize: UPLOAD_VIDEO_CHUNK_SIZE,
      maxChunkRetries: 3,
      forceChunkSize: true,
      progressCallbacksInterval: 0,
      simultaneousUploads: 1,
      headers: {
        Authorization: getAccessToken()
      }
    };

    this._flowId = flowId;
    this._flowOpts = {...defaultOptions, ...flowOpts};
    //  this._beforeAdd = beforeAdd;
    this._flow = null;
    this._flowEl = null;
    this._retryFile = null;

    this.startFlow();
  }

  getFlowEl() {
    return this._flowEl;
  }

  removeDOM() {
    const elDOM = document.getElementById(this._flowId);
    if (elDOM) {
      elDOM.remove();
    }
  }

  createDOM() {
    this.removeDOM();
    const globalFlowEl = document.getElementById('flow');
    this._flowEl = document.createElement('div');
    this._flowEl.setAttribute('id', this._flowId);
    globalFlowEl.appendChild(this._flowEl);
  }

  assignFlow() {
    this.createDOM();
    this._flow.assignBrowse(document.getElementById(this._flowId), null, null, {accept: 'video/*'});
  }

  assignFlowDrop(el) {
    this._flow.assignDrop(el);
  }

  unAssignFlowDrop(el) {
    this._flow.unAssignDrop(el);
  }

  bindFlow() {
    this._flow.on('fileAdded', (file) => {
      /*
      if ( !this._beforeAdd(file.file) ) {
        errorHandler('The file you\'ve selected is not supported');
        return false;
      }
      */
      const store = getStore();
      const state = store.getState();
      const stateFile = getFile(this._retryFile)(state);
      if (stateFile && stateFile.uniqueIdentifier) {
        if (file.name !== stateFile.name) {
          errorHandler(sameFileError);
          this._retryFile = null;
          return false;
        }
      }

      if (this.isChunkedUpload(file)) {
        this.uploadChunked(file);
      } else {
        this.upload(file);
      }

      this.emit('fileAdded', file, this._flow, this._flowId, this._flowOpts);

      // retuning false for all none chunked files or old browsers so the wont be added to flow.
      if (!this.isChunkedUpload(file)) {
        return false;
      }

    });

    this._flow.on('uploadStart', () => {
      SetPreventRefreshWarning();
      this.emit('uploadStart');
    });

    this._flow.on('fileSuccess', (file, message) => {
      RemovePreventRefreshWarning();
      successHandler('File uploaded successfully');
      let parsedMessage = {};
      try {
        parsedMessage = JSON.parse(message);
      } catch (e) {
        console.log(`uploadStart: Error parsing json: `, e);
      }
      this.emit('fileSuccess', file, parsedMessage, this._flow, this._flowId, this._flowOpts);

      const {dispatch} = getStore();
      dispatch(deleteFile(file.name));
    });

    this._flow.on('fileError', (file, message) => {
      console.log(`fileError: ERROR - fileError file, message`, file, message);
      const {dispatch} = getStore();
      if (message.indexOf(CONTENT_HAS_TRANSACTIONS) > -1) {
        this.cancel(file);
      } else {
        dispatch(updateFile({
          name: file.name,
          status: UPLOAD_STATUS.ERROR.key
        }));
      }
      errorHandler('Ops! something went wrong please retry uploading the file');
      this.emit('fileError', file, message, this._flow, this._flowId, this._flowOpts);
    });

    this._flow.on('fileProgress', (file, chunk) => {
      const {dispatch} = getStore();
      dispatch(updateFile({
        name: file.name,
        progress: file.progress(),
        timeRemaining: file.timeRemaining()
      }));
      this.emit('progress', file, file.progress(), chunk, this._flow, this._flowId, this._flowOpts);
    });

    this._flow.on('fileRetry', (file) => {
      this.emit('fileRetry', file, this._flow, this._flowId, this._flowOpts);
    });
  }

  startFlow() {
    const {_query, ...flowOpts} = this._flowOpts;
    this._flow = new Flow({...flowOpts, query: {..._query}});

    this.assignFlow();
    this.bindFlow();
  }

  isChunkedUpload(file) {
    return this._flow.support && file.size >= AMAZON_MIN_S3_CHUNK_SIZE;
    // return this._flow.support && file.size >= UPLOAD_VIDEO_CHUNK_SIZE;
  }

  upload(flowFile) {
    const {file} = flowFile;
    const {dispatch} = getStore();

    const formData = new FormData();
    Object.keys(this._flowOpts._query).forEach((key) => {
      formData.append(key, this._flowOpts._query[key]);
    });
    formData.append('size', file.size);
    formData.append('file', file);

    const CancelToken = axios.CancelToken;
    let cancelRequest = null;
    const config = {
      onUploadProgress: (progressEvent) => {
        const {dispatch} = getStore();
        dispatch(updateFile({
          name: file.name,
          progress: (progressEvent.loaded * 1) / progressEvent.total
        }));
        this.emit('progress', flowFile, (progressEvent.loaded * 1) / progressEvent.total);
      },
      cancelToken: new CancelToken(function executor(c) {
        cancelRequest = c;
      })
    };

    uploadService.uploadVideo(formData, config)
      .then((message) => {
        const {dispatch} = getStore();
        dispatch(deleteFile(file.name));
        RemovePreventRefreshWarning();
        successHandler('File uploaded successfully');
        this.emit('fileSuccess', flowFile, message);
      })
      .catch((e) => {
        const {dispatch} = getStore();
        errorHandler(e);
        if (e && e.data && e.data.error === CONTENT_HAS_TRANSACTIONS) {
          this.cancel({name: file.name});
        } else {
          dispatch(updateFile({
            name: file.name,
            status: UPLOAD_STATUS.ERROR.key
          }));
        }
        this.emit('fileError', flowFile, e);
      });

    dispatch(addFile(prepareFile(
      {
        flowId: this._flowId,
        flowOpts: this._flowOpts,
        flowFile,
        objUrl: window.URL.createObjectURL(file),
        file,
        uploadType: this.isChunkedUpload(file)
          ? UPLOAD_TYPE.CHUNKED.key
          : UPLOAD_TYPE.NORMAL.key,
        cancelRequest
      }
    )));
  }

  async uploadChunked(flowFile) {
    const {file} = flowFile;
    const store = getStore();
    const {dispatch} = store;
    const state = store.getState();
    const stateFile = getFile(this._retryFile)(state);

    // if we have a file from state that has uniqueIdentifier we want to continue and use that multi-part.
    if (stateFile && stateFile.uniqueIdentifier) {
      //  checking if multipart started and exists
      const started = await uploadService.uploadChunkedVideoStarted({
        UploadId: stateFile.uniqueIdentifier,
        fileName: file.name,
        ...this._flowOpts._query
      }).catch(() => {
      });

      if (started) {
        // if exist use that upload ID
        flowFile.uniqueIdentifier = stateFile.uniqueIdentifier;
      } else {
        // doesnt exist create new multi-part. and use that.
        const results = await uploadService.uploadChunkedVideoStart({
          fileName: file.name,
          fileSize: file.size,
          ...this._flowOpts._query
        })
          .catch((e) => {
            RemovePreventRefreshWarning();
            errorHandler(e);
          });
        if (results && results.data) {
          flowFile.uniqueIdentifier = results.data.UploadId;
        }
      }
    } else {
      dispatch(addFile(prepareFile(
        {
          flowId: this._flowId,
          flowOpts: this._flowOpts,
          flowFile,
          objUrl: window.URL.createObjectURL(file),
          file,
          uploadType: this.isChunkedUpload(file)
            ? UPLOAD_TYPE.CHUNKED.key
            : UPLOAD_TYPE.NORMAL.key
        }
      )));
      try {
        const results = await uploadService.uploadChunkedVideoStart({
          fileName: file.name,
          fileSize: file.size,
          ...this._flowOpts._query
        });
        if (results && results.data) {
          flowFile.uniqueIdentifier = results.data.UploadId;
          dispatch(updateFile({
            name: file.name,
            uniqueIdentifier: results.data.UploadId
          }));
          setTimeout(() => {
            this._flow.upload();
          }, 500);
        }
      } catch (e) {
        RemovePreventRefreshWarning();
        errorHandler(e);
        dispatch(updateFile({
          name: file.name,
          status: UPLOAD_STATUS.ERROR.key
        }));
      }
    }

  }

  progress({uniqueIdentifier}) {
    const flowFile = this._flow.getFromUniqueIdentifier(uniqueIdentifier);
    if (flowFile) {
      return flowFile.progress();
    }
  }

  resume({uniqueIdentifier, name}) {
    const flowFile = this._flow.getFromUniqueIdentifier(uniqueIdentifier);
    if (flowFile) {
      flowFile.resume();
      const {dispatch} = getStore();
      dispatch(updateFile({
        name,
        status: UPLOAD_STATUS.UPLOADING.key
      }));
    }
  }

  pause({uniqueIdentifier, name}) {
    const flowFile = this._flow.getFromUniqueIdentifier(uniqueIdentifier);
    if (flowFile) {
      flowFile.pause();
      const {dispatch} = getStore();
      dispatch(updateFile({
        name,
        status: UPLOAD_STATUS.PAUSED.key
      }));
    }
  }

  cancel({uniqueIdentifier, name, cancelRequest}) {
    const flowFile = this._flow.getFromUniqueIdentifier(uniqueIdentifier);
    const {dispatch} = getStore();
    if (flowFile) {
      uploadService.uploadChunkedVideoAbort({
        uniqueIdentifier: flowFile.uniqueIdentifier,
        fileName: name,
        ...this._flowOpts._query
      });
      flowFile.cancel();
    } else {
      this._flow.cancel();
    }

    RemovePreventRefreshWarning();
    cancelRequest && cancelRequest();
    dispatch(deleteFile(name));
    this._retryFile = null;
    this.emit('uploadCanceled', flowFile);
  }

  retry({uniqueIdentifier, name}) {
    const flowFile = this._flow.getFromUniqueIdentifier(uniqueIdentifier);
    const store = getStore();
    const {dispatch} = store;
    const state = store.getState();
    const stateFile = getFile(name)(state);
    if (flowFile) {
      flowFile.retry();
      dispatch(updateFile({
        name,
        status: UPLOAD_STATUS.UPLOADING.key
      }));
    } else if (stateFile) {
      this._retryFile = name;
      this._flowEl.click();
    }
  }
}

let flows = [];

export function addFlow(newFlow) {
  const foundIndex = flows.findIndex((flow) => flow._flowId === newFlow._flowId);
  if (foundIndex > -1) {
    return flows[foundIndex] = newFlow;
  }
  return flows.push(newFlow);
}

export function getFlow(flowId) {
  const foundIndex = flows.findIndex((flow) => flow._flowId === flowId);
  if (foundIndex > -1) {
    return flows[foundIndex];
  }

  return false;
}

export function removeFlow(flowId) {
  const foundIndex = flows.findIndex((flow) => flow._flowId === flowId);
  if (foundIndex > -1) {
    return flows.splice(foundIndex, 1);
  }

  return false;
}