import {handleActions} from 'redux-actions';
import {differenceWith, unionWith} from 'lodash';
import {
  ADD_HOME_FAVORITES,
  DELETE_HOME_FAVORITE, UPDATE_OCCURRENCE_HOME_FAVORITES
} from './types';
import {comparator} from 'utilities/general';
import {LOGOUT} from "../auth/types";
import {reduxUpdateEventOccurrenceHelper} from "../helpers/events";
//  import categories from 'config/categories';

/*
const defaultEventFields = [
  'favoriteId',
  'id',
  'isEvent',
  'isLive',
  'allowDropIn',
  'isPrivate',
  'paymentType',
  'title',
  'summary',
  'coverPhotoDetails',
  'coverPhoto',// = logo,
  'coverPhotoHomePage',
  'price',
  'currency',
  'rating',
  'date',
  'endDate',
  'duration',
  'userFullName',
  'UserId',
  'status',
  'categories',
  'user'
];
*/

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [ADD_HOME_FAVORITES]: (state, {payload}) => {
    const comparator = (itemA, itemB) => {
      return itemA.favoriteId === itemB.favoriteId;
    };

    const {favoritesTile = [], properties} = payload;

    const unionArr = unionWith(state.rows, favoritesTile, comparator);
    if (unionArr.length === state.rows.length)
      return state;
    return {
      ...state,
      ...properties,
      rows: unionArr
    };
  },
  [UPDATE_OCCURRENCE_HOME_FAVORITES]: (state, {payload}) => {
    return reduxUpdateEventOccurrenceHelper(state, payload);
  },

  [DELETE_HOME_FAVORITE]: (state, {payload}) => {
    const {deleteIds = [], properties} = payload;

    const diff = differenceWith(state.rows, deleteIds, comparator);
    if (diff.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      ...properties,
      rows: diff
    };
  },

  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});