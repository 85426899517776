// import { createCachedSelector } from 're-reselect';

export const getUserFavorites = (state) => state.userFavorites;

export function getFavorite(state, eventId, workshopId) {
  const userFavorites = getUserFavorites(state);
  return userFavorites.find((item) => item.EventId === eventId || item.WorkshopId === workshopId);
}
// export const getFavorite = createCachedSelector(
//   getUserFavorites,
//   (state, eventId) => eventId,
//   (state, eventId, workshopId) => workshopId,
//   (userFavorites, eventId, workshopId) => {
//     return userFavorites.find((item) => item.EventId === eventId || item.WorkshopId === workshopId);
//   }
// )(
//   (state, eventId, workshopId) =>
//     (eventId && `eventId_${eventId}`) || (workshopId && `workshopId_${workshopId}`) // Use dataType as cacheKey
// );
