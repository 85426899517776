export function setData(key, value) {
  return localStorage.setItem(key, JSON.stringify(value));
}

export function appendData(key, value) {
  let data = getData(key);
  data = {...data, ...value}
  return localStorage.setItem(key, JSON.stringify(data));
}

export function getData(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function removeData(key) {
  localStorage.removeItem(key);
}

export function clearStorage(key) {
  localStorage.clear();
}
