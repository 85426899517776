import {handleActions} from 'redux-actions';
import {
  SET_BROWSER_DIMENSIONS,
  SET_BROWSER_IS_Left_BAR_OPENED,
  SET_IS_FX_READY, SET_LOGIN_MODAL_STATUS
} from './types';
import {isMobile, isTablet} from 'utilities/general';

const initialScreenDimensions = {
  width: window.innerWidth,
  height: window.innerHeight
};

let initialState = {
  isMobile: isMobile(initialScreenDimensions) || isTablet(initialScreenDimensions),
  isLeftBarOpened: true,
  screenDimensions: initialScreenDimensions,
  isFxReady: false,
  loginModalStatus: false,
};

export default handleActions({
  [SET_BROWSER_IS_Left_BAR_OPENED]: (state, action) => {
    return {
      ...state,
      isLeftBarOpened: action.payload
    };
  },
  [SET_BROWSER_DIMENSIONS]: (state, action) => {
    const screenDimensions = {
      ...state.screenDimensions,
      ...action.payload
    };

    return {
      ...state,
      screenDimensions,
      isMobile: isMobile(screenDimensions) || isTablet(screenDimensions)
    };
  },
  [SET_IS_FX_READY]: (state, action) => {
    return {
      ...state,
      isFxReady: action.payload
    };
  },
  [SET_LOGIN_MODAL_STATUS]: (state, {payload}) => {
    return {
      ...state,
      loginModalStatus: payload
    };
  }
}, {...initialState});
