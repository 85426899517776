import React from 'react';
import styled from 'styled-components';
import Icon from "components/Icon";
import {compactNumber} from "utilities/general";
import {TextShadowCss} from "styles/common";
import PropTypes from "prop-types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const UsersCountWrap = styled.div`
  color: ${({theme: {colors}}) => colors.paleGrey};
  display: flex;
  align-items: center;
  font-weight: 500;
  ${TextShadowCss};
`;

const UserCount = styled.div`

`;

const UsersIcon = styled(Icon)`
`;

function UsersCount({usersCount, numOnLeftOfIcon, hallowIcon, tooltipDisabled, compact = true, ...props}) {
  const compactUsersCount = compact ? compactNumber(usersCount) : usersCount;
  const iconName = hallowIcon ? 'far fa-user' : 'fas fa-user';

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>registered users</Tooltip>}
    >
      {({ref, ...triggerHandler}) => (
        <UsersCountWrap
          {...props}
          ref={ref}
          {...triggerHandler}
          style={tooltipDisabled ? {pointerEvents: 'none'} : {}}
        >
          {
            !numOnLeftOfIcon &&
            <UsersIcon iconName={iconName}/>
          }
          <UserCount>
            {compactUsersCount}
          </UserCount>
          {
            numOnLeftOfIcon &&
            <UsersIcon iconName={iconName}/>
          }
        </UsersCountWrap>
      )}
    </OverlayTrigger>

  );
}

UsersCount.propTypes = {
  usersCount: PropTypes.number,
  numOnLeftOfIcon: PropTypes.bool,
  compact: PropTypes.bool,
  tooltipDisabled: PropTypes.bool,
  hallowIcon: PropTypes.bool,
};

export default UsersCount;