import WorkshopService from 'services/workshop';
import UploadService from 'services/upload';

import {updateWorkshopAction} from './actions';
import {updateWorkshopSettingsAction} from '../workshopSettings/actions';
import {setWorkshopOccurrencesAction} from '../workshopOccurrences/actions';
import {deleteEventAction} from '../event/actions';
import {deleteEventSettingsAction} from '../eventSettings/actions';
import {setEventOccurrencesAction} from '../eventOccurrences/actions';
import {errorHandler} from 'utilities/services';

const workshopService = new WorkshopService();
const uploadService = new UploadService();

export function startWorkshop(workshop) {
  return dispatch => workshopService
    .startWorkshop(workshop)
    .then((response) => {
      const {data: {workshop}} = response;
      dispatch(updateWorkshopAction(workshop));
      dispatch(updateWorkshopSettingsAction(workshop.workshopSetting || {}));
      dispatch(setWorkshopOccurrencesAction(workshop.occurrences || []));
      return response;
    })
}

export function completeWorkshop(workshopId) {
  return dispatch => workshopService
    .completeWorkshop(workshopId)
    .then((response) => {
      const {data: {workshop}} = response;
      dispatch(updateWorkshopAction(workshop));
      return response;
    })
}

export function getWorkshop(workshopId) {
  return dispatch => workshopService
    .getWorkshop(workshopId)
    .then((response) => {
      const {data: {workshop}} = response;
      dispatch(updateWorkshopAction(workshop));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}

// todo: make sure that upload id is loaded with workshop

export function getWorkshopEager(workshopId) {
  return dispatch => workshopService
    .getWorkshopEager(workshopId)
    .then((response) => {
      const {data: {workshop}} = response;
      dispatch(updateWorkshopAction(workshop));
      dispatch(updateWorkshopSettingsAction(workshop.workshopSetting || {}));
      dispatch(setWorkshopOccurrencesAction(workshop.occurrences || []));
      return response;
    })
}

export function getWorkshopUploadSignUrl(workshop, uploadId) {
  return dispatch => uploadService
    .getUploadSignedUrl(uploadId)
    .then((response) => {
      const {data: {url}} = response;
      dispatch(updateWorkshopAction({
        ...workshop,
        upload: {
          ...workshop.upload,
          url
        }
      }));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}

export function updateWorkshop(workshopId, workshop) {
  return dispatch => workshopService
    .updateWorkshop(workshopId, workshop)
    .then((response) => {
      const {data: {workshop}} = response;
      dispatch(updateWorkshopAction(workshop));
      return response;
    })
}

export function resetWorkshopActions() {
  return dispatch => {
    dispatch(deleteEventAction());
    dispatch(deleteEventSettingsAction());
    dispatch(setEventOccurrencesAction([]));
  };
}

export function deleteWorkshop(workshopId) {
  return dispatch => workshopService
    .deleteWorkshop(workshopId)
    .then((response) => {
      resetWorkshopActions()(dispatch);
      return response;
    })
}

export function updateWorkshopCoverPhoto(workshopId, data, requestConfig) {
  return dispatch => workshopService
    .updateWorkshopCoverPhoto(workshopId, data, requestConfig)
    .then((response) => {
      const {data: {coverPhoto, coverPhotoThumbnail}} = response;
      dispatch(updateWorkshopAction({coverPhoto, coverPhotoThumbnail}));
      return response;
    })
}


/*
export function getWorkshopIncomplete() {
  return dispatch => workshopService
    .getWorkshopIncomplete()
    .then((response) => {
      return handleEager(dispatch, response);
    });
}
*/