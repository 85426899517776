import canCreateLiveDuration from './can-create-live-duration';
import canCreateLiveParticipantsLimit from './can-create-live-participants-limit';
import canCreateRecordDuration from './can-create-record-duration';
import canViewOccurrence from './can-view-occurrence';
import canView from './can-view';

const toExport = {
  canCreateLiveDuration,
  canCreateLiveParticipantsLimit,
  canCreateRecordDuration,
  canViewOccurrence,
  canView
};

export default toExport;