import styled, {css} from 'styled-components';
import {scale} from 'styles/common';
import {theme} from 'styles/theme';
import {UPLOAD_STATUS} from 'config/constants';
import {ProgressBar} from 'react-bootstrap';

export const HeaderTitle = styled.div`
  width: 22em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}
  ${({isCollapsed}) =>
    isCollapsed && css`
      width: 70px;
    `
  }

  ${({status, theme: {colors}}) =>
    status === UPLOAD_STATUS.ERROR.key && css`color: ${colors.error}`
  }
`;

export const StyledProgressBar = styled(ProgressBar)`
  height: 11px;
  border-radius: 2px;
  font-size: ${({theme: {typo}}) => typo.sizes.tiny};
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]]
  ])}
  font-weight: bold;
`;


export const TimeRemaining = styled.div`
  font-size: ${({theme: {typo}}) => typo.sizes.tiny};
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]]
  ])}
  font-weight: bold;
`;
