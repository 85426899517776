import {handleActions} from 'redux-actions';
import {cloneDeep, unionWith} from 'lodash';
import {
  ADD_POLL,
  ADD_POLLS,
  CLEAR_POLLS,
  DELETE_POLL,
  SET_POLLS,
  UPDATE_POLL,
  UPDATE_POLL_OPTION,
  UPSERT_POLL,
  UPSERT_POLL_OPTION_VOTE,
} from './types';
import {LOGOUT} from "../auth/types";
import {isSubsetObject} from "utilities/general";
import {getUserVoteIndex} from "utilities/polls.const";

const comparator = (itemA, itemB) => {
  return itemA.id === itemB.id;
};

export const initialState = {
  rows: [],
};

export default handleActions({
  [SET_POLLS]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    return {
      ...initialState,
      rows: payload
    };
  },
  [ADD_POLLS]: (state, {payload}) => {
    if (!payload) {
      return state
    }
    const unionArr = unionWith(payload, state.rows, comparator);
    if (unionArr.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      rows: unionArr
    };
  },

  [ADD_POLL]: (state, {payload}) => {
    if (!payload) {
      return state
    }

    const foundIndex = state.rows.findIndex((el) => {
      return el.id === payload.id
    });

    if (foundIndex > -1) {
      return state;
    }

    const elements = [{...payload}, ...state.rows];

    return {
      ...state,
      rows: elements
    };

  },

  [DELETE_POLL]: (state, {payload}) => {
    const foundIndex = state.rows.findIndex((el) => {
      return el.id === payload
    });

    if (foundIndex > -1) {
      const elements = [...state.rows];
      elements.splice(foundIndex, 1);
      return {
        ...state,
        rows: elements
      };
    }

    return state;
  },
  [UPDATE_POLL]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    const foundIndex = state.rows.findIndex((el) => {
      return !!(payload.id && el.id === payload.id);
    });

    if (foundIndex > -1) {
      if (isSubsetObject(state.rows[foundIndex], payload)) {
        return state;
      }

      const elements = [...state.rows];
      elements[foundIndex] = {...elements[foundIndex], ...payload}
      return {
        ...state,
        rows: elements
      };
    }


    return state;
  },
  [UPSERT_POLL]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    const foundIndex = state.rows.findIndex((el) => {
      return !!(payload.id && el.id === payload.id);
    });

    if (foundIndex > -1) {
      if (isSubsetObject(state.rows[foundIndex], payload)) {
        return state;
      }

      const elements = [...state.rows];
      elements[foundIndex] = {...elements[foundIndex], ...payload}
      return {
        ...state,
        rows: elements
      };
    } else {

      const elements = [...state.rows, {...payload}];

      return {
        ...state,
        rows: elements
      };
    }
  },
  [UPSERT_POLL_OPTION_VOTE]: (state, {payload}) => {
    const foundPollIndex = state.rows.findIndex((el) => {
      return !!(payload.PollId && el.id === payload.PollId);
    });

    if (foundPollIndex === -1) {
      return state;
    }

    const poll = state.rows[foundPollIndex];

    const [userPollOptionIndex, userPollOptionVotesIndex] = getUserVoteIndex(poll, payload.UserId);
    if (userPollOptionIndex > -1) {
      //same vote
      if (poll.PollOptions[userPollOptionIndex].id === payload.PollOptionId) {
        return state;
      }
    }
    const foundPollOptionIndex = poll.PollOptions?.findIndex((el) => {
      return el.id === payload.PollOptionId
    });

    //cant find the pollOption to insert the vote to
    if (foundPollOptionIndex === -1) {
      return state;
    }

    const newPoll = cloneDeep(poll);

    //remove old vote
    if (userPollOptionIndex > -1) {
      newPoll.PollOptions[userPollOptionIndex].PollOptionVotes.splice(userPollOptionVotesIndex, 1);
    }

    newPoll.PollOptions[foundPollOptionIndex].PollOptionVotes.push({...payload})

    const elements = [...state.rows];
    elements[foundPollIndex] = newPoll;

    return {
      ...state,
      rows: elements
    };

  },
  [UPDATE_POLL_OPTION]: (state, {payload}) => {
    const foundPollIndex = state.rows.findIndex((el) => {
      if (payload.PollId && el.id && el.id === payload.PollId) {
        return true;
      }
      return false;

    });

    if (foundPollIndex === -1) {
      return state;
    }

    const foundIndex = state.rows[foundPollIndex].PollOptions?.findIndex((el) => {
      return el.id === payload.id
    });

    if (foundIndex > -1) {
      if (isSubsetObject(state.rows[foundPollIndex].PollOptions[foundIndex], payload)) {
        return state;
      }

      const elements = [...state.rows];
      const newOptions = [...elements[foundPollIndex].PollOptions];
      newOptions[foundIndex] = {...newOptions[foundIndex], ...payload};
      elements[foundPollIndex] = {
        ...elements[foundPollIndex],
        PollOptions: newOptions
      };

      return {
        ...state,
        rows: elements
      };
    }

    return state;
  },

  [CLEAR_POLLS]: () => {
    return initialState;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, initialState);