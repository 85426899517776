import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { scale } from 'styles/common';
import { theme } from 'styles/theme';

import { UPLOAD_TYPE } from 'config/constants';

import { getFileUploader } from '../../reduxUtils/fileUploader/selectors';
import { setCollapsed } from '../../reduxUtils/fileUploader/actions';

import File from './components/File';
import ChunkedFile from './components/ChunkedFile';
import Icon from 'components/Icon';

const FileUploaderWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900;
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          bottom: 70px;
          left: 5px;
          position: fixed;
          ${scale([
            [
              '768px',
              [
                ['bottom', '15px'],
                ['left', '15px'],
                ['position', 'absolute'],
              ],
            ],
          ])}
        `
      : css`
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}
`;

const FileUploaderContainer = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.fill};
  border: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  max-width: 100%;
  position: relative;
  ${scale([
    [
      '768px',
      [
        ['max-width', 'auto'],
        ['margin', '0'],
      ],
    ],
  ])}
`;

const FileUploaderBackDrop = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 10px;
  background-color: ${({ theme: { colors } }) => colors.darkGrey};
  opacity: 0.6;
  position: absolute;
`;

const FileUploaderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      border-bottom: none;
    `}
`;

const FileUploaderHeaderTitle = styled.div`
  margin-right: 20px;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]],
  ])}
`;

const FileUploaderHeaderSpacer = styled.div``;

const FileUploaderHeaderMinimize = styled(Icon)`
  padding: 2px 3px;
  width: auto;
  height: auto;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme: { colors } }) => colors.darkGrey};
    border-color: ${({ theme: { colors } }) => colors.darkGrey};
  }

  font-size: ${({ theme: { typo } }) => typo.sizes.body};
  ${scale([
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]],
  ])}

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      font-size: ${({ theme: { typo } }) => typo.sizes.body};
      ${scale([
        ['768px', [['font-size', theme.typo.sizes.body]]],
        ['992px', [['font-size', theme.typo.sizes.small]]],
        ['1200px', [['font-size', theme.typo.sizes.small]]],
        ['1400px', [['font-size', theme.typo.sizes.base]]],
      ])}
      transform: rotate(180deg);
    `}
`;

const FileUploaderBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
`;

const FileUploaderWarning = styled.div`
  color: #a1001e;
  max-width: 450px;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.medium]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]],
  ])}
`;

function FileUploader({ setCollapsed, fileUploader: { isCollapsed, files }, ...props }) {
  if (!files.length) {
    return null;
  }
  return (
    <FileUploaderWrap isCollapsed={isCollapsed} {...props}>
      <FileUploaderBackDrop onClick={() => setCollapsed(!isCollapsed)} />
      <FileUploaderContainer>
        {!isCollapsed && (
          <>
            <FileUploaderHeader>
              <FileUploaderHeaderTitle>File uploading</FileUploaderHeaderTitle>
              <FileUploaderHeaderMinimize
                onClick={() => setCollapsed(!isCollapsed)}
                iconName="fas fa-window-minimize"
              />
            </FileUploaderHeader>
            <FileUploaderBody>
              {files.map((file) => {
                const FileComponent =
                  file.uploadType === UPLOAD_TYPE.CHUNKED.key ? ChunkedFile : File;
                return <FileComponent key={file.name} isCollapsed={isCollapsed} {...file} />;
              })}
              <FileUploaderWarning>
                You can minimize this window and browse around.
                <br />
                However please do not refresh the window or open another tab while uploading.
              </FileUploaderWarning>
            </FileUploaderBody>
          </>
        )}
        {isCollapsed && (
          <>
            <FileUploaderHeader isCollapsed>
              <FileUploaderHeaderSpacer />
              <FileUploaderHeaderMinimize
                isCollapsed
                onClick={() => setCollapsed(!isCollapsed)}
                iconName="fas fa-window-maximize"
              />
            </FileUploaderHeader>
            <FileUploaderBody>
              {files.map((file) => {
                const FileComponent =
                  file.uploadType === UPLOAD_TYPE.CHUNKED.key ? ChunkedFile : File;
                return <FileComponent key={file.name} isCollapsed={isCollapsed} {...file} />;
              })}
            </FileUploaderBody>
          </>
        )}
      </FileUploaderContainer>
    </FileUploaderWrap>
  );
}

FileUploader.propTypes = {
  fileUploader: PropTypes.object,
  setCollapsed: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    fileUploader: getFileUploader(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCollapsed,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
