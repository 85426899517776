import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PlayIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  //padding-top: 14px;
  //padding-left: 8px;
  line-height: 20px;
  border: 4px solid #fff;
  border-radius: 50%;
  color: #f5f5f5;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 10px #ff0080cc;
    text-shadow: 0 0 10px #ff0080cc;
  }

`;

const PlayIconElement = styled.i`
  color: white;
  text-decoration: none;
`;

function PlayIcon({className, ...props}) {
  return (
    <PlayIconWrap className={className} {...props} >
      <PlayIconElement className={'fa fa-play'} />
    </PlayIconWrap>
  );
}

PlayIcon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string
};
export default PlayIcon;