import {errorHandler} from "utilities/services";
import UserService from "services/user";
import {addPurchasesLiveEvents, setPurchasesLiveEvents} from "./actions";

const userService = new UserService();

export function fetchPurchasesLiveEvents(query = {}, addNextPage) {
  return dispatch => userService.getAllPurchases(query).then((response) => {
    const {data: {purchases}} = response;
    if (addNextPage) {
      dispatch(addPurchasesLiveEvents(purchases));
    } else {
      dispatch(setPurchasesLiveEvents(purchases));
    }
    return response;
  }).catch(errorHandler);
}