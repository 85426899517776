import {handleActions} from 'redux-actions';
import {
  SET_HOME_LIVE_EVENTS,
  ADD_HOME_LIVE_EVENTS, UPDATE_OCCURRENCE_LIVE_EVENT,
} from './types';
import {
  reduxAddEventHelper,
  reduxEventHelper, reduxUpdateEventOccurrenceHelper
} from "../helpers/events";


export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_HOME_LIVE_EVENTS]: (state, {payload}) => {
    return reduxEventHelper(state, payload);
  },
  [ADD_HOME_LIVE_EVENTS]: (state, {payload}) => {
    return reduxAddEventHelper(state, payload);
  },
  [UPDATE_OCCURRENCE_LIVE_EVENT]: (state, {payload}) => {
    return reduxUpdateEventOccurrenceHelper(state, payload);
  },
}, {...initialState});