import FollowingService from 'services/following';

import {
  addUserFollowing,
  deleteUserFollowing
} from './actions';
import {deleteHomeFollowing} from "../homeFollowings/actions";


const followingService = new FollowingService();

export function addFollowing(channelId) {
  return dispatch => followingService
    .postFollowing({channelId})
    .then((response) => {
      const {data: {userFollowing}} = response;
      dispatch(addUserFollowing(userFollowing));
      return response;
    })
}

export function deleteFollowing(id, toUserId) {
  return dispatch => followingService
    .deleteFollowing(id)
    .then((response) => {
      dispatch(deleteUserFollowing(id));
      dispatch(deleteHomeFollowing(toUserId));
      return response;
    })
}