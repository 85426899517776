import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UserSelectNoneCss } from 'styles/common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNewPolls } from '../../../../../../reduxUtils/newPolls/selectors';
import {
  addNewPollAction,
  clearNewPollsAction,
  deleteNewPollAction,
  updateNewPollAction,
} from '../../../../../../reduxUtils/newPolls/actions';
import { generatePollOptionId, generatePollId } from 'utilities/general';
import NewPollElement from 'components/Chat/components/PollsContainer/components/NewPoll/components/NewPollElement';
import {
  createNewPoll,
  deleteNewPoll,
  publishNewPoll,
} from '../../../../../../reduxUtils/newPolls/thunks';
import { POLL, POLL_OPTION, POLL_STATUS } from 'utilities/polls.const';

const AddPollButton = styled.button`
  margin-top: 5px;
  width: 100%;
  display: flex;
  height: 43px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.purpleFeather};
  border: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: bold;
  transition: 0.1s linear;

  &:hover {
    color: ${({ theme: { colors } }) => colors.dark};
    background-color: #d9c3ea;
    transform: scale(1.03);
  }

  &:active {
    transform: scale(1);
  }

  ${UserSelectNoneCss};
`;

let lastOccurrenceId = null;
let lastEventId = null;
let lastWorkshopId = null;

function NewPoll({
  userId,
  eventId,
  occurrenceId,
  workshopId,
  newPolls,
  addNewPollAction,
  deleteNewPollAction,
  updateNewPollAction,
  createNewPoll,
  publishNewPoll,
  deleteNewPoll,
  clearNewPollsAction,
}) {
  useEffect(() => {
    if (
      lastOccurrenceId !== occurrenceId ||
      lastEventId !== eventId ||
      workshopId !== lastWorkshopId
    ) {
      lastOccurrenceId = occurrenceId;
      lastEventId = eventId;
      lastWorkshopId = workshopId;

      clearNewPollsAction();
      addNewPoll();
    }
  }, [userId, eventId, occurrenceId, workshopId, clearNewPollsAction]); // eslint-disable-line

  function addNewPoll() {
    addNewPollAction({
      ...POLL,
      id: generatePollId(),
      UserId: userId,
      EventId: eventId,
      OccurrenceId: occurrenceId,
      WorkshopId: workshopId,
      status: POLL_STATUS.STARTED.key,
      PollOptions: [
        {
          ...POLL_OPTION,
          id: generatePollOptionId(),
        },
      ],
    });
  }

  return (
    <>
      <AddPollButton onClick={addNewPoll}>Add Poll</AddPollButton>
      {newPolls.map((poll) => {
        return (
          <NewPollElement
            key={poll.id}
            poll={poll}
            deleteNewPollAction={deleteNewPollAction}
            updateNewPollAction={updateNewPollAction}
            createNewPoll={createNewPoll}
            publishNewPoll={publishNewPoll}
            deleteNewPoll={deleteNewPoll}
          />
        );
      })}
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addNewPollAction,
      deleteNewPollAction,
      updateNewPollAction,
      createNewPoll,
      publishNewPoll,
      deleteNewPoll,
      clearNewPollsAction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    newPolls: getNewPolls(state),
  };
}

NewPoll.propTypes = {
  userId: PropTypes.number,
  eventId: PropTypes.string,
  occurrenceId: PropTypes.string,
  workshopId: PropTypes.string,
  newPolls: PropTypes.array,
  addNewPollAction: PropTypes.func,
  deleteNewPollAction: PropTypes.func,
  updateNewPollAction: PropTypes.func,
  createNewPoll: PropTypes.func,
  publishNewPoll: PropTypes.func,
  deleteNewPoll: PropTypes.func,
  clearNewPollsAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NewPoll));
