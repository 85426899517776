import styled from 'styled-components';
import PlaceHolder from 'components/PlaceHolder';

export const TileDescriptionHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const TileDescriptionTitle = styled.div`
  flex-grow: 1;
`;

export const TileDescriptionBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TilePlaceHolders = styled(PlaceHolder)`
  
`;
