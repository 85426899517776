import React, { lazy, useState, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import { getUserId } from '../../reduxUtils/user/selectors';
import { getBrowserIsMobile } from '../../reduxUtils/browserSettings/selectors';
import { getUserFullName, getUserAvatarThumbnail } from '../../reduxUtils/userProfile/selectors';
import WithSideBar from 'layouts/WithSideBar';
import Fallback from 'components/Fallback';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';

const options = [
  {
    label: 'Home',
    iconName: 'fas fa-home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    iconName: 'fas fa-id-badge',
    linkTo: '/user-settings/profile',
  },
  {
    label: 'Payment Methods',
    iconName: 'fas fa-money-bill',
    linkTo: '/user-settings/billing',
  },
  {
    label: 'Creator Billing',
    iconName: 'fas fa-money-bill',
    linkTo: '/user-settings/creator-billing',
  },
  // {
  //   label: 'Membership',
  //   iconName: 'fas fa-user-tie',
  //   linkTo: '/user-settings/membership'
  // },
  // {
  //   label: 'Security',
  //   iconName: 'fas fa-shield-alt',
  //   linkTo: '/user-settings/security'
  // },
  {
    label: 'Preferences',
    iconName: 'fas fa-asterisk',
    linkTo: '/user-settings/preferences',
  },
];

const UserSettingsLazy = lazy(() => import('./UserSettings'));

function UserSettings({
  match,
  location,
  isMobile,
  isLoggedIn,
  userId,
  fullName,
  avatar,
  isChatDetach,
  isChatMinimized,
}) {
  const [isLeftBarOpened, setIsLeftBarOpened] = useState(true);

  function OnIsLeftBarChange() {
    setIsLeftBarOpened(!isLeftBarOpened);
  }

  if (!isLoggedIn) {
    return <Navigate to={`/`} />;
  }

  return (
    <WithSideBar
      location={location}
      options={options}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      setLocalIsLeftBarOpened={OnIsLeftBarChange}
      isChatMinimized={isChatMinimized && isChatDetach}
    >
      <Suspense fallback={<Fallback />}>
        <UserSettingsLazy match={match} />
      </Suspense>
    </WithSideBar>
  );
}

UserSettings.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    userId: getUserId(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

export default connect(mapStateToProps, null)(UserSettings);
