import React, { useState } from 'react';
//  import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import { buildCategories } from 'utilities/general';
import {
  LightCustomToolbar,
  ScaleFontMenuCss,
  StyledTextInputCss,
  UserSelectNoneCss,
} from 'styles/common';

import Icon from 'components/Icon';
import BaseMenuItem from 'components/MenuItems/BaseMenuItem';
import ContentMenuItem from 'components/MenuItems/ContentMenuItem';

const CategoriesWrap = styled(Dropdown)`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  background-color: ${({ theme: { colors } }) => colors.fill};
  font-size: ${({ theme: { typo } }) => typo.sizes.small};
  padding: 0 8px;
  ${UserSelectNoneCss};
`;

const CategoriesMenu = styled(Dropdown.Menu)`
  max-height: 250px;
  min-height: 100px;
  overflow: auto;
  inset: 125% auto auto 0 !important;
  font-size: ${({ theme: { typo } }) => typo.sizes.small};
  transform: translate(0) !important;
  ${LightCustomToolbar};
`;

const CategoriesIcon = styled(Icon)`
  width: auto;
  height: auto;
  cursor: pointer;
  font-size: ${({ theme: { typo } }) => typo.sizes.body};
  color: ${({ isOpened, theme: { colors } }) => (isOpened ? colors.black : colors.darkGrey)};
  margin-left: 10px;
`;

const CategoriesMenuItem = styled(BaseMenuItem)`
  ${ScaleFontMenuCss};
  padding: 5px 10px;
`;

const CategoriesToggler = styled.div`
  display: flex;
  align-items: center;
`;

const CategoriesTogglerTitle = styled.div`
  white-space: nowrap;
  ${ScaleFontMenuCss};
`;

const CategoriesFilterInput = styled.input`
  ${StyledTextInputCss};
  ${ScaleFontMenuCss};

  outline-color: ${({ theme: { colors } }) => colors.secondaryLight};

  ::placeholder,
  ::-webkit-input-placeholder {
    ${ScaleFontMenuCss};
  }

  :-ms-input-placeholder {
    ${ScaleFontMenuCss};
  }

  border-radius: 10px;

  width: 100%;
`;

const allCategory = { value: 'all', label: 'All' };

function Categories({ category, updateCategory }) {
  const [categoriesFilter, setCategoriesFilter] = useState('');

  function onSelect(option) {
    updateCategory(option);
    setCategoriesFilter('');
  }

  const CustomToggle = React.forwardRef(({ children, onClick, classname }, ref) => (
    <CategoriesToggler
      classname={classname}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </CategoriesToggler>
  ));

  let categories = buildCategories();
  categories = categories.filter(({ label }) => {
    if (!categoriesFilter) {
      return true;
    }

    return label.toLowerCase().indexOf(categoriesFilter.toLowerCase()) > -1;
  });

  return (
    <CategoriesWrap drop="down">
      <CategoriesWrap.Toggle as={CustomToggle}>
        <CategoriesTogglerTitle>{category.label}</CategoriesTogglerTitle>
        <CategoriesIcon iconName={'fas fa-caret-down'} />
      </CategoriesWrap.Toggle>
      <CategoriesMenu>
        <ContentMenuItem as={'div'}>
          <CategoriesFilterInput
            placeholder="Filter Categories"
            type="text"
            value={categoriesFilter}
            onChange={(e) => setCategoriesFilter(e.target.value)}
          />
        </ContentMenuItem>
        <CategoriesMenuItem
          onClick={() => onSelect(allCategory)}
          label={allCategory.label}
          isSelected={allCategory.value === category.value ? 1 : 0}
        />
        {categories.map((option, index) => {
          // if (option.value === 'others') {
          //   return null;
          // }
          return (
            <CategoriesMenuItem
              key={`${option.value}_${index}`}
              onClick={() => onSelect(option)}
              label={option.label}
              isSelected={option.value === category.value ? 1 : 0}
            />
          );
        })}
      </CategoriesMenu>
    </CategoriesWrap>
  );
}

Categories.propTypes = {};

export default Categories;
