import {createSelector} from 'reselect';

export const getEvent = state => state.event;
export const getEventId = createSelector(getEvent, event => event.id);
export const getEventUploadId = createSelector(getEvent, event => event.UploadId);
export const getEventTitle = createSelector(getEvent, event => event.title);
export const getEventCategories = createSelector(getEvent, event => event.categories);
export const getEventDescription = createSelector(getEvent, event => event.description);
export const getEventSummary = createSelector(getEvent, event => event.summary);
export const getEventCoverPhoto = createSelector(getEvent, event => event.coverPhoto);
export const getEventCoverPhotoThumbnail = createSelector(getEvent, event => event.coverPhotoThumbnail);
export const getEventIsLive = createSelector(getEvent, event => event.isLive);
export const getEventRecord = createSelector(getEvent, event => event.recordEvent);
export const getEventRecordDuration = createSelector(getEvent, event => event.recordDuration);
export const getEventPaymentType = createSelector(getEvent, event => event.paymentType);
export const getEventPrice = createSelector(getEvent, event => event.price);
export const getEventDuration = createSelector(getEvent, event => event.duration);
export const getEventParticipantsLimit = createSelector(getEvent, event => event.participantsLimit);
export const getEventRating = createSelector(getEvent, event => event.rating);
export const getEventStatus = createSelector(getEvent, event => event.status);