import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledTextInput from 'components/Inputs/StyledTextInput';
import { SettingFontSize } from 'components/Chat/components/PollsContainer/components/JSXElements';
import { debounceFunction, getTimeInSec } from 'utilities/general';
import { TIME_UNIT } from 'config/constants';

const Wrap = styled.div`
  display: flex;
  height: fit-content;
`;

const Input = styled(StyledTextInput)`
  margin-bottom: 0;

  > label {
    margin-bottom: 0;
    ${SettingFontSize};
  }

  > div {
    margin-bottom: 0;
    height: auto !important;
    padding: 0 5px !important;
  }

  > div > input {
    ${SettingFontSize};
    margin: 3px;
    padding: 0;
  }
`;

const Form = styled.form`
  outline: none;
  margin-top: auto;
  margin-bottom: 3px;
  opacity: ${({ disabled }) => (disabled ? '50%' : '100%')};
`;

const Select = styled.select`
  color: ${({ theme: { colors } }) => colors.darkGrey};
  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 500;
`;

const timeOptions = [TIME_UNIT.s, TIME_UNIT.m, TIME_UNIT.h];

function TimerInput({ className, update, timer }) {
  const [input, setInput] = useState(timer.value);
  const [timeUnitSelector, setTimeUnitSelector] = useState(timer.unitKey);

  function onUpdate(inputUpdate, unitUpdate) {
    update({
      timer: {
        value: inputUpdate,
        unitKey: unitUpdate,
      },
      timerInSec: getTimeInSec(inputUpdate, unitUpdate),
    });
  }

  function onKeyDown(event) {
    if (['-', '.', '+'].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }

  return (
    <Wrap className={className}>
      <Input
        min="1"
        step="1"
        // disabled={}
        type={'number'}
        placeholder="unlimited"
        value={input}
        name="Timer"
        onKeyDown={onKeyDown}
        onChange={(e) => {
          const { value } = e.target;
          if (value === '0') {
            return false;
          }
          setInput(value);
          debounceFunction(() => {
            onUpdate(value, timeUnitSelector);
          }, 500);
        }}
        onBlur={() => onUpdate(input, timeUnitSelector)}
        infoText="from the moment you publish users will able to vote until timer hits zero"
        label="Timer"
      />

      <Form disabled={!input}>
        <Select
          onChange={(event) => {
            setTimeUnitSelector(event.target.value);
            onUpdate(input, event.target.value);
          }}
          value={timeUnitSelector}
        >
          {timeOptions.map((el) => {
            return (
              <option key={el.key} value={el.key}>
                {el.label}
              </option>
            );
          })}
        </Select>
      </Form>
    </Wrap>
  );
}

TimerInput.propTypes = {
  className: PropTypes.string,
  update: PropTypes.func,
  timer: PropTypes.object,
};
export default React.memo(TimerInput);
