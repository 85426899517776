import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';

import {
  DeletePollComponent,
  PollActionButton,
  PollWrapCSS
} from "components/Chat/components/PollsContainer/components/JSXElements";
import QuestionInput from "../QuestionInput";
import NewPollSettings from "../NewPollSettings";
import OptionsInput from "../OptionsInput";

const PollWrap = styled.div`
  ${PollWrapCSS};

  background-color: #F2F2F2;
    //background-color: ${({theme: {colors}}) => colors.purpleFeather};
`;

function NewPollElement({
                          poll,
                          deleteNewPollAction,
                          updateNewPollAction,
                          createNewPoll,
                          publishNewPoll,
                          deleteNewPoll,
                        }) {

  const {
    id,
    PollOptions = [],
    text = '',
    timer = {},
    showOnChat = true,
    // status,
  } = poll;

  function updatePoll(id, value) {
    updateNewPollAction({id, ...value})
  }

  function isPollValid() {
    if (!text) {
      return false;
    }

    return PollOptions.find((el) => {
      return !!el.text;
    })

  }

  function onCreateClick() {
    return createNewPoll(poll);
  }

  function onDelete() {
    deleteNewPollAction(id);
  }

  return (
    <PollWrap>
      <DeletePollComponent onDelete={onDelete} />
      <QuestionInput
        value={text}
        onUpdate={(value) => updatePoll(id, value)}
      />
      <OptionsInput
        PollId={id}
        PollOptions={PollOptions}
      />

      <NewPollSettings
        update={(value) => updatePoll(id, value)}
        timer={timer}
        showOnChat={showOnChat}
      />

      <PollActionButton
        disabled={!isPollValid()}
        onClick={onCreateClick}
      >
        create
      </PollActionButton>
    </PollWrap>
  );
}

NewPollElement.propTypes = {
  poll: PropTypes.object,
  deleteNewPollAction: PropTypes.func,
  updateNewPollAction: PropTypes.func,
  createNewPoll: PropTypes.func,
  publishNewPoll: PropTypes.func,
  deleteNewPoll: PropTypes.func,
};

export default (React.memo(NewPollElement));
