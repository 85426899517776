import {
  deleteNewPollAction
} from './actions';
import {errorHandler} from "utilities/services";
import {pick} from "lodash";
import {addPollAction} from "../polls/actions";
import {getAllowedFieldsByKey, POLL_STATUS} from 'utilities/polls.const';
import {getChatInstance} from "utilities/chat";
import {CHAT_ROOMS_LABELS} from "utilities/chat.utils";
import pollsCreatePoll from "websockets/sendActions/polls/polls.create-poll.action";

function sanitizeCreatePoll(poll) {
  const sanitizedPoll = pick(poll, getAllowedFieldsByKey('createPoll'));
  const pollOptions = [];
  for (const el of sanitizedPoll.PollOptions) {
    if (el.text) {
      pollOptions.push(pick(el, getAllowedFieldsByKey('createPollOption')))
    }
  }
  sanitizedPoll.PollOptions = pollOptions;
  return sanitizedPoll;
}

const chat = getChatInstance();

export function createNewPoll(poll) {
  const sanitizedPoll = sanitizeCreatePoll(poll);
  return dispatch => {
    const roomInstance = chat.getChatRoom(CHAT_ROOMS_LABELS.POLLS.key);
    pollsCreatePoll(roomInstance.room, sanitizedPoll).then(res => {
      dispatch(deleteNewPollAction(poll.id));
      dispatch(addPollAction(res));
    }).catch(err => {
      errorHandler(err);
    })

  }

  /*
todo: when server side ready uncomment
 */
  //
  // return dispatch => pollsService.createPoll(sanitizedPoll)
  //   .then((response) => {
  // dispatch(deleteNewPollAction( poll.id));
  //     return response;
  //   })
  //   .catch(err => {
  //     errorHandler(err)
  //   });
}

//todo: remove this is temp
export function publishNewPoll(id, poll) {

  return dispatch => {

    dispatch(deleteNewPollAction(id))

    //this is redundant as we will get it from the websocket
    dispatch(addPollAction({...poll, status: POLL_STATUS.PUBLISHED.key}))
  };

}

//todo: need to think about this one as it will be fast so maybe we use websocket for that
// export function publishNewPoll(id) {

/*
todo: when server side ready uncomment
*/
//
// return dispatch => pollsService.publishPoll(id)
//   .then((response) => {
//     /*
//     response need to be the poll object or just ok
//
//     */
//     const {data: {poll}} = response;
//
//     dispatch(updateNewPollAction(poll));
//     return response;
//   })
//   .catch(err => {
//     errorHandler(err)
//   });
// }

export function deleteNewPoll(id) {
  return dispatch => {
    dispatch(deleteNewPollAction(id));
  }
}
