import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { getSocialMediaLink, isFree } from 'utilities/general';
import { processDurationHumanize } from 'utilities/date';

import {
  isWorkshopPublished,
  getWorkshopUrl,
  getWorkshopEditUrl,
  getWorkshopManageUrl,
  getWorkshopInspectUrl,
  findWorkshopType,
  onWorkshopBuyClick,
} from 'utilities/workshop';

import { WORKSHOP_STATUS, WORKSHOP_TYPE_SELECTOR, WORKSHOP_TYPE } from 'config/constants';
import RoundCheckbox from 'components/Inputs/RoundCheckbox';

import {
  TileWrap,
  TileCover,
  TileCoverLink,
  TileCoverActions,
  TileCoverActionsRight,
  TileActionsBar,
  TileActionsItem,
  TileDescription,
  TileDescriptionHeader,
  TileDescriptionTitle,
  TileDescriptionLineText,
  TileDescriptionEnlargeSummary,
  TileDescriptionEnlargeSummaryButton,
  TileDescriptionSocial,
  TileDescriptionDuration,
  TileFilter,
  TileCoverHeaderLeft,
  TileSeparator,
  TileSeparatorVertical,
  TileButtonCustom,
  TileDescriptionLineTextWrapper,
  TileCoverWrapper,
  TileTagWrap,
  TileChannelWrapCss,
  TileChannelUserAvatarCss,
  TileCustomUserNameStyle,
  TileDescriptionFavorite,
  TileDescriptionShare,
  TileFollowersRatingCss,
  TileDurationLabelCss,
  TileDurationCss,
  TileButtonCss,
  TilePrice,
  TileLive,
  TileDescriptionUsersCount,
} from '../components/JSXElements';
import Duration from 'components/Duration';
import StyledChannel from 'components/StyledChannel';
import StarRating from 'components/Tiles/components/StarRating';
import {
  addWorkshopRegistration,
  createWorkshopRegistration,
  deleteWorkshopRegistration,
} from '../../../reduxUtils/userWorkshopRegistrations/thunks';
import { getWorkshopRegistration } from '../../../reduxUtils/userWorkshopRegistrations/selectors';
import WorkshopRegButton from 'components/WorkshopRegButton';
import WorkshopCarousel from 'components/Tiles/components/WorkshopCarousel';
import isAdmin from 'permissions/actionFunctions/admins/is-admin';
import isOwner from 'permissions/actionFunctions/admins/is-owner';
import EventsCount from 'components/EventsCount';
import { getFutureEvents } from 'utilities/event';
import { getUserCurrency } from '../../../reduxUtils/userSettings/selectors';
import Payment from 'components/Payment';
import { TILE_COVER_WIDTH } from 'styles/common';
import { updateHomeWorkshop } from '../../../reduxUtils/homeWorkshops/actions';
import { updateHomeUpcomingWorkshop } from '../../../reduxUtils/homeUpcomingWorkshops/actions';
import { updateHomeWorkshopsSuggestion1 } from '../../../reduxUtils/homeWorkshopsSuggestion1/actions';
import { updateHomeWorkshopsSuggestion2 } from '../../../reduxUtils/homeWorkshopsSuggestion2/actions';

const WorkshopRegButtonWrap = styled(WorkshopRegButton)`
  ${TileButtonCss};
`;

const DurationWrap = styled(Duration)`
  padding-top: 0.4em;
`;
const SUMMARY_EXCERPT_LENGTH = (TILE_COVER_WIDTH - 20) / 3; // we remove the padding and a third is enough for chars

function WorkshopTile({
  isLoggedIn,
  isMobile,
  isAdminView,
  userId, //the user id
  accessControls,
  userCurrency,
  isPrivate,
  isSelected,
  registration,
  content,
  addWorkshopRegistration,
  createWorkshopRegistration,
  deleteWorkshopRegistration,
  updateHomeWorkshop,
  updateHomeUpcomingWorkshop,
  updateHomeWorkshopsSuggestion1,
  updateHomeWorkshopsSuggestion2,
  setDialog,
  setGoogleDialog,
  handleSelect = () => {},
  handlePrivacy = () => {},
  handleVisibility = () => {},
  handleDelete = () => {},
  ...props
}) {
  const navigate = useNavigate();

  const {
    id,
    paymentType,
    title,
    summary,
    coverPhotoHomePage,
    price,
    currency,
    rating,
    date,
    endDate,
    eventsCount,
    duration,
    user = {},
    UserId, //the creator user id
    status,
    categories,
    eventsInfo = [],
    registrationsCount = 0,
  } = content;

  function resetBuyDialog() {
    setBuyDialog(defaultBuyDialogState);
  }

  const defaultBuyDialogState = {
    show: false,
    header: '',
    subHeader: '',
    refundLabel: '',
    eventId: null,
    occurrenceId: null,
    workshopId: null,
    occurrence: null,
    title: '',
    coverPhotoHomePage: '',
    price: 0,
    currency: null,
    userCurrency: null,
    content: null,
    onCancel: resetBuyDialog,
    onHide: resetBuyDialog,
    onComplete: () => {},
  };

  const [buyDialog, setBuyDialog] = useState(defaultBuyDialogState);

  const workshopEventsRef = useRef();
  workshopEventsRef.current = eventsInfo;
  const [showInfo, setShowInfo] = useState(false);
  const isReady = WORKSHOP_STATUS.STARTED.key !== status && WORKSHOP_STATUS.DELETED.key !== status;
  const adminOrOwner =
    UserId && userId
      ? isAdmin(null, id, userId, accessControls) || isOwner(null, UserId, null, userId)
      : false;

  const editUrl = getWorkshopEditUrl(UserId, id);
  const managerUrl = getWorkshopManageUrl(id);
  const contentUrl = getWorkshopUrl(id);
  const inspectUrl = getWorkshopInspectUrl(id, isAdminView);
  const isFreeContent = isFree(paymentType);
  const redirectTo = isFreeContent
    ? getWorkshopUrl(id, false, true)
    : getWorkshopInspectUrl(id, false, true);
  const shareUrl = getSocialMediaLink({ redirectTo, WorkshopId: id });

  const isOverFollow = summary.length >= SUMMARY_EXCERPT_LENGTH;
  const isPublished = isWorkshopPublished(status);
  const futureLiveAndVodEvents = getFutureEvents({ events: eventsInfo });
  const workshopTypeNumber = findWorkshopType(futureLiveAndVodEvents);
  const containLive = !!(workshopTypeNumber & WORKSHOP_TYPE_SELECTOR.live);
  const _endDate = endDate ? endDate : date;
  const usersCount = registrationsCount;

  function getCoverLink() {
    if (isAdminView) {
      return managerUrl;
    }
    if (!!registration) {
      return contentUrl;
    }
    return inspectUrl;
  }

  const datesArr = useMemo(() => {
    if (!date || !containLive) return null;

    return [
      { label: 'Start', date: date },
      { label: 'End', date: _endDate },
    ];
  }, [date, _endDate, containLive]);

  function onCompletePayment() {
    // navigate(contentUrl);
  }

  function onClickJoin() {
    if (!isReady) {
      return;
    }

    if (!isLoggedIn) {
      navigate(inspectUrl);
      return;
    }

    if (!isFreeContent && !adminOrOwner) {
      onBuyClick(onCompletePayment);
    } else {
      navigate(contentUrl);
    }
  }

  function onBuyClick(onComplete) {
    onWorkshopBuyClick({
      setBuyDialog,
      onComplete,
      id,
      title,
      coverPhotoHomePage,
      price,
      currency,
      userCurrency,
      content,
    });
  }

  function updateWorkshop(payload) {
    updateHomeWorkshop(payload);
    updateHomeUpcomingWorkshop(payload);
    updateHomeWorkshopsSuggestion1(payload);
    updateHomeWorkshopsSuggestion2(payload);
  }

  function updateParentComponentOnRegister() {
    const payload = {
      ...content,
      registrationsCount: registrationsCount + 1,
    };

    updateWorkshop(payload);
  }

  function updateParentComponentOnRemoveRegister() {
    const payload = {
      ...content,
      registrationsCount: registrationsCount - 1,
    };
    updateWorkshop(payload);
  }

  return (
    <>
      {buyDialog.show && (
        <Payment
          isMobile={isMobile}
          {...buyDialog}
          onCancel={resetBuyDialog}
          onHide={buyDialog.onHide}
          onComplete={buyDialog.onComplete}
        />
      )}
      <TileWrap isSelected={isSelected} isReady={isReady} {...props}>
        <TileCoverWrapper>
          <TileCover coverUrl={coverPhotoHomePage} showInfo={showInfo}>
            <TileFilter></TileFilter>
            <TileCoverLink to={getCoverLink()} />
            {isAdminView && (
              <TileCoverActions>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <RoundCheckbox
                    onChange={({ target }) => handleSelect(id, false, target.value)}
                    value={isSelected}
                  />
                </div>
                <TileCoverActionsRight>
                  <TileActionsItem
                    isActive={isPublished}
                    iconName={isPublished ? 'fas fa-eye-slash' : 'fas fa-eye'}
                    onClick={(e) => {
                      e.preventDefault();
                      return handleVisibility(id, false, !isPublished);
                    }}
                  />
                  <TileActionsBar
                    isPrivate={isPrivate}
                    isPublished={isPublished}
                    managerUrl={managerUrl}
                    editUrl={editUrl}
                    onManageClick={(e) => {
                      e.preventDefault();
                      navigate(managerUrl);
                    }}
                    onEditClick={(e) => {
                      e.preventDefault();
                      navigate(editUrl);
                    }}
                    onTogglePrivate={(e) => {
                      e.preventDefault();
                      return handlePrivacy(id, false, !isPrivate);
                    }}
                    onToggleVisibility={(e) => {
                      e.preventDefault();
                      return handleVisibility(id, false, !isPublished);
                    }}
                    onDeleteClick={(e) => {
                      e.preventDefault();
                      return handleDelete(id, false, title);
                    }}
                  />
                </TileCoverActionsRight>
              </TileCoverActions>
            )}

            <TileCoverHeaderLeft>
              {!!containLive && (
                <TileLive
                  reduceFontSize={WORKSHOP_TYPE[workshopTypeNumber].length > 4}
                  label={WORKSHOP_TYPE[workshopTypeNumber]}
                />
              )}
              <TilePrice
                price={price}
                currency={currency}
                userCurrency={userCurrency}
                isFree={isFreeContent}
                compact={true}
              />
            </TileCoverHeaderLeft>

            <TileDescriptionSocial>
              <TileDescriptionFavorite isLoggedIn={isLoggedIn} workshopId={id} />
              <TileDescriptionShare
                url={shareUrl}
                title={title}
                summary={summary}
                hashtags={categories}
                content={content}
                isWorkshop={true}
              />
            </TileDescriptionSocial>

            <TileDescriptionHeader>
              <TileDescriptionTitle dir="auto">{title}</TileDescriptionTitle>

              <TileDescriptionDuration>
                {!!usersCount && (
                  <TileDescriptionUsersCount
                    usersCount={usersCount}
                    numOnLeftOfIcon={true}
                    tooltipDisabled
                    hallowIcon
                  />
                )}
                {!!eventsCount && <EventsCount count={eventsCount} />}
                <DurationWrap
                  duration={processDurationHumanize(duration, 's')}
                  labelCss={TileDurationLabelCss}
                  durationTimeCss={TileDurationCss}
                />
              </TileDescriptionDuration>
            </TileDescriptionHeader>

            <TileSeparator />

            {datesArr ? (
              <WorkshopCarousel
                dates={datesArr}
                isFree={isFreeContent}
                id={id}
                isLoggedIn={isLoggedIn}
                registration={registration}
              />
            ) : (
              !!rating && <StarRating rating={rating} />
            )}

            <TileSeparatorVertical />

            {!isReady || !isLoggedIn || !containLive || adminOrOwner ? (
              <TileButtonCustom label="JOIN" onClick={onClickJoin} isnotready={!isReady} />
            ) : (
              <WorkshopRegButtonWrap
                userId={userId}
                adminOrOwner={adminOrOwner}
                label="JOIN"
                id={id}
                isFree={isFreeContent}
                registration={registration}
                addWorkshopRegistration={addWorkshopRegistration}
                createWorkshopRegistration={createWorkshopRegistration}
                deleteWorkshopRegistration={deleteWorkshopRegistration}
                workshop={content}
                setBuyDialog={setBuyDialog}
                workshopType={WORKSHOP_TYPE[workshopTypeNumber]}
                workshopEventsRef={workshopEventsRef}
                onBuyClick={onBuyClick}
                setDialog={setDialog}
                setGoogleDialog={setGoogleDialog}
                updateParentComponentOnRegister={updateParentComponentOnRegister}
                updateParentComponentOnRemoveRegister={updateParentComponentOnRemoveRegister}
              />
            )}
          </TileCover>
        </TileCoverWrapper>
        <TileDescription>
          <StyledChannel
            to={`/channel/${UserId}`}
            userFullName={user.fullName}
            userAvatarUrl={user.avatarThumbnail}
            followers={user.followersCount}
            userId={UserId}
            channelId={UserId}
            rating={user.rating}
            ratingCount={user.ratingCount}
            channelWrapCss={TileChannelWrapCss}
            userNameCss={TileCustomUserNameStyle}
            channelUserAvatarCss={TileChannelUserAvatarCss}
            followersRatingCss={TileFollowersRatingCss}
            showFollow={false}
            setDialog={setDialog}
          />

          <TileDescriptionLineTextWrapper dir="auto">
            <TileDescriptionLineText
              isOverFollow={isOverFollow}
              showInfo={showInfo}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (isOverFollow) {
                  setShowInfo(!showInfo);
                }
              }}
            >
              {summary}
            </TileDescriptionLineText>
            <TileDescriptionEnlargeSummary>
              {isOverFollow && (
                <TileDescriptionEnlargeSummaryButton
                  iconName={`fas ${showInfo ? 'fas fa-sort-up' : 'fas fa-sort-down'}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowInfo(!showInfo);
                  }}
                />
              )}
            </TileDescriptionEnlargeSummary>
          </TileDescriptionLineTextWrapper>

          <TileTagWrap categories={categories} />
        </TileDescription>
      </TileWrap>
    </>
  );
}

WorkshopTile.propTypes = {
  isLoggedIn: PropTypes.bool,
  id: PropTypes.number,
  userId: PropTypes.number,
  isMobile: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAdminView: PropTypes.bool,
  paymentType: PropTypes.string,
  title: PropTypes.string,
  summary: PropTypes.string,
  coverPhotoHomePage: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  userCurrency: PropTypes.string,
  rating: PropTypes.number,
  date: PropTypes.string,
  endDate: PropTypes.string,
  duration: PropTypes.number,
  user: PropTypes.object,
  accessControls: PropTypes.array,
  WorkshopId: PropTypes.number,
  status: PropTypes.string,
  handleSelect: PropTypes.func,
  handlePrivacy: PropTypes.func,
  handleDelete: PropTypes.func,
  eventsInfo: PropTypes.array,
  registration: PropTypes.object,
  addWorkshopRegistration: PropTypes.func,
  createWorkshopRegistration: PropTypes.func,
  deleteWorkshopRegistration: PropTypes.func,
  updateHomeWorkshop: PropTypes.func,
  updateHomeUpcomingWorkshop: PropTypes.func,
  updateHomeWorkshopsSuggestion1: PropTypes.func,
  updateHomeWorkshopsSuggestion2: PropTypes.func,
  setDialog: PropTypes.func,
  setGoogleDialog: PropTypes.func,
};

function mapStateToProps(state, { id }) {
  return {
    userCurrency: getUserCurrency(state),
    registration: getWorkshopRegistration(state, id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addWorkshopRegistration,
      createWorkshopRegistration,
      deleteWorkshopRegistration,
      updateHomeWorkshop,
      updateHomeUpcomingWorkshop,
      updateHomeWorkshopsSuggestion1,
      updateHomeWorkshopsSuggestion2,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WorkshopTile));
