import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {UNLIMITED_DURATION_DAYS} from "config/constants";

export const DurationWrap = styled.span`
  display: flex;
  flex-direction: row;
`;


export const DurationTime = styled.span`
  padding: 0 0.2em;
  ${({duration_time_css}) =>
    duration_time_css
  };
`;

export const InfWrap = styled.span`
  padding-left: 0.2em;
  ${({duration_time_css}) =>
    duration_time_css
  };
`;

export const DurationLabel = styled.span`
  ${({label_css}) =>
    label_css
  };
`;


function Duration({duration = {}, durationTimeCss, labelCss, ...props}) {
  if (duration.days && duration.days.time >= UNLIMITED_DURATION_DAYS) {
    return (
      <DurationWrap {...props}>
        <InfWrap
          duration_time_css={durationTimeCss}
        >
          &infin;
        </InfWrap>
      </DurationWrap>
    )
  }
  return (
    <DurationWrap {...props}>
      {
        (duration.days && !!duration.days.time) && (
          <>
            <DurationTime
              duration_time_css={durationTimeCss}
            >
              {duration.days.time}
            </DurationTime>
            <DurationLabel
              label_css={labelCss}
            >
              {duration.days.label}
            </DurationLabel>
          </>
        )
      }

      {
        (duration.hours && !!duration.hours.time) && (
          <>
            <DurationTime
              duration_time_css={durationTimeCss}
            >
              {duration.hours.time}
            </DurationTime>
            <DurationLabel
              label_css={labelCss}
            >
              {duration.hours.label}
            </DurationLabel>
          </>
        )
      }

      {
        (duration.minutes && !!duration.minutes.time) && (
          <>
            <DurationTime
              duration_time_css={durationTimeCss}
            >
              {duration.minutes.time}
            </DurationTime>
            <DurationLabel
              label_css={labelCss}
            >
              {duration.minutes.label}
            </DurationLabel>
          </>
        )
      }
    </DurationWrap>
  );
}

Duration.propTypes = {
  duration: PropTypes.object,
  durationTimeCss: PropTypes.array,
  labelCss: PropTypes.array,
};

export default React.memo(Duration);