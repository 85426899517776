import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styles/theme';
import {HideScrollBarCss, scale, UserSelectNoneCss} from 'styles/common';
import {Drag} from 'utilities/utils';

const TabsWrap = styled.div`
  display: flex;
  justify-content: center;
  ${scale([['768px', [['justify-content', `center`]]]])}
  margin: 0 auto;
  text-transform: capitalize;
`;

const HomeSectionTabs = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 10;
  margin: 0 auto;
  outline: none;

  &:focus {
    outline: none;
  }
`;


const HomeSectionTabsMenu = styled.div`
  ${UserSelectNoneCss};
  padding: 1em;

  overflow-x: scroll;

  ${HideScrollBarCss};

  width: 100vw;

  ${scale([['440px', [
    ['width', `100%`],
    [' justify-content', `center`],
  ]]])}
  display: flex;
  position: relative;
  background-color: transparent;
  border: none;
  top: inherit;

`;


const HomeSectionTab = styled.div`

  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}

  color: ${({isActive, theme: {colors}}) => isActive ? colors.purple : colors.grayNew};
  font-weight: bold;
  cursor: pointer;
  ${scale([['768px', [['margin', `0px ${theme.padding.pageD}px`]]]])}
  padding: 0.3em 1.5em 0;
  white-space: nowrap;

  &:hover {
    color: ${({theme: {colors}}) => colors.purple};
  }

  border-bottom: ${({isActive}) => isActive && '2px solid'};
  margin: 0 0.6em;
`;

function Tabs(
  {
    noMinimize = false,
    toggleIcon = 'fas fa-filter',
    tabs = [],
    selectedTab,
    onTabSelect = () => {
    },
    isDraggable = true,
    ...props
  }
) {
  //  let noMinimizeStyle = noMinimize ? '0' : '768px';
  const [/*isOpened*/, setIsOpened] = useState(false);
  const sectionRef = useRef();
  const drag = useRef();

  function onMenuSelected(tabName, onClick) {
    onTabSelect(tabName);
    setIsOpened(false);
    onClick
    && typeof onClick === 'function'
    && onClick(tabName);
  }

  useEffect(() => {
    if (isDraggable) {
      drag.current = new Drag(true, false, 1);
      drag.current.register(sectionRef);
      return () => {
        drag.current.deRegister();
      };
    }
  }, [isDraggable]);

  return (
    <TabsWrap>
      <HomeSectionTabs
        tabIndex={0}
      >
        <HomeSectionTabsMenu {...props} ref={sectionRef}>
          {
            tabs.map(({label, name, onClick}) => (
              <HomeSectionTab
                key={name}
                isActive={selectedTab === name}
                onClick={() => ((drag.current && !drag.current.isDrag) || !drag.current) && onMenuSelected(name, onClick)}
              >
                {label}
              </HomeSectionTab>
            ))
          }
        </HomeSectionTabsMenu>
      </HomeSectionTabs>
    </TabsWrap>
  );
}

Tabs.propTypes = {
  toggleIcon: PropTypes.string,
  tabs: PropTypes.array,
  selectedTab: PropTypes.string,
  onTabSelect: PropTypes.func
};

export default Tabs;