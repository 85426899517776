import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import {ProgressBar} from "react-bootstrap";

const Progress = styled(ProgressBar)`
  width: 100%;
  height: 3px;
  margin-top: 10px;

  .progress-bar {
    background-color: #4E3C99;
  }
`;

function PollTimer({
                     className,
                     timer,
                     max,
                     onDone
                   }) {
  const [elapsed, setElapsed] = useState(timer);
  const intervalIdRef = useRef(null);

  function intervalCallback() {
    let currentTimer;
    setElapsed(current => {
      currentTimer = current - 1;
      return currentTimer;
    });

    if (currentTimer <= 0) {
      setElapsed(0);
      onDone && onDone();
      clearInterval(intervalIdRef.current);
    }

  }

  useEffect(() => {
    intervalIdRef.current = setInterval(intervalCallback, 1000);
    setElapsed(timer);
    return () => {
      clearInterval(intervalIdRef.current)
    }

  }, [timer]);// eslint-disable-line

  return (
    <Progress className={className} min={0} max={max} now={elapsed} />
  );
}

PollTimer.propTypes = {
  className: PropTypes.string,
  timer: PropTypes.number,
  onDone: PropTypes.func,
};

export default (React.memo(PollTimer));