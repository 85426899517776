import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";

const Header = styled.span`
  ${({isMobile}) =>
    isMobile &&
    css`
      text-decoration: underline;
    `
  }

`;

const IconTextWrap = styled.span`
  display: flex;
  align-items: center;
  padding-left: 1px;
  white-space: nowrap;
`;

export const RightArrowIcon = styled.i`
  padding-top: 0.2em;
  font-size: 0.55rem;
  opacity: 0;
  padding-left: 3px;
  --transformVar: 850ms;
  transition: opacity 0.5s, transform var(--transformVar), -webkit-transform var(--transformVar), -moz-transform var(--transformVar), -o-transform var(--transformVar);
`;

export const IconText = styled.span`
  max-width: 0;
  font-size: 0.7rem;
  opacity: 0;
  --transformVar: 750ms;
  transition: max-width 0.8s, opacity 0.7s, transform var(--transformVar), -webkit-transform var(--transformVar), -moz-transform var(--transformVar), -o-transform var(--transformVar);
`;

const AnimatedLinkHeaderWrap = styled(Link)`
  display: flex;
  text-decoration: unset;
  width: max-content;

  &:hover, &:focus {
    text-decoration: unset;
    color: ${({theme: {colors}}) => colors.darkGrey};
  }

  &:hover ${IconText} { //parent target child element 
    max-width: 200px;
    transform: translate(0.8vw, 0);
    opacity: 1;
  }

  &:hover ${RightArrowIcon} { //parent target child element 
    transform: translate(1vw, 0);
    opacity: 1;
  }

`;


function AnimatedLinkHeader({isMobile, header, iconText = 'show all', to, ...props}) {
  return (
    <AnimatedLinkHeaderWrap
      {...props}
      to={to}
    >
      <Header isMobile={isMobile}>
        {header}
      </Header>
      <IconTextWrap>
        <IconText>
          {iconText}
        </IconText>
        <RightArrowIcon className={'fas fa-chevron-right'} />
      </IconTextWrap>
    </AnimatedLinkHeaderWrap>
  );
}

AnimatedLinkHeader.propTypes = {
  header: PropTypes.string,
  iconText: PropTypes.string,
  to: PropTypes.string,
  isMobile: PropTypes.bool,

};

export default AnimatedLinkHeader;