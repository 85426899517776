import React from 'react';
import styled from 'styled-components';
import {TilePlaceHolders} from 'components/Tiles/Placeholder/JSXElements';
import {BORDER_RADIUS, GeneralTileWrapCss, TILE_COVER_HEIGHT} from 'styles/common';


const PlaceholderTileWrap = styled.div`
  ${GeneralTileWrapCss};
`;

const TileCover = styled.div`
  display: flex;
  flex-direction: column;
  height: ${TILE_COVER_HEIGHT}px;
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  overflow: hidden;
`;

const TileDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({theme: {colors}}) => colors.fill};
  padding: 10px;
  border-radius: 0 0 7px 7px;
`;

const TileDescriptionBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

function PlaceholderTile(props) {
  return (
    <PlaceholderTileWrap {...props}>
      <TileCover>
        <TilePlaceHolders
          width={'100%'}
          height={'100%'}
        />
      </TileCover>
      <TileDescription>
        <TileDescriptionBody>
          <TilePlaceHolders
            isLight
            width={'220px'}
            height={'35px'}
            marginTop={'3px'}
            marginLeft={0}
          />
          <TilePlaceHolders
            isLight
            width={'200px'}
            height={'10px'}
            marginLeft={0}
            marginTop={'auto'}
          />
        </TileDescriptionBody>
      </TileDescription>
    </PlaceholderTileWrap>
  );
}

export default PlaceholderTile;