import {handleActions} from 'redux-actions';
import {
  SET_HOME_WORKSHOPS_SUGGESTION2,
  ADD_HOME_WORKSHOP_SUGGESTION2,
  ADD_HOME_WORKSHOPS_SUGGESTION2, UPDATE_HOME_WORKSHOPS_SUGGESTION2
} from './types';

import {
  reduxAddWorkshopHelper,
  reduxAddWorkshopsHelper,
  reduxSetWorkshopHelper, reduxUpdateWorkshopsHelper
} from "../helpers/workshops";


export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_HOME_WORKSHOPS_SUGGESTION2]: (state, {payload}) => {
    return reduxSetWorkshopHelper(state, payload);
  },
  [ADD_HOME_WORKSHOP_SUGGESTION2]: (state, {payload}) => {

    return reduxAddWorkshopHelper(state, payload);
  },
  [ADD_HOME_WORKSHOPS_SUGGESTION2]: (state, {payload}) => {
    return reduxAddWorkshopsHelper(state, payload);
  },
  [UPDATE_HOME_WORKSHOPS_SUGGESTION2]: (state, {payload}) => {
    return reduxUpdateWorkshopsHelper(state, payload);
  }

}, {...initialState});