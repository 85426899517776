import {handleActions} from 'redux-actions';
import {pick, cloneDeep} from 'lodash';
import {
  SET_HOME_UPCOMING_EVENTS,
  ADD_HOME_UPCOMING_EVENT,
  ADD_HOME_UPCOMING_EVENTS,
  //UPDATE_HOME_UPCOMING_EVENT,
  DELETE_HOME_UPCOMING_EVENT, UPDATE_OCCURRENCE_HOME_UPCOMING_EVENT
} from './types';
import {findOccurrenceIndex} from "utilities/event";
import {LOGOUT} from "../auth/types";
import {reduxUpdateEventOccurrenceHelper} from "../helpers/events";

const defaultEventFields = [
  'id',
  'isEvent',// = true,
  'isLive',// = true,
  'allowDropIn',// = true,
  'paymentType',
  'title',// = 'Some Title',
  'summary',//
  'coverPhotoDetails',
  'coverPhoto',// = logo,
  'coverPhotoHomePage',
  'price',// ='$37',
  'currency',
  'rating',//
  'ratingCount',
  'occurrences',//
  'duration', // = '5 Hours',
  'user', // = {id, fullName, avatar, avatarThumbnail, avatarHomePage, rating, ratingCount, followersCount}
  'UserId',// = 17
  'WorkshopId',
  'status',
  'categories'
];

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_HOME_UPCOMING_EVENTS]: (state, {payload}) => {
    return {
      ...state,
      ...payload,
      rows: payload.rows.map((event) => pick(event, defaultEventFields))
    };
  },

  [ADD_HOME_UPCOMING_EVENT]: (state, {payload}) => {
    const {occurrenceId, event} = payload;

    if (!occurrenceId || !event) {
      return state;
    }

    const {
      foundEventIdIndex,
      // foundOccIdIndex,
    } = findOccurrenceIndex(event.id, occurrenceId, state.rows);

    if (foundEventIdIndex === -1) //the entire event need to be added
    {
      let filterOcc = event.occurrences.flat().filter((occ) => occ.id === occurrenceId);
      let newEvent = {...event, occurrences: filterOcc};

      return {
        ...state,
        totalRows: state.totalRows + 1,
        rows: [...state.rows, newEvent]
      };
    }

    if (foundEventIdIndex > -1) { // add an occurrence
      const newOcc = event.occurrences.flat().find((occ) => occ.id === occurrenceId);

      if (newOcc) {
        const events = cloneDeep(state.rows);
        events[foundEventIdIndex].occurrences.push([newOcc]);
        return {
          ...state,
          rows: events
        };
      }
    }

    return state;
  },

  [ADD_HOME_UPCOMING_EVENTS]: (state, {payload}) => {
    const events = [...state.rows];
    const newEvents = (payload.rows || [])
      .filter((newEvent) => {
        return events.findIndex((event) => event.id === newEvent.id) === -1;
      })
      .map((newEvent) => pick(newEvent, defaultEventFields));
    if (!newEvents.length) {
      return state;
    }

    return {
      ...state,
      ...payload,
      rows: [...events, ...newEvents]
    };
  },
  /*
  [HOME_UPCOMING_UPDATE_EVENT]: (state, { payload }) => {
    const updatedEvent = pick(payload, defaultEventFields);
    const events = [...state];

    const foundIndex = events.findIndex((event) => event.id === updatedEvent.id);
    if( foundIndex > -1 ) {
      events[foundIndex] = {...events[foundIndex], ...updatedEvent};
      return events;
    }

    return state;
  }, */

  [DELETE_HOME_UPCOMING_EVENT]: (state, {payload}) => {
    const {eventId, occurrenceId} = payload;
    if (!eventId || !occurrenceId) {
      return state;
    }

    const {
      foundEventIdIndex,
      foundOccIndex,
      foundOccIdIndex
    } = findOccurrenceIndex(eventId, occurrenceId, state.rows);

    if ((foundEventIdIndex > -1) && (foundOccIdIndex > -1)) {
      let totalRows = state.totalRows;
      const events = cloneDeep(state.rows);
      if (events[foundEventIdIndex].occurrences.flat().length > 1) {
        if (foundOccIndex > -1) {
          if (events[foundEventIdIndex].occurrences[foundOccIndex].length === 1) {
            events[foundEventIdIndex].occurrences.splice(foundOccIndex, 1);
          } else {
            events[foundEventIdIndex].occurrences[foundOccIndex].splice(foundOccIdIndex, 1);
          }
        } else {
          events[foundEventIdIndex].occurrences.splice(foundOccIdIndex, 1);
        }
      } else {
        events.splice(foundEventIdIndex, 1);
        --totalRows;
      }
      return {
        ...state,
        totalRows: totalRows,
        rows: events
      };
    }

    return state;
  },
  [UPDATE_OCCURRENCE_HOME_UPCOMING_EVENT]: (state, {payload}) => {
    return reduxUpdateEventOccurrenceHelper(state, payload);
  },
  [LOGOUT]: () => {
    return initialState;
  }

}, {...initialState});