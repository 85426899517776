import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {scale} from 'styles/common';
import Duration from 'components/Duration';
import {processDuration, processDurationHumanize} from 'utilities/date';
import theme from 'styles/theme';

const DurationCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.medium]]],
    ['1200px', [['font-size', theme.typo.sizes.medium]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])};
  font-weight: bold;
  color: ${({theme: {colors}}) => colors.purple};

`;

const DurationLabelWrap = styled.div`
  height: 1.5em;
  border-radius: 0 5px 5px 0;
  padding: 0 0.5em;
  ${DurationCss};
`;


const DescriptionDurationTime = styled.span`
  padding: 0 0.2em;
  ${DurationCss};
  letter-spacing: 0.04em;

`;


function DurationLabel({duration, humanize = true,showFull = false, ...props}) {
  return (
    <DurationLabelWrap  {...props} >
        Duration:
        {
          humanize ?
            <Duration
              durationTimeCss={DurationCss}
              labelCss={DurationCss}
              duration={processDurationHumanize( duration, 's', showFull)}
            />
            :
            <DescriptionDurationTime>
              {
                processDuration(duration, 's')
              }
            </DescriptionDurationTime>
        }
    </DurationLabelWrap>
  );
}

DurationLabel.propTypes = {
  isLive: PropTypes.bool,
  duration: PropTypes.number,
  showFull: PropTypes.bool,
};


export default React.memo(DurationLabel);