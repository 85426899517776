import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { HideScrollBarCss, LightCustomToolbar } from 'styles/common';
import {
  buildCustomQuery,
  buildQuery,
  destructuringKey,
  getMaxTilesHorizontal,
  getHorizontalSkeleton,
  isMobileDevice,
} from 'utilities/general';

import PlaceholderTile from 'components/Tiles/Placeholder/PlaceholderTile';
import WorkshopTile from 'components/Tiles/WorkshopTile';
import EventTile from 'components/Tiles/EventTile';
import { PAGE_SIZE } from 'config/constants';
import PlaceholderChannelTile from 'components/Tiles/Placeholder/PlaceholderChannelTile';

const SectionBodyWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;

  ${({ hasScroll }) =>
    hasScroll
      ? css`
          padding-right: 30px;
        `
      : css``}

  ${({ isMobileDevice }) =>
    isMobileDevice
      ? css`
          overflow-x: scroll;
          ${HideScrollBarCss};
        `
      : css`
          overflow-x: auto;

          &:first-child {
            padding-left: 10px;
          }
        `}
  ${LightCustomToolbar};
`;

const NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-size: ${({ theme: { typo } }) => typo.sizes.h2};
  margin-bottom: 1em;
`;

const offsetToFetch = 100;

function SectionBody({
  navigate,
  isMobile,
  isLoggedIn,
  userSettings,
  userId,
  accessControls,
  page,
  pageSize = PAGE_SIZE,
  totalPages,
  totalRows,
  rows,
  fetch,
  reqParams = {},
  deletion,
  search,
  categoryType,
  customPagination = false,
  dataSet,
  Tile,
  PlaceHolder = PlaceholderTile,
  isSelected,
  setDialog,
  setGoogleDialog,
  isVisibleOnEmptyContent,
  ...props
}) {
  const [fetching, setFetching] = useState(true);
  const sectionRef = useRef();
  const isFetchingRef = useRef(false);
  const tileWidth = PlaceHolder === PlaceholderChannelTile ? (isMobile ? 100 : 180) : 300; //in the future will more dynamic

  function getQuery(deletion, dataSet, page, totalPages, totalRows, pageSize, rows) {
    let query;
    if (customPagination) {
      query = buildCustomQuery(deletion, dataSet, { page, totalPages, totalRows, pageSize, rows });
    } else {
      query = buildQuery({
        page,
        pageSize,
        filters: {
          category: search && search.category.value,
          inFutureOnly: true,
        },
      });
    }
    if (query) {
      query.reqParams = {
        search: search && search.category.value,
        dataSet,
        fetched: true,
      };
    }
    return query;
  }

  useEffect(() => {
    if (
      !isFetchingRef.current &&
      rows &&
      rows.length &&
      page < totalPages - 1 &&
      rows.length !== totalRows
    ) {
      if (rows.length < getMaxTilesHorizontal({ pageSize, tileWidth, isMobile })) {
        const query = getQuery(deletion, dataSet, page + 1, totalPages, totalRows, pageSize, rows);
        if (query) {
          isFetchingRef.current = true;
          setFetching(true);

          fetch(query, true, categoryType).finally(() => {
            isFetchingRef.current = false;
            setFetching(false);
          });
        }
      }
    }
  }, [rows]); // eslint-disable-line

  useEffect(() => {
    if (isSelected) {
      const searchVal = search && search.category.value;
      const shouldFetch =
        searchVal !== reqParams.search || !reqParams.fetched || reqParams.dataSet !== dataSet;

      if (shouldFetch) {
        const maxPageSize = getMaxTilesHorizontal(pageSize, tileWidth);
        const query = getQuery(deletion, dataSet, 0, totalPages, totalRows, maxPageSize, rows);
        if (query) {
          isFetchingRef.current = true;
          setFetching(true);

          fetch(query, false, categoryType).finally(() => {
            isFetchingRef.current = false;
            setFetching(false);
          });
        } else {
          setFetching(false);
        }
      } else {
        setFetching(false);
      }
    }
  }, [search && search.category.value, dataSet, isSelected]); // eslint-disable-line

  useEffect(() => {
    const el = sectionRef.current;
    if (el) {
      el.addEventListener('scroll', onScroll);
      return () => {
        el.removeEventListener('scroll', onScroll);
      };
    }
  }, [sectionRef.current, onScroll]); // eslint-disable-line

  function onScroll() {
    const el = sectionRef.current;
    if (
      el.scrollLeft + el.offsetWidth >= el.scrollWidth - offsetToFetch &&
      !isFetchingRef.current &&
      page < totalPages - 1
    ) {
      isFetchingRef.current = true;
      let query = getQuery(deletion, dataSet, page + 1, totalPages, totalRows, pageSize, rows);

      if (!query) {
        isFetchingRef.current = false;
      } else {
        setFetching(true);
        fetch(query, true, categoryType).finally(() => {
          isFetchingRef.current = false;
          setFetching(false);
        });
      }
    }
  }

  if (!isSelected) {
    return null;
  }

  return (
    <SectionBodyWrap
      ref={sectionRef}
      isMobileDevice={isMobileDevice()}
      isMobile={isMobile}
      hasScroll={page < totalPages - 2}
      {...props}
    >
      {(rows || []).map((item) => {
        let TileWrap = Tile;
        if (!Tile) {
          TileWrap = item.isEvent ? EventTile : WorkshopTile;
        }
        return (
          <TileWrap
            userId={userId}
            accessControls={accessControls}
            navigate={navigate}
            key={destructuringKey(item)}
            isMobile={isMobile}
            isLoggedIn={isLoggedIn}
            {...item}
            content={item}
            userCurrency={userSettings.currency}
            setDialog={setDialog}
            setGoogleDialog={setGoogleDialog}
          />
        );
      })}

      {!fetching && (!rows || !rows.length) && (
        <>
          <NoResultsContainer>No Results</NoResultsContainer>
        </>
      )}
      {fetching && (
        <>
          {Array(getHorizontalSkeleton(rows, pageSize, tileWidth))
            .fill(0)
            .map((_, idx) => (
              <PlaceHolder key={idx} />
            ))}
        </>
      )}
    </SectionBodyWrap>
  );
}

SectionBody.propTypes = {
  navigate: PropTypes.object,
  userId: PropTypes.number,
  accessControls: PropTypes.array,
  isMobile: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  userSettings: PropTypes.object,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  totalRows: PropTypes.number,
  rows: PropTypes.array,
  reqParams: PropTypes.object,
  search: PropTypes.object,
  fetch: PropTypes.func,
  deletion: PropTypes.func,
  Tile: PropTypes.object,
  PlaceHolder: PropTypes.func,
  setDialog: PropTypes.func,
  setGoogleDialog: PropTypes.func,
  categoryType: PropTypes.string,
  isVisibleOnEmptyContent: PropTypes.bool,
};
export default React.memo(SectionBody);
