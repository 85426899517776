import {errorHandler} from 'utilities/services';
import {getUserTimeZone} from 'utilities/date';

export const GAPI_ERRORS = {
  rateLimitExceeded: 403,
  duplicate: 409,
  deleted: 410,
};

export const GOOGLE_API = {
  youtube: {
    scopes: 'https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtubepartner',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest']
  },
  calendar: {
    scopes: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',//https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.email
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest']
  }
}

export function getGoogleSignedStatus() {
  return window.isLoggedInGoogle;
}

export function insertYouTubeOccurrence() {
}

export function addGoogleCalendarEvent(calendarEventId, startTime, endTime, location, title, summary, tries = 0) {
  if (tries === 2) {
    return;
  }
  const userTimeZone = getUserTimeZone();

  let event = {
    id: calendarEventId,
    summary: title,
    location: location,
    description: summary,
    start: {
      dateTime: startTime.toISOString(),
      timeZone: userTimeZone

    },
    end: {
      dateTime: endTime.toISOString(),
      timeZone: userTimeZone
    },
    recurrence: [
      // 'RRULE:FREQ=DAILY;COUNT=2'
    ],
    attendees: [],
    reminders: {
      useDefault: false,
      overrides: [
        {method: 'email', 'minutes': 15},
        {method: 'popup', 'minutes': 10}
      ]
    }
  };
  let request;
  if (!tries) {
    request = window.gapi.client.calendar.events.insert({
      calendarId: 'primary',
      resource: event
    });
  } else {
    request = window.gapi.client.calendar.events.update({
      calendarId: 'primary',
      eventId: calendarEventId,
      resource: event
    });
  }

  request.execute(function (event) {
    if (event.code === GAPI_ERRORS.duplicate) {
      return addGoogleCalendarEvent(calendarEventId, startTime, endTime, location, title, summary, tries + 1)
    }
  });

}

function onBatchError(err) {
  errorHandler('failed to add batch events to calendar')
  console.log(`onBatchError`, err)
}

export function deleteBatchCalendarEvents(calendarEvents) {
  if (!calendarEvents || !calendarEvents.length) {
    return;
  }
  const batch = window.gapi.client.newBatch();

  calendarEvents.forEach((element, index) => {
    batch.add(window.gapi.client.calendar.events.delete({
      calendarId: 'primary',
      eventId: element.id
    }), {id: index})
  });
  batch.then(function (res) {
    console.log(`delete calendar events`, res)
  }, (res) => console.log(`error on deleting calendar events batch`, res))
}


export function addGoogleCalendarBatchEventsGapi(calendarEvents) {
  if (!calendarEvents || !calendarEvents.length) {
    return;
  }

  const batch = window.gapi.client.newBatch();

  calendarEvents.forEach((element, index) => {
    batch.add(window.gapi.client.calendar.events.insert({
      calendarId: 'primary',
      resource: element
    }), {id: index})
  });

  batch.then(function (res) {
    const duplicateBatch = window.gapi.client.newBatch();
    let hasDuplicate = false;
    console.log(`batch calendar`, res)
    Object.keys(res.result).forEach(
      (key) => {
        if (res.result[key] && res.result[key].status === GAPI_ERRORS.duplicate) {
          hasDuplicate = true;
          duplicateBatch.add(window.gapi.client.calendar.events.update({
            calendarId: 'primary',
            eventId: calendarEvents[key].id,
            resource: calendarEvents[key]
          }))
        }

      }
    );

    if (hasDuplicate) {
      console.log(`duplicate calendar events`, duplicateBatch);
      duplicateBatch.then((res) => {
        console.log(`duplicate calendar res`, res);
      }, onBatchError);
    }
  }, onBatchError);
}

export function hasAccessToGoogleScope(requestScope) {
  let googleUser = window.gapi.auth2.getAuthInstance().currentUser.get();
  return googleUser.hasGrantedScopes(requestScope);

}

export function grandScopes(scopesToGrand) {
  let googleUser = window.gapi.auth2.getAuthInstance().currentUser.get();
  return googleUser.grant({'scope': scopesToGrand});
}

export function logoutFromGoogle() {
  window.handleSignoutClick(null, errorHandler);
}