import {createAction} from 'redux-actions';
import {
  SET_HOME_UPCOMING_EVENTS,
  ADD_HOME_UPCOMING_EVENT,
  ADD_HOME_UPCOMING_EVENTS,
  UPDATE_HOME_UPCOMING_EVENT,
  DELETE_HOME_UPCOMING_EVENT, UPDATE_OCCURRENCE_HOME_UPCOMING_EVENT
} from './types';

export const setHomeUpcomingEvents = createAction(SET_HOME_UPCOMING_EVENTS);
export const addHomeUpcomingEvent = createAction(ADD_HOME_UPCOMING_EVENT);
export const addHomeUpcomingEvents = createAction(ADD_HOME_UPCOMING_EVENTS);
export const updateHomeUpcomingEvent = createAction(UPDATE_HOME_UPCOMING_EVENT);
export const deleteHomeUpcomingEvent = createAction(DELETE_HOME_UPCOMING_EVENT);
export const updateOccurrenceHomeUpcomingEvent = createAction(UPDATE_OCCURRENCE_HOME_UPCOMING_EVENT);