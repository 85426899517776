import eventPermissionActions from 'permissions/actionFunctions/events/index';

import { MAX_STREAM_DURATION, MAX_STREAM_DURATION_TEXT } from 'config/constants';
import { hasFile, isPaid, parseInteger } from './general';
import {
  getContentErrors,
  hasCategoriesError,
  hasParticipantsLimitError,
  hasPriceError,
} from 'utilities/inputValidation';

const { canCreateLiveDuration, canCreateLiveParticipantsLimit, canCreateRecordDuration } =
  eventPermissionActions;

export function hasDropInError(dropIn, paymentType) {
  if (isPaid(paymentType) && !dropIn) {
    return `On paid content DropIn must be on`;
  }
  return false;
}

export function hasFileError(isLive, fileExists) {
  if (!isLive && !fileExists) {
    return 'For a VOD event a video is required.';
  }
  return false;
}

export function hasDurationError(duration, isLive) {
  if (!isLive) {
    return false;
  }

  const numericDuration = parseInteger(duration);

  if (numericDuration > MAX_STREAM_DURATION) {
    return `Max Duration is ${MAX_STREAM_DURATION_TEXT}`;
  } else if (numericDuration <= 0) {
    return 'You must specify duration in minutes for live free events';
  }

  return false;
}

export function hasDurationPermissionError(eventId, userId, accessControls, duration, isLive) {
  if (isLive && !canCreateLiveDuration(eventId, userId, accessControls, duration)) {
    return 'Duration is too high. Upgrade your membership.';
  }
  return false;
}

export function hasParticipantsLimitPermissionErrorEvent(
  eventId,
  userId,
  accessControls,
  participantsLimit,
  isLive,
  isWorkshopEvent
) {
  if (isWorkshopEvent) {
    return false;
  }
  if (
    isLive &&
    !canCreateLiveParticipantsLimit(eventId, userId, accessControls, participantsLimit)
  ) {
    return 'Participants Limit is too high. Upgrade your membership.';
  }
  return false;
}

export function hasRecordDurationPermissionError(
  eventId,
  userId,
  accessControls,
  recordDuration,
  isLive,
  recordEvent
) {
  return (
    isLive &&
    recordEvent &&
    !canCreateRecordDuration(eventId, userId, accessControls, recordDuration)
  );
}

export function hasEmbeddedLinkError(embeddedLink) {
  if (!embeddedLink) {
    return 'Stream link is required';
  }

  return '';
}

export function getEventDetailsErrors(event, userId, files, accessControls, userSettings) {
  const {
    id,
    categories,
    isLive,
    allowDropIn,
    recordEvent,
    recordDuration,
    price,
    streamDuration,
    participantsLimit,
    paymentType,
    WorkshopId,
  } = event;

  const isWorkshopEvent = !!WorkshopId;
  const errors = getContentErrors(event);
  const dropInError = hasDropInError(allowDropIn, paymentType);
  if (dropInError) {
    errors.allowDropIn = dropInError;
  }

  const categoriesError = !isWorkshopEvent && hasCategoriesError(categories);
  if (categoriesError) {
    errors.categories = categoriesError;
  }

  const priceError = !isWorkshopEvent && hasPriceError(price, paymentType, userSettings.currency);
  if (priceError) {
    errors.price = priceError;
  }

  const participantsLimitError =
    isLive && !isWorkshopEvent && hasParticipantsLimitError(participantsLimit);
  if (participantsLimitError) {
    errors.participantsLimit = participantsLimitError;
  }

  const fileExists = hasFile(event, files);
  const fileError = hasFileError(isLive, fileExists);
  if (fileError) {
    errors.file = fileError;
  }

  const durationError = hasDurationError(streamDuration, isLive);
  if (durationError) {
    errors.streamDuration = durationError;
  }

  // const numericDuration = parseInteger(streamDuration);
  // const durationPermissionError = hasDurationPermissionError(id, userId, accessControls, numericDuration, isLive);
  // if (durationPermissionError) {
  //   errors.membership = true;
  //   errors.streamDuration = durationPermissionError;
  // }

  // const numericParticipantsLimit = parseInteger(participantsLimit);
  // const participantsLimitPermissionError = hasParticipantsLimitPermissionErrorEvent(id, userId, accessControls, numericParticipantsLimit, isLive, isWorkshopEvent);
  // if (participantsLimitPermissionError) {
  //   errors.membership = true;
  //   errors.participantsLimit = participantsLimitPermissionError;
  // }
  const numericRecordDuration = parseInteger(recordDuration);
  const RecordDurationPermissionError = hasRecordDurationPermissionError(
    id,
    userId,
    accessControls,
    numericRecordDuration,
    isLive,
    recordEvent
  );
  if (RecordDurationPermissionError) {
    errors.recordDuration = RecordDurationPermissionError;
  }

  return errors;
}
