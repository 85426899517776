import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  UPDATE_EVENT_SETTINGS,
  DELETE_EVENT_SETTINGS
} from './types';

import {
  LOGOUT
} from '../auth/types';

export const initialState = {
  id: 0,
  allowChat: true,
  allowPolls: false,
};

export default handleActions({
  [UPDATE_EVENT_SETTINGS]: (state, {payload}) => {
    return {...state, ...pick(payload, Object.keys(initialState))};
  },
  [DELETE_EVENT_SETTINGS]: () => {
    return initialState;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
