import {
  addHomeFavorites, deleteHomeFavorite
} from './actions';

import FavoriteService from 'services/favorite';
import {errorHandler} from 'utilities/services';

const favoriteService = new FavoriteService();

export function fetchHomeFavorites(query) {
  const {queryReq, properties} = query;
  return dispatch => favoriteService
    .getFavoriteTile(queryReq)
    .then((response) => {
      const {data: {favoritesTile}} = response;
      properties.reqParams = query.reqParams;
      dispatch(addHomeFavorites({favoritesTile, properties}));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });

}

export function deleteHomeFavorites(payload) {
  return dispatch => dispatch(deleteHomeFavorite(payload));
}