import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import theme from 'styles/theme';
import { HoverEffect, HoverStyle, scale } from 'styles/common';

import Icon from 'components/Icon';

const BarItemWrap = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  font-size: ${({ theme: { typo } }) => typo.sizes.body2};

  padding: 5px ${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['padding', `5px ${theme.padding.pageD}px`]]]])}

  color: ${({ theme: { colors } }) => colors.grayNew};

  &.is-active {
    ${HoverStyle};
    color: ${({ theme: { colors } }) => colors.darkGrey};
  }
  &:hover {
    color: ${({ theme: { colors } }) => colors.darkGrey};
  }

  ${HoverEffect};
  &.is-disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const BarItemIcon = styled(Icon)`
  width: auto;
  height: auto;
  margin: 5px 0;
  font-size: ${({ theme: { typo } }) => typo.sizes.body2};
  color: ${({ theme: { colors } }) => colors.grayNew};

  ${BarItemWrap}.is-active & {
    color: ${({ theme: { colors } }) => colors.tertiary};
  }
`;

const BarItemLabel = styled.div``;

function BarItem({ className = '', match, linkTo, isOpened, label, iconName, isDisabled }) {
  const CN = classnames(className, { 'is-disabled': isDisabled });
  return (
    <BarItemWrap
      className={CN}
      // activeClassName="is-active"
      exact
      to={linkTo}
      onClick={(e) => {
        isDisabled && e.preventDefault();
      }}
    >
      <BarItemIcon isOpened={isOpened} iconName={iconName} />
      <BarItemLabel>{label}</BarItemLabel>
    </BarItemWrap>
  );
}

BarItem.propTypes = {
  match: PropTypes.object,
  linkTo: PropTypes.string.isRequired,
  isOpened: PropTypes.bool,
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string,
};

export default BarItem;
