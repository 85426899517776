import {createSelector} from 'reselect';
import {getEvent} from '../event/selectors';

export const getWorkshop = state => state.workshop;
export const getWorkshopId = createSelector(getWorkshop, workshop => workshop.id);
export const getWorkshopTitle = createSelector(getWorkshop, workshop => workshop.title);
export const getWorkshopCategories = createSelector(getEvent, event => event.categories);
export const getWorkshopDescription = createSelector(getWorkshop, workshop => workshop.description);
export const getWorkshopSummary = createSelector(getEvent, event => event.summary);
export const getWorkshopCoverPhoto = createSelector(getWorkshop, workshop => workshop.coverPhoto);
export const getWorkshopCoverPhotoThumbnail = createSelector(getWorkshop, workshop => workshop.coverPhotoThumbnail);
export const getWorkshopPaymentType = createSelector(getWorkshop, workshop => workshop.paymentType);
export const getWorkshopPrice = createSelector(getWorkshop, workshop => workshop.price);
export const getWorkshopRating = createSelector(getWorkshop, workshop => workshop.rating);
export const getWorkshopStatus = createSelector(getWorkshop, workshop => workshop.status);