import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import UserService from 'services/user';
import theme from 'styles/theme';
import { HoverEffect, scale, ScaleFontMenuCss, TinyFontSizeCss } from 'styles/common';
import currencies from 'config/currencies';
import { errorHandler } from 'utilities/services';

import { getUserSettings } from '../../../../../reduxUtils/userSettings/selectors';
import { updateUserSettingsAction } from '../../../../../reduxUtils/userSettings/actions';

import SelectInput from 'components/Inputs/SelectInput';
import LinkedMenuItem from 'components/MenuItems/LinkedMenuItem';
import BaseMenuItem from 'components/MenuItems/BaseMenuItem';
import ContentMenuItem from 'components/MenuItems/ContentMenuItem';
import StyledChannel from 'components/StyledChannel';
import {
  getUserFollowersCount,
  getUserRating,
  getUserRatingCount,
} from '../../../../../reduxUtils/userProfile/selectors';
import { getChannelBaseUrl } from 'utilities/general';

const UserMenuWrap = styled.div`
  display: flex;
  //align-items: left;
  flex-direction: column;
  flex-grow: 1;
  ${ScaleFontMenuCss};
`;

const UserMenuPersonalInfoWrap = styled(Link)`
  display: flex;
  justify-content: center;
  padding: 10px 0.3rem;
  line-height: normal;
  ${scale([
    [
      'default',
      [
        ['justify-content', 'flex-start'],
        ['padding', '10px 10px'],
      ],
    ],
    [
      '560px',
      [
        ['justify-content', 'flex-start'],
        ['padding', '10px 10px'],
      ],
    ],
    [
      '768px',
      [
        ['justify-content', 'center'],
        ['padding', '10px .3em'],
      ],
    ],
  ])}
  ${HoverEffect};
`;

const UserMenuDivider = styled.div`
  height: 0;
  margin: ${({ theme: { typo } }) => typo.sizes.tiniest} 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
`;

const MenuSelectInput = styled(SelectInput)`
  height: auto;
  padding: 5px 10px;
  ${ScaleFontMenuCss};
  color: ${({ theme: { colors } }) => colors.grayNew};
  cursor: pointer;
`;

const StyledChannelWrapper = styled(StyledChannel)`
  &:hover,
  &:focus {
    color: ${({ theme: { colors } }) => colors.grayNew};
    text-decoration: none;
  }
`;

export const FollowersRatingCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body]]],
    ['560px', [['font-size', theme.typo.sizes.medium]]],
    ['768px', [['font-size', theme.typo.sizes.small]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]],
  ])}

  color: ${({ theme: { colors } }) => colors.grayNew};

  &:hover,
  &:focus {
    color: ${({ theme: { colors } }) => colors.grayNew};
    text-decoration: none;
  }
`;

const userService = new UserService();

function UserMenu({
  forAdmin,
  userId,
  fullName,
  avatar,
  userRating,
  userRatingCount,
  userFollowersCount,
  onSelect,
  isLoggedIn,
  userSettings,
  updateUserSettingsAction,
}) {
  function onChange({ target: { name, value } }) {
    updateUserSettingsAction({
      ...userSettings,
      [name]: value,
    });

    if (isLoggedIn) {
      userService
        .updateUserSettings({ [name]: value })
        .then(() => {
          // successHandler('Currency changed successfully');
        })
        .catch(errorHandler);
    }
  }

  const { currency } = userSettings;

  const currencyOptions = Object.keys(currencies).map((key) => {
    const option = currencies[key];
    return {
      text: `${option.symbol} ${option.key}`,
      value: option.key,
    };
  });

  return (
    <UserMenuWrap>
      {!forAdmin && (
        <>
          <UserMenuPersonalInfoWrap to={isLoggedIn ? '/user-settings/profile' : '#'}>
            <StyledChannelWrapper
              userFullName={fullName}
              userAvatarUrl={avatar}
              followers={userFollowersCount}
              userId={null}
              channelId={null}
              rating={userRating}
              ratingCount={userRatingCount}
              followersRatingCss={FollowersRatingCss}
              showFollow={false}
              userNameCss={TinyFontSizeCss}
            />
          </UserMenuPersonalInfoWrap>

          <UserMenuDivider />
          <ContentMenuItem as={'span'}>
            <MenuSelectInput
              onChange={onChange}
              value={currency}
              options={currencyOptions}
              name="currency"
            />
          </ContentMenuItem>
          <UserMenuDivider />
          {isLoggedIn && !!userId && (
            <>
              <LinkedMenuItem
                to={getChannelBaseUrl(userId, true)}
                iconName="fas fa-film"
                onClick={() => onSelect('channel')}
                label="My Channel"
              />

              <LinkedMenuItem
                to="/purchases"
                iconName="fas fa-dollar-sign"
                onClick={() => onSelect('purchases')}
                label="Purchases"
              />
              <UserMenuDivider />

              <LinkedMenuItem
                to="/user-settings"
                iconName="fas fa-cog"
                onClick={() => onSelect('settings')}
                label="User Settings"
              />
              <UserMenuDivider />
            </>
          )}

          <LinkedMenuItem
            to="/legal"
            iconName="fas fa-balance-scale-right"
            onClick={() => onSelect('legal')}
            label="Legal"
          />
          <LinkedMenuItem
            to="/help"
            iconName="far fa-question-circle"
            onClick={() => onSelect('help')}
            label="Help"
          />
        </>
      )}
      {isLoggedIn && (
        <BaseMenuItem
          iconName="fas fa-sign-out-alt"
          onClick={() => onSelect('logout')}
          label="Logout"
        />
      )}
    </UserMenuWrap>
  );
}

UserMenu.propTypes = {
  forAdmin: PropTypes.bool,
  userId: PropTypes.number,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  onSelect: PropTypes.func,
  updateUserSettingsAction: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    userSettings: getUserSettings(state),
    userRating: getUserRating(state),
    userRatingCount: getUserRatingCount(state),
    userFollowersCount: getUserFollowersCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserSettingsAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserMenu));
