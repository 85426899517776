import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import theme from 'styles/theme'
import {
  BORDER_RADIUS,
  GeneralTileWrapCss, PriceLiveLabelDimCss,
  scale,
  TILE_COVER_HEIGHT,
  TILE_SEPARATOR_HEIGHT,
  UserSelectNoneCss
} from 'styles/common';
import Icon from 'components/Icon';
import PriceLabel from 'components/PriceLabel';
import ActionsBar from 'components/ActionsBar';
import {CHANNEL_AVATAR_DIM} from 'config/constants';
import CustomLabel from 'components/CustomLabel';
import Tags from 'components/Tiles/components/Tags';
import Favorite from 'components/Favorite';
import Share from 'components/Share';
import LiveLabel from "components/LiveLabel";
import UsersCount from "components/UsersCount";
import {ProgressBar} from "react-bootstrap";


export const TileCarouselNavCss = css`
  ${scale([
    ['default', [
      ['width', theme.typo.sizes.h3],
      ['height', theme.typo.sizes.h3],
      ['font-size', theme.typo.sizes.body]
    ]],
    ['560px', [
      ['width', theme.typo.sizes.h4],
      ['height', theme.typo.sizes.h4],
      ['font-size', theme.typo.sizes.small]
    ]],
    ['768px', [
      ['width', theme.typo.sizes.h5],
      ['height', theme.typo.sizes.h5],
      ['font-size', theme.typo.sizes.base]
    ]],
    ['992px', [
      ['width', theme.typo.sizes.body2],
      ['height', theme.typo.sizes.body2],
      ['font-size', theme.typo.sizes.base]
    ]],
    ['1400px', [
      ['width', theme.typo.sizes.body],
      ['height', theme.typo.sizes.body],
      ['font-size', theme.typo.sizes.tiny]
    ]]
  ])}


  ${({isMobileDeviceVar}) => !isMobileDeviceVar ?
    css`
      visibility: ${({
                       isDisabled,
                       isMobileDevice
                     }) => (isDisabled === undefined ? !isMobileDevice : isDisabled) ? 'hidden' : 'visible'};


      &:hover ${TileCarouselNext} {
        visibility: visible;
      }
    ` :
    css`
      visibility: ${({isDisabled}) => (isDisabled) ? 'hidden' : 'visible'};
    `
  };
  cursor: pointer;
  color: ${({theme: {colors}}) => colors.white};
  background: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  opacity: 0.75;
  transition: visibility 0.25s;

  &:hover {
    opacity: 1;
  }
`;


export const TileCarouselPrev = styled(Icon)`
  ${TileCarouselNavCss};

  margin-right: auto;
  margin-left: 0.4em;
`;

export const TileCarouselNext = styled(Icon)`
  ${TileCarouselNavCss};
  margin-left: auto;
  margin-right: 0.4em;

`;

export const TileWrap = styled.div`
  &:first-child {
    margin-left: 10px;
  }

  position: relative;
  ${GeneralTileWrapCss};
  background-color: ${({isEvent, theme: {colors}}) => isEvent ? colors.fill : colors.paleTertiary};
  ${UserSelectNoneCss};

  &:hover {
    box-shadow: 0 2px 12px rgb(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 12px rgb(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 2px 12px rgb(0, 0, 0, 0.4);
  }

  &::after {

    ${({isReady}) =>
      isReady
        ? css`

        `
        : css`
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.5;
          background-color: white;
          border-radius: 20px 20px 7px 7px;
        `
    };
  }

  ${({isMobileDeviceVar}) => !isMobileDeviceVar &&
    css`
      &:not(:hover) ${TileCarouselPrev} {
        visibility: hidden;
      }

      &:hover ${TileCarouselNext} {
        visibility: visible;
      }
    `};
`;

export const TileCoverWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0 7.75903px 31.0361px rgba(0, 0, 0, 0.15);
`;

export const TileCoverLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: calc(100% - ${TILE_SEPARATOR_HEIGHT});
  left: 0;
  top: 0;
  z-index: 5;
`;

export const TileCover = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({showInfo}) =>
    showInfo
      ? `${TILE_COVER_HEIGHT - 30}px`
      : `${TILE_COVER_HEIGHT}px`
  };
  width: 100%;
  padding: 10px;
  background: url('${({coverUrl}) => coverUrl}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${BORDER_RADIUS};
  position: relative;
`;

export const TileFilter = styled.div`
  position: absolute;
  background: linear-gradient(11.52deg, rgba(21, 20, 22, 0.85) 4.14%, rgba(21, 20, 22, 0.0001) 88.93%), rgba(170, 170, 170, 0.0001);
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  border-radius: ${BORDER_RADIUS};
`;

export const TileSeparatorCss = css`
  position: absolute !important;
  bottom: ${TILE_SEPARATOR_HEIGHT};
  left: 0;
  right: 0;
`;

export const TileProgressBar = styled(ProgressBar)`
  ${TileSeparatorCss};
  box-shadow: none !important;
  height: 1px !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.15) !important;
`;

export const TileSeparator = styled.div`
  ${TileSeparatorCss};
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

export const TileSeparatorVertical = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  height: ${TILE_SEPARATOR_HEIGHT};
  border-right: 1px solid rgba(255, 255, 255, 0.15);
`;

export const TileButtonCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])};

  &:hover {
  }

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.5em;
  height: 2.3em;
  bottom: 10px;
  right: 7.5%;
  letter-spacing: 0.04em;
  border-radius: 0.6em;
`;

export const TileButtonCustom = styled(CustomLabel)`
  ${TileButtonCss};
`;

export const TileCoverActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: rgb(0, 0, 0, 0.3);
  margin: -10px -10px 10px -10px;
  padding: 5px 10px;
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
  z-index: 10;

`;

export const TileCoverActionsRight = styled.div`
  display: flex;
  align-items: center;
`;

export const TileActionsBar = styled(ActionsBar)`
  color: ${({theme: {colors}}) => colors.lightGrey};

  &:hover {
    color: ${({theme: {colors}}) => colors.primary};
  }

  //width: 30px;
  padding: 0 0.5em;
  margin-right: -0.3em;

  justify-content: center;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h3]]],
    ['560px', [['font-size', theme.typo.sizes.h4]]],
    ['768px', [['font-size', theme.typo.sizes.h4]]],
    ['992px', [['font-size', theme.typo.sizes.h5]]],
    ['1200px', [['font-size', theme.typo.sizes.h5]]],
    ['1400px', [['font-size', theme.typo.sizes.body2]]]
  ])};
`;

export const TileActionsItem = styled(Icon)`
  //margin-right: 5px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  color: ${({isActive, theme: {colors}}) => {
    if (isActive) {
      return colors.due;
    }

    return colors.lightGrey;
  }};

  &:hover {
    color: ${({theme: {colors}}) => colors.primary};
  }

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])};

`;

export const TileCoverHeaderLeft = styled.span`
  display: flex;
  margin-top: -10px;
  margin-left: 8px;
  position: absolute;
`;

export const TilePrice = styled(PriceLabel)`
  ${PriceLiveLabelDimCss};
`;

export const TileLive = styled(LiveLabel)`
  ${PriceLiveLabelDimCss};
`;


export const TileDescription = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 10px 10px 5px 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
`;

export const TileDescriptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 94%;
  bottom: 70px;
  line-height: 1em;
  align-items: flex-end;
`;

export const TileDescriptionTitle = styled.span`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}
  font-weight: bold;
  color: ${({theme: {colors}}) => colors.white};
  display: block;
  display: -webkit-box;
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  letter-spacing: 0.02em;
`;


export const TileDescriptionLine = styled.div`
  display: flex;
  align-items: flex-start;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}
`;


export const TileDescriptionEnlargeSummary = styled.span`
  float: right;
  font-weight: bold;
`;

export const TileDescriptionEnlargeSummaryButton = styled(Icon)`
  cursor: pointer;
  font-weight: 500;
  color: ${({theme: {colors}}) => colors.darkGrey};
  visibility: hidden;
`;


export const TileDescriptionLineTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({theme: {colors}}) => colors.grayLighten};
  ${scale([
    ['default', [['font-size', '1.15rem']]],
    ['560px', [['font-size', '1rem']]],
    ['768px', [['font-size', '0.85rem']]],
    ['992px', [['font-size', '0.7rem']]],
    ['1400px', [['font-size', '0.6rem']]]
  ])}
  font-family: ${theme.typo.fontFamily.roboto};

  &:hover ${TileDescriptionEnlargeSummaryButton} {
    visibility: visible;
  }
`;

export const TileDescriptionLineText = styled.span`
  display: flex;
  line-height: normal;
  letter-spacing: 0.05em;
  font-weight: 500;
  word-break: break-word;
  cursor: ${({isOverFollow}) => isOverFollow ? 'pointer' : 'default'};

  margin-top: 0.5em;

  display: -webkit-box;

  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({showInfo}) =>
    showInfo
      ? css`
        -webkit-line-clamp: 4;
        max-height: 5em;
      `
      : css`
        -webkit-line-clamp: 2;
        max-height: 3em;
      `
  }

  ${UserSelectNoneCss};

`;

export const NoRating = styled.div`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]]
  ])}
  color: ${({theme: {colors}}) => colors.grey};
  font-weight: bold;
`;

export const TileDescriptionSocial = styled.span`
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: -5px;

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h3]]],
    ['560px', [['font-size', theme.typo.sizes.h4]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]

  ])}

`;

export const TileDescriptionFavorite = styled(Favorite)`
  z-index: 6;
  color: ${({theme: {colors}}) => colors.grayLighten};
  opacity: .7;

  &:hover {
    color: ${({theme: {colors}}) => colors.purpleLighten};
    opacity: 1;
  }
`;

export const TileDescriptionShare = styled(Share)`
  z-index: 8;
  color: ${({theme: {colors}}) => colors.grayLighten};
  opacity: .7;

  &:hover {
    color: ${({theme: {colors}}) => colors.purpleLighten};
    opacity: 1;
  }
`;

export const TileDescriptionDuration = styled.span`
  display: flex;
  color: ${({theme: {colors}}) => colors.white};
  font-weight: bold;
  white-space: nowrap;
  align-self: flex-end;
  padding-left: 0.8em;

  flex-direction: column;
  align-items: flex-end;
`;

export const TileDescriptionUsersCount = styled(UsersCount)`
  margin-right: -0.4em;
  text-shadow: none;
  font-weight: bold;
`;

export const ChannelTileWrap = styled.div`
  display: flex;
  flex-direction: column;
    // width: ${CHANNEL_AVATAR_DIM}px;
    //height: calc(242px + ${({theme: {typo}}) => typo.sizes.h1});
  margin-bottom: 10px;
  flex-shrink: 0;
  padding: 0;
  margin-right: ${({theme: {typo}}) => typo.sizes.h3};

  &:last-child {
    margin-right: 0;
  }

  border-radius: 5px;
`;

export const TileLeftBottom = styled.div`
  width: 50%;
  height: ${TILE_SEPARATOR_HEIGHT};
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const TileTagWrap = styled(Tags)`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', '0.7rem']]]
  ])};
  color: ${({theme: {colors}}) => colors.paleRed};

`;

export const TileChannelWrapCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', '0.7rem']]]
  ])};
  color: ${({theme: {colors}}) => colors.paleRed};
`

export const TileCustomUserNameStyle = css`
  margin-bottom: -0.4em;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', '0.88rem']]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]]
  ])}

  color: ${({theme: {colors}}) => colors.grayNew};
  max-width: 15em;

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.grayNew};
    text-decoration: none;
  }

  text-transform: uppercase;
`;

export const TileChannelUserAvatarCss = css`
  height: 33px !important;
  width: 33px !important;
`;

export const TileFollowersRatingCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]]
  ])}
  color: ${({theme: {colors}}) => colors.grayNew};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.grayNew};
    text-decoration: none;
  }
`;

export const TileDurationLabelCss = css`
  letter-spacing: 0.2em;
  font-weight: 400;
  text-transform: uppercase;

  ${scale([
    ['default', [['font-size', theme.typo.sizes.body]]],
    ['560px', [['font-size', theme.typo.sizes.medium]]],
    ['768px', [['font-size', theme.typo.sizes.small]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]]
  ])};
`;

export const TileDurationCss = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.medium]]],
    ['1200px', [['font-size', theme.typo.sizes.medium]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])};
`;


export const TileDescriptionDurationTime = styled.span`
  padding: 0 0.2em;
  ${TileDurationCss};
  letter-spacing: 0.04em;
`;

