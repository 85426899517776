import React, {useEffect, useMemo, useRef} from "react";
import PropTypes from 'prop-types';
import {throttle} from "lodash";
import {
  AuthorName,
  Body,
  Container,
  MessageWrapper,
  StyledAvatarWrap,
  TopHeaderMessage
} from "components/Chat/components/JSXElements";
import styled from "styled-components";

const UsersWrap = styled.span`
  padding-left: 5px;
  width: 100%;
  display: flex;
  align-items: center;
`;


let isBottom = true;

function UsersContainer({
                          usersInRoom,
                        }) {

  const usersInRoomRef = useRef(null)
  const bodyRef = useRef(null)

  function onScroll(e) {
    let diff = (e.target.scrollHeight - e.target.clientHeight) - e.target.scrollTop;
    isBottom = Math.abs(diff) <= 10;
  }

  const throttledEventHandler = useMemo(
    (e) => throttle((e) => onScroll(e), 50)
    , []);// eslint-disable-line
  const el = bodyRef.current;

  useEffect(() => {
    if (el) {
      el.addEventListener('scroll', throttledEventHandler, true);
      return () => {
        el.removeEventListener('scroll', throttledEventHandler, true);
      };
    }
  }, [el, throttledEventHandler]);

  useEffect(() => {
    if (isBottom) {
      usersInRoomRef.current?.scrollIntoView({/*behavior: "smooth",*/ block: "end", inline: "nearest"});
    }
  }, [usersInRoom]);

  return (
    <Body ref={bodyRef}>
      <Container>
        <div ref={usersInRoomRef}>
          {
            (usersInRoom || []).map(({userId, avatar, fullName}) => {
              return (
                <MessageWrapper key={userId}>

                  <StyledAvatarWrap
                    name={fullName}
                    url={avatar}
                  />
                  <UsersWrap>
                    <TopHeaderMessage>
                      <AuthorName>
                        {fullName}
                      </AuthorName>
                    </TopHeaderMessage>

                  </UsersWrap>

                </MessageWrapper>
              )
            })
          }
        </div>

      </Container>
    </Body>
  );
}

UsersContainer.propTypes = {
  userName: PropTypes.string
};

export default (React.memo(UsersContainer));

