import memoizeOne from "memoize-one";

export const getAccessControl = state => state.accessControl;

export const getEventAccessControl = (state, id) => {
  return getAccessControl(state)
    .find((row) => row.toEventId === id);
};

const memoGetWorkshopAccessControl = memoizeOne((accessControl, id) => accessControl.filter((row) => row.toWorkshopId === id))
export const getWorkshopAccessControl = (state, id) => {
  const accessControl = getAccessControl(state);
  return memoGetWorkshopAccessControl(accessControl, id);
};


const memoGetUserAccessControl = memoizeOne((accessControl, id) => accessControl.filter((row) => row.toUserId === id))
export const getUserAccessControl = (state, id) => {
  const accessControl = getAccessControl(state);
  return memoGetUserAccessControl(accessControl, id);
};


const memoGetOccurrenceAccessControl = memoizeOne((accessControl, id) => accessControl.filter((row) => row.toOccurrenceId === id))
export const getOccurrenceAccessControl = (state, id) => {
  const accessControl = getAccessControl(state);
  return memoGetOccurrenceAccessControl(accessControl, id);
};
