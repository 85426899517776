import {
  UPLOAD_STATUS,
  UPLOAD_TYPE
} from 'config/constants';

export function prepareFile(
  {
    flowId,
    flowOpts,
    flowFile,
    objUrl,
    file: {size, name, type},
    uploadType = UPLOAD_TYPE.CHUNKED.key,
    progress = 0,
    status = UPLOAD_STATUS.UPLOADING.key,
    cancelRequest = null
  }
) {
  return {
    flowId,
    flowOpts,
    objUrl,
    uniqueIdentifier: flowFile.uniqueIdentifier,
    size,
    name,
    type,
    uploadType,
    progress,
    status,
    cancelRequest
  };
}