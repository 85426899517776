import React, { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';

import { getAuthIsLoggedIn } from '../../../../reduxUtils/auth/selectors';
import { getUserId } from '../../../../reduxUtils/user/selectors';
import { getAccessControl } from '../../../../reduxUtils/accessControl/selectors';
import {
  getUserFullName,
  getUserAvatarThumbnail,
} from '../../../../reduxUtils/userProfile/selectors';
import { getUserCurrency, getUserSettings } from '../../../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
  getBrowserDimensions,
  getLoginModalStatus,
} from '../../../../reduxUtils/browserSettings/selectors';
import { getRates } from '../../../../reduxUtils/browserSettings/thunks';

import WithSideBar from 'layouts/WithSideBar';

import {
  addEventRegistration,
  createEventRegistration,
  deleteEventRegistration,
  setEventRegistrations,
} from '../../../../reduxUtils/userEventRegistrations/thunks';

import { refundContent } from '../../../../reduxUtils/user/thunks';

import {
  getEventRegistration,
  getUserEventRegistrations,
  getUserEventRegistrationsReqParams,
} from '../../../../reduxUtils/userEventRegistrations/selectors';
import Fallback from 'components/Fallback';
import { getIsChatDetach, getIsChatMinimized } from '../../../../reduxUtils/chat/selectors';
import { setChatMinimizeAction } from '../../../../reduxUtils/chat/actions';
import { setLoginModalStatus } from '../../../../reduxUtils/browserSettings/actions';

const Inspect = lazy(() => import('./Inspect'));

function EventFull({
  match,
  location,
  isMobile,
  userSettings,
  userCurrency,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  accessControls,
  fullName,
  avatar,
  getRates,
  eventRegistrations,
  userEventRegistrations,
  setEventRegistrations,
  userEventRegistrationsReqParams,
  addEventRegistration,
  createEventRegistration,
  deleteEventRegistration,
  refundContent,
  isChatDetach,
  isChatMinimized,
  setChatMinimizeAction,
  setLoginModalStatus,
  showLoginSignupModal,
}) {
  useEffect(() => {
    if (isMobile) {
      setChatMinimizeAction(true);
    }
  }, []); // eslint-disable-line

  return (
    <WithSideBar
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      isChatMinimized={isChatMinimized}
      showBarInMobile={false}
    >
      <Suspense fallback={<Fallback />}>
        <Inspect
          match={match}
          isMobile={isMobile}
          isLoggedIn={isLoggedIn}
          userId={userId}
          userSettings={userSettings}
          fullName={fullName}
          avatar={avatar}
          accessControls={accessControls}
          getRates={getRates}
          eventRegistrations={eventRegistrations}
          userEventRegistrations={userEventRegistrations}
          setEventRegistrations={setEventRegistrations}
          addEventRegistration={addEventRegistration}
          createEventRegistration={createEventRegistration}
          deleteEventRegistration={deleteEventRegistration}
          refundContent={refundContent}
          userEventRegistrationsReqParams={userEventRegistrationsReqParams}
          userCurrency={userCurrency}
          isChatMinimized={isChatMinimized}
          isChatDetach={isChatDetach}
          setLoginModalStatus={setLoginModalStatus}
          showLoginSignupModal={showLoginSignupModal}
        />
      </Suspense>
    </WithSideBar>
  );
}

EventFull.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  accessControls: PropTypes.array,
  userSettings: PropTypes.object,
  userCurrency: PropTypes.string,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  dimensions: PropTypes.object,
  eventRegistrations: PropTypes.array,
  userEventRegistrations: PropTypes.array,
  addEventRegistration: PropTypes.func,
  createEventRegistration: PropTypes.func,
  deleteEventRegistration: PropTypes.func,
  refundContent: PropTypes.func,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
  showLoginSignupModal: PropTypes.bool,
};

function mapStateToProps(state, { match }) {
  let params = new URLSearchParams(match.search);
  const eventId = params.get('id');

  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    accessControls: getAccessControl(state),
    userSettings: getUserSettings(state),
    userCurrency: getUserCurrency(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    dimensions: getBrowserDimensions(state),
    eventRegistrations: getEventRegistration(state, eventId),
    userEventRegistrations: getUserEventRegistrations(state),
    userEventRegistrationsReqParams: getUserEventRegistrationsReqParams(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
    showLoginSignupModal: getLoginModalStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEventRegistrations,
      addEventRegistration,
      createEventRegistration,
      deleteEventRegistration,
      refundContent,
      getRates,
      setChatMinimizeAction,
      setLoginModalStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFull);
