import styled, {css} from 'styled-components';
import ReactPlayer from 'react-player';
import {Link} from 'react-router-dom';
import theme from 'styles/theme';
import {
  scale,
  COVER_RATIO,
  NoContentCss,
  SpinnerCss,
  CoverHeightScaleCss,
  UserSelectNoneCss, TextShadowCss, LabelFontSizeCss, HideScrollBarCss, BodyFontSizeCss, SmallFontSizeCss
} from 'styles/common';
import PriceLabel from 'components/PriceLabel';
import LiveLabel from 'components/LiveLabel';
import CustomLabel from 'components/CustomLabel';
import DurationLabel from 'components/DurationLabel';
import Share from 'components/Share';
import Favorite from 'components/Favorite';
import StyledChannel from 'components/StyledChannel';
import ContextMenu from 'components/ContextMenu/EmptyContextMenu';
import ContentMenuItem from 'components/MenuItems/ContentMenuItem';
import Action from 'components/ActionsBar/component/Action';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';
import PlayIcon from 'components/PlayIcon';
import UsersCount from "components/UsersCount";
import ActionsBar from 'components/ActionsBar';

export const PriceAndLiveCss = css`
  --width: 38px;
  --height: 52px;
  ${scale([
    ['default', [['--width', '33px'], ['--height', '47px']]],
    ['560px', [['--width', '35px'], ['--height', '49px']]],
    ['768px', [['--width', '38px'], ['--height', '52px']]]
  ])}

  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);

  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);

  ${scale([
    ['default', [['font-size', '1.1rem']]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.medium]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]]
  ])}

`;

export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
  overflow-x: hidden;
  ${HideScrollBarCss};
`;


export const PageChatWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

export const ChatWrap = styled.div`
  display: flex;
  width: 300px;
  min-width: 300px;
`;

export const ChatWrapFull = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  border-top: 1px solid #DADDE1;
`;


export const PageMenuDetailsWrap = styled.div`
  display: flex;
  background: url('${({url, backupUrl}) => (url ? url : backupUrl)}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;


export const PageCoverPhotoDummy = styled.div`
  margin-top: ${COVER_RATIO};
`;

export const PageMenuCoverPhotoContainer = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 auto;
  width: 300px;
  ${scale([
    ['default', [['width', '230px']]],
    ['560px', [['width', '300px']]],
    ['768px', [['width', '350px']]],
    ['992px', [['width', '350px']]],
    ['1200px', [['width', '350px']]],
    ['1400px', [['width', '400px']]]
  ])};

`;


export const PageMenuWorkshopCoverPhotoContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 300px;
  ${scale([
    ['default', [['width', '230px']]],
    ['560px', [['width', '300px']]],
    ['768px', [['width', '350px']]],
    ['992px', [['width', '350px']]],
    ['1200px', [['width', '350px']]],
    ['1400px', [['width', '400px']]]
  ])};

  margin: 0 auto;
`;

export const PageMenuLabelsWrap = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 1em;
  z-index: 20;

`;

const SocialsCssBottom = css`
  color: ${({theme: {colors}}) => colors.grayNew};

  &:hover {
    color: ${({theme: {colors}}) => colors.darkGrey};
  }

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}
`;

export const PageActionBar = styled(ActionsBar)`
  ${SocialsCssBottom};
`;

export const PageSocial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: -0.5em;
  margin-top: -0.5em;
  padding-left: 10px;
`;

export const PageSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: flex-end;
  top: 1em;
  right: 1em;
  z-index: 20;
`;

// ${({alwaysShow}) =>
//   alwaysShow
//     ? css`
//       display: flex;
//     `
//     : css`
//       ${scale([
//         ['default', [['display', 'none']]],
//         ['560px', [['display', 'flex']]]
//       ])};
//     `
// }
export const PageStyledChannelWrapper = styled(StyledChannel)`
  display: flex;
`;

export const PageStyledChannelVideo = styled(StyledChannel)`
  ${({alwaysShow}) =>
    alwaysShow
      ? css`
        display: flex;
      `
      : css`
        ${scale([
          ['default', [['display', 'none']]],
          ['560px', [['display', 'flex']]]
        ])};
      `
  }
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 20;
`;

const SocialsCss = css`
  z-index: 20;
  color: ${({theme: {colors}}) => colors.grayLighten};
  opacity: .7;

  &:hover {
    color: ${({theme: {colors}}) => colors.purpleLighten};
    opacity: 1;
  }

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h4]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.h5]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body2]]]
  ])}
`;

export const PageDescriptionShareBottom = styled(Share)`
  ${SocialsCssBottom};
`;

export const PageDescriptionFavoriteBottom = styled(Favorite)`
  ${SocialsCssBottom};
`;

export const PageDescriptionUsersCount = styled(UsersCount)`
  text-shadow: none;
  color: ${({theme: {colors}}) => colors.grayNew};
  z-index: 20;
  ${LabelFontSizeCss};
`;

export const PageDescriptionShare = styled(Share)`
  ${SocialsCss};
`;

export const PageDescriptionFavorite = styled(Favorite)`
  ${SocialsCss};
  margin-bottom: 0.2em;
`;

export const PageDurationLabelAndUsersWrap = styled.div`
  position: absolute;
  bottom: 1em;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: flex-start;
  border-radius: 0 5px 5px 0;
  flex-direction: column;
  align-items: flex-start;
`;

export const PageDurationLabel = styled(DurationLabel)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  background-color: ${({theme: {colors}}) => colors.paleGrey};
`;

export const PageSpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
`;

export const PageStyledSpinner = styled(Spinner)`
  ${SpinnerCss};
  margin: auto;
  justify-content: center;
`;

export const PageCoverFilterWrap = styled.div`
  position: relative;
  display: flex;
  color: white;
  border-radius: 7px;

`;

export const PageCoverBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  background: url('${({url, backupUrl}) => (url ? url : backupUrl)}');
  background-size: cover;
  background-repeat: no-repeat;
  //background-position: center;
  background-position: 0 35%;
  border-radius: 0.5rem;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  cursor: auto;
  z-index: -6;
`;

export const PageCoverFilter = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -5;
  border-radius: 7px;
`;

export const PageEventRegistrationsWrap = styled.div`
  ${scale([
    ['default', [['padding-bottom', '1em 0']]],
    ['992px', [['padding-top', '0']]]
  ])}
  margin-left: auto;
`;

export const PageChannelUserDetails = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  color: ${({theme: {colors}}) => colors.white};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.white};
    text-decoration: none;
  }
`;

export const PageChannelUserName = styled.div`
  font-weight: bold;
  line-height: normal;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h3]]],
    ['560px', [['font-size', theme.typo.sizes.h3]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]]
  ])}
`;

export const BackButtonWrap = styled.div`
  display: flex;
`;

export const PageMenuDetailsShadow = styled.div`
  background-color: rgba(55, 46, 46, 0.7);
  padding: 5px;
  margin-bottom: 25px;
`;

export const PageMenuDetailsPriceLabel = styled(PriceLabel)`
  ${PriceAndLiveCss};
`;


export const PageMenuCustomLabelCss = css`
  width: 6.7em;
  height: 2.3em;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  border-radius: 0.6em;
  white-space: nowrap;

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}
  &:hover {
  }

`;

export const PageMenuDetailsCustomLabel = styled(CustomLabel)`
  ${PageMenuCustomLabelCss};
`;

export const PageMenuDetailsLiveLabel = styled(LiveLabel)`
  ${PriceAndLiveCss};
  line-height: normal;
`;

export const PageDetailsFullContent = styled.div`
  color: ${({theme: {colors}}) => colors.grayNew};
  padding: 0 1.1em 0;
  ${scale([
    ['1400px', [['padding', '0 3em']]]
  ])};
`;

export const PageDetailsRefundLabel = styled.div`

`;

export const PageDetailsTopRowContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px;

`;

export const PageActionItem = styled(Icon)`
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  color: ${({isActive, theme: {colors}}) => {
    if (isActive) {
      return colors.due;
    }

    return colors.grayNew;
  }};

  &:hover {
    color: ${({theme: {colors}}) => colors.primary};
  }

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])};

`;


export const PageDetailsContextMenu = styled(ContextMenu)`
  padding-left: 0.4em;

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}
`;

export const PageDetailsContextMenuItem = styled(ContentMenuItem)`
  margin-right: 5px;
`;

export const PageDetailsAction = styled(Action)`
`;

export const PageNoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${NoContentCss};
`;

export const PageMenuActionsContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 1em;
  right: 1em;
  //align-items: center;
`;

export const PageTabContent = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;

export const PageCommentsTitle = styled.div`
  font-size: ${({theme: {typo}}) => typo.sizes.h4};
  color: ${({theme: {colors}}) => colors.darkGrey};
  font-weight: bold;
`;

export const PageChannelRegistration = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  width: 100%;
  ${scale([
    ['default', [['flex-direction', 'row-reverse']]],
    ['560px', [['flex-direction', 'inherit']]],
  ])};
  margin-bottom: 2.5em;
  ${UserSelectNoneCss};
`;

export const PageContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
`;


export const PageContentBackground = styled.div`
  background-color: black;
  position: relative;
  min-height: auto;
  z-index: 50;
`;
//  // ${CoverHeightScaleCss};
export const VideoPlayerWrap = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;

  justify-content: center;

  & video {
    outline: none;
  }
`;

export const ReactPlayerWrap = styled(ReactPlayer)`
  //width: 100% !important;
  //height: 480px !important;
  //& video {
  //  width: 480px !important;
  //  height: 100% !important;;
  //}
  height: 52vw !important;
  max-height: 480px;
`;

export const PlayIconLabelIconWrap = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${CoverHeightScaleCss};
`;


export const PlayIconWrap = styled(PlayIcon)`
  margin-top: 3px;
  ${scale([
      ['default', [['width', '50px'], ['height', '50px '], ['font-size', '12px']]],
      ['560px', [['width', '50px'], ['height', '50px '], ['font-size', '12px']]],
      ['768px', [['width', '60px'], ['height', '60px '], ['font-size', '14px']]],
      ['992px', [['width', '80px'], ['height', '80px '], ['font-size', '20px']]],
    ]
  )};
`;


export const PlayIconLabel = styled.div`
  text-align: center;
  ${scale([
    ['default', [['font-size', '2.2rem']]],
    ['560px', [['font-size', '2.2rem']]],
    ['768px', [['font-size', '2.0rem']]],
    ['992px', [['font-size', '2.2rem']]],
    ['1200px', [['font-size', '2.2rem']]],
    ['1400px', [['font-size', '2rem']]]
  ])}

`;

export const PlayIconLabelIconLink = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  ${TextShadowCss};
  cursor: pointer;

  &:focus,
  &:hover {
    color: white;
    text-shadow: 0 0 10px #ff0080cc;
    text-decoration: none;
  }

  &:hover ${PlayIconLabel} {
    text-decoration: underline;
  }

`;


export const TypeLabel = styled.div`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}

`;


export const ShowAllAvailableDates = styled(Link)`
  color: ${({theme: {colors}}) => colors.purple};
  font-weight: 500;
  white-space: nowrap;
  text-align: end;
  text-transform: uppercase;
  display: block;

  ${scale([
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]]
  ])}
  &:hover {
    color: ${({theme: {colors}}) => colors.purpleLighten};
  }
`;
export const ShowAllAvailableDatesWrap = styled.div`
  flex-direction: column;
  padding: 10px ${({theme: {padding}}) => padding.pageM}px;
  ${scale([['768px', [['padding', `10px ${theme.padding.pageD}px`]]]])}
`;

export const PageChannelUserAvatarCss = css`
  --dim: 40px;
  width: var(--dim);
  height: var(--dim);
  min-width: var(--dim);
  max-width: var(--dim);
  min-height: var(--dim);
  max-height: var(--dim);

  ${scale([
    ['560px', [['--dim', '50px;']]],
    ['768px', [['--dim', '60px;']]],
    ['992px', [['--dim', '65px;']]],
    ['1200px', [['--dim', '70px;']]],
    ['1400px', [['--dim', '75px;']]],
  ])};

`;


const PageUserNameFontSize = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}

`;


export const PageFollowersRatingFontSize = css`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}

`;


export const PageUserNameCss = css`
  ${PageUserNameFontSize};
  color: ${({theme: {colors}}) => colors.grayNew};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.grayNew};
    text-decoration: none;
  }
`;

export const PageFollowersRatingCss = css`
  ${PageFollowersRatingFontSize};

  color: ${({theme: {colors}}) => colors.grayNew};

`;


export const PageUserNameWhiteCss = css`
  ${PageUserNameFontSize};
  color: ${({theme: {colors}}) => colors.white};
  ${TextShadowCss};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.lightGrey};
    text-decoration: none;
  }
`;

export const PageFollowersRatingWhiteCss = css`
  ${PageFollowersRatingFontSize};
  ${TextShadowCss};

  color: ${({theme: {colors}}) => colors.white};
`;


export const ZoomWrapper = styled.div`
  width: 100%;
  height: 52vw !important;
  max-height: 480px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;
export const ZoomWindowOpenerWrap = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({theme: {colors}}) => colors.lightGrey};

  &:hover {
    color: ${({theme: {colors}}) => colors.secondary};
  }

  ${UserSelectNoneCss};

`;

export const ZoomWindowOpener = styled.span`
  ${BodyFontSizeCss};
`;

export const ZoomPopupWarning = styled.span`
  ${SmallFontSizeCss};
`;

