import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  buildCustomQuery,
  buildQuery,
  destructuringKey,
  getMaxTilesFitAllPage,
  getVerticalScrollSkeletons,
  isMobileDevice,
} from 'utilities/general';

import PlaceholderTile from 'components/Tiles/Placeholder/PlaceholderTile';
import WorkshopTile from 'components/Tiles/WorkshopTile';
import EventTile from 'components/Tiles/EventTile';
import { PAGE_SIZE_BROWSE } from 'config/constants';
import { throttle } from 'lodash';

const SectionBodyWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-template-rows: auto;
  align-items: center;
  width: 100%;
  max-width: 100%;
  justify-content: center;

  height: 100%;
  max-height: 100%;
  overflow-y: auto;

  .browse-tiles {
    margin-left: auto;
    margin-right: auto;
  }
`;

const NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-size: ${({ theme: { typo } }) => typo.sizes.h2};
  margin: 0 auto 1em auto;
`;

const offsetFromTheBottom = 180;

function SectionBody({
  navigate,
  isMobile,
  isLoggedIn,
  userSettings,
  userId,
  accessControls,
  page,
  pageSize = PAGE_SIZE_BROWSE,
  totalPages,
  totalRows,
  rows = [],
  fetch,
  reqParams = {},
  deletion,
  search,
  categoryType,
  customPagination = false,
  dataSet,
  Tile,
  PlaceHolder = PlaceholderTile,
  isSelected,
  setDialog,
  setGoogleDialog,
}) {
  const [fetching, setFetching] = useState(true);
  const sectionRef = useRef();
  const isFetchingRef = useRef(false);

  function getQuery({
    deletion,
    dataSet,
    page,
    totalPages,
    totalRows,
    pageSize,
    rows,
    offset,
    limit,
  }) {
    let query;
    if (customPagination) {
      query = buildCustomQuery(deletion, dataSet, { page, totalPages, totalRows, pageSize, rows });
    } else {
      query = buildQuery({
        page,
        pageSize,
        offset,
        limit,
        filters: {
          category: search && search.category.value,
          inFutureOnly: true,
        },
      });
    }
    if (query) {
      query.reqParams = {
        search: search && search.category.value,
        dataSet,
        fetched: true,
      };
    }
    return query;
  }

  useEffect(() => {
    if (isSelected) {
      const { maxTiles, pageSizeStep } = getMaxTilesFitAllPage(isMobile);
      const searchVal = search && search.category.value;
      const needToFetchMoreTiles = maxTiles - rows.length;
      let newPageSize = pageSizeStep; //number of tiles to fetch from the server

      const shouldFetch =
        searchVal !== reqParams.search ||
        (reqParams.fetched && needToFetchMoreTiles > 0 && page < totalPages - 1) ||
        !reqParams.fetched ||
        reqParams.dataSet !== dataSet;

      if (shouldFetch) {
        const offset = rows.length;
        const limit = needToFetchMoreTiles;
        let _page = (offset + limit) / newPageSize;
        _page = _page > 0 ? _page - 1 : _page;
        const _totalPages = Math.ceil(totalRows / newPageSize);

        const query = getQuery({
          deletion,
          dataSet,
          page: _page,
          totalPages: _totalPages,
          totalRows,
          pageSize: newPageSize,
          rows,
          offset,
          limit,
        });

        if (query) {
          isFetchingRef.current = true;
          setFetching(true);

          fetch(query, true, categoryType).finally(() => {
            isFetchingRef.current = false;
            setFetching(false);
          });
        } else {
          setFetching(false);
        }
      } else {
        setFetching(false);
      }
    }
  }, [search && search.category.value, isSelected]); // eslint-disable-line

  const throttledEventHandler = useMemo(
    (e) => throttle((e) => onScroll(e), 50),
    [page, totalPages]
  ); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('scroll', throttledEventHandler, true);
    return () => {
      window.removeEventListener('scroll', throttledEventHandler, true);
    };
  }, [throttledEventHandler]); // eslint-disable-line

  function onScroll({ target }) {
    const bottom =
      target.scrollHeight - target.scrollTop - offsetFromTheBottom < target.clientHeight;
    if (!isFetchingRef.current && bottom && page < totalPages - 1) {
      isFetchingRef.current = true;
      let query = getQuery({
        deletion,
        dataSet,
        page: page + 1,
        totalPages,
        totalRows,
        pageSize,
        rows,
      });

      if (!query) {
        isFetchingRef.current = false;
      } else {
        setFetching(true);
        fetch(query, true, categoryType).finally(() => {
          isFetchingRef.current = false;
          setFetching(false);
        });
      }
    }
  }

  if (!isSelected) {
    return null;
  }

  return (
    <>
      {!fetching && (!rows || !rows.length) && <NoResultsContainer>No Results</NoResultsContainer>}
      <SectionBodyWrap ref={sectionRef} isMobileDevice={isMobileDevice()} isMobile={isMobile}>
        {(rows || []).map((item) => {
          let TileWrap = Tile;
          if (!Tile) {
            TileWrap = item.isEvent ? EventTile : WorkshopTile;
          }
          return (
            <TileWrap
              className={'browse-tiles'}
              userId={userId}
              accessControls={accessControls}
              navigate={navigate}
              key={destructuringKey(item)}
              isMobile={isMobile}
              isLoggedIn={isLoggedIn}
              {...item}
              content={item}
              userCurrency={userSettings.currency}
              setDialog={setDialog}
              setGoogleDialog={setGoogleDialog}
            />
          );
        })}

        {fetching && (
          <>
            {Array(getVerticalScrollSkeletons(rows, isMobile))
              .fill(0)
              .map((_, idx) => (
                <PlaceHolder key={idx} className={'browse-tiles'} />
              ))}
          </>
        )}
      </SectionBodyWrap>
    </>
  );
}

SectionBody.propTypes = {
  navigate: PropTypes.object,
  userId: PropTypes.number,
  accessControls: PropTypes.array,
  isMobile: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  userSettings: PropTypes.object,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  totalRows: PropTypes.number,
  rows: PropTypes.array,
  reqParams: PropTypes.object,
  search: PropTypes.object,
  fetch: PropTypes.func,
  deletion: PropTypes.func,
  Tile: PropTypes.object,
  PlaceHolder: PropTypes.func,
  setDialog: PropTypes.func,
  setGoogleDialog: PropTypes.func,
  categoryType: PropTypes.string,
};
export default React.memo(SectionBody);
