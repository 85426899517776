module.exports = {
  USD: {key: 'USD', value: 'United States Dollar', symbol: '$', minPurchase: 1, maxPurchase: 100},
  EUR: {key: 'EUR', value: 'Euro', symbol: '€', minPurchase: 1, maxPurchase: 100},
  GBP: {key: 'GBP', value: 'British Pound Sterling', symbol: '£', minPurchase: 1, maxPurchase: 100},
  ILS: {key: 'ILS', value: 'Israeli New Sheqel', symbol: '₪', minPurchase: 5, maxPurchase: 300},

  /*
  AED: { key: 'AED', value: 'United Arab Emirates Dirham', symbol: 'د.إ'},
  AFN: { key: 'AFN', value: 'Afghan Afghani', symbol: '؋'},
  ALL: { key: 'ALL', value: 'Albanian Lek', symbol: 'Lek'},
  AMD: { key: 'AMD', value: 'Armenian Dram', symbol: '֏'},
  ANG: { key: 'ANG', value: 'Netherlands Antillean Guilder', symbol: 'ƒ'},
  AOA: { key: 'AOA', value: 'Angolan Kwanza', symbol: 'Kz'},
  ARS: { key: 'ARS', value: 'Argentine Peso', symbol: '$'},
  AUD: { key: 'AUD', value: 'Australian Dollar', symbol: 'A$'},
  AWG: { key: 'AWG', value: 'Aruban Florin', symbol: 'ƒ'},
  AZN: { key: 'AZN', value: 'Azerbaijani Manat', symbol: 'ман'},
  BAM: { key: 'BAM', value: 'Bosnia-Herzegovina Convertible Mark', symbol: 'КМ'},
  BBD: { key: 'BBD', value: 'Barbadian Dollar', symbol: '$'},
  BDT: { key: 'BDT', value: 'Bangladeshi Taka', symbol: '৳'},
  BGN: { key: 'BGN', value: 'Bulgarian Lev', symbol: 'лв'},
  BHD: { key: 'BHD', value: 'Bahraini Dinar', symbol: 'ب.د'},
  BIF: { key: 'BIF', value: 'Burundian Franc', symbol: '₣'},
  BMD: { key: 'BMD', value: 'Bermudan Dollar', symbol: '$'},
  BND: { key: 'BND', value: 'Brunei Dollar', symbol: '$'},
  BOB: { key: 'BOB', value: 'Bolivian Boliviano', symbol: 'Bs.'},
  BRL: { key: 'BRL', value: 'Brazilian Real', symbol: 'R$'},
  BSD: { key: 'BSD', value: 'Bahamian Dollar', symbol: '$'},
  BTC: { key: 'BTC', value: 'Bitcoin', symbol: '₿'},
  BTN: { key: 'BTN', value: 'Bhutanese Ngultrum', symbol: 'Nu.'},
  BWP: { key: 'BWP', value: 'Botswanan Pula', symbol: 'P'},
  BYN: { key: 'BYN', value: 'Belarusian Ruble', symbol: 'Br'},
  BZD: { key: 'BZD', value: 'Belize Dollar', symbol: '$'},
  CAD: { key: 'CAD', value: 'Canadian Dollar', symbol: '$'},
  CDF: { key: 'CDF', value: 'Congolese Franc', symbol: '₣'},
  CHF: { key: 'CHF', value: 'Swiss Franc', symbol: '₣'},
  CLF: { key: 'CLF', value: 'Chilean Unit of Account (UF)', symbol: 'UF'},
  CLP: { key: 'CLP', value: 'Chilean Peso', symbol: '$'},
  CNH: { key: 'CNH', value: 'Chinese Yuan (Offshore)', symbol: '¥'},
  CNY: { key: 'CNY', value: 'Chinese Yuan', symbol: '¥'},
  COP: { key: 'COP', value: 'Colombian Peso', symbol: '$'},
  CRC: { key: 'CRC', value: 'Costa Rican Colón', symbol: '₡'},
  CUC: { key: 'CUC', value: 'Cuban Convertible Peso', symbol: 'CUC$'},
  CUP: { key: 'CUP', value: 'Cuban Peso', symbol: '$'},
  CVE: { key: 'CVE', value: 'Cape Verdean Escudo', symbol: '$'},
  CZK: { key: 'CZK', value: 'Czech Republic Koruna', symbol: 'Kč'},
  DJF: { key: 'DJF', value: 'Djiboutian Franc', symbol: '₣'},
  DKK: { key: 'DKK', value: 'Danish Krone', symbol: 'kr'},
  DOP: { key: 'DOP', value: 'Dominican Peso', symbol: '$'},
  DZD: { key: 'DZD', value: 'Algerian Dinar', symbol: 'د.ج'},
  EGP: { key: 'EGP', value: 'Egyptian Pound', symbol: ' £'},
  ERN: { key: 'ERN', value: 'Eritrean Nakfa', symbol: 'Nfk'},
  ETB: { key: 'ETB', value: 'Ethiopian Birr', symbol: 'ብር'},
  FJD: { key: 'FJD', value: 'Fijian Dollar', symbol: '$'},
  FKP: { key: 'FKP', value: 'Falkland Islands Pound', symbol: '£'},
  GEL: { key: 'GEL', value: 'Georgian Lari', symbol: 'ლ'},
  GGP: { key: 'GGP', value: 'Guernsey Pound', symbol: '£'},
  GHS: { key: 'GHS', value: 'Ghanaian Cedi', symbol: '  ₵'},
  GIP: { key: 'GIP', value: 'Gibraltar Pound', symbol: '£'},
  GMD: { key: 'GMD', value: 'Gambian Dalasi', symbol: 'D'},
  GNF: { key: 'GNF', value: 'Guinean Franc', symbol: '₣'},
  GTQ: { key: 'GTQ', value: 'Guatemalan Quetzal', symbol: 'Q'},
  GYD: { key: 'GYD', value: 'Guyanaese Dollar', symbol: '$'},
  HKD: { key: 'HKD', value: 'Hong Kong Dollar', symbol: '$'},
  HNL: { key: 'HNL', value: 'Honduran Lempira', symbol: 'L'},
  HRK: { key: 'HRK', value: 'Croatian Kuna', symbol: 'Kn'},
  HTG: { key: 'HTG', value: 'Haitian Gourde', symbol: 'G'},
  HUF: { key: 'HUF', value: 'Hungarian Forint', symbol: 'Ft'},
  IDR: { key: 'IDR', value: 'Indonesian Rupiah', symbol: 'Rp'},
  IMP: { key: 'IMP', value: 'Manx pound', symbol: '£'},
  INR: { key: 'INR', value: 'Indian Rupee', symbol: '₹'},
  IQD: { key: 'IQD', value: 'Iraqi Dinar', symbol: 'ع.د'},
  IRR: { key: 'IRR', value: 'Iranian Rial', symbol: '﷼'},
  ISK: { key: 'ISK', value: 'Icelandic Króna', symbol: 'Kr'},
  JEP: { key: 'JEP', value: 'Jersey Pound', symbol: '£'},
  JMD: { key: 'JMD', value: 'Jamaican Dollar', symbol: '$'},
  JOD: { key: 'JOD', value: 'Jordanian Dinar', symbol: 'د.ا'},
  JPY: { key: 'JPY', value: 'Japanese Yen', symbol: '¥'},
  KES: { key: 'KES', value: 'Kenyan Shilling', symbol: 'Sh'},
  KGS: { key: 'KGS', value: 'Kyrgystani Som', symbol: 'Лв'},
  KHR: { key: 'KHR', value: 'Cambodian Riel', symbol: '៛'},
  KMF: { key: 'KMF', value: 'Comorian Franc', symbol: 'CF'},
  KPW: { key: 'KPW', value: 'North Korean Won', symbol: '₩'},
  KRW: { key: 'KRW', value: 'South Korean Won', symbol: '₩'},
  KWD: { key: 'KWD', value: 'Kuwaiti Dinar', symbol: 'د.ك'},
  KYD: { key: 'KYD', value: 'Cayman Islands Dollar', symbol: '$'},
  KZT: { key: 'KZT', value: 'Kazakhstani Tenge', symbol: '〒'},
  LAK: { key: 'LAK', value: 'Laotian Kip', symbol: '₭'},
  LBP: { key: 'LBP', value: 'Lebanese Pound', symbol: 'ل.ل'},
  LKR: { key: 'LKR', value: 'Sri Lankan Rupee', symbol: 'Rs'},
  LRD: { key: 'LRD', value: 'Liberian Dollar', symbol: '$'},
  LSL: { key: 'LSL', value: 'Lesotho Loti', symbol: 'L'},
  LYD: { key: 'LYD', value: 'Libyan Dinar', symbol: 'ل.د'},
  MAD: { key: 'MAD', value: 'Moroccan Dirham', symbol: 'د.م.'},
  MDL: { key: 'MDL', value: 'Moldovan Leu', symbol: 'L'},
  MGA: { key: 'MGA', value: 'Malagasy Ariary', symbol: 'Ar'},
  MKD: { key: 'MKD', value: 'Macedonian Denar', symbol: 'ден'},
  MMK: { key: 'MMK', value: 'Myanma Kyat', symbol: 'K'},
  MNT: { key: 'MNT', value: 'Mongolian Tugrik', symbol: '₮'},
  MOP: { key: 'MOP', value: 'Macanese Pataca', symbol: 'P'},
  MRU: { key: 'MRU', value: 'Mauritanian Ouguiya', symbol: 'UM'},
  MUR: { key: 'MUR', value: 'Mauritian Rupee', symbol: '₨'},
  MVR: { key: 'MVR', value: 'Maldivian Rufiyaa', symbol: 'ރ.'},
  MWK: { key: 'MWK', value: 'Malawian Kwacha', symbol: 'MK'},
  MXN: { key: 'MXN', value: 'Mexican Peso', symbol: '$'},
  MYR: { key: 'MYR', value: 'Malaysian Ringgit', symbol: 'RM'},
  MZN: { key: 'MZN', value: 'Mozambican Metical', symbol: 'MTn'},
  NAD: { key: 'NAD', value: 'Namibian Dollar', symbol: '$'},
  NGN: { key: 'NGN', value: 'Nigerian Naira', symbol: '₦'},
  NIO: { key: 'NIO', value: 'Nicaraguan Córdoba', symbol: 'C$'},
  NOK: { key: 'NOK', value: 'Norwegian Krone', symbol: 'kr'},
  NPR: { key: 'NPR', value: 'Nepalese Rupee', symbol: '₨'},
  NZD: { key: 'NZD', value: 'New Zealand Dollar', symbol: '$'},
  OMR: { key: 'OMR', value: 'Omani Rial', symbol: 'ر.ع.'},
  PAB: { key: 'PAB', value: 'Panamanian Balboa', symbol: 'B/.'},
  PEN: { key: 'PEN', value: 'Peruvian Nuevo Sol', symbol: 'S/.'},
  PGK: { key: 'PGK', value: 'Papua New Guinean Kina', symbol: 'K'},
  PHP: { key: 'PHP', value: 'Philippine Peso', symbol: '₱'},
  PKR: { key: 'PKR', value: 'Pakistani Rupee', symbol: '₨'},
  PLN: { key: 'PLN', value: 'Polish Zloty', symbol: 'zł'},
  PYG: { key: 'PYG', value: 'Paraguayan Guarani', symbol: '₲'},
  QAR: { key: 'QAR', value: 'Qatari Rial', symbol: 'ر.ق'},
  RON: { key: 'RON', value: 'Romanian Leu', symbol: 'L'},
  RSD: { key: 'RSD', value: 'Serbian Dinar', symbol: 'din'},
  RUB: { key: 'RUB', value: 'Russian Ruble', symbol: 'р.'},
  RWF: { key: 'RWF', value: 'Rwandan Franc', symbol: '₣'},
  SAR: { key: 'SAR', value: 'Saudi Riyal', symbol: 'ر.س'},
  SBD: { key: 'SBD', value: 'Solomon Islands Dollar', symbol: '$'},
  SCR: { key: 'SCR', value: 'Seychellois Rupee', symbol: '₨'},
  SDG: { key: 'SDG', value: 'Sudanese Pound', symbol: '£'},
  SEK: { key: 'SEK', value: 'Swedish Krona', symbol: 'kr'},
  SGD: { key: 'SGD', value: 'Singapore Dollar', symbol: '$'},
  SHP: { key: 'SHP', value: 'Saint Helena Pound', symbol: '£'},
  SLL: { key: 'SLL', value: 'Sierra Leonean Leone', symbol: 'Le'},
  SOS: { key: 'SOS', value: 'Somali Shilling', symbol: 'Sh'},
  SRD: { key: 'SRD', value: 'Surinamese Dollar', symbol: '$'},
  SSP: { key: 'SSP', value: 'South Sudanese Pound', symbol: '£'},
  STN: { key: 'STN', value: 'São Tomé and Príncipe Dobra', symbol: 'Db'},
  SVC: { key: 'SVC', value: 'Salvadoran Colón', symbol: '¢'},
  SYP: { key: 'SYP', value: 'Syrian Pound', symbol: 'ل.س'},
  SZL: { key: 'SZL', value: 'Swazi Lilangeni', symbol: 'L'},
  THB: { key: 'THB', value: 'Thai Baht', symbol: '฿'},
  TJS: { key: 'TJS', value: 'Tajikistani Somoni', symbol: 'ЅМ'},
  TMT: { key: 'TMT', value: 'Turkmenistani Manat', symbol: 'm'},
  TND: { key: 'TND', value: 'Tunisian Dinar', symbol: 'د.ت'},
  TOP: { key: 'TOP', value: 'Tongan Pa\'anga', symbol: 'T$'},
  TRY: { key: 'TRY', value: 'Turkish Lira', symbol: '₤'},
  TTD: { key: 'TTD', value: 'Trinidad and Tobago Dollar', symbol: '$'},
  TWD: { key: 'TWD', value: 'New Taiwan Dollar', symbol: '$'},
  TZS: { key: 'TZS', value: 'Tanzanian Shilling', symbol: 'Sh'},
  UAH: { key: 'UAH', value: 'Ukrainian Hryvnia', symbol: '₴'},
  UGX: { key: 'UGX', value: 'Ugandan Shilling', symbol: 'Sh'},
  UYU: { key: 'UYU', value: 'Uruguayan Peso', symbol: '$'},
  VES: { key: 'VES', value: 'Venezuelan Bolívar Soberano', symbol: 'bs.f.'},
  VND: { key: 'VND', value: 'Vietnamese Dong', symbol: '₫'},
  VUV: { key: 'VUV', value: 'Vanuatu Vatu', symbol: 'Vt'},
  WST: { key: 'WST', value: 'Samoan Tala', symbol: 'T'},
  XAF: { key: 'XAF', value: 'CFA Franc BEAC', symbol: '₣'},
  XCD: { key: 'XCD', value: 'East Caribbean Dollar', symbol: '$'},
  XDR: { key: 'XDR', value: 'Special Drawing Rights', symbol: 'XDR'},
  XOF: { key: 'XOF', value: 'CFA Franc BCEAO', symbol: 'CFA'},
  XPF: { key: 'XPF', value: 'CFP Franc', symbol: '₣'},
  YER: { key: 'YER', value: 'Yemeni Rial', symbol: '﷼'},
  ZAR: { key: 'ZAR', value: 'South African Rand', symbol: 'R'},
  ZMW: { key: 'ZMW', value: 'Zambian Kwacha', symbol: 'ZK'},
  ZWL: { key: 'ZWL', value: 'Zimbabwean Dollar', symbol: '$'}
  */
};