import {createAction} from 'redux-actions';
import {
  SET_ACCESS_CONTROL,
  ADD_ACCESS_CONTROL,
  UPDATE_ACCESS_CONTROL,
  DELETE_ACCESS_CONTROL
} from './types';

export const setAccessControl = createAction(SET_ACCESS_CONTROL);
export const addAccessControl = createAction(ADD_ACCESS_CONTROL);
export const updateAccessControl = createAction(UPDATE_ACCESS_CONTROL);
export const deleteAccessControl = createAction(DELETE_ACCESS_CONTROL);