import React, { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAuthIsLoggedIn } from '../../../../reduxUtils/auth/selectors';
import { getUserId } from '../../../../reduxUtils/user/selectors';
import { getAccessControl } from '../../../../reduxUtils/accessControl/selectors';
import {
  getUserFullName,
  getUserAvatar,
  getUserRating,
  getUserRatingCount,
  getUserFollowersCount,
} from '../../../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
  getBrowserDimensions,
} from '../../../../reduxUtils/browserSettings/selectors';
import { getRates } from '../../../../reduxUtils/browserSettings/thunks';

import WithSideBar from 'layouts/WithSideBar';
import Fallback from 'components/Fallback';
import { getIsChatDetach, getIsChatMinimized } from '../../../../reduxUtils/chat/selectors';
import { setChatMinimizeAction } from '../../../../reduxUtils/chat/actions';

const Manage = lazy(() => import('./Manage'));

function EventFull({
  match,
  location,
  isMobile,
  userSettings,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  fullName,
  avatar,
  rating,
  ratingCount,
  followersCount,
  getRates,
  isChatMinimized,
  isChatDetach,
  setChatMinimizeAction,
}) {
  useEffect(() => {
    if (isMobile) {
      setChatMinimizeAction(true);
    }
  }, []); // eslint-disable-line

  return (
    <WithSideBar
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      isChatMinimized={isChatMinimized}
    >
      <Suspense fallback={<Fallback />}>
        <Manage
          match={match}
          isMobile={isMobile}
          userSettings={userSettings}
          isLoggedIn={isLoggedIn}
          userId={userId}
          fullName={fullName}
          avatar={avatar}
          getRates={getRates}
          rating={rating}
          ratingCount={ratingCount}
          followersCount={followersCount}
          isChatMinimized={isChatMinimized}
          isChatDetach={isChatDetach}
        />
      </Suspense>
    </WithSideBar>
  );
}

EventFull.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  accessControls: PropTypes.array,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  dimensions: PropTypes.object,
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  followersCount: PropTypes.number,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    accessControls: getAccessControl(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatar(state),
    rating: getUserRating(state),
    ratingCount: getUserRatingCount(state),
    followersCount: getUserFollowersCount(state),
    dimensions: getBrowserDimensions(state),
    isChatMinimized: getIsChatMinimized(state),
    isChatDetach: getIsChatDetach(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRates,
      setChatMinimizeAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventFull);
