import {createAction} from 'redux-actions';
import {
  SET_COLLAPSED,
  ADD_FILE,
  UPDATE_FILE,
  DELETE_FILE
} from './types';

export const setCollapsed = createAction(SET_COLLAPSED);
export const addFile = createAction(ADD_FILE);
export const updateFile = createAction(UPDATE_FILE);
export const deleteFile = createAction(DELETE_FILE);