import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Row, Col} from 'react-bootstrap';

import {
  BaseFontSizeCss,
  Body2FontSizeCss,
  ErrorFontSizeCss,
  LabelFontSizeCss
} from 'styles/common';
import ContentDetails from 'components/Payment/components/ContentDetails';
import Button from 'components/Button';
import PaymentMethod from 'components/StripePayNow/component/PaymentMethod';
import AddPayment from 'components/Payment/components/AddPayment';
import PlaceHolder from 'components/PlaceHolder';
import {hasErrors} from 'utilities/general';
import {TRANSACTION_STATUS} from "config/constants";

const PaymentDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  font-family: ${({theme: {typo}}) => typo.fontFamily.montserrat};
`;

const PaymentDetailsInner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;


const PlaceHolderWrap = styled(PlaceHolder)`
  width: 100%;
`;

const ContentWrap = styled.div`
  max-width: 450px;
  min-width: 200px;

  margin: 0 auto;
`;

const RefundLabel = styled.div`
  margin-top: 0.2rem;
  ${BaseFontSizeCss};
`;

const StripePayNowWrap = styled.div`
  max-width: 450px;
  min-width: 290px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 7px;
  justify-content: center;
  margin: 0 auto;

  #root {
    display: flex;
    align-items: center;
  }


  .result-message {
    line-height: 22px;
    font-size: 16px;
  }

  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }

  .hidden {
    display: none;
  }

  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }

  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid ${({theme: {colors}}) => colors.lightGrey};
    max-height: 44px;
    width: 100%;
    background-color: ${({theme: {colors}}) => colors.paleGrey};
    box-sizing: border-box;
  }

  #payment-request-button {
    margin-bottom: 32px;
  }

`;

const ProcessingPaymentWrap = styled.div`
  display: flex;
  padding: 10px 15px;
  // text-align: center;
  // font-weight: bold;
    // ${Body2FontSizeCss};
`;

const ProcessingPayment = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  ${Body2FontSizeCss};
`;

const StripePayNowTitle = styled.div`
  padding: 10px 15px;
  text-align: center;
  font-weight: bold;
  ${Body2FontSizeCss};
`;


const Text = styled.div`
  padding: 0 15px 15px;
  ${LabelFontSizeCss};
`;

const DotFlashingWrap = styled.span`
  padding: 0 2ch;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-end;
`;

const DotFlashing = styled.span`
  text-align: center;
  ${LabelFontSizeCss};

  position: relative;
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &::before, &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
`;


const AddPaymentMethod = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
  color: ${({theme: {colors}}) => colors.primary};
  ${LabelFontSizeCss};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.secondary};
  }
`;


const PaymentMethodsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Error = styled.div`
  user-select: text;
  max-width: 270px;
  color: ${({theme: {colors}}) => colors.error};
  font-weight: 400;
  word-break: break-word;
  white-space: break-spaces;
  ${ErrorFontSizeCss};
`;


const ButtonsWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;


const ButtonWrap = styled(Button)`
  padding: 0.8em 1.0em;
  ${LabelFontSizeCss};

  &:first-child {
    margin-left: 0;
  }
`;

const PayButton = styled(Button)`
  margin-left: 20px;
  padding: 0.8em 3.0em;
  ${LabelFontSizeCss};

  &:first-child {
    margin-left: 0;
  }
`;


function PaymentDetails(
  {
    header,
    subHeader,
    refundLabel,
    title,
    coverPhoto,
    price,
    currency,
    userCurrency,
    isProcessing,
    paymentMethods,
    isLoadingPayments,
    cardErrorResponse,
    paySubmitError,
    stripeCustomerDPM,
    addPaymentMethod,
    selectedPaymentMethod,
    onDeletePaymentMethod,
    onNewPaymentMethodClick,
    onSelectPaymentMethod,
    onDefaultPaymentMethod,
    handlePaySubmit,
    handleSubmitNewPayment,
    errors,
    setErrors,
    billingDetails,
    setBillingDetails,
    savePaymentMethod,
    setSavePaymentMethod,
    disabledAddPayment,
    setDisabledAddPayment,
    setCardErrorResponse,
    transaction,
  }) {
  const addNewPaymentMethod = (!paymentMethods.length && !isLoadingPayments) || addPaymentMethod;

  function isPaymentSelected() {
    return selectedPaymentMethod && !!paymentMethods.find(({id, last4, brand}) => id === selectedPaymentMethod);
  }

  const containErrors = hasErrors(errors);
  const isProcessingPayment = isProcessing || (transaction && transaction.status && (transaction.status.toUpperCase() === TRANSACTION_STATUS.STARTED.key || transaction.status.toUpperCase() === TRANSACTION_STATUS.SUCCEEDED.key));

  const disablePayButton = (isProcessing || isProcessingPayment) || (addNewPaymentMethod ? (containErrors || disabledAddPayment) : !isPaymentSelected());
  const errorsSubmitNewPayment = (containErrors && errors.card) || cardErrorResponse;
  const anyErrors = addNewPaymentMethod ? errorsSubmitNewPayment : paySubmitError;

  return (
    <PaymentDetailsWrap>
      <PaymentDetailsInner>
        <Row style={{
          flexWrap: isProcessingPayment ? 'wrap-reverse' : 'wrap',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Col xs={12} md={'auto'}>
            {
              <ContentWrap>
                <ContentDetails
                  header={header}
                  subHeader={subHeader}
                  title={title}
                  coverPhoto={coverPhoto}
                  price={price}
                  currency={currency}
                  userCurrency={userCurrency}
                />
                <RefundLabel>
                  {refundLabel}
                </RefundLabel>
              </ContentWrap>
            }
          </Col>

          <Col xs={12} md={'auto'}>
            <StripePayNowWrap>

              <div style={{display: !isProcessingPayment ? 'none' : 'unset'}}>
                <ProcessingPaymentWrap>
                  <ProcessingPayment>
                    Processing Payment
                  </ProcessingPayment>
                  <DotFlashingWrap>
                    <DotFlashing />
                  </DotFlashingWrap>
                </ProcessingPaymentWrap>
                <Text> We are still processing your payment</Text>
              </div>


              <div style={{display: !isProcessingPayment && addNewPaymentMethod ? 'unset' : 'none'}}>
                <StripePayNowTitle>Billing</StripePayNowTitle>
                <AddPayment
                  errors={errors}
                  setErrors={setErrors}
                  billingDetails={billingDetails}
                  setBillingDetails={setBillingDetails}
                  savePaymentMethod={savePaymentMethod}
                  setSavePaymentMethod={setSavePaymentMethod}
                  setDisabledAddPayment={setDisabledAddPayment}
                  setCardErrorResponse={setCardErrorResponse}
                />
              </div>


              <div style={{display: !isProcessingPayment && !addNewPaymentMethod ? 'unset' : 'none'}}>
                <StripePayNowTitle>Payment</StripePayNowTitle>
                <PaymentMethodsList>
                  {
                    isLoadingPayments
                      ?
                      (
                        Array(3)
                          .fill(0)
                          .map((_, idx) => (
                            <PlaceHolderWrap
                              key={idx}
                              height={'25px'}
                              marginBottom={'10px'}
                              marginLeft={0}
                            />
                          ))
                      )
                      :
                      (
                        paymentMethods.map(({id, last4, brand}, index) => (
                          <PaymentMethod
                            key={id}
                            id={id}
                            brand={brand}
                            last4={last4}
                            isSelected={id === selectedPaymentMethod}
                            isDefault={id === stripeCustomerDPM}
                            onSelect={() => onSelectPaymentMethod(id)}
                            onDelete={() => onDeletePaymentMethod(id, index)}
                            onSetDefault={(e) => onDefaultPaymentMethod(e, id)}
                          />
                        ))
                      )
                  }
                </PaymentMethodsList>
                {
                  !isLoadingPayments &&
                  <AddPaymentMethod onClick={onNewPaymentMethodClick}>
                    Add Payment Method
                  </AddPaymentMethod>
                }
              </div>
            
              {(anyErrors) && <Error>{anyErrors}</Error>}

            </StripePayNowWrap>
          </Col>
        </Row>
      </PaymentDetailsInner>
      <ButtonsWrap>
        {
          (addNewPaymentMethod && !!paymentMethods.length) &&
          <ButtonWrap
            disabled={isProcessing}
            label='CANCEL'
            onClick={() => onNewPaymentMethodClick(false)}
            type='button'
            buttonStyle='generalWhite'
          />
        }

        <PayButton
          disabled={disablePayButton}
          onClick={addNewPaymentMethod ? handleSubmitNewPayment : handlePaySubmit}
          isLoading={isProcessing || isLoadingPayments}
          label='PAY'
          buttonStyle={'generalPurple'}
          id='submit'
        />

      </ButtonsWrap>
    </PaymentDetailsWrap>
  );
}

PaymentDetails.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  coverPhoto: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  userCurrency: PropTypes.string,
  isProcessing: PropTypes.bool,
  paymentMethods: PropTypes.array,
  isLoadingPayments: PropTypes.bool,
  cardErrorResponse: PropTypes.string,
  paySubmitError: PropTypes.string,
  stripeCustomerDPM: PropTypes.string,
  selectedPaymentMethod: PropTypes.string,
  onDeletePaymentMethod: PropTypes.func,
  onNewPaymentMethodClick: PropTypes.func,
  onSelectPaymentMethod: PropTypes.func,
  onDefaultPaymentMethod: PropTypes.func,
  handlePaySubmit: PropTypes.func,
  handleSubmitNewPayment: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  billingDetails: PropTypes.object,
  setBillingDetails: PropTypes.func,
  savePaymentMethod: PropTypes.bool,
  setSavePaymentMethod: PropTypes.func,
  disabledAddPayment: PropTypes.bool,
  setDisabledAddPayment: PropTypes.func,
  setCardErrorResponse: PropTypes.func,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  refundLabel: PropTypes.string,
};

export default PaymentDetails;