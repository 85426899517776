import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import FeedbacksBar from '../components/FeedbacksBar';
import TopBar from '../components/TopBar';
import LeftSideBar from '../components/LeftSideBar';
import BottomBar from '../components/BottomBar';
import { LightCustomToolbar, ScaleFontMenuCss } from 'styles/common';
import { getChannelBaseUrl, isMobileDevice } from 'utilities/general';

const WithSideBarWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${ScaleFontMenuCss};
  color: ${({ theme: { colors } }) => colors.grayNew};
  @media only screen and (min-width: 600px) {
    zoom: 0.8;
  }
`;

const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
`;

const PageContent = styled.div`
  flex-grow: 1;

  overflow: auto;
  scroll-behavior: smooth;
  ${({ isMobileDevice }) =>
    !isMobileDevice &&
    css`
      ${LightCustomToolbar};
    `}
`;

function WithSideBar({
  location,
  options,
  isLoggedIn,
  isMobile,
  forAdmin,
  isLeftBarOpened,
  userId,
  fullName,
  avatar,
  setLocalIsLeftBarOpened,
  isChatMinimized,
  showLeftBar = false,
  showBarInMobile = true,
  children,
}) {
  let defaultOptions = isLoggedIn
    ? [
        {
          label: 'Home',
          iconName: 'fas fa-home',
          linkTo: '/',
        },
        {
          label: 'My Channel',
          iconName: 'fas fa-film',
          linkTo: getChannelBaseUrl(userId, true),
          isDisabled: !showLeftBar && !userId,
        },
        // {
        //   label: 'History',
        //   iconName: 'fas fa-history',
        //   linkTo: '/history'
        // },
        // {
        //   label: 'Friends Activity',
        //   iconName: 'fas fa-street-view',
        //   linkTo: '/friends-activity'
        // },
        // {
        //   label: 'Suggestions',
        //   iconName: 'fas fa-thumbs-up',
        //   linkTo: '/suggestions'
        // }
      ]
    : [
        {
          label: 'Home',
          iconName: 'fas fa-home',
          linkTo: '/',
        },
        // {
        //   label: 'History',
        //   iconName: 'fas fa-history',
        //   linkTo: '/history'
        // },
        // {
        //   label: 'Friends Activity',
        //   iconName: 'fas fa-street-view',
        //   linkTo: '/friends-activity'
        // },
        // {
        //   label: 'Suggestions',
        //   iconName: 'fas fa-thumbs-up',
        //   linkTo: '/suggestions'
        // }
      ];

  options = options || defaultOptions;

  return (
    <WithSideBarWrap>
      <FeedbacksBar />
      <TopBar
        location={location}
        isLoggedIn={isLoggedIn}
        isMobile={isMobile}
        forAdmin={forAdmin}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        setLocalIsLeftBarOpened={setLocalIsLeftBarOpened}
        isChatMinimized={isChatMinimized}
      />
      <Body>
        {!isMobile && (isLoggedIn || showLeftBar) && (
          <LeftSideBar
            isLeftBarOpened={isLeftBarOpened}
            isLoggedIn={isLoggedIn || showLeftBar}
            isMobile={isMobile}
            options={options}
          />
        )}
        <PageContent
          isMobileDevice={isMobileDevice()}
          isMobile={isMobile}
          // ref={callbackRef}
        >
          {children}
        </PageContent>
      </Body>
      {showBarInMobile && isMobile && (isLoggedIn || showLeftBar) && (
        <BottomBar isLoggedIn={isLoggedIn || showLeftBar} isMobile={isMobile} options={options} />
      )}
    </WithSideBarWrap>
  );
}

WithSideBar.propTypes = {
  location: PropTypes.object,
  options: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  forAdmin: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  showBarInMobile: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  setLocalIsLeftBarOpened: PropTypes.func,
};

export default React.memo(React.memo(WithSideBar));
