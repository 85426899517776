import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {DropDownItemCss, ScaleFontMenuCss} from 'styles/common';

import Icon from 'components/Icon';

const ActionWrap = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  white-space: nowrap;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  color: ${({isDelete, isActive, theme: {colors}}) => {
    if (isDelete) {
      return colors.error;
    }
    if (isActive) {
      return colors.due;
    }

    return colors.darkGrey;
  }};

  &:hover {
    color: ${({theme: {colors}}) => colors.secondary};
  }

  ${({disabled}) => disabled ? css`opacity: 0.6;` : ''}
  ${DropDownItemCss};
  ${ScaleFontMenuCss};
`;

const ActionIcon = styled(Icon)`
  margin-right: 5px;
`;

const ActionLabel = styled.div`
  font-weight: bold;
`;

function Action({
                  isActive, isDelete, iconName, label, onClick = () => {
  }, ...props
                }) {
  return (
    <ActionWrap
      isActive={isActive}
      isDelete={isDelete}
      onClick={onClick}
      {...props}
    >
      {
        iconName &&
        <ActionIcon iconName={iconName}/>
      }
      <ActionLabel>{label}</ActionLabel>
    </ActionWrap>
  );
}

Action.propTypes = {
  isActive: PropTypes.bool,
  isDelete: PropTypes.bool,
  iconName: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default Action;