import React, { Suspense, lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';

import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import {
  getUserEmail,
  getUserId /*, getUserZoomConnected*/,
} from '../../reduxUtils/user/selectors';
import { getUserFullName, getUserAvatarThumbnail } from '../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
} from '../../reduxUtils/browserSettings/selectors';
import { getRates } from '../../reduxUtils/browserSettings/thunks';

import WithSideBar from 'layouts/WithSideBar';

import { getAccessControl } from '../../reduxUtils/accessControl/selectors';
import { getEventRegistration } from '../../reduxUtils/userEventRegistrations/selectors';
import {
  addEventRegistration,
  createEventRegistration,
  deleteEventRegistration,
  setEventRegistrations,
} from '../../reduxUtils/userEventRegistrations/thunks';

import { refundContent } from '../../reduxUtils/user/thunks';

import Fallback from 'components/Fallback';
import {
  getIsChatDetach,
  getIsChatMinimized,
  getIsChatShown,
} from '../../reduxUtils/chat/selectors';
import { setChatMinimizeAction } from '../../reduxUtils/chat/actions';

const Event = lazy(() => import('./Event'));

function EventWrap({
  navigate,
  match,
  location,
  isLoggedIn,
  isMobile,
  isLeftBarOpened,
  userId,
  //  zoomConnected,
  userSettings,
  fullName,
  userEmail,
  avatar,
  accessControls,
  eventRegistrations,
  addEventRegistration,
  createEventRegistration,
  deleteEventRegistration,
  refundContent,
  setEventRegistrations,
  isChatDetach,
  isChatMinimized,
  setChatMinimizeAction,
}) {
  const [bodyRef, setBodyRef] = useState();

  useEffect(() => {
    if (isMobile) {
      setChatMinimizeAction(false);
    }
  }, []); // eslint-disable-line

  return (
    <WithSideBar
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      isChatMinimized={isChatMinimized}
      bodyRef={bodyRef}
      showBarInMobile={false}
    >
      <Suspense fallback={<Fallback />}>
        <Event
          navigate={navigate}
          match={match}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          userId={userId}
          //zoomConnected={zoomConnected}
          userSettings={userSettings}
          fullName={fullName}
          userEmail={userEmail}
          avatar={avatar}
          accessControls={accessControls}
          eventRegistrations={eventRegistrations}
          addEventRegistration={addEventRegistration}
          createEventRegistration={createEventRegistration}
          deleteEventRegistration={deleteEventRegistration}
          refundContent={refundContent}
          setEventRegistrations={setEventRegistrations}
          isChatMinimized={isChatMinimized}
          isChatDetach={isChatDetach}
          bodyRef={setBodyRef}
        />
      </Suspense>
    </WithSideBar>
  );
}

EventWrap.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  //  zoomConnected: PropTypes.bool,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  userEmail: PropTypes.string,
  avatar: PropTypes.string,
  accessControls: PropTypes.array,
  eventRegistrations: PropTypes.array,
  addEventRegistration: PropTypes.func,
  createEventRegistration: PropTypes.func,
  deleteEventRegistration: PropTypes.func,
  refundContent: PropTypes.func,
  setEventRegistrations: PropTypes.func,
  isChatShown: PropTypes.bool,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state, { match }) {
  let params = new URLSearchParams(match.search);
  const eventId = params.get('id');

  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    //  zoomConnected: getUserZoomConnected(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    accessControls: getAccessControl(state),
    userEmail: getUserEmail(state),
    eventRegistrations: getEventRegistration(state, eventId),
    isChatShown: getIsChatShown(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRates,
      setEventRegistrations,
      addEventRegistration,
      createEventRegistration,
      deleteEventRegistration,
      refundContent,
      setChatMinimizeAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventWrap);
