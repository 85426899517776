import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getUserPrice } from 'utilities/fx';

import theme from 'styles/theme';
import {
  PriceLiveLabelReduceFontSizeCss,
  PriceLiveLabelFontSizeCss,
  scale,
  UserSelectNoneCss,
} from 'styles/common';
import { compactNumber, fixNumber } from 'utilities/general';

const PriceLabelWrap = styled.div`
  white-space: nowrap;
  ${({ asText }) =>
    asText
      ? css`
          padding-left: 1.5em;
          font-weight: 600;
          line-height: normal;
          color: ${({ theme: { colors } }) => colors.grayNew};
          //letter-spacing: 0.04em;
          ${scale([
            ['default', [['font-size', theme.typo.sizes.h5]]],
            ['560px', [['font-size', theme.typo.sizes.body2]]],
            ['768px', [['font-size', theme.typo.sizes.body2]]],
            ['992px', [['font-size', theme.typo.sizes.body]]],
            ['1200px', [['font-size', theme.typo.sizes.body]]],
            ['1400px', [['font-size', theme.typo.sizes.small]]],
          ])}
        `
      : css`
          margin-left: 0.4em;

          &:first-child {
            margin-left: 0;
          }

          ${({ reduce_fonts }) =>
            reduce_fonts ? PriceLiveLabelReduceFontSizeCss : PriceLiveLabelFontSizeCss}
          ${UserSelectNoneCss};
          font-weight: bold;
          color: ${({ theme: { colors } }) => colors.purple};
          background-color: ${({ theme: { colors } }) => colors.paleGrey};
          display: flex;
          justify-content: center;
          align-items: flex-end;
          text-align: center;
          border-radius: 0 0 5px 5px;
        `};
`;

const Label = styled.div`
  ${({ asText }) =>
    asText
      ? css``
      : css`
          margin-bottom: 5px;
        `};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: normal;
`;

const Symbol = styled.span`
  font-family: ${theme.typo.fontFamily.montserrat};
  margin-right: 0.1em;
`;

const Price = styled.span``;

function PriceLabel({
  price = 0,
  currency = 'USD',
  userCurrency = 'USD',
  isFree,
  compact = true,
  asText = false,
  ...props
}) {
  function formatPrice({ symbol, price }) {
    let fixedPrice = price;
    if (compact && price >= 10000) {
      fixedPrice = compactNumber(price);
    }
    if (!compact) {
      fixedPrice = fixNumber(price);
    }

    return (
      <>
        <Symbol>{symbol}</Symbol>
        <Price>{fixedPrice}</Price>
      </>
    );
  }

  let priceLabel;
  let userPrice = {};
  if (isFree || !price) {
    priceLabel = 'FREE';
  } else {
    userPrice = getUserPrice(price, currency, userCurrency);
    priceLabel = formatPrice(userPrice);
  }

  return (
    <PriceLabelWrap asText={+asText} reduce_fonts={+(userPrice.price >= 1000)} {...props}>
      <Label asText={+asText}>{priceLabel}</Label>
    </PriceLabelWrap>
  );
}

PriceLabel.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  userCurrency: PropTypes.string,
  isFree: PropTypes.bool,
  asText: PropTypes.bool,
};

export default PriceLabel;
