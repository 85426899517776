import {createAction} from 'redux-actions';
import {
  SET_USER_FAVORITES,
  ADD_USER_FAVORITE,
  DELETE_USER_FAVORITE
} from './types';


export const setUserFavorites = createAction(SET_USER_FAVORITES);
export const addUserFavorite = createAction(ADD_USER_FAVORITE);
export const deleteUserFavorite = createAction(DELETE_USER_FAVORITE);