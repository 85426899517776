import {errorHandler} from 'utilities/services';
import {CHAT_ROOMS_LABELS} from "utilities/chat.utils";
import pollsDeletePoll from "websockets/sendActions/polls/polls.delete-poll.action";
import {getChatInstance} from "utilities/chat";
import pollsUpdatePollVisibility from "websockets/sendActions/polls/polls.update-poll-visibility.action";
import pollsSendVote from "websockets/sendActions/polls/polls.send-vote.action";

const chat = getChatInstance();

export function deletePoll(id) {
  const roomInstance = chat.getChatRoom(CHAT_ROOMS_LABELS.POLLS.key);
  pollsDeletePoll(roomInstance.room, {id}).then(res => {
  }).catch(err => {
    errorHandler(err);
  })
}


export function updatePollVisibility(id, status) {
  const roomInstance = chat.getChatRoom(CHAT_ROOMS_LABELS.POLLS.key);
  pollsUpdatePollVisibility(roomInstance.room, {id, status}).then(res => {
  }).catch(err => {
    errorHandler(err);
  })
}


export function sendPollVote(data) {
  const roomInstance = chat.getChatRoom(CHAT_ROOMS_LABELS.POLLS.key);
  pollsSendVote(roomInstance.room, data).then(res => {
  }).catch(err => {
    errorHandler(err);
  })
}
