import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Outlet, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider as ReduxProvider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import GlobalStyle from './styles/global-styles';
import theme from './styles/theme';
import App from './containers/App';
import configureStore from './reduxUtils/configureStore';
import { setStore } from 'utilities/store';
import websockets from 'config/websockets';

const store = configureStore();
setStore(store);
websockets();

const container = document.getElementById('root');
createRoot(container).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
      <GlobalStyle />
    </ReduxProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
