import {createAction} from 'redux-actions';
import {
  SET_USER_EVENT_REGISTRATIONS,
  ADD_USER_EVENT_REGISTRATION,
  ADD_USER_EVENTS_REGISTRATION,
  DELETE_USER_EVENT_REGISTRATION,
  DELETE_USER_EVENTS_REGISTRATION,
} from './types';


export const setUserEventRegistrations = createAction(SET_USER_EVENT_REGISTRATIONS);
export const addUserEventRegistration = createAction(ADD_USER_EVENT_REGISTRATION);
export const addUserEventsRegistration = createAction(ADD_USER_EVENTS_REGISTRATION);
export const deleteUserEventRegistration = createAction(DELETE_USER_EVENT_REGISTRATION);
export const deleteUserEventsRegistration = createAction(DELETE_USER_EVENTS_REGISTRATION);