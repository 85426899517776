export class Drag {
  isDown = false;
  startY = 0;
  startX = 0;
  top = 0;
  left = 0;
  isDrag = false;
  el = null;
  onMouseLeaveOn = true;

  constructor(horizontal = true, vertical = true, speed = 1, onMouseLeaveOn = true) {
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.speed = speed;
    this.horizontal = horizontal;
    this.vertical = vertical;
    this.onMouseLeaveOn = onMouseLeaveOn;
  }

  register(useRefObj) {
    if (!useRefObj || !useRefObj.current)
      return;
    this.el = useRefObj.current;
    this.el.addEventListener('mousedown', this.onMouseDown, {passive: false});
  }

  deRegister() {
    if (this.el)
      this.el.removeEventListener('mousedown', this.onMouseDown, {passive: false});
  }

  onMouseMove(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.isDown) return;

    if (this.vertical) {// How far the mouse has been moved
      const dy = e.clientY - this.startY;
      this.isDrag = Math.abs(dy) > 10;
      // Scroll the element
      this.el.scrollTop = (this.top - dy) * this.speed;
    }
    if (this.horizontal) {// How far the mouse has been moved
      const dx = e.clientX - this.startX;
      this.isDrag = Math.abs(dx) > 10;
      // Scroll the element
      this.el.scrollLeft = (this.left - dx) * this.speed;
    }
  }

  onMouseUp(e) {
    // const el = sectionRef.current;
    // e.preventDefault();
    this.el.removeEventListener('mousemove', this.onMouseMove, {passive: false});
    this.el.removeEventListener('mouseup', this.onMouseUp, {passive: false});
    if (this.onMouseLeaveOn)
      this.el.removeEventListener('mouseleave', this.onMouseUp, {passive: false});
    setTimeout(() => this.isDrag = false, 50);
  }

  onMouseDown(e) {
    e.stopPropagation();
    e.preventDefault();
    if (!this.el)
      return;

    this.isDown = true;
    if (this.vertical) {
      this.startY = e.clientY;
      this.top = this.el.scrollTop;
    }

    if (this.horizontal) {
      this.startX = e.clientX;
      this.left = this.el.scrollLeft;
    }

    this.el.addEventListener('mousemove', this.onMouseMove, {passive: false});
    this.el.addEventListener('mouseup', this.onMouseUp, {passive: false});
    if (this.onMouseLeaveOn) {
      this.el.addEventListener('mouseleave', this.onMouseUp, {passive: false});
    }
  }

}

export function setQueryParamsWithoutPageReload(key, value) {
  const url = new URL(window.location);
  if (!value) {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, value);
  }
  window.history.pushState(null, '', url.toString());
}
