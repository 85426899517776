import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BodyFontSizeCss, ContactSupport, ModalSmallFontSizeCss } from 'styles/common';
import { errorHandler } from 'utilities/services';
import { login } from '../../../reduxUtils/auth/thunks';
import StyledTextInput from 'components/Inputs/StyledTextInput';
import PasswordInput from 'components/Inputs/PasswordInput';
import Button from 'components/Button';
import { AL_EVENT_ACTION, AL_EVENT_STATUS, alLogin } from 'utilities/analytics';

const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginForm = styled.form`
  /* width:fit-content; */
  /* width: 100%; */
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 1em;
`;

const ButtonInner = styled(Button)`
  ${BodyFontSizeCss};
`;

const FormLabel = styled.div`
  //margin-top: -0.6em;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayNew};
  margin-bottom: 0.2em;
  ${ModalSmallFontSizeCss};
`;

const ContentWrap = styled.div`
  > div {
    margin-bottom: 10px;
  }
`;

function Login({ setShow, login, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  function isEmailValid() {
    const isValid = !!email;
    if (!email) {
      setEmailError('Email cannot be empty');
    } else {
      setEmailError('');
    }
    return isValid;
  }

  function isPasswordValid() {
    let isValid = !!password;
    if (!isValid) {
      setPasswordError('Password cannot be empty');
    } else {
      setPasswordError('');
    }
    return isValid;
  }

  function isFormValid() {
    const emailIsValid = isEmailValid();
    const passwordIsValid = isPasswordValid();
    return emailIsValid && passwordIsValid;
  }

  function checkValidation() {
    return !!email && !!password;
  }

  let disabledLoginButton = !checkValidation();

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (isFormValid()) {
      const formData = {
        email,
        password,
      };

      setIsLoading(true);
      let hasError;
      let callPushToDataLayer = true;
      let userId;

      login(formData)
        .then((res) => {
          userId = res.accessToken.UserId;
          setShow(false);
        })
        .catch((err) => {
          hasError = err.message;
          if (err.statusCode === 409) {
            err.message = 'Wrong email or password';
            callPushToDataLayer = false;
          }

          errorHandler(err);
          setIsLoading(false);
        })
        .finally(() => {
          callPushToDataLayer &&
            alLogin({
              eventAction: AL_EVENT_ACTION.submit,
              eventStatus: hasError ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
              eventLabel: 'login',
              error: hasError,
              userId,
            });
        });
    }
  }

  return (
    <LoginWrap>
      <LoginForm onSubmit={onSubmit}>
        <ContentWrap>
          <StyledTextInput
            placeholder="Email address"
            value={email}
            name="email"
            type="email"
            onChange={({ target }) => setEmail(target.value)}
            error={emailError}
          />

          <PasswordInput
            placeholder="Password"
            value={password}
            name="password"
            onChange={({ target }) => setPassword(target.value)}
            error={passwordError}
          />
        </ContentWrap>

        <FormLabel>
          <Link target="_blank" rel="noopener noreferrer" to="/auth/forgot-password">
            Forgot Password?
          </Link>
        </FormLabel>
        <div>
          <ContactSupport target="_blank" rel="noopener noreferrer" to={'/help'}>
            Contact Support
          </ContactSupport>
        </div>

        <ButtonWrap>
          <ButtonInner
            isLoading={isLoading}
            backIconName="fas fa-sign-in-alt"
            label="Sign In"
            buttonStyle={'generalPurple'}
            disabled={disabledLoginButton}
          />
        </ButtonWrap>
      </LoginForm>
    </LoginWrap>
  );
}

Login.propTypes = {
  setShow: PropTypes.func,
  login: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Login);
