import {hasPermissions} from '../../index';

export default function canViewWorkshopContent(workshopId, accessControls) {
  const permissionFunction = 'workshopCanViewContent';
  const object = {
    type: 'workshop',
    id: workshopId,
    key: 'toWorkshopId'
  };

  return hasPermissions(object, accessControls, permissionFunction);
}