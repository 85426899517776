import React, {useEffect, useMemo, useReducer, useRef} from "react";
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {getNowUTCWithLocal} from "utilities/date";
import {throttle} from "lodash";
import {
  AuthorName,
  Body,
  ContainerCss,
  InnerMessageWrap,
  MessageWrapper,
  StyledAvatarWrap, TopHeaderMessage
} from "components/Chat/components/JSXElements";


const MessageContainer = styled.div`
  ${ContainerCss}
  .youSender {
    background-color: cornflowerblue;
    color: white;
  }

`;

const Message = styled.div`
  height: auto;
  display: flex;
`;


const MessageContent = styled.div`
  word-break: break-word;
  white-space: pre-line;
  width: auto;
  height: auto;
  padding: 5px;
  border-radius: 0 8px 8px 8px;
  color: white;
  display: flex;
  align-items: center;

  overflow-wrap: break-word;

  ${({you}) =>
    !you
      ? css`
        background-color: #ededed;
        color: ${({theme: {colors}}) => colors.darkGrey};
      `
      : css`
        background-color: cornflowerblue;
        //background-color: #7269ef;
      `
  }
`;

const MessageMeta = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
`;

const Time = styled.span`
`;

const updateInterval = 60 * 1000; //every 1 minute
let isBottom = true;

function ChatMessageContainer({
                                userId,
                                messageList,
                              }) {

  const messagesEndRef = useRef(null)
  const bodyRef = useRef(null)
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  function onScroll(e) {
    let diff = (e.target.scrollHeight - e.target.clientHeight) - e.target.scrollTop;
    isBottom = Math.abs(diff) <= 10;
  }

  const throttledEventHandler = useMemo(
    (e) => throttle((e) => onScroll(e), 50)
    , []);// eslint-disable-line
  const el = bodyRef.current;

  useEffect(() => {
    if (el) {
      el.addEventListener('scroll', throttledEventHandler, true);
      return () => {
        el.removeEventListener('scroll', throttledEventHandler, true);
      };
    }
  }, [el, throttledEventHandler]);

  useEffect(() => {
    if (isBottom) {
      messagesEndRef.current?.scrollIntoView({/*behavior: "smooth",*/ block: "end", inline: "nearest"});
    }
  }, [messageList]);

  useEffect(() => {
    const interval = setInterval(() => forceUpdate(), updateInterval);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const now = getNowUTCWithLocal();

  return (
    <Body ref={bodyRef}>
      <MessageContainer>
        <div ref={messagesEndRef}>
          {
            (messageList || []).map((messageContent, index) => {
              if (!messageContent) {
                return null;
              }
              const timeElapsed = now.to(messageContent.time).replace('_', '');
              return (
                <MessageWrapper key={index}>

                  <StyledAvatarWrap
                    name={messageContent.userName}
                    url={messageContent.avatar}
                  />
                  <InnerMessageWrap>

                    <TopHeaderMessage>
                      <AuthorName>
                        {messageContent.userName}
                      </AuthorName>
                      <Time>{timeElapsed}</Time>
                    </TopHeaderMessage>

                    <Message>
                      <div>
                        <MessageContent
                          className={`${userId === messageContent.userId ? 'youSender' : ''}`}
                          you={userId === messageContent.userId}
                          dir={'auto'}
                        >
                          {messageContent.message}
                        </MessageContent>
                        <MessageMeta>
                        </MessageMeta>
                      </div>
                    </Message>
                  </InnerMessageWrap>

                </MessageWrapper>
              )
            })
          }
        </div>

      </MessageContainer>
    </Body>
  );
}

ChatMessageContainer.propTypes = {
  userName: PropTypes.string
};

export default (React.memo(ChatMessageContainer));

