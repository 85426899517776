import adminCanEdit from './admin-can-edit';
import eventCanView from './event-can-view';
import eventCanViewOccurrence from './event-can-view-occurrence';
import canViewPayouts from './can-view-payouts';
import eventCreateLiveParticipantsLimit from './event-create-live-participants-limit';
import eventCreateLiveDuration from './event-create-live-duration';
import eventCreateRecordDuration from './event-create-record-duration';
import isAnAdmin from './is-an-admin';
import workshopCanViewContent from './workshop-can-view-content';
import workshopCreateParticipantsLimit from './workshop-create-participants-limit';

const toExport = {
  adminCanEdit,
  eventCanView,
  eventCanViewOccurrence,
  canViewPayouts,
  eventCreateLiveParticipantsLimit,
  eventCreateLiveDuration,
  eventCreateRecordDuration,
  isAnAdmin,
  workshopCanViewContent,
  workshopCreateParticipantsLimit
};

export default toExport;