import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PriceLabel from 'components/PriceLabel';
import {BodyFontSizeCss, COVER_RATIO, COVER_ASPECT_RATIO_INT, LabelFontSizeCss, TinyFontSizeCss} from 'styles/common';
import CoverPhoto from 'components/CoverPhoto';

const ContentDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: max-content;

  background: #FFFFFF;
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.15);
`;

const Header = styled.div`
  margin: .5em auto;
  text-transform: uppercase;
  color: ${({theme: {colors}}) => colors.purple};
  font-weight: bold;
  ${TinyFontSizeCss};
  white-space: pre-line;
  text-align: center;
`;

const SubHeader = styled.div`
  color: ${({theme: {colors}}) => colors.grayNew};
`;

const Title = styled.div`
  padding: 0 1.5em;
  color: ${({theme: {colors}}) => colors.grayNew};
  ${LabelFontSizeCss};
  font-weight: 500;
`;

const TitleCoverPhotoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: normal;
  margin: 0 5px;
  padding-bottom: 20px;
`;

const PriceTotalWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  margin: 0 5px;
`;

const Total = styled.div`
  font-weight: 600;
  color: ${({theme: {colors}}) => colors.grayNew};
  ${BodyFontSizeCss};
`;

const CoverPhotoContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 60px;
  min-width: 60px;
  min-height: calc(60px * ${COVER_ASPECT_RATIO_INT});
  height: calc(60px * ${COVER_ASPECT_RATIO_INT});
`;

const CoverPhotoDummy = styled.div`
  margin-top: ${COVER_RATIO};
`;

const PriceLabelWrap = styled(PriceLabel)`
  color: ${({theme: {colors}}) => colors.darkGrey};
`;

const CoverPhotoImage = styled(CoverPhoto)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.5rem;
  cursor: auto;
  border: 1px solid ${({theme: {colors}}) => colors.lightGrey};

`;

const Separator = styled.div`
  border-bottom: 2px solid ${({theme: {colors}}) => colors.lightGrey};
`;

function ContentDetails({
                          header,
                          subHeader,
                          title,
                          coverPhoto,
                          price,
                          currency,
                          userCurrency,
                        }) {

  return (
    <ContentDetailsWrap>
      <Header>
        {header}
        {
          subHeader &&
          <SubHeader>
            {subHeader}
          </SubHeader>
        }
      </Header>


      <TitleCoverPhotoWrap>
        <CoverPhotoContainer>
          <CoverPhotoDummy />
          <CoverPhotoImage
            url={coverPhoto}
          />
        </CoverPhotoContainer>
        <Title dir='auto'>
          {title}
        </Title>
      </TitleCoverPhotoWrap>

      <Separator />

      <PriceTotalWrap>
        <Total>
          Total
        </Total>
        <PriceLabelWrap
          isFree={false}
          asText={true}
          price={price}
          currency={currency}
          userCurrency={userCurrency}
          compact={false}
        />
      </PriceTotalWrap>
    </ContentDetailsWrap>
  );
}

ContentDetails.propTypes =
  {
    userId: PropTypes.number,
    userSettings: PropTypes.object,
    fullName: PropTypes.string,
    avatar: PropTypes.string,
    onSelect: PropTypes.func,
    updateUserSettings: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    header: PropTypes.string,
    subHeader: PropTypes.string,
  };


export default React.memo(ContentDetails);