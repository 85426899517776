import {getRouter} from 'config/websockets';

function joinRoomPromisified(socket, toRoom, data) {
  return new Promise((resolve, reject) => {
    socket.emit('route', 'chat', 'joinUsersRoom', [toRoom, data], (res) => {
      if (res.status === 'ok') {
        resolve(res.data);
      } else {
        reject(res.error);
      }
    });
  });
}

export default async function chatJoinUsersRoom(toRoom, data) {
  let socketRouter = getRouter();
  return joinRoomPromisified(socketRouter.socket, toRoom, data);
};
