import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import {OptionWrapCss} from "components/Chat/components/PollsContainer/components/JSXElements";
import OptionComponent from "../OptionComponent";

import {sendPollVote} from "../../../../../../../../reduxUtils/polls/thunks";
import {getRemainingTimeToVote} from "utilities/polls.const";
import {errorHandler} from "utilities/services";

const Wrap = styled.div`
`;

const OptionStyled = styled(OptionComponent)`
  ${OptionWrapCss};
`;

function Options({
                   className,
                   PollId,
                   PollOptions,
                   totalVotes = 0,
                   disabled,
                   publishedAt,
                   timerInSec,
                   userSelected
                 }) {
  const [selected, setSelected] = useState(userSelected);

  function onClickOption(id) {
    const {voteTimePassed} = getRemainingTimeToVote(publishedAt, timerInSec);
    if (voteTimePassed) {
      errorHandler('voting time has passed');
      return;
    }
    setSelected(id);
    sendPollVote({
      PollId: PollId,
      PollOptionId: id
    })
  }

  return (
    <Wrap className={className}>
      {
        PollOptions.map((option) => {
          return (
            <OptionStyled
              key={option.id}
              value={option.text}
              image={option.img}
              votes={option.PollOptionVotes?.length}
              totalVotes={totalVotes}
              onClick={() => onClickOption(option.id)}
              disabled={disabled}
              isSelected={option.id === selected}
            />
          )
        })
      }
    </Wrap>
  );
}


Options.propTypes = {
  className: PropTypes.string,
  PollId: PropTypes.number,
  PollOptions: PropTypes.array,
  updatePollOptionAction: PropTypes.func,
  disabled: PropTypes.bool,
};
export default (React.memo(Options));