import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  PriceLiveLabelReduceFontSizeCss,
  PriceLiveLabelFontSizeCss,
  UserSelectNoneCss,
} from 'styles/common';

const LabelWrap = styled.div`
  letter-spacing: 0.06em;

  ${({ reduce_fonts }) =>
    reduce_fonts ? PriceLiveLabelReduceFontSizeCss : PriceLiveLabelFontSizeCss};

  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.white};
  background-color: ${({ theme: { colors } }) => colors.paleRed};
  // border: 1px solid ${({ theme: { colors } }) => colors.paleError};
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  word-spacing: 100px;
  ${UserSelectNoneCss};
`;

const Label = styled.div`
  ${({ reduce_fonts }) =>
    reduce_fonts
      ? css`
          margin: auto;
        `
      : css`
          margin-bottom: 5px;
        `};
`;

function LiveLabel({ label = 'LIVE', reduceFontSize = false, ...props }) {
  return (
    <LabelWrap reduce_fonts={+reduceFontSize} {...props}>
      <Label reduce_fonts={+reduceFontSize}>{label}</Label>
    </LabelWrap>
  );
}

LiveLabel.propTypes = {
  className: PropTypes.string,
};
export default LiveLabel;
