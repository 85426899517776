import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  UPDATE_USER_SETTINGS
} from './types';

import {
  LOGOUT
} from '../auth/types';

let initialState = {
  language: '',
  timezone: '',
  currency: 'USD',
  notifyNewSub: false,
  addToCalendar: false,
  addToCalendarPopUp: 'none',
  notifySubNewContent: false,
  notifyFriendActivity: false,
  reportMyActivity: false,
  showSuggestions: false
};

export default handleActions({
  [UPDATE_USER_SETTINGS]: (state, {payload}) => {
    return {...state, ...pick(payload, Object.keys(initialState))};
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
