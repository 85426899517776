import {handleActions} from 'redux-actions';

import {
  ADD_FEEDBACK,
  REMOVE_FEEDBACK
} from './types';

import {
  LOGOUT
} from '../auth/types';


let initialState = [];
const defaultFeedback = {
  name: '',
  type: 'general',
  content: '',
  noClose: false
};

export default handleActions({
  [ADD_FEEDBACK]: (state, {payload}) => {
    const {name} = payload;
    const newState = [...state];
    const foundIndex = newState.findIndex((feedback) => feedback.name === name);

    if (foundIndex === -1) {
      newState.push({...defaultFeedback, ...payload});
      return newState;
    }
    return state;
  },
  [REMOVE_FEEDBACK]: (state, {payload}) => {
    const name = payload;
    const newState = [...state];
    const foundIndex = newState.findIndex((feedback) => feedback.name === name);

    if (foundIndex > -1) {
      newState.splice(foundIndex, 1);
      return newState;
    }
    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, initialState);