import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import RoundCheckbox from 'components/Inputs/RoundCheckbox';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import theme from "styles/theme";
import {LabelFontSizeCss, scale} from "styles/common";

const PaymentMethodWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  white-space: nowrap;
`;

const PaymentMethodCard = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-right: 5rem;
`;

const PaymentMethodCardSelect = styled.div`
  display: flex;
  margin-right: 10px;
`;

const PaymentMethodCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
`;

const CardNumber = styled.div`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]],
    ['1600px', [['font-size', theme.typo.sizes.base]]],
  ])};
  font-weight: 600;
  text-transform: uppercase;
`;

const CardDefault = styled.div`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body]]],
    ['560px', [['font-size', theme.typo.sizes.small]]],
    ['768px', [['font-size', theme.typo.sizes.small]]],
    ['992px', [['font-size', theme.typo.sizes.base]]],
    ['1200px', [['font-size', theme.typo.sizes.base]]],
    ['1400px', [['font-size', theme.typo.sizes.tiny]]],
    ['1600px', [['font-size', theme.typo.sizes.tiny]]],
  ])};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.secondary};
  }
`;

const CardDefaultSet = styled.div`
`;

const PaymentMethodActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  ${LabelFontSizeCss};

  &:hover,
  &:focus {
    color: ${({theme: {colors}}) => colors.secondary};
  }
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

function PaymentMethod(
  {
    className,
    brand,
    last4,
    isSelected,
    isDefault,
    onSelect,
    onDelete,
    onSetDefault,
    ...props
  }
) {
  const [isLoading, setIsLoading] = useState(false);

  function handleDelete() {
    if (!isLoading) {
      setIsLoading(true);
      onDelete();
    }
  }

  function handleSetDefault(e) {
    e.stopPropagation();
    if (!isLoading) {
      setIsLoading(true);
      onSetDefault(e)
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <PaymentMethodWrap>
      <PaymentMethodCard
        onClick={onSelect}
      >
        <PaymentMethodCardSelect>
          <RoundCheckbox onClick={onSelect} value={isSelected}/>
        </PaymentMethodCardSelect>
        <PaymentMethodCardInfo>
          <CardNumber>
            {brand} {'****'} {last4}
          </CardNumber>
          <CardDefault>
            {
              isDefault
                ? <>Default Payment</>
                : <CardDefaultSet onClick={handleSetDefault}>Set Default</CardDefaultSet>
            }
          </CardDefault>
        </PaymentMethodCardInfo>

      </PaymentMethodCard>
      <PaymentMethodActions>
        {
          isLoading
            ? <StyledSpinner/>
            :
            (
              <StyledIcon
                onClick={handleDelete}
                className="fas fa-trash"
              />
            )
        }
      </PaymentMethodActions>
    </PaymentMethodWrap>
  );
}

PaymentMethod.propTypes = {
  id: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isDefault: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetDefault: PropTypes.func.isRequired,
};

export default PaymentMethod;