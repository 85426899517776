import styled from "styled-components";
import {scale} from "styles/common";
import theme from 'styles/theme';
import SelectInput from "components/Inputs/SelectInput";

export const Header = styled.div`
  font-size: ${({theme: {typo}}) => typo.sizes.h3};
  color: ${({theme: {colors}}) => colors.darkGrey};
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.7em;
`;

export const SubHeader = styled.div`
  margin-bottom: 0.5em;
  font-size: ${({theme: {typo}}) => typo.sizes.h5};
  color: ${({theme: {colors}}) => colors.darkGrey};
`;

export const SubTitle = styled.div`
  margin-top: 0.7em;
  margin-bottom: 0.5em;
  color: ${({theme: {colors}}) => colors.darkGrey};
  font-weight: 500;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h4]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body2]]],
    ['1600px', [['font-size', theme.typo.sizes.body2]]],
  ])}
  
`;

export const SelectInputWrap = styled(SelectInput)`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]],
    ['1600px', [['font-size', theme.typo.sizes.small]]],
  ])}
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${scale([['768px', [['width', `auto`]]]])}
  font-size: ${({theme: {typo}}) => typo.sizes.body2};
`;