import {setLogin, setLogout} from '../reduxUtils/auth/actions';
import {getStore} from './store';
import {
  AUTH_KEY,
  INCOMPLETE_EVENT_KEY,
  INCOMPLETE_WORKSHOP_EVENT_KEY,
  INCOMPLETE_WORKSHOP_KEY
} from 'config/constants';
import {logoutFromGoogle} from "utilities/gapi";
import {getData, setData} from "utilities/localStorage";

export function getAuth() {
  return JSON.parse(localStorage.getItem(AUTH_KEY)) || {};
}

export function setAuth(auth) {
  return localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
}

export function clearLocalStorage() {
  const unfinishedEvent = getData(INCOMPLETE_EVENT_KEY);
  const unfinishedWorkshopEvent = getData(INCOMPLETE_WORKSHOP_EVENT_KEY);
  const unfinishedWorkshop = getData(INCOMPLETE_WORKSHOP_KEY);

  localStorage.clear();

  unfinishedEvent && setData(INCOMPLETE_EVENT_KEY, unfinishedEvent);
  unfinishedWorkshopEvent && setData(INCOMPLETE_WORKSHOP_EVENT_KEY, unfinishedWorkshopEvent);
  unfinishedWorkshop && setData(INCOMPLETE_WORKSHOP_KEY, unfinishedWorkshop);


}

export function getAccessToken() {
  return getAuth().token;
}

export function isLoggedIn() {
  return !!getAccessToken();
}

export function login(accessToken) {
  const {dispatch} = getStore();
  setAuth(accessToken);
  dispatch(setLogin(accessToken));
}


export function logout() {
  logoutFromGoogle();
  const {dispatch} = getStore();
  clearLocalStorage();
  dispatch(setLogout());
}