import {lighten, transparentize, darken, rgba} from 'polished'; //  , darken

export const theme = {
  colors: {
    primary: '#004BAB',
    secondary: '#53A9FF',
    secondaryLight: '#5FA7F1',
    paleSecondary: '#f1f8ff',
    tertiary: '#ef6f95',
    paleTertiary: lighten(0.3, '#ef6f95'),
    tertiaryDsbl: '#E0C7D7',
    pinkFlamingo: '#ff61f1',
    pinkFlamingoLight: '#f18deb',
    black: '#303133',
    white: '#ffffff',
    transparent: rgba(255, 255, 255, 0.7),
    yellowGold: '#E2C21E',
    grey: '#929599',
    lightGrey: '#D9DDE4',
    darkGrey: '#4E5055',
    highlightGrey: lighten(0.4, '#D9DDE4'),
    primary05: lighten(0.5, '#004BAB'), //#004BA8 - check that its correct
    due: '#efb74a',
    paleGrey: '#F9FAFC',
    disabled: '#E1E1E1',
    paleBlue: '#F3F5F8',
    notify: '#0568d6',
    tanNotify: darken(0.2, '#0568d6'),
    paleNotify: lighten(0.2, '#0568d6'),
    success: '#4bd66d',
    tanSuccess: darken(0.2, '#4bd66d'),
    paleSuccess: lighten(0.2, '#4bd66d'),
    warning: '#FCEB3B',
    tanWarning: darken(0.2, '#FCEB3B'),
    paleWarning: lighten(0.2, '#FCEB3B'),
    error: '#dc3545',
    tanError: darken(0.2, '#E6173E'),
    paleError: lighten(0.2, '#E6173E'),
    grayNew: '#677585',
    grayLighten: lighten(0.1, '#677585'),
    grayLightenX2: lighten(0.2, '#677585'),
    grayLightenX3: lighten(0.3, '#677585'),
    grayLightenX4: lighten(0.4, '#677585'),
    grayLightenX5: lighten(0.5, '#677585'),
    purple: '#821059',
    purpleTransparent: transparentize(0.2, '#821059'),
    purpleLighten: lighten(0.1, '#821059'),
    violetPurple: '#9089f5',
    purpleFeather: '#EED7FF',
    fill: '#FAFAFA',
    whiteFill: '#FEFEFE',
    orange: '#FE6D46',
    paleRed: '#E93E6B',
    dark: '#2b2b2b',
    lightGrayV2: '#BDBDBD',
  },
  typo: {
    fontFamily: {
      primary: '\'Roboto\', sans-serif',
      bebasNeue: 'Bebas Neue,\'Roboto\', sans-serif',
      josefinSans: 'Josefin Sans,\'Roboto\', sans-serif',
      montserrat: 'Montserrat,Helvetica,Arial,serif',
      roboto: 'Roboto, Arial, sans-serif'
    },
    weights: {
      light: 100,
      thin: 300,
      normal: 400,
      bold: 500
    },
    sizes: {
      h1: '2.2rem',
      h2: '2.0rem',
      h3: '1.8rem',
      h4: '1.6rem',
      h5: '1.4rem',
      body2: '1.2rem',
      body: '1rem',
      medium: '0.9rem',
      small: '0.8rem',
      base: '0.7rem',
      tiny: '0.6rem',
      tiniest: '0.5rem'
    }
  },
  sizes: {
    'aaa': '240px',
    'bb': '150px'
  },
  animations: {
    defaultTime: '300ms',
    secondaryTime: '450ms'
  },
  animationCurves: {
    defaultCurve: 'cubic-bezier(0.23, 1, 0.32, 1) '
  },
  layers: {
    four: 400,
    three: 300,
    two: 200,
    one: 100
  },
  padding: {
    pageD: 20,
    pageM: 10
  }
};

export default theme;