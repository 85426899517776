import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import CarouselItem from '../CarouselItem';
import {TileCarouselNext, TileCarouselPrev, TileLeftBottom} from 'components/Tiles/components/JSXElements';
import {canWatchLiveEvent, getEventInspectUrl, getEventRegistrationLogic, getEventUrl} from 'utilities/event';
import {isMobileDevice} from "utilities/general";

const isMobileDeviceVar = isMobileDevice();

function OccurrencesCarousel(
  {
    accessControls,
    occurrences = [],
    occurrence,
    duration = 0,
    isFree,
    eventId = null,
    WorkshopId,
    setOccurrenceId,
    isLoggedIn,
    eventRegistrations,
    allowDropIn,
    adminOrOwner,
    ...props
  }
) {

  const sliderRef = useRef(
    {
      prevScrollDisabled: true,
      nextScrollDisabled: false,
      position: 0
    }
  )

  // useEffect(() => {
  //   setPosition(0);
  // }, [occurrences])// eslint-disable-line

  const url = (occurrenceId, date, duration, allowDropIn) => {
    if (!isLoggedIn) {
      return getEventInspectUrl(eventId);
    }

    let eventRegLogic = getEventRegistrationLogic({
      date,
      duration,
      eventId,
      occurrenceId,
      WorkshopId,
      registrations: eventRegistrations,
      isFree,
      allowDropIn,
      adminOrOwner
    });

    if (canWatchLiveEvent({
      isLoggedIn: true,
      adminOrOwner,
      accessControls,
      ...eventRegLogic,
    })) {
      return getEventUrl(eventId, occurrenceId, adminOrOwner);
    }

    return getEventInspectUrl(eventId, occurrenceId);
  }

  function setPosition(position, prevScrollDisabled) {
    sliderRef.current = {
      ...sliderRef.current,
      position: position,
      prevScrollDisabled: prevScrollDisabled === false ? false : sliderRef.current.prevScrollDisabled
    }

    setOccurrenceId(occurrences[position].id);
  }

  function onPrevClick(e) {
    e.stopPropagation();
    e.preventDefault();
    let position = sliderRef.current.position;
    if (sliderRef.current.position === 0) {
      position = occurrences.length - 1;
    } else {
      --position;
    }
    setPosition(position)
  }

  const hasScroll = occurrences.length > 1;

  function onNextClick(e) {
    e.stopPropagation();
    e.preventDefault();
    let position = sliderRef.current.position;
    if (sliderRef.current.position >= occurrences.length - 1) {
      position = 0;
    } else {
      ++position;
    }

    setPosition(position, false);
  }

  if (!occurrences || occurrences.length === 0) {
    return null;
  }

  // let occurrence = occurrences[sliderRef.current.position];
  if (!occurrence) {
    return null;
  }

  return (
    <TileLeftBottom {...props}>
      {
        hasScroll &&
        (
          <TileCarouselPrev
            isDisabled={sliderRef.current.prevScrollDisabled}
            iconName='fas fa-angle-left'
            onClick={onPrevClick}
            isMobileDevice={isMobileDeviceVar}
          />
        )
      }

      {
        occurrence.date &&
        <CarouselItem
          date={occurrence.date}
          duration={occurrence.duration || duration}
          to={
            url(
              occurrence.id,
              occurrence.date,
              occurrence.duration || duration,
              occurrence.allowDropIn || allowDropIn
            )}
        />
      }

      {
        hasScroll &&
        (
          <TileCarouselNext
            iconName='fas fa-angle-right'
            onClick={onNextClick}
            isMobileDevice={isMobileDeviceVar}
          />
        )
      }
    </TileLeftBottom>
  );
}

OccurrencesCarousel.propTypes = {
  accessControls: PropTypes.array,
  url: PropTypes.string,
  occurrences: PropTypes.array,
  setOccurrenceId: PropTypes.func,
  duration: PropTypes.number,
  isFree: PropTypes.bool,
  isWorkshop: PropTypes.bool,
  eventId: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  eventRegistrations: PropTypes.array,
  allowDropIn: PropTypes.bool,
  adminOrOwner: PropTypes.bool,
  isWorkshopEvents: PropTypes.bool,

};

export default (React.memo(OccurrencesCarousel));
