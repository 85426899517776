import {getRouter} from 'config/websockets';

function sendMessagePromisified(socket, toRoom, data) {
  return new Promise((resolve, reject) => {
    socket.emit('route', 'chat', 'sendRoomMessage', [toRoom, data], (res) => {
      if (res.status === 'ok') {
        resolve();
      } else {
        reject(res.error);
      }
    });
  });
}

export default async function chatSendMessageToRoom(toRoom, data) {
  let socketRouter = getRouter();
  return sendMessagePromisified(socketRouter.socket, toRoom, data);
};

// export default async function sendMessageToUser(toUser, data) {
//   let socketRouter = getRouter();
//   return sendMessagePromisified(socketRouter.socket, toUser, null, data);
// };