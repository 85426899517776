import {createAction} from 'redux-actions';
import {
  SET_CHAT_VISIBILITY,
  SET_CHAT_DETACH,
  HIDE_CHAT_DETACH,
  TOGGLE_CHAT_MINIMIZE, SET_CHAT_MINIMIZE,
} from './types';

export const setChatVisibilityAction = createAction(SET_CHAT_VISIBILITY);
export const setChatDetachAction = createAction(SET_CHAT_DETACH);
export const hideChatDetachAction = createAction(HIDE_CHAT_DETACH);
export const toggleChatMinimizeAction = createAction(TOGGLE_CHAT_MINIMIZE);
export const setChatMinimizeAction = createAction(SET_CHAT_MINIMIZE);