import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {processTileDate} from 'utilities/date';
import theme from 'styles/theme'
import {scale} from "styles/common";

const TileScheduleDay = styled.div`
  cursor: pointer;
  color: ${({theme: {colors}}) => colors.white};
  font-weight: normal;

`;

const TileScheduleDate = styled.div`
  cursor: pointer;

  font-weight: bold;

  ${({animate}) =>
    animate
      ? css`
        animation: fill 1.2s 10 alternate;

        @keyframes fill {
          0% {
            color: ${({theme: {colors}}) => colors.purple};
          }
          40%, 100% {
            color: inherit;
          }
        }
      `
      : css`

      `
  }

`;
const OccurrenceItemWrap = styled(Link)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}
    //font-family: Cambria, Georgia, serif;
  &:hover {
    color: ${({theme: {colors}}) => colors.transparent};
  }

  color: ${({theme: {colors}}) => colors.white};

  &:hover ${TileScheduleDate} {
    color: ${({theme: {colors}}) => colors.transparent};
  }

  &:hover ${TileScheduleDay} {
    color: ${({theme: {colors}}) => colors.transparent};
  }
`;

function CarouselItem({to, date, duration, ...props}) {
  if (!date) {
    return null;
  }

  const dateObj = processTileDate(date, duration);

  return (
    <OccurrenceItemWrap to={to} {...props}>
      <TileScheduleDate animate={dateObj.date.toUpperCase() === 'NOW'}>
        {dateObj.date}
      </TileScheduleDate>
      <TileScheduleDay>
        {dateObj.day}
      </TileScheduleDay>
    </OccurrenceItemWrap>
  );
}

CarouselItem.propTypes = {
  to: PropTypes.string,
  date: PropTypes.string,
  duration: PropTypes.number
};
export default React.memo(CarouselItem);