export const getTransactions = state => state.transactions.rows;
//
// export const getTransaction = createCachedSelector(
//   getTransactions,
//   (state, eventId) => eventId,
//   (state, eventId, occurrenceId) => occurrenceId,
//   (state, eventId, occurrenceId, workshopId) => workshopId,
//   (transactions, eventId, occurrenceId, workshopId) => {
//     return (transactions || []).find((item) => {

//         if (eventId) {
//           if (+item.eventId === +eventId) {
//             if (occurrenceId) {
//               return +item.OccurrenceId === +occurrenceId;
//             }
//             return true;
//           }
//         }
//         return +item.WorkshopId === +workshopId;
//
//       }
//     );
//   }
// )(
//   (state, eventId, occurrenceId, workshopId) => (eventId && `eventId_${eventId}`) || (occurrenceId && `occurrenceId_${occurrenceId}`) || (workshopId && `workshopId_${workshopId}`) // Use dataType as cacheKey
// );

export const getTransaction = (state, eventId, occurrenceId, workshopId) => {
  const transactions = getTransactions(state);
  return (transactions || []).find((item) => {
    if (eventId) {
      if (item.EventId === eventId) {
        if (occurrenceId) {
          return item.OccurrenceId === occurrenceId;
        }
        return true;
      }
    }
    return workshopId && item.WorkshopId === workshopId;

  });
}