import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserSelectNoneCss } from 'styles/common';
import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import { getUserId } from '../../reduxUtils/user/selectors';
import { getUserFullName, getUserAvatarThumbnail } from '../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
} from '../../reduxUtils/browserSettings/selectors';
import { getSearch } from '../../reduxUtils/search/selectors';
import { getRates } from '../../reduxUtils/browserSettings/thunks';
import { getHomeEvents } from '../../reduxUtils/homeEvents/selectors';
import { getHomeLiveEvents } from '../../reduxUtils/homeLiveEvents/selectors';
import { getHomeWorkshops } from '../../reduxUtils/homeWorkshops/selectors';
import WithSideBar from 'layouts/WithSideBar';
import SectionBody from './components/SectionBody';
import MetaDecorator from 'components/MetaDecorator';
import { HOME_DEFAULT_CATEGORIES, HOME_PAGE_CONTENT } from 'config/constants';
import { getUserFavorites } from '../../reduxUtils/userFavorites/selectors';
import { getHomeFavorites } from '../../reduxUtils/homeFavorites/selectors';
import { fetchHomeFavorites, deleteHomeFavorites } from '../../reduxUtils/homeFavorites/thunks';

import { getUserFollowings } from '../../reduxUtils/userFollowings/selectors';

import { getHomeFollowings } from '../../reduxUtils/homeFollowings/selectors';
import { deleteHomeFollowings, fetchHomeFollowings } from '../../reduxUtils/homeFollowings/thunks';
import EventTile from 'components/Tiles/EventTile';

import PlaceholderTile from 'components/Tiles/Placeholder/PlaceholderTile';
import { setAllEventRegistrations } from '../../reduxUtils/userEventRegistrations/thunks';
import { getUserEventRegistrationsReqParams } from '../../reduxUtils/userEventRegistrations/selectors';
import { getUserWorkshopRegistrationsReqParams } from '../../reduxUtils/userWorkshopRegistrations/selectors';
import { setAllWorkshopRegistrations } from '../../reduxUtils/userWorkshopRegistrations/thunks';
import { getAccessControl } from '../../reduxUtils/accessControl/selectors';
import { fetchHomeUpcomingEvents } from '../../reduxUtils/homeUpcomingEvents/thunks';
import { getHomeUpcomingEvents } from '../../reduxUtils/homeUpcomingEvents/selectors';
import { fetchHomeUpcomingWorkshops } from '../../reduxUtils/homeUpcomingWorkshops/thunks';
import { getHomeUpcomingWorkshops } from '../../reduxUtils/homeUpcomingWorkshops/selectors';
import EmptyModal from 'components/Modal/EmptyModal';
import GoogleSignIn from 'components/GoogleSignIn';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { getHomeLiveEventsSuggestion1 } from '../../reduxUtils/homeLiveEventsSuggestion1/selectors';
import { getHomeLiveEventsSuggestion2 } from '../../reduxUtils/homeLiveEventsSuggestion2/selectors';
import { fetchHomeEvents, fetchHomeLiveEvents } from '../../reduxUtils/helpers/events';
import { getHomeEventsSuggestion1 } from '../../reduxUtils/homeEventsSuggestion1/selectors';
import { getHomeEventsSuggestion2 } from '../../reduxUtils/homeEventsSuggestion2/selectors';
import { fetchHomeWorkshops } from '../../reduxUtils/helpers/workshops';
import { getHomeWorkshopsSuggestion1 } from '../../reduxUtils/homeWorkshopsSuggestion1/selectors';
import { getHomeWorkshopsSuggestion2 } from '../../reduxUtils/homeWorkshopsSuggestion2/selectors';
import { findCategoryByValue } from 'utilities/general';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';

const BrowseWrap = styled.div`
  width: 100%;
  height: 100%;
`;

const BrowseSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0 20px;
  overflow-x: hidden;
  ${({ isMobile }) =>
    isMobile
      ? css``
      : css`
          padding: 0 10px;
        `}
`;

const BrowseSectionTitle = styled.div`
  width: 100%;
  font-size: ${({ theme: { typo } }) => typo.sizes.h5};
  font-weight: bold;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.darkGrey};
  text-transform: uppercase;
  ${UserSelectNoneCss};
`;

const NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-size: ${({ theme: { typo } }) => typo.sizes.h2};
  margin: 0 auto 1em auto;
`;
const Spacer = styled.div`
  width: 100%;
  height: 20px;
`;

function BrowseContent({
  location,
  match,
  isMobile,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  accessControls,
  userSettings,
  fullName,
  avatar,
  search,
  homeLiveEvents,
  fetchHomeLiveEvents,
  homeLiveEventsSuggestion1,
  homeLiveEventsSuggestion2,
  homeEvents,
  homeEventsSuggestion1,
  homeEventsSuggestion2,
  fetchHomeEvents,
  homeWorkshops,
  homeWorkshopsSuggestion1,
  homeWorkshopsSuggestion2,
  fetchHomeWorkshops,
  userEventRegistrationsReqParams,
  setAllEventRegistrations,
  setAllWorkshopRegistrations,
  userWorkshopRegistrationsReqParams,
  getRates,
  isChatMinimized,
  isChatDetach,
}) {
  const navigate = useNavigate();
  const { type, category, categoryType } = useParams();

  function resetGoogleDialog() {
    setGoogleDialog(defaultGoogleDialogState);
  }

  const defaultGoogleDialogState = {
    show: false,
    title: `Sign in with google`,
    scopes: '',
    discoveryDocs: [],
    onHide: resetGoogleDialog,
    onCancel: resetGoogleDialog,
    onSuccess: () => {},
    onFail: () => {},
  };
  const [googleDialog, setGoogleDialog] = useState(defaultGoogleDialogState);

  function resetDialog() {
    setDialog(defaultDialogState);
  }

  const defaultDialogState = {
    show: false,
    title: '',
    contentTitle: '',
    isLoading: false,
    children: null,
    confirmationText: '',
    onCancel: resetDialog,
    onHide: resetDialog,
    onConfirm: () => {},
  };
  const [dialog, setDialog] = useState(defaultDialogState);

  const userCategories = HOME_DEFAULT_CATEGORIES;

  let browseObj = getBrowseContent(type, category, categoryType, userCategories);

  useEffect(() => {
    getRates();
  }, [getRates]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!userEventRegistrationsReqParams.setAll) {
        setAllEventRegistrations();
      }
      if (!userWorkshopRegistrationsReqParams.setAll) {
        setAllWorkshopRegistrations();
      }
    }
  }, [isLoggedIn]); // eslint-disable-line

  // function getCategoryObject(userCategories, type, category) {
  //   let search = null;
  //   let categoryType = null;
  //
  //   if (userCategories[type]) {
  //     for (let keyCategoryType in userCategories[type]) {
  //       const innerObj = userCategories[type][keyCategoryType];
  //       if (keyCategoryType && innerObj?.search.category.value === category) {
  //         search = innerObj.search;
  //         categoryType = innerObj.categoryType;
  //         break;
  //       }
  //     }
  //   }
  //   return {search, categoryType};
  // }

  function getBrowseContent(type, category, categoryType, userCategories) {
    let fetch = null;
    let content = null;
    if (!userCategories?.[type]?.[categoryType]) {
      return null;
    }
    // let {search, categoryType} = getCategoryObject(userCategories, type, category);
    let search = userCategories?.[type]?.[categoryType]?.search;
    if (search?.category?.value !== category) {
      const foundCategory = findCategoryByValue(category);
      if (foundCategory) {
        search = { category: foundCategory };
      }
    }

    if (type === 'live') {
      fetch = fetchHomeLiveEvents;
      switch (true) {
        case userCategories[type]?.new.categoryType === categoryType:
          content = homeLiveEvents;
          break;
        case userCategories[type]?.suggestion1.categoryType === categoryType:
          content = homeLiveEventsSuggestion1;
          break;
        case userCategories[type]?.suggestion2.categoryType === categoryType:
          content = homeLiveEventsSuggestion2;
          break;
        default:
          break;
      }
    }

    if (type === 'vod') {
      fetch = fetchHomeEvents;
      switch (true) {
        case userCategories[type]?.new.categoryType === categoryType:
          content = homeEvents;
          break;
        case userCategories[type]?.suggestion1.categoryType === categoryType:
          content = homeEventsSuggestion1;
          break;
        case userCategories[type]?.suggestion2.categoryType === categoryType:
          content = homeEventsSuggestion2;
          break;
        default:
          break;
      }
    }

    if (type === 'series') {
      fetch = fetchHomeWorkshops;
      switch (true) {
        case userCategories[type]?.new.categoryType === categoryType:
          content = homeWorkshops;
          break;
        case userCategories[type]?.suggestion1.categoryType === categoryType:
          content = homeWorkshopsSuggestion1;
          break;
        case userCategories[type]?.suggestion2.categoryType === categoryType:
          content = homeWorkshopsSuggestion2;
          break;
        default:
          break;
      }
    }

    return { fetch, content, search, categoryType };
  }

  return (
    <>
      <EmptyModal
        centered
        show={googleDialog.show}
        title={googleDialog.title}
        displayCloseButton
        onCancel={resetGoogleDialog}
        onHide={resetGoogleDialog}
      >
        <GoogleSignIn
          text={
            'for adding automatic reminder to google calendar you need to sign in to your google account'
          }
          onSuccess={googleDialog.onSuccess}
          onFail={googleDialog.onFail}
          scopes={googleDialog.scopes}
          discoveryDocs={googleDialog.discoveryDocs}
        />
      </EmptyModal>

      <ConfirmationModal
        {...dialog}
        isLoading={dialog.isLoading}
        show={dialog.show}
        title={dialog.title}
        contentTitle={dialog.contentTitle}
        confirmationText={dialog.confirmationText}
        confirmButtonText={dialog.action}
        cancelButtonText={dialog.cancelButtonText}
        children={dialog.children}
        onCancel={resetDialog}
        onConfirm={dialog.onConfirm}
        onHide={resetDialog}
      />

      <WithSideBar
        location={location}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <BrowseWrap>
          <MetaDecorator
            title={`${type} • ${browseObj?.search?.category.label}`}
            description={HOME_PAGE_CONTENT}
          />

          <BrowseSection isMobile={isMobile}>
            <BrowseSectionTitle>
              {type}
              <span style={{ textTransform: 'capitalize' }}>
                {' '}
                • {browseObj?.search?.category.label || category}
              </span>
            </BrowseSectionTitle>
            {!browseObj ? (
              <NoResultsContainer>No Results</NoResultsContainer>
            ) : (
              <SectionBody
                isSelected={true}
                isLoggedIn={isLoggedIn}
                userId={userId}
                accessControls={accessControls}
                navigate={navigate}
                search={browseObj?.search}
                categoryType={browseObj?.categoryType}
                userSettings={userSettings}
                isMobile={isMobile}
                fetch={browseObj?.fetch}
                {...browseObj?.content}
                setDialog={setDialog}
                setGoogleDialog={setGoogleDialog}
                Tile={EventTile}
                PlaceHolder={PlaceholderTile}
              />
            )}
          </BrowseSection>

          <Spacer />
        </BrowseWrap>
      </WithSideBar>
    </>
  );
}

BrowseContent.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  search: PropTypes.object,
  homeLiveEvents: PropTypes.object,
  homeLiveEventsSuggestion1: PropTypes.object,
  homeLiveEventsSuggestion2: PropTypes.object,
  homeEvents: PropTypes.object,
  homeEventsSuggestion1: PropTypes.object,
  homeEventsSuggestion2: PropTypes.object,
  homeWorkshops: PropTypes.object,
  homeWorkshopsSuggestion1: PropTypes.object,
  homeWorkshopsSuggestion2: PropTypes.object,
  fetchHomeEvents: PropTypes.func,
  fetchHomeWorkshops: PropTypes.func,
  userEventRegistrationsReqParams: PropTypes.object,
  userWorkshopRegistrationsReqParams: PropTypes.object,
  setAllWorkshopRegistrations: PropTypes.func,
  setAllEventRegistrations: PropTypes.func,
  ratingCount: PropTypes.number,
  followersCount: PropTypes.number,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    search: getSearch(state),
    homeLiveEvents: getHomeLiveEvents(state),
    homeLiveEventsSuggestion1: getHomeLiveEventsSuggestion1(state),
    homeLiveEventsSuggestion2: getHomeLiveEventsSuggestion2(state),
    homeEvents: getHomeEvents(state),
    homeEventsSuggestion1: getHomeEventsSuggestion1(state),
    homeEventsSuggestion2: getHomeEventsSuggestion2(state),
    homeWorkshops: getHomeWorkshops(state),
    homeWorkshopsSuggestion1: getHomeWorkshopsSuggestion1(state),
    homeWorkshopsSuggestion2: getHomeWorkshopsSuggestion2(state),
    userFavorite: getUserFavorites(state),
    homeFavorites: getHomeFavorites(state),
    userFollowing: getUserFollowings(state),
    homeFollowings: getHomeFollowings(state),
    homeUpcomingEvents: getHomeUpcomingEvents(state),
    homeUpcomingWorkshops: getHomeUpcomingWorkshops(state),
    userEventRegistrationsReqParams: getUserEventRegistrationsReqParams(state),
    userWorkshopRegistrationsReqParams: getUserWorkshopRegistrationsReqParams(state),
    accessControls: getAccessControl(state),
    isChatMinimized: getIsChatMinimized(state),
    isChatDetach: getIsChatDetach(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchHomeLiveEvents,
      fetchHomeEvents,
      fetchHomeWorkshops,
      fetchHomeFavorites,
      deleteHomeFavorites,
      fetchHomeFollowings,
      deleteHomeFollowings,
      fetchHomeUpcomingEvents,
      fetchHomeUpcomingWorkshops,
      setAllEventRegistrations,
      setAllWorkshopRegistrations,
      getRates,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowseContent);
