import {
  addUserEventRegistration,
  addUserEventsRegistration,
  deleteUserEventRegistration,
  setUserEventRegistrations
} from './actions';
import {errorHandler, successHandler} from 'utilities/services';
import EventService from 'services/event';
import UserService from 'services/user'
import {addHomeUpcomingEvent, deleteHomeUpcomingEvent} from '../homeUpcomingEvents/actions';



const userService = new UserService();
const eventService = new EventService();

export function setAllEventRegistrations() {
  return dispatch => eventService.getAllUserEventRegistrations()
    .then((response) => {
      const {data: {registrations}} = response;
      dispatch(setUserEventRegistrations(registrations));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}

export function setEventRegistrations(eventId) {
  return dispatch => eventService.getAllEventRegistrations(eventId)
    .then((response) => {
      const {data: {registrations}} = response;
      dispatch(setUserEventRegistrations(registrations));
      return registrations;
    })
    .catch(err => {
      errorHandler(err);
      return null;
    });
}

export function addEventRegistration(occurrenceId, event) {
  return dispatch => userService.getRegistrations({eventId: event.id, occurrenceId})
    .then((response) => {
      const {data: {registrations}} = response;
      dispatch(addUserEventsRegistration(registrations));
      dispatch(addHomeUpcomingEvent({occurrenceId, event}));
    })
    .catch(err => {
      errorHandler(err);
    });
}

export function createEventRegistration(eventId, occurrenceId, event) {
  return dispatch => eventService.createEventRegistration(eventId, occurrenceId)
    .then((response) => {
      const {data: {registration}} = response;
      dispatch(addUserEventRegistration(registration));
      dispatch(addHomeUpcomingEvent({occurrenceId, event}));

      successHandler('You\'ve successfully registered to the event');
      return response;
    })
}

export function deleteEventRegistration(eventId, occurrenceId, registrationId) {
  return dispatch => eventService
    .deleteEventRegistration(eventId, registrationId)
    .then((response) => {
      dispatch(deleteUserEventRegistration(registrationId));
      dispatch(deleteHomeUpcomingEvent({eventId, occurrenceId}));
      return response;
    })
}