import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styles/theme';
import {scale} from 'styles/common';
import CarouselItem from '../CarouselItem';
import {TileCarouselNext, TileCarouselPrev, TileLeftBottom} from 'components/Tiles/components/JSXElements';
import {getWorkshopInspectUrl, getWorkshopUrl} from 'utilities/workshop';

const Label = styled.div`
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 5px;
  color: #9c9c9c;
  font-size: 0.7rem;
  letter-spacing: 0.06em;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body2]]],
    ['560px', [['font-size', theme.typo.sizes.body]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
    ['1200px', [['font-size', theme.typo.sizes.small]]],
    ['1400px', [['font-size', theme.typo.sizes.base]]]
  ])}
`;

function WorkshopCarousel({
                            dates = [],
                            id,
                            isFree,
                            isLoggedIn,
                            registration = {}
                          }) {
  const [slider, setSlider] = useState({
    hasScroll: true,
    prevScrollDisabled: true,
    nextScrollDisabled: false,
    position: 0
  });

  const url = () => {
    if (!isLoggedIn) {
      return getWorkshopInspectUrl(id)
    }
    return (isFree || (registration && !!registration.id)) ?
      getWorkshopUrl(id) :
      getWorkshopInspectUrl(id)
  }


  function onPrevClick(e) {
    e.stopPropagation();
    e.preventDefault();
    let position = slider.position;
    if (slider.position === 0) {
      position = dates.length - 1;
    } else {
      --position;
    }

    setSlider((current) => {
      return {
        ...current,
        position: position,
      };
    });
  }

  function onNextClick(e) {
    e.stopPropagation();
    e.preventDefault();
    let position = slider.position;
    if (slider.position >= dates.length - 1) {
      position = 0;
    } else {
      ++position;
    }

    setSlider((current) => {
      return {
        ...current,
        position: position,
        prevScrollDisabled: false
      };
    });
  }

  if (!dates.length) {
    return null;
  }

  return (
    <TileLeftBottom>
      <Label>
        {dates[slider.position].label}
      </Label>
      {
        slider.hasScroll &&
        (
          <TileCarouselPrev
            isDisabled={slider.prevScrollDisabled}
            iconName="fas fa-angle-left"
            onClick={onPrevClick}
          />
        )
      }

      {
        <CarouselItem
          date={dates[slider.position].date}
          to={url()}
        />
      }

      {
        slider.hasScroll &&
        (
          <TileCarouselNext
            iconName="fas fa-angle-right"
            onClick={onNextClick}
          />
        )
      }
    </TileLeftBottom>
  );
}

WorkshopCarousel.propTypes = {
  dates: PropTypes.array,
  id: PropTypes.number,
  isFree: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  registration: PropTypes.object,
};

export default React.memo(WorkshopCarousel);
