import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import StyledAvatar from 'components/StyledAvatar';
import theme from 'styles/theme';
import StarIcon from 'components/StarIcon';
import {ChannelTileWrap} from 'components/Tiles/components/JSXElements';
import Tags from 'components/Tiles/components/Tags';
import {UserSelectNoneCss, scale, LabelFontSizeCss, HomeChannelAvatarCss} from 'styles/common';
import {compactNumber} from "utilities/general";

export const ChannelTileStyle = styled(ChannelTileWrap)`
  margin-right: ${({theme: {typo}}) => typo.sizes.h3};
  position: relative;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 10px;
  }

  ${UserSelectNoneCss};

  &:hover {
    box-shadow: -60px 60px 15px -65px #c3c3c3, 60px 60px 15px -65px #c3c3c3;
    -webkit-box-shadow: -60px 60px 15px -65px #c3c3c3, 60px 60px 15px -65px #c3c3c3;
    -moz-box-shadow: -60px 60px 15px -65px #c3c3c3, 60px 60px 15px -65px #c3c3c3;
  }
`;

export const ChannelTileAvatar = styled(StyledAvatar)`
  ${HomeChannelAvatarCss};
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;
`;

const ChannelTileLink = styled(Link)`
  position: absolute;
  height: 88%;
  width: 100%;
  top: 0;
  left: 0;
`;

const ChannelTileName = styled.div`
  font-weight: bold;
  text-align: center;
  color: ${({theme: {colors}}) => colors.grayNew};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: auto;
  margin: 0 0.7em;
  text-transform: capitalize;

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h4]]],
    ['768px', [['font-size', theme.typo.sizes.h5]]],
    ['992px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body2]]],
    ['1400px', [['font-size', theme.typo.sizes.body]]]
  ])}
`;

const SectionWrap = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
    // font-family:${theme.typo.fontFamily.josefinSans};
  color: ${({theme: {colors}}) => colors.grayNew};
  margin-bottom: 0.5em;
  font-size: ${({theme: {typo}}) => typo.sizes.small};
`;

const Star = styled(StarIcon)`
  margin: 0 0.2em;
`;

const Rating = styled.span`
`;

const RatingCount = styled.span`
`;

const Followers = styled.span`
  margin: 0 0.4em;
  ${LabelFontSizeCss};
`;

const TagsWrap = styled(Tags)`
  margin: 0 0.6em 0.2em 0.6em;
  width: auto;
`;

function ChannelTile({
                       id,
                       fullName,
                       avatar,
                       followersCount,
                       rating,
                       ratingCount,
                       tags,
                       ...props
                     }) {
  return (
    <ChannelTileStyle>
      <ChannelTileLink to={`/channel/${id}`}/>
      <ChannelTileAvatar url={avatar} name={fullName}/>
      <ChannelTileName>{fullName}</ChannelTileName>

      <SectionWrap>

        {
          !!rating && (
            <>
              <Rating>{rating}</Rating>
              <Star/>
              <RatingCount>({compactNumber(+ratingCount)})</RatingCount>
            </>
          )
        }

        {
          !!followersCount && (
            <>
              <Followers>{compactNumber(+followersCount)} Followers</Followers>
            </>
          )
        }

      </SectionWrap>
      {
        !!tags &&
        <TagsWrap
        />
      }

    </ChannelTileStyle>

  );
}

ChannelTile.propTypes = {
  id: PropTypes.number,
  channelName: PropTypes.string,
  avatar: PropTypes.string
};
export default React.memo(ChannelTile);