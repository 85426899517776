import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import StyledAvatar from 'components/StyledAvatar';
import UserMenu from '../UserMenu';
import { UserSelectNoneCss } from 'styles/common';

const UserMenuDropdownWrap = styled(Dropdown)`
  display: flex;
  align-items: center;
  ${UserSelectNoneCss};
`;

const UserMenuDropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

function UserMenuDropdown({
  forAdmin,
  isShown,
  userId,
  fullName,
  avatar,
  setIsShown,
  onSelect,
  isLoggedIn,
}) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  return (
    <UserMenuDropdownWrap>
      <UserMenuDropdownWrap.Toggle as={CustomToggle}>
        <UserMenuDropdownToggle>
          <StyledAvatar name={fullName} url={avatar} to="/profile" />
        </UserMenuDropdownToggle>
      </UserMenuDropdownWrap.Toggle>
      <UserMenuDropdownWrap.Menu>
        <UserMenu
          forAdmin={forAdmin}
          userId={userId}
          fullName={fullName}
          avatar={avatar}
          onSelect={onSelect}
          isLoggedIn={isLoggedIn}
        />
      </UserMenuDropdownWrap.Menu>
    </UserMenuDropdownWrap>
  );
}

UserMenuDropdown.propTypes = {
  forAdmin: PropTypes.bool,
  isShown: PropTypes.bool,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  setIsShown: PropTypes.func,
  onSelect: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};

export default React.memo(UserMenuDropdown);
