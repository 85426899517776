import { combineReducers } from 'redux';
import accessControl from './accessControl/reducers';
import auth from './auth/reducers';
import browserSettings from './browserSettings/reducers';
import event from './event/reducers';
import eventSettings from './eventSettings/reducers';
import eventOccurrences from './eventOccurrences/reducers';
import feedbacks from './feedbacks/reducers';
import homeLiveEvents from './homeLiveEvents/reducers';
import homeLiveEventsSuggestion1 from './homeLiveEventsSuggestion1/reducers';
import homeLiveEventsSuggestion2 from './homeLiveEventsSuggestion2/reducers';
import homeEvents from './homeEvents/reducers';
import homeEventsSuggestion1 from './homeEventsSuggestion1/reducers';
import homeEventsSuggestion2 from './homeEventsSuggestion2/reducers';
import homeWorkshops from './homeWorkshops/reducers';
import homeWorkshopsSuggestion1 from './homeWorkshopsSuggestion1/reducers';
import homeWorkshopsSuggestion2 from './homeWorkshopsSuggestion2/reducers';
import homeFavorites from './homeFavorites/reducers';
import homeFollowings from './homeFollowings/reducers';
import userEventRegistrations from './userEventRegistrations/reducers';
import userWorkshopRegistrations from './userWorkshopRegistrations/reducers';
import fileUploader from './fileUploader/reducers';
import search from './search/reducers';
import user from './user/reducers';
import userFavorites from './userFavorites/reducers';
import userFollowings from './userFollowings/reducers';
import userProfile from './userProfile/reducers';
import userSettings from './userSettings/reducers';
import workshop from './workshop/reducers';
import workshopSettings from './workshopSettings/reducers';
import workshopOccurrences from './workshopOccurrences/reducers';
import homeUpcomingEvents from './homeUpcomingEvents/reducers';
import homeUpcomingWorkshops from './homeUpcomingWorkshops/reducers';
import chat from './chat/reducers';
import socket from './socket/reducers';
import purchasesLiveEvents from './purchase/purchasesLiveEvents/reducers';
import purchasesEvents from './purchase/purchasesEvents/reducers';
import purchasesWorkshops from './purchase/purchasesWorkshops/reducers';
import newPolls from './newPolls/reducers';
import polls from './polls/reducers';
import transactions from './transactions/reducers';

const rootReducer = combineReducers({
  accessControl,
  auth,
  browserSettings,
  event,
  eventSettings,
  eventOccurrences,
  feedbacks,
  homeLiveEvents,
  homeLiveEventsSuggestion1,
  homeLiveEventsSuggestion2,
  homeEvents,
  homeEventsSuggestion1,
  homeEventsSuggestion2,
  homeWorkshops,
  homeWorkshopsSuggestion1,
  homeWorkshopsSuggestion2,
  homeFavorites,
  homeFollowings,
  userEventRegistrations,
  userWorkshopRegistrations,
  fileUploader,
  search,
  user,
  userFavorites,
  userFollowings,
  userProfile,
  userSettings,
  workshop,
  workshopSettings,
  workshopOccurrences,
  homeUpcomingEvents,
  homeUpcomingWorkshops,
  chat,
  socket,
  transactions,
  purchasesLiveEvents,
  purchasesEvents,
  purchasesWorkshops,
  newPolls,
  polls,
});

export default rootReducer;
