import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import OptionInput from 'components/Chat/components/PollsContainer/components/NewPoll/components/OptionInput';
import { bindActionCreators } from 'redux';
import {
  addNewPollOptionAction,
  deleteNewPollOptionAction,
  updateNewPollOptionAction,
} from '../../../../../../../../reduxUtils/newPolls/actions';
import { connect } from 'react-redux';
import { generatePollOptionId } from 'utilities/general';
import { OptionWrapCss } from 'components/Chat/components/PollsContainer/components/JSXElements';
import { POLL_OPTION } from 'utilities/polls.const';

const Wrap = styled.div``;

const OptionInputStyled = styled(OptionInput)`
  ${OptionWrapCss};
`;

function OptionsInput({
  className,
  PollId,
  PollOptions,
  addNewPollOptionAction,
  deleteNewPollOptionAction,
  updateNewPollOptionAction,
}) {
  function addNewOption() {
    addNewPollOptionAction({
      ...POLL_OPTION,
      PollId,
      id: generatePollOptionId(),
    });
  }

  function onDelete(id) {
    if (PollOptions.length > 1) {
      deleteNewPollOptionAction({
        PollId,
        id,
      });
    }
  }

  function onUpdate(id, data) {
    updateNewPollOptionAction({
      PollId,
      id,
      ...data,
    });
  }

  return (
    <Wrap className={className}>
      {PollOptions.map((option, index) => {
        return (
          <OptionInputStyled
            key={option.id}
            value={option.text}
            image={option.img}
            isLast={index === PollOptions.length - 1}
            addNewOption={addNewOption}
            onUpdate={(data) => onUpdate(option.id, data)}
            onDelete={() => {
              onDelete(option.id);
            }}
          />
        );
      })}
    </Wrap>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addNewPollOptionAction,
      deleteNewPollOptionAction,
      updateNewPollOptionAction,
    },
    dispatch
  );
}

OptionsInput.propTypes = {
  className: PropTypes.string,
  PollId: PropTypes.string,
  PollOptions: PropTypes.array,
  addNewPollOptionAction: PropTypes.func,
  deleteNewPollOptionAction: PropTypes.func,
  updateNewPollOptionAction: PropTypes.func,
};
export default connect(null, mapDispatchToProps)(React.memo(OptionsInput));
