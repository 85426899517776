import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getUserEager } from '../../reduxUtils/user/thunks';
import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import {
  getBrowserIsFxReady,
  getBrowserIsMobile,
  getLoginModalStatus,
} from '../../reduxUtils/browserSettings/selectors';
import {
  setBrowserDimensions,
  setLoginModalStatus,
} from '../../reduxUtils/browserSettings/actions';
import { getUserId } from '../../reduxUtils/user/selectors';
import {
  getIsChatDetach,
  getChatDetachProps,
  getIsChatMinimized,
} from '../../reduxUtils/chat/selectors';

import {
  alWebVitals,
  getDeviceInfo,
  getWebVitals,
  mixpanelIdentify,
  mixpanelRegister,
} from 'utilities/analytics';
import { getEncodedRedirectPath, setCurrencyForNoneLoggedUser } from 'utilities/general';
import jwtLogin from 'websockets/sendActions/authentication/auth.jwt-login.action';

import AdminPanel from '../AdminPanel';
import Home from '../Home';
import BrowseContent from '../BrowseContent';
import Login from '../Auth/Login';

import Register from '../Auth/Register';
import ResetPassword from '../Auth/ResetPassword';
import ForgotPassword from '../Auth/ForgotPassword';
import VerifyEmail from '../Auth/VerifyEmail';

import UserSettings from '../UserSettings';
// import Cart from '../Cart';
// import Notifications from '../Notifications';
import Channel from '../Channel';
// import Subscriptions from '../Subscriptions';
// import History from '../History';
import Purchases from '../Purchases/index';
import Friends from '../Friends';
import FriendsActivity from '../FriendsActivity';
// import Suggestions from '../Suggestions';

import Workshop from '../Workshop';
import WorkshopInspect from '../Workshop/containers/Inspect';
import WorkshopManage from '../Workshop/containers/Manage';
import WorkshopNew from '../Workshop/containers/New';
import WorkshopEdit from '../Workshop/containers/Edit';

import Event from '../Event';
import EventInspect from '../Event/containers/Inspect';
import EventManage from '../Event/containers/Manage';
import EventNew from '../Event/containers/New';
import EventEdit from '../Event/containers/Edit';

import PageNotFound from '../PageNotFound';
import FileUploader from 'components/FileUploader';
import Legal from '../Legal';
import Help from 'containers/Help';

// import PayNow from 'containers/PayNow';
//  import ZoomAuth from 'containers/ZoomAuth';
import Chat from 'components/Chat';
import { errorHandler } from 'utilities/services';
import LoginSignupModal from 'containers/Auth/LoginSignupModal';
let resizingTimeout = null;
let appLoaded = true;

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:accessToken" element={<ResetPassword />} />
      <Route path="verify-email/:accessToken" element={<VerifyEmail />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

const EventRoutes = ({ match }) => (
  <Routes>
    <Route path="new/*" element={<EventNew match={match} />} />
    <Route path=":eventId/edit/*" element={<EventEdit match={match} />} />
    <Route path=":id/manage" element={<EventManage match={match} />} />
    <Route path=":id/inspect" element={<EventInspect match={match} />} />
    <Route path=":id/occurrence/:occurrenceId/inspect" element={<EventInspect match={match} />} />
    <Route path=":id/occurrence/:occurrenceId/*" element={<Event match={match} />} />
    <Route path=":id" element={<Event match={match} />} />
  </Routes>
);

const WorkshopRoutes = ({ match }) => (
  <Routes>
    <Route path="event" element={<EventNew />} />
    <Route path="new/*" element={<WorkshopNew />} />
    {/* Add other nested routes for channel here */}
    <Route path="*" element={<Workshop />} />
  </Routes>
);

const ChannelRoutes = ({ match }) => {
  return (
    <Routes>
      <Route path="event/*" element={<EventRoutes match={match} />} />
      <Route path="workshop/*" element={<WorkshopRoutes match={match} />} />
      {/* Add other nested routes for channel here */}
      <Route path="*" element={<Channel match={match} />} />
    </Routes>
  );
};

function App({
  isLoggedIn,
  isFxReady,
  setBrowserDimensions,
  setLoginModalStatus,
  getUserEager,
  userId,
  isChatDetach,
  isChatMinimized,
  showLoginSignupModal,
  chatDetachProps,
}) {
  // let match = useSearchParams();
  let match = new URL(document.location);
  // let params = new URL(document.location);
  // let urlsearchparams = new URLSearchParams(document.location.search);
  useEffect(() => {
    const handleResize = () => {
      if (resizingTimeout) {
        clearTimeout(resizingTimeout);
      }

      resizingTimeout = setTimeout(() => {
        setBrowserDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 300);
    };
    if (appLoaded) {
      appLoaded = false;
      setBrowserDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setBrowserDimensions]);

  // useEffect(() => {
  //   mixpanelIdentify(userId);
  // }, [userId]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserEager();
      jwtLogin()
        .then((res) => {})
        .catch((err) => {
          errorHandler(err);
        });
    }
  }, [isLoggedIn, getUserEager]);

  useEffect(() => {
    if (!isLoggedIn && isFxReady) {
      setCurrencyForNoneLoggedUser();
    }
  }, [isLoggedIn, isFxReady]);

  useEffect(() => {
    let timeOut;
    const deviceInfo = getDeviceInfo();
    let webVitals = { webVitals: deviceInfo };

    // should be the last call to capture latest CLS
    timeOut = setTimeout(() => {
      getWebVitals(webVitals);
    });
    return () => {
      alWebVitals(webVitals.webVitals);
      clearTimeout(timeOut);
    };
  }, [window.location.href]); // eslint-disable-line

  // useEffect(() => {
  //   mixpanelRegister({isLoggedIn})
  // }, [isLoggedIn]);// eslint-disable-line

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home match={match} />} />
          <Route
            path="/browse/:type/:categoryType/:category"
            element={<BrowseContent match={match} />}
          />
          <Route path="/auth/*" element={<AuthRoutes match={match} />} />
          <Route path="/channel/:channelId/*" element={<ChannelRoutes match={match} />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/help/*" element={<Help />} />
          <Route path="/admin-panel" element={<AdminPanel match={match} />} />
          <Route path="/user-settings/*" element={<UserSettings />} />
          <Route
            path="/purchases"
            element={
              isLoggedIn ? (
                <Purchases match={match} />
              ) : (
                <Navigate to={`/${getEncodedRedirectPath()}`} />
              )
            }
          />
          <Route
            path="/friends"
            element={isLoggedIn ? <Friends /> : <Navigate to={`/${getEncodedRedirectPath()}`} />}
          />
          <Route
            path="/friends-activity"
            element={
              isLoggedIn ? <FriendsActivity /> : <Navigate to={`/${getEncodedRedirectPath()}`} />
            }
          />
          <Route path="/event/*" element={<EventRoutes match={match} />} />
          <Route path="/workshop/*" element={<WorkshopRoutes match={match} />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <LoginSignupModal show={showLoginSignupModal} setShow={setLoginModalStatus} />
      </Router>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        position="top-center"
        style={{
          textAlign: 'center',
          fontSize: '18px',
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        }}
        toastStyle={{ borderRadius: '8px' }}
      />
      <FileUploader />
      {isChatDetach && !isChatMinimized && (
        <Chat
          {...chatDetachProps}
          isChatDetach={isChatDetach}
          userId={userId}
          isLoggedIn={isLoggedIn}
        />
      )}
    </>
  );
}

App.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  setBrowserDimensions: PropTypes.func,
  setLoginModalStatus: PropTypes.func,
  getUserEager: PropTypes.func,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
  showLoginSignupModal: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isFxReady: getBrowserIsFxReady(state),
    isMobile: getBrowserIsMobile(state),
    userId: getUserId(state),
    chatDetachProps: getChatDetachProps(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
    showLoginSignupModal: getLoginModalStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBrowserDimensions,
      getUserEager,
      setLoginModalStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
