import {createAction} from 'redux-actions';
import {
  SET_USER_FOLLOWINGS,
  ADD_USER_FOLLOWING,
  DELETE_USER_FOLLOWING
} from './types';


export const setUserFollowings = createAction(SET_USER_FOLLOWINGS);
export const addUserFollowing = createAction(ADD_USER_FOLLOWING);
export const deleteUserFollowing = createAction(DELETE_USER_FOLLOWING);