import {createAction} from 'redux-actions';
import {
  SET_HOME_WORKSHOPS,
  ADD_HOME_WORKSHOP,
  ADD_HOME_WORKSHOPS,
  UPDATE_HOME_WORKSHOP,
  DELETE_HOME_WORKSHOP
} from './types';

export const setHomeWorkshops = createAction(SET_HOME_WORKSHOPS);
export const addHomeWorkshop = createAction(ADD_HOME_WORKSHOP);
export const addHomeWorkshops = createAction(ADD_HOME_WORKSHOPS);
export const updateHomeWorkshop = createAction(UPDATE_HOME_WORKSHOP);
export const deleteHomeWorkshop = createAction(DELETE_HOME_WORKSHOP);