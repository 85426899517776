import UpcomingWorkshopService from 'services/workshop';

import {
  setHomeUpcomingWorkshops,
  addHomeUpcomingWorkshops
} from './actions';
import {errorHandler} from 'utilities/services';

const workshopService = new UpcomingWorkshopService();

export function fetchHomeUpcomingWorkshops(query, addNextPage) {
  return dispatch => workshopService
    .getAllUpcoming(query)
    .then((response) => {
      const {data: {workshops}} = response;
      workshops.reqParams = query.reqParams;
      if (addNextPage) {
        dispatch(addHomeUpcomingWorkshops(workshops));
      } else {
        dispatch(setHomeUpcomingWorkshops(workshops));
      }
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}