import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {MenuItemWrapCss} from 'styles/common';
import Dropdown from 'react-bootstrap/Dropdown';

const ContentMenuItemWrap = styled(Dropdown.Item)`
  ${MenuItemWrapCss};
`;


function ContentMenuItem({className, children, ...props}) {
  return (
    <ContentMenuItemWrap className={className} {...props}>
      {children}
    </ContentMenuItemWrap>
  );
}

ContentMenuItem.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default ContentMenuItem;