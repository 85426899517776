import {createAction} from 'redux-actions';
import {
  SET_HOME_LIVE_EVENTS_SUGGESTION1,
  ADD_HOME_LIVE_EVENTS_SUGGESTION1,
  UPDATE_OCCURRENCE_LIVE_EVENT_SUGGESTION1,
} from './types';

export const setHomeLiveEventsSuggestion1 = createAction(SET_HOME_LIVE_EVENTS_SUGGESTION1);
export const addHomeLiveEventsSuggestion1 = createAction(ADD_HOME_LIVE_EVENTS_SUGGESTION1);
export const updateOccurrenceLiveEventsSuggestion1 = createAction(UPDATE_OCCURRENCE_LIVE_EVENT_SUGGESTION1);
