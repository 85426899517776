import {handleActions} from 'redux-actions';
import {differenceWith, pick, unionWith} from 'lodash';
import {
  SET_USER_EVENT_REGISTRATIONS,
  ADD_USER_EVENT_REGISTRATION,
  ADD_USER_EVENTS_REGISTRATION,
  DELETE_USER_EVENT_REGISTRATION,
  DELETE_USER_EVENTS_REGISTRATION,
} from './types';
import {LOGOUT} from "../auth/types";


const comparator = (itemA, itemB) => {
  return itemA.id === itemB.id;
};

const defaultFields = [
  'id',
  'createdAt',
  'updatedAt',
  'UserId',
  'EventId',
  'OccurrenceId'
];

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_USER_EVENT_REGISTRATIONS]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    const unionArr = unionWith(state.rows, payload, comparator);
    if (unionArr.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      reqParams: {setAll: true},
      rows: unionArr
    };
  }
  ,
  [ADD_USER_EVENTS_REGISTRATION]: (state, {payload}) => {
    if (!payload) {
      return state
    }
    const unionArr = unionWith(state.rows, payload, comparator);
    if (unionArr.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      rows: unionArr
    };
  },
  [ADD_USER_EVENT_REGISTRATION]: (state, {payload}) => {
    if (!payload) {
      return state
    }

    const newRegistration = pick(payload, defaultFields);

    const foundIndex = state.rows.findIndex((registration) => registration.id === newRegistration.id);

    if (foundIndex === -1) {
      const registrations = [...state.rows];
      registrations.push(newRegistration);
      return {
        ...state,
        rows: registrations
      };
    }

    return state;
  },
  [DELETE_USER_EVENT_REGISTRATION]: (state, {payload}) => {

    const foundIndex = state.rows.findIndex((registration) => {
      return registration.id === payload
    });

    if (foundIndex > -1) {
      const registrations = [...state.rows];
      registrations.splice(foundIndex, 1);
      return {
        ...state,
        rows: registrations
      };
    }

    return state;
  },

  [DELETE_USER_EVENTS_REGISTRATION]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    const diff = differenceWith(state.rows, payload, comparator);
    if (diff.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      rows: diff
    };
  },

  [LOGOUT]: () => {
    return initialState;
  }
}, initialState);