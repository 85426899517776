import {PERMISSIONS} from 'config/constants';

export default function isAnAdmin() {
  return [
    PERMISSIONS.security.SECURITY_RANK_10,
    PERMISSIONS.security.SECURITY_RANK_30,
    PERMISSIONS.security.SECURITY_RANK_50,
    PERMISSIONS.security.SECURITY_RANK_70,
    PERMISSIONS.security.SECURITY_RANK_90
  ];
}