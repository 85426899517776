import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addFavorite, deleteFavorite } from '../../reduxUtils/userFavorites/thunks';
import { getFavorite } from '../../reduxUtils/userFavorites/selectors';
import { errorHandler } from 'utilities/services';
import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import { setLoginModalStatus } from '../../reduxUtils/browserSettings/actions';

const FavoriteWrap = styled(Icon)`
  cursor: pointer;

  ${({ isFavorite }) =>
    isFavorite &&
    css`
      color: ${({ theme: { colors } }) => colors.lightGrey} !important;

      &:hover {
        color: ${({ theme: { colors } }) => colors.purpleLighten} !important;
        opacity: 1;
      }
    `}
`;

function Favorite({
  isLoggedIn,
  favorite = null,
  eventId = null,
  workshopId = null,
  addFavorite,
  deleteFavorite,
  notLoggedInCB,
  setLoginModalStatus,
  ...props
}) {
  const busyRef = useRef(false);
  // const [show, setShow] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (busyRef.current) return;

    if (!isLoggedIn) {
      setLoginModalStatus(true);
      return;
    }

    if (!workshopId && !eventId) {
      errorHandler('error on id');
      return;
    }
    if (!favorite) {
      busyRef.current = true;
      addFavorite({ eventId, workshopId })
        .catch(({ message }) => errorHandler(message))
        .finally(() => (busyRef.current = false));
    } else {
      busyRef.current = true;
      deleteFavorite(favorite.id)
        .catch(({ message }) => errorHandler(message))
        .finally(() => (busyRef.current = false));
    }
  };

  return (
    <>
      <FavoriteWrap
        isFavorite={!!favorite}
        {...props}
        onClick={onClick}
        iconName={!!favorite ? 'fas fa-heart fa-sm' : 'far fa-heart fa-sm'}
      />
    </>
  );
}

Favorite.propTypes = {
  favorite: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  eventId: PropTypes.number,
  workshopId: PropTypes.number,
  notLoggedInCB: PropTypes.func,
};

function mapStateToProps(state, { eventId, workshopId }) {
  return {
    favorite: getFavorite(state, eventId, workshopId),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addFavorite,
      deleteFavorite,
      setLoginModalStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Favorite));
