import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_USER_PAYMENT_METHOD, DELETE_USER_PAYMENT_METHOD,
  UPDATE_USER
} from './types';

import {
  LOGOUT
} from '../auth/types';

export const initialState = {
  id: null,
  email: '',
  status: '',
  provider: '',
  stripeAccountId: '',
  stripeAccountStatus: '',
  stripeCustomerId: '',
  stripeCustomerDPM: '',
  paymentMethods: [],
  //  zoomConnected: false,
};

export default handleActions({
  [UPDATE_USER]: (state, {payload}) => {
    return {...state, ...pick(payload, Object.keys(initialState))};
  },

  [SET_USER_PAYMENT_METHOD]: (state, {payload}) => {
    if (!payload) {
      return state
    }

    return {
      ...state,
      paymentMethods: payload
    };
  },

  [DELETE_USER_PAYMENT_METHOD]: (state, {payload}) => {

    const foundIndex = state.paymentMethods.findIndex((paymentMethod) => {
      return paymentMethod.id === payload
    });

    if (foundIndex > -1) {
      const paymentMethods = [...state.paymentMethods];
      paymentMethods.splice(foundIndex, 1);
      return {
        ...state,
        paymentMethods: paymentMethods
      };
    }

    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
