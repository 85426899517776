import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
// import Switch from "components/Switch";
// import {SettingFontSize} from "components/Chat/components/PollsContainer/components/JSXElements";
import TimerInput from "./components/TimerInput";

const Wrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const TimerInputStyled = styled(TimerInput)`
`;

//
// const SwitchStyle = styled(Switch)`
//   white-space: nowrap;
//   margin-left: 5px;
//
//   .switchLabel {
//     margin-right: 5px;
//   }
//
//   > div {
//     ${SettingFontSize};
//
//   }
//
//   .switchInput {
//     --dim: 30px;
//     background-color: ${({isActive, theme: {colors}}) => isActive ? colors.purple : colors.lightGrey};
//
//       //background-color: ${({theme: {colors}}) => colors.purple};
//   }
// `;

function NewPollSettings({
                           className,
                           update,
                           timer,
                           showOnChat,
                           alwaysShow,
                         }) {
  // const [showOnChatToggle, setShowOnChatToggle] = useState(showOnChat);
  //
  // function onSettingsChange(value) {
  //   update(value);
  // }

  return (
    <Wrap className={className}>
      <TimerInputStyled
        timer={timer}
        update={update}
      />

      {/*todo: add support in the future*/}
      {/*<SwitchStyle*/}
      {/*  label='Show on Chat'*/}
      {/*  isActive={showOnChatToggle}*/}
      {/*  name='showOnChat'*/}
      {/*  onChange={(e) => {*/}
      {/*    const {name, value} = e.target;*/}
      {/*    setShowOnChatToggle(value);*/}
      {/*    onSettingsChange({[name]: value});*/}
      {/*  }}*/}
      {/*  info={``}*/}
      {/*/>*/}
    </Wrap>
  );
}

NewPollSettings.propTypes = {
  className: PropTypes.string,
  update: PropTypes.func,
  timer: PropTypes.object,
  showOnChat: PropTypes.bool,
};

export default (React.memo(NewPollSettings));

