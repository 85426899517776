import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import theme from 'styles/theme';
import { HoverEffect, HoverStyle, scale } from 'styles/common';

import Icon from 'components/Icon';

const BarItemWrap = styled(NavLink)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 35px;
  margin: 0 -${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['margin', `0px -${theme.padding.pageD}px`]]]])}

  padding: 10px ${({ theme: { padding } }) => padding.pageM}px;
  ${scale([['768px', [['padding', `10px ${theme.padding.pageD}px`]]]])}

  color: ${({ theme: { colors } }) => colors.grayNew};

  &:hover {
    color: ${({ theme: { colors } }) => colors.darkGrey};
  }

  ${HoverEffect};

  &.is-active {
    ${HoverStyle};
    color: ${({ theme: { colors } }) => colors.darkGrey};
  }

  &.is-disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const BarItemIcon = styled(Icon)`
  width: 20px;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  ${({ isopened }) =>
    isopened
      ? css`
          margin-right: ${({ theme: { padding } }) => padding.pageM}px;
          ${scale([['768px', [['margin-right', `${theme.padding.pageD}px`]]]])}
        `
      : `margin-right: 0;`}
  color: ${({ theme: { colors } }) => colors.grayNew};

  ${BarItemWrap}:hover & {
    color: ${({ theme: { colors } }) => colors.darkGrey};
  }

  ${BarItemWrap}.is-active & {
    color: ${({ theme: { colors } }) => colors.tertiary};
  }
`;

const BarItemLabel = styled.div`
  white-space: nowrap;
`;

function BarItem({ className, match, linkTo, isOpened, label, iconName, isDisabled }) {
  const CN = classnames(className, { 'is-disabled': isDisabled });
  return (
    <BarItemWrap
      className={CN}
      // activeClassName="is-active"
      exact
      to={linkTo}
      onClick={(e) => {
        isDisabled && e.preventDefault();
      }}
    >
      {iconName && <BarItemIcon isopened={+isOpened} iconName={iconName} />}
      {isOpened && <BarItemLabel>{label}</BarItemLabel>}
    </BarItemWrap>
  );
}

BarItem.propTypes = {
  match: PropTypes.object,
  linkTo: PropTypes.string.isRequired,
  isOpened: PropTypes.bool,
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string,
};

export default BarItem;
