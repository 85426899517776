import {createAction} from 'redux-actions';
import {
  SET_EVENT_OCCURRENCES,
  ADD_EVENT_OCCURRENCE,
  UPDATE_EVENT_OCCURRENCE,
  DELETE_EVENT_OCCURRENCE
} from './types';

export const setEventOccurrencesAction = createAction(SET_EVENT_OCCURRENCES);
export const addEventOccurrenceAction = createAction(ADD_EVENT_OCCURRENCE);
export const updateEventOccurrenceAction = createAction(UPDATE_EVENT_OCCURRENCE);
export const deleteEventOccurrenceAction = createAction(DELETE_EVENT_OCCURRENCE);