import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ResizeableTextBox from 'components/ResizeableTextBox';
import PropTypes from 'prop-types';
// import Picker from 'emoji-picker-react';
import Icon from 'components/Icon';
import { CHAT_MESSAGE_MAX_INPUT } from 'config/constants';
import { faSmile } from '@fortawesome/free-regular-svg-icons';

const ChatFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  border-top: none;
  margin: 5px 0 10px 0;
  padding: 0 5px;
`;

const SendIcon = styled.div`
  height: 75%;
  width: 75%;
  max-height: 75vw;
  max-width: 75vh;
  position: relative;
  margin: 0 auto;
  border-radius: 10%;

  &::before {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    height: 60%;
    width: 100%;
    -webkit-clip-path: polygon(10% 0%, 90% 50%, 10% 100%, 20% 55%, 50% 50%, 20% 45%);
    -moz-clip-path: polygon(10% 0%, 90% 50%, 10% 100%, 20% 55%, 50% 50%, 20% 45%);
    clip-path: polygon(10% 0%, 90% 50%, 10% 100%, 20% 55%, 50% 50%, 20% 45%);
    left: 1%;
    top: 21%;
  }
`;

const ButtonWrap = styled.button`
  display: flex;
  --dim: 25px;
  width: var(--dim);
  height: var(--dim);
  min-width: var(--dim);
  min-height: var(--dim);

  border: none;
  padding: 0;
  border-radius: 50%;
  background: ${({ theme: { colors } }) => colors.grayLightenX2};

  &.disabled {
    opacity: 0.6;
  }

  &:hover:not(.disabled) {
    background: ${({ theme: { colors } }) => colors.grayLighten};
  }

  align-items: center;
`;

const Emoji = styled(Icon)`
  font-size: 23px;
  margin: 0 5px;
  color: ${({ theme: { colors } }) => colors.grayLighten};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ theme: { colors } }) => colors.grayNew};
        cursor: pointer;
      }
    `};
`;

const EmojiWrap = styled.div`
  position: relative;
`;

const StyleResizeableTextBox = styled(ResizeableTextBox)`
  border-radius: 10px;
  margin: 0 5px 0 0;
  padding: 0 3px;
  background-color: #f2f2f2;

  &:focus,
  &:focus-within {
    outline: ${({ theme: { colors } }) => colors.violetPurple} solid 2px;
  }

  .TextArea {
    font-size: 13px;

    background-color: #f2f2f2;

    &::placeholder {
      color: #a9a9a9;
      font-size: 13px;
    }
  }
`;

const BottomWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
`;

const Warning = styled.div`
  position: absolute;
  left: 5px;
  bottom: 40px;
  color: ${({ theme: { colors } }) => colors.error};
`;

let Picker;

function ChatInput({
  showWarningMsg,
  isLoggedIn,
  onSendMessageClick,
  isChatDetach,
  isSocketConnected,
  isPublished,
  isLoading,
  isDisabled,
  isConnecting,
  isConnected,
  ...props
}) {
  const [currentMessage, setCurrentMessage] = useState('');
  const inputRef = useRef(null);
  const emojiRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);

  const onEmojiClick = (emojiObject) => {
    const [start, end] = [inputRef.current.selectionStart, inputRef.current.selectionEnd];
    setCurrentMessage((current) => {
      return current.substring(0, start) + emojiObject.emoji + current.substring(end);
    });
    inputRef.current.focus();
    inputRef.current.setRangeText(emojiObject.emoji, start, end, 'end');
    setShowEmojis(false);
  };

  useEffect(() => {
    if (inputRef.current && isChatDetach) {
      inputRef.current.focus();
    }
  }, [isChatDetach]);

  useEffect(() => {
    if (!Picker) {
      // async import
      import('emoji-picker-react').then((module) => {
        Picker = module.default;
      });
    }
  }, []);

  function getPlaceholder() {
    if (!isLoggedIn) {
      return 'You must sign in to send a message';
    }

    if (!isPublished) {
      return 'room is available for publish content only';
    }

    if (isSocketConnected === false) {
      return 'not connected to chat server';
    }

    if (isLoading || isConnecting) {
      return 'connecting...';
    }

    if (!isConnected) {
      return 'not connected to room';
    }

    return 'Send a message';
  }

  const disableInput =
    !isConnected ||
    isLoading ||
    isDisabled ||
    !isLoggedIn ||
    isSocketConnected === false ||
    !isPublished;
  const disableEmoji =
    !isConnected || isLoading || isDisabled || !isLoggedIn || isSocketConnected === false;
  const disableSendClick =
    !isConnected || !currentMessage || !isLoggedIn || !isSocketConnected || !isPublished;

  return (
    <ChatFooter {...props}>
      {isPublished && (
        <EmojiWrap
          ariaHidden="true"
          tabIndex={2}
          ref={emojiRef}
          // onBlur={(event) => {
          //   if (!event.currentTarget.contains(event.relatedTarget)) {
          //     setShowEmojis(false);
          //     inputRef.current.focus();
          //   }
          // }}
        >
          {showEmojis && Picker && (
            <Picker
              previewConfig={{ showPreview: false }}
              id="picker"
              open={true}
              width={'auto'}
              height={'350px'}
              tabIndex={3}
              style={{
                position: 'absolute',
                top: '-5px',
                transform: 'translate(-100%, -100%)',
              }}
              onEmojiClick={onEmojiClick}
            />
          )}
        </EmojiWrap>
      )}

      {
        <Warning style={{ display: showWarningMsg ? 'block' : 'none' }}>
          you are too fast...
        </Warning>
      }
      <BottomWrap>
        <Emoji
          id="emojiButton"
          disabled={disableEmoji}
          fontAwesomeIcon={faSmile}
          onClick={(e) => {
            e.stopPropagation();
            if (disableEmoji) {
              return false;
            }
            setShowEmojis((current) => {
              if (current) {
                inputRef.current.focus();
              }
              // return true;
              return !current;
            });
          }}
        ></Emoji>

        <StyleResizeableTextBox
          id="styleResizeableTextBox"
          rowsInit={1}
          minRows={1}
          maxRows={5}
          disabled={disableInput}
          onInput={setCurrentMessage}
          placeholder={getPlaceholder()}
          inputRef={inputRef}
          maxLength={CHAT_MESSAGE_MAX_INPUT}
          value={currentMessage}
          onEnterClick={() => {
            setShowEmojis(false);
            onSendMessageClick(currentMessage, setCurrentMessage);
            inputRef.current.focus();
          }}
        />
        <ButtonWrap
          className={disableSendClick ? 'disabled' : ''}
          disabled={disableSendClick}
          onClick={(e) => {
            setShowEmojis(false);
            inputRef.current?.focus();
            e.preventDefault();
            onSendMessageClick(currentMessage, setCurrentMessage);
          }}
        >
          <SendIcon />
        </ButtonWrap>
      </BottomWrap>
    </ChatFooter>
  );
}

ChatInput.propTypes = {
  isLoggedIn: PropTypes.bool,
  onSendMessageClick: PropTypes.func,
};

export default React.memo(ChatInput);
