import {errorHandler} from 'utilities/services';
import {CHAT_ROOMS, CHAT_ROOMS_LABELS, USERS_ROOM} from 'utilities/chat.utils';
import chatSendMessageToRoom from 'websockets/sendActions/chat/chat.send-message.action';
import chatJoinRoom from 'websockets/sendActions/chat/chat.join-room.action';
import chatJoinUsersRoom from 'websockets/sendActions/chat/chat.join-users-room.action';
import chatLeaveUsersRoom from 'websockets/sendActions/chat/chat.leave-users-room.action';
import chatLeaveRoom from 'websockets/sendActions/chat/chat.leave-room.action';
import chatSocketLogOut from "websockets/sendActions/chat/chat.socket-logOut.action";
import {addElementFromArray, removeElementFromArray} from "utilities/array";
import pollsJoinRoom from "websockets/sendActions/polls/polls.join-room.action";
import {setPollsAction} from "../reduxUtils/polls/actions";
import {getStore} from "utilities/store";
import pollsLeaveRoom from "websockets/sendActions/polls/polls.leave-room.action";
import pollsSocketLogOut from "websockets/sendActions/polls/polls.socket-logOut.action";

let socket = {}

let init = false;
let userId = undefined;
let fullName = undefined;
let avatar = undefined;
let channelId = undefined;
let eventId = undefined;
let occurrenceId = undefined;
let workshopId = undefined;
let chatRoomsLabels = {};
let chatRooms = CHAT_ROOMS;
let usersRoom = USERS_ROOM;
let currentChatRoom = CHAT_ROOMS.LOBBY;
let setUsersRoom = undefined;
let setMessageList = undefined;
let setUsersCount = undefined;
let setChatRoomsLabels = undefined;
let setSelectedRoom = undefined;
let setIsConnecting = undefined;

function updateCurrentStates(usersCount, messageList) {
  messageList !== undefined && (setMessageListState(messageList));
  usersCount !== undefined && (setUsersCountState(usersCount));
}

function setChatRoomsLabelsState(state) {
  setChatRoomsLabels && setChatRoomsLabels(state);
}

function setUsersCountState(state) {
  setUsersCount && setUsersCount(state);
}

function setMessageListState(state) {
  setMessageList && setMessageList(state);
}

function setUsersRoomState(state) {
  setUsersRoom && setUsersRoom(state);
}

function setSelectedRoomState(state) {
  setSelectedRoom && setSelectedRoom(state);
}

function setIsConnectingState(state) {
  setIsConnecting && setIsConnecting(state);
}

export function getChatInstance() {
  return chat;
}

const chat = {

  init(_userId, _fullName, _avatar, _channelId, _eventId, _occurrenceId, _workshopId) {
    userId = _userId;
    fullName = _fullName;
    avatar = _avatar;
    channelId = _channelId;
    eventId = _eventId;
    occurrenceId = _occurrenceId;
    workshopId = _workshopId;
    init = true;

  },
  // getChatIds() {
  //   return {
  //     channelId,
  //     eventId,
  //     occurrenceId,
  //     workshopId
  //   };
  // },
  updateUsersRoom(data) {
    if (!data) {
      return;
    }

    const {room, userId, userData, action} = data;
    if (!room || !userId) {
      console.log(`updateUsersRoom: received bad data`, room, userId, userData);
      return;
    }

    if (room === usersRoom.room) {
      let arr = null;
      if (action === 'add') {
        if (!userData) {
          console.log(`updateUsersRoom: received bad userData`);
          return;
        }
        arr = addElementFromArray(usersRoom.usersInRoom, {...userData, userId}, 'userId');
      }

      if (action === 'leave') {
        arr = removeElementFromArray(usersRoom.usersInRoom, 'userId', userId);
      }

      if (arr && usersRoom.usersInRoom !== arr) {
        usersRoom.usersInRoom = arr;
        setUsersRoomState({...usersRoom});
      }
    } else {
      console.log(`update-users-rooms got different room id`, data)
    }
  },
  updateUsersCount(data) {
    if (!data) {
      return;
    }
    const {roomKey, room, usersCount} = data;

    if (!roomKey || !room) {
      console.log(`updateUsersCount: received bad data`);
      return;
    }

    if (room === chatRooms[roomKey].room) {
      chatRooms[roomKey].usersCount = usersCount;
    }

    if (currentChatRoom.room === room) {
      setUsersCountState(currentChatRoom.usersCount);
    }
  }, receiveMessage(data) {

    if (!data || !data.roomKey || !chatRooms[data.roomKey]) {
      return;
    }
    chatRooms[data.roomKey].messages = [...chatRooms[data.roomKey].messages, data];
    if (data.room === currentChatRoom.room) {
      setMessageListState(chatRooms[data.roomKey].messages);
    } else {
      chatRooms[data.roomKey].unreadMessages += 1;
      chatRoomsLabels[data.roomKey].unreadMessages += 1;
      setChatRoomsLabelsState({...chatRoomsLabels});
    }
  },
  isInit() {
    return init;
  },

  initChatRoomsLabels(_chatRoomsLabels) {
    chatRoomsLabels = {..._chatRoomsLabels};
  },

  getChatRoomsLabels() {
    return chatRoomsLabels;
  },

  updateSetUsersRoom(_setUsersRoom) {
    setUsersRoom = _setUsersRoom;
  },

  updateSetMessageList(_setMessageList) {
    setMessageList = _setMessageList;
    setMessageList(currentChatRoom.messages)
  },

  updateSetChatRoomsLabels(_setChatRoomsLabels) {
    setChatRoomsLabels = _setChatRoomsLabels;
    setChatRoomsLabels(chatRoomsLabels);
  }, updateSetSelectedRoom(_setSelectedRoom) {
    setSelectedRoom = _setSelectedRoom;
  },

  updateSetIsConnecting(_setIsConnecting) {
    setIsConnecting = _setIsConnecting;
  },

  updateSetUsersCount(_setUsersCount) {
    setUsersCount = _setUsersCount;
    setUsersCountState(currentChatRoom.usersCount)
  },

  isAnyRoomConnecting() {
    return !!Object.values(chatRooms).find((room) => room.isConnecting);
  },
  asyncEmit(eventName, data) {
    return new Promise(function (resolve, reject) {
      socket.emit(eventName, data, (err) => {
        if (err) {
          // console.log(`emit error`, err)
          reject();
        }
        // console.log(`asyncEmit resolved`)
        resolve();
      });
      setTimeout(reject, 20 * 1000);
    });
  },

  async sendMessage(message) {
    const {room} = message;

    chatSendMessageToRoom(room, message)
      .then(res => {

      })
      .catch(err => {
        errorHandler(err);
      })


    //todo:we can check if message was delivered successfully
    // asyncEmit('send_message', message).then(() => {
    //   console.log(`send message success`)
    // }).catch(err => {
    //   console.log(`send message fail`, message, err)
    // })

    chatRooms[message.roomKey].messages = [...chatRooms[message.roomKey].messages, message]
    setMessageListState(chatRooms[message.roomKey].messages);
    return true;
  },

  getCurrentUsersRoom() {
    return usersRoom;
  },

  getCurrentRoom() {
    return currentChatRoom;
  },

  getChatRooms() {
    return chatRooms;
  },
  getChatRoom(key) {
    return chatRooms[key];
  },
  async connectToRoom(room, reconnect) {
    if (!room || !room.key || !room.room) {
      console.log(`connectToRoom could not join room data is not complete`, room)
      return Promise.resolve();
    }

    if (!reconnect) {
      if (room.isConnected) {
        return Promise.resolve();
      }
    }

    room.isConnecting = true;

    setIsConnectingState(true);

    const data = {
      room: room.room,
      roomKey: room.key,
      userId: userId,
      userData: {
        userId: userId,
        avatar: avatar,
        fullName: fullName
      }
    }

    chatJoinRoom(room.room, data)
      .then(res => {
        const {messages = [], usersCount = 0} = res || {};
        room.messages = messages;
        room.usersCount = usersCount;
        console.log(`successfully reconnect`, room.room)
        room.isConnecting = false;
        room.isConnected = true;
        const thisRoomLabel = chatRoomsLabels[room.key];
        thisRoomLabel.isConnecting = false;
        setChatRoomsLabelsState({...chatRoomsLabels});

        setIsConnectingState(this.isAnyRoomConnecting());

        if (currentChatRoom.room === room.room) {
          updateCurrentStates(currentChatRoom.usersCount, currentChatRoom.messages);
          setSelectedRoomState({...currentChatRoom});
        }
      })
      .catch(err => {
        const thisRoomLabel = chatRoomsLabels[room.key];
        thisRoomLabel.isConnecting = false;
        setChatRoomsLabelsState({...chatRoomsLabels});
        room.isConnecting = false;
        room.isConnected = false;
        setIsConnectingState(this.isAnyRoomConnecting());
        if (currentChatRoom.room === room.room) {
          setSelectedRoomState({...currentChatRoom})
        }

        errorHandler(err);
        console.log(`reconnect failed`, room.room, err)
      })
  },

  async joinPollRoom(room) {
    return new Promise(function (resolve, reject) {
      if (!room || !room.key || !room.room) {
        console.log(`could not join room missing data`, room)
        return reject('could not join room');
      }
      const thisRoom = chatRooms[room.key];

      if (!thisRoom.isPublished) {
        return reject('could not join room for unpublished content');
      }

      if (thisRoom.isConnected) {
        if (room.room === thisRoom.room) {
          currentChatRoom = thisRoom;
          return resolve(currentChatRoom);
        }
      }

      thisRoom.room = room.room;
      thisRoom.isConnecting = true;
      const thisRoomLabel = chatRoomsLabels[room.key];
      thisRoomLabel.isConnecting = true;
      setChatRoomsLabelsState({...chatRoomsLabels});
      setIsConnectingState(true);

      const data = {
        room: room.room,
        roomKey: room.key,
        userId: userId,
        EventId: eventId,
        OccurrenceId: occurrenceId,
        WorkshopId: workshopId
      }

      pollsJoinRoom(room.room, data)
        .then(res => {
          const {polls = []} = res || {};
          const {dispatch} = getStore();
          dispatch(setPollsAction(polls));
          currentChatRoom = chatRooms[room.key];
          const thisRoomLabel = chatRoomsLabels[room.key];

          if (thisRoomLabel) {
            thisRoomLabel.isConnecting = false;
            setChatRoomsLabelsState({...chatRoomsLabels});
          }

          currentChatRoom.room = room.room;
          currentChatRoom.isConnected = true;
          currentChatRoom.isConnecting = false;

          setIsConnectingState(false);
          resolve(currentChatRoom);
        })
        .catch(err => {
          thisRoom.isConnected = false;
          thisRoom.isConnecting = false;
          const thisRoomLabel = chatRoomsLabels[room.key];
          thisRoomLabel.isConnecting = false;
          setChatRoomsLabelsState({...chatRoomsLabels});
          setIsConnectingState(false);
          console.log(`chatJoinRoom: exception `, err)
          reject(err);
        })
    })
  },
  async joinRoom(room) {
    return new Promise(function (resolve, reject) {
      if (!room || !room.key || !room.room) {
        console.log(`could not join room missing data`, room)
        return reject('could not join room');
      }
      const thisRoom = chatRooms[room.key];
      //
      if (!thisRoom.isPublished) {
        return reject('could not join room for unpublished content');
      }

      if (thisRoom.isConnected) {
        if (room.room === thisRoom.room) {
          currentChatRoom = thisRoom;

          if (chatRoomsLabels[room.key]) {
            chatRoomsLabels[room.key].unreadMessages = 0;
            setChatRoomsLabelsState({...chatRoomsLabels})
          }

          updateCurrentStates(currentChatRoom.usersCount, currentChatRoom.messages)

          return resolve(currentChatRoom);
        } else {
          this.leaveRoom(currentChatRoom);
        }
      }

      thisRoom.room = room.room;
      thisRoom.isConnecting = true;
      const thisRoomLabel = chatRoomsLabels[room.key];
      thisRoomLabel.isConnecting = true;
      setChatRoomsLabelsState({...chatRoomsLabels});
      setIsConnectingState(true);

      const data = {
        room: room.room,
        roomKey: room.key,
        userId: userId,
        userData: {
          userId: userId,
          avatar: avatar,
          fullName: fullName
        }
      }

      chatJoinRoom(room.room, data)
        .then(res => {
          const {messages = [], usersCount = 0} = res || {};
          currentChatRoom = chatRooms[room.key];
          const thisRoomLabel = chatRoomsLabels[room.key];

          if (thisRoomLabel) {
            thisRoomLabel.unreadMessages = 0;
            thisRoomLabel.isConnecting = false;
            setChatRoomsLabelsState({...chatRoomsLabels});
          }

          currentChatRoom.unreadMessages = 0;
          currentChatRoom.room = room.room;
          currentChatRoom.isConnected = true;
          currentChatRoom.isConnecting = false;
          currentChatRoom.usersCount = usersCount;
          currentChatRoom.messages = messages;

          setIsConnectingState(false);
          updateCurrentStates(currentChatRoom.usersCount, currentChatRoom.messages);
          resolve(currentChatRoom);
        })
        .catch(err => {
          thisRoom.isConnected = false;
          thisRoom.isConnecting = false;
          const thisRoomLabel = chatRoomsLabels[room.key];
          thisRoomLabel.isConnecting = false;
          setChatRoomsLabelsState({...chatRoomsLabels});
          setIsConnectingState(false);
          console.log(`chatJoinRoom: exception `, err)
          reject(err);
        })
    })
  },

  async joinUsersRoom(room, reconnect) {
    if (!room || !room.key || !room.room) {
      console.log(`joinUsersRoom: could not show users in room missing data`, room)
      errorHandler('could not show users in room');
      return
    }
    const thisRoom = chatRooms[room.key];

    if (!thisRoom.isPublished) {
      errorHandler('could not get users for unpublished content');
      return
    }

    if (!reconnect) {
      if (usersRoom.isConnected) {
        if (room.room === usersRoom.room) {
          usersRoom.visible = true;
          setUsersRoomState({...usersRoom});
          return;
        } else {
          this.leaveUsersRoom(true);
        }
      }
    }

    usersRoom.isConnecting = true;
    usersRoom.room = room.room;
    usersRoom.key = room.key;

    setUsersRoomState({...usersRoom});

    chatJoinUsersRoom(room.room, {
      room: room.room, roomKey: room.key, userId: userId,
    })
      .then(res => {
        const {usersInRoom = {}} = res || {};
        usersRoom.usersInRoom = Object.values(usersInRoom);

        usersRoom.room = room.room;
        usersRoom.key = room.key;
        usersRoom.isConnected = true;
        usersRoom.isConnecting = false;
        if (!reconnect) {
          usersRoom.visible = true;
        }
        setUsersRoomState({...usersRoom});
      })
      .catch(err => {
        //todo: think what happens on fail and reconnect
        usersRoom.isConnecting = false;
        usersRoom.isConnected = false;
        setUsersRoomState({...usersRoom});
        errorHandler(err);
      })
  },

  async leaveUsersRoom(keepUsersRoomState) {
    const roomId = usersRoom.room;
    const roomKey = usersRoom.key;
    // const connected = usersRoom.isConnected;

    usersRoom.room = '';
    usersRoom.key = '';
    usersRoom.usersInRoom = [];
    usersRoom.reconnect = false;
    usersRoom.visible = (keepUsersRoomState) ? usersRoom.visible : false;
    const isConnected = usersRoom.isConnected;
    usersRoom.isConnected = false;

    setUsersRoomState({...usersRoom})

    // if (!connected || reconnect) {
    //   return;
    // }

    if (isConnected) {
      chatLeaveUsersRoom(roomId, {
        room: roomId, roomKey: roomKey, userId: userId,
      });
    }
  },
  clearRoom(room) {
    const chatRoomInner = chatRooms[room.key];
    if (!chatRoomInner.isConnected) {
      return;
    }

    chatRoomInner.room = '';
    chatRoomInner.isConnected = false;
    chatRoomInner.messages = [];
    chatRoomInner.usersCount = 0;
    chatRoomInner.reconnect = false;
  },

  async leaveRoom(chatRoom) {
    const chatRoomInner = chatRooms[chatRoom.key];

    if (!chatRoomInner.isConnected) {
      return;
    }

    const roomId = chatRoomInner.room;
    const roomKey = chatRoomInner.key;
    this.clearRoom(chatRoom);
    if (roomKey === CHAT_ROOMS_LABELS.POLLS.key) {
      pollsLeaveRoom(roomId, {
        room: roomId, roomKey: roomKey, userId: userId,
      });
    } else {
      chatLeaveRoom(roomId, {
        room: roomId, roomKey: roomKey, userId: userId,
      });
    }


    updateCurrentStates(currentChatRoom.usersCount, currentChatRoom.messages);

  },

  leaveRooms(rooms) {
    Object.values(rooms).forEach((room) => {
      this.leaveRoom(room);
    });
  },
  socketLogOut() {
    chatSocketLogOut();
    pollsSocketLogOut();
  },
  async reconnectToRooms() {
    Object.values(chatRooms).forEach((room) => {
      if (room.reconnect || room.isConnected || room.isConnecting) {
        room.reconnect = true;
        if (room.key === CHAT_ROOMS_LABELS.POLLS.key) {
          this.joinPollRoom(room);
        } else {
          this.connectToRoom(room, true);
        }
      }
    });

    if (usersRoom.reconnect || usersRoom.isConnected || usersRoom.isConnecting) {
      usersRoom.reconnect = true;
      this.joinUsersRoom(usersRoom, true)
    }
  },

}


