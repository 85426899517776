import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { EVENT_STATUS, WORKSHOP_EVENT_BUY_NOTICE } from 'config/constants';
import {
  isEventPublished,
  getEventBaseUrl,
  getEventUrl,
  getEventEditUrl,
  getEventManageUrl,
  getEventInspectUrl,
  getEventRegistrationLogic,
  canWatchLiveEvent,
  onEventBuyClick,
} from 'utilities/event';
import { sortByDateFlatArray } from 'utilities/array';
import { errorHandler } from 'utilities/services';
import { getSocialMediaLink, isFree, isMobileDevice } from 'utilities/general';
import { processDuration, processDurationHumanize } from 'utilities/date';

import RoundCheckbox from 'components/Inputs/RoundCheckbox';

import {
  TileWrap,
  TileCover,
  TileCoverLink,
  TileCoverActions,
  TileCoverActionsRight,
  TileActionsBar,
  TileActionsItem,
  TileCoverHeaderLeft,
  TileDescription,
  TileDescriptionHeader,
  TileDescriptionTitle,
  TileFilter,
  TileDescriptionLineText,
  TileDescriptionEnlargeSummary,
  TileDescriptionEnlargeSummaryButton,
  TileDescriptionSocial,
  TileDescriptionDuration,
  TileSeparator,
  TileButtonCustom,
  TileSeparatorVertical,
  TileDescriptionLineTextWrapper,
  TileCoverWrapper,
  TileTagWrap,
  TileDescriptionDurationTime,
  TileChannelUserAvatarCss,
  TileChannelWrapCss,
  TileCustomUserNameStyle,
  TileDurationLabelCss,
  TileDescriptionFavorite,
  TileDescriptionShare,
  TileFollowersRatingCss,
  TileDurationCss,
  TileButtonCss,
  TilePrice,
  TileLive,
  TileDescriptionUsersCount,
  TileProgressBar,
} from '../components/JSXElements';
import Duration from 'components/Duration';
import OccurrencesCarousel from 'components/Tiles/components/OccurrencesCarousel';
import StyledChannel from 'components/StyledChannel';
import { flatten } from 'lodash';
import StarRating from 'components/Tiles/components/StarRating';

import {
  addEventRegistration,
  createEventRegistration,
  deleteEventRegistration,
} from '../../../reduxUtils/userEventRegistrations/thunks';
import { refundContent } from '../../../reduxUtils/user/thunks';

import { getEventRegistration } from '../../../reduxUtils/userEventRegistrations/selectors';
import styled from 'styled-components';
import EventRegButton from 'components/EventRegButton';
import isOwner from 'permissions/actionFunctions/admins/is-owner';
import isAdmin from 'permissions/actionFunctions/admins/is-admin';
import { getUserCurrency } from '../../../reduxUtils/userSettings/selectors';
import canView from 'permissions/actionFunctions/events/can-view';
import Payment from 'components/Payment';
import { getPlayTime } from 'utilities/vod';
import { TILE_COVER_WIDTH } from 'styles/common';
import { updateOccurrenceLiveEvents } from '../../../reduxUtils/homeLiveEvents/actions';
import { updateOccurrenceLiveEventsSuggestion1 } from '../../../reduxUtils/homeLiveEventsSuggestion1/actions';
import { updateOccurrenceLiveEventsSuggestion2 } from '../../../reduxUtils/homeLiveEventsSuggestion2/actions';
import { updateOccurrenceHomeUpcomingEvent } from '../../../reduxUtils/homeUpcomingEvents/actions';
import { updateOccurrenceHomeFavorite } from '../../../reduxUtils/homeFavorites/actions';

const EventTileRegButton = styled(EventRegButton)`
  ${TileButtonCss};
`;

const isMobileDeviceVar = isMobileDevice();
const SUMMARY_EXCERPT_LENGTH = (TILE_COVER_WIDTH - 20) / 3; // we remove the padding and a third is enough for chars

function EventTile({
  className,
  userId,
  accessControls,
  userCurrency,
  isMobile,
  isLoggedIn,
  isAdminView,
  content,
  eventRegistrations = null,
  addEventRegistration,
  createEventRegistration,
  deleteEventRegistration,
  refundContent,
  updateOccurrenceLiveEvents,
  updateOccurrenceLiveEventsSuggestion1,
  updateOccurrenceLiveEventsSuggestion2,
  updateOccurrenceHomeUpcomingEvent,
  updateOccurrenceHomeFavorite,
  setDialog,
  setGoogleDialog,
  handleSelect = () => {},
  handlePrivacy = () => {},
  handleVisibility = () => {},
  handleDelete = () => {},
  ...props
}) {
  const navigate = useNavigate();

  const {
    isSelected,
    id,
    isLive,
    allowDropIn,
    isPrivate,
    paymentType,
    title,
    summary,
    coverPhotoHomePage,
    price,
    currency,
    rating,
    occurrences,
    duration,
    user = {},
    UserId,
    WorkshopId,
    status,
    categories,
  } = content;

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function resetBuyDialog() {
    setBuyDialog(defaultBuyDialogState);
  }

  const defaultBuyDialogState = {
    show: false,
    header: '',
    subHeader: '',
    refundLabel: '',
    eventId: null,
    occurrenceId: null,
    workshopId: null,
    occurrence: null,
    title: '',
    coverPhotoHomePage: '',
    price: 0,
    currency: null,
    userCurrency: null,
    content: null,
    onCancel: resetBuyDialog,
    onHide: resetBuyDialog,
    onComplete: () => {},
  };

  const [buyDialog, setBuyDialog] = useState(defaultBuyDialogState);

  const flattenOcc = useMemo(() => {
    return flatten(occurrences).sort(sortByDateFlatArray);
  }, [occurrences]);

  const adminOrOwner =
    UserId && userId
      ? isOwner(UserId, null, null, userId) || isAdmin(id, null, userId, accessControls)
      : false;

  const occurrenceObj = flattenOcc && flattenOcc[0];
  const [showInfo, setShowInfo] = useState(false);
  const [occurrenceId, setOccurrenceId] = useState(occurrenceObj?.id);
  const occurrence = flattenOcc.find((el) => el.id === occurrenceId);
  const isFreeContent = isFree(paymentType);
  const isReady = EVENT_STATUS.STARTED.key !== status;
  const editUrl = getEventEditUrl(UserId, WorkshopId, id);
  // const occurrenceId = occurrence && occurrence.id;
  const date = occurrence && occurrence.date;
  const contentUrl = getEventUrl(id, occurrenceId, adminOrOwner);
  const managerUrl = getEventManageUrl(id);
  const inspectUrl = getEventInspectUrl(id);
  const isPublished = isEventPublished(status);
  const isWorkshopEvent = !!WorkshopId;
  const actionHandlePrivacy = {};
  const isOverFollow = summary.length >= SUMMARY_EXCERPT_LENGTH;

  let registrationsCount = occurrence?.registrationsCount || 0;

  useEffect(() => {
    setOccurrenceId(occurrenceObj?.id);
  }, [occurrenceObj, setOccurrenceId]);

  const redirectTo = isFree(paymentType)
    ? getEventBaseUrl(id, true)
    : getEventInspectUrl(id, null, true);
  const shareUrl = getSocialMediaLink({ redirectTo, EventId: id });

  if (!isWorkshopEvent) {
    actionHandlePrivacy.onTogglePrivate = (e) => {
      e.stopPropagation();
      e.preventDefault();
      return handlePrivacy(id, true, !isPrivate);
    };
  }

  function onClickCover() {
    if (!isLoggedIn) {
      return inspectUrl;
    }

    if (isAdminView) {
      return managerUrl;
    }

    if (!isLive && isFreeContent) {
      return contentUrl;
    }

    if (adminOrOwner && isWorkshopEvent) {
      return contentUrl;
    }

    if (isLive && date && flattenOcc.length === 1) {
      let eventRegLogic = getEventRegistrationLogic({
        date,
        duration,
        eventId: id,
        occurrenceId,
        WorkshopId,
        registrations: eventRegistrations,
        isFree: isFreeContent,
        allowDropIn,
      });

      if (
        canWatchLiveEvent({
          isLoggedIn: true,
          adminOrOwner,
          accessControls,
          ...eventRegLogic,
        })
      ) {
        return contentUrl;
      }
    }

    return inspectUrl;
  }

  function onCompletePaymentVod(transaction) {
    navigate(contentUrl);
  }

  function onClickJoin() {
    if (!isReady) {
      return;
    }

    if (!isLoggedIn) {
      navigate(inspectUrl);
      return;
    }

    //for vod event
    if (isFreeContent || adminOrOwner || canView(id, null, null, accessControls)) {
      navigate(contentUrl);
      return;
    }

    if (!isFreeContent) {
      if (isWorkshopEvent) {
        errorHandler(WORKSHOP_EVENT_BUY_NOTICE);
        return;
      }
      onBuyClick(onCompletePaymentVod);
    }
  }

  function onBuyClick(onComplete, occurrence) {
    onEventBuyClick({
      setBuyDialog,
      onComplete,
      isLive,
      occurrence,
      id,
      title,
      coverPhoto: coverPhotoHomePage,
      price,
      currency,
      userCurrency,
      content,
    });
  }

  function updateReduxOccurrence(payload) {
    updateOccurrenceLiveEvents(payload);
    updateOccurrenceLiveEventsSuggestion1(payload);
    updateOccurrenceLiveEventsSuggestion2(payload);
    updateOccurrenceHomeUpcomingEvent(payload);
    updateOccurrenceHomeFavorite(payload);
  }

  function updateParentComponentOnRegister() {
    const payload = {
      ...occurrence,
      registrationsCount: registrationsCount + 1,
    };
    updateReduxOccurrence(payload);
  }

  function updateParentComponentOnRemoveRegister() {
    const payload = {
      ...occurrence,
      registrationsCount: registrationsCount - 1,
    };
    updateReduxOccurrence(payload);
  }

  return (
    <>
      {buyDialog.show && (
        <Payment
          isMobile={isMobile}
          {...buyDialog}
          onCancel={resetBuyDialog}
          onHide={buyDialog.onHide}
          onComplete={buyDialog.onComplete}
        />
      )}
      <TileWrap isReady={isReady} isEvent isMobileDevice={isMobileDeviceVar} className={className}>
        <TileCoverWrapper>
          <TileCover coverUrl={coverPhotoHomePage} showInfo={showInfo}>
            <TileFilter />
            <TileCoverLink to={onClickCover()} />

            {isAdminView && (
              <TileCoverActions>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <RoundCheckbox
                    onChange={({ target }) => handleSelect(id, true, target.value)}
                    value={isSelected}
                  />
                </div>
                <TileCoverActionsRight>
                  <TileActionsItem
                    isActive={isPublished}
                    iconName={isPublished ? 'fas fa-eye-slash' : 'fas fa-eye'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      return handleVisibility(id, true, !isPublished);
                    }}
                  />
                  <TileActionsBar
                    isPrivate={isPrivate}
                    isPublished={isPublished}
                    managerUrl={managerUrl}
                    editUrl={editUrl}
                    onManageClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(managerUrl);
                    }}
                    onEditClick={(e) => {
                      e.preventDefault();
                      navigate(editUrl);
                    }}
                    onToggleVisibility={(e) => {
                      e.preventDefault();
                      return handleVisibility(id, true, !isPublished);
                    }}
                    onDeleteClick={(e) => {
                      e.preventDefault();
                      return handleDelete(id, true, title);
                    }}
                    {...actionHandlePrivacy}
                  />
                </TileCoverActionsRight>
              </TileCoverActions>
            )}

            <TileCoverHeaderLeft>
              {isLive && <TileLive />}
              {!isWorkshopEvent && (
                <TilePrice
                  price={price}
                  currency={currency}
                  userCurrency={userCurrency}
                  isFree={isFreeContent}
                  compact={true}
                />
              )}
            </TileCoverHeaderLeft>

            <TileDescriptionSocial>
              <TileDescriptionFavorite isLoggedIn={isLoggedIn} eventId={id} />
              <TileDescriptionShare
                url={shareUrl}
                title={title}
                summary={summary}
                hashtags={categories}
                content={content}
              />
            </TileDescriptionSocial>

            <TileDescriptionHeader>
              <TileDescriptionTitle dir="auto">{title}</TileDescriptionTitle>

              <TileDescriptionDuration>
                {!!registrationsCount && (
                  <TileDescriptionUsersCount
                    usersCount={registrationsCount}
                    numOnLeftOfIcon={true}
                    tooltipDisabled
                    hallowIcon
                  />
                )}

                {isLive ? (
                  <Duration
                    duration={processDurationHumanize(duration, 's')}
                    labelCss={TileDurationLabelCss}
                    durationTimeCss={TileDurationCss}
                  />
                ) : (
                  <TileDescriptionDurationTime>
                    {processDuration(duration, 's')}
                  </TileDescriptionDurationTime>
                )}
              </TileDescriptionDuration>
            </TileDescriptionHeader>

            {isLive ? (
              <TileSeparator />
            ) : (
              <TileProgressBar
                variant={'danger'}
                now={duration && (getPlayTime(id) / duration) * 100}
              />
            )}

            {isLive ? (
              <OccurrencesCarousel
                accessControls={accessControls}
                occurrences={flattenOcc}
                occurrence={occurrence}
                duration={duration}
                isFree={isFreeContent}
                eventId={id}
                WorkshopId={WorkshopId}
                setOccurrenceId={setOccurrenceId}
                isLoggedIn={isLoggedIn}
                allowDropIn={allowDropIn}
                eventRegistrations={eventRegistrations}
                adminOrOwner={adminOrOwner}
              />
            ) : (
              !!rating && <StarRating rating={rating} />
            )}

            <TileSeparatorVertical />
            {!isReady || !isLoggedIn || !isLive ? (
              <TileButtonCustom label="JOIN" onClick={onClickJoin} isnotready={+!isReady} />
            ) : (
              !!occurrence && (
                <EventTileRegButton
                  label="JOIN"
                  eventId={id}
                  duration={duration}
                  summary={summary}
                  occurrence={occurrence}
                  WorkshopId={WorkshopId}
                  isFree={isFreeContent}
                  eventRegistrations={eventRegistrations}
                  addEventRegistration={addEventRegistration}
                  createEventRegistration={createEventRegistration}
                  deleteEventRegistration={deleteEventRegistration}
                  refundContent={refundContent}
                  updateParentComponentOnRegister={updateParentComponentOnRegister}
                  updateParentComponentOnRemoveRegister={updateParentComponentOnRemoveRegister}
                  navigate={navigate}
                  title={title}
                  allowDropIn={allowDropIn}
                  isLoggedIn={isLoggedIn}
                  forceUpdate={forceUpdate}
                  adminOrOwner={adminOrOwner}
                  event={content}
                  accessControls={accessControls}
                  setDialog={setDialog}
                  onBuyClick={onBuyClick}
                  setGoogleDialog={setGoogleDialog}
                />
              )
            )}
          </TileCover>
        </TileCoverWrapper>
        <TileDescription>
          <StyledChannel
            to={`/channel/${UserId}`}
            userFullName={user.fullName}
            userAvatarUrl={user.avatarThumbnail}
            followers={user.followersCount}
            userId={UserId}
            channelId={UserId}
            rating={user.rating}
            ratingCount={user.ratingCount}
            channelWrapCss={TileChannelWrapCss}
            userNameCss={TileCustomUserNameStyle}
            channelUserAvatarCss={TileChannelUserAvatarCss}
            followersRatingCss={TileFollowersRatingCss}
            showFollow={false}
            setDialog={setDialog}
          />

          <TileDescriptionLineTextWrapper dir="auto">
            <TileDescriptionLineText
              isOverFollow={isOverFollow}
              showInfo={showInfo}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (isOverFollow) {
                  setShowInfo(!showInfo);
                }
              }}
            >
              {summary}
            </TileDescriptionLineText>
            <TileDescriptionEnlargeSummary>
              {isOverFollow && (
                <TileDescriptionEnlargeSummaryButton
                  iconName={showInfo ? 'fas fa-sort-up' : 'fas fa-sort-down'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowInfo(!showInfo);
                  }}
                />
              )}
            </TileDescriptionEnlargeSummary>
          </TileDescriptionLineTextWrapper>

          <TileTagWrap categories={categories} />
        </TileDescription>
      </TileWrap>
    </>
  );
}

EventTile.propTypes = {
  userId: PropTypes.number,
  userCurrency: PropTypes.string,
  accessControls: PropTypes.array,
  navigate: PropTypes.object,
  isMobile: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  embeddedType: PropTypes.string,
  embeddedLink: PropTypes.string,
  isPrivate: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAdminView: PropTypes.bool,
  content: PropTypes.object,
  handleSelect: PropTypes.func,
  handlePrivacy: PropTypes.func,
  handleDelete: PropTypes.func,
  eventRegistrations: PropTypes.array,
  addEventRegistration: PropTypes.func,
  createEventRegistration: PropTypes.func,
  deleteEventRegistration: PropTypes.func,
  refundContent: PropTypes.func,
  updateOccurrenceLiveEvents: PropTypes.func,
  updateOccurrenceLiveEventsSuggestion1: PropTypes.func,
  updateOccurrenceLiveEventsSuggestion2: PropTypes.func,
  updateOccurrenceHomeUpcomingEvent: PropTypes.func,
  updateOccurrenceHomeFavorite: PropTypes.func,
  setDialog: PropTypes.func,
  setGoogleDialog: PropTypes.func,
  setBuyDialog: PropTypes.func,
};

function mapStateToProps(state, { content }) {
  const { id, isLive } = content;
  let eventRegistrations = null;
  if (isLive) {
    eventRegistrations = getEventRegistration(state, id);
  }

  return {
    userCurrency: getUserCurrency(state),
    eventRegistrations: eventRegistrations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addEventRegistration,
      createEventRegistration,
      deleteEventRegistration,
      refundContent,
      updateOccurrenceLiveEvents,
      updateOccurrenceLiveEventsSuggestion1,
      updateOccurrenceLiveEventsSuggestion2,
      updateOccurrenceHomeUpcomingEvent,
      updateOccurrenceHomeFavorite,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventTile));
