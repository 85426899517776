import {hasPermissions} from '../../index';

export default function isAdmin(eventId, workshopId, channelId, accessControls) {
  const permissionFunction = 'adminCanEdit';
  const workshopObject = {
    type: 'workshop',
    id: workshopId,
    key: 'toWorkshopId',
    parent: {
      type: 'user',
      id: channelId,
      key: 'toUserId'
    }
  };
  const eventObject = {
    type: 'event',
    id: eventId,
    key: 'toEventId',
    parent: workshopObject
  };

  const object = eventId ? eventObject : workshopObject;
  
  return hasPermissions(object, accessControls, permissionFunction);
}