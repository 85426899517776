// import { createCachedSelector } from 're-reselect';

export const getUserWorkshopRegistrations = (state) => state.userWorkshopRegistrations.rows;
export const getUserWorkshopRegistrationsReqParams = (state) =>
  state.userWorkshopRegistrations.reqParams;

// export const getWorkshopRegistration = createCachedSelector(
//   getUserWorkshopRegistrations,
//   (state, workshopId) => workshopId,
//   (userWorkshopRegistration, workshopId) => {
//     return (userWorkshopRegistration || []).find(
//       (item) => workshopId && item && +item.WorkshopId === +workshopId
//     );
//   }
// )(
//   (state, workshopId) => workshopId // Use dataType as cacheKey
// );

export const getWorkshopRegistration = (state, workshopId) => {
  const userWorkshopRegistration = getUserWorkshopRegistrations(state);
  return (userWorkshopRegistration || []).find(
    (item) => workshopId && item && +item.WorkshopId === +workshopId
  );
};
