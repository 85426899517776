import {createAction} from 'redux-actions';
import {
  UPDATE_USER,
  SET_USER_PAYMENT_METHOD,
  DELETE_USER_PAYMENT_METHOD,
} from './types';

export const updateUser = createAction(UPDATE_USER);
export const setUserPaymentMethod = createAction(SET_USER_PAYMENT_METHOD);
export const deleteUserPaymentMethod = createAction(DELETE_USER_PAYMENT_METHOD);
