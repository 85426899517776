import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import ResizeableTextBox from "components/ResizeableTextBox";
import {QuestionCss} from "components/Chat/components/PollsContainer/components/JSXElements";
import {debounceFunction} from "utilities/general";
import {POLL_QUESTION_MAX_INPUT} from "utilities/polls.const";

const Wrap = styled.div`
`;

const StyleResizeableTextBox = styled(ResizeableTextBox)`

  .TextArea {
    ${QuestionCss};
    background-color: inherit;

    ::placeholder {
      text-align: center;
      color: inherit;
    }
  }

`;

function QuestionInput({
                         className,
                         value = '',
                         onUpdate
                       }) {
  const [input, setInput] = useState(value)

  function onInput(inputText) {
    setInput(inputText);
    debounceFunction(() => {
      onUpdate({text: inputText});
    }, 500)

  }

  return (
    <Wrap className={className}>
      <StyleResizeableTextBox
        onInput={onInput}
        placeholder={'Enter a question'}
        maxLength={POLL_QUESTION_MAX_INPUT}
        value={input}
        onBlur={() => onUpdate({text: input})}
        // onEnterClick={() => {
        //   onSendMessageClick(currentMessage, setCurrentMessage);
        //   inputRef.current.focus();
        // }}
      />
    </Wrap>
  );
}

QuestionInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default (React.memo(QuestionInput));