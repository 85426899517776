import UserService from 'services/user';
import {updateUserSettingsAction} from '../userSettings/actions';
import {errorHandler} from 'utilities/services';

const userService = new UserService();

export function loadUserSettings() {
  return dispatch => userService
    .getUserSettings()
    .then((response) => {
      const {data: {userSettings}} = response;
      dispatch(updateUserSettingsAction(userSettings));
      return userSettings;
    })
    .catch(err => {
      errorHandler(err)
    });
}

export function updateUserSettings(userSettings) {
  return dispatch => userService
    .updateUserSettings(userSettings)
    .then((response) => {
      const {data: {userSettings}} = response;
      dispatch(updateUserSettingsAction(userSettings));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}