import {createAction} from 'redux-actions';
import {
  SET_USER_WORKSHOP_REGISTRATIONS,
  ADD_USER_WORKSHOP_REGISTRATION,
  DELETE_USER_WORKSHOP_REGISTRATION
} from './types';


export const setUserWorkshopRegistrations = createAction(SET_USER_WORKSHOP_REGISTRATIONS);
export const addUserWorkshopRegistration = createAction(ADD_USER_WORKSHOP_REGISTRATION);
export const deleteUserWorkshopRegistration = createAction(DELETE_USER_WORKSHOP_REGISTRATION);