import {handleActions} from 'redux-actions';
import {
  SET_HOME_EVENTS,
  ADD_HOME_EVENTS
  // ADD_HOME_EVENT,
  //UPDATE_HOME_EVENT,
  //DELETE_HOME_EVENT
} from './types';
import {
  reduxAddEventHelper,
  reduxEventHelper
} from "../helpers/events";


const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_HOME_EVENTS]: (state, {payload}) => {
    return reduxEventHelper(state, payload);
  },

  // [ADD_HOME_EVENT]: (state, {payload}) => {
  //   const newEvent = pick(payload, defaultEventFields);
  //   const events = [...state.rows];
  //
  //   const foundIndex = events.findIndex((event) => event.id === newEvent.id);
  //   if (foundIndex === -1) {
  //     events.push(newEvent);
  //     return events;
  //   }
  //
  //   return state;
  // },
  [ADD_HOME_EVENTS]: (state, {payload}) => {
    return reduxAddEventHelper(state, payload);
  }
  /*
  [HOME_UPDATE_EVENT]: (state, { payload }) => {
    const updatedEvent = pick(payload, defaultEventFields);
    const events = [...state];

    const foundIndex = events.findIndex((event) => event.id === updatedEvent.id);
    if( foundIndex > -1 ) {
      events[foundIndex] = {...events[foundIndex], ...updatedEvent};
      return events;
    }

    return state;
  },
  [HOME_DELETE_EVENT]: (state, { payload }) => {
    const eventId = payload;
    const events = [...state];

    const foundIndex = events.findIndex((occurrence) => occurrence.id === eventId);
    if( foundIndex > -1 ) {
      events.splice(foundIndex, 1);
      return events;
    }

    return state;
  },
  */
}, {...initialState});