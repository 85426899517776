import { dateToUserTimeZone, formatDate, getDiff, isDateInFuture } from './date';
import {
  EMBEDDED_TYPES,
  EVENT_DROPIN_GRACE_MIN,
  EVENT_STATUS,
  FREE_EVENT_NO_UNREGISTER_TIME_MIN,
  INCOMPLETE_EVENT_KEY,
  INCOMPLETE_WORKSHOP_EVENT_KEY,
  LIVE_EVENT_REFUND_NOTICE,
  OFFSET_START_TIME_MIN,
  PAID_EVENT_NO_UNREGISTER_TIME_MIN,
  UPLOAD_STATUS,
  VOD_REFUND_NOTICE,
} from 'config/constants';

import { extractUrl, getChannelBaseUrl, getEncodedRedirectPath, isPaid, subsStr } from './general';
import { getWorkshopBaseUrl, getWorkshopManageUrl } from './workshop';
import { getEventScheduleErrors, saveOccurrences } from './event-schedule';
import { getEventDetailsErrors } from './event-details';
import memoizeOne from 'memoize-one';
import EventService from 'services/event';
import { errorHandler, successHandler } from 'utilities/services';
import { AL_EVENT, AL_EVENT_ACTION, AL_EVENT_STATUS, alPushContent } from 'utilities/analytics';
import {
  deleteBatchEventFromGoogleCalendar,
  setBatchEventCalendarConfirmation,
} from 'utilities/calendar';
import canViewOccurrence from 'permissions/actionFunctions/events/can-view-occurrence';
import { removeData } from 'utilities/localStorage';
import { isEqual } from 'lodash';
import { getStore, getStoreState } from 'utilities/store';
import { resetEventActions, updateEvent } from '../reduxUtils/event/thunks';
import { getUserSettings } from '../reduxUtils/userSettings/selectors';
import { resetUseOriginalEvent } from 'utilities/event-hooks';

export function getEventBaseUrl(id, fullPath = false) {
  return `${fullPath ? window.location.origin : ''}/event/${id}`;
}

export function getEventUrl(id, occurrence, isAdminView = false, fullPath = false) {
  const defaultOccurrence = occurrence ? `/occurrence/${occurrence}` : '';
  return `${getEventBaseUrl(id, fullPath)}${defaultOccurrence}${isAdminView ? '?adminView=1' : ''}`;
}

export function getEventEditUrl(channelId, workshopId, eventId) {
  let editUrl = `${getEventBaseUrl(eventId)}/edit${getEncodedRedirectPath()}`;
  if (workshopId) {
    editUrl = `${getWorkshopBaseUrl(workshopId)}${editUrl}`;
  }
  return `${getChannelBaseUrl(channelId)}${editUrl}`;
}

export function getEventManageUrl(id) {
  return `${getEventBaseUrl(id)}/manage`;
}

export function getEventInspectUrl(id, occurrence = null, fullPath = false) {
  const defaultOccurrence = occurrence ? `/occurrence/${occurrence}` : '';

  return `${getEventBaseUrl(id, fullPath)}${defaultOccurrence}/inspect`;
}

export function isEventPublished(status) {
  return status === EVENT_STATUS.PUBLISHED.key;
}

export function getPublishingEventError(event = {}) {
  if (event.status !== EVENT_STATUS.COMPLETED.key) {
    return 'You must finish creating the event first !';
  }

  if (
    !event.isLive &&
    event.upload &&
    event.upload.id &&
    event.upload.status !== UPLOAD_STATUS.SUCCESS.key
  ) {
    return 'You must finish uploading video!';
  }

  if (event.isLive) {
    const remainingOccurrences =
      (event.occurrences || []).filter(({ date }) => {
        const now = dateToUserTimeZone(Date.now());
        const future = dateToUserTimeZone(date);
        return now.isSame(future) || now.isBefore(future);
      }).length > 0;

    if (event.occurrences && event.occurrences.length > 0 && remainingOccurrences.length < 1) {
      return 'To publish an event it should have no date or at least 1 date in the future';
    }
  }

  return null;
}

export function updateMenusAndValidate({
  event,
  userId,
  files,
  accessControl,
  userSettings,
  occurrences,
  onMenuChange,
  checkLive,
}) {
  const errors = {};

  const eventDetailsErrors = getEventDetailsErrors(
    event,
    userId,
    files,
    accessControl,
    userSettings
  );
  if (Object.keys(eventDetailsErrors).length) {
    errors.eventDetailsErrors = eventDetailsErrors;
  }

  const eventScheduleErrorsObj = getEventScheduleErrors(occurrences, event.isLive, checkLive);
  errors.eventScheduleErrors = eventScheduleErrorsObj;

  if (errors.eventDetailsErrors) {
    onMenuChange(2, { isDisabled: true });
  } else {
    onMenuChange(2, { isDisabled: false });
  }

  if (errors.eventDetailsErrors || errors.eventScheduleErrors.hasErrors) {
    onMenuChange(3, { isDisabled: true });
  } else {
    onMenuChange(3, { isDisabled: false });
  }

  return errors;
}

export const getDateFromEvent = memoizeOne((occurrences, occurrenceId = null) => {
  let res = null;

  if (!occurrences || !occurrences[0] || !occurrences[0].length) return null;

  if (!occurrenceId) {
    return dateToUserTimeZone(occurrences[0][0].date);
  }

  res = occurrences.find((occurrence) => +occurrenceId === occurrence.id);
  if (res) {
    return dateToUserTimeZone(res.date);
  }

  return res;
});

export function findRegistrationForOccurrence(eventId, occurrenceId, registrations) {
  return (registrations || []).find(
    ({ EventId, OccurrenceId }) =>
      eventId && +EventId === +eventId && occurrenceId && +OccurrenceId === +occurrenceId
  );
}

export function isEventNow(diff, durationInMin = 0) {
  //when duration is zero on going event return false (no drop-in)
  return diff >= -OFFSET_START_TIME_MIN && diff <= durationInMin;
}

export function getEventRegistrationLogic({
  date,
  duration,
  eventId,
  occurrenceId,
  WorkshopId,
  registrations,
  isFree,
  allowDropIn,
}) {
  let registration = {};
  let registered = false;
  let url = '';
  let dropIn = false;
  let _allowDropIn = allowDropIn;
  let needToRegister = true;
  let disableUnregister = false;
  let eventPassed = false;
  const durationInMin = duration / 60;
  const now = dateToUserTimeZone(new Date());
  const durationElapsed = Math.floor(getDiff(now, date, 'minutes'));
  const eventNow = isEventNow(durationElapsed, durationInMin);
  const isEventFreeAndNow = isFree && eventNow;
  let isRegisteredAndNow = false;

  if (isEventFreeAndNow) {
    url = getEventUrl(eventId, occurrenceId);
  } else {
    registration = findRegistrationForOccurrence(eventId, occurrenceId, registrations) || {};
    registered = !!registration.id;
    if (registered) {
      isRegisteredAndNow = eventNow;
      url = getEventUrl(eventId, occurrenceId, false);
    }
  }

  //the event is taking place now check if the user allowed in
  if (isRegisteredAndNow || isEventFreeAndNow) {
    dropIn = true;
  }

  //is it possible to enter the event while it happening
  if (!allowDropIn) {
    if (eventNow && durationElapsed <= EVENT_DROPIN_GRACE_MIN) {
      _allowDropIn = true;
    }
  }

  //this means the user dont need to register they can just enter
  if (dropIn || (registered && durationElapsed <= 0)) {
    needToRegister = false;
  }

  const no_unregister = isFree
    ? FREE_EVENT_NO_UNREGISTER_TIME_MIN
    : PAID_EVENT_NO_UNREGISTER_TIME_MIN;
  if (registered && durationElapsed >= -no_unregister) {
    disableUnregister = true;
  }

  if (durationElapsed >= durationInMin) {
    eventPassed = true;
  }

  return {
    registration: registration,
    registered: registered,
    isRegisteredAndNow: isRegisteredAndNow,
    eventId: eventId,
    occurrenceId: occurrenceId,
    WorkshopId: WorkshopId,
    date: date,
    duration: duration,
    url: url,
    dropIn: dropIn,
    allowDropIn: _allowDropIn,
    needToRegister: needToRegister,
    isEventFreeAndNow: isEventFreeAndNow,
    disableUnregister: disableUnregister,
    eventPassed: eventPassed,
    eventNow: eventNow,
    isFree: isFree,
  };
}

function isZoom(url) {
  return url.includes('zoom.us');
}

function isFacebookLive(url) {
  return url.includes('facebook.com') || url.includes('//fb.me') || url.includes('//fb.watch');
}

function isYouTubeLive(url) {
  return url.includes('youtu.be') || url.includes('youtube.com');
}

/*
function isGoogleMeet(url) {
  return url.includes('meet.google.com');
}

function isMicrosoftTeams(url) {
  return url.includes('teams.microsoft.com');
}
*/

export function getEmbeddedTypeByUrl(url) {
  let type = {};
  const lowerCaseUrl = url.toLowerCase();

  switch (true) {
    case isZoom(lowerCaseUrl):
      type = EMBEDDED_TYPES.ZOOM;
      break;
    case isFacebookLive(lowerCaseUrl):
      type = EMBEDDED_TYPES.FL;
      break;

    case isYouTubeLive(lowerCaseUrl):
      type = EMBEDDED_TYPES.YL;
      break;

    // case isGoogleMeet(lowerCaseUrl):
    //   type = EMBEDDED_TYPES.GM;
    //   break;
    //
    // case isMicrosoftTeams(lowerCaseUrl):
    //   type = EMBEDDED_TYPES.MT;
    //   break;
    default:
      type = EMBEDDED_TYPES.UNKNOWN;
      break;
  }
  return type;
}

export function findOccurrence(flattenOcc = [], id) {
  return flattenOcc.find((eventOccurrence) => +eventOccurrence.id === +id);
}

function processYouTubeLinks(url) {
  let external;
  let internal;
  let id;
  let regex;

  switch (true) {
    case url.endsWith('/livestreaming'): //e,g - https://studio.youtube.com/video/k9MZ7LkX2Sc/livestreaming
      id = subsStr(url, 'video\\/', false, '\\/livestreaming', false);
      internal = `https://youtu.be/${id}`;
      external = url;
      break;

    case url.includes('youtu.be/'): //e,g - https://youtu.be/uU8oZin6pgU
      id = subsStr(url, 'youtu.be\\/', false);
      internal = url;
      external = `https://studio.youtube.com/video/${id}/livestreaming`;
      break;

    case url.includes('/embed/'): //e,g - https://www.youtube.com/embed/Rsrj0K_JmUc
      id = subsStr(url, 'embed\\/', false);
      internal = `https://youtu.be/${id}`;
      external = `https://studio.youtube.com/video/${id}/livestreaming`;
      break;
    default:
      regex = new RegExp('(?:\\/|%3D|v=|vi=|c)([0-9A-z-_]{11})(?:[%#?&]|$)');
      id = (regex.exec(url) || [])[1];
      if (!!id) {
        internal = `https://youtu.be/${id}`;
        external = `https://studio.youtube.com/video/${id}/livestreaming`;
      } else {
        internal = url;
        external = url;
      }
      break;
  }
  return {
    internal: internal,
    external: external,
  };
}

function processFacebookLinks(url = '') {
  let external;
  let internal;
  let extractedUrl;

  switch (true) {
    case url.startsWith('<iframe src='): //e,g - iframe
      extractedUrl = subsStr(url, 'href=', false, '&width|%2F', false);
      internal = url;
      external = decodeURIComponent(extractedUrl);
      break;

    case url.startsWith('https://'): //e,g - https://fb.me/e/3ypPHPF9I
      internal = encodeURIComponent(url);
      external = url;
      break;
    default:
      internal = url;
      external = url;
      break;
  }

  return {
    internal: internal,
    external: external,
  };
}

export function getStreamLinks(embeddedType, embeddedLink = '') {
  let links = {
    external: embeddedLink,
    internal: embeddedLink,
  };

  switch (embeddedType) {
    case EMBEDDED_TYPES.ZOOM.key:
      links.external = extractUrl(embeddedLink);
      break;
    case EMBEDDED_TYPES.FL.key:
      links = processFacebookLinks(embeddedLink);
      break;

    case EMBEDDED_TYPES.YL.key:
      links = processYouTubeLinks(embeddedLink);
      break;

    // case EMBEDDED_TYPES.GM.key:
    //   break;
    //
    // case EMBEDDED_TYPES.MT.key:
    //   break;
    default:
      break;
  }

  return links;
}

export function getFutureEvents({
  events = [],
  filterVodEvents = false,
  isLiveEvent = null,
  eventDuration = null,
}) {
  return events.flat().filter(({ date, occurrences, isLive, duration, status }) => {
    const _isLive = isLiveEvent != null ? isLiveEvent : isLive;
    const _duration = eventDuration != null ? eventDuration : duration;
    const _date = (occurrences && occurrences[0] && occurrences[0][0].date) || date;
    if (status && !isEventPublished(status)) {
      return false;
    }
    if (!_isLive) {
      return !filterVodEvents;
    }
    if (!_date) {
      return false;
    }

    return isDateInFuture(_date, _duration);
  });
}

export function findOccurrenceIndex(eventId, occurrenceId, events) {
  let foundEventIdIndex = -1,
    foundOccIndex = -1,
    foundOccIdIndex = -1;
  if (!eventId || !occurrenceId) {
    return {};
  }

  for (let i = 0; i < events.length; ++i) {
    if (events[i].id === eventId) {
      foundEventIdIndex = i;
      if (events[i].occurrences) {
        for (let k = 0, occurrences = events[i].occurrences; k < occurrences.length; ++k) {
          if (Array.isArray(events[i].occurrences[k])) {
            foundOccIdIndex = occurrences[k].findIndex(({ id }) => id === occurrenceId);
            if (foundOccIdIndex > -1) {
              foundOccIndex = k;
              break;
            }
          } else {
            if (events[i].occurrences[k].id === occurrenceId) {
              foundOccIdIndex = k;
              break;
            }
          }
        }
      }
      break;
    }
  }

  return { foundEventIdIndex, foundOccIndex, foundOccIdIndex };
}

export function canEnterLiveEvent({
  occurrenceId,
  WorkshopId,
  userId,
  accessControls,
  isLoggedIn,
  adminOrOwner,
  isFree,
  registered,
}) {
  return (
    isLoggedIn &&
    !!(
      adminOrOwner ||
      isFree ||
      registered ||
      canViewOccurrence({
        occurrenceId,
        WorkshopId,
        userId,
        accessControls,
      })
    )
  );
}

export function canWatchLiveEvent({
  isLoggedIn,
  adminOrOwner,
  occurrenceId,
  WorkshopId,
  userId,
  accessControls,
  dropIn,
  allowDropIn,
  isFree,
  registered,
  needToRegister,
}) {
  if (!isLoggedIn) {
    return false;
  }

  if (adminOrOwner) {
    return true;
  }

  if (dropIn && !allowDropIn) {
    return false;
  }

  return (
    !needToRegister &&
    canEnterLiveEvent({
      occurrenceId,
      WorkshopId,
      userId,
      accessControls,
      isLoggedIn,
      adminOrOwner,
      isFree,
      registered,
    })
  );
}

export function onEventBuyClick({
  setBuyDialog,
  onComplete,
  isLive,
  occurrence,
  id,
  title,
  coverPhoto,
  price,
  currency,
  userCurrency,
  content,
}) {
  setBuyDialog((current) => {
    return {
      ...current,
      header: isLive ? `LIVE Event` : 'VOD Event',
      subHeader: isLive && occurrence ? formatDate(occurrence.date) : '',
      refundLabel: isLive ? LIVE_EVENT_REFUND_NOTICE : VOD_REFUND_NOTICE,
      eventId: id,
      occurrenceId: occurrence && occurrence.id,
      workshopId: null,
      occurrence: occurrence,
      title: title,
      coverPhoto: coverPhoto,
      price: price,
      currency: currency,
      userCurrency: userCurrency,
      content: content,
      show: true,
      onComplete: onComplete,
    };
  });
}

const eventService = new EventService();

function updateVisibility(isPublished, eventId) {
  return eventService.updateEventVisibility(eventId, { isPublished });
}

function getEventTotalRegisteredUsers(event) {
  return event?.occurrences.reduce((accumulator, el) => {
    return accumulator + el.registrationsCount;
  }, 0);
}

export function onEventToggleVisibility({
  isPublished,
  event,
  updateEventState,
  setDialog,
  setGoogleDialog,
}) {
  const publishingErrors = getPublishingEventError(event);

  if (isPublished && publishingErrors) {
    errorHandler(publishingErrors);
    return false;
  }

  const action = isPublished ? AL_EVENT_ACTION.publish : AL_EVENT_ACTION.unpublish;
  let actionButton = isPublished ? AL_EVENT_ACTION.publish : AL_EVENT_ACTION.unpublish;
  let hasError;
  let text = `Are you sure you want to ${action}`;

  // user going to unpublish the event
  if (event.isLive && !isPublished) {
    const registrationsCount = getEventTotalRegisteredUsers(event);

    if (registrationsCount) {
      const freeText = `You have ${registrationsCount} ${
        registrationsCount === 1 ? `registered user` : `registered users`
      } on this event\nAre you sure you want to unpublish this event?`;
      const paidText = `You have ${registrationsCount} ${
        registrationsCount === 1 ? `registered user` : `registered users`
      } on this event\nAre you sure you want to unpublish this event?\nThis will issue refund to the buyers`;
      if (isPaid(event.paymentType)) {
        text = paidText;
        actionButton = 'refund';
      } else {
        text = freeText;
      }
    }
  }

  setDialog((current) => {
    return {
      show: true,
      title: `${action} - ${event.title}`,
      confirmationText: text,
      // contentTitle: `${event.title ? `"${event.title}"` : ''}`,
      action: actionButton,
      onCancel: current.onCancel,
      onConfirm: () => {
        setDialog((currentDialog) => {
          return {
            ...currentDialog,
            isLoading: true,
          };
        });
        return updateVisibility(isPublished, event.id)
          .then(({ data: { status } }) => {
            successHandler(`event ${action}ed successfully`);
            updateEventState(status);
            current.onCancel();
            if (event.isLive) {
              if (isPublished) {
                setBatchEventCalendarConfirmation({
                  adminOrOwner: true,
                  eventId: event.id,
                  occurrences: event.occurrences,
                  duration: event.duration,
                  title: event.title,
                  summary: event.summary,
                  setGoogleDialog,
                  setDialog,
                });
              } else {
                deleteBatchEventFromGoogleCalendar({
                  eventId: event.id,
                  occurrences: event.occurrences,
                });
              }
            }
          })
          .catch((err) => {
            hasError = err.message;
            errorHandler(err);
            setDialog((currentDialog) => {
              return {
                ...currentDialog,
                isLoading: false,
              };
            });
          })
          .finally(() => {
            alPushContent({
              alEvent: AL_EVENT.EVENT.createEvent,
              eventAction: action,
              eventStatus: hasError ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
              eventLabel: `${action} event`,
              content: event,
              error: hasError,
            });
          });
      },
    };
  });
}

export function onEventTogglePrivate(isPrivate, eventId, updateEventState) {
  return eventService
    .updateEvent(eventId, { isPrivate })
    .then(() => {
      updateEventState(isPrivate);
    })
    .catch((err) => {
      errorHandler(err);
    });
}

function deleteEvent(eventId) {
  return eventService.deleteEvent(eventId);
}

function onConfirmDelete(navigate, userId) {
  navigate(getChannelBaseUrl(userId, true));
}

export function onEventDeleteClick(setDialog, navigate, event, userId, redirect) {
  let actionButton = 'Delete';
  let text = `Are you sure you want to delete`;

  // user going to delete the event and has users that are registered to it
  if (event.isLive) {
    const registrationsCount = getEventTotalRegisteredUsers(event);
    if (registrationsCount) {
      const freeText = `You have ${registrationsCount} ${
        registrationsCount === 1 ? `registered user` : `registered users`
      } on this event\nAre you sure you want to delete this event?`;
      const paidText = `You have ${registrationsCount} ${
        registrationsCount === 1 ? `registered user` : `registered users`
      } on this event\nAre you sure you want to delete this event?\nThis will issue refund to the buyers`;
      if (isPaid(event.paymentType)) {
        text = paidText;
        actionButton = 'refund';
      } else {
        text = freeText;
      }
    }
  }

  setDialog((current) => {
    return {
      show: true,
      title: `Delete - ${event.title}`,
      confirmationText: text,
      // contentTitle: `${event.title ? `"${event.title}"` : ''}`,
      action: actionButton,
      onCancel: current.onCancel,
      onConfirm: () => {
        setDialog((currentDeleteDialog) => {
          return {
            ...currentDeleteDialog,
            isLoading: true,
          };
        });

        deleteEvent(event.id)
          .then(() => redirect && onConfirmDelete(navigate, userId))
          .catch((err) => {
            errorHandler(err);
            setDialog((currentDeleteDialog) => {
              return {
                ...currentDeleteDialog,
                isLoading: false,
              };
            });
          });
      },
    };
  });
}

export async function eventOnDoneClick({
  navigate,
  userId,
  edit,
  event,
  eventToSave = {},
  occurrences,
  occurrencesToSave,
  eventSettings,
  eventSettingsToSave,
}) {
  let error;

  try {
    if (event.isLive && occurrencesToSave) {
      if (!isEqual(occurrencesToSave, occurrences)) {
        await saveOccurrences(event.id, occurrencesToSave, eventService);
      }
    }

    if (!isEqual(eventSettingsToSave, eventSettings)) {
      await eventService.updateEventSettings(event.id, eventSettingsToSave);
    }

    let duration;
    if (eventToSave.isLive) {
      duration = +eventToSave.streamDuration * 60; // in seconds
    } else {
      if (eventToSave.videoDuration !== null) {
        duration = eventToSave.videoDuration;
      } else {
        duration = event.duration;
      }
    }

    const state = getStoreState();
    const updatedEvent = {
      ...eventToSave,
      duration: duration,
      categories: eventToSave.categories.map(({ value }) => value),
    };

    if (isPaid(event.paymentType)) {
      const { currency } = getUserSettings(state);
      updatedEvent.currency = currency;
    }

    // the event fields without the changes
    const originalEvent = {
      ...event,
      categories: event.categories.map(({ value }) => value),
    };

    const { dispatch } = getStore();
    if (!isEqual(originalEvent, updatedEvent)) {
      await dispatch(updateEvent(event.id, updatedEvent));
      await eventService.completeEvent(event.id);
    }

    if (!edit) {
      removeData(!!event.workshopId ? INCOMPLETE_WORKSHOP_EVENT_KEY : INCOMPLETE_EVENT_KEY);
    }

    resetUseOriginalEvent();
    dispatch(resetEventActions());
    successHandler(`Event ${edit ? 'update' : 'created'} successfully`);
    let pushTo = getChannelBaseUrl(userId, true);

    if (event.workshopId) {
      pushTo = getWorkshopManageUrl(event.workshopId);
    }

    navigate(pushTo);
  } catch ({ message }) {
    error = message;
    errorHandler(message);
  }

  if (!edit) {
    alPushContent({
      alEvent: AL_EVENT.EVENT.createEvent,
      eventAction: AL_EVENT_ACTION.submit,
      eventStatus: error ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
      eventLabel: 'finished creating new event',
      content: event,
      userId,
      error,
    });
  }
}
