import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Dropdown} from 'react-bootstrap';
import {GeneralDropDownItemCss} from 'styles/common';

import Icon from 'components/Icon';
import {MenuItemIconCss, MenuItemLabelCss} from 'styles/common';

const BaseMenuItemWrap = styled(Dropdown.Item)`
  ${GeneralDropDownItemCss};

`;

const BaseMenuItemIcon = styled(Icon)`
  color: inherit;

  &:hover {
    color: inherit;
  }

  ${MenuItemIconCss}
`;

const BaseMenuItemLabel = styled.span`
  ${MenuItemLabelCss}
`;

function BaseMenuItem({className, iconName, label, isSelected, onClick}) {
  return (
    <BaseMenuItemWrap
      className={className}
      onClick={onClick}
      is_selected={isSelected}
      title={label}
    >
      {iconName && <BaseMenuItemIcon iconName={iconName}/>}
      <BaseMenuItemLabel>{label}</BaseMenuItemLabel>
    </BaseMenuItemWrap>
  );
}

BaseMenuItem.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default BaseMenuItem;