import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_WORKSHOP_OCCURRENCES,
  ADD_WORKSHOP_OCCURRENCE,
  UPDATE_WORKSHOP_OCCURRENCE,
  DELETE_WORKSHOP_OCCURRENCE
} from './types';

import {
  LOGOUT
} from '../auth/types';

const defaultOccurrenceFields = ['id', 'date', 'endDate'];

export const initialState = [];

export default handleActions({
  [SET_WORKSHOP_OCCURRENCES]: (state, {payload}) => {
    return payload.map((occurrence) => pick(occurrence, defaultOccurrenceFields));
  },
  [ADD_WORKSHOP_OCCURRENCE]: (state, {payload}) => {
    const newOccurrence = pick(payload, defaultOccurrenceFields);
    const occurrences = [...state];

    const foundIndex = occurrences.findIndex((occurrence) => occurrence.id === newOccurrence.id);
    if (foundIndex === -1) {
      occurrences.push(newOccurrence);
      return occurrences;
    }

    return state;
  },
  [UPDATE_WORKSHOP_OCCURRENCE]: (state, {payload}) => {
    const updatedOccurrence = pick(payload, defaultOccurrenceFields);
    const occurrences = [...state];

    const foundIndex = occurrences.findIndex((occurrence) => occurrence.id === updatedOccurrence.id);
    if (foundIndex > -1) {
      occurrences[foundIndex] = {...occurrences[foundIndex], ...updatedOccurrence};
      return occurrences;
    }

    return state;
  },
  [DELETE_WORKSHOP_OCCURRENCE]: (state, {payload}) => {
    const occurrenceId = payload;
    const occurrences = [...state];

    const foundIndex = occurrences.findIndex((occurrence) => occurrence.id === occurrenceId);
    if (foundIndex > -1) {
      occurrences.splice(foundIndex, 1);
      return occurrences;
    }

    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, [...initialState]);