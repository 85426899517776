import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_ACCESS_CONTROL,
  ADD_ACCESS_CONTROL,
  UPDATE_ACCESS_CONTROL,
  DELETE_ACCESS_CONTROL
} from './types';

import {
  LOGOUT
} from '../auth/types';


const defaultAccessControlFields = ['id', 'permission', 'action', 'beginAt', 'expireAt', 'UserId', 'toUserId', 'toEventId', 'toWorkshopId', 'toOccurrenceId'];
let initialState = [];

export default handleActions({
  [SET_ACCESS_CONTROL]: (state, {payload}) => {
    return [
      ...payload.map((accessControl) => pick(accessControl, defaultAccessControlFields))
    ];
  },
  [ADD_ACCESS_CONTROL]: (state, {payload}) => {
    const newAccessControl = pick(payload, defaultAccessControlFields);
    const accessControls = [...state];

    const foundIndex = accessControls.findIndex((accessControl) => accessControl.id === newAccessControl.id);
    if (foundIndex === -1) {
      accessControls.push(newAccessControl);
      return accessControls;
    }

    return state;
  },
  [UPDATE_ACCESS_CONTROL]: (state, {payload}) => {
    const updatedAccessControl = pick(payload, defaultAccessControlFields);
    const accessControls = [...state];

    const foundIndex = accessControls.findIndex((accessControl) => accessControl.id === updatedAccessControl.id);
    if (foundIndex > -1) {
      accessControls[foundIndex] = {...accessControls[foundIndex], ...updatedAccessControl};
      return accessControls;
    }

    return state;
  },
  [DELETE_ACCESS_CONTROL]: (state, {payload}) => {
    const accessControlId = payload;
    const accessControls = [...state];

    const foundIndex = accessControls.findIndex((accessControl) => accessControl.id === accessControlId);
    if (foundIndex > -1) {
      accessControls.splice(foundIndex, 1);
      return accessControls;
    }

    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, [...initialState]);
