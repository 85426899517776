import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_USER_FAVORITES,
  ADD_USER_FAVORITE,
  DELETE_USER_FAVORITE
} from './types';
import {LOGOUT} from "../auth/types";

const defaultFavoriteFields = [
  'id',
  'UserId',
  'WorkshopId',
  'EventId'
];

export const initialState = [];

export default handleActions({
  [SET_USER_FAVORITES]: (state, {payload}) => {
    return [...payload.map((Favorite) => pick(Favorite, defaultFavoriteFields))];
  },
  [ADD_USER_FAVORITE]: (state, {payload}) => {
    const newFavorite = pick(payload, defaultFavoriteFields);
    const Favorites = [...state];

    const foundIndex = Favorites.findIndex((Favorite) => Favorite.id === newFavorite.id);
    if (foundIndex === -1) {
      Favorites.push(newFavorite);
      return Favorites;
    }

    return state;
  },
  [DELETE_USER_FAVORITE]: (state, {payload}) => {
    const Favorites = [...state];

    const foundIndex = Favorites.findIndex((Favorite) => Favorite.id === payload);
    if (foundIndex > -1) {
      Favorites.splice(foundIndex, 1);
      return Favorites;
    }

    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, [...initialState]);