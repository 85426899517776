import {PERMISSIONS, EVENT_SIZE_LIMITATIONS} from 'config/constants';

export default function eventCreateLiveDuration({duration}) {
  const permissionsOrder = [
    //PERMISSIONS.subscription.MEMBERSHIP_ENTERPRISE,
    //PERMISSIONS.subscription.MEMBERSHIP_PREMIUM,
    //PERMISSIONS.subscription.MEMBERSHIP_GOLD,
    PERMISSIONS.subscription.MEMBERSHIP_FREE
  ];

  let i = 0;
  let l = permissionsOrder.length;
  for (i; i < l; i++) {
    const permissionName = permissionsOrder[i];
    const permission = EVENT_SIZE_LIMITATIONS[permissionName];
    if (
      duration > permission.duration.min &&
      duration <= permission.duration.max
    ) {
      return [permissionName];
    }
  }

  return ['MEMBERSHIP_FREE'];
}