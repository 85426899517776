import pollsReceiveVote from "../receiveActions/polls/polls.receive-vote.action";
import pollDeleteMessage from "../receiveActions/polls/polls.delete-poll-message.action";
import pollsUpdatePollVisibilityMessage
  from "websockets/receiveActions/polls/polls.update-poll-visibility-message.action";

export default function pollsRoutes(socketRouter) {
  socketRouter.use('polls:receiveVote', pollsReceiveVote);
  socketRouter.use('polls:pollDeleteMessage', pollDeleteMessage);
  socketRouter.use('polls:updatePollVisibilityMessage', pollsUpdatePollVisibilityMessage);
};
