import {
  addTransactionAction,
  deleteTransactionAction,
  setTransactionsAction,
} from './actions';
import {errorHandler} from 'utilities/services';
import UserService from "services/user";

const userService = new UserService();

export function setAllTransactions(query = {}) {
  return dispatch => userService.getAllTransactions(query)
    .then((response) => {
      const {data: {transactions}} = response;
      dispatch(setTransactionsAction(transactions));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}

export function addTransaction(transaction) {
  return dispatch => dispatch(addTransactionAction(transaction));
}

export function deleteTransaction(id) {
  return dispatch => dispatch(deleteTransactionAction(id));

}