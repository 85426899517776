import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import theme from 'styles/theme';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { LightCustomToolbar, HideScrollBarCss, UserSelectNoneCss, scale } from 'styles/common';

import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import { getUserId } from '../../reduxUtils/user/selectors';
import { getUserFullName, getUserAvatarThumbnail } from '../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
} from '../../reduxUtils/browserSettings/selectors';
import { getSearch } from '../../reduxUtils/search/selectors';
import { getRates } from '../../reduxUtils/browserSettings/thunks';
import { getHomeEvents } from '../../reduxUtils/homeEvents/selectors';

import { getHomeLiveEvents } from '../../reduxUtils/homeLiveEvents/selectors';
import { getHomeWorkshops } from '../../reduxUtils/homeWorkshops/selectors';

import WithSideBar from 'layouts/WithSideBar';
import Tabs from 'components/Tabs';
import SectionBody from './components/SectionBody';
import MetaDecorator from 'components/MetaDecorator';
import ChannelTile from 'components/Tiles/ChannelTile';
import { HOME_DEFAULT_CATEGORIES, HOME_PAGE_CONTENT } from 'config/constants';
import { getUserFavorites } from '../../reduxUtils/userFavorites/selectors';
import { getHomeFavorites } from '../../reduxUtils/homeFavorites/selectors';
import { fetchHomeFavorites, deleteHomeFavorites } from '../../reduxUtils/homeFavorites/thunks';

import { PAGE_SIZE } from 'config/constants';
import { appName } from 'config/config';
import { getUserFollowings } from '../../reduxUtils/userFollowings/selectors';

import { getHomeFollowings } from '../../reduxUtils/homeFollowings/selectors';
import { deleteHomeFollowings, fetchHomeFollowings } from '../../reduxUtils/homeFollowings/thunks';
import EventTile from 'components/Tiles/EventTile';
import WorkshopTile from 'components/Tiles/WorkshopTile';

import PlaceholderChannelTile from 'components/Tiles/Placeholder/PlaceholderChannelTile';
import PlaceholderTile from 'components/Tiles/Placeholder/PlaceholderTile';
import { setAllEventRegistrations } from '../../reduxUtils/userEventRegistrations/thunks';
import { getUserEventRegistrationsReqParams } from '../../reduxUtils/userEventRegistrations/selectors';
import { getUserWorkshopRegistrationsReqParams } from '../../reduxUtils/userWorkshopRegistrations/selectors';
import { setAllWorkshopRegistrations } from '../../reduxUtils/userWorkshopRegistrations/thunks';
import { getAccessControl } from '../../reduxUtils/accessControl/selectors';
import { isMobileDevice } from 'utilities/general';
import { fetchHomeUpcomingEvents } from '../../reduxUtils/homeUpcomingEvents/thunks';
import { getHomeUpcomingEvents } from '../../reduxUtils/homeUpcomingEvents/selectors';
import { fetchHomeUpcomingWorkshops } from '../../reduxUtils/homeUpcomingWorkshops/thunks';
import { getHomeUpcomingWorkshops } from '../../reduxUtils/homeUpcomingWorkshops/selectors';
import EmptyModal from 'components/Modal/EmptyModal';
import GoogleSignIn from 'components/GoogleSignIn';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { getHomeLiveEventsSuggestion1 } from '../../reduxUtils/homeLiveEventsSuggestion1/selectors';
import { getHomeLiveEventsSuggestion2 } from '../../reduxUtils/homeLiveEventsSuggestion2/selectors';
import { fetchHomeEvents, fetchHomeLiveEvents } from '../../reduxUtils/helpers/events';
import { getHomeEventsSuggestion1 } from '../../reduxUtils/homeEventsSuggestion1/selectors';
import { getHomeEventsSuggestion2 } from '../../reduxUtils/homeEventsSuggestion2/selectors';
import { fetchHomeWorkshops } from '../../reduxUtils/helpers/workshops';
import { getHomeWorkshopsSuggestion1 } from '../../reduxUtils/homeWorkshopsSuggestion1/selectors';
import { getHomeWorkshopsSuggestion2 } from '../../reduxUtils/homeWorkshopsSuggestion2/selectors';
import AnimatedLinkHeader, { RightArrowIcon } from 'components/AnimatedLinkHeader';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';

const HomeWrap = styled.div`
  width: 100%;
  height: 100%;
`;

const HomeSection = styled.div`
  margin: 15px 0 30px;

  ${({ isMobile }) =>
    isMobile
      ? css``
      : css`
          padding: 0 10px;
        `}
`;

const HomeSectionTitle = styled.div`
  width: 100%;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h3]]],
    ['560px', [['font-size', theme.typo.sizes.h4]]],
    ['1200px', [['font-size', theme.typo.sizes.h5]]],
  ])}
  font-weight: bold;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.darkGrey};
  text-transform: uppercase;
  ${UserSelectNoneCss};
`;

const HomeSectionSubTitle = styled(AnimatedLinkHeader)`
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h5]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
  ])}
  font-weight: bold;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.grayNew};
  ${UserSelectNoneCss};
  padding: 0 10px;
  //font-family: 'heebo', "Segoe UI", sans-serif, Arial;
`;

const HomeSectionBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;

  ${({ isMobileDevice }) =>
    isMobileDevice
      ? css`
          overflow-x: scroll;
          ${HideScrollBarCss};
        `
      : css`
          overflow-x: auto;
        `};

  ${LightCustomToolbar};
`;

const Spacer = styled.div`
  width: 100%;
  height: 60px;
`;

const SectionBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  &:hover ${RightArrowIcon} {
    //parent target child element
    //transform: translate(1vw, 0);
    max-width: 200px;
    opacity: 1;
  }
`;

function Home({
  location,
  isMobile,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  accessControls,
  userSettings,
  fullName,
  avatar,
  search,
  homeLiveEvents,
  fetchHomeLiveEvents,
  homeLiveEventsSuggestion1,
  homeLiveEventsSuggestion2,
  homeEvents,
  homeEventsSuggestion1,
  homeEventsSuggestion2,
  fetchHomeEvents,
  homeWorkshops,
  homeWorkshopsSuggestion1,
  homeWorkshopsSuggestion2,
  fetchHomeWorkshops,
  homeFavorites,
  fetchHomeFavorites,
  homeUpcomingEvents,
  homeUpcomingWorkshops,
  deleteHomeFavorites,
  fetchHomeUpcomingEvents,
  fetchHomeUpcomingWorkshops,
  userFavorite,
  homeFollowings,
  fetchHomeFollowings,
  deleteHomeFollowings,
  userFollowing,
  userEventRegistrationsReqParams,
  setAllEventRegistrations,
  setAllWorkshopRegistrations,
  userWorkshopRegistrationsReqParams,
  getRates,
  isChatDetach,
  isChatMinimized,
}) {
  const navigate = useNavigate();
  // const [fATabSelected, setFATabSelected] = useState('following');
  const [uTabSelected, setUTabSelected] = useState('upcoming-events');
  let [match] = useSearchParams();

  function resetGoogleDialog() {
    setGoogleDialog(defaultGoogleDialogState);
  }

  const defaultGoogleDialogState = {
    show: false,
    title: `Sign in with google`,
    scopes: '',
    discoveryDocs: [],
    onHide: resetGoogleDialog,
    onCancel: resetGoogleDialog,
    onSuccess: () => {},
    onFail: () => {},
  };
  const [googleDialog, setGoogleDialog] = useState(defaultGoogleDialogState);

  function resetDialog() {
    setDialog(defaultDialogState);
  }

  const defaultDialogState = {
    show: false,
    title: '',
    contentTitle: '',
    isLoading: false,
    children: null,
    confirmationText: '',
    onCancel: resetDialog,
    onHide: resetDialog,
    onConfirm: () => {},
  };
  const [dialog, setDialog] = useState(defaultDialogState);

  useEffect(() => {
    getRates();
  }, [getRates]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!userEventRegistrationsReqParams.setAll) {
        setAllEventRegistrations();
      }
      if (!userWorkshopRegistrationsReqParams.setAll) {
        setAllWorkshopRegistrations();
      }
    }
  }, [isLoggedIn]); // eslint-disable-line

  const userSearch =
    search.category.value && search.category.value !== 'all' ? search.category : null;

  return (
    <>
      <EmptyModal
        centered
        show={googleDialog.show}
        title={googleDialog.title}
        displayCloseButton
        onCancel={resetGoogleDialog}
        onHide={resetGoogleDialog}
      >
        <GoogleSignIn
          text={
            'for adding automatic reminder to google calendar you need to sign in to your google account'
          }
          onSuccess={googleDialog.onSuccess}
          onFail={googleDialog.onFail}
          scopes={googleDialog.scopes}
          discoveryDocs={googleDialog.discoveryDocs}
        />
      </EmptyModal>

      <ConfirmationModal
        {...dialog}
        isLoading={dialog.isLoading}
        show={dialog.show}
        title={dialog.title}
        contentTitle={dialog.contentTitle}
        confirmationText={dialog.confirmationText}
        confirmButtonText={dialog.action}
        cancelButtonText={dialog.cancelButtonText}
        children={dialog.children}
        onCancel={resetDialog}
        onConfirm={dialog.onConfirm}
        onHide={resetDialog}
      />

      <WithSideBar
        location={location}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <HomeWrap>
          <MetaDecorator title={appName} description={HOME_PAGE_CONTENT} />
          <HomeSection isMobile={isMobile}>
            <HomeSectionTitle>LIVE</HomeSectionTitle>

            <SectionBodyWrap>
              <HomeSectionSubTitle
                isMobile={isMobile}
                header={
                  userSearch
                    ? userSearch.label
                    : HOME_DEFAULT_CATEGORIES.live.new.search.category.label
                }
                to={`/browse/live/new/${
                  userSearch
                    ? userSearch.value
                    : HOME_DEFAULT_CATEGORIES.live.new.search.category.value
                }`}
              />

              {/*<Tabs*/}
              {/*  selectedTab={elTabSelected}*/}
              {/*  onTabSelect={setElTabSelected}*/}
              {/*  tabs={[*/}
              {/*    {*/}
              {/*      label: 'Trending',*/}
              {/*      name: 'trending'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Trending - (country)',*/}
              {/*      name: 'trending-country'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Recommended',*/}
              {/*      name: 'recommended'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Popular',*/}
              {/*      name: 'popular'*/}
              {/*    }*/}
              {/*  ]}*/}
              {/*/>*/}
              <SectionBody
                isVisibleOnEmptyContent={true}
                isSelected={true}
                isLoggedIn={isLoggedIn}
                userId={userId}
                accessControls={accessControls}
                navigate={navigate}
                search={search}
                categoryType={HOME_DEFAULT_CATEGORIES.live.new.categoryType}
                userSettings={userSettings}
                isMobile={isMobile}
                fetch={fetchHomeLiveEvents}
                {...homeLiveEvents}
                setDialog={setDialog}
                setGoogleDialog={setGoogleDialog}
                Tile={EventTile}
                PlaceHolder={PlaceholderTile}
              />
            </SectionBodyWrap>
            {(!homeLiveEventsSuggestion1.reqParams.fetched ||
              !!homeLiveEventsSuggestion1.rows.length) && (
              <SectionBodyWrap>
                <HomeSectionSubTitle
                  isMobile={isMobile}
                  header={HOME_DEFAULT_CATEGORIES.live.suggestion1.search.category.label}
                  style={{ marginTop: 30 }}
                  to={`/browse/live/suggestion1/${HOME_DEFAULT_CATEGORIES.live.suggestion1.search.category.value}`}
                />
                <SectionBody
                  isSelected={true}
                  isLoggedIn={isLoggedIn}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  search={HOME_DEFAULT_CATEGORIES.live.suggestion1.search}
                  categoryType={HOME_DEFAULT_CATEGORIES.live.suggestion1.categoryType}
                  userSettings={userSettings}
                  isMobile={isMobile}
                  fetch={fetchHomeLiveEvents}
                  {...homeLiveEventsSuggestion1}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={EventTile}
                  PlaceHolder={PlaceholderTile}
                />
              </SectionBodyWrap>
            )}

            {(!homeLiveEventsSuggestion2.reqParams.fetched ||
              !!homeLiveEventsSuggestion2.rows.length) && (
              <SectionBodyWrap>
                <HomeSectionSubTitle
                  isMobile={isMobile}
                  header={HOME_DEFAULT_CATEGORIES.live.suggestion2.search.category.label}
                  style={{ marginTop: 30 }}
                  to={`/browse/live/suggestion2/${HOME_DEFAULT_CATEGORIES.live.suggestion2.search.category.value}`}
                />
                <SectionBody
                  isSelected={true}
                  isLoggedIn={isLoggedIn}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  search={HOME_DEFAULT_CATEGORIES.live.suggestion2.search}
                  categoryType={HOME_DEFAULT_CATEGORIES.live.suggestion2.categoryType}
                  userSettings={userSettings}
                  isMobile={isMobile}
                  fetch={fetchHomeLiveEvents}
                  {...homeLiveEventsSuggestion2}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={EventTile}
                  PlaceHolder={PlaceholderTile}
                />
              </SectionBodyWrap>
            )}
          </HomeSection>

          <HomeSection isMobile={isMobile}>
            <HomeSectionTitle>VOD</HomeSectionTitle>
            <SectionBodyWrap>
              <HomeSectionSubTitle
                isMobile={isMobile}
                header={
                  userSearch
                    ? userSearch.label
                    : HOME_DEFAULT_CATEGORIES.vod.new.search.category.label
                }
                to={`/browse/vod/new/${
                  userSearch
                    ? userSearch.value
                    : HOME_DEFAULT_CATEGORIES.vod.new.search.category.value
                }`}
              />

              {/*<Tabs*/}
              {/*  selectedTab={eTabSelected}*/}
              {/*  onTabSelect={setETabSelected}*/}
              {/*  tabs={[*/}
              {/*    {*/}
              {/*      label: 'Trending',*/}
              {/*      name: 'trending'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Trending - (country)',*/}
              {/*      name: 'trending-country'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Recommended',*/}
              {/*      name: 'recommended'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Popular',*/}
              {/*      name: 'popular'*/}
              {/*    }*/}
              {/*  ]}*/}
              {/*/>*/}
              <SectionBody
                isSelected={true}
                isVisibleOnEmptyContent={true}
                userId={userId}
                accessControls={accessControls}
                navigate={navigate}
                isMobile={isMobile}
                isLoggedIn={isLoggedIn}
                search={search}
                categoryType={HOME_DEFAULT_CATEGORIES.vod.new.categoryType}
                userSettings={userSettings}
                fetch={fetchHomeEvents}
                {...homeEvents}
                setDialog={setDialog}
                setGoogleDialog={setGoogleDialog}
                Tile={EventTile}
              />
            </SectionBodyWrap>

            {(!homeEventsSuggestion1.reqParams.fetched || !!homeEventsSuggestion1.rows.length) && (
              <SectionBodyWrap>
                <HomeSectionSubTitle
                  isMobile={isMobile}
                  header={HOME_DEFAULT_CATEGORIES.vod.suggestion1.search.category.label}
                  to={`/browse/vod/suggestion1/${HOME_DEFAULT_CATEGORIES.vod.suggestion1.search.category.value}`}
                  style={{ marginTop: 30 }}
                />
                <SectionBody
                  isSelected={true}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  isMobile={isMobile}
                  isLoggedIn={isLoggedIn}
                  search={HOME_DEFAULT_CATEGORIES.vod.suggestion1.search}
                  categoryType={HOME_DEFAULT_CATEGORIES.vod.suggestion1.categoryType}
                  userSettings={userSettings}
                  fetch={fetchHomeEvents}
                  {...homeEventsSuggestion1}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={EventTile}
                />
              </SectionBodyWrap>
            )}

            {(!homeEventsSuggestion2.reqParams.fetched || !!homeEventsSuggestion2.rows.length) && (
              <SectionBodyWrap>
                <HomeSectionSubTitle
                  isMobile={isMobile}
                  header={HOME_DEFAULT_CATEGORIES.vod.suggestion2.search.category.label}
                  to={`/browse/vod/suggestion2/${HOME_DEFAULT_CATEGORIES.vod.suggestion2.search.category.value}`}
                  style={{ marginTop: 30 }}
                />
                <SectionBody
                  isSelected={true}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  isMobile={isMobile}
                  isLoggedIn={isLoggedIn}
                  search={HOME_DEFAULT_CATEGORIES.vod.suggestion2.search}
                  categoryType={HOME_DEFAULT_CATEGORIES.vod.suggestion2.categoryType}
                  userSettings={userSettings}
                  fetch={fetchHomeEvents}
                  {...homeEventsSuggestion2}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={EventTile}
                />
              </SectionBodyWrap>
            )}
          </HomeSection>

          <HomeSection isMobile={isMobile}>
            <HomeSectionTitle>Workshops</HomeSectionTitle>

            <SectionBodyWrap>
              <HomeSectionSubTitle
                isMobile={isMobile}
                header={
                  userSearch
                    ? userSearch.label
                    : HOME_DEFAULT_CATEGORIES.series.new.search.category.label
                }
                to={`/browse/series/new/${
                  userSearch
                    ? userSearch.label
                    : HOME_DEFAULT_CATEGORIES.series.new.search.category.value
                }`}
              />

              {/*<Tabs*/}
              {/*  selectedTab={wTabSelected}*/}
              {/*  onTabSelect={setWTabSelected}*/}
              {/*  tabs={[*/}
              {/*    {*/}
              {/*      label: 'Trending',*/}
              {/*      name: 'trending'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Trending - (country)',*/}
              {/*      name: 'trending-country'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Recommended',*/}
              {/*      name: 'recommended'*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: 'Popular',*/}
              {/*      name: 'popular'*/}
              {/*    }*/}
              {/*  ]}*/}
              {/*/>*/}
              <SectionBody
                isSelected={true}
                isVisibleOnEmptyContent={true}
                userId={userId}
                accessControls={accessControls}
                navigate={navigate}
                isMobile={isMobile}
                isLoggedIn={isLoggedIn}
                search={search}
                categoryType={HOME_DEFAULT_CATEGORIES.series.new.categoryType}
                userSettings={userSettings}
                fetch={fetchHomeWorkshops}
                {...homeWorkshops}
                setDialog={setDialog}
                setGoogleDialog={setGoogleDialog}
                Tile={WorkshopTile}
              />
            </SectionBodyWrap>

            {(!homeWorkshopsSuggestion1.reqParams.fetched ||
              !!homeWorkshopsSuggestion1.rows.length) && (
              <SectionBodyWrap>
                <HomeSectionSubTitle
                  isMobile={isMobile}
                  header={HOME_DEFAULT_CATEGORIES.series.suggestion1.search.category.label}
                  to={`/browse/series/suggestion1/${HOME_DEFAULT_CATEGORIES.series.suggestion1.search.category.value}`}
                  style={{ marginTop: 30 }}
                />
                <SectionBody
                  isSelected={true}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  isMobile={isMobile}
                  isLoggedIn={isLoggedIn}
                  search={HOME_DEFAULT_CATEGORIES.series.suggestion1.search}
                  categoryType={HOME_DEFAULT_CATEGORIES.series.suggestion1.categoryType}
                  userSettings={userSettings}
                  fetch={fetchHomeWorkshops}
                  {...homeWorkshopsSuggestion1}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={WorkshopTile}
                />
              </SectionBodyWrap>
            )}

            {(!homeWorkshopsSuggestion2.reqParams.fetched ||
              !!homeWorkshopsSuggestion2.rows.length) && (
              <SectionBodyWrap>
                <HomeSectionSubTitle
                  isMobile={isMobile}
                  header={HOME_DEFAULT_CATEGORIES.series.suggestion2.search.category.label}
                  to={`/browse/series/suggestion2/${HOME_DEFAULT_CATEGORIES.series.suggestion2.search.category.value}`}
                  style={{ marginTop: 30 }}
                />
                <SectionBody
                  isSelected={true}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  isMobile={isMobile}
                  isLoggedIn={isLoggedIn}
                  search={HOME_DEFAULT_CATEGORIES.series.suggestion2.search}
                  categoryType={HOME_DEFAULT_CATEGORIES.series.suggestion2.categoryType}
                  userSettings={userSettings}
                  fetch={fetchHomeWorkshops}
                  {...homeWorkshopsSuggestion2}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={WorkshopTile}
                />
              </SectionBodyWrap>
            )}
          </HomeSection>

          {isLoggedIn && (
            <HomeSection
              isMobile={isMobile}
              style={{ display: homeFollowings.totalRows ? 'block' : 'none' }}
            >
              <HomeSectionTitle>CHANNELS</HomeSectionTitle>
              <HomeSectionSubTitle as="div">Following</HomeSectionSubTitle>
              {/*<Tabs*/}
              {/*  selectedTab={fATabSelected}*/}
              {/*  onTabSelect={setFATabSelected}*/}
              {/*  tabs={[*/}
              {/*    // {*/}
              {/*    //   label: 'Top Ranked',*/}
              {/*    //   name: 'top-ranked'*/}
              {/*    // },*/}
              {/*    {*/}
              {/*      label: 'Following',*/}
              {/*      name: 'following'*/}
              {/*    },*/}
              {/*    // {*/}
              {/*    //   label: 'Recommended',*/}
              {/*    //   name: 'recommended'*/}
              {/*    // }*/}
              {/*  ]}*/}
              {/*/>*/}
              <HomeSectionBody isMobileDevice={isMobileDevice()}>
                {
                  <SectionBody
                    isSelected={true}
                    // search={search}
                    userSettings={userSettings}
                    isMobile={isMobile}
                    isLoggedIn={isLoggedIn}
                    fetch={fetchHomeFollowings}
                    deletion={deleteHomeFollowings}
                    {...homeFollowings}
                    totalPages={Math.ceil(userFollowing.length / (PAGE_SIZE + 2))}
                    totalRows={userFollowing.length}
                    pageSize={PAGE_SIZE + 2}
                    customPagination={true}
                    dataSet={userFollowing}
                    Tile={ChannelTile}
                    PlaceHolder={PlaceholderChannelTile}
                  />
                }
              </HomeSectionBody>
            </HomeSection>
          )}

          {/*<HomeSection  isMobile={isMobile}>*/}
          {/*  <HomeSectionTitle>*/}
          {/*    Whats Your Friends Are Up To*/}
          {/*  </HomeSectionTitle>*/}
          {/*  <Tabs*/}
          {/*    selectedTab={ fATabSelected }*/}
          {/*    onTabSelect={ setFATabSelected }*/}
          {/*    tabs={ [*/}
          {/*      {*/}
          {/*        label: 'Online',*/}
          {/*        name: 'online'*/}
          {/*      },*/}
          {/*      {*/}
          {/*        label: 'Watching',*/}
          {/*        name: 'watching'*/}
          {/*      },*/}
          {/*      // {*/}
          {/*      //   label: 'Following',*/}
          {/*      //   name: 'following'*/}
          {/*      // },*/}
          {/*    ] }*/}
          {/*  />*/}
          {/*<HomeSectionBody>*/}
          {/*  <UserTile*/}
          {/*    id={ 1 }*/}
          {/*    fullName="Some Name11"*/}
          {/*  />*/}
          {/*  <UserTile*/}
          {/*    id={ 2 }*/}
          {/*    fullName="Some Name22"*/}
          {/*  />*/}
          {/*  <UserTile*/}
          {/*    id={ 3 }*/}
          {/*    fullName="Some Name33"*/}
          {/*  />*/}
          {/*  <UserTile*/}
          {/*    id={ 4 }*/}
          {/*    fullName="Some Name44"*/}
          {/*  />*/}
          {/*  <UserTile*/}
          {/*    id={ 5 }*/}
          {/*    fullName="Some Name55"*/}
          {/*  />*/}
          {/*  <UserTile*/}
          {/*    id={ 6 }*/}
          {/*    fullName="Some Name66"*/}
          {/*  />*/}
          {/*  <UserTile*/}
          {/*    id={ 7 }*/}
          {/*    fullName="Some Name77"*/}
          {/*  />*/}
          {/*  <UserTile*/}
          {/*    id={ 8 }*/}
          {/*    fullName="Some Name88"*/}
          {/*  />*/}
          {/*</HomeSectionBody>*/}
          {/*</HomeSection>*/}

          {isLoggedIn && (
            <HomeSection isMobile={isMobile}>
              <HomeSectionTitle>About You</HomeSectionTitle>
              <Tabs
                selectedTab={uTabSelected}
                onTabSelect={setUTabSelected}
                tabs={[
                  // {
                  //   label: 'Continue Watching',
                  //   name: 'continue-watching'
                  // },
                  {
                    label: 'Upcoming Events',
                    name: 'upcoming-events',
                  },
                  {
                    label: 'Upcoming Workshops',
                    name: 'upcoming-workshops',
                  },
                  // {
                  //   label: 'Following',
                  //   name: 'following'
                  // },
                  {
                    label: 'Favorite',
                    name: 'favorite',
                  },
                ]}
              />
              <HomeSectionBody>
                <SectionBody
                  isSelected={'upcoming-events' === uTabSelected}
                  isVisibleOnEmptyContent={true}
                  uTabSelected={uTabSelected}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  // search={search}
                  userSettings={userSettings}
                  isMobile={isMobile}
                  isLoggedIn={isLoggedIn}
                  fetch={fetchHomeUpcomingEvents}
                  {...homeUpcomingEvents}
                  Tile={EventTile}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  PlaceHolder={PlaceholderTile}
                />
                <SectionBody
                  isSelected={'upcoming-workshops' === uTabSelected}
                  uTabSelected={uTabSelected}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  // search={search}
                  userSettings={userSettings}
                  isMobile={isMobile}
                  isLoggedIn={isLoggedIn}
                  fetch={fetchHomeUpcomingWorkshops}
                  {...homeUpcomingWorkshops}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={WorkshopTile}
                />
                <SectionBody
                  isSelected={'favorite' === uTabSelected}
                  uTabSelected={uTabSelected}
                  userId={userId}
                  accessControls={accessControls}
                  navigate={navigate}
                  search={search}
                  userSettings={userSettings}
                  isMobile={isMobile}
                  isLoggedIn={isLoggedIn}
                  fetch={fetchHomeFavorites}
                  deletion={deleteHomeFavorites}
                  {...homeFavorites}
                  totalPages={Math.ceil(userFavorite.length / PAGE_SIZE)}
                  totalRows={userFavorite.length}
                  pageSize={PAGE_SIZE}
                  customPagination={true}
                  dataSet={userFavorite}
                  setDialog={setDialog}
                  setGoogleDialog={setGoogleDialog}
                  Tile={null}
                />
              </HomeSectionBody>
            </HomeSection>
          )}
          <Spacer />
        </HomeWrap>
      </WithSideBar>
    </>
  );
}

Home.propTypes = {
  navigate: PropTypes.func,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  search: PropTypes.object,
  homeLiveEvents: PropTypes.object,
  homeLiveEventsSuggestion1: PropTypes.object,
  homeLiveEventsSuggestion2: PropTypes.object,
  homeEvents: PropTypes.object,
  homeEventsSuggestion1: PropTypes.object,
  homeEventsSuggestion2: PropTypes.object,
  homeWorkshops: PropTypes.object,
  homeWorkshopsSuggestion1: PropTypes.object,
  homeWorkshopsSuggestion2: PropTypes.object,
  fetchHomeEvents: PropTypes.func,
  fetchHomeWorkshops: PropTypes.func,
  fetchHomeFavorites: PropTypes.func,
  deleteHomeFavorites: PropTypes.func,
  fetchHomeFollowings: PropTypes.func,
  deleteHomeFollowings: PropTypes.func,
  fetchHomeUpcomingEvents: PropTypes.func,
  fetchHomeUpcomingWorkshops: PropTypes.func,
  userEventRegistrationsReqParams: PropTypes.object,
  userWorkshopRegistrationsReqParams: PropTypes.object,
  setAllWorkshopRegistrations: PropTypes.func,
  setAllEventRegistrations: PropTypes.func,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    search: getSearch(state),
    homeLiveEvents: getHomeLiveEvents(state),
    homeLiveEventsSuggestion1: getHomeLiveEventsSuggestion1(state),
    homeLiveEventsSuggestion2: getHomeLiveEventsSuggestion2(state),
    homeEvents: getHomeEvents(state),
    homeEventsSuggestion1: getHomeEventsSuggestion1(state),
    homeEventsSuggestion2: getHomeEventsSuggestion2(state),
    homeWorkshops: getHomeWorkshops(state),
    homeWorkshopsSuggestion1: getHomeWorkshopsSuggestion1(state),
    homeWorkshopsSuggestion2: getHomeWorkshopsSuggestion2(state),
    userFavorite: getUserFavorites(state),
    homeFavorites: getHomeFavorites(state),
    userFollowing: getUserFollowings(state),
    homeFollowings: getHomeFollowings(state),
    homeUpcomingEvents: getHomeUpcomingEvents(state),
    homeUpcomingWorkshops: getHomeUpcomingWorkshops(state),
    userEventRegistrationsReqParams: getUserEventRegistrationsReqParams(state),
    userWorkshopRegistrationsReqParams: getUserWorkshopRegistrationsReqParams(state),
    accessControls: getAccessControl(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchHomeLiveEvents,
      fetchHomeEvents,
      fetchHomeWorkshops,
      fetchHomeFavorites,
      deleteHomeFavorites,
      fetchHomeFollowings,
      deleteHomeFollowings,
      fetchHomeUpcomingEvents,
      fetchHomeUpcomingWorkshops,
      setAllEventRegistrations,
      setAllWorkshopRegistrations,
      getRates,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
