import { appName } from 'config/config';
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import { getUserId } from '../../reduxUtils/user/selectors';
import { getUserFullName, getUserAvatarThumbnail } from '../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
} from '../../reduxUtils/browserSettings/selectors';
import { getSearch } from '../../reduxUtils/search/selectors';
import { getRates } from '../../reduxUtils/browserSettings/thunks';
import WithSideBar from 'layouts/WithSideBar';
import MetaDecorator from 'components/MetaDecorator';
import { HOME_PAGE_CONTENT } from 'config/constants';
import { getUserFavorites } from '../../reduxUtils/userFavorites/selectors';
import { getUserFollowings } from '../../reduxUtils/userFollowings/selectors';
import { setAllEventRegistrations } from '../../reduxUtils/userEventRegistrations/thunks';
import { getUserEventRegistrationsReqParams } from '../../reduxUtils/userEventRegistrations/selectors';
import { getUserWorkshopRegistrationsReqParams } from '../../reduxUtils/userWorkshopRegistrations/selectors';
import { setAllWorkshopRegistrations } from '../../reduxUtils/userWorkshopRegistrations/thunks';
import { getAccessControl } from '../../reduxUtils/accessControl/selectors';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';
import { fetchPurchasesLiveEvents } from '../../reduxUtils/purchase/purchasesLiveEvents/thunks';
import { fetchPurchasesEvents } from '../../reduxUtils/purchase/purchasesEvents/thunks';
import { getPurchasesLiveEvents } from '../../reduxUtils/purchase/purchasesLiveEvents/selectors';
import { getPurchasesEvents } from '../../reduxUtils/purchase/purchasesEvents/selectors';
import { fetchPurchasesWorkshops } from '../../reduxUtils/purchase/purchasesWorkshops/thunks';
import { getPurchasesWorkshops } from '../../reduxUtils/purchase/purchasesWorkshops/selectors';
import Fallback from 'components/Fallback';

const PurchasesComponent = lazy(() => import('./Purchases'));

function Purchases({
  navigate,
  location,
  isMobile,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  accessControls,
  userSettings,
  fullName,
  avatar,
  search,
  purchasesLiveEvents,
  fetchPurchasesLiveEvents,
  purchasesEvents,
  fetchPurchasesEvents,
  purchasesWorkshops,
  fetchPurchasesWorkshops,
  userEventRegistrationsReqParams,
  setAllEventRegistrations,
  setAllWorkshopRegistrations,
  userWorkshopRegistrationsReqParams,
  isChatDetach,
  isChatMinimized,
  getRates,
}) {
  return (
    <WithSideBar
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      isChatMinimized={isChatMinimized && isChatDetach}
    >
      <MetaDecorator title={appName} description={HOME_PAGE_CONTENT} />
      <Suspense fallback={<Fallback />}>
        <PurchasesComponent
          navigate={navigate}
          isMobile={isMobile}
          isLoggedIn={isLoggedIn}
          userId={userId}
          accessControls={accessControls}
          userSettings={userSettings}
          search={search}
          purchasesLiveEvents={purchasesLiveEvents}
          fetchPurchasesLiveEvents={fetchPurchasesLiveEvents}
          purchasesEvents={purchasesEvents}
          fetchPurchasesEvents={fetchPurchasesEvents}
          purchasesWorkshops={purchasesWorkshops}
          fetchPurchasesWorkshops={fetchPurchasesWorkshops}
          userEventRegistrationsReqParams={userEventRegistrationsReqParams}
          setAllEventRegistrations={setAllEventRegistrations}
          setAllWorkshopRegistrations={setAllWorkshopRegistrations}
          userWorkshopRegistrationsReqParams={userWorkshopRegistrationsReqParams}
          getRates={getRates}
        />
      </Suspense>
    </WithSideBar>
  );
}

Purchases.propTypes = {
  navigate: PropTypes.object,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  purchasesLiveEvents: PropTypes.object,
  purchasesEvents: PropTypes.object,
  homeWorkshops: PropTypes.object,
  fetchPurchasesEvents: PropTypes.func,
  fetchPurchasesWorkshops: PropTypes.func,
  userEventRegistrationsReqParams: PropTypes.object,
  userWorkshopRegistrationsReqParams: PropTypes.object,
  setAllWorkshopRegistrations: PropTypes.func,
  setAllEventRegistrations: PropTypes.func,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    search: getSearch(state),
    purchasesLiveEvents: getPurchasesLiveEvents(state),
    purchasesEvents: getPurchasesEvents(state),
    purchasesWorkshops: getPurchasesWorkshops(state),
    userFavorite: getUserFavorites(state),
    userFollowing: getUserFollowings(state),
    userEventRegistrationsReqParams: getUserEventRegistrationsReqParams(state),
    userWorkshopRegistrationsReqParams: getUserWorkshopRegistrationsReqParams(state),
    accessControls: getAccessControl(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPurchasesLiveEvents,
      fetchPurchasesEvents,
      fetchPurchasesWorkshops,
      setAllEventRegistrations,
      setAllWorkshopRegistrations,
      getRates,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
