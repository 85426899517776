import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { errorHandler, successHandler } from 'utilities/services';
import { canWatchLiveEvent, getEventRegistrationLogic, getEventUrl } from 'utilities/event';
import {
  DROPIN_NOT_ALLOWED,
  FREE_EVENT_NO_UNREGISTER_ERROR,
  FREE_EVENT_NO_UNREGISTER_TIME,
  OFFSET_START_TIME,
  PAID_EVENT_NO_UNREGISTER_ERROR,
  WORKSHOP_EVENT_BUY_NOTICE,
  WORKSHOP_EVENT_FREE_NOTICE,
} from 'config/constants';
import { dateToUserTimeZone, getDiff } from 'utilities/date';
import CustomLabel from 'components/CustomLabel';
import {
  deleteEventFromGoogleCalendar,
  getCalendarEventId,
  setEventCalendarConfirmation,
} from 'utilities/calendar';

import {
  AL_EVENT,
  AL_EVENT_ACTION,
  AL_EVENT_STATUS,
  alPushContent,
  alPurchaseRefund,
} from 'utilities/analytics';

const EventRegButtonWrap = styled(CustomLabel)``;

function EventRegButton({
  className,
  label,
  userId,
  buyLabel = label,
  registerLabel = label,
  unRegisterLabel = label,
  eventId,
  summary,
  duration,
  occurrence,
  WorkshopId,
  isFree,
  eventRegistrations,
  addEventRegistration,
  createEventRegistration,
  deleteEventRegistration,
  refundContent,
  updateParentComponentOnRegister = () => {},
  updateParentComponentOnRemoveRegister = () => {},
  title = '',
  allowDropIn,
  drag,
  forceUpdate,
  event,
  onBuyClick,
  accessControls,
  adminOrOwner,
  setDialog,
  setGoogleDialog,
}) {
  const navigate = useNavigate();

  const busyRef = useRef(false);
  const timer = useRef(null);
  const [refresh, setRefresh] = useReducer((x) => x + 1, 0);

  const { id, date } = occurrence || {};
  const eventRegLogic = adminOrOwner
    ? {}
    : getEventRegistrationLogic({
        date,
        duration,
        eventId,
        occurrenceId: id,
        WorkshopId,
        registrations: eventRegistrations,
        isFree,
        allowDropIn,
      });

  useEffect(
    () => {
      if (occurrence && occurrence.date) {
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = null;
        }

        const { date } = occurrence;
        const now = dateToUserTimeZone(new Date());
        const timeDiff = Math.floor(getDiff(now, date));
        let timerRemaining = 0;

        switch (true) {
          case timeDiff < -FREE_EVENT_NO_UNREGISTER_TIME:
            timerRemaining = Math.abs(timeDiff) - FREE_EVENT_NO_UNREGISTER_TIME;
            break;
          case timeDiff < -OFFSET_START_TIME:
            timerRemaining = Math.abs(timeDiff) - OFFSET_START_TIME;
            break;
          case timeDiff < 0:
            timerRemaining = Math.abs(timeDiff);
            break;

          default:
          // code block
        }

        if (!timerRemaining || timerRemaining > 3 * 60 * 60) {
          //update static page upto 3 hour
          return;
        }

        timer.current = setTimeout(() => {
          forceUpdate && forceUpdate();
          setRefresh();
        }, timerRemaining * 1000);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer.current);
      }
    },
    [occurrence, refresh] // eslint-disable-line
  );

  function deregisterToEventOccurrence(registrationId) {
    const action = isFree ? 'unregister' : 'refund';
    const dialogTitle = isFree ? 'Unregister' : 'Refund';
    setDialog((current) => {
      return {
        show: true,
        title: `${dialogTitle} Event`,
        confirmationText: `Are you sure you want to ${action}`,
        contentTitle: `${title ? `"${title}"` : ''}`,
        action: action,
        onCancel: current.onCancel,
        onConfirm: () => {
          if (busyRef.current) {
            return;
          }
          busyRef.current = true;
          current.onHide();

          let error;
          const func = isFree
            ? () => deleteEventRegistration(eventId, occurrence.id, registrationId)
            : () =>
                refundContent({
                  eventId: eventId,
                  occurrenceId: occurrence.id,
                  registrationId,
                });

          func()
            .then(() => {
              updateParentComponentOnRemoveRegister();
              const calendarEventId = getCalendarEventId({
                eventId: eventId,
                occurrenceId: occurrence.id,
              });
              deleteEventFromGoogleCalendar(calendarEventId);
              const msg = isFree
                ? `You've successfully unregistered from the event`
                : 'Refund Successfully, confirmation is send to your email';
              successHandler(msg);
            })
            .catch((err) => {
              error = err.message;
              errorHandler(err);
            })
            .finally(() => {
              const alPush = isFree ? alPushContent : alPurchaseRefund;
              alPush({
                alEvent: isFree ? AL_EVENT.EVENT.unregisterEvent : AL_EVENT.GENERAL.refund,
                eventAction: AL_EVENT_ACTION.submit,
                eventStatus: error ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
                eventLabel: 'unregister from event',
                content: event,
                occurrenceId: occurrence.id,
                error: error,
                isRefund: isFree ? true : undefined,
              });
              busyRef.current = false;
              current.onCancel();
            });
        },
      };
    });
  }

  function registerToEventOccurrence(occurrence) {
    if (busyRef.current) {
      return;
    }

    let error;
    busyRef.current = true;
    createEventRegistration(eventId, occurrence.id, event)
      .then(() => {
        updateParentComponentOnRegister();
        setEventCalendarConfirmation({
          adminOrOwner,
          eventId,
          occurrence,
          duration,
          title,
          summary,
          setGoogleDialog,
          setDialog,
        });
      })
      .catch((err) => {
        error = err.message;
        errorHandler(err);
      })
      .finally(() => {
        alPushContent({
          alEvent: AL_EVENT.EVENT.registerEvent,
          eventAction: AL_EVENT_ACTION.submit,
          eventStatus: error ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
          eventLabel: 'register to event',
          content: event,
          occurrenceId: occurrence.id,
          error: error,
        });
        busyRef.current = false;
      });
  }

  function onCompleteBuy(transaction) {
    successHandler(`Your purchase of ${title} successfully completed\nyou may enter the event`);
    if (eventRegLogic.eventNow) {
      navigate(getEventUrl(eventId, occurrence.id));
    } else {
      addEventRegistration(occurrence.id, event);
      updateParentComponentOnRegister();
      setEventCalendarConfirmation({
        adminOrOwner,
        eventId,
        occurrence,
        duration,
        title,
        summary,
        setGoogleDialog,
        setDialog,
      });
    }
  }

  function onRegisterClick(registered, occurrence, registrationId) {
    if (registered) {
      deregisterToEventOccurrence(registrationId);
    } else {
      if (isFree) {
        registerToEventOccurrence(occurrence);
      } else {
        onBuyClick(onCompleteBuy);
      }
    }
  }

  function onClick(eventRegLogic, showUnregister, isDisabledUnregister, occurrence, needToBuy) {
    if (adminOrOwner) {
      navigate(getEventUrl(eventId, occurrence.id, true));
      return;
    }

    if (showUnregister) {
      if (isDisabledUnregister) {
        errorHandler(isFree ? FREE_EVENT_NO_UNREGISTER_ERROR : PAID_EVENT_NO_UNREGISTER_ERROR);
        return;
      }
      onRegisterClick(eventRegLogic.registered, occurrence, eventRegLogic.registration.id);
      return;
    }

    if (eventRegLogic.dropIn && !eventRegLogic.allowDropIn) {
      errorHandler(DROPIN_NOT_ALLOWED);
      return;
    }

    if (
      canWatchLiveEvent({
        isLoggedIn: true,
        adminOrOwner,
        accessControls,
        ...eventRegLogic,
      })
    ) {
      navigate(getEventUrl(eventId, occurrence.id));
      return;
    }

    if (WorkshopId && !eventRegLogic.registered) {
      if (!isFree) {
        errorHandler(WORKSHOP_EVENT_BUY_NOTICE);
      } else {
        errorHandler(WORKSHOP_EVENT_FREE_NOTICE);

        // navigate(getEventInspectUrl(eventId, occurrence?.id));
      }

      return;
    }

    if (needToBuy) {
      onBuyClick(onCompleteBuy, occurrence);
      return;
    }

    if (eventRegLogic.needToRegister) {
      onRegisterClick(eventRegLogic.registered, occurrence, eventRegLogic.registration.id);
    }
  }

  if (!occurrence) {
    return null;
  }

  const showUnregister = !adminOrOwner && !eventRegLogic.dropIn && eventRegLogic.registered;
  const needToBuy = !adminOrOwner && !WorkshopId && !isFree && !eventRegLogic.registered;
  const unregisterOn = !adminOrOwner && !eventRegLogic.dropIn && eventRegLogic.registered;
  const isDisabledUnregister = unregisterOn && eventRegLogic.disableUnregister;
  const isDisabledDropIn = eventRegLogic.dropIn && !eventRegLogic.allowDropIn;

  function isDisabled() {
    if (adminOrOwner) {
      return false;
    }

    return isDisabledUnregister || isDisabledDropIn;
  }

  const getLabel = () => {
    if (adminOrOwner) {
      return label;
    }

    if (needToBuy) {
      return buyLabel;
    }

    if (showUnregister) {
      return unRegisterLabel;
    }

    if (eventRegLogic.needToRegister) {
      return registerLabel;
    }

    return label;
  };

  return (
    <EventRegButtonWrap
      className={className}
      invertColor={showUnregister}
      disable={isDisabled()}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        !(drag && drag.current && drag.current.isDrag) &&
          onClick(eventRegLogic, showUnregister, isDisabledUnregister, occurrence, needToBuy);
      }}
      label={getLabel()}
    />
  );
}

EventRegButton.propTypes = {
  adminOrOwner: PropTypes.bool,
  label: PropTypes.string,
  buyLabel: PropTypes.string,
  registerLabel: PropTypes.string,
  unRegisterLabel: PropTypes.string,
  eventId: PropTypes.number,
  summary: PropTypes.string,
  duration: PropTypes.number,
  occurrence: PropTypes.object,
  isFree: PropTypes.bool,
  eventRegistrations: PropTypes.array,
  addEventRegistration: PropTypes.func,
  createEventRegistration: PropTypes.func,
  deleteEventRegistration: PropTypes.func,
  refundContent: PropTypes.func,
  updateParentComponentOnRegister: PropTypes.func,
  updateParentComponentOnRemoveRegister: PropTypes.func,
  navigate: PropTypes.object,
  title: PropTypes.string,
  drag: PropTypes.object,
  forceUpdate: PropTypes.func,
  event: PropTypes.object,
  accessControls: PropTypes.array,
  onBuyClick: PropTypes.func,
  setDialog: PropTypes.func,
  setGoogleDialog: PropTypes.func,
};

export default EventRegButton;
