import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_USER_WORKSHOP_REGISTRATIONS,
  ADD_USER_WORKSHOP_REGISTRATION,
  DELETE_USER_WORKSHOP_REGISTRATION
} from './types';
import {LOGOUT} from "../auth/types";

const defaultFields = [
  'id',
  'createdAt',
  'updatedAt',
  'UserId',
  'WorkshopId',
];

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_USER_WORKSHOP_REGISTRATIONS]: (state, {payload}) => {
    if (!payload) {
      return state;
    }

    return {
      ...state,
      reqParams: {setAll: true},
      rows: [...payload]
    };
  },
  [ADD_USER_WORKSHOP_REGISTRATION]: (state, {payload}) => {
    const newRegistration = pick(payload, defaultFields);
    const foundIndex = state.rows.findIndex((registration) => registration.id === newRegistration.id);
    if (foundIndex === -1) {
      const registrations = [...state.rows];
      registrations.push(newRegistration);
      return {
        ...state,
        rows: registrations
      };
    }

    return state;
  },
  [DELETE_USER_WORKSHOP_REGISTRATION]: (state, {payload}) => {
    const foundIndex = (state.rows || []).findIndex((registration) => registration.id === payload);
    if (foundIndex > -1) {
      const registrations = [...(state.rows || [])];
      registrations.splice(foundIndex, 1);
      return {
        ...state,
        rows: registrations
      };
    }

    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, initialState);