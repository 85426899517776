import React from 'react';
//  import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Dropdown} from 'react-bootstrap';

import Icon from 'components/Icon';
import {UserSelectNoneCss} from 'styles/common';

const ContextMenuWrap = styled(Dropdown)`
  ${UserSelectNoneCss};
  min-width: auto !important;
`;

const ContextMenuToggle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  min-width: auto;
  color: ${({theme: {colors}}) => colors.grayNew};

  &:hover {
    color: ${({theme: {colors}}) => colors.darkGrey};
  }

`;

const ContextMenuIcon = styled(Icon)`
  width: auto;
  height: auto;
`;

const Menu = styled(ContextMenuWrap.Menu)`
  min-width: auto !important;
  padding: .3rem 0;
`;

function ContextMenu(
  {
    className,
    children,
    drop = 'left',
    menuIcon = "fas fa-ellipsis-v",
    ...props
  }
) {
  //  const [isLoading, setIsLoading] = useState(false);

  const CustomToggle = React.forwardRef(({children, onClick, classname}, ref) => (
    <ContextMenuToggle
      className={classname}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </ContextMenuToggle>
  ));

  return (
    <ContextMenuWrap
      drop={drop}
    >
      <ContextMenuWrap.Toggle
        classname={className}
        as={CustomToggle}
      >
        <ContextMenuIcon
          iconName={menuIcon}
        />
      </ContextMenuWrap.Toggle>
      <Menu>
        {children}
      </Menu>
    </ContextMenuWrap>
  );
}

ContextMenu.propTypes = {};

export default ContextMenu;