import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

import TextInput from '../TextInput';
import InfoButton from 'components/InfoButton';

import { ErrorFontSizeCss, LabelFontSizeCss, StyledTextInputCss } from 'styles/common';

const StyledTextInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
`;

const StyledTextInputFieldWrap = styled.div`
  ${StyledTextInputCss}
  &.is-focused {
    border: 1px solid ${({ theme: { colors } }) => colors.secondaryLight};
  }

  ${({ inputFieldCss }) => inputFieldCss};

  &.form-control.is-invalid {
    padding: 5px;
  }

  ${({ disabled_color }) =>
    disabled_color &&
    css`
      background-color: ${({ theme: { colors } }) => colors.disabled} !important;
    `}
`;

const TextInputWrap = styled(TextInput)`
  margin: 0 ${({ theme: { typo } }) => typo.sizes.tiniest};
  height: 100%;
  color: #495057;
  ${LabelFontSizeCss};

  ${({ inputStyleCss }) => inputStyleCss};
`;

const StyledTextInputLabel = styled.label`
  color: ${({ theme: { colors } }) => colors.grayNew};
  font-weight: 500;
  white-space: nowrap;
  ${LabelFontSizeCss};
`;

const StyledTextInputIcon = styled.i``;

const Feedback = styled.div`
  color: ${({ theme: { colors } }) => colors.error};
  font-weight: 400;
  ${ErrorFontSizeCss};

  &:hover {
    cursor: auto; //todo: zoom restyle
  }
`;

function StyledTextInput({
  label = '',
  error,
  hideErrorText,
  name,
  style,
  className = null,
  infoText,
  icon,
  inputTip,
  onFocus,
  onBlur,
  disabled,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [refs, setRefs] = useState(null);

  function handleOnFocus(e) {
    setIsFocused(true);
    onFocus && onFocus(e);
  }

  function handleOnBlur(e) {
    setIsFocused(false);
    onBlur && onBlur(e);
  }

  const wrapperClass = classnames(
    'form-group ',
    className,
    error && error.length > 0 ? 'has-error' : ''
  );

  const fieldClass = classnames(
    'form-control',
    error ? 'is-invalid' : '',
    !error && isFocused ? 'is-focused' : ''
  );

  return (
    <StyledTextInputWrap style={style} className={wrapperClass} onClick={() => refs.click()}>
      {label && <StyledTextInputLabel htmlFor={name}>{label}</StyledTextInputLabel>}

      <StyledTextInputFieldWrap className={fieldClass} disabled_color={+disabled} {...props}>
        {icon && <StyledTextInputIcon className={icon} />}
        <TextInputWrap
          setRef={(ref) => {
            setRefs(ref);
          }}
          name={name}
          {...props}
          disabled={disabled}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        {infoText && !error && <InfoButton infoText={infoText} />}
      </StyledTextInputFieldWrap>
      {error && !hideErrorText && <Feedback className="invalid-feedback">{error}</Feedback>}
      {inputTip && !error && <Feedback>{inputTip}</Feedback>}
    </StyledTextInputWrap>
  );
}

StyledTextInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideErrorText: PropTypes.bool,
  name: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  infoText: PropTypes.string,
  icon: PropTypes.string,
  inputTip: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default StyledTextInput;
