import React from "react";
import PropTypes from "prop-types";
import styled, {css} from 'styled-components';
import {
  OptionBoxCss,
  OptionBoxCssInner,
  OptionImageCss
} from "components/Chat/components/PollsContainer/components/JSXElements";
import {UserSelectNoneCss} from "styles/common";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;

`;


const OptionBoxWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${OptionBoxCss};

  ${({disabled}) => !disabled ?
    css`
      cursor: pointer;

      &:hover {
        border-width: 1px;
        outline: 2px solid ${({theme: {colors}}) => colors.violetPurple};
        border-color: ${({theme: {colors}}) => colors.violetPurple};
        margin-bottom: 2px;
      }

    `
    :
    css`
      cursor: auto;
    `
  };

  ${({isSelected}) => isSelected &&
    css`
      border-width: 1px;
      outline: 2px solid ${({theme: {colors}}) => colors.violetPurple};
      border-color: ${({theme: {colors}}) => colors.violetPurple};
      margin-bottom: 2px;
    `
  };

`;

const OptionBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${OptionBoxCssInner};
`;


const ProgressBar = styled.div`
  position: absolute;
  height: 100%;
  content: "";
  background-color: #e6f3ff;
  top: 0;
  bottom: 0;
  left: 0;
  //border-top-left-radius: inherit;
  //border-bottom-left-radius: inherit;
  //border-top-right-radius: 0;
  //border-bottom-right-radius: 0;
  transition: width .6s ease;
  ${({progress}) =>
    progress
    && css`
      width: ${progress};
    `
  };

`;

const ProgressText = styled.div`
  font-size: 11px;
  position: absolute;
  top: -2px;
  right: 5px;
  ${UserSelectNoneCss};
  z-index: 10;

`;

const TextStyled = styled.div`
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  ${UserSelectNoneCss};
  z-index: 5;
  width: 100%;
`;

const ImgStyled = styled.img`
  ${OptionImageCss};
  ${UserSelectNoneCss};
  z-index: 5;
  margin-left: 10px;
`;


function Option({
                  className,
                  value = '',
                  image = '',
                  votes = 0,
                  totalVotes,
                  onClick,
                  disabled,
                  isSelected,
                }) {

  const progress = totalVotes && `${((votes / totalVotes) * 100).toFixed(2)}%`;

  return (
    <Wrap className={className}>
      <OptionBoxWrapper
        disabled={disabled}
        isSelected={isSelected}
        onClick={disabled ? undefined : onClick}
        progress={progress}
      >
        {!!progress && <ProgressBar progress={progress} />}

        <OptionBox

        >
          {
            !!totalVotes &&
            <ProgressText>
              {votes}/{progress}
            </ProgressText>
          }
          <TextStyled dir={'auto'}>
            {value}
          </TextStyled>
          {
            !!image &&
            <ImgStyled
              src={image}
            />
          }
        </OptionBox>
      </OptionBoxWrapper>
    </Wrap>
  );
}

Option.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  isLast: PropTypes.bool,
  addNewOption: PropTypes.func,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default (React.memo(Option));