import React from 'react';
import styled, {css} from 'styled-components';
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'

const Wrap = styled.div`
  .flag-dropdown, .form-control {
    ${({error, theme: {colors}}) => {
      return error
        ? css`border-color: ${colors.error}`
        : null;
    }};

    .country-list {
      //max-height: 170px;
      outline: none;
      position: fixed;
    }

    &.error {
      border: 1px solid ${({theme: {colors}}) => colors.error};
    }

`;

function PhoneNumberInput({error, ...props}) {
  return (
    <Wrap error={error}>
      <PhoneInput {...props} />
    </Wrap>
  );
}

PhoneNumberInput.propTypes = {};

export default PhoneNumberInput;