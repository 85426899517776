import React, {lazy, Suspense} from 'react';
import Fallback from 'components/Fallback';

const AdminPanelLazy = lazy(() => import('./AdminPanel'));
function AdminPanel({match}) {
  return (
    <Suspense fallback={<Fallback/>}>
      <AdminPanelLazy match={match}/>
    </Suspense>
  );
}

export default AdminPanel;
