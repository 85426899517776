import { isPaid, parseInteger } from 'utilities/general';
import {
  INCOMPLETE_WORKSHOP_KEY,
  WORKSHOP_REFUND_NOTICE,
  WORKSHOP_STATUS,
  WORKSHOP_TYPE,
  WORKSHOP_TYPE_SELECTOR,
} from 'config/constants';
import { getChannelBaseUrl, getEncodedRedirectPath } from './general';
import { isEventPublished } from 'utilities/event';
import {
  getContentErrors,
  hasCategoriesError,
  hasParticipantsLimitError,
  hasPriceError,
} from 'utilities/inputValidation';

import { errorHandler, successHandler } from 'utilities/services';
import { AL_EVENT, AL_EVENT_ACTION, AL_EVENT_STATUS, alPushContent } from 'utilities/analytics';
import {
  deleteBatchWorkshopEventFromGoogleCalendar,
  setWorkshopCalendarConfirmation,
} from 'utilities/calendar';
import WorkshopService from 'services/workshop';
import { getStore, getStoreState } from 'utilities/store';
import { getUserSettings } from '../reduxUtils/userSettings/selectors';
import { isEqual } from 'lodash';
import {
  completeWorkshop,
  resetWorkshopActions,
  updateWorkshop,
} from '../reduxUtils/workshop/thunks';
import { removeData } from 'utilities/localStorage';

export function getWorkshopBaseUrl(id, fullPath = false) {
  return `${fullPath ? window.location.origin : ''}/workshop/${id}`;
}

export function getWorkshopUrl(id, isAdminView, fullPath = false) {
  return `${getWorkshopBaseUrl(id, fullPath)}${isAdminView ? '?adminView=1' : ''}`;
}

export function getWorkshopEditUrl(channelId, workshopId) {
  return `${getChannelBaseUrl(channelId)}${getWorkshopBaseUrl(
    workshopId
  )}/edit${getEncodedRedirectPath()}`;
}

export function getWorkshopManageUrl(id) {
  return `${getWorkshopBaseUrl(id)}/manage/events`;
}

export function getWorkshopInspectUrl(id, fullPath = false) {
  return `${getWorkshopBaseUrl(id, fullPath)}/inspect/events`;
}

export function isWorkshopPublished(status) {
  return status === WORKSHOP_STATUS.PUBLISHED.key;
}

export function getPublishingWorkshopError(workshop = {}) {
  if (workshop.status !== WORKSHOP_STATUS.COMPLETED.key) {
    return 'You must finish creating the workshop first !';
  }
  if (workshop.eventsCount < 2) {
    return 'To publish a workshop it should have at least 2 published events';
  }

  return null;
}

export function hasParticipantsLimitPermissionError(
  workshopId,
  userId,
  accessControls,
  participantsLimit
) {
  // todo: when we will implement membership enable this and test
  // if (!canCreateParticipantsLimitWorkshop(workshopId, userId, accessControls, participantsLimit)) {
  //   return 'Participants Limit is too high. Upgrade your membership.';
  // }
  return false;
}

export function validateWorkshopDetails(workshop, userId, accessControls, userSettings) {
  const { id, categories, price, participantsLimit, paymentType } = workshop;

  const errors = getContentErrors(workshop);

  const categoriesError = hasCategoriesError(categories);
  if (categoriesError) {
    errors.categories = categoriesError;
  }

  const priceError = hasPriceError(price, paymentType, userSettings.currency);
  if (priceError) {
    errors.price = priceError;
  }

  const participantsLimitError = hasParticipantsLimitError(participantsLimit);
  if (participantsLimitError) {
    errors.participantsLimit = participantsLimitError;
  }

  const numericParticipantsLimit = parseInteger(participantsLimit) || 0;
  const participantsLimitPermissionError = hasParticipantsLimitPermissionError(
    id,
    userId,
    accessControls,
    numericParticipantsLimit
  );
  if (participantsLimitPermissionError) {
    errors.membership = true;
    errors.participantsLimit = participantsLimitPermissionError;
  }
  return errors;
}

export function findWorkshopType(events = []) {
  let type = WORKSHOP_TYPE_SELECTOR.none;
  if (!events) return type;

  for (let i = 0; i < events.length; ++i) {
    const { isLive, status } = events[i];
    if (!isEventPublished(status)) {
      continue;
    }
    if (isLive === true) {
      type = type | WORKSHOP_TYPE_SELECTOR.live;
    } else {
      if (isLive === false) {
        type = type | WORKSHOP_TYPE_SELECTOR.vod;
      }
    }
    if (type === WORKSHOP_TYPE_SELECTOR.mix) {
      return type;
    }
  }
  return type;
}

export function getWorkshopRegistrationLogic(registration = {}, id, isFree) {
  let registered = false;
  let url = '';
  let dropIn = false;
  let disableUnregister = false;

  registered = !!(registration && registration.id);

  return {
    registration: registration,
    registered: registered,
    url: url,
    dropIn: dropIn,
    disableUnregister: disableUnregister,
  };
}

export function onWorkshopBuyClick({
  setBuyDialog,
  onComplete,
  id,
  title,
  coverPhotoHomePage,
  price,
  currency,
  userCurrency,
  content,
}) {
  setBuyDialog((current) => {
    return {
      ...current,
      header: 'Workshop',
      subHeader: '',
      refundLabel: WORKSHOP_REFUND_NOTICE,
      eventId: null,
      occurrenceId: null,
      workshopId: id,
      occurrence: null,
      title: title,
      coverPhoto: coverPhotoHomePage,
      price: price,
      currency: currency,
      userCurrency: userCurrency,
      content: content,
      show: true,
      onComplete: onComplete,
    };
  });
}

const workshopService = new WorkshopService();

function updateVisibility(isPublished, workshopId) {
  return workshopService.updateWorkshopVisibility(workshopId, { isPublished });
}

export function onWorkshopToggleVisibility({
  adminOrOwner,
  isPublished,
  workshop,
  updateWorkshopState,
  workshopEventsRef,
  workshopType,
  setDialog,
  setGoogleDialog,
}) {
  const publishingErrors = getPublishingWorkshopError(workshop);

  if (isPublished && publishingErrors) {
    errorHandler(publishingErrors);
    return false;
  }

  const action = isPublished ? AL_EVENT_ACTION.publish : AL_EVENT_ACTION.unpublish;
  let actionButton = isPublished ? AL_EVENT_ACTION.publish : AL_EVENT_ACTION.unpublish;
  let text = `Are you sure you want to ${action}`;

  // user going to unpublish the event
  if (!isPublished) {
    const registrationsCount = workshop.registrationsCount;
    if (registrationsCount) {
      const freeText = `You have ${registrationsCount} ${
        registrationsCount === 1 ? `registered user` : `registered users`
      } on this event\nAre you sure you want to unpublish this event?`;
      const paidText = `You have ${registrationsCount} ${
        registrationsCount === 1 ? `registered user` : `registered users`
      } on this event\nAre you sure you want to unpublish this event?\nThis will issue refund to the buyers`;
      if (isPaid(workshop.paymentType)) {
        text = paidText;
        actionButton = 'refund';
      } else {
        text = freeText;
      }
    }
  }

  setDialog((current) => {
    return {
      show: true,
      title: `${action} - ${workshop.title}`,
      confirmationText: text,
      // contentTitle: `${workshop.title ? `"${workshop.title}"` : ''}`,
      action: actionButton,
      onCancel: current.onCancel,
      onConfirm: () => {
        setDialog((currentDeleteDialog) => {
          return {
            ...currentDeleteDialog,
            isLoading: true,
          };
        });
        let hasError;
        return updateVisibility(isPublished, workshop.id)
          .then(({ data: { status } }) => {
            successHandler(`workshop ${action}ed successfully`);
            updateWorkshopState(status);
            current.onCancel();

            if (
              workshopType === WORKSHOP_TYPE[WORKSHOP_TYPE_SELECTOR.live] ||
              workshopType === WORKSHOP_TYPE[WORKSHOP_TYPE_SELECTOR.mix]
            ) {
              if (isPublished) {
                setWorkshopCalendarConfirmation({
                  adminOrOwner,
                  workshopEventsRef,
                  setGoogleDialog,
                  setDialog,
                });
              } else {
                deleteBatchWorkshopEventFromGoogleCalendar(workshopEventsRef.current);
              }
            }
          })
          .catch((err) => {
            hasError = err.message;
            errorHandler(err);
            setDialog((currentState) => {
              return {
                ...currentState,
                isLoading: false,
              };
            });
          })
          .finally(() => {
            alPushContent({
              alEvent: AL_EVENT.WORKSHOP.createWorkshop,
              eventAction: action,
              eventStatus: hasError ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
              eventLabel: `${action} workshop`,
              content: workshop,
              workshopType: workshopType,
              error: hasError,
              isWorkshop: true,
            });
          });
      },
    };
  });
}

function deleteWorkshop(workshopId) {
  return workshopService.deleteWorkshop(workshopId);
}

function redirectOnDelete(navigate, userId) {
  navigate(getChannelBaseUrl(userId, true));
}

export function onDeleteWorkshopClick(setDialog, workshop, userId, navigate, redirect) {
  let actionButton = 'Delete';
  let text = `Are you sure you want to delete`;

  // user going to delete the workshop
  const registrationsCount = workshop.registrationsCount;
  if (registrationsCount) {
    const freeText = `You have ${registrationsCount} ${
      registrationsCount === 1 ? `registered user` : `registered users`
    } on this workshop\nAre you sure you want to delete this workshop?`;
    const paidText = `You have ${registrationsCount} ${
      registrationsCount === 1 ? `registered user` : `registered users`
    } on this workshop\nAre you sure you want to delete this workshop?\nThis will issue refund to the buyers`;
    if (isPaid(workshop.paymentType)) {
      text = paidText;
      actionButton = 'refund';
    } else {
      text = freeText;
    }
  }

  setDialog((current) => {
    return {
      show: true,
      title: `Delete - ${workshop.title}`,
      confirmationText: text,
      // contentTitle: `${workshop.title ? `"${workshop.title}"` : ''}`,
      action: actionButton,
      onCancel: current.onCancel,
      onConfirm: () => {
        setDialog((currentDeleteDialog) => {
          return {
            ...currentDeleteDialog,
            isLoading: true,
          };
        });
        deleteWorkshop(workshop.id)
          .then(() => {
            redirect && redirectOnDelete(navigate, userId);
          })
          .catch((err) => {
            errorHandler(err);
            setDialog((currentDeleteDialog) => {
              return {
                ...currentDeleteDialog,
                isLoading: false,
              };
            });
          });
      },
    };
  });
}

export function onWorkshopTogglePrivate(isPrivate, workshopId, updateWorkshopState) {
  return workshopService
    .updateWorkshop(workshopId, { isPrivate })
    .then(() => {
      updateWorkshopState(isPrivate);
    })
    .catch((err) => {
      errorHandler(err);
    });
}

export async function onDoneWorkshopDoneClick({
  navigate,
  edit,
  userId,
  workshop,
  workshopToSave,
}) {
  let error;

  try {
    const state = getStoreState();

    const updatedWorkshop = {
      ...workshopToSave,
      categories: workshopToSave.categories.map(({ value }) => value),
    };

    if (isPaid(workshop.paymentType)) {
      const { currency } = getUserSettings(state);
      updatedWorkshop.currency = currency;
    }
    const originalWorkshop = {
      ...workshop,
      categories: workshop.categories.map(({ value }) => value),
    };

    const { dispatch } = getStore();

    if (!isEqual(originalWorkshop, updatedWorkshop)) {
      await dispatch(updateWorkshop(workshop.id, updatedWorkshop));
      await dispatch(completeWorkshop(workshop.id));
    }

    if (!edit) {
      removeData(INCOMPLETE_WORKSHOP_KEY);
    }

    dispatch(resetWorkshopActions());
    successHandler(`Workshop ${edit ? 'update' : 'created'} successfully`);
    navigate(getWorkshopManageUrl(workshop.id));
  } catch ({ message }) {
    error = message;
    errorHandler(message);
  }

  if (!edit) {
    alPushContent({
      alEvent: AL_EVENT.WORKSHOP.createWorkshop,
      eventAction: AL_EVENT_ACTION.submit,
      eventStatus: error ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
      eventLabel: 'finished creating new workshop',
      content: workshop,
      userId,
      isWorkshop: true,
      error,
    });
  }
}
