import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from 'assets/Logo.png';

import { scale } from 'styles/common';

const LogoWrap = styled(Link)`
  height: 100%;
  width: 70px;
  //height: 31px;
  ${scale([
    ['768px', [['width', '79px']]],
    ['992px', [['width', '95px']]],
  ])}
  display: block;
  background: url('${logo}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

function Logo(props) {
  return <LogoWrap to="/" {...props} />;
}

Logo.propTypes = {
  to: PropTypes.string,
};

export default Logo;
