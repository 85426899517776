/* eslint no-unused-expressions: 0 */

import {createGlobalStyle} from 'styled-components';
import {theme} from './theme';
import {scale} from './common';
import {normalize} from 'polished';

import heeboThin from './fonts/Heebo-Thin.ttf';
import heeboRegular from './fonts/Heebo-Regular.ttf';
import heeboMedium from './fonts/Heebo-Medium.ttf';
import heeboLight from './fonts/Heebo-Light.ttf';
import heeboExtraBold from './fonts/Heebo-ExtraBold.ttf';
import heeboBold from './fonts/Heebo-Bold.ttf';
import BebasNeue from './fonts/BebasNeue-Regular.ttf';
import BebasNeueOtf from './fonts/BebasNeue-Regular.otf';
import JosefinSans from './fonts/JosefinSans-Regular.ttf';

export default createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: 'Bebas Neue';
    src: url(${BebasNeue}) format("truetype"),
    url(${BebasNeueOtf}) format("truetype")    ;
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Josefin Sans';
    src: url(${JosefinSans}) format("truetype"),
    url(${JosefinSans}) format("truetype")    ;
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'heebo';
    src: url(${heeboThin}) format("truetype");
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'heebo';
    src: url(${heeboThin}) format("truetype");
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'heebo';
    src: url(${heeboRegular}) format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'heebo';
    src: url(${heeboMedium}) format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'heebo';
    src: url(${heeboLight}) format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'heebo';
    src: url(${heeboExtraBold}) format("truetype");
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'heebo';
    src: url(${heeboBold}) format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  body, html, #root {
    height: 100%;
    text-align: inherit;
    min-width: auto !important;
  }

  body {
    ${scale([
      ['default', [['font-size', theme.typo.sizes.tiny + ' !important']]],
      ['560px', [['font-size', theme.typo.sizes.base + ' !important']]],
      ['768px', [['font-size', theme.typo.sizes.small + ' !important']]],
      ['992px', [['font-size', theme.typo.sizes.body + ' !important']]],
      ['1400px', [['font-size', theme.typo.sizes.body2 + ' !important']]]
    ])}
    background-color: #FAFAFA !important;

    //todo: remove this when we don't need to override zoom style
    font-family: "Segoe UI", sans-serif, Arial !important;
  }

  #modal-body-inner {
    padding: 10px 20px 15px !important;
  }

  //todo: all zoom style override, can be remove in the future
  input::selection {
    background: #a8d1ff !important;
  }

  input:focus {
    outline: none !important;
    transition: none !important;
  }

  input[type=text] {
    outline: none !important;
    transition: none !important;
  }

  div > input[type=text] {
    outline: none !important;
    transition: none !important;
  }

  div > input[type=text]:focus {
    outline: none !important;
    transition: none !important;
  }

  input[type=text]:focus {
    outline: none !important;
    transition: none !important;
  }

  input[type=text]::selection {
    background: #a8d1ff !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #flow {
    display: none;
  }

  html {
    font-family: 'heebo';

    ${scale([
      ['default', [['font-size', theme.typo.sizes.tiny + ' !important']]],
      ['560px', [['font-size', theme.typo.sizes.base + ' !important']]],
      ['768px', [['font-size', theme.typo.sizes.small + ' !important']]],
      ['992px', [['font-size', theme.typo.sizes.body + ' !important']]],
      ['1400px', [['font-size', theme.typo.sizes.body2 + ' !important']]]
    ])}

  }

  a, a:hover, a:focus, a, a:visited { //zoom override style
    text-decoration: none !important;
  }
`;