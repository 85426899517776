import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { scale } from 'styles/common';
import { appName } from 'config/config';

import MetaDecorator from 'components/MetaDecorator';
import styled from 'styled-components';
import Tabs from 'components/Tabs';
import Login from 'assets/eventCreation/login.png';
import EventCreation1 from 'assets/eventCreation/event-creation-1.png';
import EventCreation2 from 'assets/eventCreation/event-creation-2.png';
import EventCreation3 from 'assets/eventCreation/event-creation-3.png';
import EventCreationZoom from 'assets/eventCreation/event-creation-3-zoom.png';
import EventCreationYouTube1 from 'assets/eventCreation/event-creation-3-youtube.png';
import EventCreationYouTube2 from 'assets/eventCreation/event-creation-3-youtube-2.png';
import EventCreationFacebook from 'assets/eventCreation/event-creation-3-facebook.png';

const PageWrap = styled.div`
  padding: 1em 2em;
`;

const ColWrap = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const Header = styled.div`
  font-weight: 500;
  color: ${({ theme: { colors } }) => colors.dark};
  letter-spacing: 0.03em;
  font-size: ${({ theme: { typo } }) => typo.sizes.h4};
`;

const Border = styled.div`
  border-bottom: 3px solid ${({ theme: { colors } }) => colors.purple};
  width: 19em;
  margin-top: 1em;
`;

const SectionWrap = styled.div`
  margin-top: 2em;
`;

const TabWrap = styled(Tabs)`
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  //margin-top: 2em;
  margin: 2em auto;
  //padding: 2em 0em;
  width: 100%;
  ${scale([
    ['992px', [['width', '90%']]],
    ['1600px', [['width', '90%']]],
  ])}
  color: ${({ theme: { colors } }) => colors.darkGrey};

  > * {
    margin-top: 3em;
    border-top: 3px solid ${({ theme: { colors } }) => colors.lightGrey};
    padding: 2em 0;
  }
`;

const InstructionTitle = styled.div`
  color: ${({ theme: { colors } }) => colors.black};
  font-size: 3rem;
  font-weight: bold;
`;

const InstructionDescription = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
`;

const Note = styled.div`
  font-size: 1rem;
  font-weight: 400;
`;

const InstructionImage = styled.img`
  font-size: 1.5rem;
  font-weight: 500;
  max-width: 100%;
  max-height: 500px;
`;

const LinkWrap = styled.span`
  cursor: pointer;
  text-decoration: underline solid blue;
`;

function HowToCreateAnEvent() {
  function onTabSelect(tabSelected) {
    window.location.href = `${window.location.origin}/help/how-to-create-an-event/#${tabSelected}`;
  }

  return (
    <>
      <MetaDecorator
        url={`${window.location.origin}/help/how-to-create-an-event`}
        title={'How To Create An Event'}
        titleSuffix={''}
        description={`here you can learn how to create an event`}
      ></MetaDecorator>

      <PageWrap>
        <Header>How To Create An Event</Header>

        <Border />

        <SectionWrap>
          <TabWrap
            noMinimize={false}
            onTabSelect={onTabSelect}
            isDraggable={false}
            tabs={[
              {
                label: 'CREATION OF THE EVENT',
                name: 'creation-of-the-event',
              },
              {
                label: 'CONNECT WITH ZOOM',
                name: 'primusConnect-with-zoom',
              },
              {
                label: 'CONNECT WITH YOUTUBE LIVE',
                name: 'primusConnect-with-youtube-live',
              },
              {
                label: 'CONNECT WITH FACEBOOK LIVE',
                name: 'primusConnect-with-facebook-live',
              },
            ]}
          />
          <Container>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle>
                  Login or
                  {/*<br/>*/}
                  {/*or*/}
                  <br />
                  Sign Up
                </InstructionTitle>
                <InstructionDescription>
                  By clicking on Sign in, you will be able to login or create an account.
                </InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={Login} />
              </ColWrap>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle id={'creation-of-the-event'}>Create an Event</InstructionTitle>
                <InstructionDescription>Click on the Add event</InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={EventCreation1} />
              </ColWrap>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle>Fill The Details</InstructionTitle>
                <InstructionDescription>
                  Complete all the information corresponding to the event: cover, title, category to
                  which it belongs, payment type, summary.
                </InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={EventCreation2} />
              </ColWrap>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle id={'set-stream-link'}>Set Stream Link</InstructionTitle>
                <InstructionDescription>
                  Add dates for the event,
                  <br />
                  Place the stream link from Zoom, Facebook, YouTube and click Create Event.
                  <br />
                  Click on Publish.
                  <Note>See instructions bellow on how to find the stream link.</Note>
                </InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={EventCreation3} />
              </ColWrap>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle id={'primusConnect-with-zoom'}>
                  Set Zoom Stream Link
                </InstructionTitle>
                <InstructionDescription>
                  {`Open zoom click on schedule a meeting fill in the details same as on ${appName},`}{' '}
                  <br />
                  click on show meeting information, and copy the zoom link,
                  <br />
                  Paste it in the event in the &nbsp;
                  <LinkWrap
                    onClick={() => {
                      window.location.href = `${window.location.origin}/help/how-to-create-an-event/#set-stream-link`;
                    }}
                  >
                    {'Stream Link'}
                  </LinkWrap>
                  &nbsp; field.
                </InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={EventCreationZoom} />
              </ColWrap>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle id={'primusConnect-with-youtube-live'}>
                  Set YouTube Stream Link | Step 1
                </InstructionTitle>
                <InstructionDescription>
                  Go to YouTube click on Create -> Go live
                  <br />
                  Click on Webcam or if you are using streaming software
                  <br />
                  Click on Manage -> SCHEDULE STREAM
                  <br />
                  {`Fill in the details to match on ${appName}.`}
                </InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={EventCreationYouTube1} />
              </ColWrap>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle>Set YouTube Stream Link | Step 2</InstructionTitle>
                <InstructionDescription>
                  Click on Share
                  <br />
                  Click on Copy video link.
                  <br />
                  Paste it in the event in the &nbsp;
                  <LinkWrap
                    onClick={() => {
                      window.location.href = `${window.location.origin}/help/how-to-create-an-event/#set-stream-link`;
                    }}
                  >
                    {'Stream Link'}
                  </LinkWrap>
                  &nbsp; field.{' '}
                </InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={EventCreationYouTube2} />
              </ColWrap>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={6}>
                <InstructionTitle id={'primusConnect-with-facebook-live'}>
                  Set Facebook Stream Link
                </InstructionTitle>
                <InstructionDescription>
                  Create Event on facebook
                  <br />
                  {`Fill in the details to match on ${appName}`},
                  <br />
                  Click on 3 dots menu and on the stream link.
                  <br />
                  Paste it in the event in the &nbsp;
                  <LinkWrap
                    onClick={() => {
                      window.location.href = `${window.location.origin}/help/how-to-create-an-event/#set-stream-link`;
                    }}
                  >
                    {'Stream Link'}
                  </LinkWrap>
                  &nbsp; field.
                </InstructionDescription>
              </Col>
              <ColWrap xs={12} md={12} lg={6}>
                <InstructionImage src={EventCreationFacebook} />
              </ColWrap>
            </Row>
          </Container>
        </SectionWrap>
      </PageWrap>
    </>
  );
}

HowToCreateAnEvent.propTypes = {
  location: PropTypes.object,
};

export default HowToCreateAnEvent;
