import React from 'react';

import PropTypes from 'prop-types';
import {Elements} from '@stripe/react-stripe-js';
import Payment from "./Payment";
import {loadStripe} from "@stripe/stripe-js";
import {STRIPE_PUBLIC_KEY} from "config/config";

const promise = loadStripe(STRIPE_PUBLIC_KEY);

function PaymentIndex(
  {
    eventId,
    occurrenceId,
    workshopId,
    ...props
  }
) {
  // const [clientSecret, setClientSecret] = useState(null);


  // useEffect(() => {
  //   const results = stripeService.paymentCreateIntent({
  //     eventId,
  //     workshopId,
  //     occurrenceId,
  //     savePaymentMethod: true
  //   }).then(results => {
  //     const {data: {clientSecret, transactionId: transactionIdRes}} = results;
  //     console.log('clientSecret', clientSecret)
  //     setClientSecret(clientSecret)
  //   })
  // }, [])


  // const options = {
  //   clientSecret,
  // };

  return (
    <>
      {
        // promise && clientSecret &&
        <Elements stripe={promise}>
          {/*<Elements stripe={promise} options={options}>*/}
          <Payment
            eventId={eventId}
            occurrenceId={occurrenceId}
            workshopId={workshopId}
            {...props}
          />
        </Elements>
      }
    </>
  );
}

PaymentIndex.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  stripeCustomerId: PropTypes.string,
  stripeCustomerDPM: PropTypes.string,
  userId: PropTypes.number,
  email: PropTypes.string,
  fullName: PropTypes.string,
  mobilePhoneNumber: PropTypes.string,
  eventId: PropTypes.number,
  workshopId: PropTypes.number,
  occurrenceId: PropTypes.number,
  updateUserDpm: PropTypes.func,
  deletePaymentMethod: PropTypes.func,
  content: PropTypes.object,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  refundLabel: PropTypes.string,
};

export default (React.memo(PaymentIndex));