import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {transparentize} from 'polished';

import theme from 'styles/theme';
import {scale} from 'styles/common';

import Icon from 'components/Icon';

const buttonGeneral = css`
  border-bottom: 1px solid ${({theme: {colors}}) => colors.black};
  color: ${({theme: {colors}}) => colors.darkGrey};
  background-color: ${({theme: {colors}}) => colors.paleGrey};
  box-shadow: inset 0px -2px 4px 0px ${({theme: {colors}}) => colors.lightGrey};
`;

const buttonNotify = css`
    //border-bottom: 1px solid ${({theme: {colors}}) => colors.notify};
  color: ${({theme: {colors}}) => colors.darkGrey};
  background-color: rgb(112 112 112 / 10%);
  box-shadow: inset 0px -2px 4px 0px #c1c1c1;
`;

const buttonSuccess = css`
  border-bottom: 1px solid ${({theme: {colors}}) => colors.success};
  color: ${({theme: {colors}}) => colors.darkGrey};
  background-color: ${({theme: {colors}}) => transparentize(0.9, colors.success)};
  box-shadow: inset 0px -2px 4px 0px ${({theme: {colors}}) => colors.paleSuccess};
`;

const buttonError = css`
  border-bottom: 1px solid ${({theme: {colors}}) => colors.error};
  color: ${({theme: {colors}}) => colors.darkGrey};
  background-color: ${({theme: {colors}}) => transparentize(0.9, colors.error)};
  box-shadow: inset 0px -2px 4px 0px ${({theme: {colors}}) => colors.paleError};
`;

const buttonWarning = css`
  border-bottom: 1px solid ${({theme: {colors}}) => colors.warning};
  color: ${({theme: {colors}}) => colors.darkGrey};
  background-color: ${({theme: {colors}}) => transparentize(0.9, colors.warning)};
  box-shadow: inset 0px -2px 4px 0px ${({theme: {colors}}) => colors.paleWarning};
`;

const barWrapStyles = {
  general: buttonGeneral,
  notify: buttonNotify,
  success: buttonSuccess,
  error: buttonError,
  warning: buttonWarning
};

const BarItemWrap = styled.div`
  display: flex;
  padding: 10px ${({theme: {padding}}) => padding.pageM}px;
  ${scale([['768px', [['padding', `10px ${theme.padding.pageD}px`]]]])}
  align-items: center;
  //font-weight: bold;

  ${({type}) => barWrapStyles[type]};
`;

const BarItemText = styled.div`
  flex-grow: 1;
  padding: 0 ${({theme: {padding}}) => padding.pageM}px;
  ${scale([['768px', [['padding', `0 ${theme.padding.pageD}px`]]]])}
`;

const BarItemIcon = styled(Icon)`
  cursor: pointer;
`;

function BarItem({type, onRemove, noClose, children}) {
  return (
    <BarItemWrap type={type}>
      <BarItemIcon
        type={type}
        iconName="fas fa-exclamation-circle"
        onClick={onRemove}
      />
      <BarItemText type={type}>{children}</BarItemText>
      {
        !noClose &&
        (
          <BarItemIcon
            type={type}
            iconName="fa fa-times"
            onClick={onRemove}
          />
        )
      }
    </BarItemWrap>
  );
}

BarItem.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  noClose: PropTypes.bool,
  onRemove: PropTypes.func
};

export default BarItem;