import React, { Suspense, lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDecodedRedirectPath } from 'utilities/general';

import { getAuthIsLoggedIn } from '../../../../reduxUtils/auth/selectors';
import {
  getUserEmail,
  getUserId,
  getUserStripeAccountId,
  getUserStripeAccountStatus,
} from '../../../../reduxUtils/user/selectors';
import { getUserAccessControl } from '../../../../reduxUtils/accessControl/selectors';
import { getBrowserIsMobile } from '../../../../reduxUtils/browserSettings/selectors';
import {
  getUserFullName,
  getUserAvatarThumbnail,
} from '../../../../reduxUtils/userProfile/selectors';
import { getWorkshop } from '../../../../reduxUtils/workshop/selectors';
import {
  getWorkshopEager,
  startWorkshop,
  resetWorkshopActions,
} from '../../../../reduxUtils/workshop/thunks';

import WithSideBar from 'layouts/WithSideBar';
import MetaDecorator from 'components/MetaDecorator';

import Fallback from 'components/Fallback';
import { AL_EVENT, AL_EVENT_ACTION, AL_EVENT_STATUS, alPushContent } from 'utilities/analytics';
import { getIsChatDetach, getIsChatMinimized } from '../../../../reduxUtils/chat/selectors';
import { getUserSettings } from '../../../../reduxUtils/userSettings/selectors';

const New = lazy(() => import('./New'));
let accessControl = [];

function WorkshopNew({
  navigate,
  match,
  location,
  isMobile,
  isLoggedIn,
  userId,
  fullName,
  avatar,
  workshop,
  accessControl,
  userSettings,
  getWorkshopEager,
  resetWorkshopActions,
  startWorkshop,
  stripeAccountId,
  stripeAccountStatus,
  isChatDetach,
  isChatMinimized,
}) {
  const [redirectTo, setRedirectTo] = useState('');
  const [isLeftBarOpened, setIsLeftBarOpened] = useState(true);
  const [menuOptions, setMenuOptions] = useState([
    {
      label: 'Home',
      iconName: 'fas fa-home',
      linkTo: '/',
    },
    {
      label: 'Details',
      iconName: 'fas fa-info-circle',
      linkTo: `workshop-details`,
    },
    // {
    //   label: 'Settings',
    //   iconName: 'fas fa-cog',
    //   linkTo: `workshop-settings`,
    //   isDisabled: true
    // }
  ]);

  useEffect(() => {
    const firstRedirectTo = getDecodedRedirectPath();

    if (firstRedirectTo) {
      setRedirectTo(firstRedirectTo);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    alPushContent({
      alEvent: AL_EVENT.WORKSHOP.createWorkshop,
      eventAction: AL_EVENT_ACTION.start,
      eventStatus: AL_EVENT_STATUS.success,
      eventLabel: 'creating new workshop',
      eventCategory: 'workshop',
      userId: userId,
      isWorkshop: true,
    });
  }, []); // eslint-disable-line

  function OnIsLeftBarChange() {
    setIsLeftBarOpened(!isLeftBarOpened);
  }

  return (
    <>
      <MetaDecorator
        title={'Create new workshop'}
        description={"Create new workshop free or paid it's up to you"}
      >
        <meta name="robots" content="noodp" />
      </MetaDecorator>
      <WithSideBar
        location={location}
        options={menuOptions}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        setLocalIsLeftBarOpened={OnIsLeftBarChange}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <Suspense fallback={<Fallback />}>
          <New
            navigate={navigate}
            match={match}
            userId={userId}
            workshop={workshop}
            accessControl={accessControl}
            userSettings={userSettings}
            getWorkshopEager={getWorkshopEager}
            resetWorkshopActions={resetWorkshopActions}
            startWorkshop={startWorkshop}
            redirectTo={redirectTo}
            setMenuOptions={setMenuOptions}
            stripeAccountId={stripeAccountId}
            stripeAccountStatus={stripeAccountStatus}
          />
        </Suspense>
      </WithSideBar>
    </>
  );
}

WorkshopNew.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  isLeftBarOpened: PropTypes.bool,
  workshop: PropTypes.object,
  accessControl: PropTypes.array,
  userSettings: PropTypes.object,
  getWorkshopEager: PropTypes.func,
  resetWorkshopActions: PropTypes.func,
  startWorkshop: PropTypes.func,
  email: PropTypes.string,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  const userId = getUserId(state);
  if (userId) {
    accessControl = getUserAccessControl(state, userId);
  }
  return {
    userId,
    accessControl,
    userSettings: getUserSettings(state),
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    workshop: getWorkshop(state),
    email: getUserEmail(state),
    stripeAccountId: getUserStripeAccountId(state),
    stripeAccountStatus: getUserStripeAccountStatus(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getWorkshopEager,
      resetWorkshopActions,
      startWorkshop,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopNew);
