import React, { useEffect, useRef } from 'react';

import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useState } from 'react';
import NewWindow from 'react-new-window';
import ChatBody from 'components/Chat/components/ChatBody';
import { connect } from 'react-redux';
import {
  hideChatDetachAction,
  setChatDetachAction,
  toggleChatMinimizeAction,
} from '../../reduxUtils/chat/actions';
import { StyleSheetManager } from 'styled-components';
import { getStoreState } from 'utilities/store';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';
import { errorHandler } from 'utilities/services';
import { CHAT_ROOMS, CHAT_ROOMS_LABELS, getRoomStatus, parseChatRooms } from 'utilities/chat.utils';
import { getChatInstance } from 'utilities/chat';
import { getSocketConnectedAction } from '../../reduxUtils/socket/selectors';

const chat = getChatInstance();
const CHAT_DISCONNECT_TIME = 5000; //0 sec
// const CHAT_DISCONNECT_TIME = 30 * 1000; //30 sec
let disconnectTimer;
let prevIsLoggedIn = null;

function Chat({
  allowChat,
  isAllowedToEventRoom,
  isLive,
  userId,
  fullName,
  avatar,
  channelId,
  channelAvatar,
  channelFullName,
  eventId,
  occurrenceId,
  isWorkshopEvent,
  workshopId,
  isLoggedIn,
  isChatDetach,
  toggleChatMinimizeAction,
  setChatDetachAction,
  hideChatDetachAction,
  isAdmin,
  isPublished = true,
  isSocketConnected,
}) {
  const [selectedRoom, setSelectedRoom] = useState(() => chat.getCurrentRoom());
  const [chatRoomsLabels, setChatRoomsLabels] = useState(chat.getChatRoomsLabels());
  const [isConnecting, setIsConnecting] = useState(chat.getCurrentRoom().isConnecting);

  const [usersRoom, setUsersRoom] = useState(chat.getCurrentUsersRoom());
  const [usersCount, setUsersCount] = useState();
  const [messageList, setMessageList] = useState([]);
  const newWindowNodeRef = useRef(null);

  function onRoomClick(e) {
    if (!e.isPublished) {
      errorHandler('content must be publish for chat to be available');
      return false;
    }

    if (e.isLoading) {
      return false;
    }

    if (e.disabled) {
      errorHandler('only attendees are allowed in');
      return false;
    }

    if (selectedRoom.key === e.key) {
      if (usersRoom.visible) {
        const usersRoom = chat.getCurrentUsersRoom();
        usersRoom.visible = false;
        setUsersRoom({ ...usersRoom });
      }

      if (selectedRoom.isConnected && selectedRoom.room === e.room) {
        return false;
      }
    }

    const thisRoomLabel = chat.getChatRoomsLabels()[e.key];
    const thisRoom = chat.getChatRooms()[e.key];

    if (thisRoom.isConnecting) {
      setIsConnecting(true);
      console.log(`already ** connecting ** to this room`);
      return false;
    }

    if (e.key === CHAT_ROOMS_LABELS.POLLS.key) {
      chat
        .joinPollRoom(e)
        .then((res) => {
          setSelectedRoom(res);
        })
        .catch((err) => {
          errorHandler(err);
          thisRoom.isConnecting = false;
          thisRoomLabel.isConnecting = false;
          setIsConnecting(false);
          setChatRoomsLabels({ ...chat.getChatRoomsLabels() });
        });
    } else {
      chat
        .joinRoom(e)
        .then((res) => {
          setSelectedRoom(res);

          if (thisRoomLabel !== CHAT_ROOMS_LABELS.LOBBY.key) {
            const currentUsersRoom = chat.getCurrentUsersRoom();

            if (e.room !== currentUsersRoom.room || !currentUsersRoom.isConnected) {
              if (currentUsersRoom.visible || currentUsersRoom.isConnecting) {
                chat.joinUsersRoom(chat.getCurrentRoom());
              } else {
                chat.leaveUsersRoom(false);
              }
            }
          }
        })
        .catch((err) => {
          errorHandler(err);
          thisRoom.isConnecting = false;
          thisRoomLabel.isConnecting = false;
          setIsConnecting(false);
          setChatRoomsLabels({ ...chat.getChatRoomsLabels() });
        });
    }
  }

  function joinUsersRoom(room) {
    if (chat.getCurrentUsersRoom().isConnecting) {
      console.log(`already trying to connect to users room`);
      return false;
    }

    chat.joinUsersRoom(room);
  }

  function onClickUserCount() {
    if (!usersRoom.visible) {
      const usersRoom = chat.getCurrentRoom();
      joinUsersRoom(usersRoom);
    } else {
      const currentUsersRoom = chat.getCurrentUsersRoom();
      currentUsersRoom.visible = false;
      setUsersRoom({ ...currentUsersRoom });
    }
  }

  function onClickChatDetach() {
    setChatDetachAction({
      userId,
      fullName,
      avatar,
      channelId,
      channelAvatar,
      channelFullName,
    });
  }

  useEffect(() => {
    chat.init(userId, fullName, avatar, channelId, eventId, occurrenceId, workshopId);
  }, [userId, fullName, avatar, channelId, eventId, occurrenceId, workshopId]);

  useEffect(() => {
    chat.updateSetUsersRoom(setUsersRoom);
    chat.updateSetMessageList(setMessageList);
    chat.updateSetUsersCount(setUsersCount);
    chat.updateSetChatRoomsLabels(setChatRoomsLabels);
    chat.updateSetSelectedRoom(setSelectedRoom);
    chat.updateSetIsConnecting(setIsConnecting);
  }, []);

  useEffect(() => {
    if (!isChatDetach) {
      //still fetching user data
      if ((isLoggedIn && !userId) || (!isLoggedIn && userId)) {
        return;
      }

      const parsedChatRooms = parseChatRooms({
        isLoggedIn,
        isPublished,
        isLive,
        isAllowedToEventRoom,
        channelId,
        eventId,
        occurrenceId,
        workshopId,
      });

      chat.initChatRoomsLabels(parsedChatRooms);
      chat.updateSetChatRoomsLabels(setChatRoomsLabels);

      const chatRooms = chat.getChatRooms();
      const { roomsToJoin, roomsToLeave } = getRoomStatus(parsedChatRooms, chatRooms);

      let currentRoom = chat.getCurrentRoom();
      let roomToJoin = parsedChatRooms[currentRoom.key];

      if (
        !currentRoom.room ||
        (roomsToLeave[currentRoom.key]?.key === currentRoom.key &&
          roomsToJoin[currentRoom.key]?.key !== currentRoom.key)
      ) {
        roomToJoin = parsedChatRooms.LOBBY;
      }

      let usersHasLoggedOut = false;
      if (prevIsLoggedIn === null) {
        prevIsLoggedIn = isLoggedIn;
      } else {
        if (prevIsLoggedIn !== isLoggedIn) {
          if (!isLoggedIn) {
            prevIsLoggedIn = isLoggedIn;

            usersHasLoggedOut = true;

            //if the user currently viewing the event users room need to move to lobby users room
            //server already disconnected from users room
            const currentUsersRoom = chat.getCurrentUsersRoom();
            if (currentUsersRoom.key === CHAT_ROOMS.EVENT.key && currentUsersRoom.visible) {
              chat.leaveUsersRoom(true);
              chat.joinUsersRoom(parsedChatRooms.LOBBY);
            }
            chat.socketLogOut();
            chat.initChatRoomsLabels(parsedChatRooms);
            chat.updateSetChatRoomsLabels(setChatRoomsLabels);
          } else {
            if (!fullName) {
              //login happens but user data is not yet initialized
              return;
            }
            prevIsLoggedIn = isLoggedIn;
            chat.reconnectToRooms();
            chat.initChatRoomsLabels(parsedChatRooms);
            chat.updateSetChatRoomsLabels(setChatRoomsLabels);
            return;
          }
        }
      }

      if (!isAllowedToEventRoom || usersHasLoggedOut) {
        if (currentRoom.key === CHAT_ROOMS.EVENT.key) {
          chat.leaveRoom(currentRoom);
          roomToJoin = parsedChatRooms.LOBBY;
        }
      }
      chat.leaveRooms(roomsToLeave);

      const thisRoom = chatRooms[roomToJoin.key];
      if (!roomToJoin.disabled) {
        if (thisRoom.isConnecting) {
          setIsConnecting(true);
        }
        chat
          .joinRoom(roomToJoin)
          .then((res) => {
            setSelectedRoom({ ...res });
          })
          .catch((err) => {
            setIsConnecting(false);
            errorHandler(err);
          });
      }
    }
  }, [
    isChatDetach,
    channelId,
    eventId,
    occurrenceId,
    workshopId,
    isAllowedToEventRoom,
    isLoggedIn,
    isPublished,
    avatar,
    fullName,
    isLive,
    userId,
  ]);

  useEffect(() => {
    if (disconnectTimer) {
      clearTimeout(disconnectTimer);
      disconnectTimer = null;
    }
    return () => {
      const state = getStoreState();
      const isChatMinimized = getIsChatMinimized(state);
      const isChatDetach = getIsChatDetach(state);
      if (!isChatDetach && !isChatMinimized) {
        disconnectTimer = setTimeout(() => {
          chat.leaveRooms(chat.getChatRooms());
          chat.leaveUsersRoom();
        }, CHAT_DISCONNECT_TIME);
      }
    };
  }, []);

  const sendMessage = (message) => {
    chat.sendMessage(message);
  };

  return (
    <>
      {!isChatDetach ? (
        <ChatBody
          isAdmin={isAdmin}
          userId={userId}
          channelId={channelId}
          eventId={eventId}
          occurrenceId={occurrenceId}
          workshopId={workshopId}
          chatBodyRef={newWindowNodeRef}
          messageList={messageList}
          onClickChatDetach={onClickChatDetach}
          onClickUserCount={onClickUserCount}
          usersCount={usersCount}
          sendMessage={sendMessage}
          userName={fullName}
          avatar={avatar}
          selectedRoom={selectedRoom}
          onRoomClick={onRoomClick}
          chatRoomsLabels={chatRoomsLabels}
          isLoggedIn={isLoggedIn}
          toggleChatMinimizeAction={toggleChatMinimizeAction}
          hideChatDetachAction={hideChatDetachAction}
          isChatDetach={isChatDetach}
          isSocketConnected={isSocketConnected}
          usersRoom={usersRoom}
          isConnecting={isConnecting}
        />
      ) : (
        <StyleSheetManager target={newWindowNodeRef.current}>
          <NewWindow
            title={'Chat'}
            features={{ height: 650, width: 400 }}
            closeOnUnmount={true}
            copyStyles={true}
            onUnload={() => {
              const state = getStoreState();
              const mini = getIsChatMinimized(state);
              !mini && hideChatDetachAction();
            }}
          >
            <>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              />
              <meta charSet="utf-8" />
              <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />

              <ChatBody
                isAdmin={isAdmin}
                userId={userId}
                channelId={channelId}
                eventId={eventId}
                occurrenceId={occurrenceId}
                workshopId={workshopId}
                chatBodyRef={newWindowNodeRef}
                messageList={messageList}
                onClickChatDetach={onClickChatDetach}
                onClickUserCount={onClickUserCount}
                usersCount={usersCount}
                sendMessage={sendMessage}
                userName={fullName}
                avatar={avatar}
                selectedRoom={selectedRoom}
                onRoomClick={onRoomClick}
                chatRoomsLabels={chatRoomsLabels}
                isLoggedIn={isLoggedIn}
                toggleChatMinimizeAction={toggleChatMinimizeAction}
                hideChatDetachAction={hideChatDetachAction}
                isChatDetach={isChatDetach}
                isSocketConnected={isSocketConnected}
                usersRoom={usersRoom}
                isConnecting={isConnecting}
              />
            </>
          </NewWindow>
        </StyleSheetManager>
      )}
    </>
  );
}

Chat.propTypes = {
  userId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    isSocketConnected: getSocketConnectedAction(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleChatMinimizeAction,
      setChatDetachAction,
      hideChatDetachAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Chat));
