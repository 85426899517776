import React, {useState} from 'react';
//  import PropTypes from 'prop-types';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getSearch} from '../../../../../reduxUtils/search/selectors';

import Categories from '../Categories';
import TextInput from 'components/Inputs/TextInput';
import Spinner from 'components/Spinner';

import {StyledTextInputCss} from 'styles/common';

const SearchBarWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${StyledTextInputCss};
  border-radius: 50px;
`;

const SearchTextInputWrap = styled(TextInput)`
  margin: 0 15px;
  color: ${({theme: {colors}}) => colors.darkGrey};
`;

const SearchIconWrap = styled.div`
  margin-right: 15px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchInputIcon = styled.i`
  color: ${({theme: {colors}}) => colors.darkGrey};
`;

function SearchBar({search, onUpdateSearch}) {
  const [isLoading/*, setIsLoading*/] = useState(false);
  const {
    predicate,
    category
  } = search;

  return (
    <SearchBarWrap>
      <Categories
        category={category}
        updateCategory={(value) => onUpdateSearch({target: {name: 'category', value}})}
      />
      <SearchTextInputWrap
        disabled
        value={predicate}
        placeholder='coming soon...'
        // placeholder="Search for"
        name='predicate'
        onChange={onUpdateSearch}
      />
      <SearchIconWrap>
        {
          isLoading
            ? <Spinner />
            : <SearchInputIcon className='fas fa-search' />
        }
      </SearchIconWrap>
    </SearchBarWrap>
  );
}

SearchBar.propTypes = {
  location: PropTypes.object,
  search: PropTypes.object,
  onUpdateSearch: PropTypes.func
};

function mapStateToProps(state) {
  return {
    search: getSearch(state)
  };
}

export default connect(mapStateToProps, null)(SearchBar);
