import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_HOME_UPCOMING_WORKSHOPS,
  ADD_HOME_UPCOMING_WORKSHOP,
  ADD_HOME_UPCOMING_WORKSHOPS,
  //UPDATE_HOME_UPCOMING_WORKSHOP,
  DELETE_HOME_UPCOMING_WORKSHOP, UPDATE_HOME_UPCOMING_WORKSHOP
} from './types';
import {LOGOUT} from "../auth/types";
import {reduxUpdateWorkshopsHelper} from "../helpers/workshops";

const defaultUpcomingWorkshopFields = [
  'id',
  'isEvent',// = true,
  'paymentType',
  'title',// = 'Some Title',
  'summary',//
  'coverPhotoDetails',
  'coverPhoto',// = logo,
  'coverPhotoHomePage',
  'price',// ='$37',
  'currency',
  'rating',//
  'ratingCount',
  'date',//
  'endDate',
  'duration', // = '5 Hours',
  'user', // = {id, fullName, avatar, avatarThumbnail, avatarHomePage, rating, ratingCount, followersCount}
  'UserId',// = 17,'
  'eventsCount',
  'eventsInfo',
  'status',
  'categories'
];

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

export default handleActions({
  [SET_HOME_UPCOMING_WORKSHOPS]: (state, {payload}) => {
    return {
      ...state,
      ...payload,
      rows: payload.rows.map((workshop) => pick(workshop, defaultUpcomingWorkshopFields))
    };
  },

  [ADD_HOME_UPCOMING_WORKSHOP]: (state, {payload}) => {
    const newUpcomingWorkshop = pick(payload, defaultUpcomingWorkshopFields);

    const foundIndex = state.rows.findIndex((workshop) => workshop.id === newUpcomingWorkshop.id);
    if (foundIndex === -1) {
      const workshops = [...state.rows];
      workshops.push(newUpcomingWorkshop);
      return {
        ...state,
        totalRows: state.totalRows + 1,
        rows: workshops
      };
    }

    return state;
  },

  [ADD_HOME_UPCOMING_WORKSHOPS]: (state, {payload}) => {
    const workshops = [...state.rows];
    const newUpcomingWorkshops = (payload.rows || [])
      .filter((newUpcomingWorkshop) => {
        return workshops.findIndex((workshop) => workshop.id === newUpcomingWorkshop.id) === -1;
      })
      .map((newUpcomingWorkshop) => pick(newUpcomingWorkshop, defaultUpcomingWorkshopFields));
    return {
      ...state,
      ...payload,
      rows: [...workshops, ...newUpcomingWorkshops]
    };
  },

  [UPDATE_HOME_UPCOMING_WORKSHOP]: (state, {payload}) => {
    return reduxUpdateWorkshopsHelper(state, payload);
  },

  [DELETE_HOME_UPCOMING_WORKSHOP]: (state, {payload}) => {
    const workshopId = payload;

    const foundIndex = state.rows.findIndex((occurrence) => occurrence.id === workshopId);
    if (foundIndex > -1) {
      const workshops = [...state];
      workshops.splice(foundIndex, 1);
      return {
        ...state,
        totalRows: state.totalRows - 1,
        rows: workshops
      };
    }

    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }

}, {...initialState});