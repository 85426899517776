import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { UserSelectNoneCss } from 'styles/common';
import CoverPhoto from 'components/CoverPhoto';
import { ProgressBar } from 'react-bootstrap';

export const CoverPhotoContainerWrap = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;
  position: relative;

  .component-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const CoverPhotoContainer = styled.div`
  display: inline-block;
  position: relative;
  overflow: inherit;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const CoverPhotoDummy = styled.div`
  border-radius: inherit;
  margin-top: ${({ ratio }) => ratio || '100%'};
`;

const CoverPhotoImage = styled(CoverPhoto)`
  border-radius: inherit;
  cursor: pointer;
`;

const DetailsCoverPhotoImageUpload = styled.div`
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  ${UserSelectNoneCss};
`;

const StyledFileUpload = styled.input`
  display: none;
`;

const ImageProgressBar = styled(ProgressBar)`
  //todo: remove zoom restyle important
  height: 3px !important;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
`;

function UploadImage({
  className,
  name,
  ImagePlacement,
  showProgress,
  progress,
  borderRadius,
  ratio,
  maxWidth,
  acceptImages,
  initialImg,
  update,
  clearImageRef,
  children,
  ...props
}) {
  const [coverPhotoBase64Url, setCoverPhotoBase64Url] = useState(initialImg);
  const coverPhotoRef = useRef(null);

  clearImageRef.current = () => {
    coverPhotoRef.current.value = '';
    setCoverPhotoBase64Url(null);
  };

  function onCoverPhotoClick() {
    coverPhotoRef.current.click();
  }

  function onCoverPhotoChanged({ target: { files } }) {
    const file = files[0];

    if (!file) {
      return false;
    }

    setCoverPhotoBase64Url(window.URL.createObjectURL(file));
    update(file);
  }

  return (
    <>
      <StyledFileUpload
        type="file"
        name={name}
        accept={acceptImages}
        className="hide"
        ref={coverPhotoRef}
        onChange={onCoverPhotoChanged}
      />
      <CoverPhotoContainerWrap {...props} className={className}>
        <CoverPhotoContainer maxWidth={maxWidth} borderRadius={borderRadius}>
          <CoverPhotoDummy ratio={ratio} />
          {coverPhotoBase64Url ? (
            <CoverPhotoImage
              className={'component-image'}
              url={coverPhotoBase64Url}
              onClick={onCoverPhotoClick}
            />
          ) : (
            <DetailsCoverPhotoImageUpload
              className={'component-image component-image-upload'}
              onClick={onCoverPhotoClick}
            >
              <ImagePlacement />
            </DetailsCoverPhotoImageUpload>
          )}

          {showProgress && <ImageProgressBar now={progress} />}
        </CoverPhotoContainer>

        {children}
      </CoverPhotoContainerWrap>
    </>
  );
}

UploadImage.propTypes = {
  className: PropTypes.string,
  showProgress: PropTypes.bool,
  progress: PropTypes.number,
  borderRadius: PropTypes.string,
  ratio: PropTypes.string,
  maxWidth: PropTypes.string,
  acceptImages: PropTypes.string,
  initialImg: PropTypes.string,
  update: PropTypes.func,
  clearImageRef: PropTypes.object,
};

export default UploadImage;
