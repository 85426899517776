import styled, {css} from 'styled-components';
import {HideScrollBarCss, UserSelectNoneCss} from "styles/common";
import StyledAvatar from "components/StyledAvatar";

export const Body = styled.div`
  height: 100%;
  background: #fff;
  position: relative;
  overflow-y: scroll;
  ${HideScrollBarCss};
`;

export const ContainerCss = css`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  ${HideScrollBarCss};
  padding: 0 10px;
`;

export const ConnectingCss = css`

  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #EAEAEA;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    animation: rotate 4s linear infinite;
    background-image: linear-gradient(${({theme: {colors}}) => colors.violetPurple}, #b7b7b7);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #EAEAEA;
  }

`;


export const Container = styled.div`
  ${ContainerCss};
`;

export const TopHeaderMessage = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const AuthorName = styled.span`
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9em;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 12px;
`;

export const InnerMessageWrap = styled.span`
  padding-left: 5px;
  width: 100%;
`;

export const MessageWrapper = styled.div`
  margin: 4px 0;
  display: flex;
`;

export const StyledAvatarWrap = styled(StyledAvatar)`
  --dim: 30px;
  width: var(--dim);
  height: var(--dim);
  min-width: var(--dim);
  max-width: var(--dim);
  min-height: var(--dim);
  max-height: var(--dim);
  ${UserSelectNoneCss};
  cursor: auto;
`;