import React from 'react';
//  import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Button';

const UserMenuLoginButtonWrap = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${({theme: {typo}}) => typo.sizes.body2};
`;

const Wrapper = styled.div`
`;

function UserMenuLoginButton({setLoginModalStatus}) {
  return (
    <Wrapper>
      <UserMenuLoginButtonWrap onClick={() => {
        setLoginModalStatus(true);
      }}>
        <Button
          // isLoading={ isLoading }
          backIconName='fas fa-sign-in-alt'
          label='Sign In'
          buttonStyle={'generalWhite'}
        />
      </UserMenuLoginButtonWrap>
    </Wrapper>
  );
}

UserMenuLoginButton.propTypes = {};

export default UserMenuLoginButton;
