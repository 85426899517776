import React, { useEffect, Suspense, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getWorkshopBaseUrl } from 'utilities/workshop';
import { getDecodedRedirectPath } from 'utilities/general';

import { getAuthIsLoggedIn } from '../../../../reduxUtils/auth/selectors';
import {
  getUserId,
  getUserStripeAccountId,
  getUserStripeAccountStatus,
} from '../../../../reduxUtils/user/selectors';
import { getUserAccessControl } from '../../../../reduxUtils/accessControl/selectors';
import { getBrowserIsMobile } from '../../../../reduxUtils/browserSettings/selectors';
import {
  getUserFullName,
  getUserAvatarThumbnail,
} from '../../../../reduxUtils/userProfile/selectors';
import { getWorkshop } from '../../../../reduxUtils/workshop/selectors';
import { getWorkshopEager, startWorkshop } from '../../../../reduxUtils/workshop/thunks';

import WithSideBar from 'layouts/WithSideBar';
import MetaDecorator from 'components/MetaDecorator';

import Fallback from 'components/Fallback';
import { getIsChatDetach, getIsChatMinimized } from '../../../../reduxUtils/chat/selectors';
import { getUserSettings } from '../../../../reduxUtils/userSettings/selectors';

const Edit = lazy(() => import('./Edit'));

function WorkshopEdit({
  navigate,
  match,
  location,
  isMobile,
  isLoggedIn,
  userId,
  fullName,
  avatar,
  workshop,
  accessControl = [],
  userSettings,
  getWorkshopEager,
  stripeAccountId,
  stripeAccountStatus,
  isChatDetach,
  isChatMinimized,
}) {
  const firstRedirectTo = getDecodedRedirectPath();
  const [redirectTo, setRedirectTo] = useState('');
  const [isLeftBarOpened, setIsLeftBarOpened] = useState(true);
  const [menuOptions, setMenuOptions] = useState([
    {
      label: 'Home',
      iconName: 'fas fa-home',
      linkTo: '/',
    },
    {
      label: 'Details',
      iconName: 'fas fa-info-circle',
      linkTo: `${match.pathname}/workshop-details`,
    },
    // {
    //   label: 'Settings',
    //   iconName: 'fas fa-cog',
    //   linkTo: `${match.pathname}/workshop-settings`,
    //   isDisabled: true,
    // },
  ]);

  useEffect(() => {
    if (firstRedirectTo) {
      setRedirectTo(firstRedirectTo);
    }
  }, []); // eslint-disable-line

  function OnIsLeftBarChange() {
    setIsLeftBarOpened(!isLeftBarOpened);
  }

  return (
    <>
      <MetaDecorator
        url={getWorkshopBaseUrl(workshop.id, true)}
        image={workshop.coverPhoto}
        title={`${workshop.title} | workshop (edit)`}
        description={workshop.summery}
      >
        <meta property="og:hashtag" content={workshop.categories.join()} />
      </MetaDecorator>

      <WithSideBar
        location={location}
        options={menuOptions}
        isMobile={isMobile}
        isLoggedIn={isLoggedIn}
        isLeftBarOpened={isLeftBarOpened}
        userId={userId}
        fullName={fullName}
        avatar={avatar}
        setLocalIsLeftBarOpened={OnIsLeftBarChange}
        isChatMinimized={isChatMinimized && isChatDetach}
      >
        <Suspense fallback={<Fallback />}>
          <Edit
            navigate={navigate}
            match={match}
            userId={userId}
            workshop={workshop}
            redirectTo={redirectTo}
            accessControl={accessControl}
            userSettings={userSettings}
            getWorkshopEager={getWorkshopEager}
            setMenuOptions={setMenuOptions}
            stripeAccountId={stripeAccountId}
            stripeAccountStatus={stripeAccountStatus}
          />
        </Suspense>
      </WithSideBar>
    </>
  );
}

WorkshopEdit.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  isLeftBarOpened: PropTypes.bool,
  workshop: PropTypes.object,
  accessControl: PropTypes.array,
  getWorkshopEager: PropTypes.func,
  startWorkshop: PropTypes.func,
  isChatDetach: PropTypes.bool,
  isChatMinimized: PropTypes.bool,
};

function mapStateToProps(state) {
  const userId = getUserId(state);
  let accessControl = [];
  if (userId) {
    accessControl = getUserAccessControl(state, userId);
  }
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    userId,
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    workshop: getWorkshop(state),
    accessControl,
    userSettings: getUserSettings(state),
    stripeAccountId: getUserStripeAccountId(state),
    stripeAccountStatus: getUserStripeAccountStatus(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getWorkshopEager,
      startWorkshop,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopEdit);
