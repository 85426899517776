import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import PollElement from "./components/PollElement";
import {getPolls} from "../../../../../../reduxUtils/polls/selectors";
import {deletePoll} from "../../../../../../reduxUtils/polls/thunks";
import ConfirmationModal from "components/Modal/ConfirmationModal";


function DisplayPoll({
                       isAdmin,
                       userId,
                       polls,
                     }) {
  const defaultDialogState = {
    show: false,
    title: '',
    contentTitle: '',
    isLoading: false,
    children: null,
    confirmationText: '',
    onCancel: resetDialog,
    onHide: resetDialog,
    onConfirm: () => {
    }
  };
  const [dialog, setDialog] = useState(defaultDialogState);

  function resetDialog() {
    setDialog(defaultDialogState);
  }

  return (
    <>
      <ConfirmationModal
        {...dialog}
        isLoading={dialog.isLoading}
        show={dialog.show}
        title={dialog.title}
        contentTitle={dialog.contentTitle}
        confirmationText={dialog.confirmationText}
        confirmButtonText={dialog.action}
        cancelButtonText={dialog.cancelButtonText}
        children={dialog.children}
        onCancel={resetDialog}
        onConfirm={dialog.onConfirm}
        onHide={resetDialog}
      />
      {
        polls.map((poll) => {
          return (
            <PollElement
              key={poll.id}
              isAdmin={isAdmin}
              userId={userId}
              poll={poll}
              deletePoll={deletePoll}
              setDialog={setDialog}
            />
          )
        })
      }
    </>
  );
}

function mapStateToProps(state) {
  return {
    polls: getPolls(state),
  }
}

DisplayPoll.propTypes = {
  userId: PropTypes.number,
  isAdmin: PropTypes.bool,
  polls: PropTypes.array,

};

export default connect(mapStateToProps)(React.memo(DisplayPoll));
