import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  UPDATE_USER_PROFILE
} from './types';

import {
  LOGOUT
} from '../auth/types';

let initialState = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  avatarDetails: '',
  avatar: '',
  avatarThumbnail: '',
  avatarHomePage: '',
  rating: 0,
  ratingCount: 0,
  followersCount: 0,
  mobilePhoneNumber: ''
};

export default handleActions({
  [UPDATE_USER_PROFILE]: (state, {payload}) => {
    return {...state, ...pick(payload, Object.keys(initialState))};
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
