import moment from 'moment';

export function sortByProp(key, order = 'ASC', prepareProp = (value) => value) {
  return function (a, b) {
    const aOrder = prepareProp(a[key]);
    const bOrder = prepareProp(b[key]);

    if (aOrder < bOrder) {
      return order === 'ASC' ? -1 : 1;
    }

    if (aOrder > bOrder) {
      return order === 'ASC' ? 1 : -1;
    }

    return 0;
  };
}

export function sortByMultipleProp(keyA, keyB, order = 'ASC', prepareProp = (keyA, keyB) => keyA) {
  return function (a, b) {
    const aOrder = prepareProp(a[keyA], a[keyB]);
    const bOrder = prepareProp(b[keyA], b[keyB]);

    if (aOrder < bOrder) {
      return order === 'ASC' ? -1 : 1;
    }

    if (aOrder > bOrder) {
      return order === 'ASC' ? 1 : -1;
    }

    return 0;
  };
}

export function sortByPropInvokeFunction(order = 'ASC', prepareProp = () => {
}) {
  return function (a, b) {
    const aOrder = prepareProp(a);
    const bOrder = prepareProp(b);

    if (aOrder < bOrder) {
      return order === 'ASC' ? -1 : 1;
    }

    if (aOrder > bOrder) {
      return order === 'ASC' ? 1 : -1;
    }

    return 0;
  };
}


export function sortByCreateOn(a, b) {
  const aCreatedOn = moment(a.createdOn);
  const bCreatedOn = moment(b.createdOn);

  if (aCreatedOn.diff(bCreatedOn) < 0) {
    return 1;
  }

  if (aCreatedOn.diff(bCreatedOn) > 0) {
    return -1;
  }

  return 0;
}

export function sortByColumnOrder(a, b) {
  const aOrder = a.order;
  const bOrder = b.order;

  if (aOrder < 0) {
    return 1;
  }

  if (bOrder > 0) {
    return -1;
  }

  return 0;
}

export function dndReorder({list, startIndex, endIndex}) {
  const newList = [...list];
  const [removed] = newList.splice(startIndex, 1);
  newList.splice(endIndex, 0, removed);
  return newList;
}

export function sortByDate(a, b) {
  let aDate = null;
  let bDate = null;

  if (!a.occurrences || !a.occurrences[0] || !a.occurrences[0][0] || !a.occurrences[0][0].date) {
    if (!b.occurrences || !b.occurrences[0] || !b.occurrences[0][0] || !b.occurrences[0][0].date) {
      return 0;
    }
    return -1;
  }

  aDate = moment(a.occurrences[0][0].date);

  if (!b.occurrences || !b.occurrences[0] || !b.occurrences[0][0] || !b.occurrences[0][0].date) {
    return 1;
  }

  bDate = moment(b.occurrences[0][0].date);

  return aDate.diff(bDate);
}

export function sortByDateFlatArray(a, b) {
  return moment(a.date).diff(moment(b.date), 'minutes', true);
}


export function addElementFromArray(arr, element, key) {

  if (!arr || !arr.length) {
    return arr;
  }

  const index = arr.findIndex((userInRoom) => userInRoom[key] === element[key]);
  if (index > -1) {
    return arr;
  }

  return [...arr, element];
}

export function removeElementFromArray(arr, key, value) {
  if (!arr || !arr.length) {
    return arr;
  }

  const index = arr.findIndex((userInRoom) => userInRoom[key] === value);
  if (index < 0) {
    return arr;
  }
  const newArr = [...arr];
  newArr.splice(index, 1);
  return newArr;
}