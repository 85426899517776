import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleSignInButtonImg from 'assets/google sign in.png';
import {successHandler} from "utilities/services";

const GoogleSignInWrap = styled.div`

`;

const Text = styled.p`
  font-size: 18px;
`;

const GoogleButton = styled.input`
  display: flex;
  height: 50px;
  border-radius: 5px;
  margin: 0 auto;
  border: 2px solid ${({theme: {colors}}) => colors.lightGrey};

  &:hover {
    border: 2px solid ${({theme: {colors}}) => colors.grey};
  }

  cursor: ${({isLoading}) => isLoading ? 'not-allowed' : 'pointer'};
  opacity: ${({isLoading}) => isLoading ? 0.8 : 1};

`;

const Error = styled.p`
  font-size: 15px;
  color: ${({theme: {colors}}) => colors.error};
  height: 15px;
  margin-bottom: 0;
`;


function GoogleSignIn({className, text, scopes, discoveryDocs, onSuccess, onFail, ...props}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function onInitSuccess(isSignedIn) {
    if (window.isGoogleApiLoaded) {
      if (isSignedIn) {
        setIsLoading(false)
        onSuccess && onSuccess();
      } else {
        signIn();
      }
    } else {
      setError('failed to load google api');
      setIsLoading(false);
    }
  }

  function signIn() {
    setIsLoading(true);
    window.handleAuthClick(null, setError(), scopes)
      .then(() => {
        setIsLoading(false);
        window.isLoggedInGoogle = true;
        successHandler('you\'ve successfully signed in with google')
        onSuccess && onSuccess();
      })
      .catch(err => {
        setIsLoading(false);
        let errorMsg = err.error;
        switch (err.error) {
          case 'popup_closed_by_user':
            errorMsg = 'sign in was not complete pop up window was closed';
            break;
          case 'access_denied':
            errorMsg = 'sign in was not complete due to access denied';
            break;
          default:

            break

        }
        setError(errorMsg);
        onFail(errorMsg);
      })
  }

  function signInWithGoogle() {
    if (!window.isGoogleApiLoaded) {
      setIsLoading(true);
      window.handleClientLoad(scopes, onInitSuccess, (error) => {
        setIsLoading(false);
        setError(error);
      });
    } else {
      signIn();
    }

  }

  return (
    <GoogleSignInWrap className={className} {...props}>
      <Text>
        {text}
      </Text>
      <GoogleButton
        type="image"
        disabled={isLoading}
        isLoading={isLoading}
        src={GoogleSignInButtonImg}
        onClick={signInWithGoogle}
      />
      <Error>
        {error}
      </Error>
    </GoogleSignInWrap>
  );
}

GoogleSignIn.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  scopes: PropTypes.string,
  discoveryDocs: PropTypes.array,
};

export default GoogleSignIn;
