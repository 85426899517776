import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFeedbacks } from '../../../reduxUtils/feedbacks/selectors';
import { removeFeedback } from '../../../reduxUtils/feedbacks/actions';

import BarItem from './components/BarItem';

const FeedbackBarWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

function FeedbackBar({ feedbacks = [], removeFeedback }) {
  if (!feedbacks.length) {
    return null;
  }

  function removeAFeedback(name) {
    removeFeedback(name);
  }

  return (
    <FeedbackBarWrap>
      {feedbacks.map(({ type, content, noClose, name }) => (
        <BarItem key={name} type={type} onRemove={() => removeAFeedback(name)} noClose={noClose}>
          {content}
        </BarItem>
      ))}
    </FeedbackBarWrap>
  );
}

FeedbackBar.propTypes = {
  feedbacks: PropTypes.array,
  removeFeedback: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    feedbacks: getFeedbacks(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFeedback,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackBar);
