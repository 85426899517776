import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  UPDATE_WORKSHOP,
  DELETE_WORKSHOP
} from './types';

import {
  PAYMENT_TYPES,
  WORKSHOP_STATUS,
  WORKSHOP_SIZE_LIMITATIONS
} from 'config/constants';

import {
  LOGOUT
} from '../auth/types';

import {fixCategories} from 'utilities/general';

export const initialState = {
  id: null,
  title: 'Workshop title',
  categories: [],
  description: '',
  summary: '',
  coverPhotoDetails: '',
  coverPhoto: '',
  coverPhotoHomePage: '',
  coverPhotoThumbnail: '',
  paymentType: PAYMENT_TYPES.FREE.key,
  price: 0,
  currency: 'USD',
  participantsLimit: WORKSHOP_SIZE_LIMITATIONS.MEMBERSHIP_FREE.participantsLimit.max,
  rating: 0,
  userFullName: '',
  isPrivate: false,
  status: WORKSHOP_STATUS.STARTED.key
};

export default handleActions({
  [UPDATE_WORKSHOP]: (state, {payload}) => {
    if (payload.categories) {
      payload.categories = fixCategories(payload.categories);
    }
    return {...state, ...pick(payload, Object.keys(initialState))};
  },
  [DELETE_WORKSHOP]: () => {
    return initialState;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
