import UserService from 'services/user';
import StripeService from 'services/stripe';

import {setUserPaymentMethod, deleteUserPaymentMethod, updateUser} from './actions';
import {updateUserSettingsAction} from '../userSettings/actions';
import {updateUserProfile} from '../userProfile/actions';
import {setUserFavorites} from '../userFavorites/actions';
import {setUserFollowings} from '../userFollowings/actions';
import {setAccessControl} from '../accessControl/actions';

import {errorHandler} from 'utilities/services';
import {deleteUserEventRegistration} from "../userEventRegistrations/actions";
import {deleteHomeUpcomingEvent} from "../homeUpcomingEvents/actions";

const userService = new UserService();
const stripeService = new StripeService();

export function getUserEager() {
  return dispatch => userService
    .getUserEager()
    .then((response) => {
      const {data: {user}} = response;
      dispatch(updateUser(user));
      dispatch(updateUserSettingsAction(user.userSetting || {}));
      dispatch(updateUserProfile(user.userProfile || {}));
      dispatch(setUserFavorites(user.userFavorites || []));
      dispatch(setUserFollowings(user.userFollowings || []));
      dispatch(setAccessControl(user.accessControls || {}));

      // if (user.status === USER_STATUS.REGISTERED.key) {
      //   dispatch(addFeedback({
      //     name: FEEDBACK_NAMES.EMAIL_VERIFICATION.key,
      //     type: 'notify',
      //     content: <VerificationRequired noClose/>,
      //     noClose: true
      //   }));
      // }
      return response;
    })
    .catch(err => {
      errorHandler(err)
    })
}

export function updateUserDpm(stripeCustomerDPM) {
  return dispatch => stripeService.paymentUpdateDpm({stripeCustomerDPM})
    .then((response) => {
      dispatch(updateUser({stripeCustomerDPM}));
      return response;
    });
}

export function getPaymentListMethods() {
  return dispatch => stripeService.paymentListMethods()
    .then((response) => {
      const {data: {paymentMethods}} = response;
      dispatch(setUserPaymentMethod(paymentMethods));
      return response;
    });
}

export function deletePaymentMethod(paymentMethodId) {
  return dispatch => stripeService.paymentDeleteMethod(paymentMethodId)
    .then((response) => {
      dispatch(deleteUserPaymentMethod(paymentMethodId));
      return response;
    }).catch(err => {
      errorHandler(err)
    });
}

export function refundContent({eventId, occurrenceId, registrationId}) {
  return dispatch => stripeService.createContentRefund({EventId: eventId, OccurrenceId: occurrenceId})
    .then((response) => {
      dispatch(deleteUserEventRegistration(registrationId));
      dispatch(deleteHomeUpcomingEvent({eventId, occurrenceId}));
      return response;
    })
}

/*
export function getUserAccessControl( ) {
  return dispatch => userService
    .getUserAccessControl()
    .finally((accessControl) => {
      updateUser({accessControl})
    });
}

export function updateUserAccessControl(accessControl) {
  return dispatch => userService
    .updateUserAccessControl(accessControl)
    .finally((accessControl) => {
      updateUser({accessControl})
    });
}
*/