import {handleActions} from 'redux-actions';
import {differenceWith, unionWith} from 'lodash';
import {
  ADD_HOME_FOLLOWINGS,
  DELETE_HOME_FOLLOWINGS,
  DELETE_HOME_FOLLOWING
} from './types';
import {LOGOUT} from "../auth/types";
// import { comparator } from 'utilities/general';

export const initialState = {
  page: 0,
  pageSize: 0,
  totalPages: 0,
  totalRows: 0,
  rows: [],
  reqParams: {}
};

const comparator = (itemA, itemB) => {
  return itemA.id === itemB.id;
};

export default handleActions({
  [ADD_HOME_FOLLOWINGS]: (state, {payload}) => {
    const {followingsTile = [], properties} = payload;
    const unionArr = unionWith(state.rows, followingsTile, comparator);
    if (unionArr.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      ...properties,
      rows: unionArr
    };
  },
  [DELETE_HOME_FOLLOWING]: (state, {payload}) => {
    const {toUserId} = payload;
    const foundIndex = state.rows.findIndex((following) => following.toUserId === toUserId);
    if (foundIndex > -1) {
      const newFollowings = [...state.rows];
      newFollowings.splice(foundIndex, 1);
      return {
        ...state,
        totalRows: state.totalRows - 1,
        rows: newFollowings
      };
    }

    return state;
  },

  [DELETE_HOME_FOLLOWINGS]: (state, {payload}) => {
    const {deleteIds = [], properties} = payload;

    const diff = differenceWith(state.rows, deleteIds, comparator);
    if (diff.length === state.rows.length) {
      return state;
    }

    return {
      ...state,
      ...properties,
      rows: diff
    };
  },

  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});