import React, {Suspense, lazy} from 'react';

import CenteredWithBackground from 'layouts/CenteredWithBackground';
import Fallback from 'components/Fallback';

const VerifyEmail = lazy(() => import('./VerifyEmail'));
export default function VerifyEmailWrap() {
  return (
    <CenteredWithBackground>
      <Suspense fallback={<Fallback />}>
        <VerifyEmail />
      </Suspense>
    </CenteredWithBackground>
  );
}