import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';

const SearchButtonWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SearchButtonIcon = styled(Icon)`
  font-size: ${({theme: {typo}}) => typo.sizes.h5};
  color: ${({isOpened, theme: {colors}}) => isOpened ? colors.black : colors.darkGrey};
`;

function SearchButton({isMobile}) {
  const [isOpened, setIsOpened] = useState(false);

  function handleOnClick() {
    setIsOpened(!isOpened);
  }

  return (
    <SearchButtonWrap onClick={handleOnClick} isMobile={isMobile}>
      <SearchButtonIcon isOpened={isOpened} className="fas fa-search"/>
    </SearchButtonWrap>
  );
}

SearchButton.propTypes = {
  isMobile: PropTypes.bool
};

export default SearchButton;
