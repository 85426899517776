import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { UserSelectNoneCss } from 'styles/common';

const CustomLabelWrap = styled.div`
  padding: 3px 10px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.white};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  ${UserSelectNoneCss};

  ${({ invertColor }) =>
    invertColor
      ? css`
          opacity: 0.8;
          background: linear-gradient(180deg, #6a6a6a 0%, rgba(106, 106, 106, 0) 100%), #c2c7cd;
        `
      : css`
          background: linear-gradient(1.56deg, #5f2656 40.98%, #bb226d 100.92%), #6092d6;
        `};

  ${({ disable, isnotready }) =>
    disable
      ? css`
          &:hover {
            cursor: not-allowed;
          }
        `
      : !isnotready &&
        css`
          cursor: pointer;

          transition: 0.1s linear;

          &:hover {
            transform: scale(1.05);
          }

          &:active {
            transform: scale(0.95);
          }
        `};
`;

function CustomLabel({ label, ...props }) {
  return <CustomLabelWrap {...props}>{label}</CustomLabelWrap>;
}

CustomLabel.propTypes = {
  label: PropTypes.string,
};

export default React.memo(CustomLabel);
