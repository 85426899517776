import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SwitchInputWrap = styled.div`
  display: flex;
  --dim: 40px;
  align-items: center;

  height: calc(var(--dim) / 2);
  width: var(--dim);
  border-radius: 10px;
  padding: 0 1px;
  cursor: ${({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer'};
  background-color: ${({isActive, theme: {colors}}) => isActive ? colors.primary : colors.lightGrey};
  justify-content: ${({isActive}) => isActive ? 'flex-end' : 'flex-start'};
`;

const SwitchInputHandle = styled.div`
  display: block;
  --dim: 40%;
  aspect-ratio: 1 / 1;
  //height: var(--dim);
  width: var(--dim);

  border-radius: 50%;
  background-color: ${({theme: {colors}}) => colors.white};
`;

function SwitchInput(
  {
    className,
    isActive,
    disabled,
    onChange = () => {},
    setRef = () => {},
    ...props
  }
) {
  return (
    <SwitchInputWrap
      {...props}
      ref={setRef}
      className={className}
      isActive={isActive}
      isDisabled={disabled}
      onClick={() => !disabled && onChange(!isActive)}
      title={isActive ? 'yes' : 'no'}
    >
      <SwitchInputHandle />
    </SwitchInputWrap>
  );
}

SwitchInput.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onChange: PropTypes.func
};

export default SwitchInput;
