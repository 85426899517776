import WorkshopService from 'services/workshop';


import {errorHandler} from 'utilities/services';
import {addHomeWorkshops, setHomeWorkshops} from "../homeWorkshops/actions";
import {CONTENT_CATEGORY_TYPE} from "config/constants";
import {pick} from "lodash";
import {addHomeWorkshopsSuggestion2, setHomeWorkshopsSuggestion2} from "../homeWorkshopsSuggestion2/actions";
import {addHomeWorkshopsSuggestion1, setHomeWorkshopsSuggestion1} from "../homeWorkshopsSuggestion1/actions";

const workshopService = new WorkshopService();
const defaultWorkshopFields = [
  'id',
  'isEvent',// = true,
  'paymentType',
  'title',// = 'Some Title',
  'summary',//
  'coverPhotoDetails',
  'coverPhoto',// = logo,
  'coverPhotoHomePage',
  'price',// ='$37',
  'currency',
  'rating',//
  'ratingCount',
  'date',//
  'endDate',
  'duration', // = '5 Hours',
  'user', // = {id, fullName, avatar, avatarThumbnail, avatarHomePage, rating, ratingCount, followersCount}
  'UserId',// = 17,'
  'eventsCount',
  'eventsInfo',
  'status',
  'categories',
  'registrationsCount'
];

export function fetchHomeWorkshops(query, addNextPage, type) {
  return dispatch => workshopService
    .getAll(query)
    .then((response) => {
      const {data: {workshops}} = response;
      workshops.reqParams = query.reqParams;

      switch (type) {
        case CONTENT_CATEGORY_TYPE.new:
          if (addNextPage) {
            dispatch(addHomeWorkshops(workshops));
          } else {
            dispatch(setHomeWorkshops(workshops));
          }
          break;

        case CONTENT_CATEGORY_TYPE.suggestion1:
          if (addNextPage) {
            dispatch(addHomeWorkshopsSuggestion1(workshops));
          } else {
            dispatch(setHomeWorkshopsSuggestion1(workshops));
          }
          break;

        case CONTENT_CATEGORY_TYPE.suggestion2:
          if (addNextPage) {
            dispatch(addHomeWorkshopsSuggestion2(workshops));
          } else {
            dispatch(setHomeWorkshopsSuggestion2(workshops));
          }
          break;

        default:
          console.log(`fetchHomeWorkshops default case hit`, type)
          break;
      }

      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}

export function reduxSetWorkshopHelper(state, payload) {
  return {
    ...state,
    ...payload,
    rows: payload.rows.map((workshop) => pick(workshop, defaultWorkshopFields))
  };
}

export function reduxAddWorkshopHelper(state, payload) {
  const newWorkshop = pick(payload, defaultWorkshopFields);
  const workshops = [...state.rows];

  const foundIndex = workshops.findIndex((workshop) => workshop.id === newWorkshop.id);
  if (foundIndex === -1) {
    workshops.push(newWorkshop);
    return workshops;
  }

  return state;
}

export function reduxAddWorkshopsHelper(state, payload) {
  const workshops = [...state.rows];
  const newWorkshops = (payload.rows || [])
    .filter((newWorkshop) => {
      return workshops.findIndex((workshop) => workshop.id === newWorkshop.id) === -1;
    })
    .map((newWorkshop) => pick(newWorkshop, defaultWorkshopFields));
  return {
    ...state,
    ...payload,
    rows: [...workshops, ...newWorkshops]
  };
}

export function reduxUpdateWorkshopsHelper(state, payload) {
  const foundIndex = (state.rows || []).findIndex((workshop) => workshop.id === payload.id);
  if (foundIndex > -1) {
    const workshops = [...state.rows];
    workshops[foundIndex] = {...workshops[foundIndex], ...payload}
    return {
      ...state,
      rows: workshops
    }
  }
  return state;
}