import moment from 'moment-timezone';
import {getUserSettings} from '../reduxUtils/userSettings/selectors';
import {getStoreState} from './store';
import {NO_TZ_TIMESTAMP_FORMAT, TIME_INTERVALS} from 'config/constants';
import {isEventNow} from "utilities/event";

export function dateFromUserTimeZone(date) {
  const defaultTimezone = moment.tz.guess();
  const state = getStoreState();
  const {timezone} = getUserSettings(state);
  const offset = moment.tz(date, timezone || defaultTimezone).format('Z');
  return moment(date).format(NO_TZ_TIMESTAMP_FORMAT) + offset;
}

export function getUserTimeZone() {
  const state = getStoreState();
  const {timezone} = getUserSettings(state);
  return timezone;
}

export function dateToUserTimeZone(date) {
  const state = getStoreState();
  const {timezone} = getUserSettings(state);
  return moment(date).tz(timezone || moment.tz.guess());
}

export function isDateInFuture(date, offset = null) {
  const now = dateToUserTimeZone(Date.now());
  const future = dateToUserTimeZone(date);
  if (!!offset) {
    future.add(offset, 's');
  }

  return now.isSame(future) || now.isBefore(future);
}

export function getDuration(duration) {
  return moment.utc(duration).format('HH:mm:ss');
}

export function formatVideoLength(duration, unit) {
  let seconds = duration;
  if (unit === 'm') {
    seconds = duration * 60;
  }

  if (unit === 'h') {
    seconds = duration * 60 * 60;
  }

  let format = 'm:ss';
  if (seconds >= 60) {
    format = 'mm:ss';
  }

  if (seconds >= 3600) {
    format = 'HH:mm:ss';
  }
  return moment.utc(moment.duration(seconds, 's').asMilliseconds()).format(format);
}

export function processDuration(duration = 0, unit = 'm') {
  if (!duration)
    return '';

  return formatVideoLength(duration, unit);
}

export function processDurationHumanize(duration = 0, unit = 'm', showFull = false) {
  if (!duration || duration === Number.POSITIVE_INFINITY)
    return {};

  let minutesLabel = 'Min'
  let secondsLabel = 'Sec'
  let hoursLabel = 'H'
  let daysLabel = 'D'
  const momentDuration = moment.duration(duration, unit);
  if (showFull || !momentDuration.minutes()) {
    if (showFull || !momentDuration.hours()) {
      if (momentDuration.days()) {
        if (momentDuration.days() === 1) {
          daysLabel = 'Day';
        } else {
          daysLabel = 'Days';
        }
      }
    }
    if (showFull || !momentDuration.days()) {
      if (momentDuration.hours()) {
        if (momentDuration.hours() === 1) {
          hoursLabel = 'Hour';
        } else {
          hoursLabel = 'Hours';
        }
      }
    }
  }

  // if (momentDuration.asSeconds() < 60) {
  if (showFull) {
    if (momentDuration.seconds() === 1) {
      secondsLabel = 'second';
    } else {
      secondsLabel = 'seconds';
    }
  }
  // }

  return {
    days: {time: momentDuration.days(), label: daysLabel},
    hours: {time: momentDuration.hours(), label: hoursLabel},
    minutes: {time: momentDuration.minutes(), label: minutesLabel},
    seconds: {time: momentDuration.seconds(), label: secondsLabel}
  }
}

export function getDurationObject(duration = 0, unit = 'm', showFull = false) {
  if (!duration || duration === Number.POSITIVE_INFINITY)
    return {};

  let minutesLabel = 'Min'
  let secondsLabel = 'Sec'
  let hoursLabel = 'H'
  let daysLabel = 'D'
  const momentDuration = moment.duration(duration, unit);

  if (showFull) {
    if (momentDuration.minutes() === 1) {
      minutesLabel = 'minute';
    } else {
      minutesLabel = 'minutes';
    }
  }


  if (showFull) {
    if (momentDuration.days() === 1) {
      daysLabel = 'Day';
    } else {
      daysLabel = 'Days';
    }
  }

  if (showFull) {
    if (momentDuration.hours() === 1) {
      hoursLabel = 'Hour';
    } else {
      hoursLabel = 'Hours';
    }
  }


  // if (momentDuration.asSeconds() < 60) {
  if (showFull) {
    if (momentDuration.seconds() === 1) {
      secondsLabel = 'second';
    } else {
      secondsLabel = 'seconds';
    }
  }
  // }

  return {
    days: {time: momentDuration.days(), label: daysLabel},
    hours: {time: momentDuration.hours(), label: hoursLabel},
    minutes: {time: momentDuration.minutes(), label: minutesLabel},
    seconds: {time: momentDuration.seconds(), label: secondsLabel}
  }
}

// return the maximum from duration
// e.g days , hours exist return the days
export function getMaxTimeStr(duration) {
  console.log('duration', duration)
  const durationObj = getDurationObject(duration, 'seconds', true);
  let textTimeRemaining = '';
  switch (true) {
    case durationObj?.days?.time > 0:
      textTimeRemaining = `${durationObj.days.time} ${durationObj.days.label}`;
      break;
    case durationObj?.hours?.time > 0:
      textTimeRemaining = `${durationObj.hours.time} ${durationObj.hours.label}`;
      break;
    case durationObj?.minutes?.time > 0:
      textTimeRemaining = `${durationObj.minutes.time} ${durationObj.minutes.label}`;
      break;
    case durationObj?.seconds?.time > 0:
      textTimeRemaining = `${durationObj.seconds.time} ${durationObj.seconds.label}`;
      break;
    default:
      break
  }
  return textTimeRemaining;
}


export function getDiff(userNow, date, units = 'seconds') { //when duration is zero ongoing event return false (no drop-in)
  const userDate = dateToUserTimeZone(date);
  return userNow.diff(userDate, units, true);
}

function processDateHelper(date, duration = 0, pattern = ' h:mm a, MMM DD', fullDatePattern = 'dddd h:mm a, MMM DD') {
  const userNow = dateToUserTimeZone(new Date());
  const durationInMin = duration / 60;
  const timeDiff = Math.floor(getDiff(userNow, date, 'minutes'));

  const eventNow = isEventNow(timeDiff, durationInMin);

  return dateToUserTimeZone(date).calendar({
    sameDay: function (now) {
      if (eventNow) {
        return `[Now]${pattern}`;
      }
      return `[Today]${pattern}`;
    },
    nextDay: `[Tomorrow]${pattern}`,
    nextWeek: `${fullDatePattern}`,
    lastDay: function () {
      if (eventNow) {
        return `[Now]${pattern}`;
      }
      return `${fullDatePattern}`
    },
    lastWeek: function () {
      if (eventNow) {
        return `[Now]${pattern}`;
      }
      return `${fullDatePattern}`
    },
    sameElse: function () {
      if (eventNow) {
        return `[Now]${pattern}`;
      }
      return `${fullDatePattern}`
    }
  });
}

export function processDate(date, duration = 0) {
  if (!date)
    return '';

  return processDateHelper(date, duration);

}

export function processTileDate(date, duration = 0) {
  if (!date)
    return {};

  const processedDate = processDateHelper(date, duration, '|ddd, h:mm a', 'MMM DD|ddd, h:mm a')
  const split = processedDate.split('|');
  return {date: split[0] || '', day: split[1] || ''}
}

export function processDateToObject(date, duration = 0) {
  if (!date)
    return {};

  const processedDate = processDateHelper(date, duration, '|dddd|h:mm a', 'DD MMM|ddd|h:mm a')
  const split = processedDate.split('|');
  return {date: split[0] || '', day: split[1] || '', hour: split[2] || ''}
}

export function formatDate(date) {
  return date
    ? dateToUserTimeZone(date).format('dddd h:mm a, DD MMM')
    : '';
}

export function formatDateIso(date) {
  return date
    ? dateToUserTimeZone(date).format('YYYY-MM-DD HH:mm:ss')
    : '';
}

export function formatDateHour(date, offset = 0) {
  if (!date)
    return '';

  if (!offset) {
    return dateToUserTimeZone(date).format('h:mm a');
  }

  return dateToUserTimeZone(date).add(offset, 's').format('h:mm a');
}

export function getRoundedDate(date) {
  date.set({
    minutes: roundMinutes(date.get('minute')),
    second: 0,
    millisecond: 0
  });

  return date;
}

/*
* rounds minutes to the closest interval
*
* */
export function roundMinutes(num, interval = TIME_INTERVALS) {
  const remainder = 60 % interval;
  if (remainder || num > 60) {
    throw new Error('Num must be smaller then 60, and round to has to divide with 60 with no reminder');
  }

  let i = 1;
  const steps = 60 / interval;

  for (i; i <= steps; i++) {
    const prevIndex = Math.max(0, i - 1);
    const min = prevIndex * interval;
    const max = i * interval;
    if (num >= min && num <= max) {
      return max;
    }
  }
  return num;
}

export function formatTime(date) {
  let formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
  return formattedDate.replace("+00:00", "Z");
}

export function getNowUTC() {
  return moment.utc();
}

export function getNowUnix() {
  return Date.now();
}

moment.updateLocale('en-short', {
  relativeTime: {
    future: "%s",
    past: "%s",
    s: 'now',
    ss: '%d_s',
    m: "1_m",
    mm: "%d_m",
    h: "1_h",
    hh: "%d_h",
    d: "1_d",
    dd: "%d_d",
    w: "1_w",
    ww: "%d_w",
    M: "1_m",
    MM: "%d_m",
    y: "1_y",
    yy: "%d_y"
  }
});

moment.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'a few seconds',
    ss: '%d seconds',
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years"
  }
});

export function getNowUTCWithLocal() {
  return moment.utc().locale('en-short');
}

export function findFutureOccurrence(eventsArr) {
  let res = null;
  if (!eventsArr) {
    return res;
  }

  for (let m = 0; !res && m < eventsArr.length; m++) {
    const {occurrences = [], duration} = eventsArr[m];
    res = occurrences.find((occurrence) => {
      const {date} = occurrence;
      return isDateInFuture(date, duration);
    });

    if (res) {
      return {
        date: dateToUserTimeZone(res.date),
        duration: duration
      };
    }
  }

  return res;
}

