import {handleActions} from 'redux-actions';
import {
  SET_COLLAPSED,
  ADD_FILE,
  UPDATE_FILE,
  DELETE_FILE
} from './types';

import {
  LOGOUT
} from '../auth/types';

import {
  setData,
  getData
} from 'utilities/localStorage';

import {
  UPLOAD_STATUS,
  FILE_UPLOADER_KEY
} from 'config/constants';

/*
const defaultFile = {
  uniqueIdentifier: null,
  uploadType: 'chunked',
  fileSize: '',
  name: '',
  progress:  0,
  status: 'started'
};
*/

export const initialState = getData(FILE_UPLOADER_KEY) || {
  isCollapsed: false,
  files: []
};

const objOverrideForStorage = {
  status: UPLOAD_STATUS.ERROR.key,
  objUrl: null
};

function prepareFilesToStorage(files) {
  return (files || []).map((file) => ({
    ...file,
    ...objOverrideForStorage
  }));
}

export default handleActions({
  [SET_COLLAPSED]: (state, {payload}) => {
    const saveState = {
      ...state,
      isCollapsed: payload
    };
    setData(FILE_UPLOADER_KEY, {
      ...getData(FILE_UPLOADER_KEY),
      isCollapsed: payload
    });
    return saveState;
  },
  [ADD_FILE]: (state, {payload}) => {
    const files = [...state.files];
    const newFile = payload;
    const foundIndex = files.findIndex((file) => file.name === newFile.name);

    if (foundIndex > -1) {
      files[foundIndex] = newFile;
      const saveState = {
        ...state,
        files: [...prepareFilesToStorage(files)]
      };
      setData(FILE_UPLOADER_KEY, saveState);
      return {
        ...state,
        isCollapsed: false,
        files: [...files]
      };
    }

    const saveState = {
      ...state,
      files: [...prepareFilesToStorage(files), {...newFile, ...objOverrideForStorage}]
    };
    setData(FILE_UPLOADER_KEY, saveState);
    return {
      ...state,
      isCollapsed: false,
      files: [...files, newFile]
    };
  },
  [UPDATE_FILE]: (state, {payload}) => {
    const files = [...state.files];
    const updatedFile = payload;
    const foundIndex = files.findIndex((file) => file.name === updatedFile.name);
    if (foundIndex > -1) {
      files[foundIndex] = {...files[foundIndex], ...updatedFile};
      const saveState = {
        ...state,
        files: [...prepareFilesToStorage(files)]
      };
      setData(FILE_UPLOADER_KEY, saveState);
      return {
        ...state,
        files: [...files]
      };
    }

    const saveState = {
      ...state,
      files: [...prepareFilesToStorage(files)]
    };
    setData(FILE_UPLOADER_KEY, saveState);
    return {
      ...state,
      files: [...files]
    };
  },
  [DELETE_FILE]: (state, {payload}) => {
    const files = [...state.files];
    const fileName = payload;
    const foundIndex = files.findIndex((file) => file.name === fileName);
    if (foundIndex > -1) {
      files.splice(foundIndex, 1);

      const newState = {
        ...state,
        files: [...files]
      };
      setData(FILE_UPLOADER_KEY, newState);
      return newState;
    }
    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
