import {
  addUserWorkshopRegistration,
  deleteUserWorkshopRegistration,
  setUserWorkshopRegistrations
} from './actions';
import {errorHandler, successHandler} from 'utilities/services';
import WorkshopService from 'services/workshop';
import {
  addUserEventsRegistration,
  deleteUserEventsRegistration
} from '../userEventRegistrations/actions';
import {addHomeUpcomingWorkshop} from "../homeUpcomingWorkshops/actions";

const workshopService = new WorkshopService();

export function setAllWorkshopRegistrations() {
  return dispatch => workshopService.getAllUserWorkshopRegistrations()
    .then((response) => {
      const {data: {registrations}} = response;
      dispatch(setUserWorkshopRegistrations(registrations));
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}

export function setWorkshopRegistrations(workshopId) {
  return dispatch => workshopService.getAllWorkshopRegistrations(workshopId)
    .then((response) => {
      const {data: {registration: {workshop, events}}} = response;
      if (workshop) {
        dispatch(setUserWorkshopRegistrations([workshop]));
      }
      if (events) {
        dispatch(addUserEventsRegistration(events));
      }
      return response;
    })
    .catch(err => {
      errorHandler(err)
    });
}


export function addWorkshopRegistration(workshopId, workshop) {
  return dispatch => workshopService.getAllWorkshopRegistrations(workshopId)
    .then((response) => {
      const {data: {registration}} = response;
      if (registration) {
        dispatch(addUserWorkshopRegistration(registration.workshop));
        dispatch(addUserEventsRegistration(registration.events));
      }
      dispatch(addHomeUpcomingWorkshop(workshop));
      return response;
    })
    .catch(err => {
      errorHandler(err);
    });
}


export function createWorkshopRegistration(workshopId, workshop) {
  return dispatch => workshopService.createWorkshopRegistration(workshopId)
    .then((response) => {
      const {data: {registration}} = response;
      if (registration) {
        dispatch(addUserWorkshopRegistration(registration.workshop));
        dispatch(addUserEventsRegistration(registration.events));
      }
      dispatch(addHomeUpcomingWorkshop(workshop));
      successHandler('You\'ve successfully registered to the workshop');
      return response;
    })
}

export function deleteWorkshopRegistration(workshopId, registrationId) {
  return dispatch => workshopService
    .deleteWorkshopRegistration(workshopId, registrationId)
    .then((response) => {
      const {data: {res}} = response;
      const {events} = res ? res : {};
      dispatch(deleteUserWorkshopRegistration(registrationId));
      dispatch(deleteUserEventsRegistration(events));
      successHandler('You\'ve successfully unregistered from the workshop');
      return response;
    })
}