import FavoriteService from 'services/favorite';

import {
  addUserFavorite,
  deleteUserFavorite
} from './actions';
import {errorHandler} from 'utilities/services';


const favoriteService = new FavoriteService();

export function addFavorite({eventId, workshopId}) {
  return dispatch => favoriteService.postFavorite({eventId, workshopId})
    .then((response) => {
      const {data: {userFavorite}} = response;
      dispatch(addUserFavorite(userFavorite));
      return response;
    })
    .catch(err=>{errorHandler(err)});
}

export function deleteFavorite(id) {
  return dispatch => favoriteService
    .deleteFavorite(id)
    .then((response) => {
      dispatch(deleteUserFavorite(id));
      return response;
    })
    .catch(err=>{errorHandler(err)});
}