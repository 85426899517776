import React from 'react';
import styled from 'styled-components';


const StarIconWrap = styled.i`
  color: ${({theme: {colors}}) => colors.orange};
  margin: 0 0.3em;
`;

function StarIcon() {
  return (
    <StarIconWrap className="fas fa-star"/>
  );
}


StarIcon.propTypes = {};

export default StarIcon;