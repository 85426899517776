import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';

import { getAuthIsLoggedIn } from '../../reduxUtils/auth/selectors';
import { getUserId } from '../../reduxUtils/user/selectors';
import { getUserFullName, getUserAvatarThumbnail } from '../../reduxUtils/userProfile/selectors';
import { getUserSettings } from '../../reduxUtils/userSettings/selectors';
import {
  getBrowserIsMobile,
  getBrowserIsLeftBarOpened,
  getBrowserDimensions,
} from '../../reduxUtils/browserSettings/selectors';
import { getRates } from '../../reduxUtils/browserSettings/thunks';
import WithSideBar from 'layouts/WithSideBar';
import { getAccessControl } from '../../reduxUtils/accessControl/selectors';
import {
  getUserWorkshopRegistrations,
  getUserWorkshopRegistrationsReqParams,
  getWorkshopRegistration,
} from '../../reduxUtils/userWorkshopRegistrations/selectors';
import {
  createWorkshopRegistration,
  addWorkshopRegistration,
  deleteWorkshopRegistration,
  setAllWorkshopRegistrations,
  setWorkshopRegistrations,
} from '../../reduxUtils/userWorkshopRegistrations/thunks';
import {
  getUserEventRegistrations,
  getUserEventRegistrationsReqParams,
} from '../../reduxUtils/userEventRegistrations/selectors';

import Fallback from 'components/Fallback';
import { setAllEventRegistrations } from '../../reduxUtils/userEventRegistrations/thunks';
import { getIsChatDetach, getIsChatMinimized } from '../../reduxUtils/chat/selectors';
import { setLoginModalStatus } from '../../reduxUtils/browserSettings/actions';

const Workshop = lazy(() => import('./Workshop'));

function WorkshopFull({
  navigate,
  match,
  location,
  isMobile,
  userSettings,
  isLoggedIn,
  isLeftBarOpened,
  userId,
  fullName,
  avatar,
  getRates,
  dimensions,
  accessControls,
  registration,
  userWorkshopRegistrations,
  setWorkshopRegistrations,
  addWorkshopRegistration,
  createWorkshopRegistration,
  deleteWorkshopRegistration,
  eventRegistrations,
  userEventRegistrationsReqParams,
  setAllEventRegistrations,
  setAllWorkshopRegistrations,
  userWorkshopRegistrationsReqParams,
  isChatMinimized,
  isChatDetach,
}) {
  const workshopId = useParams();
  return (
    <WithSideBar
      location={location}
      isMobile={isMobile}
      isLoggedIn={isLoggedIn}
      isLeftBarOpened={isLeftBarOpened}
      userId={userId}
      fullName={fullName}
      avatar={avatar}
      isChatMinimized={isChatMinimized && isChatDetach}
    >
      <Suspense fallback={<Fallback />}>
        <Workshop
          navigate={navigate}
          match={match}
          workshopId={workshopId}
          isMobile={isMobile}
          userSettings={userSettings}
          isLoggedIn={isLoggedIn}
          userId={userId}
          getRates={getRates}
          dimensions={dimensions}
          accessControls={accessControls}
          registration={registration}
          userWorkshopRegistrations={userWorkshopRegistrations}
          setWorkshopRegistrations={setWorkshopRegistrations}
          addWorkshopRegistration={addWorkshopRegistration}
          createWorkshopRegistration={createWorkshopRegistration}
          deleteWorkshopRegistration={deleteWorkshopRegistration}
          eventRegistrations={eventRegistrations}
          userEventRegistrationsReqParams={userEventRegistrationsReqParams}
          setAllEventRegistrations={setAllEventRegistrations}
          setAllWorkshopRegistrations={setAllWorkshopRegistrations}
          userWorkshopRegistrationsReqParams={userWorkshopRegistrationsReqParams}
          setLoginModalStatus={setLoginModalStatus}
        />
      </Suspense>
    </WithSideBar>
  );
}

WorkshopFull.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLeftBarOpened: PropTypes.bool,
  userId: PropTypes.number,
  userSettings: PropTypes.object,
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  dimensions: PropTypes.object,
  accessControls: PropTypes.array,
  setWorkshopRegistrations: PropTypes.func,
  eventRegistrations: PropTypes.array,
  setAllWorkshopRegistrations: PropTypes.func,
  setAllEventRegistrations: PropTypes.func,
  userEventRegistrationsReqParams: PropTypes.object,
  userWorkshopRegistrationsReqParams: PropTypes.object,
  isChatMinimized: PropTypes.bool,
  isChatDetach: PropTypes.bool,
};

function mapStateToProps(state, { workshopId }) {
  return {
    isLoggedIn: getAuthIsLoggedIn(state),
    isMobile: getBrowserIsMobile(state),
    isLeftBarOpened: getBrowserIsLeftBarOpened(state),
    userId: getUserId(state),
    userSettings: getUserSettings(state),
    fullName: getUserFullName(state),
    avatar: getUserAvatarThumbnail(state),
    dimensions: getBrowserDimensions(state),
    accessControls: getAccessControl(state),
    registration: getWorkshopRegistration(state, workshopId),
    userWorkshopRegistrations: getUserWorkshopRegistrations(state),
    eventRegistrations: getUserEventRegistrations(state),
    userEventRegistrationsReqParams: getUserEventRegistrationsReqParams(state),
    userWorkshopRegistrationsReqParams: getUserWorkshopRegistrationsReqParams(state),
    isChatDetach: getIsChatDetach(state),
    isChatMinimized: getIsChatMinimized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRates,
      setWorkshopRegistrations,
      addWorkshopRegistration,
      createWorkshopRegistration,
      deleteWorkshopRegistration,
      setAllEventRegistrations,
      setAllWorkshopRegistrations,
      setLoginModalStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopFull);
