import React from 'react';
import {Modal} from 'react-bootstrap';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

import {BodyFontSizeCss, LabelFontSizeCss, UserSelectNoneCss} from 'styles/common';
import Icon from 'components/Icon';

const StyledModal = styled(Modal)`
  ${UserSelectNoneCss};

  .custom-size-dialog {
    padding: 0;

    ${({margin}) => {
      return margin
        ? css`
          margin: ${margin};
        `
        : css`
          margin: 0;
        `

    }}
    ${({width}) => {
      return width
        ? css`
          width: ${width};
        `
        : null;
    }}
    ${({max_width}) => max_width
      && css`
        max-width: ${max_width};
      `
    }
    ${({height}) => {
      return height
        ? css`
          max-height: ${height};
          height: ${height};
        `
        : null;
    }}
    & .modal-content {
      border: none;
      overflow: auto;
      ${({border_radius}) => {
        return border_radius
          ? css`
            border-radius: 10px;
          `
          : css`
            border-radius: 0;
          `;
      }}
      height: 100%;
      width: 100%;
    }
  }
`;

const Root = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  color: ${({theme: {colors}}) => colors.darkGrey};
`;

const modelCss = css`
  display: flex;
  padding: 10px ${({theme: {padding}}) => padding.pageM}px !important;
`;

const Header = styled.div`
  position: relative;
  ${modelCss};
  min-height: 53px;
  align-items: center;
  border-bottom: 1px solid ${({theme: {colors}}) => colors.lightGrey};
  font-size: ${({theme: {typo}}) => typo.sizes.h5};
  -webkit-box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.4);
  font-weight: 500;
`;

const CloseButtonWrap = styled.div`
  position: absolute;
  right: 0;
`;

const CloseButton = styled(Icon)`
  color: ${({theme: {colors}}) => colors.darkGrey};
  margin-right: ${({theme: {padding}}) => padding.pageM}px;
  ${BodyFontSizeCss};
  cursor: pointer;
`;

const HeaderContent = styled.div`
  margin: auto;
  text-transform: uppercase;
  ${LabelFontSizeCss};
`;


const Body = styled.div`
  display: flex;
  padding: 0;
  flex-grow: 1;
`;

const Footer = styled.div`
  ${modelCss};
  align-items: center;
  border-top: 1px solid ${({theme: {colors}}) => colors.lightGrey};
  justify-content: center;
  -webkit-box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px -1px 8px -4px rgba(0, 0, 0, 0.4);
`;

function FullScreenModal(
  {
    children,
    width = '100%',
    height = '100%',
    onHide = null,
    show,
    backdrop = false,
    headerContent,
    footerContent,
    onCloseClick,
    ...props
  }
) {
  return (
    <StyledModal
      dialogClassName='custom-size-dialog'
      onHide={onHide}
      show={show}
      width={width}
      height={height}
      backdrop={backdrop}
      {...props}
      centered
      // todo:remove this when we fix zoom
      animation={false}
    >
      <Root>
        <Header>
          <HeaderContent>
            {headerContent}
          </HeaderContent>

          <CloseButtonWrap onClick={onCloseClick}>
            <CloseButton iconName='fa fa-times' />
          </CloseButtonWrap>
        </Header>
        <Body>
          {children}
        </Body>
        {
          footerContent &&
          (
            <Footer>
              {footerContent}
            </Footer>
          )
        }
      </Root>
    </StyledModal>
  );
}

FullScreenModal.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  backdrop: PropTypes.bool,
  headerContent: PropTypes.any,
  footerContent: PropTypes.any,
  onCloseClick: PropTypes.func
};
export default FullScreenModal;