import {createAction} from 'redux-actions';
import {
  SET_HOME_WORKSHOPS_SUGGESTION2,
  ADD_HOME_WORKSHOP_SUGGESTION2,
  ADD_HOME_WORKSHOPS_SUGGESTION2,
  UPDATE_HOME_WORKSHOPS_SUGGESTION2,
} from './types';

export const setHomeWorkshopsSuggestion2 = createAction(SET_HOME_WORKSHOPS_SUGGESTION2);
export const addHomeWorkshopSuggestion2 = createAction(ADD_HOME_WORKSHOP_SUGGESTION2);
export const addHomeWorkshopsSuggestion2 = createAction(ADD_HOME_WORKSHOPS_SUGGESTION2);
export const updateHomeWorkshopsSuggestion2 = createAction(UPDATE_HOME_WORKSHOPS_SUGGESTION2);
