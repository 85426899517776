import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {TileLeftBottom} from 'components/Tiles/components/JSXElements';
import theme from 'styles/theme';
import {scale} from 'styles/common';

const StarRatingWrap = styled.div`
  display: flex;
  align-items: center;
`;

const StarRatingIcon = styled.i`
  color: ${({theme: {colors}}) => colors.white};
  ${scale([
    ['default', [['font-size', theme.typo.sizes.h1]]],
    ['560px', [['font-size', theme.typo.sizes.h3]]],
    ['768px', [['font-size', theme.typo.sizes.h3]]],
    ['992px', [['font-size', theme.typo.sizes.h4]]],
    ['1200px', [['font-size', theme.typo.sizes.h5]]],
    ['1400px', [['font-size', theme.typo.sizes.body2]]]
  ])}
`;

const Rating = styled.span`
  color: ${({theme: {colors}}) => colors.white};
  margin-left: 0.5em;
  font-weight: bold;
  font-size: 0.8em;

  ${scale([
    ['default', [['font-size', theme.typo.sizes.h4]]],
    ['560px', [['font-size', theme.typo.sizes.h5]]],
    ['768px', [['font-size', theme.typo.sizes.body2]]],
    ['992px', [['font-size', theme.typo.sizes.body]]],
    ['1200px', [['font-size', theme.typo.sizes.body]]],
    ['1400px', [['font-size', theme.typo.sizes.small]]]
  ])}
  
`;

function StarRating({rating, ...props}) {
  return (
    <TileLeftBottom {...props}>
      <StarRatingWrap>
        <StarRatingIcon className="fas fa-star"/>
        <Rating>
          {rating}
        </Rating>
      </StarRatingWrap>
    </TileLeftBottom>
  );
}

StarRating.propTypes = {
  rating: PropTypes.number
};

export default StarRating;