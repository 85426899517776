import {handleActions} from 'redux-actions';
import {
  SET_CHAT_VISIBILITY,
  SET_CHAT_DETACH,
  TOGGLE_CHAT_MINIMIZE,
  HIDE_CHAT_DETACH,
  SET_CHAT_MINIMIZE
} from './types';

import {
  LOGOUT
} from '../auth/types';

let initialState = {
  socketConnected: false,
  isInit: false,
  chatRooms: [],
  currentChatRoom: {},
  isChatShown: true,
  isChatMinimized: false,
  isChatDetach: false,
  chatDetachProps: {},
};

export default handleActions({
  [SET_CHAT_VISIBILITY]: (state, {payload}) => {
    return {
      ...state,
      isChatShown: payload
    };
  },
  [SET_CHAT_DETACH]: (state, {payload = {}}) => {
    return {
      ...state,
      isChatDetach: true,
      chatDetachProps: payload
    };
  },
  [HIDE_CHAT_DETACH]: (state) => {
    return {
      ...state,
      isChatDetach: false,
      chatDetachProps: {}
    };
  },
  [TOGGLE_CHAT_MINIMIZE]: (state) => {
    return {
      ...state,
      isChatMinimized: !state.isChatMinimized
    };
  },
  [SET_CHAT_MINIMIZE]: (state, {payload}) => {
    return {
      ...state,
      isChatMinimized: payload
    };
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {...initialState});
