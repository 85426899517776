import React from "react";
import styled from 'styled-components';
import classnames from 'classnames';
import {ConnectingCss} from "components/Chat/components/JSXElements";

const Room = styled.button`
  font-weight: 500;
  line-height: normal;
  padding: 8px 10px;
  background-color: #EAEAEA;
  border-radius: 10px;
  margin: 0 5px;
  color: ${({theme: {colors}}) => colors.grayNew};
  border: 1px solid transparent;

  &:hover:not(.disabled) {
    background-color: #dcdcdc;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }


  &.isSelected:not(.isUsersRoomVisible) {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid ${({theme: {colors}}) => colors.purple};
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }

  &.isConnecting {
    ${ConnectingCss};
  }

  &.isSelected {
    color: ${({theme: {colors}}) => colors.purple};
  }

`;

const RoomWrap = styled.div`
  position: relative;
`;

const UnreadMessages = styled.div`
  position: absolute;
  border-radius: 10px;
  color: white;
  background-color: darkred;
  font-size: 9px;
  top: -20%;
  right: 0;
  padding: 0 5px;
`;


const ChatRoomsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  color: ${({theme: {colors}}) => colors.darkGrey};
  font-size: 13px;
`;


function ChatRooms({
                     chatRoomsLabels,
                     selectedRoom,
                     onRoomClick,
                     isSocketConnected,
                     isUsersRoomVisible
                   }) {

  const chatLabels = Object.values(chatRoomsLabels).reduce((prevVal, chatRoom) => {
    const {label, key, visible, disabled, isLoading, unreadMessages, isConnecting} = chatRoom;

    if (!visible) {
      return prevVal;
    }

    const disabledRoom = !isSocketConnected || isLoading || disabled;

    let liClasses = classnames({
      'disabled': disabledRoom,
      'isConnecting': isConnecting,
      'isSelected': (key === selectedRoom.key),
      'isUsersRoomVisible': isUsersRoomVisible,
    });

    prevVal.push(
      <RoomWrap key={key}
      >
        <Room
          className={liClasses}
          disabled={!isSocketConnected}
          onClick={() => {
            onRoomClick(chatRoom)
          }}
        >
          {label}
        </Room>
        {
          !!unreadMessages &&
          <UnreadMessages>
            {unreadMessages}
          </UnreadMessages>
        }
      </RoomWrap>
    );
    return prevVal;
  }, [])

  return (
    <ChatRoomsWrap>
      {chatLabels}
    </ChatRoomsWrap>
  );
}

export default React.memo(ChatRooms);

