import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {
  UPLOAD_STATUS
} from 'config/constants';

import Flow, {getFlow, addFlow} from 'utilities/flows';

import {theme} from 'styles/theme';

import Icon from 'components/Icon';
import {HeaderTitle, StyledProgressBar, TimeRemaining} from 'components/FileUploader/components/JSXElements';
import {getMaxTimeStr} from "utilities/date";

const ChunkedFileWrap = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 0 5px 5px;
  background-color: ${({theme: {colors}}) => colors.white};

  &:last-child {
    margin-bottom: 0;
  }

  ${({status, theme: {colors}}) => {

    if (status === UPLOAD_STATUS.SUCCESS.key) {
      return css`border-color: ${colors.success}`;
    }

    if (status === UPLOAD_STATUS.ERROR.key) {
      return css`border-color: ${colors.error}`;
    }

    return css`border-color: ${colors.lightGrey}`;
  }};

`;

const ChunkedFileHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ChunkedFileHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-left: 5px;
  font-size: 18px;
`;

const StyledIcon = styled(Icon)`
  width: auto;
  height: auto;
  margin-left: 10px;
  cursor: pointer;
`;

function ChunkedFile(
  {
    isCollapsed,
    flowId,
    flowOpts,
    uniqueIdentifier,
    size,
    name,
    uploadType,
    progress,
    timeRemaining,
    status,
    ...props
  }
) {
  const [flow, setFlow] = useState(null);

  const playButtonIcon = UPLOAD_STATUS.PAUSED.key === status
    ? 'far fa-play-circle'
    : 'far fa-pause-circle';

  const playButtonAction = UPLOAD_STATUS.PAUSED.key === status
    ? () => flow.resume({uniqueIdentifier, name})
    : () => flow.pause({uniqueIdentifier, name});

  const isCompleted = status === UPLOAD_STATUS.SUCCESS.key;
  let progressLabel = 'starting';
  const percentages = Math.round(progress * 100);
  if (isCompleted && progress === 1) {
    progressLabel = 'Completed';
  } else if (!isCompleted && progress === 1) {
    progressLabel = 'Processing...';
  } else if (progress > 0) {
    progressLabel = `${percentages}%`;
  }

  useEffect(() => {
    let flow = getFlow(flowId);
    if (!flow) {
      flow = new Flow(flowId, flowOpts);
      addFlow(flow);
    }
    setFlow(flow);
  }, [flowId, flowOpts]);

  let textTimeRemaining = getMaxTimeStr(timeRemaining);
  if (textTimeRemaining) {
    textTimeRemaining += ' left';
  }
  return (
    <ChunkedFileWrap status={status} {...props}>
      <ChunkedFileHeader>
        <HeaderTitle
          status={status}
          isCollapsed={isCollapsed}
        >
          {
            status === UPLOAD_STATUS.ERROR.key
              ? `Error: ${name}`
              : name
          }
        </HeaderTitle>

        {
          !isCollapsed &&
          (
            <ChunkedFileHeaderActions>
              {
                status !== UPLOAD_STATUS.ERROR.key &&
                (
                  <StyledIcon
                    onClick={playButtonAction}
                    iconName={playButtonIcon}
                  />
                )
              }

              {
                status === UPLOAD_STATUS.ERROR.key &&
                (
                  <StyledIcon
                    onClick={() => flow.retry({uniqueIdentifier, name})}
                    iconName='fas fa-undo'
                  />
                )
              }
              <StyledIcon
                onClick={() => flow.cancel({uniqueIdentifier, name})}
                style={{color: theme.colors.error}}
                iconName='fas fa-ban'
              />
            </ChunkedFileHeaderActions>
          )
        }
      </ChunkedFileHeader>
      <StyledProgressBar
        animated
        striped
        variant={progress === 0 ? 'info' : 'success'}
        now={progress === 0 ? 100 : percentages}
        label={progressLabel}
      />
      <TimeRemaining>
        {textTimeRemaining}
      </TimeRemaining>
    </ChunkedFileWrap>
  );
}

ChunkedFile.propTypes = {
  isCollapsed: PropTypes.bool,
  flowId: PropTypes.string,
  flowOpts: PropTypes.object,
  uniqueIdentifier: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string,
  uploadType: PropTypes.string,
  progress: PropTypes.number,
  status: PropTypes.string
};

export default ChunkedFile;
