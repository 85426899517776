import {errorHandler} from "utilities/services";
import UserService from "services/user";
import {addPurchasesWorkshops, setPurchasesWorkshops} from "./actions";

const userService = new UserService();

export function fetchPurchasesWorkshops(query = {}, addNextPage) {
  return dispatch => userService.getAllPurchases(query).then((response) => {
    const {data: {purchases}} = response;
    if (addNextPage) {
      dispatch(addPurchasesWorkshops(purchases));
    } else {
      dispatch(setPurchasesWorkshops(purchases));
    }
    return response;
  }).catch(errorHandler);
}