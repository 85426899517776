import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const EmptyCenteredWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%
`;

function EmptyCentered({children}) {
  return <EmptyCenteredWrap>{children}</EmptyCenteredWrap>;
}

EmptyCentered.propTypes = {
  children: PropTypes.any
};

export default EmptyCentered;