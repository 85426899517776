import {handleActions} from 'redux-actions';
import {
  LOGIN,
  LOGOUT
} from './types';

import {isLoggedIn, getAuth} from '../../utilities/auth';

let initialState = {
  isLoggedIn: false,
  accessToken: ''
};


export default handleActions({
  [LOGIN]: (state, {payload}) => {
    const {accessToken} = payload;
    return {accessToken, isLoggedIn: true};
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, {
  ...initialState,
  isLoggedIn: isLoggedIn(),
  ...getAuth()
});
