import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CoverPhotoWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  background: url('${({ url, backupUrl }) => (url ? url : backupUrl)}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

function CoverPhoto({ className, url, backupUrl, ...props }) {
  return <CoverPhotoWrap {...props} className={className} url={url} backupUrl={backupUrl} />;
}

CoverPhoto.propTypes = {
  url: PropTypes.string,
  backupUrl: PropTypes.string,
};

export default CoverPhoto;
