import money from 'money';
import currencies from "config/currencies";
import accounting from "accounting";
import {getStoreState} from "utilities/store";
import {getBrowserIsFxReady} from "../reduxUtils/browserSettings/selectors";

const fx = money;
fx.base = 'USD';

export function setRates(rates) {
  fx.rates = rates;
}

export function getFx(key) {
  return fx;
}

export function convertCurrency(amount = 0, currency = 'USD', toCurrency = 'USD') {
  const state = getStoreState();
  const isFxReady = getBrowserIsFxReady(state);

  if (isFxReady) {
    const fx = getFx();
    return Math.ceil(accounting.toFixed(fx.convert(amount, {from: currency, to: toCurrency}), 2));
  }

  return 0;
}

export function getUserPrice(price = 0, currency = 'USD', toCurrency = 'USD') {
  let priceObj = {symbol: currencies[currency].symbol, price: price};
  if (currency !== toCurrency) {
    const convertedPrice = convertCurrency(price, currency, toCurrency);
    if (convertedPrice) {
      priceObj = {symbol: currencies[toCurrency].symbol, price: convertedPrice};
    }
  }

  return priceObj;
}
