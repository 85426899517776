import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addFollowing, deleteFollowing } from '../../reduxUtils/userFollowings/thunks';
import { getFollow } from '../../reduxUtils/userFollowings/selectors';
import { errorHandler } from 'utilities/services';
import styled, { css } from 'styled-components';
import Button from 'components/Button';
import { scale } from 'styles/common';
import theme from 'styles/theme';
import { AL_EVENT, AL_EVENT_ACTION, AL_EVENT_STATUS, alFollowUnFollow } from 'utilities/analytics';
import { setLoginModalStatus } from '../../reduxUtils/browserSettings/actions';

const FollowButton = styled(Button)`
  margin-left: 0.5em;

  color: ${({ invert, theme: { colors } }) => {
    return invert ? colors.purple : colors.grayNew;
  }};

  ${({ invert }) =>
    invert
      ? css`
          color: ${({ theme: { colors } }) => colors.purple};
        `
      : css`
          color: ${({ theme: { colors } }) => colors.grayNew};
          background-color: rgb(255 255 255 / 30%);
        `}
  padding: 0.5em 0.6em;
  ${scale([
    ['default', [['font-size', theme.typo.sizes.body]]],
    ['560px', [['font-size', theme.typo.sizes.body2]]],
    ['768px', [['font-size', theme.typo.sizes.body]]],
    ['992px', [['font-size', theme.typo.sizes.small]]],
  ])}
`;

function Follow({
  follow = null,
  userId = null,
  channelId = null,
  addFollowing,
  deleteFollowing,
  userFullName,
  showUnfollow = false,
  setFollowersCount,
  setDialog,
  setLoginModalStatus,
}) {
  function setDialogState() {
    setDialog((currentState) => {
      return {
        ...currentState,
        show: true,
        title: `Unfollow channel`,
        confirmationText: `Are you sure you want to unfollow`,
        contentTitle: `${userFullName}`,
        action: 'Unfollow',
        onCancel: currentState.onCancel,
        onConfirm: (follow, userId) => {
          onClick(follow, userId);
          currentState.onCancel();
        },
      };
    });
  }

  const busyRef = useRef(false);

  function onClick(follow, userId) {
    if (busyRef.current) return;

    if (!userId) {
      setLoginModalStatus(true);
      return;
    }

    let error;
    const followCall = !follow
      ? () => addFollowing(channelId)
      : () => deleteFollowing(follow.id, follow.toUserId);
    const followAddOrSubtract = !follow ? 1 : -1;
    busyRef.current = true;
    followCall()
      .then((response) => setFollowersCount((followers) => followers + followAddOrSubtract))
      .catch((err) => {
        error = err.message;
        errorHandler(err);
      })
      .finally(() => {
        busyRef.current = false;
        alFollowUnFollow({
          alEvent: !follow ? AL_EVENT.CHANNEL.follow : AL_EVENT.CHANNEL.unfollow,
          eventAction: AL_EVENT_ACTION.click,
          eventStatus: error ? AL_EVENT_STATUS.failed : AL_EVENT_STATUS.success,
          eventLabel: 'clicking on following',
          userId,
          channelId,
          error,
        });
      });
  }

  if (!showUnfollow && follow)
    //dont display unfollow option
    return null;

  return (
    <>
      <FollowButton
        label={!!follow ? 'following' : 'follow'}
        invert={!follow}
        buttonStyle="generalWhite"
        onClick={!!follow ? setDialogState : () => onClick(follow, userId)}
      />
    </>
  );
}

Follow.propTypes = {
  follow: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  eventId: PropTypes.number,
  workshopId: PropTypes.number,
  setFollowersCount: PropTypes.func,
  setDialog: PropTypes.func,
};

function mapStateToProps(state, { channelId }) {
  return {
    // isLoggedIn: getAuthIsLoggedIn(state),
    follow: channelId ? getFollow(state, channelId) : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addFollowing,
      deleteFollowing,
      setLoginModalStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Follow));
