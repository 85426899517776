import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const PlaceHolderWrap = styled.div`
  display: block;
  width: ${({width}) => width || 0};
  height: ${({height}) => height || '15px'};
  background-color: ${({isLight, theme: {colors}}) => isLight ? colors.white : colors.fill};
  margin-top: ${({marginTop}) => marginTop || 0};
  margin-bottom: ${({marginBottom}) => marginBottom || 0};
  margin-left: ${({marginLeft}) => marginLeft || 0};
  border-radius: ${({borderRadius}) => borderRadius || 0}%;
  margin: ${({marginAuto}) => marginAuto || null};

  background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 50%, #F4F4F4 100%);
  animation: shine-lines 1.4s infinite ease-out;

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 100%;
    }
  }
`;

function PlaceHolder(props) {
  return (
    <PlaceHolderWrap {...props}>
    </PlaceHolderWrap>
  );
}

PlaceHolder.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string
};

export default PlaceHolder;
