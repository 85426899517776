import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setBrowserIsLeftBarOpened } from '../../../../../reduxUtils/browserSettings/actions';
import Icon from 'components/Icon';

const LeftSideBarButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LeftSideBarButtonIcon = styled(Icon)`
  font-size: ${({ theme: { typo } }) => typo.sizes.h5};
  color: ${({ isleftbaropened, theme: { colors } }) =>
    isleftbaropened ? colors.black : colors.darkGrey};
`;

function LeftSideBarButton({
  isLeftBarOpened,
  setLocalIsLeftBarOpened,
  setBrowserIsLeftBarOpened,
}) {
  function handleOnClick() {
    setLocalIsLeftBarOpened && setLocalIsLeftBarOpened(!isLeftBarOpened);
    !setLocalIsLeftBarOpened && setBrowserIsLeftBarOpened(!isLeftBarOpened);
  }

  return (
    <LeftSideBarButtonWrap onClick={handleOnClick}>
      <LeftSideBarButtonIcon isleftbaropened={+isLeftBarOpened} className="fas fa-bars" />
    </LeftSideBarButtonWrap>
  );
}

LeftSideBarButton.propTypes = {
  isLeftBarOpened: PropTypes.bool,
  setLocalIsLeftBarOpened: PropTypes.func,
  setBrowserIsLeftBarOpened: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBrowserIsLeftBarOpened,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(LeftSideBarButton);
