import {createAction} from 'redux-actions';
import {
  ADD_NEW_POLL,
  DELETE_NEW_POLL,
  UPDATE_NEW_POLL,
  CLEAR_NEW_POLLS,
  DELETE_NEW_POLL_OPTION,
  ADD_NEW_POLL_OPTION,
  UPDATE_NEW_POLL_OPTION,
} from './types';

export const addNewPollAction = createAction(ADD_NEW_POLL);
export const deleteNewPollAction = createAction(DELETE_NEW_POLL);
export const updateNewPollAction = createAction(UPDATE_NEW_POLL);
export const clearNewPollsAction = createAction(CLEAR_NEW_POLLS);

export const addNewPollOptionAction = createAction(ADD_NEW_POLL_OPTION);
export const deleteNewPollOptionAction = createAction(DELETE_NEW_POLL_OPTION);
export const updateNewPollOptionAction = createAction(UPDATE_NEW_POLL_OPTION);
