import {handleActions} from 'redux-actions';
import {pick} from 'lodash';
import {
  SET_USER_FOLLOWINGS,
  ADD_USER_FOLLOWING,
  DELETE_USER_FOLLOWING
} from './types';
import {LOGOUT} from "../auth/types";

const defaultFollowingFields = [
  'id',
  'UserId',
  'toUserId'
];

export const initialState = [];

export default handleActions({
  [SET_USER_FOLLOWINGS]: (state, {payload}) => {
    return [...payload.map((Following) => pick(Following, defaultFollowingFields))];
  },
  [ADD_USER_FOLLOWING]: (state, {payload}) => {
    const newFollowing = pick(payload, defaultFollowingFields);
    const Followings = [...state];
    const foundIndex = Followings.findIndex((Following) => Following.id === newFollowing.id);
    if (foundIndex === -1) {
      Followings.push(newFollowing);
      return Followings;
    }

    return state;
  },
  [DELETE_USER_FOLLOWING]: (state, {payload}) => {
    const followings = [...state];

    const foundIndex = followings.findIndex((Following) => Following.id === payload);
    if (foundIndex > -1) {
      followings.splice(foundIndex, 1);
      return followings;
    }

    return state;
  },
  [LOGOUT]: () => {
    return initialState;
  }
}, [...initialState]);