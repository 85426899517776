import React, {useState} from 'react';
//  import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Dropdown} from 'react-bootstrap';

import ContentMenuItem from 'components/MenuItems/ContentMenuItem';
import Icon from 'components/Icon';
import Action from './component/Action';
import {errorHandler} from 'utilities/services';
import {UserSelectNoneCss} from 'styles/common';

const ActionBarWrap = styled(Dropdown)`
  ${UserSelectNoneCss};
`;

const ActionBarToggle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;

  &:hover {
    color: ${({theme: {colors}}) => colors.primary};
  }

  font-size: ${({theme: {typo}}) => typo.sizes.body};
`;

const Menu = styled(ActionBarWrap.Menu)`
  min-width: auto;
`;

const ActionBarIcon = styled(Icon)`
  width: auto;
  height: auto;
`;


function ActionsBar(
  {
    className,
    isPrivate,
    isPublished,
    onStreamerClick,
    onInspectClick,
    onManageClick,
    onEditClick,
    onTogglePrivate,
    onToggleVisibility,
    onDeleteClick,
    drop = 'down',
  }
) {
  const [isLoading, setIsLoading] = useState(false);
  const CustomToggle = React.forwardRef(({children, onClick, classname}, ref) => (
    <ActionBarToggle
      className={classname}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </ActionBarToggle>
  ));

  return (
    <ActionBarWrap
      drop={drop}

    >
      <ActionBarWrap.Toggle
        classname={className}
        as={CustomToggle}
      >
        <ActionBarIcon
          iconName="fas fa-ellipsis-v"
        />
      </ActionBarWrap.Toggle>
      <Menu>
        {
          onStreamerClick &&
          (
            <ContentMenuItem>
              <Action
                label="Streamer View"
                iconName="fas fa-video"
                onClick={onStreamerClick}
              />
            </ContentMenuItem>
          )
        }

        {
          onInspectClick &&
          (
            <ContentMenuItem>
              <Action
                label="Users View"
                iconName="fas fa-users"
                onClick={onInspectClick}
              />
            </ContentMenuItem>
          )
        }
        {
          onManageClick &&
          (
            <ContentMenuItem>
              <Action
                label="Manage"
                iconName="fas fa-users-cog"
                onClick={onManageClick}
              />
            </ContentMenuItem>
          )
        }
        {
          onEditClick &&
          (
            <ContentMenuItem>
              <Action
                label="Edit"
                iconName="fas fa-edit"
                onClick={onEditClick}
              />
            </ContentMenuItem>
          )
        }
        {
          onToggleVisibility &&
          (
            <ContentMenuItem
              disabled={isLoading}
            >
              <Action
                disabled={isLoading}
                isActive={isPublished}
                label={isPublished ? 'Unpublish' : 'Publish'}
                iconName={isPublished ? 'fas fa-eye-slash' : 'fas fa-eye'}
                onClick={(e) => {
                  if (isLoading) {
                    return false;
                  }
                  onToggleVisibility(e, !isPublished);
                }}
              />
            </ContentMenuItem>
          )
        }
        {
          onTogglePrivate &&
          (
            <ContentMenuItem
              disabled={isLoading}
            >
              <Action
                disabled={isLoading}
                isActive={isPrivate}
                label={isPrivate ? 'Set Public' : 'Set Private'}
                iconName={isPrivate ? 'fas fa-lock' : 'fas fa-lock-open'}
                onClick={(e) => {
                  if (isLoading) {
                    return false;
                  }

                  setIsLoading(true);
                  onTogglePrivate(e, !isPrivate)
                    .then(() => {
                      setIsLoading(false);
                    })
                    .catch(err => {
                      errorHandler(err)
                    });
                }}
              />
            </ContentMenuItem>
          )
        }
        {
          onDeleteClick &&
          (
            <ContentMenuItem
              disabled={isLoading}

            >
              <Action
                disabled={isLoading}
                isDelete
                label="Delete"
                iconName="fas fa-trash-alt"
                onClick={(e) => {
                  if (isLoading) {
                    return false;
                  }
                  onDeleteClick(e);
                }}
              />
            </ContentMenuItem>
          )
        }
      </Menu>
    </ActionBarWrap>
  );
}

ActionsBar.propTypes = {};

export default React.memo(ActionsBar);