import {createSelector} from 'reselect';

export const getUser = state => state.user;
export const getUserId = createSelector(getUser, user => user.id);
export const getUserEmail = createSelector(getUser, user => user.email);
export const getUserStatus = createSelector(getUser, user => user.status);
export const getUserProvider = createSelector(getUser, user => user.provider);
export const getUserStripeAccountId = createSelector(getUser, user => user.stripeAccountId);
export const getUserStripeAccountStatus = createSelector(getUser, user => user.stripeAccountStatus);
export const getUserStripeCustomerId = createSelector(getUser, user => user.stripeCustomerId);
export const getUserStripeCustomerDPM = createSelector(getUser, user => user.stripeCustomerDPM);
//  export const getUserZoomConnected = createSelector(getUser, user => user.zoomConnected);
export const getUserPaymentMethods = createSelector(getUser, user => user.paymentMethods);