import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

import SwitchInput from '../SwitchInput';
import InfoButton from 'components/InfoButton';

import { ErrorFontSizeCss, LabelFontSizeCss } from 'styles/common';

const StyledSwitchInputWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
`;

const StyledSwitchInputFieldWrap = styled.div`
  display: flex; //todo: zoom restyle remove important
  align-items: center;
  padding: 5px;
  border: none;
  width: auto;
  ${LabelFontSizeCss};

  &.form-control.is-invalid {
    padding: 5px;
  }

  ${({ disabled_color }) =>
    disabled_color &&
    css`
      background-color: ${({ theme: { colors } }) => colors.disabled} !important;
    `}
`;

const StyledSwitchInputLabel = styled.label`
  color: ${({ theme: { colors } }) => colors.grayNew};
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 0;
  ${LabelFontSizeCss};
`;

const StyledSwitchInputIcon = styled.i``;

const Feedback = styled.div`
  color: ${({ theme: { colors } }) => colors.error};
  font-weight: 400;
  ${ErrorFontSizeCss};

  &:hover {
    cursor: auto; //todo: zoom restyle
  }
`;

function StyledSwitchInput({
  label = '',
  error,
  hideErrorText,
  name,
  style,
  className = null,
  infoText,
  icon,
  disabled,
  onChange,
  value,
  ...props
}) {
  const [refs, setRefs] = useState(null);

  const wrapperClass = classnames(
    'form-group ',
    className,
    error && error.length > 0 ? 'has-error' : ''
  );

  const fieldClass = classnames('form-control', error ? 'is-invalid' : '');

  return (
    <StyledSwitchInputWrap style={style} className={wrapperClass} onClick={() => refs.click()}>
      {label ? <StyledSwitchInputLabel>{label}</StyledSwitchInputLabel> : <span></span>}

      <StyledSwitchInputFieldWrap className={fieldClass} disabled_color={+!!disabled} {...props}>
        {icon && <StyledSwitchInputIcon className={icon} />}
        <SwitchInput
          setRef={(ref) => {
            setRefs(ref);
          }}
          disabled={disabled}
          onChange={onChange}
          isActive={value}
        />
        {infoText && !error && <InfoButton infoText={infoText} />}
      </StyledSwitchInputFieldWrap>
      {error && !hideErrorText && <Feedback className="invalid-feedback">{error}</Feedback>}
    </StyledSwitchInputWrap>
  );
}

StyledSwitchInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideErrorText: PropTypes.bool,
  name: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  infoText: PropTypes.string,
  icon: PropTypes.string,
};

export default StyledSwitchInput;
