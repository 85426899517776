import {categoriesParser, isPaid} from 'utilities/general';
import {getStoreState} from 'utilities/store';
import {getUserId} from '../reduxUtils/user/selectors';
import {AL_EVENT, getEventItemId, getWorkshopItemId} from 'utilities/analytics';

/*
alEvent - the event name e.g login\sign up\createEvent
action – what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
label – custom labels for particular events that you want to keep track of, for example, if there are different variations, such as Header Test Variant A, Page X Image B, or Video Header Test.
category – an object the user interacted with or group of actions performed by the user, for example, scrolling, internal links, buttons, videos, etc;
value – how much is this action worth? If you have a basic estimate, at least you can more easily combine all ‘value’ generated across different conversion types together to see an aggregate of how traffic is performing.
 */
export function pushToDataLayer(
  {
    alEvent = '',
    eventAction = '',
    eventStatus = '',
    eventLabel = '',
    eventCategory,
    eventValue = 0,
    loginMethod = 'email',
    error = '',
    userId,
    ...props
  }
) {
  let userIdVal = userId;
  if (!userIdVal) {
    const state = getStoreState();
    userIdVal = getUserId(state);
  }

  window.dataLayer.push({
    event: alEvent,
    loginMethod: loginMethod,
    eventAction: eventAction,
    eventStatus: eventStatus,
    eventLabel: eventLabel,
    eventCategory: eventCategory,
    eventValue: eventValue,
    error: error,
    userId: userIdVal,
    ...props
  });
}

export function pushPageViewDataLayer(
  {
    alEvent = '',
    loginMethod = 'email',
    error = '',
    userId,
    webVitals
  }
) {
  let userIdVal = userId;
  if (!userIdVal) {
    const state = getStoreState();
    userIdVal = getUserId(state);
  }

  window.dataLayer.push({
    event: alEvent,
    loginMethod: loginMethod,
    userId: userIdVal,
    ...webVitals
  });

}


export function pushItemsToDataLayer(
  {
    alEvent = '',
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    eventCategory, // an object the user interacted with or group of actions performed by the user, for example, scrolling, internal links, buttons, videos, etc;
    value,
    items,
    currency,
    affiliation,
    coupon,
    discount = 0,
    payment_type,
    transaction_id,
    tax = 0,
    userId,
    error,
  }
) {
  let userIdVal = userId;
  if (!userIdVal) {
    const state = getStoreState();
    userIdVal = getUserId(state);
  }
  window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: alEvent,
    eventAction: eventAction,
    eventStatus: eventStatus,
    eventLabel: eventLabel,
    eventCategory: eventCategory,
    userId: userIdVal,
    error: error,
    ecommerce: {
      payment_type: payment_type, //e,g visa/bit/paypal
      transaction_id: transaction_id,
      affiliation: affiliation, //A product affiliation to designate a supplying company e.g - facebook
      coupon: coupon, //The coupon name/code associated with the item.e.g - SUMMER_FUN
      discount: discount, //The monetary discount value associated with the item. e.g - 2.22
      currency: currency,
      value: value,
      tax: tax,
      items: items,
    }
  });
}

/*
 * Call this function when a user interact with event.
 * @param {Object} item An object that represents the ecommerce item.
https://developers.google.com/tag-platform/gtagjs/reference/events#select_item
 */
export function pushEventToDataLayer(
  {
    alEvent = '',
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    content = {}, //the event object
    homeCategory,  //the home category the event is shown
    embeddedType,
    occurrenceId,
    value,
    promotion_id,
    promotion_name,
    creative_name,
    creative_slot,
    affiliation,
    coupon,
    discount = 0,
    payment_type,
    transaction_id,
    quantity = 1,
    tax = 0,
    userId,
    error,
    ...itemsProps
  }
) {

  const {
    id,
    // occurrences,
    title,
    categories = [],
    isLive,
    paymentType,
    price = 0,
    duration,
    currency,
    WorkshopId,
    participantsLimit,
    UserId
  } = content;

  const eventCategory = 'event';
  const itemId = getEventItemId(id, occurrenceId);
  const _price = isPaid(paymentType) ? price : 0;
  const categoriesParsed = categoriesParser(categories);
  const channelId = alEvent === AL_EVENT.EVENT.createEvent ? userId : UserId;

  const items = [{
    item_name: title, // Name or ID is required.
    item_id: itemId,
    participants_limit: participantsLimit,
    duration,
    embeddedType,
    home_category: homeCategory,
    categories: categoriesParsed,
    item_brand: `${WorkshopId ? 'workshop_' : ''}event`,
    item_category: (categories[0] && categories) && categories[0].value,
    item_variant: `${isLive ? 'live' : 'vod'}`,//	The item variant or unique code or description for additional item details/options e.g - live/vod
    promotion_id: promotion_id, //The ID of the promotion associated with the event. e.g - P_12345
    promotion_name: promotion_name, //The name of the promotion associated with the event. e.g - Summer Sale
    creative_name: creative_name, //The name of the promotional creative. e.g - summer_banner2
    creative_slot: creative_slot, //The name of the promotional creative slot associated with the event. e.g - featured_app_1
    channelId,
    price: _price,
    index: 1,
    quantity: quantity,
    ...itemsProps
  }];

  pushItemsToDataLayer({
    alEvent,
    eventAction,
    eventStatus,
    eventLabel,
    eventCategory,
    value,
    items,
    currency,
    affiliation,
    coupon,
    discount,
    payment_type,
    transaction_id,
    tax,
    userId,
    error,
  })

}

/*
 * Call this function when a user interact with workshop.
 * @param {Object} item An object that represents the ecommerce item.
https://developers.google.com/tag-platform/gtagjs/reference/events#select_item
 */
export function pushWorkshopToDataLayer(
  {
    alEvent = '',
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    homeCategory,  //the home category the workshop is shown
    content = {}, //the workshop object
    workshopType, //is it vod, mix, or live
    promotion_id,
    promotion_name,
    creative_name,
    creative_slot,
    affiliation,
    coupon,
    discount = 0,
    payment_type,
    transaction_id,
    tax = 0,
    userId,
    error,
    ...itemsProps
  }
) {

  const {
    id,
    title,
    categories,
    price,
    participantsLimit,
    currency,
    UserId,
    duration,
    paymentType,
    // eventsInfo = [],
  } = content;

  const itemId = getWorkshopItemId(id);
  const _price = isPaid(paymentType) ? price : 0;
  const value = isPaid(paymentType) ? ((price) + tax - discount) : 0;

  const categoriesParsed = categoriesParser(categories);
  const channelId = alEvent === AL_EVENT.WORKSHOP.createWorkshop ? userId : UserId;

  const items = [{
    item_name: title, // Name or ID is required.
    item_id: itemId,
    item_brand: `workshop`,
    participants_limit: participantsLimit,
    duration,
    home_category: homeCategory,
    categories: categoriesParsed,
    item_category: (categories && categories[0]) ? categories[0].value : undefined,
    item_variant: workshopType,//	The item variant or unique code or description for additional item details/options e.g - live/vod
    promotion_id: promotion_id, //The ID of the promotion associated with the event. e.g - P_12345
    promotion_name: promotion_name, //The name of the promotion associated with the event. e.g - Summer Sale
    creative_name: creative_name, //The name of the promotional creative. e.g - summer_banner2
    creative_slot: creative_slot, //The name of the promotional creative slot associated with the event. e.g - featured_app_1
    channelId,
    price: _price,
    index: 1,
    quantity: 1,
    ...itemsProps,
  }];

  pushItemsToDataLayer({
    alEvent,
    eventAction,
    eventStatus,
    eventLabel,
    eventCategory: 'workshop',
    value,
    items,
    currency,
    affiliation,
    coupon,
    discount,
    payment_type,
    transaction_id,
    tax,
    userId,
    error,
  })
}

export function dlOnStreamProgress(
  {
    alEvent,
    event,
    eventAction,
    eventStatus,
    eventLabel,
    watchDuration,
    playedPercentage,
    embeddedType,
    userId
  }
) {

  pushEventToDataLayer(
    {
      alEvent,
      eventAction,
      eventStatus,
      eventLabel,
      content: event,
      watchDuration,
      embeddedType,
      userId,
      playedPercentage
    })
}

export function dlOnStreamPlay({alEvent, eventAction, eventStatus, eventLabel, error, event, embeddedType, userId}) {
  pushEventToDataLayer(
    {
      alEvent,
      eventAction,
      eventStatus,
      eventLabel,
      content: event,
      embeddedType,
      userId,
      error,
    })
}


export function dlOnStreamExit(
  {
    alEvent,
    eventAction,
    eventStatus,
    eventLabel,
    watchDuration,
    event,
    embeddedType,
    userId
  }
) {
  pushEventToDataLayer(
    {
      alEvent,
      eventAction,
      eventStatus,
      eventLabel,
      content: event,
      watchDuration,
      embeddedType,
      userId,
    })
}


export function pushChannelEventToDataLayer(
  {
    alEvent = '',
    eventAction = '',
    eventStatus = '',
    eventLabel = '',
    eventCategory,
    eventValue = 0,
    loginMethod = 'email',
    error = '',
    userId,
    channelId
  }
) {

  pushToDataLayer({
    event: alEvent,
    loginMethod: loginMethod,
    eventAction: eventAction,
    eventStatus: eventStatus,
    eventLabel: eventLabel,
    eventCategory: eventCategory,
    eventValue: eventValue,
    error: error,
    userId,
    channelId
  });
}

export function pushShareToDataLayer(
  {
    alEvent = '',
    eventAction, // what the user just did, i.e. scroll down, scroll up, click, view, submit a form;
    eventStatus, // the status about the action the user did
    eventLabel, //custom labels for particular events that you want to keep track of, e.g
    content, //the event object
    occurrenceId,
    userId,
    shareMedium,
    isWorkshop,
  }
) {
  const pushEventOrWorkshop = isWorkshop ? pushWorkshopToDataLayer : pushEventToDataLayer;

  pushEventOrWorkshop({
    alEvent,
    eventAction,
    eventStatus,
    eventLabel,
    content,
    occurrenceId,
    userId,
    shareMedium,
  })

}
